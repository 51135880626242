import { Input } from '@cart/ui/atoms/Input'
import React from 'react'

import { Form } from './Form'
import { QuantityInput } from './QuantityInput'

export const AddToCart = () => {
  const generateUrl = (data) =>
    `https://www.amazon.com/gp/aws/cart/add.html?ASIN.1=${data.cartAsin.trim()}&Quantity.1=${
      data.cartQuantity
    }`

  return (
    <Form
      title="Add to cart"
      description="Send shoppers directly to your product’s ‘add to cart’ Amazon page."
      example="Example: https://www.amazon.com/gp/aws/cart/add.html?ASIN.1=B00I0TJHDO&Quantity.1=1"
      handleGenerateLink={generateUrl}
    >
      <Input
        id="cartAsin"
        label="ASIN"
        placeholder="ASIN"
        helperText="Required"
        inputProps={{ maxLength: 10 }}
        rules={{
          required: 'All fields are required.',
          pattern: {
            value: /^[a-zA-Z0-9]{10}$/,
            message: 'ASIN must be 10 alpha-numeric characters.',
          },
        }}
      />

      <QuantityInput id="cartQuantity" />
    </Form>
  )
}
