import React, { ReactElement, useEffect, useState } from 'react'
import { Provider } from 'react-redux'

// components
import { GeneralContainer } from './GeneralContainer'
// helpers
import { initSDK } from './helpers/initHooks'
import { store } from './stores/root'

export const App = (): ReactElement => {
  const [isLocalLoading, setLocalLoading] = useState(false)

  useEffect(() => {
    initSDK().then(() => setLocalLoading(true))
  }, [])

  if (isLocalLoading) {
    return (
      <Provider store={store}>
        <GeneralContainer />
      </Provider>
    )
  }

  return null
}
