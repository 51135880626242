import { gql, GraphQLClient } from 'graphql-request'

import { asyncDebounce } from '../asyncDebounce'

export const gqlClient = new GraphQLClient(process.env.NX_GRAPHQL_URL as string)

const testedPhoneNumbers: { [key: string]: boolean } = {}
export const validatePhone = async (phone: string): Promise<boolean> => {
  // Reply on required rule to handle empty phone number validation
  if (!phone) {
    return true
  }

  if (testedPhoneNumbers[phone] !== undefined) return testedPhoneNumbers[phone]

  const orgValidatePhone = gql`
    query Query($phone: String!) {
      org_validatePhone(phone: $phone)
    }
  `

  const validated: { org_validatePhone: boolean } = await gqlClient.request(orgValidatePhone, {
    phone,
  })
  testedPhoneNumbers[phone] = validated.org_validatePhone

  return validated.org_validatePhone
}

export const validatePhoneWithCCodeRule = asyncDebounce(
  async (cCode: string, phone: string, errorMessage?: string): Promise<boolean | string> => {
    const message = errorMessage || 'Please enter a valid phone number'
    return (await validatePhone(`${cCode}${phone}`)) || message
  },
  300,
)

export const validatePhoneRule = asyncDebounce(
  async (phone: string, errorMessage?: string): Promise<boolean | string> => {
    const message = errorMessage || 'Please enter a valid phone number'
    return (await validatePhone(phone)) || message
  },
  300,
)
