import React, { useContext } from 'react'

import { CarouselContext } from '../carousel'
import { StyledCarouselItem, StyledCarouselView } from './carousel-item.styles'
import { CarouselItemProps } from './carousel-item.types'

export const CarouselItem = (props: CarouselItemProps) => {
  const data = useContext(CarouselContext)
  const { children } = props
  return (
    <StyledCarouselItem slidesToScroll={data.slidesToScroll} variableWidth={data.variableWidth}>
      <StyledCarouselView variableWidth={data.variableWidth}>{children}</StyledCarouselView>
    </StyledCarouselItem>
  )
}
