import { Input } from '@cart/ui/atoms/Input'
import React from 'react'

import { Form } from './Form'

export const CanonicalUrlBuilder = () => {
  const generateUrl = (data) =>
    `https://www.amazon.com/${data.keyword1.trim()}-${data.keyword2.trim()}-${data.keyword3.trim()}-${data.keyword4.trim()}-${
      data.keyword5
    }/dp/${data.canonicalAsin}`

  return (
    <Form
      title="Create your Amazon canonical URL"
      description="Create a search engine-optimized Amazon URL by providing 5 keywords and the product’s ASIN."
      example="Example: https://www.amazon.com/Medium-Moving-Boxes-Handles-Pack/dp/B00I0TJHDO?th=1"
      handleGenerateLink={generateUrl}
    >
      <Input
        id="keyword1"
        label="Keyword 1"
        placeholder="Keyword 1"
        helperText="Required"
        type="text"
        rules={{
          required: 'All fields are required.',
        }}
      />

      <Input
        id="keyword2"
        label="Keyword 2"
        placeholder="Keyword 2"
        helperText="Required"
        rules={{
          required: 'All fields are required.',
        }}
      />

      <Input
        id="keyword3"
        label="Keyword 3"
        placeholder="Keyword 3"
        helperText="Required"
        rules={{
          required: 'All fields are required.',
        }}
      />

      <Input
        id="keyword4"
        label="Keyword 4"
        placeholder="Keyword 4"
        helperText="Required"
        rules={{
          required: 'All fields are required.',
        }}
      />

      <Input
        id="keyword5"
        label="Keyword 5"
        placeholder="Keyword 5"
        helperText="Required"
        rules={{
          required: 'All fields are required.',
        }}
      />

      <Input
        id="canonicalAsin"
        label="ASIN"
        placeholder="ASIN"
        helperText="Required"
        inputProps={{ maxLength: 10 }}
        rules={{
          required: 'All fields are required.',
          pattern: {
            value: /^[a-zA-Z0-9]{10}$/,
            message: 'ASIN must be 10 alpha-numeric characters.',
          },
        }}
      />
    </Form>
  )
}
