import tw, { styled } from 'twin.macro'

import { FooterProps } from './footer.types'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledFooter = styled.footer((props: FooterProps) => [
  tw`flex items-center justify-center pt-10 pb-6`,
  props.product === 'mcm' && tw`pt-0`,
])

export const StyledFooterList = styled.ul(() => [tw`m-0 flex list-none p-0`])

export const StyledFooterItem = styled.li(() => [
  tw`mb-4 px-2 text-center text-xs sm:(mb-0 w-auto px-6 text-left text-sm)`,
])

export const StyledFooterLink = styled.a(() => [tw`[text-decoration: none] text-monochrome-600`])

export const StyledFooterSocialIcon = styled.a(() => [tw`px-4 text-monochrome-600`])

export const StyledMarketingFooter = styled.div(() => [
  tw`flex w-full flex-wrap items-center justify-between border-monochrome-500 border-t px-5 pt-5 text-center`,
  tw`xl:px-0`,
])

export const StyledMarketingFooterCopyright = styled.div(() => [
  tw`mb-3 w-full text-center text-monochrome-600`,
  tw`sm:(mb-0 w-auto text-left)`,
])

export const StyledMarketingFooterSocial = styled.div(() => [tw`m-auto`, tw`sm:(m-0 text-right)`])
