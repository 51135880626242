import { MouseEvent } from 'react'

export const toggleZenDeskWidget = (event: MouseEvent) => {
  event.preventDefault()
  const zendeskIframe = document.querySelector('#launcher') as HTMLObjectElement
  const zendeskLauncherButton = zendeskIframe?.contentDocument?.body?.querySelector(
    '[data-testid="launcher"]',
  ) as HTMLObjectElement

  const webWidgetIframe = document.querySelector('#webWidget') as HTMLObjectElement
  const zenDeskCloseButton = webWidgetIframe?.contentDocument?.body?.querySelector(
    '[data-garden-id="buttons.icon_button"]',
  ) as HTMLObjectElement

  if (zenDeskCloseButton) {
    zenDeskCloseButton.click()
  } else if (zendeskLauncherButton) {
    zendeskLauncherButton.click()
  } else {
    const a = document.createElement('a')
    a.target = '_blank'
    a.href = 'https://www.cart.com/contact'
    a.click()
  }
}
