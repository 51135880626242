import { useBreakpoint } from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useEmblaCarousel from 'embla-carousel-react'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import tw from 'twin.macro'

import image0 from '../images/gallery/image22744.png'
import image1 from '../images/gallery/image22745.png'
import image2 from '../images/gallery/image22746.png'

const images = [image0, image1, image2]

export const ImageGallery = ({ className }: { className?: string }): ReactElement => {
  const [viewportRef, embla] = useEmblaCarousel({ skipSnaps: false, loop: true })
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])
  const isMedium = useBreakpoint('md')

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])
  const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [embla])

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
  }, [embla, setSelectedIndex])

  useEffect(() => {
    if (!embla) return
    onSelect()
    setScrollSnaps(embla.scrollSnapList())
    embla.on('select', onSelect)
  }, [embla, setScrollSnaps, onSelect])

  if (!isMedium) {
    return (
      <div>
        <img src={image0} alt="" />
      </div>
    )
  }

  return (
    <div tw="m-auto hidden max-w-[925px] md:block" className={className}>
      <div tw="relative m-auto max-w-[850px]">
        <div tw="w-full overflow-hidden" ref={viewportRef}>
          <div tw="flex select-none">
            {images.map((image) => (
              <div tw="relative min-w-full" key={image}>
                <div tw="relative overflow-hidden h-[386px]">
                  <img src={image} alt="" />
                </div>
              </div>
            ))}
          </div>
        </div>
        <button
          type="button"
          tw="absolute top-1/2 -left-9 z-10 h-8 w-8 translate-y-2/4 rounded bg-white opacity-50 shadow hover:opacity-100"
          onClick={scrollPrev}
        >
          <FontAwesomeIcon tw="text-monochrome-600" icon={solid('arrow-left')} />
        </button>
        <button
          type="button"
          tw="absolute top-1/2 -right-9 z-10 h-8 w-8 translate-y-2/4 rounded bg-white opacity-50 shadow hover:opacity-100"
          onClick={scrollNext}
        >
          <FontAwesomeIcon tw="text-monochrome-600" icon={solid('chevron-right')} />
        </button>
      </div>
      <div tw="mx-auto mt-5 flex gap-4 max-w-[850px]">
        {scrollSnaps.map((scrollSnap, index) => (
          <button
            tw="justify-around shadow max-w-[32%]"
            key={scrollSnap}
            type="button"
            onClick={() => scrollTo(index)}
            css={[selectedIndex === index ? tw`rounded border-primary-700 border-2` : '']}
          >
            <img tw="h-auto max-w-full rounded" src={images[index]} alt="" />
          </button>
        ))}
      </div>
    </div>
  )
}
