import isArray from 'lodash/isArray'
import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'
import transform from 'lodash/transform'
import React from 'react'

import SelectIcon from '../assets/icons/select-icon.svg'
import {
  ConnectorType,
  DataConnectorMutationVariables,
  DataConnectorsResponse,
} from '../stores/types/dataConnectorsTypes'

const indicatorSeparatorStyle = {
  alignSelf: 'stretch',
  backgroundColor: 'unset',
  marginBottom: 8,
  marginTop: 8,
  width: 0,
}
let prevValue = ''

export const IndicatorSeparator: any = ({ innerProps }: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <span style={indicatorSeparatorStyle} {...innerProps} />
)

export const DropdownIndicator = () => <img src={SelectIcon} alt="" />

export const getObjectsDifference = (origObj: any, newObj: any) => {
  const changes = (newObject: any, origObject: any) => {
    const arrayIndexCounter = 0
    return transform(newObject, (result: any, value, key) => {
      if (!isEqual(value, origObject[key])) {
        const resultKey = isArray(origObject) ? arrayIndexCounter + 1 : key
        // eslint-disable-next-line no-param-reassign
        result[resultKey] =
          isObject(value) && isObject(origObject[key]) ? changes(value, origObject[key]) : value
      }
    })
  }
  return changes(newObj, origObj)
}

export const SliseUrlCode = (search, name) => {
  const params = new URLSearchParams(search.substring(1))
  const code = params.get(name)
  return code
}

export const getExistConnectors = (dataConnectors: DataConnectorsResponse): ConnectorType[][] => {
  const result = []
  if (dataConnectors?.data) {
    Object.keys(dataConnectors?.data).forEach((sectionName) =>
      Object.values(dataConnectors?.data[sectionName]).forEach(
        (connectors) => connectors && result.push(connectors),
      ),
    )
  }
  return result
}

export const upgradeResponse = () => {
  window.location.pathname = '/settings/unified-analytics'
}

/**
 *
 * @param error This could be of type FetchBaseQueryError or SerializedError, and we need to handle both cases.
 * @returns string
 */
export function processErrorMessage(error): string {
  if ('status' in error) {
    // you can access all properties of `FetchBaseQueryError` here
    const errMsg = 'error' in error ? error.error : JSON.stringify(error.data, null, 2)

    return errMsg
  }

  // you can access all properties of `SerializedError` here
  return error.message
}

const checkValue = (str, max) => {
  if (str.charAt(0) !== '0' || str === '00') {
    let num = parseInt(str, 10)
    if (Number.isNaN(num) || num <= 0 || num > max) num = 1
    str =
      num > parseInt(max.toString().charAt(0), 10) && num.toString().length === 1
        ? `0${num}`
        : num.toString()
  }
  return str
}

export const handleDate = (value, name, setValue) => {
  let formattedValue
  let testValue = value
  testValue += '-'
  const values = value.split('-').map((v) => {
    return v.replace(/\D/g, '')
  })
  if (values[1]) values[1] = checkValue(values[1], 12)
  if (values[2]) values[2] = checkValue(values[2], 31)
  const output = values.map((v, i) => {
    return (v.length === 4 && i === 0) || (v.length === 2 && i === 1) ? `${v}-` : v
  })
  formattedValue = output.join('').substring(0, 10)

  // if user is backspacing, handles when the user backs over a dash
  if (prevValue?.length > value.length && testValue === formattedValue) {
    formattedValue = value.slice(0, -1)
  }
  prevValue = value
  setValue(name, formattedValue)
}

export const handleErrorMsg = (values) => {
  switch (values) {
    case 'account_id':
      return 'Please enter account ID'
    case 'api_key':
      return 'Please enter API key'
    case 'apikey':
      return 'Please enter API key'
    case 'customer_id':
      return 'Please enter customer ID'
    case 'site_urls':
      return 'Please enter url'
    case 'start_date':
      return 'Please enter start date'
    case 'username':
      return 'Please enter your username'
    case 'view_id':
      return 'Please enter view ID'
    case 'shop':
      return 'Please enter a Shopify store name'
    case 'accountId':
      return 'Please enter account ID'
    case 'apiKey':
      return 'Please enter API key'
    case 'customerId':
      return 'Please enter customer ID'
    case 'siteUrls':
      return 'Please enter url'
    case 'startDate':
      return 'Please enter start date'
    case 'viewId':
      return 'Please enter view ID'
    default:
      return 'This field is required'
  }
}

export function convertConciergeTypes(connectors: DataConnectorMutationVariables[]) {
  const KNOWN_CONCIERGE_DATA_CONNECTOR_TYPES = [
    'AmazonAdvertisingDataConnector',
    'AmazonSellerPartnerDataConnector',
    'LinkedinDataConnector',
    'MicrosoftAdvertisingDataConnector',
    'PepperjamDataConnector',
    'PinterestDataConnector',
    'SearchAds360DataConnector',
    'SnapChatDataConnector',
    'TikTokDataConnector',
    'TwitterDataConnector',
  ]

  return connectors.map((dataConnector) => {
    if (KNOWN_CONCIERGE_DATA_CONNECTOR_TYPES.includes(dataConnector.type)) {
      return { id: dataConnector.id, type: 'ConciergeDataConnector' }
    }
    return dataConnector
  })
}

export function convertWordsToTitleCase(str: string): string {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}
