import { useBreakpoint } from '@cart/ui/hooks/useBreakpoint'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import React, {
  FC,
  useContext,
  useMemo,
  useRef,
} from 'react'
import { MenuItem as ReactProSidebarMenuItem } from 'react-pro-sidebar'
import { Link, UNSAFE_LocationContext } from 'react-router-dom'

import { Icon } from '../Icon/Icon'
import { menuItemClickHandlers } from './MenuItem.actions'
import { IMenuItem } from './MenuItem.types'
import { useLeftNavStore } from '../../store/left-nav.store'

export interface ItemProps {
  item: IMenuItem
  level?: number
  closeParentMenu?: () => void
}

export const MenuItem: FC<ItemProps> = ({
  item,
  level = 0,
  closeParentMenu,
}) => {
  const isReactRouterAvailable = !!useContext(UNSAFE_LocationContext)
  const isMobile = !useBreakpoint('sm')
  const { isCollapsed, setIsCollapsed } = useLeftNavStore()
  const { pathname } = window.location
  const menuRef = useRef(null)

  const { icon, onClick, title, url, newTab } = item

  const menuItemClickHandler = menuItemClickHandlers[onClick]

  const httpUrl = url?.startsWith('http') && new URL(url)
  const isExternalLink = httpUrl && httpUrl.host !== window.location.host
  const urlPath = httpUrl ? httpUrl.pathname : url
  const isHomepage = pathname === '/' && url === '/'
  const isActive = isHomepage || (urlPath !== '/' && pathname.startsWith(urlPath))

  const callOnClick = (e: React.MouseEvent) => {
    if (menuItemClickHandler) {
      e.preventDefault()
      menuItemClickHandler(e)
    } else if (isExternalLink) {
      if (!newTab) window.location.href = url
    } else if (!isReactRouterAvailable) {
      e.preventDefault()
      window.history.pushState(null, null, url)
      const event = new PopStateEvent('popstate', { state: null })
      window.dispatchEvent(event)
    }
    closeParentMenu?.()
    if (isMobile) {
      setIsCollapsed(true)
    }
  }
  const iconComp = useMemo(
    () =>
      level === 0 && (
        <Icon icon={icon as IconName} hidden={!icon} title={title} showTooltip={isCollapsed} />
      ),
    [isCollapsed, icon, level, title],
  )

  const Component = useMemo(
    () => (!isExternalLink && isReactRouterAvailable ? <Link to={url} /> : undefined),
    [isExternalLink, isReactRouterAvailable, url],
  )

  return (
    <ReactProSidebarMenuItem
      ref={menuRef}
      icon={iconComp}
      href={!onClick ? url : undefined}
      target={url && newTab ? '_blank' : undefined}
      active={isActive}
      onClick={callOnClick}
      component={Component}
      data-level={level}
    >
      {title}
    </ReactProSidebarMenuItem>
  )
}
