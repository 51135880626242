export const loadingMessages = [
  'Bending the spoon...',
  'Boldly going where no one has gone before...',
  'Calculating the airspeed velocity of an unladen swallow...',
  'Checking the gravitational constant in your locale...',
  'Computing the answer to life, the universe, and everything...',
  'Constructing additional pylons...',
  'Convincing AI not to turn evil...',
  'Cracking military-grade encryption...',
  'Dividing by zero...',
  'Downloading more RAM...',
  'Entangling superstrings...',
  'Fluxing capacitors...',
  'Following the white rabbit...',
  'I left my keys in my other loading screen...',
  'Incinerating companion cube... you monster.',
  'Keeping all the 1s and removing all the 0s...',
  'Launching roadsters...',
  'Pay no attention to the man behind the curtain...',
  'Pressing super-colliding super-button...',
  'Proving P=NP...',
  'Quantuming quantums...',
  'Ramming ramjets...',
  'Recombobulating plumbus...',
  'Recording tricorders...',
  'Rendering tesseracts...',
  'Reticulating splines...',
  'Running kessels (in 12 parsecs)...',
  'Running with scissors...',
  'Simulating traveling salesman...',
  'So, do you come here often...?',
  'Spinning violently around the y-axis...',
  'Swapping time and space...',
  'Switching to the latest JS framework...',
  'Tokenizing real life...',
  'Twiddling thumbs...',
  'What was I supposed to be doing again...?',
  "Don't panic...you brought your towel right?",
  "I'm sorry Dave, I can't do that...",
  "Whatever you do, don't look behind you...",
]
