import { useAuth0 } from '@auth0/auth0-react'

import { getMe } from '../../gql/queries/me.query'
import { useCartAuthStore } from './auth.store'
import { parseData } from './initializer.store'

export const useStoreInitializer = () => {
  const { getAccessTokenSilently } = useAuth0()
  const store = useCartAuthStore()

  const fetchInitialData = async () => {
    if (store.isLoadingInitialData) return
    store.setIsLoadingInitialData(true)
    try {
      const accessToken = await getAccessTokenSilently()
      const response = await getMe(accessToken)
      store.setInitialData(parseData(response))
    } catch {
      store.setIsLoadingInitialData(false)
    }
  }

  return { fetchInitialData, isLoaded: store.initialDataLoaded }
}
