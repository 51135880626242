import { Alert } from '@cart/ui'
import React, { ReactElement, useEffect } from 'react'
import TagManager from 'react-gtm-module'

import { OrganizationOverview } from './OrganizationOverview/OrganizationOverview'
import { OrganizationUsers } from './OrganizationUsers/OrganizationUsers'
import { useSettingsOrganizationsContext } from './SettingsOrganizations.store'
import { useCurrentContext, isOrgRole } from '@cartdotcom/auth'

export const SettingsOrganizations = (): ReactElement => {
  const { alert, clearAlert } = useSettingsOrganizationsContext()
  const { roleName } = useCurrentContext()

  const isOrgLevelUser = isOrgRole(roleName)
  const { text: alertText, variant: alertVariant } = alert || {}

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        route: 'SettingsOrganizations',
      },
    })
  }, [])

  return (
    <div tw="m-auto w-full max-w-5xl">
      {alert && (
        <Alert severity={alertVariant} onClose={clearAlert} tw="mb-6">
          {alertText}
        </Alert>
      )}

      <OrganizationOverview />
      {isOrgLevelUser && <OrganizationUsers />}
    </div>
  )
}
