import { FormControlLabel } from '@mui/material'
import MUICheckbox from '@mui/material/Checkbox'
import { nanoid } from 'nanoid'
import React, { FocusEvent, useState } from 'react'

import formMethods from '../../framework/forms/form-methods'
import { StyledMessage } from './checkbox.styles'
import { CheckboxProps } from './checkbox.types'

const CheckboxMessage = ({
  id,
  message,
  errorStatus,
  focusStatus,
}: {
  id: string
  message: string | undefined
  errorStatus: boolean
  focusStatus: boolean
}) => {
  if (message) {
    return (
      <StyledMessage
        data-haserror={errorStatus}
        data-hasfocus={focusStatus}
        data-testid="checkbox-label"
        htmlFor={id}
      >
        {message}
      </StyledMessage>
    )
  }
  return null
}

export const Checkbox = ({
  className,
  label,
  id,
  rules,
  helperText,
  onFocus,
  onBlur,
  ...props
}: CheckboxProps) => {
  const [hasFocus, setHasFocus] = useState(false)

  const {
    register,
    formState: { errors },
  } = formMethods()
  const registerField = id ? register(id, rules) : null

  const onFocusEvent = (e: FocusEvent<HTMLButtonElement>) => {
    if (onFocus) {
      onFocus(e)
    }
    setHasFocus(true)
  }

  const onBlurEvent = (e: FocusEvent<HTMLButtonElement>) => {
    if (onBlur) {
      onBlur(e)
    }
    setHasFocus(false)
  }

  let message = helperText
  let errorStatus = false
  if (id && errors[id]) {
    message = errors[id].message
    errorStatus = Boolean(message)
  }

  const $id = id || nanoid()

  if (label) {
    return (
      <>
        <FormControlLabel
          control={
            <MUICheckbox
              role="checkbox"
              id={id}
              {...props}
              {...registerField}
              onFocus={onFocusEvent}
              onBlur={onBlurEvent}
              inputProps={{
                role: 'checkbox',
              }}
            />
          }
          label={label}
        />
        <CheckboxMessage
          id={$id}
          message={message}
          errorStatus={errorStatus}
          focusStatus={hasFocus}
        />
      </>
    )
  }

  return (
    <div className={className}>
      <MUICheckbox
        id={id}
        data-testid="checkbox"
        role="checkbox"
        {...props}
        {...registerField}
        onFocus={onFocusEvent}
        onBlur={onBlurEvent}
      />
      <CheckboxMessage
        id={$id}
        message={message}
        errorStatus={errorStatus}
        focusStatus={hasFocus}
      />
    </div>
  )
}
