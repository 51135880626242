import * as Yup from 'yup'

const regexFields = {
  accountId: /^([0-9]){5,30}$/,
  apikey: '',
  apiKey: '',
  customerId: /^([0-9-]){5,19}$/,
  loginCustomerId: /^([0-9-]){5,19}$/,
  siteUrls: /^(ftp|http|https):\/\/[^ "]+$/,
  startDate: /\d{4}-\d{2}-\d{2}/,
  username: '',
  viewId: /^([0-9]){5,15}$/,
  propertyId: /^([0-9]){5,15}$/,
}

const validateFieldCommon = {
  startDate: Yup.string()
    .matches(regexFields.startDate, 'Please enter correct start date in form yyyy-mm-dd')
    .required('Please enter start date'),
  viewId: Yup.string()
    .matches(regexFields.viewId, 'Please enter correct view ID')
    .required('Please enter view ID'),
  propertyId: Yup.string()
    .matches(regexFields.propertyId, 'Please enter correct property ID')
    .required('Please enter property ID'),
  accountId: Yup.string()
    .matches(regexFields.accountId, 'Please enter correct account ID')
    .required('Please enter account ID'),
  customerId: Yup.string()
    .matches(regexFields.customerId, 'Please enter correct customer ID')
    .required('Please enter customer ID'),
  loginCustomerId: Yup.string().matches(
    regexFields.loginCustomerId,
    'Please enter correct login customer ID',
  ),
  shop: Yup.string().required('Please enter a Shopify store name'),
  apikey: Yup.string().required('Please enter API key'),
  apiKey: Yup.string().required('Please enter API key'),
  username: Yup.string().required('Please enter your username'),
  siteUrls: Yup.string()
    .matches(regexFields.siteUrls, 'Please enter correct url')
    .required('Please enter url'),
  profileId: Yup.array().required('Please enter your profile IDs seperated by commas'),
}

const createValidate = (fields) => {
  const validate = fields.reduce((acc: Record<string, unknown>, key: string) => {
    // eslint-disable-next-line no-param-reassign
    acc[key] = validateFieldCommon[key]
    return acc
  }, {})
  return Yup.object().shape(validate)
}

export const dataConnectorsFields = {
  'google-search-console': {
    title: 'Google Search Console',
    type: 'GoogleSearchConsoleDataConnector',
    validate: createValidate(['startDate', 'siteUrls']),
    fields: [
      {
        name: 'siteUrls',
        label: 'Site URL',
        placeholder: 'Enter your site URL',
        type: 'text',
      },
      {
        name: 'startDate',
        label: 'Start Date',
        placeholder: 'yyyy-mm-dd',
        type: 'text',
      },
    ],
    initialValues: {
      business: null,
      startDate: '',
      siteUrls: '',
    },
  },
  'google-analytics': {
    title: 'Google Analytics',
    type: 'GoogleAnalyticsDataConnector',
    validate: createValidate(['startDate', 'viewId']),
    fields: [
      {
        name: 'viewId',
        label: 'View ID',
        placeholder: 'Enter your view ID',
        type: 'text',
      },
      {
        name: 'startDate',
        label: 'Start Date',
        placeholder: 'yyyy-mm-dd',
        type: 'text',
      },
    ],
    initialValues: {
      business: null,
      startDate: '',
      viewId: '',
    },
  },
  'google-analytics-4': {
    title: 'Google Analytics 4',
    type: 'GoogleAnalyticsGA4DataConnector',
    validate: createValidate(['startDate', 'propertyId']),
    fields: [
      {
        name: 'propertyId',
        label: 'Property ID',
        placeholder: 'Enter your property ID',
        type: 'text',
      },
      {
        name: 'startDate',
        label: 'Start Date',
        placeholder: 'yyyy-mm-dd',
        type: 'text',
      },
    ],
    initialValues: {
      business: null,
      startDate: '',
      viewId: '',
    },
  },

  'google-adwords': {
    title: 'Google Ads',
    type: 'GoogleAdwordsDataConnector',
    validate: createValidate(['startDate', 'customerId']),
    fields: [
      {
        name: 'startDate',
        label: 'Start Date',
        placeholder: 'yyyy-mm-dd',
        type: 'text',
      },
      {
        name: 'customerId',
        label: 'Customer ID',
        placeholder: 'Enter customer ID',
        type: 'text',
      },
      {
        name: 'loginCustomerId',
        label: 'Login Customer ID',
        placeholder: 'Enter login customer ID',
        type: 'text',
      },
    ],
    initialValues: {
      business: null,
      startDate: '',
      customerId: '',
      loginCustomerId: '',
    },
  },
  facebook: {
    title: 'Facebook',
    type: 'FacebookDataConnector',
    validate: createValidate(['startDate', 'accountId']),
    fields: [
      {
        name: 'accountId',
        label: 'Account ID',
        placeholder: 'Enter account ID',
        type: 'text',
      },
      {
        name: 'startDate',
        label: 'Start Date',
        placeholder: 'yyyy-mm-dd',
        type: 'text',
      },
    ],
    initialValues: {
      business: null,
      startDate: '',
      accountId: '',
    },
  },
  'amazon-seller-central': {
    title: 'Amazon Seller Central',
    type: 'AmazonSellerCentralDataConnector',
    validate: createValidate(['startDate']),
    fields: [],
    initialValues: {
      business: null,
      startDate: '',
    },
  },
  shopify: {
    title: 'Shopify',
    type: 'ShopifyDataConnector',
    validate: createValidate(['startDate']),
    fields: [
      {
        name: 'startDate',
        label: 'Start Date',
        placeholder: 'yyyy-mm-dd',
        type: 'text',
      },
    ],
    initialValues: {
      business: null,
      startDate: '',
    },
  },
  'amazon-advertising': {
    title: 'Amazon Advertising',
    type: 'AmazonAdvertisingDataConnector',
    validate: createValidate(['startDate']),
    fields: [
      {
        name: 'startDate',
        label: 'Start Date',
        placeholder: 'yyyy-mm-dd',
        type: 'text',
      },
    ],
    initialValues: {
      business: null,
      startDate: '',
    },
  },
  klaviyo: {
    title: 'Klaviyo',
    type: 'KlaviyoDataConnector',
    validate: createValidate(['apiKey', 'startDate']),
    fields: [
      {
        name: 'apiKey',
        label: 'API key',
        placeholder: 'Enter your API key',
        type: 'text',
      },
      {
        name: 'startDate',
        label: 'Start Date',
        placeholder: 'yyyy-mm-dd',
        type: 'text',
      },
    ],
    initialValues: {
      business: null,
      apiKey: '',
      startDate: '',
    },
  },
  mailchimp: {
    title: 'MailChimp',
    type: 'MailchimpDataConnector',
    validate: createValidate(['username', 'apikey']),
    fields: [
      {
        name: 'username',
        label: 'User Name',
        placeholder: 'Enter your user name',
        type: 'text',
      },
      {
        name: 'apikey',
        label: 'API key',
        placeholder: 'Enter your API key',
        type: 'text',
      },
    ],
    initialValues: {
      username: '',
      apikey: '',
      business: null,
    },
  },
  tiktok: {
    title: 'TikTok',
    type: 'TikTokDataConnector',
    validate: createValidate(['startDate']),
    fields: [
      {
        name: 'startDate',
        label: 'Start Date',
        placeholder: 'yyyy-mm-dd',
        type: 'text',
      },
      {
        name: 'advertiserId',
        label: 'Advertiser ID',
        placeholder: 'Enter your Advertiser ID',
        type: 'text',
      },
    ],
    initialValues: {
      business: null,
      startDate: '',
      advertiserId: '',
    },
  },
  hubspot: {
    title: 'HubSpot',
    type: 'HubspotDataConnector',
    validate: null,
    fields: [
      {
        name: 'apiKey',
        label: 'API key',
        placeholder: 'Enter your API key',
        type: 'text',
      },
      {
        name: 'startDate',
        label: 'Start Date',
        placeholder: 'yyyy-mm-dd',
        type: 'text',
      },
    ],
    initialValues: {
      apiKey: '',
      startDate: '',
      business: null,
    },
  },
  storefront: {
    title: 'StoreFront',
    type: 'StorefrontDataConnector',
    validate: null,
    fields: [],
    initialValues: {
      business: null,
    },
  },
  searchmetrics: {
    title: 'Searchmetrics',
    type: 'ConciergeDataConnector',
    validate: null,
    fields: [],
    initialValues: {
      store: null,
    },
  },
  'microsoft-advertising': {
    title: 'Microsoft Advertising',
    type: 'MicrosoftAdvertisingDataConnector',
    validate: null,
    fields: [],
    initialValues: {
      store: null,
    },
  },
  'tiktok-ads': {
    title: 'TikTok Ads',
    type: 'TikTokDataConnector',
    validate: null,
    fields: [],
    initialValues: {
      store: null,
    },
  },
  'linkedin-ads': {
    title: 'LinkedIn Ads',
    type: 'LinkedinDataConnector',
    validate: null,
    fields: [],
    initialValues: {
      store: null,
    },
  },
  'snapchat-ads': {
    title: 'Snapchat Ads',
    type: 'SnapChatDataConnector',
    validate: null,
    fields: [],
    initialValues: {
      store: null,
    },
  },
  'pinterest-ads': {
    title: 'Pinterest Ads',
    type: 'PinterestDataConnector',
    validate: null,
    fields: [],
    initialValues: {
      store: null,
    },
  },
  'search-ads-360': {
    title: 'Search Ads 360',
    type: 'SearchAds360DataConnector',
    validate: null,
    fields: [],
    initialValues: {
      store: null,
    },
  },
  'twitter-ads': {
    title: 'Twitter Ads',
    type: 'TwitterDataConnector',
    validate: null,
    fields: [],
    initialValues: {
      store: null,
    },
  },
}
