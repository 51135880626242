import { useRouter } from '@cart/ui'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import tw from 'twin.macro'

export const StickyHeader = ({
  children,
  baseTopPosition = 280, // The base position for the header, in distance from 'top'.
  stickyTopPosition = 78, // The distance from the top of the viewport when sticky.
  overviewOnClick,
  offeringsOnClick,
  requestOnClick,
}: {
  children: ReactNode
  baseTopPosition?: number
  stickyTopPosition?: number
  overviewOnClick: () => void
  offeringsOnClick: () => void
  requestOnClick: () => void
}): ReactElement => {
  const [isHeaderSticky, setIsHeaderSticky] = useState(
    window.scrollY > baseTopPosition - stickyTopPosition,
  )
  const { pathname } = useRouter()

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      if (scrollPosition > baseTopPosition - stickyTopPosition) {
        setIsHeaderSticky(true)
      } else {
        setIsHeaderSticky(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnClick = (func: () => void) => {
    func()
  }

  return (
    <nav
      tw="left-2 right-2 z-50 mx-auto flex items-center justify-center whitespace-nowrap rounded-lg bg-white py-3.5 font-bold shadow max-w-[912px] mobile:mt-4"
      css={[isHeaderSticky ? tw`sticky mobile:mt-0` : tw`absolute`]}
      style={{
        top: isHeaderSticky ? stickyTopPosition : baseTopPosition,
      }}
    >
      <span
        tw="[transition: all 400ms cubic-bezier(0.215, 0.61, 0.355, 1)] hidden w-0 items-center justify-end border-r-2 border-r-monochrome-500 p-0 opacity-0 max-w-[fit-content] md:flex"
        css={[isHeaderSticky && tw`w-1/2 opacity-100`]}
      >
        {children}
      </span>
      <div>
        <a
          tw="py-2 px-4"
          href={`${pathname}#overview`}
          onClick={(event) => {
            event.preventDefault()
            handleOnClick(overviewOnClick)
          }}
        >
          Overview
        </a>
        <a
          tw="py-2 px-4"
          href={`${pathname}#offerings`}
          onClick={(event) => {
            event.preventDefault()
            handleOnClick(offeringsOnClick)
          }}
        >
          Offerings
        </a>
        <a
          tw="py-2 px-4"
          href={`${pathname}#request`}
          onClick={(event) => {
            event.preventDefault()
            handleOnClick(requestOnClick)
          }}
        >
          Request
        </a>
      </div>
    </nav>
  )
}
