import tw, { styled } from 'twin.macro'

import { DeprecatedBadgeProps } from './deprecated-badge.types'

const handleBadgeColor = (type = 'default') => {
  switch (type) {
    case 'primary':
      return tw`bg-primary-100 text-primary-900`
    case 'success':
      return tw`bg-accent-three-50 text-accent-three-900`
    case 'warning':
      return tw`bg-secondary-50 text-secondary-900`
    case 'error':
      return tw`bg-accent-two-50 text-accent-two-900`
    case 'info':
      return tw`bg-accent-five-50 text-accent-five-900`
    case 'emphasis':
      return tw`bg-accent-four-50 text-accent-four-900`
    case 'muted':
      return tw`bg-monochrome-500 text-monochrome-600`
    default:
      return tw`bg-monochrome-300 text-monochrome-700`
  }
}

export const StyledBadge = styled.span(({ size, text, type }: DeprecatedBadgeProps) => [
  !!text && tw`rounded capitalize`,
  !!text && size === 'medium' && tw`px-2.5 py-1 text-xs [line-height:normal]`,
  !!text && size === 'small' && tw`px-1.5 py-1 align-bottom text-xs leading-none`,
  tw`inline-flex cursor-default select-none items-center align-middle`,
  handleBadgeColor(type),
  !text && tw`bg-transparent`,
])

export const StyledDot = styled.span(({ text, type }: DeprecatedBadgeProps) => [
  text && tw`pr-1.5`,
  type === 'primary' && tw`text-primary-700`,
  type === 'success' && tw`text-accent-three-700`,
  type === 'warning' && tw`text-secondary-700`,
  type === 'error' && tw`text-accent-two-700`,
  type === 'info' && tw`text-accent-five-700`,
  type === 'emphasis' && tw`text-accent-four-700`,
  type === 'muted' && tw`text-monochrome-600`,
])
