import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'

import { SideMenuTenant } from '../../../gql/graphql'
import { useUnifiedSidebarStore } from '../../store/unified-sidebar.store'
import { StyledTenantButton } from './TenantInfo.styles'

export const TenantOptions = ({
  changeTenant,
  filter = '',
}: {
  changeTenant: (tenant: SideMenuTenant, isJazzTenant: boolean) => void
  filter?: string
}) => {
  const { currentTenant, tenantsList } = useUnifiedSidebarStore()
  const [visibleTenants, setVisibleTenants] = useState<number>(1)
  const tenantsListRef = useRef<HTMLUListElement>(null)

  const isActiveTenant = (tenant: SideMenuTenant) => {
    if (currentTenant.nodes?.length > 0) {
      return currentTenant.nodes?.some(
        (node) =>
          (node.tenantCode && node.tenantCode === tenant.tenantCode) ||
          (node.businessId && node.businessId === tenant.businessId) ||
          (node.organizationId && node.organizationId === tenant.organizationId),
      )
    }
    return (
      (tenant.tenantCode && tenant.tenantCode === currentTenant.tenantCode) ||
      (tenant.businessId && tenant.businessId === currentTenant.businessId) ||
      (tenant.organizationId && tenant.organizationId === currentTenant.organizationId)
    )
  }

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (tenantsListRef.current?.children.length !== visibleTenants) {
        setVisibleTenants(tenantsListRef.current?.children.length ?? 0)
      }
    })
    const config = { subtree: true, childList: true }
    observer.observe(document, config)
    return () => observer.disconnect()
  }, [visibleTenants])

  return (
    <div>
      <ul ref={tenantsListRef} tw="m-0 list-none pl-0">
        {tenantsList.map((tenant) => {
          const isFiltered = tenant.name.toLowerCase().includes(filter)
          const hasFilteredNodes = tenant.nodes?.filter((node) =>
            node.name.toLowerCase().includes(filter),
          ).length

          tenant.nodes?.sort((a, b) =>
            a.name?.trim().toLowerCase().localeCompare(b.name?.trim().toLowerCase()),
          )

          // TODO: This is how we identify if a tenant is a Jazz tenant, which allows the tenant to be clicked.
          // Revise this logic when the integration with the Org Service is complete.
          const isJazzTenant = !!tenant.tenantCode

          return hasFilteredNodes || isFiltered ? (
            <div
              key={`${tenant.tenantCode}-${tenant.organizationId}-${tenant.businessId}`}
              tw="grid"
            >
              {isJazzTenant && (
                <StyledTenantButton
                  type="button"
                  onClick={() => changeTenant(tenant, isJazzTenant)}
                >
                  <span tw="overflow-hidden text-ellipsis pr-2">{tenant.name}</span>
                  {isActiveTenant(tenant) && (
                    <FontAwesomeIcon icon={faCircleCheck} tw="text-monochrome-300" />
                  )}
                </StyledTenantButton>
              )}
              {!isJazzTenant && (
                <div tw="w-full cursor-default items-center justify-between overflow-hidden text-ellipsis rounded px-2 py-3 text-left font-sans text-sm leading-none antialiased">
                  {tenant.name}
                </div>
              )}
              {tenant.nodes?.map((node) => {
                return isFiltered || node.name.toLowerCase().includes(filter) ? (
                  <StyledTenantButton
                    key={`${tenant.tenantCode}${node.tenantCode}${node.name}`}
                    type="button"
                    tw="!pl-7"
                    title={`${node.name} (${node.tenantCode || node.businessId})`}
                    onClick={() => changeTenant(node, isJazzTenant)}
                  >
                    <span tw="overflow-hidden text-ellipsis pr-2">{node.name}</span>
                    {isActiveTenant(node) && (
                      <FontAwesomeIcon icon={faCircleCheck} tw="text-monochrome-300" />
                    )}
                  </StyledTenantButton>
                ) : null
              })}
            </div>
          ) : null
        })}
      </ul>
      {visibleTenants === 0 && <div tw="text-sm text-monochrome-700">No results found</div>}
    </div>
  )
}
