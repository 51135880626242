import { useDefaultInfo } from '@cart/ui'
import { useCartAuth, useCurrentContext } from '@cartdotcom/auth'
import { usePermissionCheck } from '../../../hooks/usePermissionCheck'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useEffectOnceWhen } from 'rooks'
import { AccountConfirmFormProps, UseAccountConfirmFormProps } from '../AccountConfirmForm.types'
import { useAccountConfirmFormSubmit } from './useAccountConfirmFormSubmit'
import { validatePhone } from '@brand-console/utilities'

export const useAccountConfirmForm = ({ methods, log }: UseAccountConfirmFormProps) => {
  const [isFormLoading, setIsFormLoading] = useState(true)
  const [shouldShowForm, setShouldShowForm] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [, setRerenderKey] = useState(0)
  const { currentBusiness, currentOrganization, isLoaded } = useCurrentContext()
  const { phone, firstName, lastName } = useCartAuth()
  const { access: canUpdateBiz } = usePermissionCheck(
    'updateBusiness',
    currentBusiness?.id,
    currentOrganization?.id,
  )
  const { seTempEnableLiteRegistration230404 } = useFlags()
  const {
    business: { name: defaultBizName },
  } = useDefaultInfo()

  const submitForm = useAccountConfirmFormSubmit({
    methods,
    log,
    setErrorMessage,
  })

  const { trigger, reset, getValues, watch } = methods

  useEffect(() => {
    const getShouldShowForm = async () => {
      if (!seTempEnableLiteRegistration230404) setShouldShowForm(false)
      else if (
        !currentBusiness?.name ||
        currentBusiness?.name === defaultBizName ||
        !firstName ||
        !lastName ||
        !phone ||
        !(await validatePhone(phone))
      )
        setShouldShowForm(true)
      else setShouldShowForm(false)

      setIsFormLoading(false)
    }

    if (isLoaded) getShouldShowForm()
  }, [isLoaded])

  const resetForm = useCallback(() => {
    reset()
    setTimeout(() => phone && trigger('phone'), 10)
  }, [phone, reset, trigger])

  // Users may have an invalid phone number, so we need to show the error message
  useEffect(() => {
    if (!getValues('phone')) return
    setTimeout(() => {
    trigger('phone')
    }, 1)
  }, [getValues, trigger, shouldShowForm])

  useEffectOnceWhen(() => {
    // Need rerender once form is on the page to trigger validation
    setRerenderKey((prev) => prev + 1)
  }, shouldShowForm)

  return useMemo(
    () => ({
      submitForm,
      errorMessage,
      methods,
      formDefaultValues: {
        firstName,
        lastName,
        businessName: currentBusiness?.name === defaultBizName ? '' : currentBusiness?.name,
        phone: phone || '',
      },
      updateBizDisabled: !canUpdateBiz,
      shouldShowForm,
      isFormLoading,
      resetForm,
      setErrorMessage,
    }),
    [
      canUpdateBiz,
      currentBusiness?.name,
      currentOrganization?.name,
      defaultBizName,
      errorMessage,
      firstName,
      lastName,
      methods,
      phone,
      resetForm,
      shouldShowForm,
      submitForm,
    ],
  )
}
