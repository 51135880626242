import React from 'react'
import { createGlobalStyle, CSSObject } from 'styled-components'
import tw, { globalStyles } from 'twin.macro'

import { convertCssObjectToString, filterKeyContainingString } from './GlobalStyles.functions'

const filteredJson = filterKeyContainingString(globalStyles, "[type='button']")
const styles = convertCssObjectToString(filteredJson as CSSObject)

const CustomGlobalStyles = createGlobalStyle`${styles}`
const rootFontSize = 16

const CustomStyles = createGlobalStyle`
  html {
    font-size: ${rootFontSize}px;
    & body {
      ${tw`contents h-screen overflow-auto font-sans`}
      font-size: ${rootFontSize}px;
      min-width: 320px;
      ${tw`text-monochrome-900 antialiased selection:(bg-primary-300 text-white)`}
    }
    h1, h2, h3, h4, h5, h6 {
      ${tw`font-heading`}
    }
    & h1 {
      font-size: calc(1.4107rem + 1.9284vw);
    }
    & h2 {
      font-size: calc(1.29642rem + 0.55704vw)
    }
    & h3 {
      font-size: 1.2857rem;
      font-size: calc(1.25357rem + 0.04284vw);
    }

  }
  @media (min-width: 420px) {
    html {
      font-size: ${rootFontSize}px;
      & h1 {
        font-size: 2.857rem;
      }
      & h2 {
        font-size: 1.7142rem;
      }
      & h3 {
        font-size: 1.2857rem;
      }
    }
  }
  button {
    background-color: transparent;
  }
  @media print {
    body {
      ${tw`h-auto`}
    }
  }
  // Hubspot
  iframe#launcher {
    z-index: 40 !important;
  }
  .scrollable-mask {
    ${tw`overflow-auto`}
    --g: radial-gradient(75% 10px, #0000004a, #0000);
      background: linear-gradient(#fff 10px, #0000 40px calc(100% - 40px), #fff calc(100% - 10px))
          local,
        var(--g) top / 100% 200%, var(--g) bottom/100% 200%;
  }
`

const GlobalStyles = () => (
  <>
    <CustomGlobalStyles />
    <CustomStyles />
  </>
)

export default GlobalStyles
