import { logger } from '@brand-console/utilities'
import React, { FC, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { AccountConfirmFormContext } from './AccountConfirmForm.store'
import { useAccountConfirmForm } from '../hooks/useAccountConfirmForm'
import { AccountConfirmFormProviderProps } from './AccountConfirmFormProvider.types'

/**
 * Allows for the use of the account form state.
 */
export const AccountConfirmFormProvider: FC<AccountConfirmFormProviderProps> = ({ children, ...props }) => {
  return 'value' in props ? (
    <AccountConfirmFormProviderInner {...props}>{children}</AccountConfirmFormProviderInner>
  ) : (
    <AccountConfirmFormProviderUsingForm>{children}</AccountConfirmFormProviderUsingForm>
  )
}

const AccountConfirmFormProviderUsingForm = ({ children }) => {
  const log = useMemo(() => logger.setLogger(logger.LoggerNames.ORGANIZATION), [])
  const methods = useForm({ mode: 'onChange' })
  const formData = useAccountConfirmForm({ methods, log })

  return AccountConfirmFormProviderInner({ children, methods, value: formData })
}

const AccountConfirmFormProviderInner = ({ children, methods, value }) => {
  return (
    <AccountConfirmFormContext.Provider value={value}>
      <FormProvider {...methods}>{children}</FormProvider>
    </AccountConfirmFormContext.Provider>
  )
}
