export const getRptMenus = async (token: string): Promise<[]> => {
  const response = await fetch(`${process.env.NX_REPORTING_API_URL}/menus`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })

  if (!response.ok) {
    throw new Error(`Error: ${response.statusText}`)
  }

  const data = await response.json()

  return data
}
