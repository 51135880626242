import React, { FC } from 'react'
import { StyleSheetManager } from 'styled-components'

import { Spinner } from '../spinner/spinner'
import { StyledButton, StyledIcon } from './deprecated-button.styles'
import { ButtonProps } from './deprecated-button.types'

export const DeprecatedButton: FC<ButtonProps & Record<string, unknown>> = ({
  behavior = 'default',
  disabled,
  icon,
  iconPosition = 'left',
  onClick,
  renderRoot,
  size = 'medium',
  text,
  type = 'button',
  variant = 'primary',
  busy = false,
  ...props
}: ButtonProps) => {
  const { as } = props
  const shouldRenderType = !as || as === 'button'
  return (
    <StyleSheetManager target={renderRoot}>
      <StyledButton
        behavior={behavior}
        disabled={disabled || busy}
        data-testid="button"
        onClick={onClick}
        size={size}
        {...(shouldRenderType ? { type } : {})}
        variant={variant}
        {...props}
      >
        {busy && <Spinner type="global" logo={false} tw="p-[5%] ml-[-5%] mr-[-5%]" />}
        {icon && iconPosition === 'left' && (
          <StyledIcon iconPosition={iconPosition}>{icon}</StyledIcon>
        )}
        {text}
        {icon && iconPosition === 'right' && (
          <StyledIcon iconPosition={iconPosition}>{icon}</StyledIcon>
        )}
      </StyledButton>
    </StyleSheetManager>
  )
}
