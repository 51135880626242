import { useEffect, useState } from 'react'

import { isMcmEnabled } from '../../gql/queries/productInstance.query'
import { useAuthToken } from './useAuthToken'

export const useMcmEnabled = (businessId: number) => {
  const token = useAuthToken()
  const [mcmEnabled, setMcmEnabled] = useState<boolean | null>(null)

  useEffect(() => {
    const getMcmEnabled = async () => {
      const isMcmEnabledResponse = await isMcmEnabled(token, businessId)
      setMcmEnabled(isMcmEnabledResponse)
    }
    if (token && businessId) getMcmEnabled()
  }, [token, businessId])

  return mcmEnabled
}
