import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { type IAuthHeaderState, createAuthHeaderSlice } from './authHeaderSlice'
import { type IBusinessState, createBusinessSlice } from './businessSlice'
import { type IGlobalState, createGlobalSlice } from './globalSlice'
import { type IMenuState, createMenuSlice } from './menuSlice'
import { type ITenantState, createTenantSlice } from './tenantSlice'
import { type IUserState, createUserSlice } from './userSlice'

export type StoreStateType = IMenuState &
  IAuthHeaderState &
  IBusinessState &
  IGlobalState &
  ITenantState &
  IUserState

export const useUnifiedSidebarStore = create<StoreStateType>()(
  devtools(
    persist(
      (...a) => ({
        ...createAuthHeaderSlice(...a),
        ...createBusinessSlice(...a),
        ...createGlobalSlice(...a),
        ...createMenuSlice(...a),
        ...createTenantSlice(...a),
        ...createUserSlice(...a),
      }),
      { name: 'usb-data' },
    ),
    {
      name: 'Unified Sidebar Store',
    },
  ),
)
