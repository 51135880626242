import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import tw, { styled } from 'twin.macro'

import { DeprecatedChipProps } from './deprecated-chip.types'

export const StyledChip = styled.span(({ size }: DeprecatedChipProps) => [
  tw`rounded`,
  size === 'medium' && tw`px-3.5 py-2 text-sm`,
  size === 'small' && tw`px-2.5 py-1 text-sm`,
  tw`inline-flex cursor-default select-none items-center whitespace-nowrap align-middle`,
  tw`bg-primary-100 text-primary-700`,
])

export const StyledEmphasisText = styled.span(() => [tw`font-bold`])

export const StyledDismissWrapper = styled.div(() => [tw`ml-auto pl-3`])

export const StyledDismiss = styled.div(() => [tw`block leading-none`])

export const StyledDismissButton = styled.button(() => [tw`inline-flex focus:outline-none`])

export const StyledDismissButtonSrOnly = styled.span(() => [tw`sr-only`])

export const StyledCloseIcon = styled(FontAwesomeIcon)(() => [tw`mt-0.5 text-lg leading-none`])
