import { Business, Organization } from '../../common.types'

export const setCurrentOrgAndBusiness = (
  organization: Organization,
  business: Business,
  organizations: Organization[],
) => {
  let found = false
  const updatedOrganizations = organizations.map((existingOrganization) => {
    if (existingOrganization.id === organization.id) {
      found = true
      return organization
    }
    return existingOrganization
  })

  if (!found) {
    updatedOrganizations.push(organization)
  }
  return {
    organizations: updatedOrganizations,
    currentOrganization: organization,
    currentBusiness: business,
    roleName:
      organization?.roleName || business?.roleName || organization.businesses[0]?.roleName || null,
  }
}
