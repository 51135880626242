import { useRouter } from '@cart/ui'
import { useCartAuth } from '@cartdotcom/auth'

export const Logout = () => {
  const { onLogout } = useCartAuth()
  const { query } = useRouter()

  let returnUrl = window.location.origin
  if (query?.app === 'dfw') {
    returnUrl = process.env.NX_DFW_LOGIN_URL
  }

  if (query?.app === 'mcm') {
    returnUrl = `${process.env.NX_MCM_ORIGIN}/login`
  }

  onLogout({ returnUrl })
  return null
}
