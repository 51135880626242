import { type IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { icons } from './icons'

export const getIcon = (iconName: string, menuItemTitle: string) => {
  if (!iconName) {
    return null
  }

  const isFontAwesome = iconName.startsWith('fa')
  if (isFontAwesome) {
    return <FontAwesomeIcon title={menuItemTitle} icon={icons[iconName] as IconProp} />
  }
  const Element = icons[iconName] as React.ElementType

  if (!Element) {
    return null
  }

  return (
    <span tw="w-full">
      <Element />
    </span>
  )
}
