import { ApolloError } from '@apollo/client'
import {
  activeBizVar,
  activeOrgVar,
  InstanceType,
  InviteStatusEnum,
  useConsumeUserInviteMutation,
  useOrgProductInstancesLazyQuery,
  useOrgSelectUserInvitesQuery,
} from '@brand-console/generated-graphql-hooks'
import {
  localStorageHelper,
  LocalStorageKeys,
  logger,
} from '@brand-console/utilities'
import { Alert, Button, CartLogo, Footer, Spinner, useRouter } from '@cart/ui'
import { useCartAuth, useCurrentContext } from '@cartdotcom/auth'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import groupBy from 'lodash/groupBy'
import Pluralize from 'pluralize'
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import TagManager from 'react-gtm-module'
import { createGlobalStyle } from 'styled-components'
import tw from 'twin.macro'

import CreateOrganizationAutomated from '../OrganizationsRoutes/CreateOrganizationAutomated'
import { OrgCard } from './OragnizationSelectionRoute.styles'

interface PendingInvites {
  businessName?: string
  inviteStatus?: string
  inviteToken: string
  organizationName?: string
}

const CustomStyles = createGlobalStyle`
  .Header,
  .Left-Nav {
    display: none;
  }
  .Body {
    overflow: hidden !important;
  }
`

type AlertMessage = {
  message: string
  type: 'success' | 'info' | 'warning' | 'error'
}

export const OrganizationSelectionRoute = (): ReactElement => {
  const log = useMemo(() => logger.setLogger(logger.LoggerNames.ORGANIZATION), [])
  const {
    navigate,
    pathname,
    location,
    query: { businessId },
  } = useRouter()
  const { onLogout, organizations, email } = useCartAuth()
  const { currentOrganization, currentBusiness, setContext, isLoaded } = useCurrentContext()
  const isOrgSelectionPath = pathname === '/organization-selection'
  const isMcm = location.search.includes('nav=mcm')

  useEffect(() => {
    if (isOrgSelectionPath) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'pageview',
          route: 'ListOrganizations',
        },
      })
    }
  }, [isOrgSelectionPath])

  const [pendingInvites, setPendingInvites] = useState<PendingInvites[] | null>(null)
  const [alertMessage, setAlertMessage] = useState<AlertMessage>()
  const [inviteOrgBusinessName, setInviteOrgBusinessName] = useState<string>()
  const [showOrgList, setShowOrgList] = useState<boolean>(false)
  const [shouldCreateOrg, setShouldCreateOrg] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [error, setError] = useState<Error | null>(null)

  const [getProductInstances] = useOrgProductInstancesLazyQuery()

  const { loading } = useOrgSelectUserInvitesQuery({
    variables: {
      input: { status: InviteStatusEnum.PENDING },
    },
    onCompleted: (data) => {
      setPendingInvites(data.org_userInviteMe)
    },
    onError(orgListError: ApolloError) {
      log.error('Organization list error', { email }, orgListError)

      setErrorMessage(
        'Something went wrong when attempting to query your organizations. Please contact an administrator for help resolving this error.',
      )
      setError(orgListError)
    },
  })

  const checkForMcmRedirect = async (organizationId, defaultBusinessId) => {
    const data = await getProductInstances({
      variables: { input: { organizationId, instanceType: InstanceType.MCM } },
    })
    const productInstances = data?.data?.org_productInstanceOrgList
    if (productInstances?.length > 0) {
      const mcmBusinessIds = productInstances.map((instance) => instance.businessId).sort()
      const businessIds = organizations
        .find((org) => org.id === organizationId)
        .businesses.filter((b) => mcmBusinessIds.includes(b.id))
        .map((b) => b.id)
      if (!businessIds.length) return false
      const redirectBusinessId = businessIds.includes(defaultBusinessId)
        ? defaultBusinessId
        : businessIds[0]
      window.location.href = `${process.env.NX_MCM_SSO_URL}?businessId=${redirectBusinessId}`
      return true
    }
    return false
  }

  const handleOrgSelect = useCallback(
    async ({ organization, shouldRedirect, shouldSetContext }) => {
      const selectedOrg = { ...organization }
      localStorageHelper.set(LocalStorageKeys.ORGANIZATION, selectedOrg)
      const activeBusiness = selectedOrg.businesses[0]
      localStorageHelper.set(LocalStorageKeys.BUSINESS, activeBusiness)
      if (shouldSetContext) {
        await setContext(selectedOrg.id, activeBusiness.id)
      }
      activeOrgVar(selectedOrg)
      activeBizVar(activeBusiness)
      if (isOrgSelectionPath) {
        if (!isMcm) {
          navigate('/')
        } else if (!(await checkForMcmRedirect(selectedOrg.id, activeBusiness.id))) {
          window.location.href = '/'
        }
      }
      if (shouldRedirect) {
        window.location.reload()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setContext, isOrgSelectionPath, isMcm],
  )

  useEffect(() => {
    if (pendingInvites && isLoaded) {
      let showList = true

      if (organizations.length === 0 && pendingInvites.length === 0 && !isOrgSelectionPath) {
        showList = false
        setShouldCreateOrg(true)
      }

      if (organizations.length === 1 && pendingInvites.length === 0) {
        showList = false
        handleOrgSelect({
          organization: organizations[0],
          shouldRedirect: isOrgSelectionPath,
          shouldSetContext: currentOrganization?.id !== organizations[0].id,
        })
      } else if (isOrgSelectionPath || pendingInvites.length > 0) {
        showList = true
      } else if ((businessId || currentBusiness) && organizations?.length > 0) {
        const organization = organizations?.find((o) =>
          o.businesses.find((b) => b.id === Number(businessId || currentBusiness?.id)),
        )
        if (organization) {
          showList = false
          handleOrgSelect({
            organization,
            shouldRedirect: !!businessId,
            shouldSetContext: !!businessId,
          })
        }
      }

      if (showList) {
        setShowOrgList(true)
      }

      setErrorMessage(null)
      setError(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOrgSelectionPath, pendingInvites, currentBusiness, isLoaded])

  const [consumeInviteFunction, { loading: isConsumingInvite }] = useConsumeUserInviteMutation({
    onCompleted: async () => {
      if (organizations.length > 0) {
        setAlertMessage({
          type: 'success',
          message: `You've accepted the invite to join ${inviteOrgBusinessName}.`,
        })
      }
      window.location.reload()
    },
    onError($error) {
      if ($error.message === 'Invite has already been consumed') {
        setAlertMessage({
          message: 'You have already accepted this invite.',
          type: 'error',
        })
      }
    },
  })

  const signOut = () => {
    onLogout({ returnUrl: window.location.origin })
    return null
  }

  if (shouldCreateOrg) {
    return <CreateOrganizationAutomated />
  }
  if (loading || !showOrgList) {
    return <Spinner type="global" tw="m-auto h-64 w-64" />
  }

  return (
    <>
      <CustomStyles />
      <header tw="relative z-10 flex justify-between bg-white p-4 shadow-lg">
        <CartLogo tw="w-20 [height:38px]" />
        <Button variant="outlined" onClick={signOut}>
          Sign Out
        </Button>
      </header>
      <main tw="h-full grow bg-white px-3 z-[9]">
        <div tw="m-auto w-full max-w-5xl pt-10 sm:pt-10">
          {alertMessage && (
            <Alert
              severity={alertMessage.type}
              tw="mb-5 sm:mb-14"
              onClose={() => setAlertMessage(undefined)}
            >
              {alertMessage.message}
            </Alert>
          )}
          {pendingInvites && pendingInvites.length !== 0 && (
            <div tw="mb-12">
              <h2 tw="mb-4 font-heading text-2xl">
                {pendingInvites.length} Pending {Pluralize('Invite', pendingInvites.length)}
              </h2>
              <div>
                {pendingInvites?.length > 0 && (
                  <p tw="text-base">
                    You have been invited to join the following{' '}
                    {Pluralize(
                      'organization',
                      Object.keys(groupBy(pendingInvites, 'organizationName')).length,
                    )}
                    :
                  </p>
                )}
                {pendingInvites.map((invite) => {
                  const { businessName, inviteToken, organizationName, inviteStatus } = invite
                  return (
                    <div key={inviteToken} tw="mb-4 flex items-center justify-between">
                      {organizationName} {businessName && `- ${businessName}`} ({inviteStatus})
                      <Button
                        loading={isConsumingInvite}
                        variant="contained"
                        onClick={() => {
                          setAlertMessage(undefined)
                          setInviteOrgBusinessName(
                            `${organizationName} ${businessName ? `- ${businessName}` : ''}`,
                          )
                          consumeInviteFunction({
                            variables: {
                              consumeInviteUserInput: {
                                inviteToken,
                              },
                            },
                          })
                        }}
                      >
                        Accept
                      </Button>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
          <h1 tw="mb-4 text-4xl">Organizations</h1>
          {organizations.length > 0 && (
            <p tw="mb-8 font-sans text-base">
              Select an organization to manage your products and services.
            </p>
          )}
          <div tw="mb-12 grid [column-gap:32px] [row-gap:24px] sm:grid-cols-2 lg:grid-cols-3 mobile:grid-cols-1">
            {organizations.map((organization) => {
              return (
                <OrgCard
                  onClick={() =>
                    handleOrgSelect({ organization, shouldRedirect: false, shouldSetContext: true })
                  }
                  key={organization.id}
                  cardContentProps={{ style: tw`p-0` }}
                  variant="outlined"
                >
                  <div
                    title={organization.name}
                    tw="w-full overflow-hidden text-ellipsis whitespace-nowrap px-14 py-20 font-bold font-heading text-xl [overflow-wrap:anywhere]"
                  >
                    {organization.name}
                  </div>
                </OrgCard>
              )
            })}
          </div>
        </div>
        {(error || errorMessage) && (
          <>
            <FontAwesomeIcon
              icon={solid('exclamation-triangle')}
              tw="mx-auto mb-6 block h-20 w-20 text-accent-two-700"
            />
            <p tw="mx-auto mb-6 max-w-2xl text-center font-bold text-2xl text-accent-two-700">
              {errorMessage || 'Something went wrong.'}
            </p>
            <p tw="mb-2 text-center text-accent-two-700">
              {error && <code>{JSON.stringify(error)}</code>}
            </p>
            <div tw="mx-auto w-40 pt-8">
              <Button variant="contained" href="/">
                Return to Home
              </Button>
            </div>
          </>
        )}
      </main>
      {!isOrgSelectionPath && <Footer />}
    </>
  )
}
