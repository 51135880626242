import { StateCreator } from 'zustand'

import { resetters } from './storeResetter'
import { type StoreStateType } from './unified-sidebar.store'

export interface IAuthHeaderState {
  authHeader: string
  setAuthHeader: (authHeader: string) => void
}

const defaultMenuState = {
  authHeader: undefined,
}

export const createAuthHeaderSlice: StateCreator<
  StoreStateType,
  [['zustand/devtools', never]],
  [],
  IAuthHeaderState
> = (set) => {
  resetters.push(() => set(defaultMenuState, false, 'resetAuthHeaderState'))
  return {
    ...defaultMenuState,
    setAuthHeader: (authHeader) => set({ authHeader }, false, 'setAuthHeader'),
  }
}
