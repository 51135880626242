import { useDfwNavigation, useLayoutStore, useMcmNavigation, useRptNavigation, useSfNavigation } from '@brand-console/utilities'
import {
  FeedMarketingIcon,
  FulfillmentIcon,
  MarketplaceManagementIcon,
  StorefrontIcon,
  UnifiedAnalyticsIcon,
  useAppContext,
  useRouter,
} from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { Shortcut } from './Shortcut'

export const Shortcuts = () => {
  // using context instead of the org service because the BusinessServices object does not yet get updated for all services
  const { mcmEnabled, unifiedAnalyticsProvisioned, sfAccount, dfwAccount } = useAppContext()
  const { navigate } = useRouter()
  const { fulfillmentProvisioned } = useLayoutStore()
  const mcmNavigation = useMcmNavigation()
  const sfNavigation = useSfNavigation(sfAccount)
  const dfwNavigation = useDfwNavigation()
  const rptNavigation = useRptNavigation()

  return (
    <>
      <h2 tw="mb-6 text-2xl">Shortcuts</h2>

      <div tw="grid grid-cols-2 gap-5 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
        {mcmEnabled && (
          <Shortcut
            icon={<MarketplaceManagementIcon />}
            title="Marketplace Management"
            description="Open App"
            onClick={mcmNavigation.onClick}
          />
        )}

        {unifiedAnalyticsProvisioned && (
          <>
            <Shortcut
              icon={<UnifiedAnalyticsIcon />}
              title="Reporting"
              description="Open App"
              onClick={rptNavigation.onClick}
            />
          </>
        )}

        {sfAccount?.provisioned && (
          <Shortcut
            icon={<StorefrontIcon />}
            title="Storefront"
            description="Open App"
            onClick={() => sfNavigation.onClick ? sfNavigation.onClick() : navigate(sfNavigation.link)}
          />
        )}

        {dfwAccount?.provisioned && (
          <Shortcut
            icon={<FeedMarketingIcon />}
            title="Feed Marketing"
            description="Open App"
            onClick={dfwNavigation.onClick}
          />
        )}

        {fulfillmentProvisioned && (
          <Shortcut
            icon={<FulfillmentIcon />}
            title="Fulfillment"
            description="Open App"
            onClick={() => navigate('/fulfillment')}
          />
        )}

        <Shortcut
          icon={<FontAwesomeIcon tw="h-[40px] w-[40px]" icon={solid('user')} />}
          title="Manage Account"
          description="View Account Settings"
          color="primary"
          onClick={() => navigate('/settings/profile')}
        />

        <Shortcut
          icon={<FontAwesomeIcon tw="h-[40px] w-[40px]" icon={solid('question-circle')} />}
          title="Help"
          description="Get Support"
          color="primary"
          onClick={() => window.open('https://cart.com/knowledge', '_blank')}
        />
      </div>
    </>
  )
}
