import { AnalyticsModelType } from '../types/dasboardTypes'
import { dsApi } from './dsApiSlice'

const analyticsEndpoints = dsApi.injectEndpoints({
  endpoints: (builder) => ({
    getAnalytics: builder.query<AnalyticsModelType, string>({
      query: (organizationId) => {
        return `analytics/${
          typeof organizationId !== 'undefined' ? `?organization_id=${organizationId}` : ''
        }`
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          localStorage.setItem('analytics', JSON.stringify(data.data))
        } catch (error) {
          console.log(error)
        }
      },
    }),
  }),
})

export const { useGetAnalyticsQuery, useLazyGetAnalyticsQuery } = analyticsEndpoints
