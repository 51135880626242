import tw, { styled } from 'twin.macro'

const aspectRatios = {
  '1/1': tw`[aspect-ratio:1/1]`,
  '2/1': tw`[aspect-ratio:2/1]`,
  '3/4': tw`[aspect-ratio:3/4]`,
  '4/3': tw`[aspect-ratio:4/3]`,
  '9/16': tw`[aspect-ratio:9/16]`,
  '16/9': tw`[aspect-ratio:16/9]`,
}

export const ChartContainer = styled.div((props: any) => [
  tw`w-full`,
  aspectRatios[props['data-ratio'] as keyof typeof aspectRatios],
])
