import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import tw, { css, styled } from 'twin.macro'

import { DeprecatedAlertProps } from './deprecated-alert.types'

const handleTextColor = (type = 'base') => {
  switch (type) {
    case 'info':
      return tw`text-primary-800`
    case 'success':
      return tw`text-accent-three-900`
    case 'warning':
      return tw`text-secondary-900`
    case 'error':
      return tw`text-accent-two-900`
    default:
      return tw`text-white`
  }
}

export const StyledAlert = styled.div(({ size, type }: DeprecatedAlertProps) => [
  tw`w-full rounded-md shadow`,
  size === 'medium' && tw`p-4`,
  size === 'small' && tw`p-2`,
  type === 'base' && tw`bg-monochrome-900 text-white`,
  type === 'info' && tw`bg-primary-100 text-primary-700`,
  type === 'error' && tw`bg-accent-two-50 text-accent-two-700`,
  type === 'warning' && tw`bg-secondary-50 text-secondary-700`,
  type === 'success' && tw`bg-accent-three-50 text-accent-three-700`,
])

export const StyledAlertContainer = styled.div(() => [tw`flex leading-none`])

export const StyledIconContainer = styled.div(({ size }: DeprecatedAlertProps) => [
  tw`shrink-0 pr-3`,
  size === 'small' && tw`pr-1`,
  size === 'medium' && tw`pr-4`,
  css`
    & svg {
      ${tw`text-xl leading-none`}
    }
  `,
])

export const StyledIcon = styled(FontAwesomeIcon)(() => undefined)

export const StyledMessage = styled.div(({ size, type }: DeprecatedAlertProps) => [
  tw`leading-none`,
  css`
    & a {
      text-decoration: underline;
    }
    & p {
      ${tw`mb-0 mt-0.5 font-medium`};
      ${size === 'medium' && tw`text-sm`};
      ${size === 'small' && tw`text-xs`};
    }
  `,
  handleTextColor(type),
])

export const StyledDismissWrapper = styled.div(() => [tw`ml-auto pl-3`])

export const StyledDismiss = styled.div(() => [tw`block leading-none`])

export const StyledDismissButton = styled.button(() => [tw`inline-flex focus:outline-none`])

export const StyledDismissButtonSrOnly = styled.span(() => [tw`sr-only`])

export const StyledCloseIcon = styled(FontAwesomeIcon)(() => [tw`mt-0.5 text-lg leading-none`])

export const StyledHeading = styled.h4(({ type, text }: DeprecatedAlertProps) => [
  tw`mb-2.5 font-bold text-sm`,
  type === 'info' && tw`text-primary-800`,
  type === 'error' && tw`text-accent-two-900`,
  type === 'warning' && tw`text-secondary-900`,
  type === 'success' && tw`text-accent-three-900`,
  !text && tw`mb-0`,
])

export const StyledTextContainer = styled.div(() => [
  tw`flex flex-col pl-1 [overflow-wrap:anywhere]`,
])
