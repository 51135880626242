import { ContactPreferenceTypeOpts, FullfillmentServices } from '@brand-console/types'
import { hubspotFormSubmit, LoggerNames } from '@brand-console/utilities'
import { DeprecatedInput, Dropdown, DropdownItem, Textarea } from '@cart/ui'
import { useCartAuth, useCurrentContext } from '@cartdotcom/auth'
import React, { ReactElement } from 'react'
import TagManager from 'react-gtm-module'
import { $enum } from 'ts-enum-util'

import { IAccountConfirmData } from '../AccountConfirmForm'
import { BaseServiceRequestForm } from './BaseServiceRequestForm'
import { useForm } from 'react-hook-form'

export interface FulfillmentFormData {
  contactPreference: string
  currentPlatform: string
  howLongSellingOnline: string
  serviceInterest: string
  shipmentsPerDay: string
  shippingSpecialConsiderations: string
  specialCircumstances: string
  storeurl: string
}
export const FulfillmentForm = (): ReactElement => {
  const { currentBusiness, currentOrganization } = useCurrentContext()
  const { email, phone } = useCartAuth()

  const methods = useForm({ mode: 'onChange' })
  const { getValues } = methods

  const createMenuItemsFromEnum = (theEnum, stateKey: string) =>
    $enum(theEnum).map((value) => {
      let finalLabel = value
      switch (value.toLocaleLowerCase()) {
        case 'email':
          finalLabel = `${value}: ${email}`
          break
        case 'phone':
          finalLabel = `${value}: ${phone}`
          break
        default:
          break
      }
      return <DropdownItem key={`${stateKey}-${value}`} text={finalLabel} value={value} />
    })

  const sendGAFormEvent = (eventKey: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'formQuestion_filled',
        results: {
          formType: 'Fulfillment Service Request',
          [eventKey]: getValues()[eventKey],
        },
      },
    })
  }

  const onSubmit = async (data: IAccountConfirmData & FulfillmentFormData) => {
    const { firstName, lastName, businessName, orgName } = data

    const {
      contactPreference,
      currentPlatform,
      howLongSellingOnline,
      serviceInterest,
      shipmentsPerDay,
      shippingSpecialConsiderations,
      specialCircumstances,
      storeurl,
    } = data
    TagManager.dataLayer({
      dataLayer: {
        event: 'form_submit',
        results: { formType: 'Fulfillment Service Request', ...data },
      },
    })

    const hsForm = await hubspotFormSubmit({
      formId: '22bea44b-b473-4645-95d5-a140b573000b',
      data: {
        email,
        firstname: firstName,
        lastname: lastName,
        console_organization_name: orgName,
        console_organization_id: currentOrganization.id,
        console_business_id: currentBusiness.id,
        console_business_name: businessName,
        service_interest__c: serviceInterest,
        store_url: storeurl,
        current_ecommerce_platform__c: currentPlatform,
        shipments_per_day__c: shipmentsPerDay,
        how_long_selling_online__c: howLongSellingOnline,
        shipping_special_considerations__c: shippingSpecialConsiderations,
        shipping_special_circumstances__c: specialCircumstances,
        contact_preference__c: contactPreference,
      },
      portalId: parseInt(process.env.NX_HUBSPOT_MARKETING_FORMS_PORTAL_ID, 10),
      pageName: 'Brand Console - Fullfilment Trial Creation',
    })

    return !!hsForm
  }

  return (
    <BaseServiceRequestForm
      formName="Order Fulfillment"
      logName={LoggerNames.FULFILLMENT}
      onSubmit={onSubmit}
      methods={methods}
    >
      <li tw="mb-6">
        <div tw="mb-2 font-bold">What service would you like to know more about? *</div>
        <Dropdown
          id="serviceInterest"
          multipleSelection
          required
          placeholder="Select all that apply"
          onChange={() => sendGAFormEvent('serviceInterest')}
        >
          {createMenuItemsFromEnum(
            FullfillmentServices.ServiceInterestOpts,
            'serviceInterest',
          )}
        </Dropdown>
      </li>
      <li tw="mb-6">
        <div tw="mb-2 font-bold">What is the store URL?</div>
        <DeprecatedInput
          id="storeurl"
          placeholder="Store URL (optional)"
          onBlur={() => sendGAFormEvent('storeurl')}
        />
      </li>
      <li tw="mb-6">
        <div tw="mb-2 font-bold">
          Are you currently using an ecommerce platform or selling through marketplaces? *
        </div>
        <Dropdown
          id="currentPlatform"
          multipleSelection
          onChange={() => sendGAFormEvent('currentPlatform')}
          placeholder="Select all that apply"
          required
        >
          {createMenuItemsFromEnum(
            FullfillmentServices.CurrentPlatformOpts,
            'currentPlatform',
          )}
        </Dropdown>
      </li>
      <li tw="mb-6">
        <div tw="mb-2 font-bold">How many shipments per day would you like help with? *</div>
        <Dropdown id="shipmentsPerDay" onChange={() => sendGAFormEvent('shipmentsPerDay')} required>
          {createMenuItemsFromEnum(
            FullfillmentServices.ShipmentsPerDayOpts,
            'shipmentsPerDay',
          )}
        </Dropdown>
      </li>
      <li tw="mb-6">
        <div tw="mb-2 font-bold">How long have you been selling online? *</div>
        <Dropdown
          id="howLongSellingOnline"
          onChange={() => sendGAFormEvent('howLongSellingOnline')}
          required
        >
          {createMenuItemsFromEnum(
            FullfillmentServices.HowLongSellingOnlineOpts,
            'howLongSellingOnline',
          )}
        </Dropdown>
      </li>
      <li tw="mb-6">
        <div tw="mb-2 font-bold">Do any special considerations apply to your shipments?</div>
        <Dropdown
          id="shippingSpecialConsiderations"
          multipleSelection
          onChange={() => sendGAFormEvent('shippingSpecialConsiderations')}
          placeholder="Select all that apply"
        >
          {createMenuItemsFromEnum(
            FullfillmentServices.ShippingSpecialConsiderationOpts,
            'shippingSpecialConsiderations',
          )}
        </Dropdown>
      </li>
      <li tw="mb-6">
        <div tw="mb-2 font-bold">
          Are there any special circumstances, requests, or needs regarding fulfilling your product
          orders?
        </div>
        <Textarea
          id="specialCircumstances"
          onBlur={() => sendGAFormEvent('specialCircumstances')}
        />
      </li>
      <li tw="mb-6">
        <div tw="mb-2 font-bold">How would you prefer to be contacted? *</div>
        <Dropdown
          id="contactPreference"
          multipleSelection
          onChange={() => sendGAFormEvent('contactPreference')}
          placeholder="Select all that apply"
          required
        >
          {createMenuItemsFromEnum(ContactPreferenceTypeOpts, 'contactPreference')}
        </Dropdown>
      </li>
    </BaseServiceRequestForm>
  )
}
