import { logger } from '@brand-console/utilities'
import { Button, CardContent, DialogTitle } from '@cart/ui'
import { useCartAuth, useCurrentContext } from '@cartdotcom/auth'
import CardActions from '@mui/material/CardActions'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { SubmitHandler } from 'react-hook-form/dist/types/form'

import { useAccountConfirmContext } from '../../AccountConfirmForm'
import { TrialFormProps } from '../TrialForm.types'
import { AccountConfirmForm } from '../../AccountConfirmForm/AccountConfirmForm'
import { FormCompletionStepper } from './FormCompletionStepper'
import { IAccountConfirmData } from '../../AccountConfirmForm/AccountConfirmForm.types'
import { TrialFormSkeleton } from './SkeletonForm'

export const TrialFormInner: FC<TrialFormProps> = ({
  onComplete,
  onError,
  beforeSubmit,
  onSubmit,
  formTransitionIn: pageFormTransitionIn,
  errorComponent,
  formProps,
  formTitle: formName,
  modal,
  children: pageForm,
}) => {
  const log = useMemo(() => logger.setLogger(logger.LoggerNames.ORGANIZATION), [])
  const [isAccountFormSubmitting, setIsAccountFormSubmitting] = useState(false)
  const [activeStep, setActiveStep] = useState(0)

  const { firstName, lastName, phone } = useCartAuth()
  const { methods, shouldShowForm, formDefaultValues, submitForm: submitAccountForm } = useAccountConfirmContext()
  const { handleSubmit } = methods
  const { currentBusiness } = useCurrentContext()
  // shouldShowForm will update when account confirmation form is submitted so only set this initially
  const [isStepperForm] = useState(shouldShowForm)

  // If transitioning in the secondary form, wait for it to finish
  const immediateTransitionIn = !isStepperForm && !!pageFormTransitionIn
  const [loading, setLoading] = useState(immediateTransitionIn)

  const initiatePageFormTransition = useCallback((data?:IAccountConfirmData) => {
    setLoading(true)
    pageFormTransitionIn?.(data, () => setLoading(false))
  }, [pageFormTransitionIn])

  useEffect(() => {
      if(immediateTransitionIn) initiatePageFormTransition(formDefaultValues)
  }, [initiatePageFormTransition, immediateTransitionIn])

  const formTitle = isStepperForm ? 'Finalize trial signup' : formName

  const handleSubmitAccountForm = async (data) => {
    setIsAccountFormSubmitting(true)
    let submitted = false

    try {
      submitted = await submitAccountForm({
        data,
        onError: (error, friendlyErrorMessage, scope) => {
          log.error(
            'Error submitting account confirmation form',
            {
              friendlyErrorMessage,
              scope,
            },
            error,
          )
          onError?.(error, friendlyErrorMessage, scope)
        },
      })
    } finally {
      setIsAccountFormSubmitting(false)
    }

    return submitted
  }

  // Handles form submission logic for both the account form and the secondary form
  // Step 0 submits only the account form, step 1 submits only the secondary form
  const handleFormSubmit: SubmitHandler<IAccountConfirmData> = async (data, event) => {
    if (beforeSubmit && beforeSubmit() === false) return

    const dataWithDefaultValues = {
      firstName: data.firstName || firstName,
      lastName: data.lastName || lastName,
      phone: data.phone || phone,
      businessName: data.businessName || currentBusiness?.name,
      ...data,
    }

    // Only submit account form if on account form step
    if (isStepperForm && activeStep === 0) {
      if (await handleSubmitAccountForm(data)) {
        setActiveStep(1)
        // Transition in the next form
        if (pageFormTransitionIn) {
          initiatePageFormTransition(data)
        }
      }
      // Only submit the account form in this step
      return
    }

    // Submit page form, pages handle their own errors
    if (onSubmit(dataWithDefaultValues, event)) {
      onComplete?.(dataWithDefaultValues)
    }
  }

  if (loading) return <TrialFormSkeleton />

  return (
    <>
      {formTitle && <DialogTitle hideCloseButton>{formTitle}</DialogTitle>}
      <CardContent tw="px-6 pt-0">
        {isStepperForm ? (
          <>
            <FormCompletionStepper activeStep={activeStep} secondStepName={formName} tw="mb-6" />
            {modal && errorComponent}
          </>
        ) : (
          modal && errorComponent
        )}

        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <form onSubmit={handleSubmit(handleFormSubmit)} {...formProps} noValidate>
          {isStepperForm && activeStep === 0 ? (
            <>
              <AccountConfirmForm />
              <CardActions tw="justify-end px-0 pb-0 pt-6">
                <Button variant="contained" type="submit" loading={isAccountFormSubmitting}>
                  Continue
                </Button>
              </CardActions>
            </>
          ) : (
            pageForm
          )}
        </form>
      </CardContent>
    </>
  )
}
