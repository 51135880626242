import { useReactiveVar } from '@apollo/client'
import { activeBizVar } from '@brand-console/generated-graphql-hooks'
import { Spinner, useAppContext } from '@cart/ui'
import React, { useEffect } from 'react'

import { MarketplaceManagementRoute } from '../../routes/MarketplaceManagementRoute/MarketplaceManagementRoute'
import { AccountConfirmFormProvider } from '../AccountConfirmForm/store/AccountConfirmFormProvider'

export const MarketplaceManagement = () => {
  const { mcmEnabled, mcmIsLoading } = useAppContext()
  const activeBusiness = useReactiveVar(activeBizVar)

  useEffect(() => {
    if (mcmEnabled) {
      window.location.href = `${process.env.NX_MCM_SSO_URL}?businessId=${activeBusiness.id}`
    }
  }, [activeBusiness?.id, mcmEnabled])

  if (mcmIsLoading) {
    return <Spinner />
  }

  if (!mcmEnabled) {
    return (
      <AccountConfirmFormProvider>
        <MarketplaceManagementRoute />
      </AccountConfirmFormProvider>
    )
  }

  return null
}
