import React, { FC, useEffect, useState } from 'react'

import { Input } from '../input/input'
import { Criteria } from './components/criteria'
import { Score } from './components/score'
import { StyledContainer, StyledCriteriaList, StyledHelperText } from './password-strength.styles'
import { PasswordStrengthProps } from './password-strength.types'

export const PasswordStrength: FC<PasswordStrengthProps & Record<string, unknown>> = ({
  onNewPWChange,
  onConfirmPWChange,
  onValidated,
}: PasswordStrengthProps) => {
  enum PasswordStrengthEnum {
    'None',
    'Very Weak',
    'Weak',
    'Good',
    'Great',
  }

  const [passwordStrength, setPasswordStrength] = useState<PasswordStrengthEnum>(
    PasswordStrengthEnum.None,
  )

  const [passwordLengthGood, setPasswordLengthGood] = useState(false)
  const [passwordContainsNumbers, setPasswordContainsNumbers] = useState(false)
  const [passwordContainsUpperLower, setPasswordContainsUpperLower] = useState(false)
  const [passwordContainsSpecials, setPasswordContainsSpecials] = useState(false)
  const [passwordsMatch, setPasswordsMatch] = useState(false)
  const [currentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const determinePasswordStrength = (password: string) => {
    const lengthGood = password.length >= 8
    const containsUpperLower = /[A-Z]/.test(password) && /[a-z]/.test(password)
    const containsNumbers = /[0-9]/.test(password)
    const containsSpecials = /[!@#$%^&*()]/.test(password)
    const strength = [lengthGood, containsUpperLower, containsNumbers, containsSpecials].filter(
      Boolean,
    ).length

    setPasswordLengthGood(lengthGood)
    setPasswordContainsUpperLower(containsUpperLower)
    setPasswordContainsNumbers(containsNumbers)
    setPasswordContainsSpecials(containsSpecials)
    setPasswordStrength(strength)
  }

  useEffect(() => {
    determinePasswordStrength(currentPassword)
  }, [currentPassword])

  useEffect(() => {
    if (
      passwordsMatch &&
      passwordLengthGood &&
      passwordContainsNumbers &&
      passwordContainsSpecials &&
      passwordContainsUpperLower
    )
      onValidated(true)
  }, [
    passwordsMatch,
    passwordLengthGood,
    passwordContainsNumbers,
    passwordContainsSpecials,
    passwordContainsUpperLower,
    onValidated,
  ])

  return (
    <StyledContainer>
      <Input
        id="password"
        type="password"
        label="New password"
        placeholder="New password"
        tw="mb-2 block w-full"
        value={newPassword}
        onChange={(e: any) => {
          setNewPassword(e.target.value)
          determinePasswordStrength(e.target.value)
          setPasswordsMatch(e.target.value === confirmPassword)
          onNewPWChange(e.target.value)
        }}
      />
      <Score score={passwordStrength} tw="mb-2" />
      <div tw="my-2 text-monochrome-900/[0.65] tracking-tight">Your password must contain:</div>
      <StyledCriteriaList>
        <Criteria checked={passwordLengthGood}>At least 8 characters</Criteria>
        <Criteria checked={passwordContainsNumbers}>A number</Criteria>
        <Criteria checked={passwordContainsUpperLower}>Upper and lower case letters</Criteria>
        <Criteria checked={passwordContainsSpecials}>Special characters (&, %, *)</Criteria>
      </StyledCriteriaList>
      <Input
        id="confirm-password"
        type="password"
        label="Confirm new password"
        placeholder="Confirm new password"
        onChange={(e: any) => {
          setConfirmPassword(e.target.value)
          setPasswordsMatch(e.target.value === newPassword)
          if (onConfirmPWChange) {
            onConfirmPWChange(e.target.value)
          }
        }}
        tw="mb-1"
      />
      <StyledHelperText match={passwordsMatch}>
        {passwordsMatch ? 'Your passwords match' : 'Your passwords do not match'}
      </StyledHelperText>
    </StyledContainer>
  )
}
