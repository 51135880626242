import { Input } from '@cart/ui/atoms/Input'
import React from 'react'

import { asinPattern, requiredRule } from '../helpers'
import { Form } from './Form'
import { QuantityInput } from './QuantityInput'

export const BuyTogether = () => {
  const generateUrl = (data) => {
    const requiredAsins = `https://www.amazon.com/gp/aws/cart/add.html?ASIN.1=${data.asin1.trim()}&Quantity.1=${
      data.quantity1
    }&ASIN.2=${data.asin2.trim()}&Quantity.2=${data.quantity2}`

    const asin3 = data.asin3 ? `&ASIN.3=${data.asin3.trim()}&Quantity.3=${data.quantity3}` : ''
    const asin4 = data.asin4 ? `&ASIN.4=${data.asin4.trim()}&Quantity.4=${data.quantity4}` : ''

    return requiredAsins + asin3 + asin4
  }

  return (
    <Form
      handleGenerateLink={generateUrl}
      title="Create URL to Bundle Amazon Products"
      description="Send shoppers to a cart page that combines 2 or more products."
      example="Example:
      https://www.amazon.com/gp/aws/cart/add.html?ASIN.1=B00I0TJHDO&Quantity.1=1&ASIN.2=B007GCH756&Quantity.2=1"
    >
      <Input
        id="asin1"
        label="ASIN 1"
        placeholder="ASIN 1"
        helperText="Required"
        inputProps={{ maxLength: 10 }}
        type="text"
        rules={{ ...requiredRule, ...asinPattern }}
      />

      <QuantityInput index={1} />

      <Input
        id="asin2"
        label="ASIN 2"
        placeholder="ASIN 2"
        helperText="Required"
        inputProps={{ maxLength: 10 }}
        rules={{ ...requiredRule, ...asinPattern }}
      />

      <QuantityInput index={2} />

      <Input
        id="asin3"
        label="ASIN 3"
        placeholder="ASIN 3"
        helperText="Optional"
        inputProps={{ maxLength: 10 }}
        rules={{ ...asinPattern }}
      />

      <QuantityInput index={3} />

      <Input
        id="asin4"
        label="ASIN 4"
        placeholder="ASIN 4"
        helperText="Optional"
        inputProps={{ maxLength: 10 }}
        rules={{ ...asinPattern }}
      />

      <QuantityInput index={4} />
    </Form>
  )
}
