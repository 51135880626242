import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export const SearchForm = ({ onClose, onSearch }) => {
  const [searchTerm, setSearchTerm] = React.useState('')

  const handleSubmit = (event) => {
    event.preventDefault()
    onClose()
    if (searchTerm) {
      onSearch(searchTerm)
    }
  }

  return (
    <form
      tw="flex items-center justify-between gap-4 rounded-3xl border border-monochrome-900/25 border-solid px-2 py-3"
      onSubmit={handleSubmit}
    >
      <button
        aria-label="Perform Search"
        type="submit"
        tw="flex cursor-pointer items-center border-none bg-transparent pl-2"
      >
        <FontAwesomeIcon icon={faSearch} tw="text-lg text-monochrome-700 hover:text-primary-700" />
      </button>
      <input
        tw="w-full border-none focus:outline-none"
        ref={(input) => input && input.focus()}
        placeholder="Search for orders, products, listings, customers, and more"
        onChange={(event) => setSearchTerm(event.target.value)}
        aria-label="Search"
      />
      <button
        aria-label="Close Search Form"
        type="button"
        tw="flex cursor-pointer items-center border-none bg-transparent"
        onClick={onClose}
      >
        <FontAwesomeIcon
          icon={faTimes}
          tw="mr-2 text-monochrome-700 text-xl hover:text-primary-700"
        />
      </button>
    </form>
  )
}
