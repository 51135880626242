import { IMenuItem } from '../../MenuItem/MenuItem.types'

export const helpMenu: IMenuItem = {
  title: 'Help',
  icon: 'faCircleQuestion',
  items: [
    {
      title: 'Knowledge Base',
      url: 'https://cart.com/knowledge',
      newTab: true,
    },
    {
      title: 'Contact Us',
      onClick: 'onContactUsClick',
    },
    {
      title: 'Changelog',
      url: 'https://cart.canny.io/changelog',
      newTab: true,
    },
    {
      title: 'Feature Requests',
      url: '/feature-requests',
    },
  ],
}
