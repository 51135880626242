import { scrollTo, useLayoutStore } from '@brand-console/utilities'
import { Breadcrumbs, FulfillmentIcon, Spinner, useBreakpoint } from '@cart/ui'
import { useCurrentContext } from '@cartdotcom/auth'
import React, { ReactElement, useEffect } from 'react'
import TagManager from 'react-gtm-module'

import { ImageGallery } from '../../components/ImageGallery'
import { Recommendations } from '../../components/Recommendations'
import { FulfillmentForm } from '../../components/ServiceRequestForms/FulfillmentForm'
import { StickyHeader } from '../../components/StickyHeader'
import FulfillmentBannerPNG from '../../images/fulfillment-banner.png'
import { ProductBanner } from './ProductBanner'
import { ServiceOfferingItem } from './ServiceOfferingItem'

export const FulfillmentRoute = (): ReactElement => {
  const isSmall = useBreakpoint('sm')
  const { currentBusiness, isLoaded } = useCurrentContext()
  const { fulfillmentProvisioned } = useLayoutStore()

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        route: 'Fulfillment',
      },
    })
  }, [])

  useEffect(() => {
    if (fulfillmentProvisioned && currentBusiness?.id) {
      window.location.href = `${process.env.NX_JAZZ_ORIGIN}?constellation=true&businessId=${currentBusiness.id}`
    }
  }, [currentBusiness?.id, fulfillmentProvisioned])

  if (fulfillmentProvisioned) {
    return null
  }

  if (!isLoaded) {
    return <Spinner tw="m-auto h-64 w-64" />
  }

  return (
    <main tw="relative">
      <section tw="px-6 py-4 sm:px-8">
        <Breadcrumbs items={[{ text: 'Home', href: '/' }, { text: 'Fulfillment' }]} />
      </section>

      <StickyHeader
        baseTopPosition={isSmall ? 295 : 240}
        overviewOnClick={() => scrollTo('#fulfillment-overview', { offset: 90 })}
        offeringsOnClick={() => scrollTo('#fulfillment-offerings', { offset: 90 })}
        requestOnClick={() => scrollTo('#fulfillment-request', { offset: 90 })}
      >
        <FulfillmentIcon tw="mr-2 h-7 w-7" />
        <h4 tw="mb-0 mr-4 text-sm">Fulfillment</h4>
      </StickyHeader>

      <section tw="m-auto w-full max-w-5xl px-6 py-8 sm:px-8 md:overflow-hidden">
        <ProductBanner
          title="Fulfillment"
          description="Ship products faster with our nationwide fulfillment network."
          icon={FulfillmentIcon}
          bgImage={FulfillmentBannerPNG}
          buttonAction={() => scrollTo('#fulfillment-request', { offset: 164 })}
        />
      </section>

      <section tw="m-auto w-full max-w-5xl px-6 py-8 sm:px-8 md:overflow-hidden">
        <ImageGallery tw="mb-14" />
      </section>

      <section
        id="fulfillment-overview"
        tw="m-auto w-full max-w-5xl px-6 pb-8 sm:px-8 md:overflow-hidden"
      >
        <h2 tw="mb-7 mt-0">Overview</h2>
        <p tw="text-lg">
          Cart Fulfillment is a 3PL and nationwide fulfillment infrastructure solution that equips
          brands of all sizes with the infrastructure and national footprint to compete with the
          world&rsquo;s largest retailers. Our owned and operated fulfillment centers pick, pack and
          ship for direct-to-consumer and B2B use cases across the continental United States. Our
          operations are nimble, flexible and based on a strong culture of communication and
          co-creation with our merchant partners.
        </p>
        <p tw="text-lg">
          With Cart Fulfillment, you get all the benefits of an enterprise-level fulfillment
          infrastructure for a fraction of the cost, all managed by Cart.com and our strategic
          fulfillment network. Manage inventory, warehouse your orders and deliver nationwide
          &mdash; just like the big guys &mdash; with Cart Fulfillment.
        </p>
      </section>

      <section id="fulfillment-offerings" tw="bg-white p-9">
        <div tw="mx-auto w-full max-w-5xl xl:px-8">
          <h2 tw="mb-7">Service Offerings</h2>

          <div tw="grid gap-7 md:grid-cols-2 lg:grid-cols-3">
            <ServiceOfferingItem>
              <h3 tw="mb-2 text-xl">Nationwide Fulfillment</h3>
              <p tw="m-0">
                Deliver to your customers faster by leveraging our nationwide fulfillment network.
                Our footprint can service 95% of the U.S. with 2-day ground delivery and we help you
                manage shipping costs through our strategically distributed fulfillment centers.
              </p>
            </ServiceOfferingItem>

            <ServiceOfferingItem>
              <h3 tw="mb-2 text-xl">Pick, Pack, and Ship</h3>
              <p tw="m-0">
                We offer flexible pick, pack, and shipping pricing that scales with your business.
                Whether you’re an ecommerce startup or an established brand, or whether you’re a D2C
                or B2B business, our tech-enabled order fulfillment capabilities accommodate your
                needs.
              </p>
            </ServiceOfferingItem>

            <ServiceOfferingItem>
              <h3 tw="mb-2 text-xl">Order Management</h3>
              <p tw="m-0">
                Brands view aggregate and SKU-level order data, on-hand inventory information, and
                inbound and outbound shipments all in a single, easy-to-navigate dashboard.
              </p>
            </ServiceOfferingItem>

            <ServiceOfferingItem>
              <h3 tw="mb-2 text-xl">Fulfillment by Amazon (FBA) Restock</h3>
              <p tw="m-0">
                For Amazon-centric brands, our FBA restocking, storage, and prep services enable us
                to receive and hold your FBA-bound inventory until it’s ready to ship to Amazon. We
                go the extra mile through our inspection and labeling services to ensure FBA
                compliance while decreasing the time until inventory is available for purchase.
              </p>
            </ServiceOfferingItem>

            <ServiceOfferingItem>
              <h3 tw="mb-2 text-xl">Inventory Storage</h3>
              <p tw="m-0">
                With several fulfillment centers distributed across the United States, we provide
                brands convenient long-term storage solutions at competitive rates. Our IT-enabled
                facilities boast best-in-class security and monitoring measures so you can trust
                your inventory is safe.
              </p>
            </ServiceOfferingItem>
          </div>
        </div>
      </section>

      <section tw="m-auto w-full max-w-5xl px-6 py-8 sm:px-8">
        <h2 id="fulfillment-request">Service Request</h2>
        <p tw="mb-8 text-base">Please fill out the below form to request the service.</p>
          <FulfillmentForm />
      </section>

      <section tw="[max-width: 100vw] m-auto w-full px-6 py-8 sm:([max-width: calc(100vw - 100px)] px-8) lg:max-w-5xl">
        <h2 tw="mb-6 pl-4 text-2xl lg:pl-0">Recommendations</h2>
        <Recommendations pageToOmit="/fulfillment" />
      </section>
    </main>
  )
}
