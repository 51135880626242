import { logger } from '../../logging'

function getJsonPayloadFromBase64Token(base64Token: string): string {
  // source: https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
  // apparently we can't just use atob because JWTs use base64url encoding, not just base64
  return decodeURIComponent(
    window
      .atob(base64Token)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
      })
      .join(''),
  )
}

export function hasTokenExpired(token: string): boolean {
  const log = logger.setLogger(logger.LoggerNames.GLOBAL)
  if (!token || !token.includes('.')) {
    return true
  }

  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')

  let jsonPayload = ''
  try {
    jsonPayload = getJsonPayloadFromBase64Token(base64)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    log.error('Error decoding token', { function: 'hasTokenExpired', token }, error)
    return true
  }

  const { exp } = JSON.parse(jsonPayload)
  const now = Date.now()
  return now >= exp * 1000
}
