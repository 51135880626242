import { useEffect, useLayoutEffect, useState } from 'react'
import { theme } from 'twin.macro'

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

const getMediaQueryList = (breakpoint: any) => {
  const minWidth: any = theme('screens')[breakpoint]
  let mediaQueryList: any
  if (breakpoint === 'mobile') {
    mediaQueryList = matchMedia(`(max-width: ${minWidth.max})`)
  } else {
    mediaQueryList = matchMedia(`(min-width: ${minWidth})`)
  }
  return mediaQueryList
}

export const useBreakpoint = (breakpoint: any) => {
  let mediaQueryList = getMediaQueryList(breakpoint)
  const [matches, setMatches] = useState<boolean>(mediaQueryList.matches)

  useIsomorphicLayoutEffect(() => {
    mediaQueryList = getMediaQueryList(breakpoint)
    setMatches(mediaQueryList.matches)
    const onChange = (e: any) => setMatches(e.matches)
    mediaQueryList.addEventListener('change', onChange)
    return () => mediaQueryList.removeEventListener('change', onChange)
  }, [breakpoint])

  return matches
}
