import { CardContent } from '@cart/ui'
import Skeleton from '@mui/material/Skeleton'
import React, { FC } from 'react'

export type TrialFormSkeletonProps = {
  hideButton?: boolean
}
export const TrialFormSkeleton: FC<TrialFormSkeletonProps> = ({hideButton}) => (
  <CardContent>
    <Skeleton tw="mb-6" height={29} width={300} variant="rounded" />
    <div tw="mb-6 grid grid-cols-2 gap-6">
      <Skeleton height={48} variant="rounded" />
      <Skeleton height={48} variant="rounded" />
      <Skeleton height={48} variant="rounded" />
      <Skeleton height={48} variant="rounded" />
    </div>
    {!hideButton && <div tw="flex justify-end">
      <Skeleton tw="" height={32} width={93} variant="rounded" />
    </div>}
  </CardContent>
)
