import { useEffect, useState } from 'react'

import { permissionCheck } from '../../gql/queries/permissionCheck.query'
import { useAuthToken } from './useAuthToken'

export const usePermissionCheck = (
  permissionName: string,
  businessId: number,
  organizationId: number,
) => {
  const token = useAuthToken()
  const [hasAccess, setHasAccess] = useState<boolean | null>(null)

  useEffect(() => {
    const performPermissionCheck = async () => {
      const response = await permissionCheck(token, permissionName, businessId, organizationId)
      setHasAccess(response)
    }
    if (token) performPermissionCheck()
  }, [token, permissionName, businessId, organizationId])

  return hasAccess
}
