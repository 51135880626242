import { useCallback } from 'react'
import { useShallow } from 'zustand/react/shallow'

import { Business } from '../../common.types'
import { updateAppSettingCurrentContext } from '../../gql/requests'
import { useCartAuthStore } from '../store/auth.store'

export const useCurrentContext = () => {
  const {
    organizations,
    currentOrganization,
    currentBusiness,
    setCurrentContext,
    setOrganization,
    setBusiness,
    initialDataLoaded,
    roleName,
  } = useCartAuthStore(
    useShallow((state) => ({
      organizations: state.organizations,
      setOrganization: state.setOrganization,
      setBusiness: state.setBusiness,
      currentOrganization: state.currentOrganization,
      currentBusiness: state.currentBusiness,
      setCurrentContext: state.setCurrentContext,
      initialDataLoaded: state.initialDataLoaded,
      roleName: state.roleName,
    })),
  )

  const addBusiness = (organizationId: number, business: Business) => {
    const organization = organizations.find((o) => o.id === organizationId)
    const updatedOrganization = {
      ...organization,
      businesses: [...organization.businesses, business],
    }
    setBusiness(updatedOrganization, business)
  }

  const setNewOrganization = async (organization) => {
    setOrganization(organization)
    const business = organization.businesses[0]
    await updateAppSettingCurrentContext(organization.id, business?.id)
  }

  const setNewBusiness = async (business: Business, organizationId?: number) => {
    const matchedOrgId = organizationId || currentOrganization?.id
    addBusiness(matchedOrgId, business)
    await updateAppSettingCurrentContext(matchedOrgId, business?.id)
  }

  const setContext = useCallback(
    async (organizationId: number, businessId: number) => {
      const organization = organizations.find((o) => o.id === organizationId)
      const business = organization?.businesses.find((b) => b.id === businessId)
      if (currentBusiness?.id === businessId) return
      setCurrentContext(organization, business)
      await updateAppSettingCurrentContext(organizationId, businessId)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizations, currentBusiness?.id],
  )

  const setBusinessIdContext = useCallback(
    async (businessId: number) => {
      if (currentBusiness?.id === businessId) return
      const organization = organizations.find((o) => o.businesses.some((b) => b.id === businessId))
      const business = organization?.businesses.find((b) => b.id === businessId)
      if (organization && business) {
        setCurrentContext(organization, business)
        await updateAppSettingCurrentContext(organization.id, businessId)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizations],
  )

  return {
    isLoaded: initialDataLoaded,
    currentOrganization,
    currentBusiness,
    roleName,
    setContext,
    setBusinessIdContext,
    setNewOrganization,
    setNewBusiness,
  }
}
