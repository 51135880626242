import { useMeMfaAccessTokenLazyQuery } from '@brand-console/generated-graphql-hooks'
import { appendIfValid, logger } from '@brand-console/utilities'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Input } from '@cart/ui'
import { useCartAuth } from '@cartdotcom/auth'
import React, { FC, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { mfaTokenVar } from '../SettingsSecurity.partials'
import { ModalProps } from '../SettingsSecurity.types'

type ConfirmPasswordInput = {
  confirmpassword: string
}

export const ConfirmPasswordModal: FC<ModalProps> = ({ show, closeCallback }) => {
  const log = logger.setLogger(logger.LoggerNames.SECURITY)
  const { email } = useCartAuth()
  const [showFailureAlert, setShowFailureAlert] = useState(false)
  const [failureAlertMessage, setFailureAlertMessage] = useState('An unspecified error occurred.')
  const [passwordValid, setPasswordValid] = useState(false)
  const methods = useForm()
  const { handleSubmit, reset } = methods

  const [getToken, { loading }] = useMeMfaAccessTokenLazyQuery({
    onError: (error) => {
      log.error('Confirm Password error: useMeMfaAccessTokenLazyQuery', error)
      setFailureAlertMessage(error.message)
      setShowFailureAlert(true)
    },
    onCompleted: (data) => {
      if (data?.me?.user?.mfaData?.access_token) {
        mfaTokenVar(data?.me?.user?.mfaData?.access_token)
        reset()
        closeCallback()
      }
    },
  })

  const resetModalOnExited = () => {
    setShowFailureAlert(false)
    setFailureAlertMessage('')
  }

  const onSubmit = (data: ConfirmPasswordInput) => {
    if (data.confirmpassword === null) {
      setFailureAlertMessage('You must enter your password to manage MFA')
      setShowFailureAlert(true)
    } else {
      getToken({
        variables: {
          mfaInput: { username: email, password: data.confirmpassword },
        },
      })
    }
  }

  const handlePasswordChange = (e) => {
    setPasswordValid(e.target.value.length > 0)
  }

  return (
    <Dialog
      width="sm"
      open={show}
      id="confirmpassworddialog"
      onClose={() => {
        resetModalOnExited()
        closeCallback()
      }}
    >
      <DialogTitle>Enter password to enable 2-factor authentication</DialogTitle>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off" tw="w-full">
          <DialogContent dividers>
            <div tw="flex w-full flex-col items-center py-4 text-center mobile:px-1">
              {showFailureAlert && (
                <Alert
                  tw="mb-4"
                  severity="error"
                  onClose={() => {
                    setShowFailureAlert(false)
                    setFailureAlertMessage('')
                  }}
                >
                  {appendIfValid(
                    'Something went wrong when attempting to confirm your password',
                    failureAlertMessage,
                  )}
                </Alert>
              )}
              <Input
                id="confirmpassword"
                label="Enter current password"
                placeholder="Enter current password"
                required
                type="password"
                onChange={handlePasswordChange}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closeCallback()}>Cancel</Button>
            <Button
              type="submit"
              variant="contained"
              disabled={!passwordValid}
              loading={loading}
              onClick={() => setShowFailureAlert(false)}
            >
              Continue
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  )
}
