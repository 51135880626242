import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'

import { dataConnectorsFields } from '../../helpers/data'
import {
  ChosenConnectorType,
  ConnectorsDataType,
  MyConnectorsListType,
  Statuses,
} from '../types/dataConnectorsTypes'

// Define a type for the slice state
interface DataConnectorsState {
  selectedConnector: keyof typeof dataConnectorsFields | undefined
  currentView: 1 | 2 | 3
  isLoading: boolean
  chosenConnectors: any[]
  statuses: {
    data: {
      data_connector_status: Statuses[]
    }
  } | null
  actionsActive: {
    status: string | null
    business: string | null
  }
  activeSearch: string
  connectorsData: ConnectorsDataType | null
  sectionConnectors: { [key: string]: ChosenConnectorType[] } | null
  filterBy: {
    lastUpdated: boolean | null
    dataConnector: boolean | null
  }
  myConnectorsList: null | MyConnectorsListType[]
}

// Define the initial state using that type
const initialState: DataConnectorsState = {
  selectedConnector: undefined,
  currentView: 1,
  sectionConnectors: null,
  isLoading: false,
  statuses: null,
  actionsActive: {
    status: 'all',
    business: 'all',
  },
  activeSearch: '',
  chosenConnectors: [],
  connectorsData: null,
  filterBy: {
    lastUpdated: null,
    dataConnector: null,
  },
  myConnectorsList: null,
}

const dataConnectorsSlice = createSlice({
  name: 'dataConnectors',
  initialState,
  reducers: {
    setFilterBy: (state, action) => {
      state.filterBy[action.payload.filterName] = action.payload.filter
    },
    selectConnector: (state, action) => {
      state.selectedConnector = action.payload
    },
    setSectionConnectors: (state, action) => {
      state.sectionConnectors = action.payload
    },
    setCurrentView: (state, action: PayloadAction<1 | 2 | 3>) => {
      state.currentView = action.payload
    },
    setActionActive: (state, action) => {
      state.actionsActive[action.payload.actionName] = action.payload.action
    },
    setShopifyData: (state, action) => {
      state.connectorsData = { ...state.connectorsData, shopifyData: action.payload }
    },
    markConnector: (state, action: PayloadAction<ChosenConnectorType>) => {
      state.chosenConnectors.push(action.payload)
    },
    unMarkConnector: (state, action: PayloadAction<{ type: string; id: number }>) => {
      state.chosenConnectors = state.chosenConnectors.filter((i) => !isEqual(i, action.payload))
    },
    setChosenConnectors: (state, action: PayloadAction<ChosenConnectorType[] | []>) => {
      state.chosenConnectors = action.payload
    },
    setSearchActive: (state, action) => {
      state.actionsActive = initialState.actionsActive
      state.activeSearch = action.payload
    },
    setAmazonSPData: (state, action) => {
      state.connectorsData = { ...state.connectorsData, amazonSPCode: action.payload }
    },
    setFacebookToken: (state, action) => {
      state.connectorsData = { ...state.connectorsData, facebookAccessToken: action.payload }
    },
    setGoogleCodeData: (state, action) => {
      state.connectorsData = { ...state.connectorsData, googleCode: action.payload }
    },
    setGoogleStateData: (state, action) => {
      state.connectorsData = { ...state.connectorsData, googleState: action.payload }
    },
    setAmazonAdsCodeData: (state, action) => {
      state.connectorsData = { ...state.connectorsData, amazonAdsCode: action.payload }
    },
    setAmazonAdsStateData: (state, action) => {
      state.connectorsData = { ...state.connectorsData, amazonAdsState: action.payload }
    },

    setTikTokData: (state, action) => {
      state.connectorsData = { ...state.connectorsData, tiktokAuthData: action.payload }
    },
    setMyConnectorsList: (state, action) => {
      state.myConnectorsList = action.payload
    },
  },
})

export const {
  selectConnector,
  setCurrentView,
  setChosenConnectors,
  markConnector,
  unMarkConnector,
  setActionActive,
  setSearchActive,
  setShopifyData,
  setTikTokData,
  setAmazonSPData,
  setAmazonAdsCodeData,
  setAmazonAdsStateData,
  setFacebookToken,
  setMyConnectorsList,
  setGoogleCodeData,
  setGoogleStateData,
  setSectionConnectors,
  setFilterBy,
} = dataConnectorsSlice.actions
export default dataConnectorsSlice.reducer
