import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import React, {
  Children,
  cloneElement,
  CSSProperties,
  DetailedHTMLProps,
  Fragment,
  HTMLAttributes,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useMemo,
  useState,
} from 'react'
import tw from 'twin.macro'

import {
  contentTransitionProps,
  overlayTransitionProps,
  StyledCloseIcon,
  StyledContentActions,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContainer,
  StyledDialogContent,
  StyledDialogContentContainer,
  StyledDialogOverlay,
  StyledDialogTitle,
  StyledDialogTitleContainer,
} from './deprecated-dialog.styles'
import Transition from './deprecated-dialog.transition'
import {
  DeprecatedDialogProps,
  TriggerProps,
  VerticalAlignmentProps,
} from './deprecated-dialog.types'

const Trigger = ({ label, openModal }: TriggerProps) => {
  const childrenWithProps = Children.map(label, (child) => {
    const { props } = child as ReactElement
    return cloneElement(child as ReactElement, {
      onClick: () => {
        if (props.onClick) {
          props.onClick()
        }
        openModal()
      },
    })
  })
  return <div>{childrenWithProps}</div>
}

/* This element is to trick the browser into centering the modal contents. */
const VerticalAlignment = ({ alignment = 'middle' }: VerticalAlignmentProps) => {
  return (
    <span
      css={[
        tw`inline-block h-screen align-middle`,
        alignment === 'top' && tw`align-top [margin-top: -100px]`,
      ]}
      aria-hidden="true"
    >
      &#8203;
    </span>
  )
}

export const DeprecatedDialog = ({
  verticalAlign = 'middle',
  children,
  close,
  dialogProps,
  height,
  id,
  show,
  title,
  trigger,
  width,
}: DeprecatedDialogProps) => {
  const size = {} as CSSProperties
  if (height) {
    size.height = height
  }
  if (width) {
    size.width = width
  }

  const hasActions = useMemo(
    () =>
      Children.toArray(children).filter(($child) => {
        const child = $child as React.ReactElement<any>
        if (!child) return false
        return (child.type as React.FC).displayName === 'Dialog.Actions'
      }).length > 0,
    [children],
  )

  return (
    <>
      {trigger && <Trigger label={trigger} openModal={close} />}
      <Transition show={show} as={Fragment}>
        <StyledDialog
          onClose={close}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...dialogProps}
        >
          <StyledDialogContainer>
            <Transition.Child {...overlayTransitionProps} as="div">
              <StyledDialogOverlay />
            </Transition.Child>
            <VerticalAlignment alignment={verticalAlign} />
            <Transition.Child {...contentTransitionProps} as={Fragment}>
              <StyledDialogContentContainer
                aria-hidden={!show}
                aria-labelledby={`${id}-title`}
                aria-modal="true"
                id={id}
                role="dialog"
                style={size}
                data-testid="dialog"
              >
                <StyledDialogTitleContainer data-hastitle={!!title}>
                  {title && (
                    <StyledDialogTitle as="h3" id={`${id}-title`}>
                      {title}
                    </StyledDialogTitle>
                  )}
                  <StyledCloseIcon
                    icon={solid('xmark')}
                    onClick={close}
                    aria-label="Close Dialog"
                    data-testid="dialog-close"
                  />
                </StyledDialogTitleContainer>
                <StyledContentActions data-hasactions={hasActions}>{children}</StyledContentActions>
              </StyledDialogContentContainer>
            </Transition.Child>
          </StyledDialogContainer>
        </StyledDialog>
      </Transition>
    </>
  )
}

const Body = ({
  children,
  ...props
}: { children: ReactNode } & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const [hasScrollingContent, setHasScrollingContent] = useState<boolean>(false)
  const contentRef = (node: HTMLDivElement) => {
    if (node) {
      setTimeout(() => {
        const { scrollHeight, offsetHeight } = node
        setHasScrollingContent(scrollHeight > offsetHeight)
      }, 10)
    }
  }
  return (
    <StyledDialogContent {...props} ref={contentRef} data-hasscrollingcontent={hasScrollingContent}>
      {children}
    </StyledDialogContent>
  )
}

interface ActionsProps {
  className?: string
}

const Actions = ({ className, children }: PropsWithChildren<ActionsProps>): JSX.Element => {
  const arrayChildren = Children.toArray(children)
  return (
    <StyledDialogActions className={className}>
      {arrayChildren.map((child) => child)}
    </StyledDialogActions>
  )
}

DeprecatedDialog.Actions = Actions
DeprecatedDialog.Body = Body

export default DeprecatedDialog
