import { CartIcon } from '@cart/ui/icons/Cart'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { Link, UNSAFE_LocationContext } from 'react-router-dom'

import { HeaderProps } from '../../header.types'
import { AccountDropdown } from '../AccountDropdown'
import { AppsDropdown } from '../AppsDropdown/AppsDropdown'
import { McmNotifications } from '../McmNotifications/trigger/McmNotifications'
import { StyledMenuButton } from './Header.styles'

const LogoContainer = () => {
  const isReactRouterAvailable = !!useContext(UNSAFE_LocationContext)

  if (isReactRouterAvailable) {
    return (
      <Link to="/?nav=bc">
        <CartIcon tw="ml-2 h-8 w-8" />
      </Link>
    )
  }
  return (
    <a href={process.env.NX_APP_ORIGIN}>
      <CartIcon tw="ml-2 h-8 w-8" />
    </a>
  )
}

const Notifications = ({ product }) => {
  switch (product) {
    case 'mcm':
      return <McmNotifications />
    default:
      return null
  }
}

export const Header = ({ product, toggleNav }: HeaderProps) => {
  return (
    <div tw="[max-width: 100vw] [border-bottom-style: solid] flex w-full items-center justify-between border-b-monochrome-900/10 border-b bg-white py-1 pl-2 pr-2 leading-none">
      <div tw="flex items-center">
        <StyledMenuButton iconButton={false} onClick={toggleNav} aria-label="toggle sidebar">
          <FontAwesomeIcon
            tw="cursor-pointer text-lg text-monochrome-900/60"
            icon={solid('bars')}
          />
        </StyledMenuButton>
        <LogoContainer />
      </div>
      <div tw="flex items-center gap-x-2 text-monochrome-900/60">
        <Notifications product={product} />
        <AppsDropdown />
        <AccountDropdown />
      </div>
    </div>
  )
}
