import { Button } from '@cart/ui'
import React, { ReactElement, useEffect } from 'react'
import TagManager from 'react-gtm-module'

import { Styled404Container } from './404Route.styles'

export const Route404 = (): ReactElement => {
  const messages = [
    'Houston, we have a problem.',
    'I feel the need - the need for this page!',
    'Keep your friends close, but your pages closer.',
    'Magic Mirror on the wall, where is my page?',
    'Page. Missing page.',
    'Pay no attention to that page behind the curtain!',
    'Something went wrong. The page you are looking for was not found.',
    "I'd like a page. Shaken, not stirred.",
    "I'll be back. . . for this page.",
    "I'm mad as hell, and I'm not going to take these missing pages anymore!",
    "I'm your father's brother's nephew's cousin's former roommate's missing page.",
    "I've got a feeling we're not in Cart.com anymore. . .",
    "Pages? Where we're going we don't need pages.",
    "That's no moon. It's a missing page!",
    "There's no place like the home page.",
    "Well, performance issues, it's not uncommon. One out of five. . .",
    "You is kind. You is smart. You is a missin' page.",
    "You're killin' me with these pages, Smalls.",
  ]

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        route: '404',
      },
    })
  }, [])

  return (
    <main tw="absolute inset-0">
      <Styled404Container>
        <div tw="text-center">
          <h1 tw="mb-5">Something&apos;s Missing…</h1>
          <p tw="mb-10 text-lg">
            &quot;
            {messages[Math.floor(Math.random() * messages.length)]}
            &quot;
          </p>
          <Button href="/" variant="contained">
            Go Back
          </Button>
        </div>
      </Styled404Container>
    </main>
  )
}
