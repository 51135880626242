import { DialogActions } from '@mui/material'
import tw, { css, styled } from 'twin.macro'

export const StyledDialogActions = styled(DialogActions)(() => [
  css`
    &.MuiDialogActions-root {
      ${tw`flex flex-wrap gap-2`}
      ${tw`px-4 py-2`}
      ${tw`sm:(px-6 py-4)`}
      & > button {
        ${tw`m-0 mobile:grow`}
        &:not(:nth-child(2)):not(:nth-child(4)):last-child {
          ${tw`mobile:w-full`}
        }
      }
    }
  `,
])
