import { Button, Spinner, StorefrontIcon } from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'

import { loadingMessages } from './CreateStore.constants'
import { CreateStoreState, ICreateStore } from './CreateStore.types'

export const CreateStore = ({ close, state, errorMessage }: ICreateStore) => {
  const [provisioningRandomMessage, setProvisioningRandomMessage] = useState<string>(
    "Sit tight! We're provisioning your store now!",
  )

  useEffect(() => {
    const randomMessageInterval = setInterval(() => {
      setProvisioningRandomMessage(
        loadingMessages[Math.floor(Math.random() * loadingMessages.length)],
      )
    }, 5000)

    return function cleanup() {
      clearInterval(randomMessageInterval)
    }
  }, [])

  return (
    <div>
      {(() => {
        switch (state) {
          case CreateStoreState.SUCCESS:
            return (
              <div tw="flex flex-wrap justify-center py-10 px-8 text-center">
                <StorefrontIcon tw="mb-6 [max-width:120px]" />
                <h2 tw="mb-8 w-full">Your online store is ready!</h2>
                <p tw="w-full">
                  To add products and configure your store, you will be taken to our powerful online
                  store platform.
                </p>
                <p tw="mt-4 mb-10 w-full">
                  Onward in your ecommerce journey! <br />
                  We will be with you each step of the way.
                </p>
                Redirecting you now ...
              </div>
            )
          case CreateStoreState.FAILURE:
            return (
              <div tw="flex flex-wrap justify-center py-10 px-8 text-center">
                <FontAwesomeIcon
                  icon={solid('exclamation-triangle')}
                  tw="mb-6 text-5xl text-accent-two-700"
                />
                <h2 tw="mb-4 w-full">Something went wrong when provisioning your store.</h2>
                <pre tw="w-full text-accent-two-700">{errorMessage}</pre>
                <p tw="mt-4 mb-10 w-full">
                  A Cart.com representative has been alerted
                  <br />
                  and will be in touch with you soon.
                </p>
                <Button onClick={close} variant="contained">
                  Click here to close
                </Button>
              </div>
            )
          case CreateStoreState.PROVISIONING:
          default:
            return (
              <div tw="py-10 px-8 text-center">
                <Spinner tw="m-auto h-40 w-40" />
                <h2 tw="my-6">Creating your new store</h2>
                <p tw="px-10 text-lg">
                  Your new store is being created with Cart.com&apos;s powerful, feature-rich
                  ecommerce online store software!
                </p>
                <p tw="mb-4 mt-10 text-lg text-monochrome-600">{provisioningRandomMessage}</p>
              </div>
            )
        }
      })()}
    </div>
  )
}
