import { graphql } from '../../../gql'

export const sideMenuQueryDocument = graphql(/* GraphQL */ `
  query getSideMenu($input: SideMenuInput!) {
    getSideMenu(input: $input) {
      searchUrl
      logoutUrl
      logoUrl
      user {
        firstName
        lastName
        username
        avatar
      }
      tenants {
        name
        tenantCode
        businessId
        organizationId
        nodes {
          name
          tenantCode
          businessId
          organizationId
          nodes {
            name
            tenantCode
            businessId
            organizationId
          }
        }
      }
      nodes {
        title
        url
        icon
        nodes {
          title
          url
          icon
          nodes {
            title
            url
            icon
            nodes {
              title
              url
              icon
              nodes {
                title
                url
                icon
              }
            }
          }
        }
      }
    }
  }
`)
