import { create } from 'zustand'

interface FeedMarketingStore {
  planId: number
  setPlanId: (planId: number) => void
}

export const useFeedMarketingStore = create<FeedMarketingStore>((set) => ({
  planId: 0,
  setPlanId: (planId) => set(() => ({ planId })),
}))
