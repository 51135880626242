import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputAdornment } from '@mui/material'
import { useState } from 'react'

import { IconButton } from '../../icon-button/icon-button'

export const usePassword = () => {
  const [showPassword, setShowPassword] = useState(false)

  const passwordIcon = (
    <InputAdornment position="end">
      <IconButton onClick={() => setShowPassword((prev) => !prev)}>
        <FontAwesomeIcon icon={showPassword ? solid('eye-slash') : solid('eye')} />
      </IconButton>
    </InputAdornment>
  )

  return {
    showPassword,
    passwordIcon,
    passwordType: showPassword ? 'text' : 'password',
  }
}
