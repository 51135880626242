import { BusinessStage, ContactPreferenceTypeOpts } from '@brand-console/types'
import { hubspotFormSubmit, LoggerNames } from '@brand-console/utilities'
import { Dropdown, DropdownItem, Textarea } from '@cart/ui'
import { useCartAuth, useCurrentContext } from '@cartdotcom/auth'
import React from 'react'
import TagManager from 'react-gtm-module'
import { $enum } from 'ts-enum-util'

import { IAccountConfirmData } from '../AccountConfirmForm'
import { BaseServiceRequestForm } from './BaseServiceRequestForm'
import { useForm } from 'react-hook-form'

export interface CustomerEngagementFormData {
  contactPreference
  serviceInterest
  businessStage
  howCanWeHelp
}

export const CustomerEngagementForm = () => {
  const { currentBusiness, currentOrganization } = useCurrentContext()
  const { email, phone } = useCartAuth()

  const methods = useForm({ mode: 'onChange' })
  const { getValues } = methods

  const createMenuItemsFromEnum = (theEnum, stateKey: string) =>
    $enum(theEnum).map((value) => {
      let finalLabel = value

      switch (value.toLocaleLowerCase()) {
        case 'email':
          finalLabel = `${value}: ${email}`
          break
        case 'phone':
          if (!phone) {
            return null
          }
          finalLabel = `${value}: ${phone}`
          break
        default:
          break
      }
      return <DropdownItem key={`${stateKey}-${value}`} text={finalLabel} value={value} />
    })

  const createMenuRadioItemsFromEnum = (theEnum, stateKey: string) =>
    $enum(theEnum).map((value) => (
      <DropdownItem key={`${stateKey}-${value}`} text={value} value={value} />
    ))

  const sendGAFormEvent = (eventKey: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'formQuestion_filled',
        results: {
          formType: 'Customer Engagement Service Request',
          [eventKey]: getValues()[eventKey],
        },
      },
    })
  }

  const onSubmit = async (data: IAccountConfirmData & CustomerEngagementFormData) => {
    const {
      firstName,
      lastName,
      businessName,
      orgName,
      contactPreference,
      businessStage,
      howCanWeHelp,
    } = data

    const serviceInterest = 'Customer Engagement'

    TagManager.dataLayer({
      dataLayer: {
        event: 'form_submit',
        results: { formType: 'Customer Engagement Service Request', ...data },
      },
    })

    const hsForm = await hubspotFormSubmit({
      formId: '3946b34c-4d66-4a8d-930f-7216be77ba15',
      data: {
        email,
        firstname: firstName,
        lastname: lastName,
        console_organization_name: orgName,
        console_organization_id: currentOrganization?.id,
        console_business_id: currentBusiness?.id,
        console_business_name: businessName,
        service_interest__c: serviceInterest,
        business_stage__c: businessStage,
        how_can_we_help__c: howCanWeHelp,
        contact_preference__c: contactPreference,
      },
      portalId: parseInt(process.env.NX_HUBSPOT_MARKETING_FORMS_PORTAL_ID, 10),
      pageName: 'Brand Console - Customer Engagement Trial Creation',
    })

    return !!hsForm
  }

  return (
    <BaseServiceRequestForm
      formName="Customer Engagement Service"
      logName={LoggerNames.CUSTOMER_ENGAGEMENT}
      onSubmit={onSubmit}
      methods={methods}
    >
      <li tw="mb-6">
        <div tw="mb-2 font-bold">What stage is your business in? *</div>
        <Dropdown
          id="businessStage"
          onChange={() => sendGAFormEvent('businessStage')}
          placeholder="Select the stage of your business"
          required
        >
          {createMenuRadioItemsFromEnum(BusinessStage, 'businessStage')}
        </Dropdown>
      </li>
      <li tw="mb-6">
        <div tw="mb-2 font-bold">How can we help?</div>
        <Textarea id="howCanWeHelp" onBlur={() => sendGAFormEvent('howCanWeHelp')} />
      </li>
      <li tw="mb-6">
        <div tw="mb-2 font-bold">How would you prefer to be contacted? *</div>
        <Dropdown
          id="contactPreference"
          multipleSelection
          onChange={() => sendGAFormEvent('contactPreference')}
          placeholder="Select all that apply"
          required
        >
          {createMenuItemsFromEnum(ContactPreferenceTypeOpts, 'contactPreference')}
        </Dropdown>
      </li>
    </BaseServiceRequestForm>
  )
}
