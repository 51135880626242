import { useCallback, useEffect } from 'react'

import { ModifierKeys } from './useKeyPress.constants'
import { UseKeyPressProps } from './useKeyPress.types'

function isModifierMatched(
  modifier: ModifierKeys | undefined,
  metaKey: boolean,
  ctrlKey: boolean,
  shiftKey: boolean,
  altKey: boolean,
) {
  // Modifier key is not pressed
  if (!modifier && !metaKey && !altKey && !ctrlKey && !shiftKey) return true
  if (!modifier) return false

  const modifierKey = modifier.toLowerCase()
  if (modifierKey === ModifierKeys.Meta.toLowerCase() && metaKey) return true
  if (modifierKey === ModifierKeys.Alt.toLowerCase() && altKey) return true
  if (modifierKey === ModifierKeys.Control.toLowerCase() && ctrlKey) return true
  if (modifierKey === ModifierKeys.Shift.toLowerCase() && shiftKey) return true
  if (modifierKey === ModifierKeys.Ctrl_Meta.toLowerCase() && (ctrlKey || metaKey)) return true
  return false
}

export { ModifierKeys }

/**
 * A hook that listens for key press on a specified element or the document.
 * @param {Object} options - The options object for the hook.
 * @param {Object} options.ref - The reference to the element to listen for key presses on. If not provided, key presses are listened on the document.
 * @param {string} options.key - The key to listen for.
 * @param {ModifierKeys} options.modifier - Optional modifier key.
 * @param {function} options.onKeyPress - The onKeyPress function to be executed when the specified key is pressed with the specified modifier.
 * @param {boolean} options.preventDefault - If this is true the browsers default keyboard shortcut wont occur.
 */
export const useKeyPress = ({
  ref,
  key: matchKey,
  modifier,
  onKeyPress,
  preventDefault,
}: UseKeyPressProps) => {
  // handle what happens on key press
  const handleKeyPress = useCallback(
    (event: Event) => {
      if (event instanceof KeyboardEvent) {
        const { key, repeat, metaKey, ctrlKey, shiftKey, altKey } = event
        if (repeat) return

        const matchModifier = isModifierMatched(modifier, metaKey, ctrlKey, shiftKey, altKey)
        if (matchKey.toLowerCase() === key.toLowerCase() && matchModifier) {
          if (preventDefault) event.preventDefault()
          onKeyPress(event)
        }
      }
    },
    [modifier, matchKey, preventDefault, onKeyPress],
  )

  useEffect(() => {
    const targetNode = ref ?? document

    if (targetNode) targetNode.addEventListener('keydown', handleKeyPress)

    return () => {
      targetNode?.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress, ref])
}
