import tw, { css, styled } from 'twin.macro'

const mobilePosition = tw`mobile:([left: -90vw] [width: 100vw] [top: -1.5rem] absolute)`

export const StyledSearch = styled('div')(() => {
  const nodeName = 'search'
  return [
    tw`[z-index: 2147483647] relative`,
    css`
      .${nodeName}-enter {
        opacity: 0;
        ${mobilePosition}
      }
      .${nodeName}-enter-done {
        opacity: 1;
        ${mobilePosition}
      }
      .${nodeName}-enter-active {
        opacity: 1;
        ${mobilePosition}
      }
      .${nodeName}-exit {
        opacity: 1;
        ${mobilePosition}
      }
      .${nodeName}-exit-active {
        opacity: 0;
        ${mobilePosition}
        transition: opacity 200ms;
      }
    `,
  ]
})
