import { RefObject, useEffect } from 'react'

type Handler = (event: MouseEvent) => void

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: Handler,
  mouseEvent: 'mousedown' | 'mouseup' = 'mousedown',
): void {
  useEffect(() => {
    const onClickHandler = (event: MouseEvent) => {
      const el = ref?.current

      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event.target as Node)) {
        return
      }

      handler(event)
    }
    window.addEventListener(mouseEvent, onClickHandler)
    return () => {
      window.removeEventListener(mouseEvent, onClickHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
