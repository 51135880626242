import { ApolloError } from '@apollo/client'
import {
  BusinessShowDocument,
  OrganizationShowDocument,
  useBusinessNameMutation,
  useOrganizationUpdateMutation,
} from '@brand-console/generated-graphql-hooks'
import { useCurrentContext, useOrganization } from '@cartdotcom/auth'

export const useUpdateBusinessName = () => {
  const { currentBusiness, currentOrganization } = useCurrentContext()
  const { updateCurrentOrganization } = useOrganization()

  const [updateBusinessName] = useBusinessNameMutation({
    refetchQueries: [
      {
        query: BusinessShowDocument,
        variables: { orgBusinessShowId: currentBusiness?.id },
      },
    ],
  })
  const [updateOrganization] = useOrganizationUpdateMutation({
    refetchQueries: [
      {
        query: OrganizationShowDocument,
        variables: { orgOrganizationShowId: currentOrganization?.id },
      },
    ],
  })

  const updateBusinessNameDetails = async (
    businessName,
    onError?: (error: ApolloError) => void,
  ) => {
    let businessNameError = false
    let organizationError = false

    if (businessName === currentBusiness.name) return

    await updateBusinessName({
      variables: {
        updateBusinessInput: {
          id: currentBusiness?.id,
          name: businessName ?? undefined,
        },
      },
      onError: (error) => {
        businessNameError = true
        onError?.(error)
      },
    })

    if (businessNameError) return

    await updateOrganization({
      variables: {
        updateOrganizationInput: {
          id: currentOrganization.id,
          name: businessName ?? undefined,
        },
      },
      onError: (error) => {
        organizationError = true
        onError?.(error)
      },
    })

    updateCurrentOrganization(
      !organizationError
        ? {
            name: businessName,
          }
        : {},
      !businessNameError
        ? {
            name: businessName,
          }
        : {},
    )
  }

  return updateBusinessNameDetails
}
