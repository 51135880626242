import { Dialog as MUIDialog } from '@mui/material'
import tw, { css, styled } from 'twin.macro'

export const StyledDialog = styled(MUIDialog)(() => [
  css`
    // Add negative top margin when no title is provided.
    &:not(:has(.MuiDialog-container > .MuiPaper-root > .MuiDialogTitle-root > span)) {
      & .MuiDialogContent-root {
        ${tw`!-mt-14`}
      }
    }
    & .MuiDialog-container {
      ${tw`mobile:(flex items-end)`}
      ${tw`sm:items-center`}
    }
    & .MuiPaper-root {
      ${tw`mobile:([max-width: 100%] [width: 100vw] mx-0 mb-0 rounded-b-none)`}
      ${tw`sm:([min-width: 450px] mb-8)`}
    }
  `,
])
