import { gql } from 'graphql-request'

import { getToken } from '../../auth/auth'
import { MeResponse, MeUser } from '../../common.types'
import { gqlClient } from '../gqlClient'

const queryMe = gql`
  query cartAuth_Me {
    me {
      user {
        id
        auth0Id
        firstName
        lastName
        primaryEmail
        createdAt
        userData {
          phone
          company
        }
        userAppSettings {
          app
          setting
          type
        }
        organizations {
          id
          name
          roleName
          businesses {
            id
            name
            roleName
          }
          businessEntityTerminology
        }
      }
    }
  }
`

export const getMe = async (token: string): Promise<MeUser> => {
  const response: MeResponse = await gqlClient.request(
    queryMe,
    {},
    {
      Authorization: `Bearer ${token}`,
    },
  )

  return response.me.user
}

export const me = async (): Promise<MeUser> => {
  const token = await getToken()
  if (!token) return null
  return getMe(token)
}
