import MuiCardHeader from '@mui/material/CardHeader'
import { FC } from 'react'
import tw, { css, styled } from 'twin.macro'

import { CardHeaderProps } from './card-header.types'

const StyledCardHeader = styled(MuiCardHeader)(() => [
  css`
    & .MuiCardHeader-title {
      ${tw`font-bold font-heading text-base`}
    }
    & .MuiCardHeader-subheader {
      ${tw`text-sm`}
    }
  `,
])

export const CardHeader: FC<CardHeaderProps> = ({ children, ...props }) => {
  return <StyledCardHeader {...props}>{children}</StyledCardHeader>
}
