/**
 * Helper function for navigating to a given AmeriCommerce Admin Console url via Single-Sign-On.
 *
 * @param url - The FULL url to which you're navigating.
 * @param token - The auth0 x-cartid-token value. Formatted in the following fashion: 'Bearer YOUR_TOKEN_HERE'.
 * @param sub - The auth0 x-cartid-sub value. Formatted in the following fashion: 'auth0|YOUR_SUB_ID_HERE'.
 */
export const ACSingleSignOn = (url: string, token: string, sub: string) => {
  const form = document.createElement('form')
  document.body.appendChild(form)

  form.method = 'POST'
  form.action = url

  // Sub and Bearer Token
  const userSub = document.createElement('input')
  const userToken = document.createElement('input')
  userToken.type = 'hidden'
  userToken.name = 'x-cartid-token'
  userToken.value = `Bearer ${token}`
  userSub.type = 'hidden'
  userSub.name = 'x-cartid-sub'
  userSub.value = sub

  form.appendChild(userSub)
  form.appendChild(userToken)

  form.submit()
}
