import { Menu } from '../../Menu/Menu'
import { rptMenu } from './RptMenu.menu'
import { RptMenuIds } from './RptMenu.types'
import { useRptMenuData } from './useRptMenuData'

const mapRemoteMenu = (remoteMenu) => {
  const mapped: { icon: string; title: string; url: string; items?: [] } = {
    icon: remoteMenu.icon,
    title: remoteMenu.name,
    url: remoteMenu.path,
  }

  if (remoteMenu.submenu) {
    mapped.items = remoteMenu.submenu.map(mapRemoteMenu)
  }

  return mapped
}

const getMenusForSection = (menuItems, section) => {
  return menuItems.filter((item) => item.section === section).map(mapRemoteMenu)
}

export const RptMenu = () => {
  const { menuItems } = useRptMenuData()

  rptMenu[0].items = getMenusForSection(menuItems, RptMenuIds.SHARED)
  rptMenu[1].items = getMenusForSection(menuItems, RptMenuIds.GROWTH_MARKETING)
  rptMenu[2].items = getMenusForSection(menuItems, RptMenuIds.MARKETPLACE_SERVICES)
  rptMenu[3].items = getMenusForSection(menuItems, RptMenuIds.SETTINGS)

  const renderMenu = rptMenu
    .map((menu) => {
      menu.hidden = !menu.items.length

      return menu
    })
    .filter((menu) => !menu.hidden)

  return <Menu menuSections={renderMenu} />
}
