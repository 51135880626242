import { logger } from '@brand-console/utilities'
import { useAuthToken } from '@cartdotcom/auth'
import { useEffect, useState } from 'react'

import { getRptMenus } from './queries/rpt.queries'

export const useRptMenuData = () => {
  const log = logger
  const token = useAuthToken()
  const [menuItems, setMenuItems] = useState([])

  useEffect(() => {
    const getMenus = async () => {
      try {
        const menus = await getRptMenus(token)
        setMenuItems(menus)
      } catch (error) {
        log?.error(error)
      }
    }
    if (token) {
      getMenus()
    }
  }, [token])

  return { menuItems }
}
