export interface ICreateStore {
  /**
   * Function to be called to close the dialog.
   */
  close: () => void
  /**
   * Name of the store to be created.
   */
  state: CreateStoreState
  errorMessage: string
}

export enum CreateStoreState {
  'FAILURE',
  'PROVISIONING',
  'SUCCESS',
}
