import { useTabFocus } from '@cart/ui/hooks/useTabFocus'
import { useEffect, useMemo } from 'react'

import { CartAuthUser } from '../auth.types'
import { useLogout } from './hooks/useLogout'
import { useSessionCheck } from './hooks/useSessionCheck'
import { useCartAuthStore } from './store/auth.store'

export interface CartAuthProps {
  enableSessionCheck?: boolean
}

export const useCartAuth = (options?: CartAuthProps): CartAuthUser => {
  const { onLogout } = useLogout()
  const {
    setUser,
    userId,
    auth0Id,
    firstName,
    lastName,
    email,
    phone,
    company,
    organizations,
    createdAt,
  } = useCartAuthStore()

  const onSessionCheckInvalid = async (isLoggedOut?: boolean) => {
    await onLogout({ skipEndSession: true, skipAuth0Logout: isLoggedOut })
  }

  const { checkSession } = useSessionCheck(onSessionCheckInvalid)

  useTabFocus(options?.enableSessionCheck ? checkSession : undefined)

  useEffect(() => {
    if (options?.enableSessionCheck) {
      checkSession()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options?.enableSessionCheck])

  return useMemo(
    () => ({
      userId,
      auth0Id,
      firstName,
      lastName,
      email,
      phone,
      company,
      organizations,
      onLogout,
      setUser,
      createdAt,
      fullName: `${firstName || ''} ${lastName || ''}`.trim(),
    }),
    [
      userId,
      auth0Id,
      company,
      email,
      firstName,
      lastName,
      onLogout,
      organizations,
      phone,
      setUser,
      createdAt,
    ],
  )
}
