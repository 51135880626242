import { useOrganizationUsersQuery, useorgSettings_UnblockUserMutation } from '@brand-console/generated-graphql-hooks'
import { Button, Card, Dialog, DialogTitle } from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MUICardHeader from '@mui/material/CardHeader'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { AddUsers } from '../AddUsers/AddUsers'
import { RemoveUsers } from '../RemoveUsers/RemoveUsers'
import { WrappedDataGrid } from '../SettingsOrganization.partials'
import { useSettingsOrganizationsContext } from '../SettingsOrganizations.store'
import {
  ActionsCell,
  BusinessesCell,
  NameCell,
  RoleCell,
  StatusCell,
  UsersTableHeader,
} from './OrganizationUsers.partials'
import { isAtLeastOrgAdmin, isAtLeastOrgManager, useCurrentContext } from '@cartdotcom/auth'

export const OrganizationUsers = () => {
  const { currentOrganization, roleName } = useCurrentContext()
  const isOrgManagerOrAbove = isAtLeastOrgManager(roleName)
  const isOrgAdminOrAbove = isAtLeastOrgAdmin(roleName)

  const orgOrganizationShowId = currentOrganization.id

  const { data, loading, error, refetch } = useOrganizationUsersQuery({
    variables: { orgOrganizationShowId },
  })

  const { clearAlert, setAlert } = useSettingsOrganizationsContext()

  useEffect(() => {
    if (error) {
      setAlert({ variant: 'error', text: `There was a problem retrieving users: ${error}` })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const uniqueUsers = useMemo(() => {
    if (!data?.org_organizationShow) return []
    const businesses = data?.org_organizationShow?.businesses || []

    const members = data?.org_organizationShow?.members
      .map((member) => ({
        id: member.id,
        auth0Id: member.auth0Id,
        createdAt: member.createdAt,
        firstName: member.firstName,
        lastName: member.lastName,
        primaryEmail: member.primaryEmail,
        status: member.status,
        role: { name: member.role.name, displayName: member.role.displayName },
        businesses: businesses.filter((business) => business.assignedRoles.some((assignedRole) => assignedRole.userId === member.id))
          .map((business) => business.name) || []
      })) || []

    return members.sort((a, b) => (a.primaryEmail.localeCompare(b.primaryEmail)))
  }, [data?.org_organizationShow?.businesses])

  const [showAddUsersModal, setShowAddUsersModal] = useState<boolean>(false)

  const toggleAddUsersModal = useCallback(
    (shouldReload: boolean) => {
      if (shouldReload) {
        refetch()
      }
      setShowAddUsersModal(!showAddUsersModal)
    },
    [refetch, showAddUsersModal],
  )

  const handleAddUserClick = () => {
    clearAlert()
    toggleAddUsersModal(true)
  }

  const [showRemoveUsersModal, setShowRemoveUsersModal] = useState(false)
  const [usersSelectedForRemoval, setUsersSelectedForRemoval] = useState([]);

  const toggleRemoveUsersModal = useCallback(
    (shouldReload: boolean) => {
      if (showRemoveUsersModal && shouldReload) {
        refetch()
      }
      setShowRemoveUsersModal(!showRemoveUsersModal)
    },
    [refetch, showRemoveUsersModal],
  )

  const handleRemoveUserClick = useCallback(
    (userId: number) => {
      const users = uniqueUsers.filter((u) => u.id === userId)
      setUsersSelectedForRemoval(users)
      if (users) {
        toggleRemoveUsersModal(true)
      }
    },
    [uniqueUsers, setUsersSelectedForRemoval]
  )

  const [unblockUser] = useorgSettings_UnblockUserMutation()

  const handleUnblockUserClick = (userId: number) => {
    clearAlert()
    unblockUser({
      variables: {
        orgUserId: userId,
      },
      onCompleted: () => {
        setAlert({ variant: 'success', text: 'User unblocked' })
      },
      onError: (error) => {
        console.error('Error occurred unblocking user', error)
        setAlert({ variant: 'error', text: `Error occurred unblocking user: ${error.message}` })
      },
    })
  }

  const shouldShowActionsColumn = isOrgAdminOrAbove || isOrgManagerOrAbove;

  return (
    <section>
      {isOrgManagerOrAbove && (
        <div tw="mb-6 flex w-full justify-end">
          <Dialog
            id="remove-users-dialog"
            open={showRemoveUsersModal}
            onClose={() => toggleRemoveUsersModal(false)}
            title="Remove Users"
            width="sm"
          >
            <DialogTitle>Remove users</DialogTitle>
            <RemoveUsers
              close={toggleRemoveUsersModal}
              scope="organization"
              users={usersSelectedForRemoval}
            />
          </Dialog>
        </div>
      )}
      <Card tw="w-full">
        <MUICardHeader component={() => <UsersTableHeader />} />
        <WrappedDataGrid
          loading={loading}
          columns={[
            {
              headerName: 'User',
              field: 'primaryEmail',
              renderCell: NameCell,
              flex: 2,
              minWidth: 250,
            },
            {
              headerName: 'Businesses',
              field: 'businesses',
              valueGetter: BusinessesCell,
              flex: 0.5,
              minWidth: 100,
            },
            {
              headerName: 'Organization Role',
              field: 'role',
              valueGetter: RoleCell,
              flex: 1,
              minWidth: 150,
            },
            {
              headerName: 'Status',
              field: 'user',
              valueGetter: () => 'null',
              renderCell: StatusCell,
              flex: 1,
              minWidth: 150,
            },
            {
              headerName: 'Actions',
              field: 'id',
              renderCell: (params) => <ActionsCell row={params.row} canRemove={isOrgAdminOrAbove || !isAtLeastOrgManager(params.row.role.name)} onRemoveClick={handleRemoveUserClick} onUnblockClick={handleUnblockUserClick} />,
              sortable: false,
              disableColumnMenu: true,
              hide: !shouldShowActionsColumn,
            },
          ]}
          rows={loading ? [] : uniqueUsers}
        />
        {isOrgManagerOrAbove && (
          <div tw="my-5 ml-7">
            <Button
              startIcon={<FontAwesomeIcon icon={solid('plus')} />}
              onClick={handleAddUserClick}
              variant="outlined"
            >
              Add users
            </Button>
            <Dialog
              id="add-users-dialog"
              open={showAddUsersModal}
              onClose={() => toggleAddUsersModal(false)}
              width="sm"
            >
              <DialogTitle>Add users and set permissions</DialogTitle>
              <AddUsers close={toggleAddUsersModal} flow="organization" />
            </Dialog>
          </div>
        )}
      </Card>
    </section>
  )
}
