import { useQuery } from '@tanstack/react-query'

import { sideMenuQueryDocument } from '../components/unified-sidebar/sideMenuQuery'
import { useUnifiedSidebarStore } from '../store/unified-sidebar.store'
import { gqlClient } from '../utils/gqlClient/gqlClient'

export const useMenuItemData = ({ unsUrl, dfwUrl, jazzUrl, mcmUrl, bcUrl, retryFn }) => {
  const { authHeader, currentBusinessId, currentTenantCode } = useUnifiedSidebarStore()

  const query = useQuery({
    queryKey: ['menuItemData', authHeader],
    queryFn: () =>
      gqlClient(unsUrl, authHeader).request(sideMenuQueryDocument, {
        input: {
          tenantCode: currentTenantCode,
          businessId: currentBusinessId,
          dfwUrl,
          jazzUrl,
          mcmUrl,
          mcmLegacyUrl: process.env.NX_MCM_LEGACY_URL,
          bcUrl,
        },
      }),
    enabled: !!authHeader,
    // Retry every five seconds for thirty seconds
    retry: retryFn,
    retryDelay: 5000,
    // Don't refetch data at any time. We run the risk of having a stale token and sending the user to an error page.
    // The data shouldn't really change anyway.
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  })

  return { ...query }
}
