import React, { FC, useState } from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'
import { StyleSheetManager } from 'styled-components'

import { StyledTooltip, StyledTooltipWrapper } from './tooltip.styles'
import { TooltipProps } from './tooltip.types'

const findFirstOverflowAutoParent = (el: HTMLElement): HTMLElement | undefined => {
  if (!el.parentElement) {
    return undefined
  }
  if (getComputedStyle(el.parentElement).overflowY === 'auto') {
    return el.parentElement
  }
  return findFirstOverflowAutoParent(el.parentElement as HTMLElement)
}

export const Tooltip: FC<TooltipProps & Record<string, unknown>> = ({
  children,
  className,
  content,
  placement = 'top',
  renderRoot,
  trigger = 'click',
}: TooltipProps) => {
  const [controlledVisible, setControlledVisible] = useState(false)
  const [overflowAutoParent, setOverflowAutoParent] = useState<HTMLElement | undefined>()

  const updateControlledVisible = (triggerRef: HTMLElement | null) => {
    if (!controlledVisible && triggerRef) {
      const parentWithOverflowAuto = findFirstOverflowAutoParent(triggerRef)
      setOverflowAutoParent(parentWithOverflowAuto)
      if (parentWithOverflowAuto) {
        parentWithOverflowAuto.style.overflow = 'inherit'
      }
    } else if (overflowAutoParent) {
      overflowAutoParent.style.overflow = 'auto'
    }
    setControlledVisible(!controlledVisible)
  }

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible, triggerRef } =
    usePopperTooltip({
      closeOnOutsideClick: true,
      onVisibleChange: () => updateControlledVisible(triggerRef),
      placement,
      trigger,
      visible: controlledVisible,
    })

  const handleEventPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    e.stopPropagation()

  const contentLength = typeof content === 'string' ? content.length : 100
  return (
    <StyleSheetManager target={renderRoot}>
      <StyledTooltipWrapper
        className={`tooltip-wrapper ${className}`}
        onClick={handleEventPropagation}
        data-testid="tooltip"
      >
        <button type="button" ref={setTriggerRef}>
          {children}
        </button>
        {visible && (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <StyledTooltip
            ref={setTooltipRef}
            {...getTooltipProps()}
            contentLength={contentLength}
            data-testid="tooltip-content"
          >
            {content}
            <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          </StyledTooltip>
        )}
      </StyledTooltipWrapper>
    </StyleSheetManager>
  )
}

export default Tooltip
