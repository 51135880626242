import { gql } from 'graphql-request'

import { gqlClient } from '../gqlClient'

const queryProductInstanceShow = gql`
  query Org_productInstanceShow($input: GetProductInstanceInput!) {
    org_productInstanceShow(input: $input) {
      subscriptionStatus
    }
  }
`

export const isMcmEnabled = async (token: string, businessId: number): Promise<boolean> => {
  try {
    const response: any = await gqlClient.request(
      queryProductInstanceShow,
      {
        input: {
          businessId,
          instanceType: 'MCM',
        },
      },
      {
        Authorization: `Bearer ${token}`,
      },
    )

    return ['ACTIVE', 'TRIAL'].includes(response.org_productInstanceShow?.subscriptionStatus)
  } catch {
    return false
  }
}
