/* eslint-disable react/no-array-index-key */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React, { MouseEvent, useState } from 'react'
import tw from 'twin.macro'

import { ContextMenuOption, ContextMenuProps } from './context-menu.types'

const MenuItemRender = ({
  enabled = true,
  handleClose,
  icon,
  label,
  onClick,
}: ContextMenuOption) => {
  return (
    <MenuItem
      onClick={() => {
        onClick?.()
        handleClose?.()
      }}
      disableRipple
      css={[!enabled && tw`!cursor-not-allowed opacity-30`]}
    >
      <span tw="mr-3">{icon}</span>
      {label}
    </MenuItem>
  )
}

export const ContextMenu = ({
  className,
  id,
  items,
  children,
  size = 'medium',
  variant = 'outlined',
  IconButtonProps,
}: ContextMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const contextMenuId = id || 'context-menu'
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const isChildrenString = typeof children === 'string'

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (items.length === 0) return null

  return (
    <div className={className}>
      {isChildrenString && (
        <Button
          id={`${contextMenuId}-trigger`}
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant={variant}
          disableElevation
          size={size}
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          tw="![text-transform: inherit]"
        >
          {children}
        </Button>
      )}
      {!isChildrenString && (
        <IconButton
          id={`${contextMenuId}-trigger`}
          size={size}
          aria-label="more"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          {...IconButtonProps}
        >
          {children}
        </IconButton>
      )}
      <Menu
        id={contextMenuId}
        MenuListProps={{
          'aria-labelledby': `${contextMenuId}-trigger`,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {items.map((menuItems, index) => (
          <div key={index}>
            {menuItems.map((item, itemIndex) => (
              <MenuItemRender key={itemIndex} {...item} handleClose={handleClose} />
            ))}
            {index !== items.length - 1 && <Divider tw="!h-0 bg-monochrome-400" />}
          </div>
        ))}
      </Menu>
    </div>
  )
}
