/* eslint-disable react/jsx-props-no-spreading */
import { Button } from '@cart/ui'
import { Collapse } from '@mui/material'
import React from 'react'

import { AppCard } from './AppCard'
import { StyledCardGrid } from './AppsAndServices.styles'
import { useAppCards } from './hooks/useAppCards'

export const AppsAndServices = () => {
  const [collapsed, setCollapsed] = React.useState(true)
  const { cards, hiddenCards } = useAppCards('basic')

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {cards && cards.length > 0 && (
        <div>
          <h2 tw="mb-4 text-2xl">Apps and Services</h2>
          <StyledCardGrid>
            {cards.map(({ ...props }) => (
              <AppCard key={props.title} {...props} />
            ))}
          </StyledCardGrid>
          {hiddenCards && hiddenCards.length > 0 && (
            <Collapse in={!collapsed}>
              <StyledCardGrid tw="mt-6">
                {hiddenCards?.map(({ ...props }) => (
                  <AppCard key={props.title} {...props} />
                ))}
              </StyledCardGrid>
            </Collapse>
          )}
          <div tw="mt-6 flex justify-center">
            <Button
              variant="outlined"
              onClick={() => setCollapsed(!collapsed)}
              tw="font-semibold normal-case"
            >
              {collapsed ? 'Show more' : 'Show less'}
            </Button>
          </div>
        </div>
      )}
    </>
  )
}
