import { Business, Organization } from '../../common.types'
import { useCartAuthStore } from '../store/auth.store'

export const updateBusiness = (
  business: Business,
  organization: Organization,
  update: Partial<Business>,
) => {
  const updatedBusiness = { ...business, ...update }
  const updatedOrganization = {
    ...organization,
    businesses: organization.businesses.map((b) =>
      b.id === updatedBusiness.id ? updatedBusiness : b,
    ),
  }
  return {
    updatedBusiness,
    updatedOrganization,
  }
}

export const useOrganization = () => {
  const { initialDataLoaded, currentBusiness, currentOrganization, setBusiness, setOrganization } =
    useCartAuthStore()

  const getUpdatedOrganization = (organization: Organization, business: Business) => ({
    ...organization,
    businesses: organization.businesses.map((b) => (b.id === business.id ? business : b)),
  })

  const updateCurrentBusiness = (business: Partial<Business>) => {
    const updatedBusiness = { ...currentBusiness, ...business }
    setBusiness(getUpdatedOrganization(currentOrganization, updatedBusiness), updatedBusiness)
  }

  /**
   * Updates the current organization and optionally the current business
   * @param organization The properties to update on the current organization
   * @param business The properties to update on the current business, or if thats not found, the first business in the organization
   */
  const updateCurrentOrganization = (
    organization: Partial<Organization>,
    business?: Partial<Business>,
  ) => {
    let updatedOrganization = { ...currentOrganization, ...organization }
    const matchedBusiness =
      updatedOrganization.businesses.find((b) => b.id === currentBusiness.id) ||
      updatedOrganization.businesses[0]

    const updatedBusiness = business ? { ...matchedBusiness, ...business } : matchedBusiness

    if (business) updatedOrganization = getUpdatedOrganization(updatedOrganization, updatedBusiness)

    setOrganization(updatedOrganization, updatedBusiness)
  }

  return {
    isLoaded: initialDataLoaded,
    updateCurrentBusiness,
    updateCurrentOrganization,
  }
}
