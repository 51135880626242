import { useReactiveVar } from '@apollo/client'
import { appendIfValid, logger } from '@brand-console/utilities'
import {
  Alert,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Spinner,
} from '@cart/ui'
import React, { FC, useEffect, useState } from 'react'

import { authenticatorIdToRemoveVar, mfaTokenVar } from '../SettingsSecurity.partials'
import { ModalProps } from '../SettingsSecurity.types'

interface MfaFactor {
  id: string
  authenticator_type: string
  active: boolean
}

export interface ManageMFAProps extends ModalProps {
  setModalToShowCallback: (modal: string) => void
}
export const ManageMFAModal: FC<ManageMFAProps> = ({
  show,
  setModalToShowCallback,
  closeCallback,
}) => {
  const log = logger.setLogger(logger.LoggerNames.SECURITY)
  const [showFailureAlert, setShowFailureAlert] = useState(false)
  const [activeSmsId, setActiveSmsId] = useState('')
  const [activeOtpId, setActiveOtpId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [failureAlertMessage, setFailureAlertMessage] = useState('An unspecified error occurred.')
  const tokenVar = useReactiveVar(mfaTokenVar)

  const getAuthenticators = async () => {
    try {
      setIsLoading(true)
      setActiveOtpId('')
      setActiveSmsId('')
      const response = await fetch(`https://${process.env.NX_AUTH0_DOMAIN}/mfa/authenticators`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenVar}`,
        },
      })
      setIsLoading(false)
      const responseJSON = await response.json()
      if (responseJSON.length > 0) {
        // when we have the methods filter out the inactive pending/abandoned ones
        const filteredFactors: MfaFactor[] = responseJSON.filter(
          (factor: MfaFactor) => factor.active !== false,
        )
        filteredFactors.forEach((factor) => {
          if (factor.authenticator_type === 'oob') {
            setActiveSmsId(factor.id)
          } else if (factor.authenticator_type === 'otp') {
            setActiveOtpId(factor.id)
          }
        })
      }
    } catch (error) {
      setIsLoading(false)
      log.error('Enable MFA: getAuthenticators', error)
      setFailureAlertMessage('')
      setShowFailureAlert(true)
    }
  }

  const resetModalOnExited = () => {
    setShowFailureAlert(false)
    setFailureAlertMessage('')
  }

  // FIXME: this is being called twice with getMfaMethods passed into deps, look for alternate solution?
  useEffect(() => {
    if (tokenVar !== '' && show) {
      getAuthenticators()
    }
    // eslint-disable-next-line
  }, [show, tokenVar])

  return (
    <Dialog
      width="sm"
      open={show}
      id="managemfadialog"
      onClose={() => {
        resetModalOnExited()
        closeCallback()
      }}
    >
      <DialogTitle>Multi-factor authentication</DialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <Spinner tw="m-auto mb-8 h-64 w-64" />
        ) : (
          <div tw="flex w-full flex-col py-4 text-center mobile:px-1">
            {showFailureAlert && (
              <Alert
                tw="mb-3.5"
                severity="error"
                onClose={() => {
                  setShowFailureAlert(false)
                  setFailureAlertMessage('')
                }}
              >
                {appendIfValid(
                  'Something went wrong when attempting to view your authenticators',
                  failureAlertMessage,
                )}
              </Alert>
            )}
            <div tw="flex flex-row items-center justify-between gap-4">
              Keep your account extra secure with multifactor authentication.
              {(activeOtpId || activeSmsId) !== '' ? (
                <Chip color="success" label="Enabled" />
              ) : (
                <Chip color="error" label="Disabled" />
              )}
            </div>
            {/* TODO: Readd when SMS is supported
            <div tw="flex items-center flex-row justify-between">
              <div>
                <b>Method Selected</b> SMS Delivery
              </div>
              <Button
                disabled={false}
                variant={activeSmsId !== '' ? 'outlined' : 'contained'}
                color={activeSmsId !== '' ? 'error' : 'primary'}
                onClick={() => {
                  if (activeSmsId !== '') {
                    authenticatorIdToRemoveVar(activeSmsId)
                    setModalToShowCallback('remove')
                    closeCallback()
                  } else {
                    setModalToShowCallback('enablesms')
                    closeCallback()
                  }
                }}
              >
                {activeSmsId !== '' ? 'Remove' : 'Add'}
              </Button>
            </div>
            <hr />
            */}
          </div>
        )}
      </DialogContent>
      <DialogActions tw="flex flex-row items-center justify-between">
        <div>
          <b>Method Selected</b> App Authenticator
        </div>
        <div tw="flex flex-row gap-4">
          <Button onClick={closeCallback}>Cancel</Button>
          <Button
            disabled={false}
            variant="contained"
            color={activeOtpId !== '' ? 'error' : 'primary'}
            loading={isLoading}
            onClick={() => {
              if (activeOtpId !== '') {
                authenticatorIdToRemoveVar(activeOtpId)
                setModalToShowCallback('remove')
                closeCallback()
              } else {
                setModalToShowCallback('enableotp')
                closeCallback()
              }
            }}
          >
            {activeOtpId !== '' ? 'Remove' : 'Add'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}
