import { Spinner, useAppContext, useParamState } from '@cart/ui'
import { useCartAuth } from '@cartdotcom/auth'
import React from 'react'

import { ACSingleSignOn } from '../../helpers'
import { StorefrontRoute } from '../../routes/StorefrontRoute'
import { AccountConfirmFormProvider } from '../AccountConfirmForm/store/AccountConfirmFormProvider'

export const Storefront = () => {
  const { auth0Token, sfAccount, sfAccountIsLoading } = useAppContext()
  const { auth0Id } = useCartAuth()
  const sites = sfAccount?.provisioningAccount?.sites

  const { displayMode } = useParamState()

  if (sfAccountIsLoading) {
    return <Spinner />
  }

  if (!sfAccount?.provisioned) {
    return (
      <AccountConfirmFormProvider>
        <StorefrontRoute modalMode={displayMode === 'modal'} />
      </AccountConfirmFormProvider>
    )
  }

  if (sites.length === 1) {
    return (
      <>
        {ACSingleSignOn(
          `https://${sites[0].sharedDomain}/store/admin/login.aspx?fromBc=1`,
          auth0Token,
          auth0Id,
        )}
      </>
    )
  }

  return (
    <div tw="px-6 pt-6 pb-4">
      <ul tw="ml-0 grid list-none grid-cols-2 gap-6 p-0">
        {sites.map(({ id, name, sharedDomain }) => (
          <li key={id} tw="m-0 p-0">
            <button
              type="button"
              tw="flex w-full items-center justify-center overflow-hidden rounded-lg border border-monochrome-50 bg-transparent p-4 text-center font-bold text-base shadow-md transition-colors hover:(cursor-pointer bg-primary-700 text-white)"
              onClick={() => {
                ACSingleSignOn(
                  `https://${sharedDomain}/store/admin/login.aspx?fromBc=1`,
                  auth0Token,
                  auth0Id,
                )
              }}
            >
              {name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}
