import { Table } from '@cart/ui'
import { formatDistanceToNow } from 'date-fns'
import React from 'react'

import { ActionsButton, ActionsHeaderButton, StatusCell, TitleCell } from './MyConnectors.partials'

export const MyConnectorsDesktop = ({ data, updateActionConnector }) => {
  const TableButton = ({ table }) => {
    const { selectedFlatRows } = table
    const selectedConnectors = selectedFlatRows.map((row) => row.original)
    return (
      <ActionsHeaderButton
        dataConnectors={selectedConnectors}
        updateActionConnector={updateActionConnector}
      />
    )
  }
  const ActionsCell = ({ row }) => {
    return (
      <div tw="h-full w-full pr-5">
        <ActionsButton dataConnector={row.original} updateActionConnector={updateActionConnector} />
      </div>
    )
  }
  return (
    <Table
      headerContent={TableButton}
      data={data}
      columns={[
        {
          Header: 'Data Connector',
          accessor: 'title',
          Cell: TitleCell,
        },
        {
          Header: 'Business',
          accessor: 'business.name',
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: StatusCell,
        },
        {
          Header: 'Last Updated',
          accessor: 'lastUpdated',
          Cell: ({ value }) => {
            return formatDistanceToNow(new Date(value), { addSuffix: true })
          },
        },
        {
          Header: 'Category',
          accessor: 'categories',
          Cell: ({ value }) => {
            const categoryString = value
              .map((category) => category.title)
              .sort()
              .join(', ')
            return categoryString
          },
        },
        {
          disableSortBy: true,
          accessor: 'id',
          Cell: ActionsCell,
        },
      ]}
      enablePagination
      pagination={{
        itemsPerPage: 10,
      }}
      enableRowSelection
    />
  )
}
