import { nanoid } from 'nanoid'
import React, { ReactElement } from 'react'

export const DataScienceIcon = ({ className }: { className?: string }): ReactElement => {
  const title = 'Data Science Solutions'
  const idA = nanoid()
  const idB = nanoid()
  const idC = nanoid()
  const idD = nanoid()
  const idE = nanoid()

  return (
    <svg
      aria-label={title}
      className={className}
      fill="none"
      role="img"
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${idE})`}>
        <path
          d="M28.2954 9.76592L10.5954 14.5713C7.90607 15.2731 5.21678 13.6713 4.45071 11.0516C3.74893 8.36235 5.34535 5.67306 7.97035 4.90699L25.6704 0.165921C28.3596 -0.535864 31.0489 1.06056 31.815 3.68556C32.5811 6.31056 30.9846 9.05878 28.2954 9.76592Z"
          fill="#069AE3"
        />
        <path
          d="M57.6837 34.1464C56.9176 31.5214 54.2283 29.925 51.5391 30.6267L37.525 34.4035C36.9839 34.5642 36.4857 34.8053 36.0464 35.1107C36.0035 35.1375 35.966 35.1696 35.9232 35.1964C35.8589 35.2446 35.7946 35.2875 35.7303 35.341C34.5678 36.2571 33.8125 37.6714 33.8125 39.2625V55.0017C33.8125 57.7553 36.0518 59.9946 38.8053 59.9946C41.5588 59.9946 43.7981 57.7553 43.7981 55.0017V43.0821L54.1641 40.291C56.8533 39.5892 58.3908 36.8357 57.6837 34.1464Z"
          fill={`url(#${idA})`}
        />
        <path
          d="M53.9723 15.1446L10.6598 26.791C7.97052 27.4928 5.28124 25.8964 4.51516 23.2714C3.81338 20.5821 5.40981 17.8982 8.03481 17.1267L51.417 5.48031C54.1062 4.77853 56.7902 6.37496 57.5616 8.99996C58.2634 11.7535 56.6616 14.4428 53.9723 15.1446Z"
          fill={`url(#${idB})`}
        />
        <path
          d="M57.7911 22.9232C57.7911 25.1357 56.3446 27.1607 54.1 27.7499L27.0625 35.0035C26.875 34.8053 26.6768 34.6124 26.4625 34.4464C26.4036 34.3928 26.3393 34.3499 26.275 34.3071C26.2321 34.2749 26.1893 34.2428 26.1464 34.216C25.7071 33.9107 25.2143 33.6642 24.6679 33.5035L10.6589 29.7321C9.72679 29.491 8.78929 29.5232 7.9375 29.7857C7.99107 29.7642 8.03929 29.7535 8.09821 29.7321L51.4804 18.0857C54.1696 17.3839 56.8536 18.9803 57.625 21.6053C57.7375 22.0499 57.7911 22.4892 57.7911 22.9232Z"
          fill={`url(#${idC})`}
        />
        <path
          d="M28.4518 38.3732V54.9696C28.4518 57.7232 26.2125 59.9625 23.4589 59.9625C20.7053 59.9625 18.466 57.7232 18.466 54.9696V42.1821L8.10532 39.3964C5.41604 38.6892 3.87854 35.941 4.58568 33.2517C5.05711 31.6285 6.27318 30.3964 7.76782 29.866C5.78568 30.6267 4.4839 32.5178 4.4839 34.5964C4.4839 35.0196 4.53747 35.4482 4.64997 35.8767C5.41604 38.5017 8.10532 40.0982 10.7946 39.3964L27.1339 35.0089C27.9482 35.8928 28.4518 37.0714 28.4518 38.3732Z"
          fill="#DA7009"
        />
        <path
          d="M27.0641 35.0036L10.7248 39.3911C8.03549 40.0983 5.34621 38.4965 4.58013 35.8768C4.46763 35.4482 4.41406 35.0197 4.41406 34.5964C4.41406 32.5179 5.72121 30.6268 7.69799 29.8661C7.77299 29.8393 7.85871 29.8071 7.93906 29.7803C8.79085 29.5178 9.72299 29.4857 10.6605 29.7268L24.6748 33.5036C25.2212 33.6643 25.7087 33.9054 26.1534 34.2161C26.1962 34.2429 26.2391 34.275 26.2819 34.3072C26.3462 34.35 26.4105 34.3982 26.4694 34.4464C26.6784 34.6125 26.8766 34.8054 27.0641 35.0036Z"
          fill={`url(#${idD})`}
        />
      </g>
      <defs>
        <linearGradient
          id={idA}
          x1="28.7041"
          y1="45.2301"
          x2="63.1052"
          y2="45.2301"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#005982" />
          <stop offset="1" stopColor="#069AE3" />
        </linearGradient>
        <linearGradient
          id={idB}
          x1="31.0363"
          y1="35.5256"
          x2="31.0363"
          y2="5.24072"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#005982" />
          <stop offset="1" stopColor="#069AE3" />
        </linearGradient>
        <linearGradient
          id={idC}
          x1="32.8655"
          y1="40.1395"
          x2="32.8655"
          y2="21.4372"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#005982" />
          <stop offset="1" stopColor="#069AE3" />
        </linearGradient>
        <linearGradient
          id={idD}
          x1="4.41326"
          y1="34.5643"
          x2="27.0637"
          y2="34.5643"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#005982" />
          <stop offset="1" stopColor="#069AE3" />
        </linearGradient>
        <clipPath id={idE}>
          <rect width="53.5714" height="60" fill="white" transform="translate(4.28516)" />
        </clipPath>
      </defs>
    </svg>
  )
}
