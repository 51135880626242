import { scrollTo } from '@brand-console/utilities'
import { Breadcrumbs, DataScienceIcon, useBreakpoint } from '@cart/ui'
import { ReactElement, useEffect } from 'react'
import TagManager from 'react-gtm-module'

import { Recommendations } from '../../components/Recommendations'
import { DataScienceForm } from '../../components/ServiceRequestForms/DataScienceForm'
import { StickyHeader } from '../../components/StickyHeader'
import DataScienceBannerPNG from '../../images/data-science-banner.png'
import { ProductBanner } from './ProductBanner'
import { ServiceOfferingItem } from './ServiceOfferingItem'

export const DataScienceRoute = (): ReactElement => {
  const isMedium = useBreakpoint('md')

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        route: 'DataScience',
      },
    })
  }, [])

  return (
    <main tw="relative">
      <section tw="px-6 py-4 sm:px-8">
        <Breadcrumbs items={[{ text: 'Home', href: '/' }, { text: 'Data Science Solutions' }]} />
      </section>

      <section tw="m-auto w-full max-w-5xl px-6 py-8 sm:px-8 md:overflow-hidden">
        <ProductBanner
          title="Data Science Solutions"
          description="Fuel brand performance with AI-enabled insights."
          icon={DataScienceIcon}
          bgImage={DataScienceBannerPNG}
          buttonAction={() => scrollTo('#data-science-request', { offset: 164 })}
        />
      </section>

      <section
        id="data-science-overview"
        tw="m-auto w-full max-w-5xl px-6 pb-8 sm:px-8 md:overflow-hidden"
      >
        <h2 tw="mb-7 mt-0">Overview</h2>
        <p tw="text-lg">
          Cart.com&apos;s team of expert data scientists are at your service, giving brands the
          statistical advantage to scale your business. Our processes surface the most impactful
          problems in your omni-channel value chain and deliver actionable insights to improve
          business strategy, growth, and profitability.
        </p>
      </section>

      <section id="data-science-offerings" tw="bg-white py-9">
        <div tw="mx-auto w-full max-w-5xl sm:px-8">
          <h2 tw="mb-7">Service Offerings</h2>

          <div tw="grid gap-7 md:grid-cols-2 lg:grid-cols-3">
            <ServiceOfferingItem>
              <h3 tw="mb-2 text-xl">Cart Pixel</h3>
              <p tw="m-0">
                With Cart Pixel, brands of all sizes can infuse artificial intelligence into their
                marketing efforts to deepen understanding of the buyer journey, optimize marketing
                strategies to get greater return on their ad dollars and improve website conversion
                rates.
              </p>
            </ServiceOfferingItem>

            <ServiceOfferingItem>
              <h3 tw="mb-2 text-xl">Omni-Channel Analysis</h3>
              <p tw="m-0">
                Whether you’re a DTC brand or multi-faceted enterprise, we create a Unified
                Analytics hub across all sales channels and logistics networks to discover your true
                performance. Our proprietary segmentation processes are then tailored to fit your
                marketing strategies and business verticals, giving you unprecedented granularity.
              </p>
            </ServiceOfferingItem>

            <ServiceOfferingItem>
              <h3 tw="mb-2 text-xl">Demand Forecasting</h3>
              <p tw="m-0">
                Knowing the future demand for your products unlocks new opportunities that drive
                better profits. Using our forecasting and optimization models, brands can better
                meet demand of popular items without slow-moving stock draining your storage costs.
                With less money tied up in inventory, more of your investments go towards research
                and development or for increasing ad spend to drive sales.
              </p>
            </ServiceOfferingItem>

            <ServiceOfferingItem>
              <h3 tw="mb-2 text-xl">Campaign Optimization</h3>
              <p tw="m-0">
                Pricing is both an art and a science, need not be static, and must be increasingly
                personalized and adaptive. Our team includes pricing economists who can work with
                you to analyze competitive pricing, measure price elasticity of demand, run price
                sensitivity analyses, and model revenue and various price points and pricing models
                to dial in a pricing strategy that drives top line and bottom line growth.
              </p>
            </ServiceOfferingItem>

            <ServiceOfferingItem>
              <h3 tw="mb-2 text-xl">Buyer Journey Modeling</h3>
              <p tw="m-0">
                Brands have normalized low conversion, believing higher traffic is required to drive
                more sales. Our Signals Repository, along with proven Google Tag Manager strategies,
                gives brands the visibility of where fallout is occurring in the shopping process.
                These insights are then organized into prescriptive actions to improve the buyer’s
                journey, resulting in higher sales.
              </p>
            </ServiceOfferingItem>

            <ServiceOfferingItem>
              <h3 tw="mb-2 text-xl">Customer Experience Analysis</h3>
              <p tw="m-0">
                Sentiment and emotion are powerful predictors in customer loyalty and retention, but
                often manifest themselves outside of the purchasing process. Our expertise in
                separating actionable signals from noisy sources like reviews, customer service
                channels, and social media helps brands create interaction models to increase
                Customer Lifetime Value. We also uncover emerging competitor, product safety, and
                brand reputation risks to identify new opportunities for performance improvement.
              </p>
            </ServiceOfferingItem>
          </div>
        </div>
      </section>

      <section tw="m-auto w-full max-w-3xl px-6 py-8 sm:px-8 justify-center">
        <h2 id="data-science-request">Service Request</h2>
        <p tw="mb-8 text-base">Please fill out the below form to request the service.</p>
        <DataScienceForm />
      </section>

      <section tw="[max-width: 100vw] m-auto w-full px-6 py-8 sm:([max-width: calc(100vw - 100px)] px-8) lg:max-w-5xl">
        <h2 tw="mb-6 pl-4 text-2xl lg:pl-0">Recommendations</h2>
        <Recommendations pageToOmit="/data-science-solutions" />
      </section>
    </main>
  )
}
