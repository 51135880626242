import { ISidebarItem } from '@brand-console/types'
import { useRouter } from '@cart/ui'
import flattenDeep from 'lodash/flattenDeep'
import React, { memo } from 'react'
import { Route, Routes } from 'react-router-dom'

import { ProtectedRoute } from '../auth/ProtectedRoute'
import { useIsomorphicLayoutEffect } from '../hooks'
import { Route404 } from './404Route'

const AppRoutes = memo(
  ({ siteNavigation }: { siteNavigation: ISidebarItem[] }) => {
    const { location } = useRouter()

    useIsomorphicLayoutEffect(() => {
      document.body.scrollTo(0, 0)
    }, [location.pathname])

    if (!siteNavigation) {
      return null
    }
    const routes = [siteNavigation[0], ...flattenDeep(siteNavigation[0].children)]
    return (
      <Routes>
        {routes.map(($route) => {
          const { component: Component, route } = $route
          const guid = Math.random().toString(36).slice(2)
          if (Array.isArray(route)) {
            return route.map((r) => (
              <Route
                element={<ProtectedRoute component={Component} />}
                key={`${guid}-${r}`}
                path={r}
              />
            ))
          }
          return (
            <Route key={guid} path={route} element={<ProtectedRoute component={Component} />} />
          )
        })}
        <Route path="*" element={<Route404 />} />
      </Routes>
    )
  },
  () => true,
)

export { AppRoutes }
