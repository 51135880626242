import { DeprecatedButton, DeprecatedDialog } from '@cart/ui'
import React from 'react'

type Props = {
  isOpen: boolean
  setIsOpen: (val: boolean) => void
  deleteCallback: () => void
  title: string
  describe: string
  width?: number
  height?: number
}

export const DeleteModal = ({
  isOpen,
  setIsOpen,
  deleteCallback,
  title,
  describe,
  width,
  height,
}: Props) => {
  return (
    <DeprecatedDialog
      id="my-dialog"
      title={title}
      show={isOpen}
      close={() => setIsOpen(false)}
      width={width}
      height={height}
    >
      <DeprecatedDialog.Body>
        <p>{describe}</p>
      </DeprecatedDialog.Body>
      <DeprecatedDialog.Actions>
        <DeprecatedButton
          text="Cancel"
          variant="tertiary"
          behavior="warning-primary"
          onClick={() => setIsOpen(false)}
        />
        <DeprecatedButton text="Delete" behavior="warning-primary" onClick={deleteCallback} />
      </DeprecatedDialog.Actions>
    </DeprecatedDialog>
  )
}
