import { Spinner, useAppContext } from '@cart/ui'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { Route404 } from '../../routes/404Route'
import { FeedMarketingConfirmPhoneRoute } from '../../routes/FeedMarketingConfirmPhoneRoute/FeedMarketingConfirmPhoneRoute'
import { FeedMarketingContactUsRoute } from '../../routes/FeedMarketingContactUsRoute/FeedMarketingContactUsRoute'
import { FeedMarketingTrialRoute } from '../../routes/FeedMarketingTrialRoute/FeedMarketingTrialRoute'
import { AccountConfirmFormProvider } from '../AccountConfirmForm'

export const FeedMarketing = () => {
  const { dfwAccount } = useAppContext()

  if (dfwAccount?.provisioned) {
    window.location.href = `${process.env.NX_DFW_AUTHENTICATE_API_URL}?provider=cart_openid`
    return <Spinner tw="m-auto h-64 w-64" type="global" />
  }

  return (
    <Routes>
      <Route path="/contact-us" element={<FeedMarketingContactUsRoute />} />
      <Route path="/free-trial/confirm-phone" element={<FeedMarketingConfirmPhoneRoute />} />
      <Route path="/" element={<FeedMarketingTrialRoute />} />
      <Route path="*" element={<Route404 />} />
    </Routes>
  )
}
