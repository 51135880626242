import { Alert, AlertTitle, Button, Input } from '@cart/ui'
import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import tw from 'twin.macro'

export const businessError = 'businessError'

export const Form = () => {
  return (
    <>
      <div tw="mb-6 py-0">
        <p tw="text-base">
          A great store needs a great name! Your online store&apos;s name will also be the subdomain
          you&apos;ll use. Don&apos;t worry, this can be changed later in the settings.
        </p>
      </div>
      <Input
        id="storeName"
        label="Storefront name"
        placeholder="Your store name"
        inputProps={{ maxLength: 29 }}
        rules={{
          required: {
            value: true,
            message: 'Store names can not be empty.',
          },
          pattern: {
            value: /^[a-zA-Z0-9]+$/g,
            message: 'Valid store names are alphanumeric and do not have special characters.',
          },
        }}
        endAdornment={<span tw="text-monochrome-600 text-sm">.americommerce.com</span>}
      />
    </>
  )
}

export type FormErrorsProps = {
  errors: UseFormReturn['formState']['errors']
  showFailureAlert: boolean
  errorHeader: string
  errorCode?: number
  handleAlertClose: () => void
}
export const FormErrors = ({
  errors,
  showFailureAlert,
  errorHeader,
  errorCode,
  handleAlertClose,
}: FormErrorsProps) =>
  errors[businessError] && showFailureAlert ? (
    <Alert severity="error" tw="mb-6" onClose={handleAlertClose}>
      <AlertTitle>{errorHeader}</AlertTitle>
      {errors[businessError].message as unknown as string}
      {errorCode && <small> #{errorCode}</small>}
    </Alert>
  ) : null
