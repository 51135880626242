import { createContext, useContext } from 'react'
import { useAccountConfirmForm } from '../hooks/useAccountConfirmForm'
import { AccountConfirmFormContextValue } from './AccountConfirmFormProvider.types'

export const AccountConfirmFormContext = createContext<AccountConfirmFormContextValue | null>(null)

export const useAccountConfirmContext = () => {
  const context = useContext(AccountConfirmFormContext)
  if (!context) throw new Error('AccountConfirmFormContext must be used with AccountConfirmFormProvider!')
  return context
}
