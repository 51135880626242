import tw, { css, styled } from 'twin.macro'

import { TooltipProps } from './tooltip.types'

export const StyledTooltipWrapper = styled.div(() => [tw`whitespace-normal`])

export const StyledTooltip = styled.section(({ contentLength }: TooltipProps) => [
  tw`z-50 max-w-xs rounded bg-monochrome-800 px-2.5 py-1 text-white text-xs`,
  (contentLength as number) > 50 &&
    css`
      min-width: 200px;
    `,
  css`
    &[data-popper-placement^='top'] > .tooltip-arrow {
      bottom: -4px;
    }
    &[data-popper-placement^='bottom'] > .tooltip-arrow {
      top: -4px;
    }
    &[data-popper-placement^='left'] > .tooltip-arrow {
      right: -4px;
    }
    &[data-popper-placement^='right'] > .tooltip-arrow {
      left: -4px;
    }
    .tooltip-arrow,
    .tooltip-arrow::before {
      position: absolute;
      width: 8px;
      height: 8px;
      background: inherit;
    }
    .tooltip-arrow {
      visibility: hidden;
    }
    .tooltip-arrow::before {
      visibility: visible;
      content: '';
      transform: rotate(45deg);
    }
  `,
])
