import { Alert, AlertTitle, Button, CardContent, Dialog, Link } from '@cart/ui'
import DialogActions from '@mui/material/DialogActions'
import React, { FC, useState } from 'react'
import tw from 'twin.macro'

import {
  AccountConfirmFormContext,
  AccountConfirmForm,
  useAccountConfirmContext,
  useAccountConfirmForm,
  AccountConfirmFormProvider,
} from '../AccountConfirmForm'
import { FormProvider, useForm } from 'react-hook-form'
import { logger } from '@brand-console/utilities'
import { useCartAuth, useCurrentContext } from '@cartdotcom/auth'

export type IncompleteProfileAlertProps = { onComplete?: () => void }
export const IncompleteProfileAlert: FC<IncompleteProfileAlertProps> = ({ onComplete }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showSuccessBanner, setShowSuccessBanner] = useState(false)
  const [showAlert, setShowAlert] = useState(true)
  const [showUserForm, setShowUserForm] = useState(false)

  const { firstName, lastName, phone } = useCartAuth()
  const { currentBusiness, currentOrganization } = useCurrentContext()
  const log = logger.setLogger(logger.LoggerNames.PROFILE)
  const methods = useForm({ mode: 'onChange' })
  const formData = useAccountConfirmForm({ methods, log })
  const { handleSubmit } = methods
  const { submitForm: submitAccountForm, shouldShowForm, resetForm } = formData

  const onSubmit = async (data) => {
    const dataWithDefaultValues = {
      firstName: data.firstName || firstName,
      lastName: data.lastName || lastName,
      phone: data.phone || phone,
      businessName: data.businessName || currentBusiness?.name,
      orgName: data.orgName || currentOrganization?.name,
      ...data,
    }

    setIsSubmitting(true)

    if (
      !(await submitAccountForm({
        data: dataWithDefaultValues,
        onError: (error, friendlyErrorMessage, scope) => {
          log.error(
            'Error submitting account confirmation form',
            {
              friendlyErrorMessage,
              scope,
            },
            error,
          )
          setIsSubmitting(false)
        },
      }))
    ) {
      return
    }

    if (onComplete) onComplete()
    setShowUserForm(false)
    setShowAlert(false)
    setShowSuccessBanner(true)
    setIsSubmitting(false)
  }

  const handleOpen = () => {
    setShowUserForm(true)
    resetForm()
  }

  if (shouldShowForm && showAlert) {
    return (
      <>
        <Dialog
          width="md"
          open={showUserForm}
          hideCloseButton
          onClose={() => setShowUserForm(false)}
        >
          <AccountConfirmFormProvider value={formData} methods={methods}>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <CardContent tw="px-6 pt-0 pb-6">
                <AccountConfirmForm
                  showErrorAlert
                  title="Complete your account setup"
                  dialogTitle
                />
              </CardContent>
              <CardContent tw="px-6 pt-0">
                <DialogActions tw="p-0">
                  <Button type="button" onClick={() => setShowUserForm(false)}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" loading={isSubmitting}>
                    Save
                  </Button>
                </DialogActions>
              </CardContent>
            </form>
          </AccountConfirmFormProvider>
        </Dialog>
        <Alert tw="mb-4" severity="info" onClose={() => setShowAlert(false)} data-testid="incomplete-profile-alert">
          <AlertTitle>Your profile information is incomplete</AlertTitle>
          Welcome to Cart! Complete your account setup by clicking{' '}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link component="button" onClick={handleOpen} data-testid="incomplete-profile-trigger">
            here.
          </Link>
        </Alert>
      </>
    )
  }
  if (showSuccessBanner) {
    return (
      <Alert severity="success" tw="mb-4" onClose={() => setShowSuccessBanner(false)}>
        <AlertTitle tw="mb-0">Your profile information is complete!</AlertTitle>
      </Alert>
    )
  }

  return null
}
