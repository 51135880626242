import { gql } from 'graphql-request'

import { gqlClient } from '../gqlClient'

const queryAssignedPermissionCheck = gql`
  query AssignedPermissionCheck($permissionName: String, $businessId: Int, $organizationId: Int) {
    org_assignedPermissionCheck(
      permissionName: $permissionName
      businessId: $businessId
      organizationId: $organizationId
    ) {
      access
    }
  }
`

export const permissionCheck = async (
  token: string,
  permissionName: string,
  businessId: number,
  organizationId: number,
): Promise<boolean> => {
  const response: any = await gqlClient.request(
    queryAssignedPermissionCheck,
    { permissionName, businessId, organizationId },
    {
      Authorization: `Bearer ${token}`,
    },
  )

  return response.org_assignedPermissionCheck?.access
}
