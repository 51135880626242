import React, { FC } from 'react'

import {
  StyledCard,
  StyledCardBody,
  StyledCardFooter,
  StyledCardHeader,
  StyledCardHeaderContainer,
  StyledCardSubHeader,
} from './deprecated-card.styles'
import { CardProps } from './deprecated-card.types'

export const DeprecatedCard: FC<CardProps & Record<string, unknown>> = ({
  children,
  footer,
  noPadding = false,
  title,
  subtitle,
  ...props
}: CardProps) => (
  <StyledCard {...props} data-testid="card">
    {title && (
      <StyledCardHeaderContainer>
        <StyledCardHeader data-nopadding={noPadding}>{title}</StyledCardHeader>
        {subtitle && (
          <StyledCardSubHeader data-nopadding={noPadding}>{subtitle}</StyledCardSubHeader>
        )}
      </StyledCardHeaderContainer>
    )}
    <StyledCardBody
      data-id="card-body"
      data-footer={footer}
      data-nopadding={noPadding}
      data-title={title}
    >
      {children}
    </StyledCardBody>
    {footer && (
      <StyledCardFooter data-id="card-footer" data-nopadding={noPadding}>
        {footer}
      </StyledCardFooter>
    )}
  </StyledCard>
)

export default DeprecatedCard
