import { styled } from 'twin.macro'

type StylesType = {
  navBar?: boolean
}

export const TrackVertical = styled.div<StylesType>`
  top: 2px;
  bottom: 2px;
  right: 2px;
  border-radius: 3px;
  ${({ navBar }) => (navBar ? '' : 'background-color: #ECECEC;')}
`

export const ThumbVertical = styled.div<StylesType>`
  position: relative;
  display: block;
  width: 100%;
  cursor: pointer;
  border-radius: inherit;
  ${({ navBar }) => (navBar ? 'background-color: #FFFFFF;' : 'background-color: #115B86;')}
`

export const TrackHorizontal = styled.div<StylesType>`
  height: 6px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  border-radius: 3px;
  ${({ navBar }) => (navBar ? '' : 'background-color: #ECECEC;')}
`

export const ThumbHorizontal = styled.div<StylesType>`
  position: relative;
  display: block;
  width: 100%;
  cursor: pointer;
  border-radius: inherit;
  ${({ navBar }) => (navBar ? 'background-color: #FFFFFF;' : 'background-color: #115B86;')}
`
