import {
  useOrganizationRoleListQuery,
  useOrganizationUsersInviteMutation,
} from '@brand-console/generated-graphql-hooks'
import { Role, RolesNames } from '@brand-console/types'
import { logger } from '@brand-console/utilities'
import { isAtLeastOrgAdmin, isAtLeastOrgManager, isOrgRole } from '@cartdotcom/auth'
import { useCallback, useState } from 'react'

import { InviteUserProps } from './useAddUser.types'

export interface AddUserProps {
  roleName: string
  log: typeof logger
  onlyBusinessRoles?: boolean
}
export const useAddUser = ({ roleName, log, onlyBusinessRoles }: AddUserProps) => {
  const [roles, setRoles] = useState<Role[]>([])
  const [inviteUserMutation, { loading: inviteLoading, reset }] =
    useOrganizationUsersInviteMutation()

  const isOrgAdminOrAbove = isAtLeastOrgAdmin(roleName)
  const isOrgManagerOrAbove = isAtLeastOrgManager(roleName)

  const getAllowedRoles = () => {
    const allowedRoles = [RolesNames.BUSINESS_ADMINISTRATOR, RolesNames.BUSINESS_MEMBER]
    if (onlyBusinessRoles) return allowedRoles
    if (isOrgAdminOrAbove) {
      allowedRoles.push(
        RolesNames.ORGANIZATION_ADMINISTRATOR,
        RolesNames.ORGANIZATION_MANAGER,
        RolesNames.ORGANIZATION_MEMBER,
      )
    } else if (isOrgManagerOrAbove) {
      allowedRoles.push(RolesNames.ORGANIZATION_MEMBER)
    }
    return allowedRoles
  }

  const { loading: loadingQuery } = useOrganizationRoleListQuery({
    onCompleted: (queryData) => {
      const allowedRoles = getAllowedRoles()
      const filteredRoles = queryData?.org_roleList?.data?.filter(({ name }) =>
        allowedRoles.includes(name as RolesNames),
      ) as Role[]
      setRoles(filteredRoles.sort((a, b) => a.name.localeCompare(b.name)))
    },
  })

  /** Invite users to the organization or business */
  const inviteUsers = useCallback(
    async ({ orgId, businessId, emails, role, onCompleted, onError }: InviteUserProps) => {
      const isOrgInvite = isOrgRole(role)
      const primaryEmails = emails.split(',').map((email) => email.trim())
      return inviteUserMutation({
        variables: {
          inviteUserInput: {
            businessId: isOrgInvite ? undefined : businessId,
            organizationId: isOrgInvite ? orgId : undefined,
            primaryEmails,
            roleNames: [role],
          },
        },
        onCompleted,
        onError: (error) => {
          log.error(
            "AddUser Error: Couldn't invite user",
            {
              orgId,
              businessId,
              primaryEmails,
              role,
            },
            error,
          )
          reset()
          onError?.(error)
        },
      })
    },
    [inviteUserMutation, log, reset],
  )

  return {
    roles,
    loading: loadingQuery || inviteLoading,
    inviteUsers,
  }
}
