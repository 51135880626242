import { SectionCardTitle, SectionDescription, SettingSectionProps } from '../shared'
import { StorefrontLaunch } from './StorefrontLaunch'

export const settingItems: SettingSectionProps[] = [
  {
    description: (
      <SectionDescription
        title="Account Details"
        content="Manage your plan and membership type, contact and multiple store information."
      />
    ),
    cardTitle: <SectionCardTitle title="Storefront Account Details" />,
    cardContent: (
      <StorefrontLaunch
        buttonText="Manage Account Details"
        relativePath="/store/admin/account/account.aspx?fromBc=1"
      />
    ),
  },
  {
    description: (
      <SectionDescription
        title="Domain Control"
        content="Manage your store URL and shared domain information, site security and DNS settings."
      />
    ),
    cardTitle: <SectionCardTitle title="Domain Control" />,
    cardContent: (
      <StorefrontLaunch
        buttonText="Manage Domain Information"
        relativePath="/store/admin/account/domaincontrol.aspx?fromBc=1"
      />
    ),
  },
  {
    description: (
      <SectionDescription
        title="User Preferences"
        content="Manage user preferences including online store public profile, group roles and other
        preferences."
      />
    ),
    cardTitle: <SectionCardTitle title="User Preferences" />,
    cardContent: (
      <StorefrontLaunch
        buttonText="Manage User Preferences"
        relativePath="/store/admin/settings/users/useredit.aspx?fullpage=1&loginuser=1&fromBc=1&shownav=1"
      />
    ),
  },
]
