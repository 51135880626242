import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog } from '@headlessui/react'
import tw, { css, styled } from 'twin.macro'

const { Overlay, Title } = Dialog

export const StyledDialog = styled(Dialog)(() => [
  tw`fixed inset-0 z-10 flex items-end justify-center overflow-y-auto sm:items-center`,
])

export const StyledDialogContainer = styled.div(() => [tw`w-full text-center sm:px-4`])

export const StyledDialogOverlay = styled(Overlay)(() => [tw`fixed inset-0 bg-monochrome-700/70`])

export const StyledDialogTitleContainer = styled.div((props: any) => [
  tw`relative flex justify-between rounded-t-md bg-monochrome-50 p-6`,
  !props['data-hastitle'] && [
    tw`h-0 overflow-visible p-0`,
    css`
      & > svg {
        ${tw`mt-6`}
      }
    `,
  ],
])

export const StyledDialogTitle = styled(Title)(() => [tw`mb-0 font-bold text-lg leading-6`])

export const StyledContentActions = styled.div((props: any) => [
  tw`flex flex-col flex-wrap`,
  props['data-hasactions'] ? tw`h-[calc(100% - 63px)]` : tw`h-full`,
])

export const StyledDialogContent = styled.div((props: any) => [
  tw`flex flex-auto max-h-[calc(100vh - 200px)] w-full overflow-auto p-6 min-h-[30vh] sm:min-h-[auto] lg:max-h-[750px]`,
  props['data-hasscrollingcontent'] &&
    css`
      box-shadow: inset 0 -2px 2px 0px rgba(32, 33, 36, 0.1);
    `,
])

export const StyledDialogActions = styled.div(() => [
  tw`flex w-full flex-grow-0 justify-between p-6 sm:justify-end`,
  css`
    & > *:not(:last-child) {
      ${tw`sm:mr-3`}
    }
  `,
])

export const StyledDialogContentContainer = styled.div(() => [
  tw`relative m-0 inline-block w-full rounded-t-md bg-white text-left align-bottom shadow-xl max-w-[100vw]`,
  tw`min-w-full sm:(rounded-md align-middle min-w-[420px] max-w-[80vw])`,
])

export const StyledCloseIcon = styled(FontAwesomeIcon)(() => [
  tw`absolute right-6 mt-0.5 cursor-pointer text-xl text-monochrome-900/60 leading-none`,
])

export const overlayTransitionProps = {
  enter: tw`duration-300 ease-out`,
  enterFrom: tw`opacity-0`,
  enterTo: tw`opacity-100`,
  leave: tw`duration-200 ease-in`,
  leaveFrom: tw`opacity-100`,
  leaveTo: tw`opacity-0`,
}

export const contentTransitionProps = {
  enter: tw`duration-300 ease-out`,
  enterFrom: tw`scale-95 opacity-0 mb-[-50px]`,
  enterTo: tw`mb-0 scale-100 opacity-100`,
  leave: tw`duration-200 ease-in`,
  leaveFrom: tw`mb-0 scale-100 opacity-100`,
  leaveTo: tw`scale-95 opacity-0 mb-[-50px]`,
}
