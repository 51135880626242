import { gql } from 'graphql-request'

import { gqlClient } from '../gqlClient'

const queryGlobalRoleCheck = gql`
  query GlobalRoleCheck($roleName: String!) {
    org_assignedRoleCheck(roleName: $roleName) {
      access
    }
  }
`

export const isCsrCheck = async (token: string): Promise<boolean> => {
  const response: any = await gqlClient.request(
    queryGlobalRoleCheck,
    { roleName: 'customer_sales_rep' },
    {
      Authorization: `Bearer ${token}`,
    },
  )

  return response.org_assignedRoleCheck?.access
}
