import { localStorageHelper, LocalStorageKeys } from '@brand-console/utilities'
import { Spinner, useRouter } from '@cart/ui'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useEffect, useMemo, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { getExistConnectors } from '../helpers/functions'
import { MyConnectorsPage } from '../pages/MyConnectors/MyConnectorsPage'
import { AllConnectors } from '../pages/settings/DataConnectors/AllConnectors'
import { useGetDataConnectorsQuery } from '../stores/services/dataConnectorEndpoints'

const RedirectPage = React.lazy(() => import('../pages/RedirectPage/RedirectPage'))

export const RouteList = () => {
  const { uaTempShowNewMyConnectorsTable221004 } = useFlags()

  const [organizationId, setOrganizationId] = useState<string | undefined>(undefined)

  useEffect(() => {
    const orgId = localStorageHelper.get(LocalStorageKeys.ORGANIZATION)?.id
    setOrganizationId(orgId)
  }, [])

  const { data: dataConnectorsData, isFetching: dataConnectorsFetching } =
    useGetDataConnectorsQuery({ organizationId }, { skip: !organizationId })
  const hasDataConnectors = useMemo(() => {
    return getExistConnectors(dataConnectorsData).length > 0
  }, [dataConnectorsData])

  const router = useRouter()
  const { query } = router

  // @ts-ignore
  const { scope } = query

  return (
    <Routes>
      <Route path="/connectors" element={<Navigate to="/unified-analytics/my-connectors" />} />
      <Route path="/all-connectors" element={<AllConnectors />} />

      {uaTempShowNewMyConnectorsTable221004 && (
        <Route
          path="/my-connectors"
          element={
            hasDataConnectors || scope ? (
              <MyConnectorsPage />
            ) : (
              !dataConnectorsFetching &&
              dataConnectorsData !== undefined && (
                <Navigate to="/unified-analytics/all-connectors" />
              )
            )
          }
        />
      )}

      <Route
        path="*"
        element={
          <React.Suspense fallback={<Spinner type="inline" />}>
            <RedirectPage />
          </React.Suspense>
        }
      />
    </Routes>
  )
}
