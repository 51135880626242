import { UserType } from '../types/userTypes'
import { dsApi } from './dsApiSlice'

const userEndpoints = dsApi.injectEndpoints({
  endpoints: (builder) => ({
    // calling it dsMe to distinguish it from the useMe query in the org service
    dsMe: builder.query<UserType, void>({
      query: (organizationId) => {
        return `user/me/${
          typeof organizationId !== 'undefined' ? `?organization_id=${organizationId}` : ''
        }`
      },
    }),
  }),
  overrideExisting: false,
})

export const { useDsMeQuery } = userEndpoints
