import React from 'react'

import { IMenuItemsProps } from '../../unified-sidebar.types'
import { MenuItem } from './MenuItem'

export const MenuItems: React.FC<IMenuItemsProps> = ({ nodes, ...rest }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ul tw="m-0 ml-0 pl-0" {...rest}>
      {nodes.map((node) => {
        return <MenuItem key={node.title} node={node} />
      })}
    </ul>
  )
}
