export const getRuleValue = (rules: Record<string, any>, field: string) => {
  if (rules?.[field]?.value) return rules[field].value
  return rules?.[field]
}

export const addMessage = (
  rules: Record<string, any> | undefined,
  messagesOverwrite: Record<string, any>,
  field: string,
  getMessage: (value: any) => string,
) => {
  const output = messagesOverwrite
  if (rules?.[field] && !rules?.[field].message) {
    const value = getRuleValue(rules, field)
    output[field] = {
      ...rules[field],
      value,
      message: getMessage(value),
    }
  }
  return output
}
