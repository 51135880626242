/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum LocalStorageKeys {
  BUSINESS = 'business',
  ORGANIZATION = 'organization',
}

export const localStorageHelper = {
  set: (key: LocalStorageKeys, value: any) => {
    try {
      if (window?.localStorage) {
        if (value) {
          window.localStorage.setItem(key, JSON.stringify(value))
        } else {
          window.localStorage.removeItem(key)
        }
      }
    } catch {}
  },

  get: (key: LocalStorageKeys) => {
    try {
      if (window?.localStorage) {
        const value = window.localStorage.getItem(key)
        return value ? JSON.parse(value) : null
      }
    } catch {}
    return null
  },

  getKeyStartingWith: (keyStartingWith: string) => {
    try {
      if (window?.localStorage) {
        const keys = Object.keys(window.localStorage)
        const matchingKey = keys.find((key) => key.startsWith(keyStartingWith))
        if (matchingKey) {
          return localStorage.getItem(matchingKey)
        }
      }
    } catch {}
    return null
  },

  remove: (key: LocalStorageKeys) => {
    if (window?.localStorage) {
      window.localStorage.removeItem(key)
    }
  },
}
