import { MeUser } from '../../common.types'
import { me } from '../../gql/queries/me.query'

export const parseData = (user: MeUser) => {
  if (!user) return null
  const contextSetting = user.userAppSettings?.find((s) => s.type === 'CURRENT_BUSINESS_CONTEXT')
  const context = contextSetting ? JSON.parse(contextSetting.setting) : undefined

  const organization =
    user.organizations.find((o) => o.id === context?.organizationId) || user.organizations[0]
  const business =
    organization?.businesses.find((b) => b.id === context?.businessId) ||
    organization?.businesses[0]

  return {
    initialDataLoaded: true,
    userId: user.id,
    auth0Id: user.auth0Id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.primaryEmail,
    phone: user.userData?.phone,
    createdAt: user.createdAt,
    roleName: organization?.roleName || business?.roleName || null,
    company: user.userData?.company,
    organizations: user.organizations.sort((a, b) => a.name.localeCompare(b.name)),
    currentOrganization: organization,
    currentBusiness: business,
  }
}

export const initializeStore = async () => {
  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
  const getMe = async (count: number): Promise<any> => {
    await sleep(count * 100)
    const user = await me()
    if (!user && count < 10) return getMe(count + 1)
    return user
  }
  const user = await getMe(0)
  return parseData(user)
}
