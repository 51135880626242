import { nanoid } from 'nanoid'
import React, { ReactElement } from 'react'

export const FeedMarketingIcon = ({ className }: { className?: string }): ReactElement => {
  const title = 'Feed Marketing Icon'
  const idA = nanoid()
  const idB = nanoid()
  const idC = nanoid()
  return (
    <svg
      viewBox="0 0 64 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={title}
      className={className}
      role="img"
    >
      <g clipPath="url(#clip0_11775_76214)">
        <path
          d="M49.3352 12.5439V22.9416C49.3352 24.6623 48.2423 26.1641 46.6625 26.6125L38.7936 28.8756V11.2298C38.7936 11.0213 38.7837 10.8023 38.7539 10.5937L44.745 8.86247C47.06 8.20544 49.3352 10.0305 49.3352 12.5439Z"
          fill="#DA7009"
        />
        <path
          d="M56.5975 22.2636V32.6613C56.5975 34.3821 55.5046 35.8838 53.9249 36.3323L38.7832 40.6916V22.3992L49.3248 19.3644L52.0073 18.5926C54.3322 17.9252 56.5975 19.7503 56.5975 22.2636Z"
          fill="#005982"
        />
        <path
          d="M63.8703 31.9834V42.3811C63.8703 44.1018 62.7774 45.6036 61.1977 46.0521L38.7832 52.4554V34.163L56.5975 29.0841L59.2801 28.3124C61.5951 27.6449 63.8703 29.4804 63.8703 31.9834Z"
          fill="#DA7009"
        />
        <path
          d="M38.7832 11.2298V22.3992L21.7339 27.3008C20.1541 27.7493 19.0612 29.2615 19.0612 30.9718V34.5489L14.0041 35.9986C11.6991 36.666 9.42383 34.8409 9.42383 32.3276V21.9299C9.42383 20.2091 10.5167 18.7074 12.0965 18.2589L38.7335 10.5936C38.7732 10.8022 38.7832 11.0108 38.7832 11.2298Z"
          fill={`url(#${idA})`}
        />
        <path
          d="M38.7844 22.3992V34.1631L31.3726 36.2802C29.7928 36.7286 28.6999 38.2408 28.6999 39.9512V43.5909L23.6428 45.0405C21.3278 45.7079 19.0625 43.8724 19.0625 41.3695V30.9718C19.0625 29.2511 20.1554 27.7493 21.7351 27.3008L38.7844 22.3992Z"
          fill={`url(#${idB})`}
        />
        <path
          d="M38.7837 34.163V52.4554L33.2795 54.0197C30.9645 54.6872 28.6992 52.8517 28.6992 50.3488V39.9511C28.6992 38.2303 29.7921 36.7285 31.3719 36.2801L38.7837 34.163Z"
          fill={`url(#${idC})`}
        />
        <path
          d="M38.7836 52.4554V55.4798C38.7836 58.4521 36.0911 60.6213 33.3489 59.8391L7.42723 53.1229C5.54943 52.591 4.25781 50.7972 4.25781 48.7636V4.51354C4.25781 1.54129 6.95033 -0.627931 9.69252 0.16467L35.6142 6.88091C37.2933 7.36065 38.5054 8.84156 38.7439 10.604L12.1068 18.2589C10.5172 18.7073 9.42426 20.2195 9.42426 21.9299V32.3276C9.42426 34.8409 11.6995 36.666 14.0045 35.9985L19.0617 34.5489V41.3695C19.0617 43.8828 21.3369 45.7079 23.6419 45.0404L28.6991 43.5908V50.3488C28.6991 52.8622 30.9743 54.6872 33.2794 54.0198L38.7836 52.4554Z"
          fill="#069AE3"
        />
      </g>
      <defs>
        <linearGradient
          id={idA}
          x1="26.4044"
          y1="8.31146"
          x2="20.9717"
          y2="37.6643"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#069AE3" />
          <stop offset="1" stopColor="#DA7009" />
        </linearGradient>
        <linearGradient
          id={idB}
          x1="35.3755"
          y1="20.33"
          x2="20.6941"
          y2="44.5557"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#069AE3" />
          <stop offset="1" stopColor="#005982" />
        </linearGradient>
        <linearGradient
          id={idC}
          x1="42.0361"
          y1="40.0747"
          x2="27.028"
          y2="48.3296"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#069AE3" />
          <stop offset="1" stopColor="#DA7009" />
        </linearGradient>
        <clipPath id="clip0_11775_76214">
          <rect width="63.871" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
