import { useCurrentContext, useIsCsr, useMcmEnabled, useSfEnabled } from '@cartdotcom/auth'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Menu } from '../../Menu/Menu'
import { showHideMenuItems } from '../Shared/visibility.helper'
import { bcBaseMenu } from './BcMenu.menu'
import { BcMenuIds } from './BcMenu.types'

export const BcMenu = () => {
  const {
    ordersTempEnableOrders230328,
  } = useFlags()

  const { currentBusiness } = useCurrentContext()

  const isCsr = useIsCsr()
  const isMcmEnabled = useMcmEnabled(currentBusiness?.id)
  const isSfEnabled = useSfEnabled(currentBusiness?.id)

  const menu = bcBaseMenu
  // enhance menu with options

  const mainMenuVisibility = {
    [BcMenuIds.ORDERS]: ordersTempEnableOrders230328,
  }

  showHideMenuItems(menu[0].items, mainMenuVisibility)

  const settingsMenuVisibility = {
    [BcMenuIds.ADMIN_TOOLS]: isCsr,
    [BcMenuIds.MCM_SETTINGS]: isMcmEnabled,
    [BcMenuIds.STOREFRONT_SETTINGS]: isSfEnabled,
  }

  showHideMenuItems(menu[1].items[0].items, settingsMenuVisibility)

  return <Menu menuSections={menu} />
}
