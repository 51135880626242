import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { StoreStateType } from '../../auth.types'
import { setCurrentOrgAndBusiness } from './auth.store.helpers'
import { initializeStore } from './initializer.store'

export const useCartAuthStore = create<StoreStateType>()(
  devtools(
    (set) => ({
      initialDataLoaded: false,
      isLoadingInitialData: false,
      setIsLoadingInitialData(isLoading) {
        set({ isLoadingInitialData: isLoading })
      },
      fetchInitialData: async () => {
        const data = await initializeStore()

        if (data) {
          set(data, false, 'fetchInitialData')
        } else set({ initialDataLoaded: true }, false, 'fetchInitialData')
      },
      setInitialData: (data) => {
        if (data) set(data, false, 'setInitialData')
        else set({ initialDataLoaded: true }, false, 'setInitialData')
      },
      organizations: [],
      setOrganization: (organization, selectedBusiness) =>
        set(
          ({ organizations }) =>
            setCurrentOrgAndBusiness(
              organization,
              selectedBusiness || organization.businesses[0],
              organizations,
            ),
          false,
          'setOrganization',
        ),
      setBusiness: (organization, business) =>
        set(
          ({ organizations }) => setCurrentOrgAndBusiness(organization, business, organizations),
          false,
          'setBusiness',
        ),
      setCurrentContext: (organization, business) =>
        set(
          () => {
            return {
              roleName: organization?.roleName || business?.roleName || null,
              currentOrganization: organization,
              currentBusiness: business,
            }
          },
          false,
          'setCurrentContext',
        ),
      setUser: (user) => set({ ...user }, false, 'setUser'),
    }),
    { name: 'auth-data' },
  ),
)

// useCartAuthStore.getState().fetchInitialData()
