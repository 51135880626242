import { useFlags } from 'launchdarkly-react-client-sdk'
import { useMemo } from 'react'

import { dataConnectorsData as originalDataConnectorsCardData } from '../../helpers/data'
import { useGetDataConnectorsCardQuery } from '../../stores/services/dataConnectorEndpoints'

export function useDataConnectorsData() {
  const { uaTempShowShopifyConnectorCard220613 } = useFlags()
  const organizationId = JSON.parse(localStorage.getItem('organization'))?.id

  const { data } = useGetDataConnectorsCardQuery(organizationId)

  const dataConnectorsCardData = useMemo(() => {
    let ineligibleConnectorNames = []
    if (data) {
      ineligibleConnectorNames = Object.keys(data?.data)
        .filter((dcKey) => data?.data[dcKey].length === 0 && !dcKey.startsWith('storefront'))
        .map((dcKey) => dcKey.replace('_connectors', '').replaceAll('_', '-'))
    }

    if (!uaTempShowShopifyConnectorCard220613 && !ineligibleConnectorNames.includes('shopify')) {
      ineligibleConnectorNames.push('shopify')
    }

    return originalDataConnectorsCardData.filter(
      (dataConnector) => !ineligibleConnectorNames.includes(dataConnector.name),
    )
  }, [data, uaTempShowShopifyConnectorCard220613])
  return { dataConnectorsData: dataConnectorsCardData }
}
