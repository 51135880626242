import { Button } from '@cart/ui/atoms/Button'
import { css, styled } from 'twin.macro'

export const StyledMenuButton = styled(Button)(() => [
  css`
    min-width: 0px !important;
    height: 48px;
    width: 48px;
  `,
])
