export enum Plans {
  SHOP = 'Shop',
  MERCHANT = 'Merchant',
  AGENCY = 'Agency',
  ENTERPRISE = 'Enterprise',
}

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
}

export interface IPriceObject {
  priceMonthContract?: {
    USD?: number
  }
  priceYearContract?: {
    USD?: number
  }
  plan: Plans
}

export interface IPriceTable {
  [key: string | number]: IPriceObject
}

export interface IDataFeedWatchPricingPlanDetails {
  additional_extra_products_price: number
  analytics: boolean
  automatic_feed_reviews: string
  base_channels: string | number
  base_daily_downloads: number
  base_feed_setups: number
  base_price: string | number
  base_products: number | string
  base_subscriptions: string | number
  contact_options: string[]
  cost_metrics_price: string | number | boolean
  currency: string
  dedicated_account_manager: boolean
  extra_shop_price: number
  free_feed_setup: boolean
  free_trial: boolean
  gta_price: string | number
  id: number
  live_support: boolean
  marketplaces: boolean
  multiple_automatic_daily_updates: boolean
  multiple_data_sources_integration: boolean
  onboarding_live_sessions: string | number
  payment_term: number
  product_training: boolean
  sub_accounts: string | number | boolean
  tech_support_reply_time: string
}

export interface IDataFeedWatchPricingPlan {
  details: IDataFeedWatchPricingPlanDetails
  plan_name: string
  shortcut: string
  payment_term: number
}

export type IDataFeedWatchPricingPlans = IDataFeedWatchPricingPlan[]

export enum RouteState {
  'FAILURE',
  'SUCCESS',
}
