import MuiLoadingButton from '@mui/lab/LoadingButton'
import MuiIconButton from '@mui/material/IconButton'
import React, { FC } from 'react'
import tw, { css, styled } from 'twin.macro'

import { ButtonProps } from './button.types'

const getFontSize = (size?: 'small' | 'medium' | 'large') => {
  switch (size) {
    case 'small':
      return tw`!text-xs`
    case 'large':
      return tw`!text-base`
    default:
      return tw`!text-sm`
  }
}

export type StyledButtonProps = ButtonProps & { $square?: boolean }
export const StyledButton: FC<StyledButtonProps> = styled(MuiLoadingButton)<StyledButtonProps>(
  ({ size, $contrastText, to, LinkComponent, $square }: StyledButtonProps) => [
    LinkComponent && to && tw`w-max`,
    tw`![text-transform: unset]`,
    $square ? tw`!(min-w-0 rounded p-3.5)` : '',
    getFontSize(size),
    css`
      &[href]:not(.MuiButton-outlined) {
        color: ${$contrastText};
      }
    `,
  ],
)

export const StyledButtonComponent = (props: StyledButtonProps) => {
  const { to, LinkComponent, $square, $contrastText, size } = props
  const styles = css`
    ${LinkComponent && to && tw`w-max`}
    ${tw`![text-transform: unset]`}
    ${$square ? tw`!(min-w-0 rounded p-3.5)` : ''}
    ${getFontSize(size)}
    & [href]: not(.MuiButton - outlined) {
      color: ${$contrastText};
    }
  `
  const buttonProps = { ...props } as any
  delete buttonProps.$contrastText
  delete buttonProps.$square
  return <MuiLoadingButton css={styles} {...buttonProps} />
}

export type StyledIconButtonProps = ButtonProps & { $square?: boolean }
export const StyledIconButton: FC<StyledIconButtonProps> = styled(
  MuiIconButton,
)<StyledIconButtonProps>(({ size, $square }: StyledIconButtonProps) => {
  return [tw`![text-transform: unset]`, getFontSize(size), $square ? tw`!(rounded p-3.5)` : '']
})
