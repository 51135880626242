import { Card, CardContent, Carousel, CarouselItem, StatusBadge, useBreakpoint } from '@cart/ui'
import React from 'react'

import { useDataConnectorsData } from '../../hooks'
import { ButtonSetup } from './MyConnectorsModal/components/Modal/ButtonSetup'

export const MyConnectorsCarousel = ({
  setShow,
  mostPopularConnectorsList,
  packageTier,
  packageDataConnectorsList,
}) => {
  const isMedium = useBreakpoint('md')

  const { dataConnectorsData } = useDataConnectorsData()
  const mostPopularDataConnectors = mostPopularConnectorsList
    .map(({ title }) => dataConnectorsData.find(({ connectorName }) => connectorName === title))
    .filter((item) => !!item)

  const cardButton = (name, button) => {
    if (button) {
      if (
        name === 'google-adwords' ||
        name === 'google-analytics' ||
        name === 'google-search-console'
      ) {
        return <ButtonSetup connectorName={name} setShow={setShow} text="Sign in with Google" />
      }
      return (
        <div>
          <ButtonSetup connectorName={name} variant="contained" setShow={setShow} text="Add" />
        </div>
      )
    }
  }

  const upgradeButton = (name, button) => {
    if (button) {
      return (
        <div>
          <ButtonSetup
            connectorName={name}
            setShow={setShow}
            variant="outlined"
            text="Upgrade to connect"
          />
        </div>
      )
    }
  }

  const mostPopularCards = mostPopularDataConnectors.map(
    ({ title, icon, name, description, button, connectorName }) => (
      <Card key={name} tw="flex h-full flex-col justify-between p-1">
        <CardContent>
          <div tw="flex items-center justify-between">
            <div tw="flex items-center">
              <img alt={icon} src={icon} tw="w-[35px] mr-[15px]" />
              <h3 tw="mb-0">{title}</h3>
            </div>
            {!button ? <StatusBadge type="info" badgeContent="Coming Soon" /> : null}
          </div>
          <p tw="mb-4 pt-3">{description || 'no description provided'}</p>
          {packageTier === 'Free Trial'
            ? packageDataConnectorsList?.includes(connectorName)
              ? cardButton(name, button)
              : upgradeButton(name, button)
            : cardButton(name, button)}
        </CardContent>
      </Card>
    ),
    // ),
  )

  if (mostPopularCards.length === 0) {
    return null
  }

  return (
    <>
      <h2 tw="m-0 pt-8 pb-6">Most Popular</h2>
      <div tw="w-full pb-0 sm:pb-6">
        <Carousel loop slidesToScroll={isMedium ? 3 : 1}>
          {mostPopularCards?.map((card) => (
            <CarouselItem key={card && card.key}>{card}</CarouselItem>
          ))}
        </Carousel>
      </div>
    </>
  )
}
