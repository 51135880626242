import { nanoid } from 'nanoid'
import React, { ReactElement } from 'react'

export const MarketplaceManagementIcon = ({ className }: { className?: string }): ReactElement => {
  const title = 'Marketplace Management Icon'
  const idA = nanoid()
  const idB = nanoid()
  const idC = nanoid()
  const idD = nanoid()
  return (
    <svg
      aria-label={title}
      className={className}
      fill="none"
      role="img"
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11775_76109)">
        <circle cx="18.9" cy="18.9" r="18.9" fill="#069AE3" />
        <circle cx="21.3" cy="38.7" r="21.3" fill="#DA7009" />
        <circle cx="43.7996" cy="28.2" r="16.2" fill="#005982" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.0276 35.1741C34.3008 31.8208 37.7996 25.926 37.7996 19.214C37.7996 17.3325 37.5247 15.5151 37.0127 13.7999C31.4556 16.3674 27.5996 21.9904 27.5996 28.5141C27.5996 30.888 28.1102 33.1426 29.0276 35.1741Z"
          fill={`url(#${idA})`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.2253 19.2C37.5307 22.6128 42.5996 30.0853 42.5996 38.7546C42.5996 40.7081 42.3422 42.6008 41.8599 44.4C33.8273 43.4312 27.5996 36.5212 27.5996 28.141C27.5996 24.8419 28.5648 21.7706 30.2253 19.2Z"
          fill={`url(#${idB})`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.4004 28.4827C6.01775 21.8783 13.0331 17.4 21.0944 17.4C27.3147 17.4 32.9121 20.0663 36.8063 24.3183C34.477 32.1159 27.2495 37.8 18.6944 37.8C11.7537 37.8 5.68676 34.0587 2.4004 28.4827Z"
          fill={`url(#${idC})`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.0276 34.7054C28.1102 32.6739 27.5996 30.4192 27.5996 28.0453C27.5996 24.7815 28.5648 21.7431 30.2253 19.2C32.8088 20.394 35.1126 22.0903 37.0115 24.1636C35.6967 28.565 32.8214 32.2929 29.0276 34.7054Z"
          fill={`url(#${idD})`}
        />
      </g>
      <defs>
        <linearGradient
          id={idA}
          x1="31.7399"
          y1="12.205"
          x2="44.9478"
          y2="23.142"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1225" stopColor="#005982" />
          <stop offset="0.9787" stopColor="#069AE3" />
        </linearGradient>
        <linearGradient
          id={idB}
          x1="38.0398"
          y1="49.5"
          x2="23.9235"
          y2="29.0696"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1225" stopColor="#005982" />
          <stop offset="0.9787" stopColor="#069AE3" />
        </linearGradient>
        <linearGradient
          id={idC}
          x1="28.2496"
          y1="30.322"
          x2="26.12"
          y2="16.3017"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#069AE3" />
          <stop offset="1" stopColor="#DA7009" />
        </linearGradient>
        <linearGradient
          id={idD}
          x1="34.6707"
          y1="29.0216"
          x2="30.7652"
          y2="19.7676"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#069AE3" />
          <stop offset="1" stopColor="#DA7009" />
        </linearGradient>
        <clipPath id="clip0_11775_76109">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
