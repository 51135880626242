import { useCurrentContext } from '@cartdotcom/auth'
import { useEffect, useState } from 'react'

export const useMcmNavigation = () => {
  const [link, setLink] = useState<string | null>(null)
  const [onClick, setOnClick] = useState<(() => void) | null>(null)
  const { currentBusiness } = useCurrentContext()

  useEffect(() => {
    const url = `${process.env.NX_MCM_SSO_URL}?businessId=${currentBusiness?.id}`
    setOnClick(() => () => {
      window.location.href = url
    })
    setLink(url)
  }, [currentBusiness?.id])

  return { link, onClick }
}
