import { useAuth0 } from '@auth0/auth0-react'
import { useLayoutStore } from '@brand-console/utilities'
import { Button } from '@cart/ui/atoms/Button'
import { useOnClickOutside } from '@cart/ui/hooks/useOnClickOutside'
import { useCartAuth, useCurrentContext } from '@cartdotcom/auth'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from '@mui/material/Alert'
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { UNSAFE_LocationContext } from 'react-router-dom'
import tw from 'twin.macro'

import { StyledDivider, StyledMenu } from '../../header.styles'
import {
  ManageAccountButtonInternal,
  SwitchOrganizationButtonInternal,
} from './AccountDropdown.partials'
import { BusinessListItem } from './BusinessListItem'

export const AccountDropdown = () => {
  const { user } = useAuth0()
  const { onLogout, firstName, lastName, email, organizations } = useCartAuth()
  const { currentOrganization, currentBusiness } = useCurrentContext()
  const { pathname } = window.location
  const { logoutUrl, product } = useLayoutStore()
  const isReactRouterAvailable = !!useContext(UNSAFE_LocationContext)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState<boolean>(false)
  const activeOrg = currentOrganization
  const activeBiz = currentBusiness

  const businessList = activeOrg?.businesses

  const { businessEntityTerminology = 'Business' } = activeOrg || {}

  const sortedBusinessList = useMemo(() => {
    const array = businessList?.filter((biz) => biz.id !== activeBiz?.id)
    const sorted = array?.sort((a, b) => a.name.localeCompare(b.name)) || []
    return [activeBiz, ...sorted]
  }, [activeBiz, businessList])

  const menuRef = useRef(null)

  // TODO: Ideally this should be handled by product
  // When in Unified Analytics, the Account dropdown behaves differently:
  // - Businesses can't be selected
  // - Alert is presented with contextual message
  const isUnifiedAnalytics = pathname.startsWith('/unified-analytics')

  const openMenu = () => {
    setAnchorEl(document.querySelector('#account-dropdown-trigger') as never)
    setOpen(true)
  }

  const handleClose = useCallback(() => {
    setOpen(false)
    setAnchorEl(null)
  }, [])

  useOnClickOutside(menuRef, handleClose)

  const onLogoutClick = () => {
    const returnUrl = logoutUrl ? `${window.location.origin}${logoutUrl}` : window.location.origin
    onLogout({ returnUrl })
  }

  const onSwitchOrgClick = () => {
    const nav = product ? `?nav=${product}` : ''
    window.location.href = `${process.env.NX_APP_ORIGIN}/organization-selection${nav}`
  }

  if (!user) return null

  const formattedName =
    firstName || lastName ? `${firstName || ''} ${lastName || ''}`.trim() : email
  const userHasNoName = formattedName === email

  return (
    <div tw="mr-3">
      <button
        id="account-dropdown-trigger"
        onClick={openMenu}
        type="button"
        tw="border-none bg-white"
        aria-label="Open Account Menu"
        aria-controls="account-dropdown"
        aria-haspopup="true"
        aria-expanded={open}
      >
        <img
          src={user.picture}
          alt="User Profile"
          height={32}
          width={32}
          tw="mx-auto rounded-full"
        />
      </button>
      <StyledMenu
        anchorEl={anchorEl}
        id="account-dropdown"
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 8,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div ref={menuRef}>
          <div tw="px-4 pt-4 pb-6 text-center">
            <img
              src={user.picture}
              alt="User Profile"
              height={32}
              width={32}
              tw="mx-auto mt-0 rounded-full"
            />
            <p tw="m-0 mt-3.5 p-0 text-base" css={[userHasNoName && tw`mb-3.5`]}>
              {formattedName}
            </p>
            {!userHasNoName && (
              <p tw="mb-3.5 text-monochrome-600" data-testid="account-dropdown-email">
                {email}
              </p>
            )}

            {isReactRouterAvailable && <ManageAccountButtonInternal handleClose={handleClose} />}
            {!isReactRouterAvailable && (
              <Button
                variant="contained"
                onClick={() => {
                  window.location.href = `${process.env.NX_APP_ORIGIN}/settings/profile?nav=${product}`
                }}
              >
                Manage Account
              </Button>
            )}
          </div>
          <StyledDivider />
          {activeOrg && (
            <div tw="px-2 py-5">
              <dl tw="m-0 flex p-0 text-sm">
                <dt tw="pl-4 pr-2 font-normal text-xs text-monochrome-900/60 uppercase tracking-wider">
                  Organization:
                </dt>
                <dd
                  tw="m-0 overflow-hidden text-ellipsis whitespace-nowrap p-0 text-xs"
                  title={activeOrg.name}
                  data-testid="account-dropdown-organization"
                >
                  {activeOrg.name}
                </dd>
              </dl>
              {organizations.length > 1 && isReactRouterAvailable && (
                <SwitchOrganizationButtonInternal handleClose={handleClose} />
              )}
              {organizations.length > 1 && !isReactRouterAvailable && (
                <Button
                  type="button"
                  variant="text"
                  tw="!(mt-2 justify-start) w-full"
                  color="monochrome"
                  startIcon={
                    <FontAwesomeIcon
                      icon={solid('building-circle-arrow-right')}
                      tw="pl-2 !text-base"
                    />
                  }
                  onClick={onSwitchOrgClick}
                >
                  Switch Organization
                </Button>
              )}
            </div>
          )}
          {businessList && businessList.length > 0 && (
            <div>
              <StyledDivider />
              <div>
                <h5 tw="m-0 py-2 pl-6 font-sans font-normal text-monochrome-900/60 text-xs uppercase leading-10 tracking-wider">
                  {businessEntityTerminology}
                </h5>
                <div tw="h-full max-h-64 overflow-auto sm:[max-height: 210px]">
                  {isUnifiedAnalytics && businessList.length > 1 && (
                    <div tw="p-4 pt-0 text-left">
                      <Alert severity="info">
                        Data from all businesses is available in Unified Analytics. You can filter
                        by business within your dashboard views.
                      </Alert>
                    </div>
                  )}
                  <ul
                    tw="m-0 px-2 pb-2"
                    css={[
                      (isUnifiedAnalytics || businessList.length === 1) && tw`pointer-events-none`,
                    ]}
                  >
                    {sortedBusinessList.map((business) => (
                      <BusinessListItem
                        key={business.id}
                        business={business}
                        handleClose={handleClose}
                        activeBizId={activeBiz?.id}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}
          <StyledDivider />
          <div tw="flex justify-center px-2 pt-2">
            <Button
              tw="w-full"
              startIcon={<FontAwesomeIcon icon={solid('arrow-right-from-bracket')} />}
              onClick={onLogoutClick}
            >
              Log out
            </Button>
          </div>
        </div>
      </StyledMenu>
    </div>
  )
}
