import React from 'react'

import { StyledStatusBadge } from './status-badge.styles'
import { StatusBadgeProps } from './status-badge.types'

export const StatusBadge: React.FC<StatusBadgeProps> = ({ badgeContent, type, ...rest }) => {
  return (
    <StyledStatusBadge type={type} {...rest}>
      {badgeContent}
    </StyledStatusBadge>
  )
}
