import { Button, Dialog, DialogContent, useRouter } from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { MouseEventHandler, ReactElement } from 'react'

export const ServiceRequestFormModal = ({
  isShowing,
  setIsShowing,
  onClose,
}: {
  isShowing: boolean
  setIsShowing: (boolean) => void
  onClose?: () => void
}): ReactElement => {
  const { navigate } = useRouter()

  const handleOnClose = () => {
    if (onClose) {
      onClose()
    }
    setIsShowing(false)
  }

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    handleOnClose()
    navigate('/')
  }

  return (
    <Dialog
      id="my-dialog"
      open={isShowing}
      onClose={handleOnClose}
      width="sm"
      tw="[& .MuiPaper-root]:h-[400px]"
    >
      <DialogContent tw="flex justify-center pb-18">
        <div tw="flex flex-wrap items-center justify-center">
          <div tw="text-center">
            <FontAwesomeIcon icon={solid('check-circle')} tw="mb-4 text-5xl text-primary-700" />
            <h2 tw="w-full">Success!</h2>
            <p tw="mb-10 w-full">A Cart.com representative will be in touch with you soon!</p>
            <Button onClick={handleClick} variant="contained">
              Explore more services
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
