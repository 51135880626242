import {
  useAssignedRoleCreateMutation,
  useOrganizationRoleListQuery
} from '@brand-console/generated-graphql-hooks'
import { logger } from '@brand-console/utilities'
import { isOrgRole } from '@cartdotcom/auth'
import { useCallback } from 'react'

import { AssignRoleProps } from './useAssignRole.types'

export interface UseAssignRoleProps {
  log: typeof logger
}
export const useAssignRole = ({ log }: UseAssignRoleProps) => {
  const [assignRoleBusiness, { loading: loadingAssignRole }] = useAssignedRoleCreateMutation()

  const { data: orgListData, loading: loadingQuery } = useOrganizationRoleListQuery({})

  /** Assign user roles within an organization. If they were not already in the organization they will be added to it. */
  const assignRole = useCallback(
    async ({
      orgId,
      businessId,
      userId,
      role,
      onError,
      onCompleted,
    }: AssignRoleProps) => {
      const roleId = orgListData?.org_roleList?.data?.filter(
        (roleItem) => roleItem.name === role
      )[0].id
      const isOrgInvite = isOrgRole(role)
      if (!roleId) {
        onError?.(new Error('Could not assign role: Role not found'))
        return
      }
      assignRoleBusiness({
        variables: {
          createAssignedroleInput: {
            businessId: isOrgInvite ? undefined : businessId,
            organizationId: isOrgInvite ? orgId : undefined,
            userId,
            roleId,
          },
        },
        onError: (error) => {
          log.error(
            "useAssignRole Error: Couldn't assign role",
            {
              orgId,
              businessId,
              userId,
              role,
            },
            error,
          )
          onError?.(error)
        },
        onCompleted,
      })
    },
    [orgListData, assignRoleBusiness, log],
  )

  return {
    assignRole,
    loading: loadingAssignRole || loadingQuery
  }
}
