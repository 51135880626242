import tw, { css, styled } from 'twin.macro'

export const StyledCarousel = styled.div(() => [tw`relative ml-auto mr-auto max-w-full`])

export const StyledCarouselViewPort = styled.div(() => [
  tw`w-full overflow-hidden`,
  css`
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
    &.is-draggable {
      cursor: move;
      cursor: grab;
    }
    &.is-dragging {
      cursor: grabbing;
    }
  `,
])

export const StyledCarouselContainer = styled.div(
  ({ slidesToScroll }: { slidesToScroll: 1 | 2 | 3 }) => [
    tw`flex select-none`,
    slidesToScroll !== 1 && tw`ml-[-10px]`,
  ],
  css`
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
  `,
)

export const StyledArrow = styled.button(() => [
  tw`absolute top-1/2 inline-flex h-8 w-8 -translate-y-1.5 cursor-pointer items-center justify-center rounded-md border border-monochrome-500 bg-white text-monochrome-600 z-[1]`,
  css`
    touch-action: manipulation;
  `,
])
