import { useReactiveVar } from '@apollo/client'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@cart/ui'
import React, { FC } from 'react'

import { recoveryCodeVar } from '../SettingsSecurity.partials'
import { ModalProps } from '../SettingsSecurity.types'

export const RecoveryCodeModal: FC<ModalProps> = ({ show, closeCallback }) => {
  const recoveryCode = useReactiveVar(recoveryCodeVar)
  const copyRecoveryCode = () => {
    navigator.clipboard.writeText(recoveryCode)
  }

  const handleModalReset = () => {
    recoveryCodeVar('')
    closeCallback()
  }

  return (
    <Dialog width="sm" open={show} id="recoverycodedialog" onClose={() => handleModalReset()}>
      <DialogTitle>Recovery Code</DialogTitle>
      <DialogContent dividers>
        <div tw="flex w-full flex-col px-4 text-center mobile:px-1">
          <p tw="mb-4 text-left">
            This recovery code allows you access to your account in the event you loose access to
            the MFA method selected. In order to keep your account extra secure, treat your recovery
            code as you would your password.
          </p>
          <p tw="mb-4 text-left">
            Copy this recovery code and keep it somewhere safe. You’ll need it if you ever need to
            log in without your device.
          </p>
          <div tw="flex flex-col items-center justify-center">
            {/* We want this button to be un-clickable and act like a disabled one, but override the disabled styling for looks */}
            <Button variant="contained" tw="pointer-events-none">
              {recoveryCode}
            </Button>
            <Button tw="mt-3" variant="outlined" size="large" onClick={copyRecoveryCode}>
              Copy Code
            </Button>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => handleModalReset()}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}
