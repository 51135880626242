import {
  routedBizVar,
} from '@brand-console/generated-graphql-hooks'
import { AddUserRoleSelect, logger, RegEx, useAddUser } from '@brand-console/utilities'
import {
  Alert,
  AlertTitle,
  Button,
  DialogActions,
  DialogContent,
  Input,
  Select,
  SelectItem,
  Spinner,
} from '@cart/ui'
import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { useSettingsOrganizationsContext } from '../SettingsOrganizations.store'
import { IAddUsers } from './AddUsers.types'
import { isOrgRole, useCurrentContext } from '@cartdotcom/auth'
import { useReactiveVar } from '@apollo/client'

export const AddUsers = ({ close, flow = 'organization' }: IAddUsers): ReactElement => {
  const { currentOrganization, roleName } = useCurrentContext()
  const log = useMemo(() => logger.setLogger(logger.LoggerNames.ORGANIZATION), [])
  const { id: routedBusinessId } = { ...useReactiveVar(routedBizVar) }
  const methods = useForm({
    defaultValues: {
      business: flow === 'business' ? `${routedBusinessId}` : undefined,
      role: undefined,
      userEmails: undefined,
    },
  })
  const { setAlert } = useSettingsOrganizationsContext()
  const businessEntityTerminology = currentOrganization?.businessEntityTerminology || 'Business'
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false)
  const { handleSubmit, watch } = methods
  const { roles, loading, inviteUsers } = useAddUser({ roleName, log, onlyBusinessRoles: flow === 'business' })
  const selectedRole = watch('role')
  const selectedBusiness = watch('business')

  const onSubmit = useCallback(
    (formData) => {
      setShowErrorAlert(false)
      const { business, role, userEmails } = formData

      inviteUsers({
        orgId: currentOrganization.id,
        businessId: parseInt(business, 10),
        emails: userEmails,
        role,
        onCompleted: () => {
          setAlert({
            variant: 'success',
            text: `User has been successfully invited.`,
          })
          close(true)
        },
        onError: () => {
          setShowErrorAlert(true)
        },
      },
      )
    },
    [inviteUsers, currentOrganization.id, setAlert, close],
  )

  if (loading) {
    return (
      <div tw="[min-height: 392px]">
        <Spinner tw="m-auto h-18 w-18" type="global" />
      </div>
    )
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <DialogContent>
          <div tw="w-full">
            {showErrorAlert && (
              <Alert severity="error" tw="mb-4" onClose={() => setShowErrorAlert(false)} data-testid="addUsers-inviteError">
                <AlertTitle>
                  There was an error when trying to invite the user. Please try again.
                </AlertTitle>
              </Alert>
            )}
            <h2 tw="mb-2 text-lg">Invite User</h2>
            <Input
              id="userEmails"
              label="Invite new or existing users via email."
              placeholder="Email, comma separated"
              required
              rules={{
                pattern: {
                  value: RegEx.VALID_EMAIL_ADDRESS,
                  message: 'Please use valid email address',
                },
              }}
              tw="mb-4"
              type="email"
            />
            <h2 tw="mb-2 text-lg">Set Role</h2>
            <AddUserRoleSelect label="Give your users access permission below." roles={roles} data-testid="addUsers-roles" />
            {!isOrgRole(selectedRole) && (
              <>
                <h2 tw="mb-2 text-lg">Set {businessEntityTerminology}</h2>

                <Select
                  id="business"
                  data-testid="addUsers-business"
                  label={`Select a ${businessEntityTerminology.toLowerCase()} you want to invite the user(s) to`}
                  disabled={flow === 'business'}
                  value={selectedBusiness}
                  required
                  tw="mb-4"
                >
                  {currentOrganization.businesses.map((business) => {
                    const { id, name } = business
                    return (
                      <SelectItem key={id} value={`${id}`}>
                        {name}
                      </SelectItem>
                    )
                  })}
                </Select>
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            tw="z-10"
            variant="contained"
            disabled={loading}
            data-testid="addUsers-sendInvite"
          >
            Send Invite
          </Button>
        </DialogActions>
      </form>
    </FormProvider>
  )
}
