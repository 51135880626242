import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'
import tw from 'twin.macro'

const getScoreColor = (score: number) => {
  switch (score) {
    case 1:
      return tw`bg-accent-two-700`
    case 2:
      return tw`bg-accent-five-700`
    case 3:
      return tw`bg-accent-six-700`
    case 4:
      return tw`bg-accent-three-700`
    default:
      return tw`bg-monochrome-900/[0.3]`
  }
}

export interface ScoreProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  score: number
}
export const Score: FC<ScoreProps> = ({ score, ...props }) => (
  <div tw="grid w-full grid-cols-4 gap-1" {...props}>
    {Array.from(Array(4)).map((i, index) => (
      <div
        key={i}
        tw="h-1 rounded-md"
        css={[score > index ? getScoreColor(score) : getScoreColor(0)]}
      />
    ))}
  </div>
)
