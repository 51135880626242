/* eslint-disable react/jsx-props-no-spreading */
import { useUserPhoneNumberQuery } from '@brand-console/generated-graphql-hooks'
import { Services } from '@brand-console/types'
import { countryCodes, hubspotFormSubmit, logger } from '@brand-console/utilities'
import {
  Breadcrumbs,
  Button,
  DeprecatedAlert,
  DeprecatedCard,
  DeprecatedInput,
  Dropdown,
  DropdownItem,
  Spinner,
  Textarea,
  Transition,
} from '@cart/ui'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { useMe } from '../../hooks'
import { transitionProps } from './FeedMarketingContactUsRoute.styles'

export const FeedMarketingContactUsRoute = () => {
  const log = logger.setLogger(logger.LoggerNames.FEED_MARKETING)
  const me = useMe()
  const [message, setMessage] = useState<string | null>()
  const [messageType, setMessageType] = useState<'success' | 'error'>('success')
  const formMethods = useForm()
  const { reset } = formMethods
  const { data } = useUserPhoneNumberQuery()
  const [show, setShow] = useState(false)

  const phone = data?.me?.user?.userData?.phone

  useEffect(() => {
    if (data) {
      setShow(true)
    }
  }, [data])

  const formSubmit = async ({
    additionalInformation,
    countryCode,
    experience,
    feedTool,
    numberOfProducts,
    phoneNumber,
  }) => {
    const portalId = 2773967
    const formId = 'd7782140-6789-4b50-8f5f-a022bd255fbe'

    try {
      const res = await hubspotFormSubmit({
        formId,
        data: {
          email: me.email,
          firstname: me.name.split(' ')[0],
          lastname: me.name.split(' ')[1] || '',
          country_code: countryCode,
          phone: phoneNumber,
          website: 'N/A',
          using_a_feed_tool: feedTool,
          advertising_experience: experience,
          how_many_products: numberOfProducts,
          prospect_comments: additionalInformation,
        },
        portalId,
        pageName: `Cart.com | Brand Console | ${Services.FEED_MARKETING}`,
      })
      setMessage(res.inlineMessage)
      setMessageType('success')
      reset()
    } catch (e) {
      setMessage('An error occurred. Please try again later.')
      setMessageType('error')
      log?.error('FeedMarketingContactUsRoute > formSubmit', e.message)
    }
  }

  if (!data) {
    return <Spinner tw="m-auto h-64 w-64" type="global" />
  }

  return (
    <Transition show={show} tw="w-full">
      <section tw="mx-auto w-full max-w-screen-xl px-3.5">
        <Breadcrumbs
          tw="relative z-10 mb-10 mt-7 sm:-ml-2"
          items={[
            { text: 'Home', href: '/' },
            { text: Services.FEED_MARKETING, href: '/feed-marketing' },
            { text: 'Contact Us' },
          ]}
        />
        {message && <DeprecatedAlert text={message} type={messageType} />}
        <Transition.Child {...transitionProps} as="div">
          <div tw="mx-auto flex max-w-screen-sm items-center px-4 py-10 text-center sm:px-2">
            <FormProvider {...formMethods}>
              <DeprecatedCard>
                <h1 tw="my-6 text-center text-2xl sm:(my-10 text-4xl)">Contact Us</h1>
                <form
                  onSubmit={formMethods.handleSubmit(formSubmit)}
                  tw="mb-10 flex w-full flex-wrap"
                >
                  <div tw="mb-6 w-1/2 pr-2">
                    <Dropdown tw="pb-4" id="countryCode" label="Country Code" required>
                      {countryCodes.map((country) => {
                        return (
                          <DropdownItem
                            key={country.name}
                            text={`${country.name} ${country.dialCode}`}
                            value={`${country.dialCode}`}
                            selected={country.dialCode === '+1'}
                          />
                        )
                      })}
                    </Dropdown>
                  </div>
                  <div tw="mb-5 w-1/2 pl-2">
                    <DeprecatedInput
                      type="tel"
                      id="phoneNumber"
                      label="Phone Number"
                      value={phone}
                      required
                    />
                  </div>
                  <Dropdown id="feedTool" label="Are you using a Feed Tool?" required tw="mb-6">
                    <DropdownItem text="Yes" value="Yes" />
                    <DropdownItem text="No" value="No" />
                  </Dropdown>
                  <Dropdown
                    id="experience"
                    label="What is your online advertising experience?"
                    tw="mb-6"
                    required
                  >
                    <DropdownItem text="Getting Started" value="Getting Started" />
                    <DropdownItem text="Some Experience" value="Some Experience" />
                    <DropdownItem text="I Known What I'm Doing" value="I Known What I'm Doing" />
                    <DropdownItem
                      text="I Consider Myself an Expert"
                      value="I Consider Myself an Expert"
                    />
                  </Dropdown>
                  <Dropdown
                    id="numberOfProducts"
                    label="Number of products in your store?"
                    required
                    tw="mb-6"
                  >
                    <DropdownItem text="Under 1,000" value="Under 1,000" />
                    <DropdownItem text="1,000 - 5,000" value="1,000 - 5,000" />
                    <DropdownItem text="5,000 - 25,000" value="5,000 - 25,000" />
                    <DropdownItem text="25,000 - 150,000" value="25,000 - 150,000" />
                    <DropdownItem text="Over 150,000" value="Over 150,000" />
                  </Dropdown>
                  <Textarea id="additionalInformation" label="Additional Information:" tw="mb-6" />
                  <Button type="submit" tw="w-full">
                    Submit
                  </Button>
                </form>
              </DeprecatedCard>
            </FormProvider>
          </div>
        </Transition.Child>
      </section>
    </Transition>
  )
}
