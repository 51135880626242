import { useReactiveVar } from '@apollo/client'
import {
  routedBizVar,
  useBusinessRemoveUserMutation,
  useOrganizationRemoveUserMutation,
} from '@brand-console/generated-graphql-hooks'
import { logger } from '@brand-console/utilities'
import { Alert, AlertTitle, Button, DialogActions, DialogContent } from '@cart/ui'
import Pluralize from 'pluralize'
import React, { useCallback, useState } from 'react'

import { useSettingsOrganizationsContext } from '../SettingsOrganizations.store'
import { RemoveUsersProps } from './RemoveUsers.types'
import { useCurrentContext } from '@cartdotcom/auth'

export const RemoveUsers = ({ close, scope, users }: RemoveUsersProps) => {
  const log = logger.setLogger(logger.LoggerNames.ORGANIZATION)
  const { id: businessId } = { ...useReactiveVar(routedBizVar) }
  const { setAlert } = useSettingsOrganizationsContext()
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false)
  const { currentOrganization } = useCurrentContext()
  const userIdsToRemove = users.map(({ id }) => id)

  const mappedUsers = users.map(({ firstName, lastName, primaryEmail }) => {
    const name = firstName && lastName ? `${firstName} ${lastName}` : ''
    const hasName = !!name
    if (!hasName) {
      return primaryEmail
    }
    return `${name} (${primaryEmail})`
  })
  const usersAmount = users.length
  const submitButtonText = `Remove ${usersAmount === 1 ? 'User' : 'All'}`

  const [removeUsersFromOrganization, { loading: loadingOrganization }] =
    useOrganizationRemoveUserMutation()
  const [removeUsersFromBusiness, { loading: loadingBusiness }] = useBusinessRemoveUserMutation()

  const onSubmit = useCallback(() => {
    setShowErrorAlert(false)
    if (scope === 'organization') {
      removeUsersFromOrganization({
        variables: {
          organizationId: currentOrganization.id,
          userIds: userIdsToRemove,
        },
        onError(error) {
          log.error('RemoveUsers GraphQL error', error)
          setShowErrorAlert(true)
        },
        onCompleted() {
          const text = usersAmount === 1 ? 'User has been removed.' : 'Users have been removed.'
          setAlert({
            variant: 'success',
            text,
          })
          close(true)
        },
      })
    } else if (scope === 'business') {
      removeUsersFromBusiness({
        variables: {
          businessId,
          userIds: userIdsToRemove,
        },
        onError(error) {
          log.error('RemoveUsers GraphQL error', error)
          setShowErrorAlert(true)
        },
        onCompleted() {
          const text = usersAmount === 1 ? 'User has been removed.' : 'Users have been removed.'
          setAlert({
            variant: 'success',
            text,
          })
          close(true)
        },
      })
    }
  }, [
    businessId,
    close,
    log,
    currentOrganization?.id,
    removeUsersFromBusiness,
    removeUsersFromOrganization,
    scope,
    setAlert,
    userIdsToRemove,
    usersAmount,
  ])

  return (
    <div>
      <DialogContent>
        <div>
          {showErrorAlert && (
            <Alert severity="error" tw="mb-4" onClose={() => setShowErrorAlert(false)}>
              <AlertTitle>{`There was an error when trying to remove the ${Pluralize(
                'user',
                usersAmount,
              )}`}</AlertTitle>
            </Alert>
          )}
          {usersAmount === 1 && (
            <div>
              <p tw="mb-2">
                Removing <strong>{mappedUsers}</strong> means that they will no longer be able to
                access this {scope}.
              </p>
              <p>Are you sure you wish to proceed?</p>
            </div>
          )}
          {usersAmount > 1 && (
            <div>
              <p tw="mb-2">
                Removing the following users means they will no longer be able to access this{' '}
                {scope}. Are you sure you wish to proceed?
              </p>
              <ul tw="list-inside list-disc">
                {mappedUsers.map((user) => (
                  <li tw="mb-2" key={user}>
                    {user}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          type="button"
          onClick={() => close(false)}
          data-testid="remove-users-cancel-button"
        >
          Cancel
        </Button>
        <Button
          color="error"
          variant="contained"
          type="submit"
          size="medium"
          tw="z-10"
          disabled={loadingOrganization || loadingBusiness}
          onClick={onSubmit}
          data-testid="remove-confirmation-users-button"
        >
          {submitButtonText}
        </Button>
      </DialogActions>
    </div>
  )
}
