import { checkIfPhrasesExist } from '@brand-console/utilities'

import { addArticle, padIfExist } from '../text/text'

export const isNameExistsError = (errorMessage: string) => {
  return checkIfPhrasesExist(
    ['name already exists', 'unique constraint failed on the fields: (`organizationid`,`name`)'],
    errorMessage,
    true,
  )
}

export const getNameExistsErrorMessage = (scope: string, name?: string) => {
  if (!scope) return `That name already exists.`
  return `${addArticle(scope, true)} with the name${padIfExist(name || '', {padEnd: false})} already exists.`
}

/**
 *  Returns a friendly error message based on the error message and the scope
 * @param error The error object
 * @param scope What the error affects, ie organization, user, etc
 * @returns
 */
export const getErrorHeader = (error: Error | string, scope = '') => {
  const errorMessage = typeof error === 'string' ? error : error.message.toLowerCase()
  if (isNameExistsError(errorMessage)) {
    return `That ${padIfExist(scope, { padStart: false })}name already exists`
  }
  if (checkIfPhrasesExist(['forbidden', 'insufficient permissions'], errorMessage, true)) {
    return `You do not have permission to update this${padIfExist(scope, { padEnd: false })}`
  }
  return 'Something went wrong'
}

/**
 * Returns a friendly error message for a modal body based on the error message and the scope
 * @param error The error object or message
 * @param scope What the error affects, ie organization, user, etc
 * @param values Values to be used in the error message, ie org name, biz name
 * @returns a modal body
 */
export const getErrorBody = (
  error: Error | string,
  scope = '',
  values: { name?: string; } = {},
) => {
  const errorMessage = typeof error === 'string' ? error : error.message
  const { name } = values

  if (errorMessage.toLowerCase().includes('forbidden')) return null
  if (isNameExistsError(errorMessage)) {
    return <p>{getNameExistsErrorMessage(scope, name)}</p>
  }
  return (
    <p>There could be a misconfiguration in the system or a service outage. Please try again.</p>
  )
}

/**
 * Returns a friendly error message based on the error and the scope
 * @param error The error object or message
 * @param scope What the error affects, ie organization, user, etc
 * @param values Values to be used in the error message, ie org name, biz name
 * @returns a friendly error message
 */
export const getFriendlyErrorMessage = (
  error: Error | string,
  scope = '',
  values: { name?: string; defaultMessage?: string; action?: string } = {},
) => {
  const errorMessage = typeof error === 'string' ? error : error.message
  const { name, defaultMessage, action } = values

  if (isNameExistsError(errorMessage)) {
    return getNameExistsErrorMessage(scope, name)
  }
  if (errorMessage.toLowerCase().includes('wrong email or password')) {
    if (scope === 'password') return 'Wrong password'
    if (scope === 'email') return 'Wrong email'
    return 'Wrong email or password'
  }
  if (errorMessage.includes('forbidden')) {
    return `You do not have permission to ${action || 'update'} this${padIfExist(scope, { padEnd: false })}`
  }
  return defaultMessage || 'Something went wrong'
}
