import { StateCreator } from 'zustand'

import { resetters } from './storeResetter'
import { type StoreStateType } from './unified-sidebar.store'

export interface IBusinessState {
  currentBusinessId?: string
  setCurrentBusinessId: (currentBusinessId: string) => void
}

const defaultMenuState = {
  currentBusinessId: undefined,
}

export const createBusinessSlice: StateCreator<
  StoreStateType,
  [['zustand/devtools', never]],
  [],
  IBusinessState
> = (set) => {
  resetters.push(() => set(defaultMenuState, false, 'resetBusinessState'))
  return {
    ...defaultMenuState,
    setCurrentBusinessId: (currentBusinessId) =>
      set({ currentBusinessId }, false, 'setCurrentBusinessId'),
  }
}
