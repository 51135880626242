import preval from 'preval.macro'
import React from 'react'
import GitInfo from 'react-git-info/macro'

export const StatusRoute = () => {
  const gitInfo = GitInfo()

  return (
    <div tw="m-auto mt-7 w-full max-w-screen-xl p-4 shadow">
      <div tw="border-monochrome-500 border-b-2">
        <h1>Brand Console Status</h1>
      </div>
      <div tw="mt-6">
        <h3>Git Branch</h3>
        <p>{gitInfo.branch}</p>
      </div>
      <div tw="mt-6">
        <h3>Git Tags</h3>
        <p>{gitInfo.tags.join(', ')}</p>
      </div>
      <div>
        <h3>Git Commit SHA</h3>
        <p>{gitInfo.commit.hash}</p>
      </div>
      <div>
        <h3>Last Build Time</h3>
        <p>{preval`module.exports = new Date().toLocaleString('en-US', {dateStyle: 'full', timeStyle: 'full', timeZone: 'America/Chicago'})`}</p>
      </div>
    </div>
  )
}
