import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export type LeftNavStoreType = {
  isCollapsed: boolean,
  setIsCollapsed: (collapsed: boolean) => void
}

export const useLeftNavStore = create<LeftNavStoreType>()(
  devtools(
    (set) => ({
      isCollapsed: false,
      setIsCollapsed: (collapsed) => set({ isCollapsed: collapsed }, false, 'setIsCollapsed'),
    }),
    { name: 'left-nav-store' },
  ),
)
