import { IMenu } from '../../Menu/Menu.types'
import { IMenuItem } from '../../MenuItem/MenuItem.types'
import { helpMenu } from '../Shared/help.menu'
import { BcMenuIds } from './BcMenu.types'

const settingsMenu = {
  title: 'Settings',
  icon: 'faGear',
  items: [
    {
      title: 'Profile',
      url: '/settings/profile',
    },
    {
      title: 'Security',
      url: '/settings/security',
    },
    {
      title: 'Organization',
      url: '/settings/organization',
    },
    {
      title: 'Storefront',
      url: '/settings/storefront',
      hidden: true,
      id: BcMenuIds.STOREFRONT_SETTINGS,
    },
    {
      title: 'Marketplace Management',
      url: '/settings/marketplace-management',
      hidden: true,
      id: BcMenuIds.MCM_SETTINGS,
    },
    {
      title: 'Admin Tools',
      url: '/settings/admin-tools/dashboard',
      hidden: true,
      id: BcMenuIds.ADMIN_TOOLS,
    },
    {
      title: 'Unified Analytics',
      url: '/settings/unified-analytics',
    },
  ],
} as IMenuItem

export const bcBaseMenu = [
  {
    id: 'bc',
    items: [
      {
        title: 'Home',
        icon: 'faHouse',
        url: '/',
      },

      {
        title: 'Orders',
        icon: 'faCartShopping',
        url: '/orders',
        hidden: false,
        id: BcMenuIds.ORDERS,
      },
    ],
  },
  {
    id: 'footer',
    items: [settingsMenu, helpMenu],
  },
] as IMenu[]
