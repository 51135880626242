export const RegEx = {
  /**
   * Regular expression to validate email address. It also accepts multiple comma-separated email addresses.
   */
  VALID_EMAIL_ADDRESS: /^(?:\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(?:\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/,

  /**
   * Regular expression to validate password. It follows the next rules: At least 8 characters in length,
   * have at least 1 special character (!@#$%^&*), and must only consist of alphanumeric and special characters.
   */
  VALID_PASSWORD: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}$/,

  /**
   * Regular expression to validate a url with or without a protocol being informed.
   *
   * Valid examples:
   * - https://www.cart.com
   * - http://www.cart.com
   * - www.cart.com
   * - cart.com
   *
   * Invalid examples:
   * - htt://www.cart.com
   * - ://www.cart.com
   */
  VALID_URL_WITH_OR_WITHOUT_PROTOCOL:
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,

  /**
   * Regular expression to validate a domain with or without a protocol being informed.
   *
   * Valid examples:
   * - https://www.cart.com
   * - http://www.cart.com
   * - www.cart.com
   * - cart.com
   * - cart.com/
   *
   * Invalid examples:
   * - htt://www.cart.com
   * - ://www.cart.com
   * - cart.com/test
   * - cart.com/test/folder
   */
  VALID_DOMAIN_WITH_OR_WITHOUT_PROTOCOL:
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/)?$/,

  /**
   * Regular expression to validate phone number. It accepts a string of digits of length 10-13 with an optional + sign in front.
   */
  VALID_PHONE_NUMBER: /^\+?[1-9]\d{9,14}$/,
}
