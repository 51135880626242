import { useReactiveVar } from '@apollo/client'
import { activeOrgVar } from '@brand-console/generated-graphql-hooks'
import { ContextMenu, StatusBadge } from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

import { DeleteModal } from '../../components/molecules/DeleteModal/DeleteModal'
import { dataStatusColor } from '../../helpers/data'
import { DataConnectorIcon } from '../settings/DataConnectors/components/DataConnectorSection/DataConnectorSection.partials'

export const TitleCell = ({ value, row: { original } }) => {
  return (
    <>
      <DataConnectorIcon dataConnector={original} width={18} tw="mr-2.5" />
      {value}
    </>
  )
}

export const ActionsButton = ({ dataConnector, updateActionConnector }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const activeOrg = useReactiveVar(activeOrgVar)

  return (
    <div tw="flex h-full w-full items-center justify-end">
      <ContextMenu
        size="small"
        variant="outlined"
        items={[
          [
            {
              label: 'Sync',
              onClick: () =>
                updateActionConnector({
                  variables: {
                    action: 'sync',
                    dataConnectorType: dataConnector.dataConnectorType,
                    dataConnectorId: Number(dataConnector.id),
                    organizationId: activeOrg?.id.toString(),
                  },
                }),
            },
            {
              label: 'Reset',
              onClick: () =>
                updateActionConnector({
                  variables: {
                    action: 'reset',
                    dataConnectorType: dataConnector.dataConnectorType,
                    dataConnectorId: Number(dataConnector.id),
                    organizationId: activeOrg?.id.toString(),
                  },
                }),
            },
            {
              label: '',
              icon: <span tw="ml-2 text-accent-two-700">Delete</span>,
              onClick: () => setShowDeleteModal(true),
            },
          ],
        ]}
      >
        <FontAwesomeIcon
          icon={solid('ellipsis-vertical')}
          tw="aspect-square text-monochrome-600 hover:text-primary-700"
        />
      </ContextMenu>
      <DeleteModal
        isOpen={showDeleteModal}
        setIsOpen={setShowDeleteModal}
        deleteCallback={() =>
          updateActionConnector({
            variables: {
              action: 'delete',
              dataConnectorType: dataConnector.dataConnectorType,
              dataConnectorId: Number(dataConnector.id),
              organizationId: activeOrg?.id.toString(),
            },
          })
        }
        title="Please confirm"
        describe="Are you sure you want to delete this Data Connector?"
        width={639}
      />
    </div>
  )
}

export const StatusCell = ({ value }) => {
  return <StatusBadge badgeContent={value} type={dataStatusColor[value]} />
}

// todo: type the arg once the graphql types are in place
export const ActionsHeaderButton = ({ dataConnectors, updateActionConnector }) => {
  const activeOrg = useReactiveVar(activeOrgVar)

  const rowsAreNotSelected = !dataConnectors || dataConnectors.length === 0
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  return (
    <>
      <DeleteModal
        isOpen={showDeleteModal}
        setIsOpen={setShowDeleteModal}
        deleteCallback={() =>
          dataConnectors.map((item) =>
            updateActionConnector({
              variables: {
                action: 'delete',
                dataConnectorType: item.dataConnectorType,
                dataConnectorId: Number(item.id),
                organizationId: activeOrg?.id.toString(),
              },
            }),
          )
        }
        title="Please confirm"
        describe={`Are you sure you want to delete this Data Connector${
          dataConnectors.length > 1 ? 's' : ''
        }?`}
        width={639}
      />
      <ContextMenu
        items={[
          [
            {
              label: 'Sync',
              onClick: rowsAreNotSelected
                ? null
                : () =>
                    dataConnectors.map((item) =>
                      updateActionConnector({
                        variables: {
                          action: 'sync',
                          dataConnectorType: item.dataConnectorType,
                          dataConnectorId: Number(item.id),
                          organizationId: activeOrg?.id.toString(),
                        },
                      }),
                    ),
            },
            {
              label: 'Reset',
              onClick: rowsAreNotSelected
                ? null
                : () =>
                    dataConnectors.map((item) =>
                      updateActionConnector({
                        variables: {
                          action: 'reset',
                          dataConnectorType: item.dataConnectorType,
                          dataConnectorId: Number(item.id),
                          organizationId: activeOrg?.id.toString(),
                        },
                      }),
                    ),
            },
            {
              label: '',
              icon: <span tw="ml-2 text-accent-two-700">Delete</span>,
              onClick: rowsAreNotSelected ? null : () => setShowDeleteModal(true),
            },
          ],
        ]}
      >
        Actions
      </ContextMenu>
    </>
  )
}
