import { BcMenu } from './BcMenu/BcMenu'
import { McmMenu } from './McmMenu/McmMenu'
import { RptMenu } from './RptMenu/RptMenu'
import { UaMenu } from './UaMenu/UaMenu'

export const ProductMenu = ({ product }) => {
  switch (product) {
    case 'mcm':
      return <McmMenu />
    case 'ua':
      return <UaMenu />
    case 'rpt':
      return <RptMenu />
    case 'bc':
    default:
      return <BcMenu />
  }
}
