import { Services } from '@brand-console/types'
import { Button, DeprecatedCard } from '@cart/ui'
import React from 'react'

export const SettingsMarketplaceManagement = () => (
  <main tw="m-auto max-w-5xl px-3 sm:px-20">
    <h2 tw="mb-9">{Services.MARKETPLACE_MANAGEMENT}</h2>
    <div tw="mb-18 w-full">
      <div tw="block pr-7 lg:(inline-block w-1/3)">
        <h3>Subscription</h3>
        <p>Manage your subscription plan, view your billing information and last invoices.</p>
      </div>
      <div tw="block align-top lg:(inline-block w-2/3 pl-2)">
        <DeprecatedCard title={`${Services.MARKETPLACE_MANAGEMENT} Subscription`}>
          <div tw="py-4">
            <Button href={process.env.NX_MCM_SUBSCRIPTION_SETTINGS_URL} variant="outlined">
              Manage Subscription
            </Button>
          </div>
        </DeprecatedCard>
      </div>
    </div>
  </main>
)
