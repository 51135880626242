/* eslint-disable react/jsx-props-no-spreading */
import { useReactiveVar } from '@apollo/client'
import {
  activeBizVar,
  activeOrgVar,
  useUAActionDataConnectorMutation,
  useUADataConnectorsListQuery,
  useUAMostPopularDataConnectorsListQuery,
  useUApackageDataConnectorsListQuery,
} from '@brand-console/generated-graphql-hooks'
import { logger } from '@brand-console/utilities'
import { Button, DeprecatedButton, Spinner, useBreakpoint } from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import uniqBy from 'lodash/uniqBy'
import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import plugImage from '../../assets/images/image_data_con.png'
import { CTAContainer } from '../../components/molecules/CTAContainer/CTAContainer'
import { convertWordsToTitleCase, SliseUrlCode } from '../../helpers/functions'
import { SERVICE_CTA_DATA } from '../../helpers/serviceCtaData'
import { useAppDispatch } from '../../stores/hooks/hooks'
import { displayAlert } from '../../stores/reducers/alertPropsSlice'
import {
  selectConnector,
  setAmazonAdsCodeData,
  setAmazonAdsStateData,
  setAmazonSPData,
  setCurrentView,
  setGoogleCodeData,
  setGoogleStateData,
  setShopifyData,
  setTikTokData,
} from '../../stores/reducers/dataConnectorsSlice'
import { useDsMeQuery } from '../../stores/services/userEndpoints'
import { MyConnectorsCarousel } from './MyConnectorsCarousel'
import { MyConnectorsDesktop } from './MyConnectorsDesktop'
import { MyConnectorsFilter } from './MyConnectorsFilter'
import { MyConnectorsMobile } from './MyConnectorsMobile'
import { MyConnectorsModal } from './MyConnectorsModal/MyConnectorsModal'

export const MyConnectorsPage = () => {
  const activeOrg = useReactiveVar(activeOrgVar)
  const activeBiz = useReactiveVar(activeBizVar)

  const log = logger.setLogger(logger.LoggerNames.UNIFIED_ANALYTICS)
  const isSmallOrBigger = useBreakpoint('sm')
  const dispatch = useAppDispatch()
  const [show, setShow] = useState(false)

  const organizationId = JSON.parse(localStorage.getItem('organization'))?.id
  const { data: user, isLoading: userLoading } = useDsMeQuery(organizationId)

  const {
    data: dataConnectorsList,
    loading: dataConnectorsListLoading,
    error,
  } = useUADataConnectorsListQuery({
    variables: {
      organizationId: activeOrg?.id.toString(),
    },
  })

  const {
    data: packageDataConnectorsList,
    loading: packageDataConnectorsListLoading,
    error: packageDataConnectorsListError,
  } = useUApackageDataConnectorsListQuery({
    variables: {
      businessId: activeBiz?.id.toString(),
    },
  })
  const [updateActionConnector, { loading: UpdateActionConnectorLoading }] =
    useUAActionDataConnectorMutation({
      refetchQueries: ['UADataConnectorsList'],
    })
  const { data: mostPopularConnectorsList, loading: mostPopularConnectorsListLoading } =
    useUAMostPopularDataConnectorsListQuery()
  // getting code and show FormModal
  useEffect(() => {
    const connectorName = localStorage.getItem('connector_name')
    if (document.location.search && connectorName) {
      dispatch(selectConnector(connectorName))
      switch (connectorName) {
        case 'amazon-advertising':
          dispatch(setAmazonAdsCodeData(SliseUrlCode(document.location.search, 'code')))
          dispatch(setAmazonAdsStateData(SliseUrlCode(document.location.search, 'state')))
          setShow(true)
          dispatch(setCurrentView(2))
          break

        case 'amazon-seller-partner':
          dispatch(setAmazonSPData(SliseUrlCode(document.location.search, 'spapi_oauth_code')))
          setShow(true)
          dispatch(setCurrentView(2))
          break
        case 'google-adwords':
          dispatch(setGoogleCodeData(SliseUrlCode(document.location.search, 'code')))
          dispatch(setGoogleStateData(SliseUrlCode(document.location.search, 'state')))
          setShow(true)
          dispatch(setCurrentView(2))
          break
        case 'google-analytics':
          dispatch(setGoogleCodeData(SliseUrlCode(document.location.search, 'code')))
          dispatch(setGoogleStateData(SliseUrlCode(document.location.search, 'state')))
          setShow(true)
          dispatch(setCurrentView(2))
          break
        case 'google-analytics-4':
          dispatch(setGoogleCodeData(SliseUrlCode(document.location.search, 'code')))
          dispatch(setGoogleStateData(SliseUrlCode(document.location.search, 'state')))
          setShow(true)
          dispatch(setCurrentView(2))
          break
        case 'google-search-console':
          dispatch(setGoogleCodeData(SliseUrlCode(document.location.search, 'code')))
          dispatch(setGoogleStateData(SliseUrlCode(document.location.search, 'state')))
          setShow(true)
          dispatch(setCurrentView(2))
          break
        case 'tiktok':
          dispatch(
            setTikTokData({
              code: SliseUrlCode(document.location.search, 'auth_code'),
              state: SliseUrlCode(document.location.search, 'state'),
            }),
          )
          setShow(true)
          dispatch(setCurrentView(2))
          break
        default:
          localStorage.setItem('connector_name', '')
      }
    }
  }, [dispatch])
  // state
  const methods = useForm({
    mode: 'onChange',
    defaultValues: { business: [], status: [], category: [], search: '' },
  })
  const { setValue, watch } = methods
  const formValues = watch()
  const loading =
    dataConnectorsListLoading ||
    packageDataConnectorsListLoading ||
    mostPopularConnectorsListLoading ||
    UpdateActionConnectorLoading ||
    userLoading

  const menuFilterItems = useMemo(() => {
    const businessList = dataConnectorsList?.ua_dataConnectorsList.map((dataConnector) => {
      return { title: dataConnector.business.name, value: dataConnector.business.id }
    })
    const business = uniqBy(businessList, 'value')

    const statusList = dataConnectorsList?.ua_dataConnectorsList.map((dataConnector) => ({
      title: convertWordsToTitleCase(dataConnector.status),
      value: dataConnector.status,
    }))
    const status = uniqBy(statusList, 'value')

    const categoryList = dataConnectorsList?.ua_dataConnectorsList
      .map((dataConnector) => dataConnector.categories)
      .flat()
      .map((category) => ({
        title: convertWordsToTitleCase(category.title),
        value: category.title,
      }))
    const category = uniqBy(categoryList, 'title')

    return {
      business,
      status,
      category,
    }
  }, [dataConnectorsList?.ua_dataConnectorsList])

  const filteredDataConnectors = useMemo(() => {
    if (
      formValues.business.length > 0 ||
      formValues.status.length > 0 ||
      formValues.category.length > 0 ||
      formValues.search
    ) {
      return dataConnectorsList?.ua_dataConnectorsList
        .filter((dataConnector) =>
          formValues.business.length > 0
            ? formValues.business.find(
                (businessValue) => dataConnector.business.id === businessValue,
              )
            : dataConnector,
        )
        .filter((dataConnector) =>
          formValues.status.length > 0
            ? formValues.status.find((statusValue) => dataConnector.status === statusValue)
            : dataConnector,
        )
        .filter((dataConnector) =>
          formValues.category.length > 0
            ? formValues.category.find((categoryValue) =>
                dataConnector.categories.find((category) => category.title === categoryValue),
              )
            : dataConnector,
        )
        .filter((dataConnector) =>
          dataConnector.title.toLowerCase().includes(formValues.search.toLowerCase()),
        )
    }
    return dataConnectorsList?.ua_dataConnectorsList
  }, [dataConnectorsList?.ua_dataConnectorsList, formValues])

  if (loading) {
    return <Spinner />
  }

  if (error || packageDataConnectorsListError) {
    dispatch(
      displayAlert({
        heading: 'Error: unable to fetch data connectors',
        type: 'error',
        text: 'Please try refreshing the page or try again later.',
      }),
    )
    log.error(`Error fetching data connectors: ${JSON.stringify(error)}`)
  }

  const onCloseModal = () => {
    localStorage.setItem('connector_name', '')
    setShow(false)
    dispatch(selectConnector(undefined))
    dispatch(setCurrentView(1))
  }

  const modal = (
    <MyConnectorsModal
      packageTier={packageDataConnectorsList?.ua_packagedataConnectorsList?.packageType?.key}
      packageDataConnectorsList={
        packageDataConnectorsList?.ua_packagedataConnectorsList?.dataConnectorTypes
      }
      show={show}
      onClose={onCloseModal}
    />
  )

  if (isSmallOrBigger) {
    if (user?.data.attributes.flags.marketing_analytics_flag) {
      return (
        <div tw="w-full max-w-[1400px]">
          <div tw="mt-5 mb-7 flex items-center justify-between">
            <h2 tw="whitespace-nowrap">My Connectors</h2>
            <Button
              tw="justify-center"
              startIcon={<FontAwesomeIcon icon={solid('plus')} />}
              onClick={() => setShow(true)}
              variant="contained"
            >
              Add Data Connector
            </Button>
          </div>
          <FormProvider {...methods}>
            <form tw="w-full">
              <MyConnectorsFilter data={menuFilterItems} setValue={setValue} values={formValues} />
            </form>
          </FormProvider>
          <MyConnectorsDesktop
            data={filteredDataConnectors}
            updateActionConnector={updateActionConnector}
          />
          {mostPopularConnectorsList &&
            mostPopularConnectorsList.ua_catalog &&
            mostPopularConnectorsList.ua_catalog.length > 0 && (
              <MyConnectorsCarousel
                setShow={setShow}
                mostPopularConnectorsList={mostPopularConnectorsList.ua_catalog}
                packageTier={packageDataConnectorsList?.ua_packagedataConnectorsList?.title}
                packageDataConnectorsList={
                  packageDataConnectorsList?.ua_packagedataConnectorsList?.dataConnectorTypes
                }
              />
            )}
          {modal}
        </div>
      )
    }
    return (
      <div data-testid="my-connectors-cta-container" tw="relative w-full overflow-hidden">
        <img
          src={plugImage}
          alt="call to action"
          tw="w-full object-cover shadow-sm brightness-50 scale-[1.01] blur-[2px] h-[80vh]"
        />
        <CTAContainer data={SERVICE_CTA_DATA.marketing_analytics_flag} />
      </div>
    )
  }

  if (user?.data.attributes.flags.marketing_analytics_flag) {
    return (
      <div tw="w-full">
        <h2 tw="mt-6 mb-7 whitespace-nowrap">My Connectors</h2>
        <DeprecatedButton
          tw="mb-4 justify-center"
          icon={<FontAwesomeIcon icon={solid('plus')} />}
          text="Add Data Connector"
          onClick={() => setShow(true)}
        />
        <FormProvider {...methods}>
          <form tw="w-full">
            <MyConnectorsFilter data={menuFilterItems} setValue={setValue} values={formValues} />
          </form>
        </FormProvider>

        <MyConnectorsMobile
          data={filteredDataConnectors}
          updateActionConnector={updateActionConnector}
        />
        {mostPopularConnectorsList &&
          mostPopularConnectorsList.ua_catalog &&
          mostPopularConnectorsList.ua_catalog.length > 0 && (
            <MyConnectorsCarousel
              setShow={setShow}
              mostPopularConnectorsList={mostPopularConnectorsList.ua_catalog}
              packageTier={packageDataConnectorsList?.ua_packagedataConnectorsList?.title}
              packageDataConnectorsList={
                packageDataConnectorsList?.ua_packagedataConnectorsList?.dataConnectorTypes
              }
            />
          )}
        {modal}
      </div>
    )
  }

  return (
    <div data-testid="my-connectors-cta-container" tw="relative w-full overflow-hidden">
      <img
        src={plugImage}
        alt="call to action"
        tw="w-full object-cover shadow-sm brightness-50 scale-[1.01] blur-[2px] h-[80vh]"
      />
      <CTAContainer data={SERVICE_CTA_DATA.marketing_analytics_flag} />
    </div>
  )
}
