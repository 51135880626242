import MuiCard from '@mui/material/Card'
import { FC, MouseEventHandler, useState } from 'react'

import { CardProps } from './card.types'

export const Card: FC<CardProps> = ({
  children,
  onMouseOver,
  onMouseOut,
  variant,
  hoverElevation,
  elevation = 1,
  ...props
}) => {
  const [currentElevation, setCurrentElevation] = useState(elevation)
  const cardElevation = variant === 'outlined' ? 0 : currentElevation

  const handleMouseOver: MouseEventHandler<HTMLDivElement> = (e) => {
    onMouseOver?.(e)
    setCurrentElevation(hoverElevation ?? elevation)
  }

  const handleMouseOut: MouseEventHandler<HTMLDivElement> = (e) => {
    onMouseOut?.(e)
    setCurrentElevation(elevation)
  }

  return (
    <MuiCard
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      elevation={cardElevation}
      variant={variant}
      {...props}
    >
      {children}
    </MuiCard>
  )
}
