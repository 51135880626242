import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { ReactNode } from 'react'
import { theme } from 'twin.macro'

import GlobalStyles from '../GlobalStyles'
import { ResponsiveDebugger } from '../ResponsiveDebugger'
import { ExtendedPalette } from './ThemeProvider.types'

const palette: ExtendedPalette = {
  primary: {
    main: theme`colors.primary.700`,
    contrastText: theme`colors.white`,
    dark: theme`colors.primary.900`,
    light: theme`colors.primary.500`,
    '50': theme`colors.primary.50`,
    '100': theme`colors.primary.100`,
    '200': theme`colors.primary.200`,
    '300': theme`colors.primary.300`,
    '400': theme`colors.primary.400`,
    '500': theme`colors.primary.500`,
    '600': theme`colors.primary.600`,
    '700': theme`colors.primary.700`,
    '800': theme`colors.primary.800`,
    '900': theme`colors.primary.900`,
  },
  secondary: {
    main: theme`colors.secondary.700`,
    contrastText: theme`colors.white`,
    '50': theme`colors.secondary.50`,
    '100': theme`colors.secondary.100`,
    '200': theme`colors.secondary.200`,
    '300': theme`colors.secondary.300`,
    '400': theme`colors.secondary.400`,
    '500': theme`colors.secondary.500`,
    '600': theme`colors.secondary.600`,
    '700': theme`colors.secondary.700`,
    '800': theme`colors.secondary.800`,
    '900': theme`colors.secondary.900`,
  },
  'accent-one': {
    main: theme`colors.accent-one.700`,
    contrastText: theme`colors.white`,
    '50': theme`colors.accent-one.50`,
    '100': theme`colors.accent-one.100`,
    '200': theme`colors.accent-one.200`,
    '300': theme`colors.accent-one.300`,
    '400': theme`colors.accent-one.400`,
    '500': theme`colors.accent-one.500`,
    '600': theme`colors.accent-one.600`,
    '700': theme`colors.accent-one.700`,
    '800': theme`colors.accent-one.800`,
    '900': theme`colors.accent-one.900`,
  },
  'accent-two': {
    main: theme`colors.accent-two.700`,
    contrastText: theme`colors.white`,
    '50': theme`colors.accent-two.50`,
    '100': theme`colors.accent-two.100`,
    '200': theme`colors.accent-two.200`,
    '300': theme`colors.accent-two.300`,
    '400': theme`colors.accent-two.400`,
    '500': theme`colors.accent-two.500`,
    '600': theme`colors.accent-two.600`,
    '700': theme`colors.accent-two.700`,
    '800': theme`colors.accent-two.800`,
    '900': theme`colors.accent-two.900`,
  },
  'accent-three': {
    main: theme`colors.accent-three.700`,
    contrastText: theme`colors.white`,
    '50': theme`colors.accent-three.50`,
    '100': theme`colors.accent-three.100`,
    '200': theme`colors.accent-three.200`,
    '300': theme`colors.accent-three.300`,
    '400': theme`colors.accent-three.400`,
    '500': theme`colors.accent-three.500`,
    '600': theme`colors.accent-three.600`,
    '700': theme`colors.accent-three.700`,
    '800': theme`colors.accent-three.800`,
    '900': theme`colors.accent-three.900`,
  },
  'accent-four': {
    main: theme`colors.accent-four.700`,
    contrastText: theme`colors.white`,
    '50': theme`colors.accent-four.50`,
    '100': theme`colors.accent-four.100`,
    '200': theme`colors.accent-four.200`,
    '300': theme`colors.accent-four.300`,
    '400': theme`colors.accent-four.400`,
    '500': theme`colors.accent-four.500`,
    '600': theme`colors.accent-four.600`,
    '700': theme`colors.accent-four.700`,
    '800': theme`colors.accent-four.800`,
    '900': theme`colors.accent-four.900`,
  },
  'accent-five': {
    main: theme`colors.accent-five.700`,
    contrastText: theme`colors.white`,
    '50': theme`colors.accent-five.50`,
    '100': theme`colors.accent-five.100`,
    '200': theme`colors.accent-five.200`,
    '300': theme`colors.accent-five.300`,
    '400': theme`colors.accent-five.400`,
    '500': theme`colors.accent-five.500`,
    '600': theme`colors.accent-five.600`,
    '700': theme`colors.accent-five.700`,
    '800': theme`colors.accent-five.800`,
    '900': theme`colors.accent-five.900`,
  },
  'accent-six': {
    main: theme`colors.accent-six.700`,
    contrastText: theme`colors.white`,
    '50': theme`colors.accent-six.50`,
    '100': theme`colors.accent-six.100`,
    '200': theme`colors.accent-six.200`,
    '300': theme`colors.accent-six.300`,
    '400': theme`colors.accent-six.400`,
    '500': theme`colors.accent-six.500`,
    '600': theme`colors.accent-six.600`,
    '700': theme`colors.accent-six.700`,
    '800': theme`colors.accent-six.800`,
    '900': theme`colors.accent-six.900`,
  },
  monochrome: {
    main: theme`colors.monochrome.700`,
    contrastText: theme`colors.white`,
    '50': theme`colors.monochrome.50`,
    '100': theme`colors.monochrome.100`,
    '200': theme`colors.monochrome.200`,
    '300': theme`colors.monochrome.300`,
    '400': theme`colors.monochrome.400`,
    '500': theme`colors.monochrome.500`,
    '600': theme`colors.monochrome.600`,
    '700': theme`colors.monochrome.700`,
    '800': theme`colors.monochrome.800`,
    '900': theme`colors.monochrome.900`,
  },
}

const headerTheme = {
  fontFamily: theme`fontFamily.heading`,
  fontWeight: 700,
}

export const cartMuiTheme = createTheme({
  palette,
  typography: {
    fontFamily: theme`fontFamily.sans`,
    button: {
      fontWeight: 700,
      fontFamily: theme`fontFamily.heading`,
    },
    h1: headerTheme,
    h2: headerTheme,
    h3: headerTheme,
    h4: {
      ...headerTheme,
      fontSize: theme`fontSize.2xl`,
      letterSpacing: theme`letterSpacing.tightXl`,
    },
    h5: {
      ...headerTheme,
      fontSize: theme`fontSize.xl`,
      letterSpacing: theme`letterSpacing.tightLg`,
    },
    h6: {
      ...headerTheme,
      fontSize: theme`fontSize.base`,
      letterSpacing: theme`letterSpacing.tight`,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '& .MuiAutocomplete-popper': {
          'z-index': '10000 !important',
          '@media (max-width: 639px)': {
            width: 'calc(100vw - 57px) !important',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            padding: '0 0 0 6px !important',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: ({ ownerState }) => {
          const { size } = ownerState
          if (size === 'medium') {
            return {
              paddingTop: '14px !important',
              paddingBottom: '14px !important',
            }
          }
          return {}
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          lineHeight: '1.25',
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: theme`colors.white`,
          color: theme`colors.monochrome.800`,
        },
      },
    },
  },
})

export const ThemeProvider = ({
  children,
  responsiveDebugger = false,
  includeCSSDefaults = true,
}: {
  children: ReactNode
  responsiveDebugger?: boolean
  includeCSSDefaults?: boolean
}) => (
  <MuiThemeProvider theme={cartMuiTheme}>
    {includeCSSDefaults && <CssBaseline />}
    {includeCSSDefaults && <GlobalStyles />}
    {responsiveDebugger && <ResponsiveDebugger />}
    {children}
  </MuiThemeProvider>
)
