/**
 * Checks if a string contains any of the given phrases
 * @param phrases  The phrases to check for
 * @param inputString The string to check
 * @param caseInsensitive If true, the check will be case insensitive
 * @returns true if the string contains any of the phrases, false otherwise
 */
export const checkIfPhrasesExist = (
  phrases: string[],
  inputString: string,
  caseInsensitive = false,
) => {
  return phrases.some((phrase) => {
    if (caseInsensitive) {
      return inputString.toLowerCase().includes(phrase.toLowerCase())
    }
    return inputString.includes(phrase)
  })
}
