import { Button, toggleZenDeskWidget } from '@cart/ui'
import { createGlobalStyle } from 'styled-components'

import { ReactComponent as UnreachableSVG } from '../../images/svgs/unreachable.svg'

const CustomStyles = createGlobalStyle`
  // Hide the Zendesk Launcher
  #launcher {
    display: none;
  }
`

export const UnsError = () => {
  const params = new URLSearchParams(window.location.search)
  const returnParam = params.get('return')

  return (
    <div tw="h-screen w-screen items-center justify-center p-8 sm:flex">
      <CustomStyles />
      <div tw="md:(flex items-center justify-center)">
        <div tw="mb-4 md:mb-0">
          <div tw="mb-8">
            <h1 tw="mb-2 text-3xl sm:text-4xl">Oops! we’re experiencing some hiccups on our end</h1>
            <p tw="text-lg">
              Please try returning to the previous page. Contact us if this persists.
            </p>
          </div>

          <Button tw="mr-3" href={returnParam} variant="contained" size="large">
            Return
          </Button>
          <Button size="large" onClick={toggleZenDeskWidget}>
            Contact Us
          </Button>
        </div>
        <UnreachableSVG tw="max-w-full" />
      </div>
    </div>
  )
}
