import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// Since RootState is just a type and not a runtime variable,
// we don't need to worry about a cyclic import.
// eslint-disable-next-line import/no-cycle
import { RootState } from '../root'

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.NX_DS_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as RootState).tokenSlice
    headers.set('content-type', 'application/vnd.api+json')
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  },
})

export const dsApi = createApi({
  reducerPath: 'dsApi',
  tagTypes: ['DataConnectorsCards', 'DataConnectors', 'Alerts', 'EmailReports'],
  baseQuery,
  endpoints: () => ({}),
})
