import { useReactiveVar } from '@apollo/client'
import {
  activeBizVar,
  InstanceType,
  useOrgProductInstanceListQuery,
} from '@brand-console/generated-graphql-hooks'
import { useLayoutStore } from '@brand-console/utilities'
import React, { memo, useEffect } from 'react'

import { Header as HeaderComponent } from './components/Header'
import { HeaderProps } from './header.types'

const HeaderComp = ({ product = null, toggleNav }: HeaderProps) => {
  const activeBiz = useReactiveVar(activeBizVar)
  const { setFulfillmentProvisioned } = useLayoutStore()
  const { data: productInstances } = useOrgProductInstanceListQuery({
    variables: {
      input: {
        businessId: activeBiz?.id,
      },
    },
    skip: !activeBiz?.id,
  })

  useEffect(() => {
    const hasFulfillmentProduct = productInstances?.org_productInstanceList?.find(
      (productInstance) => productInstance.instanceType === InstanceType.FULFILLMENT,
    )
    setFulfillmentProvisioned(!!hasFulfillmentProduct)
  }, [productInstances, setFulfillmentProvisioned])
  return <HeaderComponent product={product} toggleNav={toggleNav} />
}

export const Header = memo(HeaderComp)
