import {
  ApolloCache,
  BaseMutationOptions,
  DefaultContext,
  OperationVariables,
} from '@apollo/client'
import {
  OrgUserChangeOwnPasswordMutation,
  useOrgUserChangeOwnPasswordMutation,
} from '@brand-console/generated-graphql-hooks'
import { logger } from '@brand-console/utilities'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  PasswordStrength,
} from '@cart/ui'
import React, { FC, useState } from 'react'

import { ModalProps } from '../SettingsSecurity.types'
import { getFriendlyErrorMessage } from '@brand-console/utilities/errors'

export interface ChangePasswordModalProps extends ModalProps {
  successCallback: (
    data: OrgUserChangeOwnPasswordMutation,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clientOptions?: BaseMutationOptions<any, OperationVariables, DefaultContext, ApolloCache<any>>,
  ) => void
}
export const ChangePasswordModal: FC<ChangePasswordModalProps> = ({
  show,
  successCallback,
  closeCallback,
}) => {
  const log = logger.setLogger(logger.LoggerNames.SECURITY)

  const [passwordValid, setPasswordValid] = useState(false)

  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')

  const [errorMessage, setErrorMessage] = useState('')

  const [showFailureAlert, setShowFailureAlert] = useState(false)

  const [changePasswordMutation, { loading }] = useOrgUserChangeOwnPasswordMutation()

  const handleNewPasswordChange = (newPasswordValue: string) => {
    setNewPassword(newPasswordValue)
  }

  const handlePasswordValidated = (isValid: boolean) => {
    setPasswordValid(isValid)
  }

  const changePassword = async () => {
    setErrorMessage('')
    setShowFailureAlert(false)
    changePasswordMutation({
      variables: {
        input: {
          currentPassword,
          newPassword,
        },
      },
      onCompleted: successCallback,
      onError: (error) => {
        log.error('Change password error', error)
        setErrorMessage(error.message)
        setShowFailureAlert(true)
      },
    })
  }

  const handleClose = () => {
    setShowFailureAlert(false)
    setErrorMessage('')
    closeCallback()
    setPasswordValid(false)
  }

  return (
    <Dialog id="change-password-dialog" open={show} onClose={handleClose} width="sm">
      <DialogTitle>Change password</DialogTitle>
      <DialogContent dividers>
        <div tw="w-full">
          {showFailureAlert && (
            <Alert severity="error" onClose={() => setShowFailureAlert(false)} tw="mb-6">
              Something went wrong when attempting to update your password:
              <br />
              <b>{getFriendlyErrorMessage(errorMessage, 'password')}</b>
            </Alert>
          )}
          <Input
            id="confirm-password"
            label="Enter your current password"
            type="password"
            placeholder="Enter your current password"
            onChange={(e) => setCurrentPassword(e.target.value)}
            tw="mb-6"
          />
          <PasswordStrength
            onNewPWChange={handleNewPasswordChange}
            onValidated={handlePasswordValidated}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeCallback()}>Cancel</Button>
        <Button
          loading={loading}
          disabled={!passwordValid}
          onClick={changePassword}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
