import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { icons } from './icons'

export const getIcon = (iconName: string) => {
  const isFontAwesome = iconName.startsWith('fa')
  if (isFontAwesome) {
    return <FontAwesomeIcon icon={icons[iconName] as IconProp} />
  }
  const Element = icons[iconName] as React.ElementType

  if (!Element) {
    return null
  }

  return (
    <span tw="w-full">
      <Element />
    </span>
  )
}
