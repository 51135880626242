import { useReactiveVar } from '@apollo/client'
import {
  activeOrgVar,
  useUAActionDataConnectorMutation,
  useUAMyConnectorsOAuthUrlLazyQuery,
} from '@brand-console/generated-graphql-hooks'
import { Button, ButtonProps } from '@cart/ui'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import google_logo from '../../../../../assets/icons/google_logo.svg'
import { dataConnectorsData, dataConnectorsFields } from '../../../../../helpers/data'
import { initFacebook } from '../../../../../helpers/initHooks'
import { useAppDispatch } from '../../../../../stores/hooks/hooks'
import { displayAlert } from '../../../../../stores/reducers/alertPropsSlice'
import {
  selectConnector,
  setCurrentView,
  setFacebookToken,
} from '../../../../../stores/reducers/dataConnectorsSlice'
import { RootState } from '../../../../../stores/root'

interface ButtonSetupProps extends ButtonProps {
  connectorName?: keyof typeof dataConnectorsFields
  setShow?: any
  isIncomplete?: boolean
  incompleteDataConnector?: any
  text?: string
}

type Props = ButtonSetupProps

export const ButtonSetup = ({
  connectorName,
  setShow,
  text,
  disabled,
  variant,
  isIncomplete,
  incompleteDataConnector,
  size,
}: Props) => {
  const dispatch = useAppDispatch()
  const activeOrg = useReactiveVar(activeOrgVar)
  const selectedConnector = useSelector(
    (state: RootState) => state.dataConnectors.selectedConnector,
  )

  const [updateActionConnector] = useUAActionDataConnectorMutation()

  const selectedConnectorType = useMemo(() => {
    if (selectedConnector || connectorName) {
      return dataConnectorsData.find(
        (item) => item.name === selectedConnector || item.name === connectorName,
      )?.connectorName
    }
  }, [selectedConnector, connectorName])

  const [getOAuthUrl, { error: dataConnectorsError }] = useUAMyConnectorsOAuthUrlLazyQuery({
    // once we get the url, send the user along on their merry way
    onCompleted: (oAuthData) => {
      if (oAuthData) {
        document.location.href = oAuthData.ua_dataConnectorAuthUrl.url
      }
      if (dataConnectorsError) {
        dispatch(
          displayAlert({
            heading: 'Error',
            type: 'error',
            text: 'Something went wrong',
          }),
        )
      }
    },
  })

  const callbackOAuth = () => {
    getOAuthUrl({
      variables: {
        connectorType: selectedConnectorType,
      },
    })
  }

  // response for connectors
  const responseFacebook = () => {
    initFacebook().then(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.FB.login(
        (response) => dispatch(setFacebookToken(response.authResponse?.accessToken)),
        {
          scope: 'public_profile,email,pages_show_list,pages_read_engagement,ads_read',
        },
      ),
    )
    connectorName && setShow(true)
    dispatch(setCurrentView(2))
  }

  const responseShopify = () => {
    connectorName && setShow(true)
    dispatch(setCurrentView(3))
  }

  const response = () => {
    connectorName
      ? localStorage.setItem('connector_name', connectorName)
      : localStorage.setItem('connector_name', selectedConnector)
    if (connectorName) {
      dispatch(selectConnector(connectorName))
    }

    if (isIncomplete && incompleteDataConnector) {
      updateActionConnector({
        variables: {
          action: 'delete',
          dataConnectorType: incompleteDataConnector?.dataConnectorType,
          dataConnectorId: Number(incompleteDataConnector?.id),
          organizationId: activeOrg?.id.toString(),
        },
      })
    }

    switch (selectedConnector || connectorName) {
      case 'facebook':
        responseFacebook()
        break
      case 'amazon-advertising':
        callbackOAuth()
        break
      case 'shopify':
        responseShopify()
        break
      case 'google-analytics':
        callbackOAuth()
        break
      case 'google-analytics-4':
        callbackOAuth()
        break
      case 'google-search-console':
        callbackOAuth()
        break
      case 'google-adwords':
        callbackOAuth()
        break
      case 'tiktok':
        callbackOAuth()
        break
      default:
        connectorName && setShow(true)
        dispatch(setCurrentView(2))
    }
  }

  if (text === 'Sign in with Google') {
    return (
      <button
        type="button"
        tw="flex w-max cursor-pointer items-center justify-between rounded-sm border border-[#4285F4]"
        onClick={() => response()}
      >
        <img tw="mx-2 h-[18px]" src={google_logo} alt="" />
        <div tw="py-1 px-3 text-white bg-[#4285F4]">
          <span tw="font-semibold text-xs">{text}</span>
        </div>
      </button>
    )
  }

  return (
    <Button variant={variant} onClick={() => response()} disabled={disabled} size={size}>
      {text}
    </Button>
  )
}

ButtonSetup.defaultProps = {
  connectorName: null,
  setShow: null,
}
