import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, ReactNode } from 'react'

export type CriteriaProps = { children: ReactNode; checked: boolean }
export const Criteria: FC<CriteriaProps> = ({ children, checked }) => (
  <li tw="text-monochrome-900/[0.65] tracking-tight">
    {!checked ? (
      <FontAwesomeIcon tw="mr-2 text-monochrome-900/[0.60]" icon={regular('circle')} />
    ) : (
      <FontAwesomeIcon tw="mr-2 text-accent-three-700" icon={solid('circle-check')} />
    )}
    {children}
  </li>
)
