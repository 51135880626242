import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { StyleSheetManager } from 'styled-components'

import { DropdownItem } from '../dropdown/dropdown-item/dropdown-item'
import {
  StyledPagination,
  StyledPaginationButton,
  StyledPaginationDropdown,
} from './table-pagination.styles'
import { TablePaginationProps } from './table-pagination.types'

export const TablePagination: FC<TablePaginationProps & Record<string, unknown>> = ({
  currentPageCallback,
  pageSize,
  pageSizeCallback,
  renderRoot,
  totalCount,
  currentPage,
}: TablePaginationProps) => {
  const [page, setPage] = useState(1)
  const totalPageCount = Math.ceil(totalCount / pageSize)
  const perPageOptions = [5, 10, 25, 50, 100]
  const [itemsPerPage, setItemsPerPage] = useState(pageSize)

  useEffect(() => {
    if (currentPage) {
      setPage(currentPage)
    }
  }, [currentPage])
  const handlePageChange = (newPage: number) => {
    setPage(newPage)
    currentPageCallback(newPage)
  }

  const onNext = () => {
    if (page < totalPageCount) {
      handlePageChange(page + 1)
    }
  }

  const onPrevious = () => {
    if (page > 1) {
      handlePageChange(page - 1)
    }
  }

  const updateItemsPerPage = useCallback(
    (selectedRows: any) => {
      const elementsPerPage = parseInt(selectedRows[0].value, 10)
      setItemsPerPage(elementsPerPage)
      pageSizeCallback(elementsPerPage)
      // reset back to first page if items per page change
      setPage(1)
    },
    [pageSizeCallback],
  )

  return (
    <StyleSheetManager target={renderRoot}>
      <StyledPagination tw="flex h-10 w-full select-none items-center justify-between text-xs">
        <div>
          Page {page} of {totalPageCount}
        </div>
        <div tw="flex items-center">
          <span tw="mr-2 whitespace-nowrap sm:mx-2">Items per page:</span>
          <StyledPaginationDropdown
            tw="w-16 sm:w-20"
            onChange={updateItemsPerPage}
            id="my-dropdown"
          >
            {perPageOptions.map((option) => {
              return (
                <DropdownItem
                  key={option}
                  text={`${option}`}
                  value={`${option}`}
                  selected={option === itemsPerPage}
                />
              )
            })}
          </StyledPaginationDropdown>
          {/* TODO: this is a pro icon
        <FontAwesomeIcon
          onClick={() => handlePageChange(1)}
          icon={solid('chevrons-left')}
          tw="mr-3"
          size="xl"
        /> */}
          <StyledPaginationButton>
            <FontAwesomeIcon
              onClick={() => onPrevious()}
              icon={solid('chevron-left')}
              tw="mr-3 cursor-pointer"
              size="xl"
            />
          </StyledPaginationButton>
          <StyledPaginationButton>
            <FontAwesomeIcon
              onClick={() => onNext()}
              icon={solid('chevron-right')}
              tw="mr-3 cursor-pointer"
              size="xl"
            />
          </StyledPaginationButton>
          {/* TODO: this is a pro icon
        <FontAwesomeIcon
          onClick={() => handlePageChange(totalPageCount)}
          icon={solid('chevrons-right')}
          tw="mr-3"
          size="xl"
        /> */}
        </div>
      </StyledPagination>
    </StyleSheetManager>
  )
}

// export default TablePagination
