import { Auth0ApolloProvider, Auth0ProviderWithHistory } from '@brand-console/utilities'
import { AppProvider, ThemeProvider } from '@cart/ui'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { LicenseInfo } from '@mui/x-license-pro'
import { StrictMode } from 'react'
import * as ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'

import { PreApp } from './components/PreApp'
import { DD_LOGS, DD_RUM } from './logging'
import registerServiceWorker from './serviceWorker/serviceWorkerRegistration'
declare global {
  interface Window {
    requeryACMyStoresAPI: () => void
    zE?: ZendeskWidget
  }
}

DD_RUM()
DD_LOGS()

datadogRum.startSessionReplayRecording()
datadogLogs.logger.setLevel('error')
if (process.env.NX_ENV === 'local') {
  datadogLogs.logger.setHandler('console')
}

LicenseInfo.setLicenseKey(process.env.NX_MUI_X_PRO_LICENSE_KEY)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <StrictMode>
    <ThemeProvider>
      <Router>
        <Auth0ProviderWithHistory>
          <Auth0ApolloProvider>
            <AppProvider>
              <PreApp />
            </AppProvider>
          </Auth0ApolloProvider>
        </Auth0ProviderWithHistory>
      </Router>
    </ThemeProvider>
  </StrictMode>,
)

registerServiceWorker()
