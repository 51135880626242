/* eslint-disable react/jsx-props-no-spreading */
import { useReactiveVar } from '@apollo/client'
import {
  activeBizVar,
  activeOrgVar,
  useUAOrganizationLazyQuery,
} from '@brand-console/generated-graphql-hooks'
import { useLayoutStore } from '@brand-console/utilities'
import { Footer, Snackbar, useAppContext, useBreakpoint, useParamState } from '@cart/ui'
import { Header } from '@cartdotcom/header'
import { LeftNav } from '@cartdotcom/left-nav'
import { UnifiedSidebar } from '@cartdotcom/unified-sidebar'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useCallback, useEffect, useState } from 'react'
import { createGlobalStyle } from 'styled-components'

import { StyledContainer } from './BrandHq.styles'

const CustomStyles = createGlobalStyle`
  // Hide the Zendesk Launcher
  #launcher {
     display: none;
  }
`

export const BrandHq = ({ children }) => {
  const activeOrg = useReactiveVar(activeOrgVar)
  const activeBusiness = useReactiveVar(activeBizVar)
  const { setUnifiedAnalyticsProvisioned, snackbar, setSnackbar } = useAppContext()
  const { product } = useLayoutStore()
  const isExtraLarge = useBreakpoint('xl')
  const { seTempShowNewHeader230324 } = useFlags()

  const {
    isMcmNav,
    isConstellation,
    businessId,
    tenantCode: tenantCodeParam,
    displayMode,
  } = useParamState()

  const contentOnly = displayMode === 'modal'

  const [open, setOpen] = useState<boolean>(isExtraLarge)
  const [getUAOrganization, { data: uaOrganization, loading: uaOrganizationLoading }] =
    useUAOrganizationLazyQuery()

  const uaEnabled = uaOrganization?.ua_organization.length > 0

  const toggleMenu = useCallback(() => {
    setOpen(!open)
  }, [open])

  useEffect(() => {
    if (activeOrg?.id) {
      getUAOrganization({ variables: { orgId: activeOrg.id } })
    }
  }, [activeOrg?.id, getUAOrganization])

  useEffect(() => {
    if (!uaOrganizationLoading) {
      setUnifiedAnalyticsProvisioned(uaEnabled)
    }
  }, [setUnifiedAnalyticsProvisioned, uaEnabled, uaOrganizationLoading])

  if (isMcmNav === null) return null

  let businessIdParam = businessId

  if (tenantCodeParam) {
    businessIdParam = ''
  } else if (!businessIdParam && !tenantCodeParam) {
    businessIdParam = activeBusiness?.id?.toString()
  }

  if (isConstellation) {
    return (
      <main data-cartid="layout-container">
        {window.location.pathname === '/unserror' ? null : (
          <UnifiedSidebar
            business-id={businessIdParam}
            dfw-url={process.env.NX_DFW_ORIGIN}
            jazz-url={process.env.NX_JAZZ_ORIGIN}
            mcm-url={process.env.NX_MCM_ORIGIN}
            bc-url={process.env.NX_APP_ORIGIN}
            tenant-code={tenantCodeParam}
            spa
            uns-url={process.env.NX_UNIFIED_NAV_SERVICE_URL}
            product="bc"
            logout-url={`${process.env.NX_APP_ORIGIN}/logout`}
          />
        )}
        <div data-cartid="app-container">{children}</div>
      </main>
    )
  }

  return (
    <StyledContainer $isNewHeader={seTempShowNewHeader230324}>
      <CustomStyles />
      {!contentOnly && (
        <>
          <div className="Header" role="banner">
            <Header toggleNav={toggleMenu} />
          </div>
          <div className="Left-Nav">
            <LeftNav open={open} product={isMcmNav ? 'mcm' : product} />
          </div>
        </>
      )}
      <div className="Body">{children}</div>
      {!contentOnly && (
        <div className="Footer">
          <Footer />
        </div>
      )}
      {snackbar && <Snackbar {...snackbar} onClose={() => setSnackbar(null)} />}
    </StyledContainer>
  )
}
