const FACEBOOK_APP_ID = process.env.NX_FACEBOOK_APP_ID
const AMAZON_ADS_APP_ID = process.env.NX_AMAZON_ADS_APP_ID
export const initFacebook = () =>
  new Promise((resolve) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.FB.init({
      appId: FACEBOOK_APP_ID,
      cookie: true,
      xfbml: false,
      version: 'v17.0',
    })
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolve()
  })

export const initAmazon = () =>
  new Promise((resolve) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.amazon.Login.setClientId(AMAZON_ADS_APP_ID)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolve()
  })

export const initSDK = () =>
  new Promise((resolve) => {
    ;(function (d, s, id) {
      let js = d.getElementsByTagName(s)[0]
      const fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      js.setAttribute('type', 'text/javascript')
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
    ;(function (d) {
      const a = d.createElement('script')
      a.type = 'text/javascript'
      a.async = true
      a.id = 'amazon-login-sdk'
      a.src = 'https://assets.loginwithamazon.com/sdk/na/login1.js'
      d.getElementById('root').appendChild(a)
    })(document)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolve()
  })
