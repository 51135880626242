import { logger } from '@brand-console/utilities'

export interface DFWProvisioningMetadata {
  blocked: boolean
  brand_console: boolean
  current_package: string
  deleted: boolean
  email: string
  payment_term: string
  suspended: boolean
  username: string
}

export interface IsDFWEnabledProps {
  token: string
  email: string
  log: typeof logger
}
/**
 * Checks if the user has access to DFW.
 * Make sure to log the error if the response is not successful.
 */
export const isDFWEnabled = async ({ token, email, log }: IsDFWEnabledProps): Promise<boolean> => {
  if (process.env.NX_DFW_ENABLED !== 'true') {
    return false
  }

  try {
    const encodedEmail = encodeURIComponent(email as string)
    const httpHeaders = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
    const response = await fetch(`${process.env.NX_DFW_USERS_API_URL}?email=${encodedEmail}`, {
      method: 'GET',
      headers: httpHeaders,
    })

    if (!response.ok) {
      if (response.status !== 404) {
        log?.error('fetchDFWAccountAPI Response Error', {
          status: response.status,
          statusText: response.statusText,
          bodyText: await response.text(),
          email,
        })
      }
      return false
    }

    const responseJSON = await response.json()

    if (responseJSON.errors) {
      if (responseJSON.errors?.[0] !== 'not found')
        log?.error('fetchDFWAccountAPI Response JSON Error', { email, response: responseJSON })
      return false
    }

    const dfwAccount: DFWProvisioningMetadata = responseJSON
    return !dfwAccount.blocked && !dfwAccount.suspended && !dfwAccount.deleted
  } catch (error) {
    log?.error('fetchDFWAccountAPI Error', { email }, error as Error)
    return false
  }
}
