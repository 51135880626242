import {
  activeBizVar,
  activeOrgVar,
  useCreateOrganizationMutation,
} from '@brand-console/generated-graphql-hooks'
import {
  localStorageHelper,
  LocalStorageKeys,
} from '@brand-console/utilities'
import { Spinner, useDefaultInfo, useRouter } from '@cart/ui'
import { useCurrentContext } from '@cartdotcom/auth'
import React, { useEffect, useRef } from 'react'

const CreateOrganizationAutomated = () => {
  const { organization: defaultOrg, business: defaultBiz } = useDefaultInfo()
  const [createOrganization] = useCreateOrganizationMutation()
  const hasCalledMutation = useRef(false)
  const { pathname, navigate, location } = useRouter()
  const { setNewOrganization } = useCurrentContext()

  useEffect(() => {
    const changeContext = async (org) => {
      await setNewOrganization(org)
    }
    if (defaultBiz.name && defaultOrg.name && !hasCalledMutation.current) {
      createOrganization({
        variables: {
          createOrganizationInput: {
            name: defaultOrg.name,
            businessName: defaultBiz.name,
          },
        },
        onCompleted: (data) => {
          const onlyOrg = data.org_organizationCreate
          const onlyBusiness = data.org_organizationCreate.businesses[0]
          activeBizVar(onlyBusiness)
          const org = {
            businessEntityTerminology: onlyOrg.businessEntityTerminology,
            businesses: [onlyBusiness],
            createdAt: onlyOrg.createdAt,
            id: onlyOrg.id,
            name: onlyOrg.name,
            roleName: onlyOrg.roleName,
          }
          activeOrgVar(org)
          localStorageHelper.set(LocalStorageKeys.BUSINESS, onlyBusiness)
          changeContext(org)
          navigate(`${pathname}${location.search}`)
        },
      })
      hasCalledMutation.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Spinner tw="m-auto h-64 w-64" />
}

export default CreateOrganizationAutomated
