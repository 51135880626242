import { Spinner } from '@cart/ui'
import React, { lazy, Suspense } from 'react'

const OrdersApp = lazy(() => import('./OrdersLazy'))
const ShipmentsApp = lazy(() => import('./ShipmentsLazy'))

export const Orders = () => (
  <Suspense fallback={<Spinner type="global" />}>
    <OrdersApp />
  </Suspense>
)

export const Shipments = () => (
  <Suspense fallback={<Spinner type="global" />}>
    <ShipmentsApp />
  </Suspense>
)
