import tw, { css, styled } from 'twin.macro'

import { TextareaProps } from './textarea.types'

export const StyledTextareaContainer = styled.div(() => [tw`w-full text-left`])

export const StyledTextareaLabel = styled.label((props: TextareaProps | any) => [
  tw`block font-normal text-xs text-monochrome-900`,
  props['data-hasfocus'] && !props['data-haserror'] && tw`text-primary-700`,
  props['data-haserror'] && tw`text-accent-two-900`,
])

export const StyledTextareaField = styled.textarea((props: TextareaProps | any) => [
  tw`block w-full rounded-md border-2 border-monochrome-500 p-2 pr-10 text-sm placeholder-monochrome-600 disabled:bg-monochrome-50`,
  tw`focus:(border-primary-700 outline-none ring-accent-two-900)`,
  props['data-haserror'] && tw`border-accent-two-900 focus:border-accent-two-900`,
  css`
    height: 82px;
  `,
])

export const StyledMessage = styled.p((props: TextareaProps | any) => [
  tw`mt-1 text-monochrome-600 text-xs`,
  props['data-haserror'] && tw`text-accent-two-900`,
  props['data-hasfocus'] && tw`text-monochrome-900`,
])
