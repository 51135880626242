import { nanoid } from 'nanoid'
import React, { ReactElement } from 'react'

export const GrowthMarketingIcon = ({ className }: { className?: string }): ReactElement => {
  const title = 'Growth Marketing Icon'
  const idA = nanoid()
  const idB = nanoid()
  return (
    <svg
      aria-label={title}
      className={className}
      fill="none"
      role="img"
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${idB})`}>
        <path
          d="M46.0433 33.3235C50.4133 25.75 54.7334 18.1563 59.0435 10.5625C60.1636 8.60353 60.3336 6.49304 59.3735 4.42294C59.2935 4.25127 59.2135 4.07961 59.1335 3.90794C59.1035 3.84735 59.0635 3.78676 59.0335 3.72617C57.7435 1.46421 55.8435 0.151471 53.2434 0.0605882C50.9234 -0.0201961 48.5933 0.0302941 46.2633 0.0302941C43.8732 0.0302941 41.4732 0.0201961 39.0831 0.010098C37.1631 0.010098 35.243 0 33.323 0C31.3629 0 29.4029 0.0302941 27.4429 0.010098C24.6028 -0.0201961 22.3928 1.13098 20.9627 3.64539C16.6626 11.1583 12.3725 18.6814 8.08246 26.2044C7.15244 27.8504 6.92243 29.6074 7.31244 31.4958C7.52244 31.9401 7.73245 32.3945 7.94245 32.8388C8.03245 32.9903 8.12246 33.1418 8.21246 33.3033C9.48248 35.4441 11.3725 36.6559 13.8426 36.7266C16.4326 36.8073 19.0127 36.7467 21.6127 36.7467C24.7928 36.7467 27.9829 36.7568 31.1729 36.7568C34.013 36.7669 36.8631 36.7366 39.7231 36.7669C42.4732 36.8174 44.6632 35.7268 46.0433 33.3235Z"
          fill="#DA7009"
        />
        <path
          d="M0.862528 20.4788L21.623 56.7813C24.073 61.0729 30.2031 61.0729 32.6532 56.7813L53.4136 20.4788C55.8737 16.1771 52.8036 10.815 47.9035 10.815H6.38264C1.47254 10.8049 -1.58752 16.1771 0.862528 20.4788Z"
          fill="#069AE3"
        />
        <path
          d="M47.9035 10.815H16.8629C13.9328 15.9448 11.0028 21.0847 8.07269 26.2246C7.14267 27.8706 6.91267 29.6276 7.30267 31.516C7.51268 31.9502 7.70268 32.3844 7.91269 32.8186L8.07269 33.1014C8.11269 33.1721 8.15269 33.2427 8.19269 33.3134C9.46272 35.4542 11.3528 36.666 13.8228 36.7367C16.4129 36.8174 18.9929 36.7569 21.593 36.7569C24.773 36.7569 27.9631 36.767 31.1532 36.767C33.9932 36.777 36.8433 36.7468 39.7034 36.7771C42.4634 36.7972 44.6435 35.7067 46.0335 33.3134C48.5635 28.9208 51.0836 24.518 53.5937 20.1153C55.7037 15.8943 52.6736 10.815 47.9035 10.815Z"
          fill={`url(#${idA})`}
        />
      </g>
      <defs>
        <linearGradient
          id={idA}
          x1="6.86929"
          y1="23.7968"
          x2="54.4179"
          y2="23.7968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#005982" />
          <stop offset="1" stopColor="#069AE3" />
        </linearGradient>
        <clipPath id={idB}>
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
