import { useReactiveVar } from '@apollo/client'
import {
  activeOrgVar,
  useUACheckBusinessMyConnectorsQuery,
} from '@brand-console/generated-graphql-hooks'
import { Button, DeprecatedDialog, Select, SelectItem } from '@cart/ui'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import React, { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { ErrorsMessage } from '../../../../../components/atoms/ErrorsMessage/ErrorsMessage'
import { ScrollbarStyled } from '../../../../../components/atoms/ScrollBarStyled/ScrollBarStyled'
import { setupGuideText, shopifyConnector } from '../../../../../helpers/data'
import { useMyConnectorsData } from '../../../../../hooks/useMyConnectorsData/useMyConnectorsData'
import { ConnectorsDataType } from '../../../../../stores/types/dataConnectorsTypes'
import { dataConnectorsFields } from '../../../data'
import { handleInputType } from './FormModal.partials'

type Props = {
  show: boolean
  amazonAdsProfilesData?: any
  amazonAdsProfilesLoading?: boolean
  onClose: () => void
  packageTier: string
  selectedConnector: keyof typeof dataConnectorsFields | undefined
  connectorCodes: ConnectorsDataType | null
  shopNameError?: string
  handleFormSubmit?: (values: any) => void
  handleShopifyFormSubmit?: (values: any) => void
}

export const FormModal = ({
  show,
  amazonAdsProfilesData,
  amazonAdsProfilesLoading,
  onClose,
  packageTier,
  selectedConnector,
  handleFormSubmit,
  handleShopifyFormSubmit,
  shopNameError,
}: Props) => {
  const activeOrg = useReactiveVar(activeOrgVar)
  const methods = useForm({ mode: 'onChange' })
  const { handleSubmit, setValue, watch } = methods
  const selectedBusiness = watch('business')
  const { data: checkBusiness } = useUACheckBusinessMyConnectorsQuery({
    variables: {
      organizationId: activeOrg?.id.toString(),
    },
  })
  const { dataConnectorsData } = useMyConnectorsData()
  const selectedConnectorType = useMemo(() => {
    if (dataConnectorsData) {
      return dataConnectorsData.find((item) => item.name === selectedConnector)?.connectorName
    }
  }, [dataConnectorsData])

  const availableBusiness = checkBusiness?.ua_dataConnectorCheckBusiness
  // TODO: when https://cartdotcom.atlassian.net/browse/DSAT-370 is completed
  // replace value; id with businessId and convert to number
  const options: { value: string; title: string }[] | null =
    (availableBusiness &&
      selectedConnectorType &&
      availableBusiness
        .find(({ connectorType }) => connectorType === selectedConnectorType)
        ?.businesses.map(({ id, name }) => {
          return { value: id, title: name }
        })) ||
    null

  const shopifyDialog = shopifyConnector[selectedConnector] && (
    <DeprecatedDialog
      show={show}
      id="shopify-dialog"
      close={onClose}
      title={shopifyConnector[selectedConnector].title}
      width={800}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <form tw="w-full" onSubmit={handleSubmit(handleShopifyFormSubmit)}>
          <DeprecatedDialog.Body>
            <div tw="flex w-full flex-col sm:flex-row">
              <div tw="flex w-full flex-col gap-4 sm:w-1/2">
                <Select id="business" required label="Business" size="small">
                  {options?.map((item) => (
                    <SelectItem data-testid="select-option" key={item.title} value={item.value}>
                      {item.title}
                    </SelectItem>
                  ))}
                </Select>
                {shopifyConnector[selectedConnector].fields.map(
                  ({ name, placeholder, label, type }) =>
                    handleInputType(packageTier, name, placeholder, label, type, setValue),
                )}
                {shopNameError && <ErrorsMessage message={shopNameError} />}
              </div>
              <div tw="ml-0 mt-7 w-full border-2 border-monochrome-200 [border-radius:0.286rem] sm:(ml-7 mt-0 w-1/2)">
                <ScrollbarStyled
                  style={{
                    height: '300px',
                  }}
                >
                  <div tw="p-4">
                    <h3>Setup Guide</h3>
                    <p tw="mb-4">
                      Follow these instructions to{' '}
                      <Link
                        tw="text-primary-700 underline"
                        to="https://apps.shopify.com/cart-unified-analytics-1"
                        target="_blank"
                      >
                        install the Cart Unified Analytics app
                      </Link>{' '}
                      on your Shopify store, then connect that Shopify store to your business.
                    </p>
                    <h4>Select Business</h4>
                    <p tw="mb-4">
                      This dropdown menu contains the list of your businesses on Cart.com. Select
                      the business associated with your{' '}
                      {dataConnectorsFields[selectedConnector].title} store.
                    </p>
                    <h4>Activation Code</h4>
                    <p>
                      This field accepts the unique activation code which will tie your Shopify
                      store to this data connector. If you have not already done so,{' '}
                      <Link
                        tw="text-primary-700 underline"
                        to="https://apps.shopify.com/cart-unified-analytics-1"
                        target="_blank"
                      >
                        click on this link
                      </Link>{' '}
                      to go to your Shopify store and install the Cart.com Unified Analytics app.
                      Once the app is installed on your Shopify store, you will see your activation
                      code on the app&apos;s status page. You can view the app&apos;s status page at
                      any time inside your Shopify store by clicking the &lsquo;Apps&rsquo; menu
                      item, then selecting the Cart.com Unified Analytics app from the
                      &lsquo;Installed Apps&rsquo; section of the window that pops up. This will
                      take you to the status page for the app, which will display your unique
                      activation code.
                    </p>
                  </div>
                </ScrollbarStyled>
              </div>
            </div>
          </DeprecatedDialog.Body>
          <DeprecatedDialog.Actions>
            <Button
              data-testid="shopify-close-button"
              variant="text"
              onClick={onClose}
              className="text-primary"
            >
              Close
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={typeof selectedBusiness === 'undefined'}
            >
              Continue setup
            </Button>
          </DeprecatedDialog.Actions>
        </form>
      </FormProvider>
    </DeprecatedDialog>
  )

  if (selectedConnector && selectedConnector === 'shopify') {
    return shopifyDialog
  }

  return selectedConnector && selectedConnectorType ? (
    <DeprecatedDialog
      id="connectors-dialog"
      show={show}
      close={onClose}
      title={dataConnectorsFields[selectedConnector].title}
      width={800}
    >
      <FormProvider {...methods}>
        <form tw="w-full" onSubmit={handleSubmit(handleFormSubmit)}>
          <DeprecatedDialog.Body>
            <div tw="flex w-full flex-col sm:flex-row">
              <div tw="flex w-full flex-col gap-4 sm:w-1/2">
                <Select id="business" required label="Business" size="small">
                  {options?.map((item) => (
                    <SelectItem data-testid="select-option" key={item.title} value={item.value}>
                      {item.title}
                    </SelectItem>
                  ))}
                </Select>
                {dataConnectorsFields[selectedConnector].fields.map(
                  ({ name, placeholder, label, type }) =>
                    handleInputType(packageTier, name, placeholder, label, type, setValue),
                )}
                {selectedConnector === 'amazon-advertising' && !amazonAdsProfilesLoading && (
                  <Autocomplete
                    id="profiles"
                    multiple
                    options={amazonAdsProfilesData?.ua_AmazonAdsGetProfiles?.profiles}
                    getOptionLabel={(option: any) =>
                      `${option.account_name} - ${option.profile_id}`
                    }
                    renderInput={(params) => <TextField {...params} label="Profile IDs" />}
                    size="small"
                    onChange={(e: any, newValue: any) => setValue('profiles', newValue)}
                  />
                )}
              </div>
              <div tw="ml-0 mt-7 w-full border-2 border-monochrome-200 [border-radius:0.286rem] sm:(ml-7 mt-0 w-1/2)">
                <ScrollbarStyled
                  style={{
                    height: '300px',
                  }}
                >
                  <div tw="p-4">
                    <h3>Setup Guide</h3>
                    <p className="mb-16px">
                      Follow these instructions to connect your{' '}
                      {dataConnectorsFields[selectedConnector].title} account to your business.
                    </p>
                    <h4>Select Business</h4>
                    <p className="mb-16px">
                      This dropdown menu contains the list of your businesses on Cart.com. Select
                      the business associated with your{' '}
                      {dataConnectorsFields[selectedConnector].title} store.
                    </p>
                    {dataConnectorsFields[selectedConnector].type === 'ConciergeDataConnector' && (
                      <>
                        <h4>Note</h4>
                        <p>
                          Within two business days we will email you to begin the process of setting
                          up the connector.
                        </p>
                      </>
                    )}
                    {dataConnectorsFields[selectedConnector].fields.find(
                      ({ name }) => name === 'startDate',
                    ) && (
                      <>
                        <h4>Start Date</h4>
                        <p className="mb-16px">
                          The connector will pull data from{' '}
                          {dataConnectorsFields[selectedConnector].title} beginning from this date.
                        </p>
                      </>
                    )}
                    {dataConnectorsFields[selectedConnector].fields.find(
                      ({ name }) => name === 'advertiserId',
                    ) && (
                      <>
                        <h4>Advertiser ID</h4>
                        <p className="mb-16px">
                          This is the unique value that identifies your{' '}
                          {dataConnectorsFields[selectedConnector].title} account. To find your
                          Advertiser ID, first log in to your{' '}
                          {dataConnectorsFields[selectedConnector].title} Ads Manager account on the{' '}
                          {dataConnectorsFields[selectedConnector].title} platform
                          (http://ads.tiktok.com). Once you have logged in, find your business name
                          in the top right corner of the screen. Click on your business name. You
                          will see ‘ID:’ and then a string of numbers. This is your advertiser ID.
                          Copy that value and enter it in the ‘Advertiser ID’ field here.
                        </p>
                      </>
                    )}

                    {dataConnectorsFields[selectedConnector].fields.map(
                      ({ name }) =>
                        setupGuideText.hasOwnProperty(name) && (
                          <>
                            <h4>{setupGuideText[name].title}</h4>
                            {setupGuideText[name].guide.length > 1 ? (
                              <ol tw="list-decimal">
                                {setupGuideText[name].guide.map((item) => (
                                  <li key={item}>{item}</li>
                                ))}
                              </ol>
                            ) : (
                              <p>{setupGuideText[name].guide[0]}</p>
                            )}
                          </>
                        ),
                    )}
                  </div>
                </ScrollbarStyled>
              </div>
            </div>
          </DeprecatedDialog.Body>
          <DeprecatedDialog.Actions>
            <Button data-testid="close-button" variant="text" onClick={onClose}>
              Close
            </Button>
            <Button variant="contained" type="submit" disabled={!selectedBusiness}>
              Save
            </Button>
          </DeprecatedDialog.Actions>
        </form>
      </FormProvider>
    </DeprecatedDialog>
  ) : null
}
