import { Palette, PaletteColor, useTheme } from '@mui/material/styles'
import React, { Children, FC, ReactElement, ReactNode } from 'react'

import { StyledButton, StyledButtonComponent, StyledIconButton } from './button.styles'
import { ButtonProps } from './button.types'

export const Button: FC<ButtonProps> = ({
  className,
  loading,
  iconButton,
  to,
  square,
  ...props
}) => {
  const theme = useTheme()
  const { color = 'primary' } = props
  let isIconButton = iconButton
  if (isIconButton === undefined) {
    Children.map(props.children, (child: ReactNode) => {
      if (typeof child === 'object' && child !== null) {
        const $child = child as ReactElement
        isIconButton = !!$child.props?.icon
      }
    })
  }

  if (isIconButton) {
    const { children, size = 'medium' } = props
    return (
      <StyledIconButton
        TouchRippleProps={square ? { center: false } : undefined}
        color={color}
        size={size}
        $square={square}
        {...props}
        to={to}
      >
        {children}
      </StyledIconButton>
    )
  }

  const paletteColor = theme.palette[color as keyof Palette] as PaletteColor
  return (
    <StyledButtonComponent
      $contrastText={paletteColor?.contrastText}
      $square={square}
      className={className}
      {...props}
      loading={loading}
      disableElevation
      to={to}
    />
  )
}
