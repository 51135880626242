import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MuiAlert from '@mui/material/Alert'
import MuiAlertTitle, { AlertTitleProps as MuiAlertTitleProps } from '@mui/material/AlertTitle'
import React, { useMemo } from 'react'
import tw from 'twin.macro'

import { IconButton } from '../icon-button/icon-button'
import { severityOutlinedIcons, severitySolidIcons } from './alert.helpers'
import { AlertProps } from './alert.types'

const SEVERITY_COLORS = {
  error: tw`!border-accent-two-800`,
  info: tw`!(border-b-primary-800 bg-primary-50 text-primary-900)`,
  success: tw`!border-accent-three-800`,
  warning: tw`!border-accent-five-800`,
}

export const Alert = (props: AlertProps) => {
  const {
    variant = 'standard',
    severity = 'success',
    onClose = undefined,
    action = undefined,
  } = props

  const alertIcon =
    variant === 'standard' ? severitySolidIcons(severity) : severityOutlinedIcons(severity)

  const iconAction = useMemo(() => {
    if (action) {
      return action
    }
    if (onClose) {
      return (
        <IconButton size="small" color="inherit" onClick={onClose}>
          <FontAwesomeIcon icon={solid('close')} />
        </IconButton>
      )
    }
    return null
  }, [action, onClose])

  return (
    <MuiAlert
      {...props}
      icon={alertIcon}
      css={[SEVERITY_COLORS[severity], variant === 'standard' && tw`border`]}
      tw="[& .MuiAlert-message]:text-sm"
      action={iconAction}
    />
  )
}

export const AlertTitle = (props: MuiAlertTitleProps) => {
  return <MuiAlertTitle tw="!(font-bold text-base)" {...props} />
}
