import { configureStore } from '@reduxjs/toolkit'

import { alertPropsSlice } from './reducers/alertPropsSlice'
import { dashboardSlice } from './reducers/dashboardSlice'
import dataConnectorsSlice from './reducers/dataConnectorsSlice'
import emailReportsSlice from './reducers/emailReportsSlice'
import { tokenSlice } from './reducers/tokenSlice'
// eslint-disable-next-line import/no-cycle
import { dsApi } from './services/dsApiSlice'

export const reducer = {
  alertPropsSlice: alertPropsSlice.reducer,
  dataConnectors: dataConnectorsSlice,
  emailReportsSlice,
  tokenSlice: tokenSlice.reducer,
  dashboardSlice: dashboardSlice.reducer,
  [dsApi.reducerPath]: dsApi.reducer,
}

export const middleware = (getDefaultMiddleware) => getDefaultMiddleware().concat(dsApi.middleware)

export const store = configureStore({
  reducer,
  middleware,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
