import { faArchive } from '@fortawesome/pro-solid-svg-icons/faArchive'
import { faBullhorn } from '@fortawesome/pro-solid-svg-icons/faBullhorn'
import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog'
import { faFileText } from '@fortawesome/pro-solid-svg-icons/faFileText'
import { faShareAlt } from '@fortawesome/pro-solid-svg-icons/faShareAlt'
import { faShoppingCart } from '@fortawesome/pro-solid-svg-icons/faShoppingCart'
import { faSmile } from '@fortawesome/pro-solid-svg-icons/faSmile'
import { faStore } from '@fortawesome/pro-solid-svg-icons/faStore'
import { faTable } from '@fortawesome/pro-solid-svg-icons/faTable'
import { faTag } from '@fortawesome/pro-solid-svg-icons/faTag'
import { faThLarge } from '@fortawesome/pro-solid-svg-icons/faThLarge'
import { faTruck } from '@fortawesome/pro-solid-svg-icons/faTruck'
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers'

export const icons = {
  faThLarge,
  faShareAlt,
  faBullhorn,
  faTag,
  faShoppingCart,
  faTruck,
  faUsers,
  faSmile,
  faTable,
  faCog,
  faFileText,
  faArchive,
  faStore,
}
