import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'

export const ToolsContext = createContext<IToolsContext | null>(null)
export interface IToolsContext {
  value: string
  url: object
  setUrl: Dispatch<SetStateAction<object>>
  setValue: Dispatch<SetStateAction<string>>
}

export const useToolsContext = () => {
  const context = useContext(ToolsContext)
  if (!context) throw new Error('ToolsContext must be used with ToolsProvider!')
  return context
}

export const ToolsProvider = ({ children }: { children: ReactNode }) => {
  const [value, setValue] = useState('/free-tools/amazon-url-builder')
  const [url, setUrl] = useState({})

  const state = useMemo(
    () => ({
      value,
      url,
      setValue,
      setUrl,
    }),
    [url, value],
  )
  return <ToolsContext.Provider value={state}>{children}</ToolsContext.Provider>
}
