import { makeVar } from '@apollo/client'
import { ActiveBusiness, ActiveOrganization } from '@brand-console/types'

// Reactive variables.
export const activeBizVar = makeVar<ActiveBusiness>(undefined)
export const activeOrgVar = makeVar<ActiveOrganization>(undefined)
export const routedBizVar = makeVar<ActiveBusiness>(null)

// Reactive variables for auth callback
export const callbackRedirectUriVar = makeVar<string>('')
export const callbackParamsVar = makeVar<{ [key: string]: any }>({})
