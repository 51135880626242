// icons
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// embla
import useEmblaCarousel from 'embla-carousel-react'
import React, {
  Children,
  createContext,
  FC,
  ReactChild,
  ReactElement,
  ReactFragment,
  ReactPortal,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useBreakpoint } from '../../framework/hooks/useBreakpoint'
// components
import {
  StyledArrow,
  StyledCarousel,
  StyledCarouselContainer,
  StyledCarouselViewPort,
} from './carousel.styles'
// types
import { CarouselProps } from './carousel.types'

export const CarouselContext = createContext({ slidesToScroll: 1, variableWidth: 0 })
export const Carousel: FC<CarouselProps & Record<string, unknown>> = (
  props: CarouselProps,
): ReactElement | null => {
  const { children, loop = false, slidesToScroll = 1, variableWidth = 0 } = props
  const options: { [key: string]: any } = {
    loop,
    skipSnaps: true,
    slidesToScroll,
    containScroll: 'keepSnaps',
    startIndex: loop ? 1 : 0,
  }
  if (variableWidth) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    options['dragFree'] = true
  }
  const pages: (ReactChild | ReactFragment | ReactPortal)[] = useMemo(() => {
    if (Children.count(children) <= 2 && loop) {
      return React.Children.toArray(children).concat(children)
    }
    return React.Children.toArray(children)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children])

  const [viewportRef, embla] = useEmblaCarousel(options)
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const isLarge = useBreakpoint('lg')

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])
  const onSelect = useCallback(() => {
    if (!embla) return
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
  }, [embla])

  useEffect(() => {
    if (!embla) return
    embla.on('select', onSelect)
    onSelect()
  }, [embla, onSelect])

  const carouselValue = useMemo(() => {
    return { slidesToScroll, variableWidth }
  }, [slidesToScroll, variableWidth])

  return (
    <CarouselContext.Provider value={carouselValue}>
      <StyledCarousel>
        <StyledCarouselViewPort ref={viewportRef}>
          <StyledCarouselContainer slidesToScroll={slidesToScroll}>{pages}</StyledCarouselContainer>
        </StyledCarouselViewPort>
        {prevBtnEnabled && isLarge && (
          <StyledArrow tw="-left-4">
            <FontAwesomeIcon onClick={scrollPrev} icon={solid('chevron-left')} />
          </StyledArrow>
        )}
        {nextBtnEnabled && isLarge && (
          <StyledArrow tw="-right-4">
            <FontAwesomeIcon onClick={scrollNext} icon={solid('chevron-right')} />
          </StyledArrow>
        )}
      </StyledCarousel>
    </CarouselContext.Provider>
  )
}
