import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import React, { FC, MouseEventHandler } from 'react'
import { StyleSheetManager } from 'styled-components'

import {
  StyledChip,
  StyledCloseIcon,
  StyledDismiss,
  StyledDismissButton,
  StyledDismissButtonSrOnly,
  StyledDismissWrapper,
  StyledEmphasisText,
} from './deprecated-chip.styles'
import { DeprecatedChipProps } from './deprecated-chip.types'

export const DeprecatedChip: FC<DeprecatedChipProps & Record<string, unknown>> = ({
  className,
  onClose,
  renderRoot,
  showDismiss = true,
  size = 'medium',
  text,
  emphasisText,
}: DeprecatedChipProps) => (
  <StyleSheetManager target={renderRoot}>
    <StyledChip data-testid="chip" size={size} text={text} className={className}>
      {text}
      {emphasisText && <StyledEmphasisText>&nbsp;{emphasisText}</StyledEmphasisText>}
      {showDismiss && (
        <StyledDismissWrapper>
          <StyledDismiss>
            <StyledDismissButton
              type="button"
              onClick={onClose as MouseEventHandler<HTMLButtonElement>}
            >
              <StyledDismissButtonSrOnly>Dismiss</StyledDismissButtonSrOnly>
              <StyledCloseIcon icon={solid('xmark')} aria-hidden="true" />
            </StyledDismissButton>
          </StyledDismiss>
        </StyledDismissWrapper>
      )}
    </StyledChip>
  </StyleSheetManager>
)

export default DeprecatedChip
