import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import tw, { css, styled } from 'twin.macro'

import { DeprecatedCheckbox } from '../../deprecated-checkbox/deprecated-checkbox'
import { Tooltip } from '../../tooltip/tooltip'
import { DropdownItemProps } from './dropdown-item.types'

export const StyledDropdownItem = styled.li(({ isHighlighted, selected }: DropdownItemProps) => [
  tw`relative m-1 flex cursor-pointer select-none rounded text-xs text-monochrome-700`,
  selected && tw`bg-primary-100`,
  !selected && tw`hocus:bg-monochrome-50`,
  isHighlighted && tw`bg-monochrome-50`,
])

export const StyledOptionText = styled.span(({ selected }: DropdownItemProps) => [
  tw`w-full overflow-hidden overflow-ellipsis py-2 pl-4 pr-9 text-sm`,
  selected && tw`bg-primary-100`,
])

export const StyledCheckbox = styled(DeprecatedCheckbox)`
  ${tw`w-full px-4 py-3`}
  ${css`
    & > span {
      ${tw`left-3`}
    }
  `}
`

export const StyledTooltip = styled(Tooltip)`
  ${tw`relative mt-2 -ml-6 w-0 bg-transparent`}
`
export const StyledDropdownItemInfoIcon = styled(FontAwesomeIcon)`
  ${tw`my-auto text-sm`}
`
