/*
  This route is responsible for the following:
  - Check if user is authenticated
    - If not, store the previous path in local storage and redirect to login
    - If so, read the previous path from local storage and redirect to it
  - During this process, it will show a loading screen
*/
import { useReactiveVar } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { callbackParamsVar, callbackRedirectUriVar } from '@brand-console/generated-graphql-hooks'
import { Spinner } from '@cart/ui'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { CallbackError } from './CallbackError'

const DEFAULT_REDIRECT_URI = `${window.location.origin}/callback`

export const AuthCallbackRoute = () => {
  const { isAuthenticated, isLoading, loginWithRedirect, error } = useAuth0()
  const [errorMessage, setErrorMessage] = useState<string>()
  const navigate = useNavigate()

  const callbackRedirectUri = useReactiveVar(callbackRedirectUriVar)
  const callbackParams = useReactiveVar(callbackParamsVar)

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (error) {
      setErrorMessage(error.message)
      return
    }

    if (!isAuthenticated) {
      if (
        // this will not work on localhost as the referrer is dropped from https to http
        (!document.referrer || document.referrer.startsWith(window.location.origin)) &&
        window.location.search.includes('nav=mcm')
      ) {
        window.location.href = `${process.env.NX_MCM_ORIGIN}/login`
        return
      }

      sessionStorage.setItem('callbackRedirectUri', callbackRedirectUri)
      sessionStorage.setItem('callbackParams', JSON.stringify(callbackParams))

      loginWithRedirect({
        authorizationParams: {
          ...callbackParams,
          redirectUri: DEFAULT_REDIRECT_URI,
          audience: process.env.NX_AUTH0_AUDIENCE,
        },
      })

      return
    }

    const redirectUri = sessionStorage.getItem('callbackRedirectUri') || callbackRedirectUri
    const params = JSON.parse(sessionStorage.getItem('callbackParams')) || callbackParams || {}
    const hstc = sessionStorage.getItem('hstc')

    sessionStorage.removeItem('callbackRedirectUri')
    sessionStorage.removeItem('callbackParams')
    sessionStorage.removeItem('hstc')

    /*
      HSTC is a HubSpot cookie that is set when a user visits console.cart.com.
      The idea is to track source therefore we need to make sure we send the user back to this hstc
      after login/signup.

      Note: The general callback setup should have automatically handled this however,
            there is a re-render issue with pre-app component where auth0 isAuthenticated variable
            caused the pre-app to render twice and during the second render, the hstc cookie from url is lost.
            We can likely fix it and remove this code in the future.
    */
    if (hstc) {
      navigate(`/${hstc}`, { replace: true })
      return
    }

    if (!redirectUri) {
      navigate('/')
      return
    }

    navigate(redirectUri, { replace: true, state: params })
  }, [
    callbackParams,
    callbackRedirectUri,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    navigate,
    error,
  ])

  return errorMessage ? <CallbackError errorMessage={errorMessage} /> : <Spinner />
}
