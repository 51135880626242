import React, { ReactElement, useEffect } from 'react'
import TagManager from 'react-gtm-module'

import { SettingSection } from '../shared'
import { settingItems } from './SettingsStorefront.constants'

export const SettingsStorefront = (): ReactElement => {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        route: 'SettingsOnlineStore',
      },
    })
  }, [])

  return (
    <main tw="m-auto max-w-5xl px-3 sm:px-20">
      <h2 tw="mb-9">Storefront</h2>
      {settingItems.map(({ description, cardTitle, cardSubtitle, cardContent }) => (
        <SettingSection
          description={description}
          cardTitle={cardTitle}
          cardContent={cardContent}
          cardSubtitle={cardSubtitle}
        />
      ))}
    </main>
  )
}
