import { nanoid } from 'nanoid'
import React, { ReactElement } from 'react'

export const MarketplaceServicesIcon = ({ className }: { className?: string }): ReactElement => {
  const title = 'Marketplace Services'
  const idA = nanoid()
  const idB = nanoid()
  return (
    <svg
      aria-label={title}
      className={className}
      fill="none"
      role="img"
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${idB})`}>
        <path
          d="M45.4861 50.849L59.3478 15.2004C61.388 10.2542 58.4476 5.18744 53.227 4.52393L16.2625 0C12.362 0 9.18164 3.19691 9.18164 7.11765C9.18164 8.35419 9.51168 9.59074 10.1418 10.6765L33.2146 50.849C35.1648 54.257 39.5153 55.4332 42.9058 53.4427C43.9559 52.8395 44.856 51.9347 45.4861 50.849Z"
          fill="#DA7009"
        />
        <path
          d="M42.6961 14.3558C35.0451 9.86208 27.3342 5.42862 19.6233 0.995171C17.643 -0.150892 15.5128 -0.331849 13.4125 0.663416L12.9025 0.904692L12.7224 0.995171C10.4121 2.32219 9.09199 4.28256 9.00198 6.96676C8.91197 9.34937 8.97197 11.7621 8.97197 14.1447C8.97197 16.6178 8.97197 19.0607 8.94197 21.5338C8.94197 23.5244 8.94197 25.4847 8.94197 27.4753C8.94197 29.4658 8.97197 31.5166 8.94197 33.5373C8.91196 36.4628 10.0821 38.7248 12.6324 40.2026C20.2533 44.636 27.8743 49.0393 35.4952 53.4426C37.1154 54.3776 39.0356 54.649 40.8659 54.2268C41.3159 54.0157 41.766 53.8045 42.216 53.5934L42.6961 53.322C44.8563 52.0251 46.0865 50.0648 46.1765 47.5314C46.2665 44.8773 46.2065 42.1931 46.2065 39.5391C46.2065 36.2517 46.2065 32.9944 46.2065 29.7071C46.2065 26.7816 46.1765 23.8561 46.2065 20.9005C46.2365 18.0353 45.1264 15.8035 42.6961 14.3558Z"
          fill="#DA7009"
        />
        <path
          d="M33.7847 19.9354C26.1037 15.4417 18.3928 10.9781 10.7119 6.54461C8.70162 5.39855 6.60137 5.21759 4.50111 6.21285C4.32109 6.30333 4.17107 6.36365 3.99105 6.45413L3.81103 6.57477C1.47074 7.90179 0.150581 9.86216 0.0605695 12.5162C0.000562219 14.929 0.0305659 17.3116 0.0305659 19.7243C0.0305659 22.1974 0.0305659 24.6403 0.000562217 27.1134C0.000562217 29.104 0.000562217 31.0643 0.000562217 33.0548C0.000562217 35.0454 0.0305659 37.0962 0.000562217 39.1169C-0.0294414 42.0424 1.1407 44.3044 3.69101 45.7822C11.3119 50.2156 18.9329 54.6189 26.5538 59.0222C28.174 59.9572 30.0942 60.2286 31.9245 59.8064C32.3745 59.5952 32.8246 59.3841 33.2746 59.173L33.7547 58.9016C35.9149 57.6047 37.1451 55.6443 37.2351 53.1109C37.3251 50.4569 37.2651 47.8029 37.2651 45.1187C37.2651 41.8313 37.2651 38.574 37.2651 35.2867C37.2651 32.3612 37.2351 29.4357 37.2651 26.4801C37.2951 23.6149 36.215 21.3529 33.7847 19.9354Z"
          fill="#069AE3"
        />
        <path
          d="M33.785 19.9354C26.1041 15.4416 18.3931 10.978 10.7122 6.54456C10.2021 6.24297 9.69206 6.03185 9.12199 5.85089C9.06198 6.21281 9.03198 6.57472 9.00198 6.96679C8.91197 9.3494 8.97197 11.7622 8.97197 14.1448C8.97197 16.6178 8.97197 19.0608 8.94197 21.5339C8.94197 23.5244 8.94197 25.4848 8.94197 27.4753C8.94197 29.4658 8.97197 31.5167 8.94197 33.5374C8.91196 36.4628 10.0821 38.7248 12.6324 40.2026C20.2533 44.6361 27.8743 49.0394 35.4952 53.4427C36.0053 53.7443 36.5453 53.9554 37.1154 54.1363C37.1754 53.7744 37.2054 53.4427 37.2354 53.0807C37.3254 50.4267 37.2654 47.7727 37.2654 45.0885C37.2654 41.8011 37.2654 38.5438 37.2654 35.2564C37.2654 32.331 37.2354 29.4055 37.2654 26.4499C37.2954 23.6149 36.2153 21.3529 33.785 19.9354Z"
          fill={`url(#${idA})`}
        />
      </g>
      <defs>
        <linearGradient
          id={idA}
          x1="36.9427"
          y1="54.0688"
          x2="9.13216"
          y2="6.16099"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#005982" />
          <stop offset="1" stopColor="#069AE3" />
        </linearGradient>
        <clipPath id={idB}>
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
