import { Input } from '@cart/ui/atoms/Input'
import { FC } from 'react'

import { quantityPattern, requiredRule } from '../helpers'

export interface QuantityInputProps {
  id?: string
  label?: string
  index?: number
  required?: boolean
}
export const QuantityInput: FC<QuantityInputProps> = ({
  id = 'quantity',
  label = 'Quantity',
  index,
  required = true,
}) => (
  <Input
    id={`${id}${index || ''}`}
    label={`${label} ${index || ''}`}
    placeholder={`${label} ${index || ''}`}
    helperText={required && 'Required'}
    defaultValue={1}
    inputProps={{ min: 1 }}
    type="number"
    rules={{ ...(required ? requiredRule : []), ...quantityPattern }}
  />
)
