import { MerchantTools } from '@cartdotcom/merchant-tools'
import Box from '@mui/material/Box'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

export const FreeToolsRoutes = () => {
  return (
    <Box tw="mx-auto mt-8 max-w-3xl sm:mt-18">
      <Routes>
        <Route path="amazon-url-builder" element={<MerchantTools />} />
        <Route path="*" element={<MerchantTools />} />
        <Route path="/" element={<Navigate to="amazon-url-builder" replace />} />
      </Routes>
    </Box>
  )
}
