import { Input } from '@cart/ui'
import React from 'react'

import { handleDate, handleErrorMsg } from '../../../../../helpers/functions'

const startDate = new Date()
startDate.setDate(startDate.getDate() - 90)
startDate.toJSON().slice(0, 10)
const day = startDate.getDate()
const month = startDate.getMonth() + 1
let twoDigitMonth = month.toString()
if (month < 10) {
  twoDigitMonth = `0${month}`
}
let twoDigitDay = day.toString()
if (day < 10) {
  twoDigitDay = `0${day}`
}

const year = startDate.getFullYear()
const oldestStartDate = `${year}-${twoDigitMonth}-${twoDigitDay}`

export const handleInputType = (packageTier, name, placeholder, label, type, setValue) => {
  switch (name) {
    case 'accountId':
      return (
        <Input
          key={name}
          id={name}
          size="small"
          label={label}
          placeholder={placeholder}
          name={name}
          type={type}
          rules={{
            required: handleErrorMsg(name),
            maxLength: {
              message: 'Please enter 30 characters or less',
              value: 30,
            },
            minLength: {
              message: 'Please enter at least 5 characters',
              value: 5,
            },
          }}
        />
      )
    case 'activationCode':
      return (
        <Input
          key={name}
          id={name}
          size="small"
          label={label}
          placeholder={placeholder}
          name={name}
          type={type}
          rules={{
            required: handleErrorMsg(name),
          }}
        />
      )

    case 'customerId':
      return (
        <Input
          key={name}
          id={name}
          size="small"
          label={label}
          placeholder={placeholder}
          name={name}
          type={type}
          rules={{
            required: handleErrorMsg(name),
            maxLength: {
              message: 'Please enter 19 characters or less',
              value: 19,
            },
            minLength: {
              message: 'Please enter at least 5 characters',
              value: 5,
            },
          }}
        />
      )
    case 'loginCustomerId':
      return (
        <Input
          key={name}
          id={name}
          size="small"
          label={label}
          placeholder={placeholder}
          name={name}
          type={type}
        />
      )
    case 'viewId':
      return (
        <Input
          key={name}
          id={name}
          size="small"
          label={label}
          placeholder={placeholder}
          name={name}
          type={type}
          rules={{
            required: handleErrorMsg(name),
            maxLength: {
              message: 'Please enter 15 characters or less',
              value: 15,
            },
            minLength: {
              message: 'Please enter at least 5 characters',
              value: 5,
            },
          }}
        />
      )
    case 'propertyId':
      return (
        <Input
          key={name}
          id={name}
          size="small"
          label={label}
          placeholder={placeholder}
          name={name}
          type={type}
          rules={{
            required: handleErrorMsg(name),
            maxLength: {
              message: 'Please enter 15 characters or less',
              value: 15,
            },
            minLength: {
              message: 'Please enter at least 5 characters',
              value: 5,
            },
          }}
        />
      )
    case 'shop':
      return (
        <Input
          key={name}
          id={name}
          size="small"
          label={label}
          placeholder={placeholder}
          name={name}
          type={type}
          rules={{
            required: handleErrorMsg(name),
          }}
        />
      )
    case 'siteUrls':
      return (
        <Input
          key={name}
          id={name}
          size="small"
          label={label}
          placeholder={placeholder}
          name={name}
          type={type}
          rules={{
            required: handleErrorMsg(name),
            pattern: {
              message: 'This does not look like a valid URL.',
              value: /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/i,
            },
          }}
        />
      )
    case 'startDate':
      return (
        <Input
          key={name}
          defaultValue={oldestStartDate}
          disabled={packageTier === 'FreeTrial'}
          helperText={
            packageTier === 'FreeTrial' &&
            'During the free trial period, this data source is restricted to the last 90 days of data.'
          }
          id={name}
          size="small"
          label={label}
          placeholder={placeholder}
          name={name}
          type={type}
          onChange={(e) => handleDate(e.target.value, name, setValue)}
          rules={{
            required: handleErrorMsg(name),
            pattern: {
              message: 'Please enter correct start date in form yyyy-mm-dd',
              value: /^\d{4}-\d{2}-\d{2}$/,
            },
          }}
        />
      )
    default:
      return (
        <Input
          key={name}
          id={name}
          size="small"
          label={label}
          placeholder={placeholder}
          name={name}
          type={type}
          rules={{
            required: handleErrorMsg(name),
          }}
        />
      )
  }
}
