import { nanoid } from 'nanoid'
import React, { ReactElement } from 'react'

export const StorefrontIcon = ({ className }: { className?: string }): ReactElement => {
  const title = 'Storefront Icon'
  const idA = nanoid()
  const idB = nanoid()
  return (
    <svg
      aria-label={title}
      className={className}
      fill="none"
      role="img"
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${idB})`}>
        <path
          d="M18.4838 32.1086L0.101562 35.4744C0.292381 36.5844 0.754972 37.6403 1.47199 38.5283L16.9572 57.6736C18.397 59.4555 20.6464 60.3075 22.8553 59.8995L46.5746 55.5616C47.6791 55.3577 48.691 54.8597 49.5178 54.1397L37.5194 39.3023C32.8704 33.5485 25.6135 30.8066 18.4838 32.1086Z"
          fill={`url(#${idA})`}
        />
        <path
          d="M58.5337 21.4769L43.0485 2.33166C42.3315 1.4377 41.4063 0.783723 40.3886 0.393738L34.0048 18.597C31.53 25.6528 32.8715 33.5425 37.5205 39.2962L49.5248 54.1397C50.3574 53.4197 51.005 52.4717 51.3867 51.3798L59.6208 27.8907C60.3899 25.7068 59.9735 23.2589 58.5337 21.4769Z"
          fill="#DA7009"
        />
        <path
          d="M40.3878 0.39378C39.3701 0.00979505 38.2541 -0.104201 37.1497 0.0997916L13.4304 4.44362C11.2215 4.84561 9.38846 6.44155 8.62519 8.63146L0.3795 32.1146C-0.00213729 33.2065 -0.0946555 34.3645 0.0961633 35.4744L18.4842 32.1086C25.6138 30.8066 31.5292 25.6528 34.0041 18.5971L40.3878 0.39378Z"
          fill="#069AE3"
        />
      </g>
      <defs>
        <linearGradient
          id={idA}
          x1="20.16"
          y1="29.6846"
          x2="30.6947"
          y2="61.7057"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1225" stopColor="#005982" />
          <stop offset="0.9787" stopColor="#069AE3" />
        </linearGradient>
        <clipPath id={idB}>
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
