import { Radio } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'

import { useRadioGroupContext } from './radio-group.store'
import { RadioItemProps } from './radio-group.types'

/** Meant to be used inside of Radio Group */
export const RadioItem = ({ radioProps, id, ...props }: RadioItemProps) => {
  const field = useRadioGroupContext()
  return <FormControlLabel control={<Radio id={id} {...field} {...radioProps} />} {...props} />
}
