import { RadioGroup as MuiRadioGroup } from '@mui/material'
import React, { FC } from 'react'
import { useController } from 'react-hook-form'

import formMethods from '../../framework/forms/form-methods'
import { RadioGroupContext } from './radio-group.store'
import { RadioGroupProps } from './radio-group.types'

export const RadioGroup: FC<RadioGroupProps> = ({
  children,
  defaultValue,
  methods,
  id,
  rules,
  ...props
}) => {
  const defaultMethods = formMethods()
  const { register, control } = methods || defaultMethods

  const registerField = register(id, rules)

  const {
    field: { onChange },
  } = useController({
    ...((defaultValue && { defaultValue }) as any),
    control,
    name: id,
    rules,
  })

  const onChangeEvent = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (props.onChange) props.onChange(e, value)
    onChange(e, value)
  }

  return (
    <MuiRadioGroup
      {...registerField}
      {...props}
      onChange={onChangeEvent}
      defaultValue={defaultValue}
    >
      <RadioGroupContext.Provider value={registerField}>{children}</RadioGroupContext.Provider>
    </MuiRadioGroup>
  )
}
