import { Input } from '@cart/ui'
import React from 'react'

import { requiredRule } from '../helpers'
import { Form } from './Form'

const Description = () => (
  <>
    Create an Amazon search results page filtered by brand and keyword.
    <br />
    Brand is case sensitive.
  </>
)
export const TwoStepViaBrand = () => {
  const generateUrl = (data) => {
    return `https://www.amazon.com/s/?url=search-alias%3Daps&field-keywords=${data.keywords}&field-brand=${data.brand}`
  }

  return (
    <Form
      title="2 Step via Brand"
      description={<Description />}
      example="Example: https://www.amazon.com/s/ref=nb_sb_noss_2?url=search-alias%3Daps&field-keywords=Milk&field-brand=NESTLE"
      handleGenerateLink={generateUrl}
    >
      <Input
        id="keywords"
        label="Keywords"
        placeholder="Keywords"
        helperText="Required"
        rules={{ ...requiredRule }}
      />
      <Input
        id="brand"
        label="Brand"
        placeholder="Brand"
        helperText="Required"
        rules={{ ...requiredRule }}
      />
    </Form>
  )
}
