import { steps } from './FeedMarketingTrialRoute.data'
import {
  IDataFeedWatchPricingPlanDetails,
  IPriceTable,
  Plans,
} from './FeedMarketingTrialRoute.types'

export const getClosestStep = (value) => {
  let closest = steps[0]
  steps.forEach((step) => {
    if (value >= step) {
      closest = step
    }
  })
  return closest
}

export const rangeLabelTemplate = (step: string) => {
  const value: string | number = steps[+step]
  const valueAsNumber = parseInt(value as string, 10)
  switch (true) {
    case value === 'ABOVE':
      return `> 100k SKUs`
    case valueAsNumber >= 1000 && valueAsNumber <= 100000:
      return `${Math.round((value as number) / 1000)}k SKUs`
    case valueAsNumber < 1000:
      return `< 1k SKUs`
    default:
      return value?.toString()
  }
}

export const getActivePlanMinSKUs = (activePlan: Plans, priceTable: IPriceTable) => {
  let minSKUs
  const map = new Map(Object.entries(priceTable))
  map.forEach((value, key) => {
    if (!minSKUs && value.plan === activePlan) {
      minSKUs = key
    }
  })
  return minSKUs
}

export const getActivePlanPricePoint = (activePlan: Plans, priceTable: IPriceTable) => {
  let currentStep
  const map = new Map(Object.entries(priceTable))
  map.forEach((value, key) => {
    if (!currentStep && value.plan === activePlan) {
      currentStep = key
    }
  })
  // eslint-disable-next-line eqeqeq
  return steps.findIndex((step) => step == currentStep)
}

export const getTierPrice = (
  numberOfProducts,
  monthlyPlanData: IDataFeedWatchPricingPlanDetails,
  type: 'monthly' | 'yearly',
) => {
  const {
    additional_extra_products_price: additionalPrice,
    base_price: basePrice,
    base_products: baseProducts,
  } = monthlyPlanData
  if (typeof basePrice === 'string' || typeof baseProducts === 'string') return basePrice
  if (numberOfProducts === baseProducts) {
    return type === 'monthly' ? basePrice : parseInt(((basePrice as number) / 12).toFixed(), 10)
  }
  const total = (basePrice as number) + ((numberOfProducts - baseProducts) * additionalPrice) / 1000
  const totalY =
    (basePrice as number) / 12 + ((numberOfProducts - baseProducts) * additionalPrice) / 1000
  return type === 'monthly' ? total : Math.round(totalY)
}
