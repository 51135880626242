import { useAuth0 } from '@auth0/auth0-react'
import { logger } from '@brand-console/utilities'
import { useCartAuth, useCurrentContext } from '@cartdotcom/auth'

export enum StorefrontErrorTypes {
  STORE_NAME_TAKEN,
  STORE_NAME_FORBIDDEN,
  EMAIL_ALREADY_ASSOCIATED,
  UNKNOWN,
}
export const useStorefrontApi = () => {
  const { getAccessTokenSilently } = useAuth0()
  const { auth0Id, email, fullName } = useCartAuth()
  const { currentBusiness } = useCurrentContext()
  const log = logger.setLogger(logger.LoggerNames.STOREFRONT)

  const headers = async () => {
    return {
      'Content-Type': 'application/json',
      'x-cartid-businessid': currentBusiness.id.toString(),
      'x-cartid-email': email,
      'x-cartid-sub': auth0Id,
      Accept: 'application/json',
      Authorization: `Bearer ${await getAccessTokenSilently()}`,
    }
  }

  const processErrors = (errors) => {
    // TODO: This error handling logic needs to be refactored.
    errors.forEach((element: { code: number; message: string }) => {
      let statusCode = ''
      const statusCodeMatchArray = element.message.match(/\s\d+(\\r\\n)/g)
      if (statusCodeMatchArray !== null) {
        statusCode = statusCodeMatchArray[0].trim()
      }
      if (element.code === 0 && statusCodeMatchArray === null) {
        throw new Error(`${element.message} (ERR#${element.code})`)
      }
      if (element.code === 1001) {
        throw new Error(
          `Your email, ${email}, is already associated to an online store account. Soon you will be able to attach that account here as well, but until then, please log out and create a CartID with a different email address.  (ERR#${element.code})`,
        )
      } else if (element.code === 0 && statusCode === '1027') {
        throw new Error(
          `Your email, ${email}, is already associated as a user of an online store account. Soon you will be able to attach that account here as well. (ERR#${element.code})`,
        )
      } else if (element.code === 1000) {
        throw new Error(`An unknown server error has occurred. (ERR#${element.code})`)
      }
    })
    throw new Error(errors.map((element: { message: string }) => element.message).join('\n'))
  }

  const callProvisioningAPI = async (storeName: string) => {
    try {
      const provisionResponse = await fetch(`${process.env.NX_PROVISION_TRIAL_API_URL}`, {
        method: 'POST',
        headers: await headers(),
        body: JSON.stringify({
          email,
          siteName: storeName,
          name: fullName,
          trialType: 'CartDotCom',
          ipAddress: '127.0.0.1',
        }),
      })

      let provisionResponseJSON

      if (provisionResponse.ok) {
        provisionResponseJSON = await provisionResponse.json()
      } else {
        try {
          const respStr = await provisionResponse.text()
          provisionResponseJSON = JSON.parse(respStr)
        } catch (exception) {
          throw new Error(await provisionResponse.text())
        }
      }

      if (provisionResponseJSON.success) {
        return { success: true }
      }
      processErrors(provisionResponseJSON.errors)
    } catch (error) {
      return { success: false, message: error.message }
    }

    return { success: false }
  }

  const validateStoreName = async (
    storeName: string,
  ): Promise<{
    success
    errors?:
      {
        errorMessage?: string
        code?: number
        fieldId?: string
        errorType?: StorefrontErrorTypes
      }[]
  }> => {
    try {
      // Call trialValidation API.
     const validateResponse = await fetch(`${process.env.NX_TRIAL_VALIDATE_API_URL}`, {
        method: 'POST',
        headers: await headers(),
        body: JSON.stringify({
          email,
          siteName: storeName,
        }),
      })

      const validateResponseJSON = await validateResponse.json()

      if (validateResponseJSON.success) {
        return { success: true }
      }
      const errors = validateResponseJSON.errors.map(
        (element: { code: number; message: string }) => {
          let errorMessage = `An error has occurred. ${element.message}`
          const { code } = element
          let fieldId
          const statusCodeMatchArray = element.message.match(/\s\d+(\\r\\n)/g)
          const statusCode =
            statusCodeMatchArray !== null ? statusCodeMatchArray[0].trim() : undefined
          let errorType = null

          if (element.code === 1001 || (element.code === 0 && statusCode === '1027')) {
            errorMessage = `Your email, ${email}, is already associated to an storefront account. Please contact support to merge accounts.`
            errorType = StorefrontErrorTypes.EMAIL_ALREADY_ASSOCIATED
          } else if (element.code === 1005 || element.code === 1007 || element.code === 1008) {
            fieldId = 'storeName'
            errorMessage = 'This name is already taken.'
            errorType = StorefrontErrorTypes.STORE_NAME_TAKEN
          } else if (element.code === 1004) {
            fieldId = 'storeName'
            errorMessage = "Sorry, you can't use that store name."
            errorType = StorefrontErrorTypes.STORE_NAME_FORBIDDEN
          }
          return { success: false, errorMessage, code, fieldId, errorType }
        },
      )
      log.error(
        validateResponseJSON.errors
          .map((element: { message: string }) => element.message)
          .join('\n'),
        validateResponseJSON,
      )
      return { success: false, errors }
    } catch (error) {
      log.error('Storefront Error: validateStoreNameOnServer', error)
    }
    return {
      success: false,
      errors: [
        {
          errorMessage: 'Oops! Something went wrong. Please try again later.',
          errorType: StorefrontErrorTypes.UNKNOWN,
        },
      ],
    }
  }

  return { callProvisioningAPI, validateStoreName }
}
