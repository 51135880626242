import { DialogTitle } from '@mui/material'
import tw, { css } from 'twin.macro'

import { DialogTitleProps } from './dialog-title.types'

export type StyledDialogTitleProps = DialogTitleProps & { hasChildren?: boolean }
export const StyledDialogTitle = ({ hasChildren, ...props }: StyledDialogTitleProps) => {
  const styles = css`
    ${!hasChildren && tw`justify-end`}
    &.MuiTypography-root {
      ${tw`flex items-center justify-between px-4`}
      ${tw`sm:(px-6 py-4)`}
      ${tw`font-bold text-xl leading-6 sm:leading-none`}
    }
  `
  return <DialogTitle css={styles} {...props} />
}
