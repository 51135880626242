import tw, { css, styled } from 'twin.macro'

import { DeprecatedCheckboxProps } from './deprecated-checkbox.types'

export const StyledCheckbox = styled.div(({ disabled }: DeprecatedCheckboxProps) => [
  tw`relative flex items-center leading-none`,
  disabled &&
    css`
      ${tw`cursor-not-allowed`}
      & * {
        ${tw`text-monochrome-600`}
      }
    `,
])

export const StyledCheckboxInput = styled.input(() => [
  tw`pointer-events-none absolute z-0 opacity-0`,
  css`
    height: 20px;
    width: 20px;
  `,
])

export const StyledCheckboxLabel = styled.span(({ hasSmallLabel }: DeprecatedCheckboxProps) => [
  tw`inline-block w-full select-none pl-8 text-left text-sm leading-none`,
  hasSmallLabel && tw`leading-tight`,
  css`
    & > small {
      ${tw`block text-monochrome-600`}
    }
  `,
])

export const StyledCheckboxCheckmark = styled.span(
  ({ disabled, state }: DeprecatedCheckboxProps) => [
    tw`absolute top-0 left-0 bottom-0 z-10 my-auto ml-0 h-5 w-5 rounded border-2 border-monochrome-600 border-solid bg-transparent`,
    css`
      &:after {
        ${tw`absolute m-auto block border-transparent leading-none`}
        content: '';
        inset: 0;
        top: -2px;
        width: 5px;
        height: 10px;
        border-style: solid;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    `,
    state === 'checked' &&
      css`
        ${tw`border-2 border-primary-700 bg-primary-700`}
        &:after {
          ${tw`border-white`}
        }
      `,
    state !== 'checked' && tw`border-2`,
    state === 'indeterminate' && [
      css`
        ${tw`border border-primary-700 bg-primary-700`}
        &:after {
          ${tw`absolute m-auto block border-transparent border-white leading-none`}
          content: '';
          inset: 0;
          top: 2px;
          width: 5px;
          height: 10px;
          border-style: solid;
          border-width: 0 0 0 2px;
          transform: none;
          transform: rotate(90deg);
        }
      `,
    ],
    disabled && tw`border-2 border-monochrome-600 bg-monochrome-600`,
    disabled && state === 'unchecked' && tw`bg-white`,
  ],
)

export const StyledSROnlyLabel = styled.label(() => [tw`sr-only`])
