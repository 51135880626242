import { Step, StepLabel, Stepper, StepperProps } from '@cart/ui'
import { FC } from 'react'

export type FormCompletionStepperProps = {
  secondStepName: string
  activeStep: number
} & StepperProps
export const FormCompletionStepper: FC<FormCompletionStepperProps> = ({
  secondStepName,
  activeStep,
  ...rest
}) => {
  const steps = ['Confirm your account information', secondStepName]
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Stepper activeStep={activeStep} {...rest}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
