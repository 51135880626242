/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import { NavLink, Outlet, useLocation, useNavigate, useParams, useRoutes } from 'react-router-dom'
import tw from 'twin.macro'

import { useTabsContext } from './tabs.store'
import {
  StyledTabs,
  StyledTabsBody,
  StyledTabsHeaderItem,
  StyledTabsHeaderList,
} from './tabs.styles'

export const Tabs = ({
  children,
  className,
  root,
}: {
  children: any
  className?: string
  root?: string
}) => {
  const { routes, updateRoutes } = useTabsContext()
  const location = useLocation()
  const navigate = useNavigate()
  const { id } = useParams()
  const element = useRoutes(routes, location.pathname)
  const [defaultTab, setDefaultTab] = useState<boolean>(false)

  // Update the routes when the children change (Add Nested Tabs Support)
  useEffect(() => {
    updateRoutes(root, children)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Select the default tab
  useEffect(() => {
    if (!element?.props.children && !defaultTab) {
      React.Children.toArray(children).forEach((child: any) => {
        if (
          child.type.name === 'TabItem' ||
          child.type.displayName === 'TabItem' ||
          child?.props?.originalType?.displayName === 'TabItem' ||
          child?.props?.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.name === 'TabItem' ||
          child?.props?.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.displayName === 'TabItem'
        ) {
          const { index, path } = child.props
          const rootEnd =
            root
              ?.split('/')
              ?.pop()
              ?.replace(':id', id as string) ?? ''
          if (index && !location.pathname.endsWith(path) && location.pathname.endsWith(rootEnd)) {
            setDefaultTab(true)
            navigate(path)
          }
        }
      })
    }
  }, [children, defaultTab, element, id, location.pathname, navigate, root])

  return (
    <StyledTabs data-testid="tabs" className={className}>
      <header>
        <nav>
          <StyledTabsHeaderList>
            {React.Children.toArray(children).map((child: any) => {
              if (
                child.type.name === 'TabItem' ||
                child.type.displayName === 'TabItem' ||
                child?.props?.originalType?.displayName === 'TabItem' ||
                child?.props?.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.name === 'TabItem' ||
                child?.props?.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.displayName === 'TabItem'
              ) {
                const { disabled, label, path } = child.props
                const thePath = path === '/' ? '' : path ?? ''
                return (
                  <NavLink
                    key={thePath}
                    to={thePath}
                    onClick={(event) => (disabled ? event.preventDefault() : null)}
                    css={[disabled && tw`cursor-not-allowed`]}
                  >
                    {({ isActive }) => (
                      <StyledTabsHeaderItem
                        isActive={isActive}
                        isDisabled={disabled}
                        data-testid="tab"
                      >
                        {label}
                      </StyledTabsHeaderItem>
                    )}
                  </NavLink>
                )
              }
              return null
            })}
          </StyledTabsHeaderList>
        </nav>
      </header>
      <StyledTabsBody>
        {element}
        <Outlet />
      </StyledTabsBody>
    </StyledTabs>
  )
}

Tabs.displayName = 'Tabs'
