import tw, { styled } from 'twin.macro'

import { PasswordStrengthProps } from './password-strength.types'

export const StyledContainer = styled.div(() => [tw`w-full`])
export const StyledCriteriaList = styled.ul(() => [tw`mb-6 flex flex-col gap-1 pl-5 text-sm`])

export const StyledHelperText = styled.p(({ match }: PasswordStrengthProps) => [
  match ? tw`text-accent-three-800` : tw`text-monochrome-600`,
])
