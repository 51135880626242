import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DOMPurify from 'dompurify'
import parse from 'html-react-parser'
import React, { FC, MouseEventHandler, useEffect, useState } from 'react'
import { StyleSheetManager } from 'styled-components'

import {
  StyledAlert,
  StyledAlertContainer,
  StyledCloseIcon,
  StyledDismiss,
  StyledDismissButton,
  StyledDismissButtonSrOnly,
  StyledDismissWrapper,
  StyledHeading,
  StyledIcon,
  StyledIconContainer,
  StyledMessage,
  StyledTextContainer,
} from './deprecated-alert.styles'
import { DeprecatedAlertProps } from './deprecated-alert.types'

const alertIcons = {
  info: () => <FontAwesomeIcon icon={solid('circle-info')} />,
  warning: () => <FontAwesomeIcon icon={solid('triangle-exclamation')} />,
  success: () => <FontAwesomeIcon icon={solid('circle-check')} />,
  base: () => null,
  error: () => <FontAwesomeIcon icon={solid('triangle-exclamation')} />,
}

export const DeprecatedAlert: FC<DeprecatedAlertProps & Record<string, unknown>> = ({
  allowClose = true,
  baseAlertIcon: BaseAlertIcon,
  className,
  heading,
  onClose,
  renderRoot,
  show = true,
  showIcon = true,
  size = 'medium',
  text,
  type = 'base',
}: DeprecatedAlertProps) => {
  const [shouldShowIcon, setShouldShowIcon] = useState(showIcon && type !== 'base')
  let IconElement = alertIcons[type] as any

  if (type === 'base' && BaseAlertIcon) {
    IconElement = () => BaseAlertIcon
  }

  useEffect(() => {
    if (type === 'base' && BaseAlertIcon) {
      setShouldShowIcon(true)
    }
  }, [BaseAlertIcon, type])

  if (!show) {
    return null
  }

  const html = DOMPurify.sanitize(text, { ALLOWED_TAGS: ['a', 'b', 'br', 'i'] })

  return (
    <StyleSheetManager target={renderRoot}>
      <StyledAlert type={type} className={className} data-testid="alert" size={size}>
        <StyledAlertContainer>
          {shouldShowIcon && (
            <StyledIconContainer size={size}>
              <StyledIcon icon={solid('star')} as={IconElement} />
            </StyledIconContainer>
          )}
          <StyledTextContainer>
            {heading && (
              <StyledHeading type={type} text={html}>
                {heading}
              </StyledHeading>
            )}
            {html && (
              <StyledMessage type={type} size={size}>
                <p>{parse(html)}</p>
              </StyledMessage>
            )}
          </StyledTextContainer>
          {allowClose && (
            <StyledDismissWrapper>
              <StyledDismiss>
                <StyledDismissButton
                  type="button"
                  onClick={onClose as MouseEventHandler<HTMLButtonElement>}
                >
                  <StyledDismissButtonSrOnly>Dismiss</StyledDismissButtonSrOnly>
                  <StyledCloseIcon icon={solid('xmark')} aria-hidden="true" />
                </StyledDismissButton>
              </StyledDismiss>
            </StyledDismissWrapper>
          )}
        </StyledAlertContainer>
      </StyledAlert>
    </StyleSheetManager>
  )
}

export default DeprecatedAlert
