import { useBreakpoint } from '@cart/ui/hooks/useBreakpoint'
import React, { useEffect, useState } from 'react'
import { createGlobalStyle } from 'styled-components'
import tw from 'twin.macro'

import { LeftNavProps } from '../../left-nav.types'
import { ProductMenu } from '../ProductMenus/ProductMenu'
import { useLeftNavStore } from '../../store/left-nav.store'

const OverlayStyles = createGlobalStyle`
  Body {
    white-space: nowrap;
    overflow: hidden;
  }
`

export const LeftNav = ({ product, open = true }: LeftNavProps) => {
  const isSmall = useBreakpoint('sm')
  const isExtraLarge = useBreakpoint('xl')
  const { isCollapsed, setIsCollapsed } = useLeftNavStore()
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true)

  const handleClose = () => setIsCollapsed(true)

  useEffect(() => {
    if (!isInitialLoad) {
      if (!open && isCollapsed) {
        setIsCollapsed(false)
      } else if (open && !isCollapsed) {
        setIsCollapsed(true)
      } else {
        setIsCollapsed(!open)
      }
    } else {
      if (!isExtraLarge) {
        setIsCollapsed(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    setIsInitialLoad(false)
  }, [])

  return (
    <div tw="relative h-full">
      <ProductMenu product={product} />
      {!isSmall && !isCollapsed && (
        <>
          <OverlayStyles />
          <div
            aria-hidden="true"
            onClick={handleClose}
            css={[
              tw`[z-index: -1] absolute inset-0 w-screen transition-all`,
              tw`[z-index: 0] bg-monochrome-600/20 backdrop-blur-sm`,
            ]}
          />
        </>
      )}
    </div>
  )
}
