import Link from '@mui/material/Link'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { format } from 'date-fns'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

export const CreatedOnCell = ({ value }) => {
  if (!value) return null
  return format(new Date(value), 'MMMM d, yyyy')
}

export const NameCell = (params: GridRenderCellParams) => {
  const { row, value } = params

  return (
    <Link
      component={RouterLink}
      to={`/settings/organization/businesses/${row.id}`}
      tw="no-underline hover:underline"
    >
      {value}
    </Link>
  )
}
