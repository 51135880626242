import { useEffect, useState } from 'react'

import { isCsrCheck } from '../../gql/queries/roleCheck.query'
import { useAuthToken } from './useAuthToken'

export const useIsCsr = () => {
  const token = useAuthToken()
  const [isCsr, setIsCsr] = useState<boolean | null>(null)

  useEffect(() => {
    const getIsCsr = async () => {
      const isCsrResponse = await isCsrCheck(token)
      setIsCsr(isCsrResponse)
    }
    if (token) getIsCsr()
  }, [token])

  return isCsr
}
