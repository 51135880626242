import { logger } from '@brand-console/utilities'
import { GraphQLClient } from 'graphql-request'

const responseMiddleware = (response) => {
  if (!(response instanceof Error) && response.errors) {
    logger.error('GraphQL request error', response.errors, response)
  }
}

export const gqlClient = new GraphQLClient(process.env.NX_GRAPHQL_URL, { responseMiddleware })
