import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'

export const MenuSkeleton = () => {
  return (
    <Box tw="mt-3 hidden h-full !border-r-monochrome-200 border-r md:(block [width:60px]) xl:[width: 250px]">
      <Skeleton tw="mx-1 mb-2 bg-monochrome-200" height={50} variant="rounded" />
      <Skeleton tw="mx-1 mb-2 bg-monochrome-200" height={50} variant="rounded" />
      <Skeleton tw="mx-1 mb-2 bg-monochrome-200" height={50} variant="rounded" />
      <Skeleton tw="mx-1 mb-2 bg-monochrome-200" height={50} variant="rounded" />
      <Skeleton tw="mx-1 mb-2 bg-monochrome-200" height={50} variant="rounded" />
      <Skeleton tw="mx-1 mb-2 bg-monochrome-200" height={50} variant="rounded" />
    </Box>
  )
}
