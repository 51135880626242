import { Services } from '@brand-console/types'
import { capitalize, logger, scrollTo } from '@brand-console/utilities'
import { Breadcrumbs, Button, Range, Spinner, useBreakpoint, useRouter } from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import uniqBy from 'lodash/uniqBy'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { CellValue } from 'react-table'

import { useFeedMarketingStore } from '../../components/FeedMarketing/FeedMarketing.store'
import { compareTablePlansColumnIndex, comparisonData, steps } from './FeedMarketingTrialRoute.data'
import {
  getActivePlanMinSKUs,
  getActivePlanPricePoint,
  getTierPrice,
  rangeLabelTemplate,
} from './FeedMarketingTrialRoute.functions'
import { HeaderCell, PlanCell, SelectedPlan } from './FeedMarketingTrialRoute.partials'
import {
  StyledCard,
  StyledCardContainer,
  StyledCardContent,
  StyledCardInfo,
  StyledCardPrice,
  StyledTable,
} from './FeedMarketingTrialRoute.styles'
import {
  Currency,
  IDataFeedWatchPricingPlan,
  IDataFeedWatchPricingPlans,
  IPriceObject,
  IPriceTable,
  Plans,
  RouteState,
} from './FeedMarketingTrialRoute.types'
import { getFriendlyErrorMessage } from '@brand-console/utilities/errors'

export const FeedMarketingTrialRoute = () => {
  const log = useMemo(() => logger.setLogger(logger.LoggerNames.FEED_MARKETING), [])
  const [state, setState] = useState(RouteState.SUCCESS)
  const [error, setError] = useState<string>(null)
  const [priceTable, setPriceTable] = useState<IPriceTable>()
  const { navigate } = useRouter()
  const isSmall = useBreakpoint('sm')
  const stickyColumnWidth = isSmall ? 350 : 200
  const currency = '$'
  const [uniquePlans, setUniquePlans] = useState<IDataFeedWatchPricingPlans>()
  const { setPlanId } = useFeedMarketingStore()

  const [subscriptionType, setSubscriptionType] = useState<'monthly' | 'yearly'>('monthly')
  const subscriptionKey = useMemo(
    () => (subscriptionType === 'monthly' ? 'priceMonthContract' : 'priceYearContract'),
    [subscriptionType],
  )

  const initialShopPrice = priceTable?.[1000][subscriptionKey].USD
  const initialMerchantPrice = priceTable?.[5000][subscriptionKey].USD
  const initialAgencyPrice = priceTable?.[30000][subscriptionKey].USD
  const initialEnterprisePrice = 'Custom'

  const [showComparisonTable, setShowComparisonTable] = useState<boolean>(false)
  const [step, setStep] = useState(10)
  const [priceObject, setPriceObject] = useState<IPriceObject>() // priceTable[1000]
  const [activePlan, setActivePlan] = useState<Plans>(Plans.AGENCY)
  const [shopPrice, setShopPrice] = useState(initialShopPrice)
  const [merchantPrice, setMerchantPrice] = useState(initialMerchantPrice)
  const [agencyPrice, setAgencyPrice] = useState(initialAgencyPrice)
  const [enterprisePrice, setEnterprisePrice] = useState<undefined | number | 'Custom'>(
    initialEnterprisePrice,
  )
  const [activeColumnIndex, setActiveColumnIndex] = useState<number>(0)
  const [selectedPlan, setSelectedPlan] = useState<Plans>()
  const [showFreeTrial, setShowFreeTrial] = useState<boolean>(true)
  const tableRef = useRef<HTMLElement>(null)
  const data = useMemo(() => comparisonData, [])

  const getCurrentPrice = useCallback(
    (plan: Plans) => {
      switch (plan) {
        case Plans.SHOP:
          return shopPrice
        case Plans.MERCHANT:
          return merchantPrice
        case Plans.AGENCY:
          return agencyPrice
        case Plans.ENTERPRISE:
          return enterprisePrice
        default:
          return undefined
      }
    },
    [agencyPrice, enterprisePrice, merchantPrice, shopPrice],
  )

  useEffect(
    () => {
      if (priceObject) {
        setActiveColumnIndex(compareTablePlansColumnIndex[activePlan])
        switch (activePlan) {
          case 'Shop':
            setShopPrice(priceObject[subscriptionKey].USD)
            setMerchantPrice(initialMerchantPrice)
            setAgencyPrice(initialAgencyPrice)
            setEnterprisePrice(initialEnterprisePrice)
            break
          case 'Merchant':
            setMerchantPrice(priceObject[subscriptionKey].USD)
            setShopPrice(initialShopPrice)
            setAgencyPrice(initialAgencyPrice)
            setEnterprisePrice(initialEnterprisePrice)
            break
          case 'Agency':
            setAgencyPrice(priceObject[subscriptionKey].USD)
            setMerchantPrice(initialMerchantPrice)
            setShopPrice(initialShopPrice)
            setEnterprisePrice(initialEnterprisePrice)
            break
          case 'Enterprise':
            setEnterprisePrice(
              priceObject ? priceObject[subscriptionKey].USD : initialEnterprisePrice,
            )
            setAgencyPrice(initialAgencyPrice)
            setMerchantPrice(initialMerchantPrice)
            setShopPrice(initialShopPrice)
            break
          default:
            break
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activePlan, priceObject, subscriptionType, subscriptionKey],
  )

  useEffect(() => {
    const fetchUrl = `${process.env.NX_DFW_PRICING_API_URL}?currency=usd`
    const fetchDFWPrices = async () => {
      try {
        const response = await fetch(fetchUrl, {
          method: 'GET',
        })

        const priceData: IDataFeedWatchPricingPlans = await response.json()
        const uniquePlansData = uniqBy(
          priceData,
          (e: IDataFeedWatchPricingPlan) => e.plan_name,
        ).sort((a, b) => (a.details.base_products < b.details.base_products ? 1 : -1))
        setUniquePlans(uniquePlansData)

        const productsPerPlan = {}
        uniquePlansData.forEach((plan: IDataFeedWatchPricingPlan) => {
          productsPerPlan[plan.plan_name] = plan.details.base_products
          return true
        })

        const returnObject = {}
        const getPlanName = (numberOfProducts) => {
          if (numberOfProducts === 100000) {
            return 'Agency'
          }
          let plan
          Object.keys(productsPerPlan).forEach((entry) => {
            const planName = entry
            const planProducts = productsPerPlan[entry]
            if (numberOfProducts >= planProducts && !plan) {
              plan = planName
            }
          })
          return plan
        }

        steps.forEach((numberOfProducts) => {
          // For Custom Enterprise pricing
          if (numberOfProducts === 'ABOVE') {
            returnObject[numberOfProducts] = {
              priceMonthContract: {},
              priceYearContract: {},
              plan: Plans.ENTERPRISE,
            }
          }
          if (parseInt(numberOfProducts as string, 10) > 0) {
            const plan = getPlanName(numberOfProducts)
            const monthlyPlan = priceData.find(
              (e) => e.plan_name === plan && e.details.payment_term === 1,
            )
            const yearlyPlan = priceData.find(
              (e) => e.plan_name === plan && e.details.payment_term === 12,
            )
            returnObject[numberOfProducts] = {
              priceMonthContract: {
                [Currency.USD]: getTierPrice(numberOfProducts, monthlyPlan.details, 'monthly'),
              },
              priceYearContract: {
                [Currency.USD]: getTierPrice(numberOfProducts, yearlyPlan.details, 'yearly'),
              },
              plan,
            }
          }
        })
        setPriceTable(returnObject)
        setPriceObject(returnObject[30000])
      } catch (e) {
        log.error("Couldn't fetch DFW prices", { fetchUrl }, e)
        setState(RouteState.FAILURE)
        setError(getFriendlyErrorMessage(e))
      }
    }
    fetchDFWPrices()
  }, [log])

  const onChange = useCallback(
    (value) => {
      const closestStep = steps[value] === steps[0] ? steps[1] : steps[value]
      const priceTableObject = priceTable[closestStep]
      const currentActivePlan = Plans[priceTableObject.plan.toUpperCase()]
      unstable_batchedUpdates(() => {
        setActivePlan(currentActivePlan)
        setPriceObject(priceTableObject)
      })
    },
    [priceTable],
  )

  const actionShowComparisonTable = () => {
    setShowComparisonTable(true)
    window.setTimeout(() => {
      scrollTo('#comparison-table')
    }, 100)
  }

  const onTableClick = useCallback(
    ({ cell }) => {
      let activePlanId = capitalize(cell.id || cell.column.id)
      let minSKUs = getActivePlanMinSKUs(Plans[activePlanId.toUpperCase()], priceTable)
      if (!minSKUs) {
        minSKUs = 1000
        activePlanId = 'Shop'
      }
      const priceTableObject = priceTable[minSKUs]
      unstable_batchedUpdates(() => {
        setStep(getActivePlanPricePoint(Plans[activePlanId.toUpperCase()], priceTable))
        setActivePlan(Plans[activePlanId.toUpperCase()])
        setPriceObject(priceTableObject)
      })
    },
    [priceTable],
  )

  const startFreeTrial = (plan: Plans) => {
    setShowFreeTrial(true)
    setSelectedPlan(plan)
    const $planId = uniquePlans.find(($plan) => $plan.plan_name === plan)?.details?.id
    setPlanId($planId)
  }

  const contactUs = () => {
    navigate('/feed-marketing/contact-us')
  }

  if (state === RouteState.FAILURE) {
    return (
      <div tw="flex h-full flex-col items-center justify-center">
        <h1>Could not load page</h1>
        <p>{error}</p>
      </div>
    )
  }

  if (selectedPlan && showFreeTrial) {
    const priceDisplay = getCurrentPrice(selectedPlan)
    return (
      <SelectedPlan
        setShow={setShowFreeTrial}
        currency={currency}
        priceDisplay={priceDisplay}
        selectedPlan={selectedPlan}
      />
    )
  }

  if ((!priceTable || !priceObject) && activePlan !== Plans.ENTERPRISE) {
    return <Spinner tw="m-auto h-64 w-64" type="global" />
  }

  return (
    <div tw="mx-auto w-full max-w-screen-xl px-3.5">
      <Breadcrumbs
        tw="mb-10 mt-7 sm:-ml-2"
        items={[
          { text: 'Home', href: '/' },
          { text: Services.FEED_MARKETING, href: '/feed-marketing' },
          { text: 'Free Trial' },
        ]}
      />
      <div tw="text-center">
        <h1 tw="mb-8 font-heading font-bold">Choose a plan to start your free trial</h1>
        <p tw="mb-8 px-10 text-base leading-6 sm:text-xl">
          Your subscription can be scaled up or down at any time
        </p>
        <div tw="mb-14 flex items-center justify-center gap-7 text-center sm:mb-20">
          <Button
            size="large"
            variant={subscriptionType === 'monthly' ? 'contained' : 'outlined'}
            onClick={() => setSubscriptionType('monthly')}
          >
            Monthly
          </Button>
          or
          <Button
            size="large"
            variant={subscriptionType === 'yearly' ? 'contained' : 'outlined'}
            onClick={() => setSubscriptionType('yearly')}
          >
            Yearly
          </Button>
        </div>
        <div tw="mx-auto mb-6 max-w-2xl sm:(mb-10 mt-10)">
          <Range
            id="skus"
            max={steps.length - 1}
            labelTemplate={rangeLabelTemplate}
            min={0}
            step={1}
            value={step}
            onChange={onChange}
          />
        </div>
      </div>
      <section
        data-id="plans"
        tw="mx-auto mb-5 flex max-w-full flex-wrap sm:(mb-14 px-10) lg:(mb-18 max-w-5xl px-0)"
      >
        {((!isSmall && activePlan === 'Shop') || isSmall) && (
          <StyledCardContainer>
            <StyledCard
              elevation={2}
              data-active={activePlan === 'Shop'}
              onClick={() => {
                if (activePlan !== 'Shop') {
                  unstable_batchedUpdates(() => {
                    setStep(getActivePlanPricePoint(Plans.SHOP, priceTable))
                    setActivePlan(Plans.SHOP)
                    setPriceObject(priceTable[1000])
                  })
                }
              }}
            >
              <StyledCardContent>
                <h2 tw="mb-4">Shop</h2>
                <StyledCardPrice>
                  {typeof shopPrice === 'number' && currency}
                  {typeof shopPrice === 'number' ? shopPrice : 'Custom'}
                </StyledCardPrice>
                <StyledCardInfo>1k products included</StyledCardInfo>
                <div>
                  <Button
                    variant={activePlan === Plans.SHOP ? 'contained' : 'outlined'}
                    size="small"
                    onClick={() => startFreeTrial(Plans.SHOP)}
                  >
                    Start free trial
                  </Button>
                </div>
              </StyledCardContent>
            </StyledCard>
          </StyledCardContainer>
        )}
        {((!isSmall && activePlan === 'Merchant') || isSmall) && (
          <StyledCardContainer>
            <StyledCard
              data-active={activePlan === 'Merchant'}
              onClick={() => {
                if (activePlan !== 'Merchant') {
                  unstable_batchedUpdates(() => {
                    setActivePlan(Plans.MERCHANT)
                    setStep(getActivePlanPricePoint(Plans.MERCHANT, priceTable))
                    setPriceObject(priceTable[5000])
                  })
                }
              }}
            >
              <StyledCardContent>
                <h2 tw="mb-4">Merchant</h2>
                <StyledCardPrice>
                  {typeof merchantPrice === 'number' && currency}
                  {typeof merchantPrice === 'number' ? merchantPrice : 'Custom'}
                </StyledCardPrice>
                <StyledCardInfo>5k products included</StyledCardInfo>
                <div>
                  <Button
                    variant={activePlan === Plans.MERCHANT ? 'contained' : 'outlined'}
                    size="small"
                    onClick={() => startFreeTrial(Plans.MERCHANT)}
                  >
                    Start free trial
                  </Button>
                </div>
              </StyledCardContent>
            </StyledCard>
          </StyledCardContainer>
        )}
        {((!isSmall && activePlan === 'Agency') || isSmall) && (
          <StyledCardContainer>
            <StyledCard
              data-active={activePlan === 'Agency'}
              onClick={() => {
                if (activePlan !== 'Agency') {
                  unstable_batchedUpdates(() => {
                    setActivePlan(Plans.AGENCY)
                    setStep(getActivePlanPricePoint(Plans.AGENCY, priceTable))
                    setPriceObject(priceTable[30000])
                  })
                }
              }}
            >
              <StyledCardContent>
                <h2 tw="mb-4">Agency</h2>
                <StyledCardPrice>
                  {typeof agencyPrice === 'number' && currency}
                  {typeof agencyPrice === 'number' ? agencyPrice : 'Custom'}
                </StyledCardPrice>
                <StyledCardInfo>30k products included</StyledCardInfo>
                <div>
                  <Button
                    variant={activePlan === Plans.AGENCY ? 'contained' : 'outlined'}
                    size="small"
                    onClick={() => startFreeTrial(Plans.AGENCY)}
                  >
                    Start free trial
                  </Button>
                </div>
              </StyledCardContent>
            </StyledCard>
          </StyledCardContainer>
        )}
        {((!isSmall && activePlan === Plans.ENTERPRISE) || isSmall) && (
          <StyledCardContainer>
            <StyledCard
              data-active={activePlan === Plans.ENTERPRISE}
              onClick={() => {
                if (activePlan !== Plans.ENTERPRISE) {
                  unstable_batchedUpdates(() => {
                    setActivePlan(Plans.ENTERPRISE)
                    setStep(getActivePlanPricePoint(Plans.ENTERPRISE, priceTable))
                    setPriceObject({
                      plan: Plans.ENTERPRISE,
                      priceMonthContract: {},
                      priceYearContract: {},
                    })
                  })
                }
              }}
            >
              <StyledCardContent>
                <h2 tw="mb-4">Enterprise</h2>
                <StyledCardPrice>
                  {typeof enterprisePrice === 'number' && currency}
                  {typeof enterprisePrice === 'number' ? enterprisePrice : 'Custom'}
                </StyledCardPrice>
                <StyledCardInfo>Unlimited products included</StyledCardInfo>
                <div>
                  <Button
                    variant={activePlan === Plans.ENTERPRISE ? 'contained' : 'outlined'}
                    size="small"
                    onClick={contactUs}
                  >
                    Contact Us
                  </Button>
                </div>
              </StyledCardContent>
            </StyledCard>
          </StyledCardContainer>
        )}
      </section>
      <section data-id="comparison-table" tw="mx-auto flex max-w-full flex-wrap sm:px-14">
        <h2 tw="mb-20 flex w-full items-center justify-center text-center font-bold text-primary-700 text-sm leading-none sm:(mb-16 block text-4xl text-monochrome-800)">
          Compare Plans
          {!showComparisonTable && (
            <>
              <br tw="hidden sm:block" />
              <div
                role="button"
                onKeyDown={actionShowComparisonTable}
                tabIndex={0}
                tw="ml-1 cursor-pointer text-primary-700 leading-none sm:(ml-0 text-monochrome-600)"
                onClick={actionShowComparisonTable}
              >
                <FontAwesomeIcon icon={solid('angle-down')} />
              </div>
            </>
          )}
        </h2>
        {showComparisonTable && (
          <StyledTable
            id="comparison-table"
            innerRef={tableRef}
            data-active-column={activeColumnIndex}
            enableSorting={false}
            mobileDisplay="collapsed"
            onClick={onTableClick}
            columns={[
              {
                accessor: 'item',
                sticky: 'left',
                width: stickyColumnWidth,
              },
              {
                Header: () =>
                  HeaderCell({ activePlan, id: 'Shop', price: shopPrice, startFreeTrial }),
                accessor: 'shop',
                align: 'center',
                Cell: (e: CellValue) =>
                  PlanCell({ activePlan, id: 'Shop', startFreeTrial, value: e.cell.value }),
                width: 200,
              },
              {
                Header: () =>
                  HeaderCell({
                    activePlan,
                    id: 'Merchant',
                    price: merchantPrice,
                    startFreeTrial,
                  }),
                accessor: 'merchant',
                Cell: (e: CellValue) =>
                  PlanCell({ activePlan, id: 'Merchant', startFreeTrial, value: e.cell.value }),
                width: 200,
              },
              {
                Header: () =>
                  HeaderCell({ activePlan, id: 'Agency', price: agencyPrice, startFreeTrial }),
                accessor: 'agency',
                Cell: (e: CellValue) =>
                  PlanCell({ activePlan, id: 'Agency', startFreeTrial, value: e.cell.value }),
                width: 200,
              },
              {
                Header: () =>
                  HeaderCell({
                    activePlan,
                    id: 'Enterprise',
                    price: enterprisePrice,
                    startFreeTrial: contactUs,
                    buttonText: 'Contact Us',
                  }),
                accessor: 'enterprise',
                Cell: (e: CellValue) =>
                  PlanCell({
                    activePlan,
                    id: 'Enterprise',
                    startFreeTrial: contactUs,
                    value: e.cell.value,
                    buttonText: 'Contact Us',
                  }),
                width: 200,
              },
            ]}
            data={data}
          />
        )}
      </section>
    </div>
  )
}
