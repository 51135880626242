export enum LoggerNames {
  CUSTOMER_ENGAGEMENT = 'customer-engagement',
  DATA_SCIENCE = 'data-science',
  FEED_MARKETING = 'feed-marketing',
  FULFILLMENT = 'fulfillment',
  GLOBAL = 'global',
  GROWTH_CAPITAL = 'growth-capital',
  GROWTH_MARKETING = 'growth-marketing',
  HOME = 'home',
  MARKETING_SERVICES = 'marketing-services',
  MARKETPLACE_MANAGEMENT = 'marketplace-management',
  MARKETPLACE_SERVICES = 'marketplace-services',
  ORGANIZATION = 'organization',
  PROFILE = 'profile',
  SECURITY = 'security',
  SHIPPING_BULK_LABELS = 'shipping-bulk-labels',
  SHIPPING_SETTINGS = 'shipping-settings',
  STOREFRONT = 'storefront',
  UNIFIED_ANALYTICS = 'unified-analytics',
  BILLING = 'billing',
}

export const loggerNames = Object.values(LoggerNames)
