/* eslint-disable jsx-a11y/label-has-associated-control */
import { useAuth0 } from '@auth0/auth0-react'
import { Services } from '@brand-console/types'
import { getHubspotCookie, hubspotFormSubmit, logger } from '@brand-console/utilities'
import { useCartAuth, useCurrentContext } from '@cartdotcom/auth'
import { Breadcrumbs } from '@cart/ui/atoms/Breadcrumbs'
import { Alert } from '@cart/ui/molecules/Alert'

import React, { ReactElement, useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'

import { TrialFormProps } from '../../components/TrialForm'
import { useAccountConfirmContext } from '../../components/AccountConfirmForm'
import { MarketplaceManagementRouteDialog } from './components/MarketplaceManagementRoute.dialog'
import { MarketplaceManagementRouteForm } from './components/MarketplaceManagementRoute.form'
import { MCMFormData, MCMModalState } from './MarketplaceManagementRoute.types'
import { getErrorBody, getErrorHeader } from '@brand-console/utilities/errors'

const serviceInterestHubspotMapping = {
  full: 'Full Service',
  listing: 'Listing',
  repricing: 'Repricing',
}

// TODO: once we have metadata on selleractive being provisioned only show modal if they havent done it yet, otherwise SSO into selleractive instead
export const MarketplaceManagementRoute = (): ReactElement => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const log = logger.setLogger(logger.LoggerNames.MARKETPLACE_SERVICES)
  const { getAccessTokenSilently } = useAuth0()
  const { email, auth0Id } = useCartAuth()
  const { currentBusiness, currentOrganization } = useCurrentContext()

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        route: 'MCM',
      },
    })
  }, [])

  const [showFailureAlert, setShowFailureAlert] = useState(false)
  const [failureAlertMessage, setFailureAlertMessage] = useState('Something went wrong.')
  const [createTrialErrorMessage, setCreateTrialErrorMessage] = useState('Something went wrong.')
  const [errorScope, setErrorScope] = useState<string>(null)
  const [modalState, setModalState] = useState(MCMModalState.STANDBY)
  const [showDialog, setShowDialog] = useState(false)

  const { methods } = useAccountConfirmContext()
  const {
    getValues,
    formState: { isDirty, isValid },
  } = methods
  const formValues = getValues()

  const handleDialogClose = () => {
    setShowDialog(false)
  }

  const onBeforeUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault()
    e.returnValue = 'Your trial is currently processing.  Are you sure you want to exit?'
  }

  // eslint-disable-next-line consistent-return
  const onSubmit = async (data: MCMFormData) => {
    const { firstName, lastName, phone, businessName, orgName } = data
    const interest = data['marketplace-radio-group']

    if (data['marketplace-radio-group'] === null) {
      setFailureAlertMessage('You must select an option before continuing')
      setShowFailureAlert(true)
      return false
    }

    setShowDialog(true)
    setIsSubmitting(true)
    setModalState(MCMModalState.PROVISIONING)
    window.addEventListener('beforeunload', onBeforeUnload)
    try {
      const httpHeaders = {
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
        'x-cartid-sub': auth0Id,
        'x-cartid-email': email,
        'x-cartid-businessid': currentBusiness.id.toString(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }

      const response = await fetch(`${process.env.NX_MCM_TRIAL_CREATION}`, {
        method: 'POST',
        headers: httpHeaders,
        body: JSON.stringify({
          firstName,
          lastName,
          businessName,
          productInterest: interest,
          hubSpotUserToken: getHubspotCookie(),
          sourcePageUri: window.location.href,
          phone: phone || '[legacy]',
        }),
      })
      // Let the catch handle all errors.
      if (!response.ok) throw new Error(await response.text())

      hubspotFormSubmit({
        formId: 'a73cdbb3-18ca-4dce-9341-ff069d6b431a',
        data: {
          email,
          firstname: firstName,
          lastname: lastName,
          phone,
          console_organization_name: orgName,
          console_organization_id: currentOrganization.id,
          console_business_id: currentBusiness.id,
          console_business_name: businessName,
          console_trial___marketplace_management: true,
          console_marketplace_management_service_interest: serviceInterestHubspotMapping[interest],
        },
        portalId: parseInt(process.env.NX_HUBSPOT_MARKETING_FORMS_PORTAL_ID, 10),
        pageName: 'Brand Console - MCM Trial Creation',
      })

      const responseJSON = await response.json()
      if (responseJSON.success) {
        window.removeEventListener('beforeunload', onBeforeUnload)
        setModalState(MCMModalState.SUCCESS)
        window.location.href = `${process.env.NX_MCM_SSO_URL}?businessId=${currentBusiness.id}`
      } else {
        // The fetch was successful, but the response was not.
        // Display all the errors the response is sending back.
        throw new Error(responseJSON.errors.toString())
      }
    } catch (error) {
      log.error('Marketplace Management: createMCMTrial', error)
      setModalState(MCMModalState.FAILURE)
      setCreateTrialErrorMessage(error.message)
    } finally {
      setIsSubmitting(false)
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }

  const onAccountConfirmError: TrialFormProps['onError'] = (error, friendlyErrorMessage, scope) => {
    setModalState(MCMModalState.FAILURE)
    setCreateTrialErrorMessage(error.message)
    setErrorScope(scope)
  }

  const beforeSubmit = () => {
    setShowFailureAlert(false)
    return true
  }

  const modalErrorHeader = () => {
    if (createTrialErrorMessage.startsWith('Email already in use'))
      return 'Looks like you have an account'
    return getErrorHeader(createTrialErrorMessage, errorScope)
  }

  const modalErrorBody = () => {
    if (createTrialErrorMessage.startsWith('Email already in use'))
      return (
        <>
          <p>
            A {Services.MARKETPLACE_MANAGEMENT} account with {email} already exists.
          </p>
          <p>An account rep will contact you about merging these two accounts.</p>
        </>
      )

    return getErrorBody(createTrialErrorMessage, errorScope, {
      name: getValues('businessName'),
    })
  }

  return (
    <>
      <MarketplaceManagementRouteDialog
        show={showDialog}
        state={modalState}
        onCloseClick={handleDialogClose}
        error={{ header: modalErrorHeader(), body: modalErrorBody() }}
      />

      <div tw="container mx-auto">
        <div tw="flex-row">
          <div tw="flex-col">
            <div tw="my-6 px-3 sm:px-6">
              <Breadcrumbs
                items={[{ text: 'Home', href: '/' }, { text: Services.MARKETPLACE_MANAGEMENT }]}
              />
            </div>
          </div>
        </div>
        {showFailureAlert && (
          <div tw="mx-auto w-4/5 flex-col py-6 lg:w-2/3">
            <Alert severity="error" onClose={() => setShowFailureAlert(false)}>
              {failureAlertMessage}
            </Alert>
          </div>
        )}
        <MarketplaceManagementRouteForm
          beforeSubmit={beforeSubmit}
          onSubmit={onSubmit}
          onError={onAccountConfirmError}
          isLoading={isSubmitting}
          disabled={!isValid || !isDirty || !formValues['marketplace-radio-group']}
          methods={methods}
        />
      </div>
    </>
  )
}
