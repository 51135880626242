import { Services } from '@brand-console/types'
import { useLayoutStore } from '@brand-console/utilities'
import { Button, Carousel, CarouselItem, DeprecatedCard, useAppContext, useRouter } from '@cart/ui'
import React, { ReactElement } from 'react'
import tw from 'twin.macro'

import { cardContent } from './Recommendations.constants'
import { RecommendationsProps } from './Recommendations.types'

export const Recommendations = ({ className, pageToOmit }: RecommendationsProps): ReactElement => {
  const { sfAccount, mcmEnabled, unifiedAnalyticsProvisioned } = useAppContext()
  const { fulfillmentProvisioned } = useLayoutStore()
  const { navigate } = useRouter();

  const recommendationCards = cardContent
    .filter((card) => card.page !== pageToOmit)
    .map((card, index) => {
      if (card.title === Services.FEED_MARKETING) {
        return null
      }
      if (card.title === Services.STOREFRONT && sfAccount?.provisioned) {
        return null
      }
      if (card.title === Services.MARKETPLACE_MANAGEMENT && mcmEnabled) {
        return null
      }
      if (card.title === Services.FULFILLMENT && fulfillmentProvisioned) {
        return null
      }
      if (
        (card.title === Services.UNIFIED_ANALYTICS ||
          card.title === Services.DATA_SCIENCE_SOLUTIONS) &&
        unifiedAnalyticsProvisioned
      ) {
        return null
      }
      return (
        <CarouselItem key={card.page} data-isfirst={index === 0}>
          <DeprecatedCard noPadding tw="h-full">
            <div tw="flex flex-wrap p-6">
              <div tw="mb-4 flex w-full flex-wrap items-center">
                <div tw="flex w-2/3 items-center">
                  <span tw="mr-2 w-8">{card.image}</span>
                  <span tw="font-bold text-lg leading-none">{card.title}</span>
                </div>
                <div tw="flex w-1/3 justify-end">
                  <Button
                    //href={card.page}
                    onClick={() => navigate(card.page)}
                    variant="contained"
                    size="small"
                    css={[
                      {
                        ':hover': tw`text-white`,
                      },
                    ]}
                  >
                    {card.action}
                  </Button>
                </div>
              </div>
              <div tw="w-full">{card.description}</div>
            </div>
          </DeprecatedCard>
        </CarouselItem>
      )
    })
    .filter((card) => !!card)

  return (
    <Carousel variableWidth={350} className={className} loop>
      {recommendationCards}
    </Carousel>
  )
}
