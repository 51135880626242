import React, { memo } from 'react'

import { LeftNav as LeftNavComponent } from './components/LeftNav/LeftNav'
import { LeftNavProps } from './left-nav.types'

export * from './left-nav.types'

const LeftNavComp = ({ product = null, open = true }: LeftNavProps) => {
  return (
    <LeftNavComponent product={product} open={open} />
  )
}

export const LeftNav = memo(LeftNavComp)
