/* eslint-disable @typescript-eslint/no-explicit-any */

import debounce from 'lodash/debounce'

// https://github.com/react-hook-form/react-hook-form/issues/40#issuecomment-894663328
export function asyncDebounce<F extends (...args: any[]) => Promise<any>>(func: F, wait?: number) {
  const debounced = debounce((resolve: any, reject: any, args: Parameters<F>) => {
    func(...args)
      .then(resolve)
      .catch(reject)
  }, wait)

  return (...args: Parameters<F>): ReturnType<F> =>
    new Promise((resolve, reject) => {
      debounced(resolve, reject, args)
    }) as ReturnType<F>
}
