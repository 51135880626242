import { Services } from '@brand-console/types'
import { useAppContext } from '@cart/ui'
import React, { ReactElement, useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { Navigate, Route, Routes } from 'react-router-dom'
import slugster from 'slugster'

import { AdminTools } from '../AdminTools'
import { SettingsBusiness } from '../SettingsRoutes/SettingsBusiness/SettingsBusinesses'
import { SettingsMarketplaceManagement } from '../SettingsRoutes/SettingsMarketplaceManagement'
import { SettingsOrganizations } from '../SettingsRoutes/SettingsOrganizations/SettingsOrganizations'
import { SettingsOrganizationsProvider } from '../SettingsRoutes/SettingsOrganizations/SettingsOrganizations.store'
import { SettingsProfile } from '../SettingsRoutes/SettingsProfile/SettingsProfile'
import { SettingsSecurity } from '../SettingsRoutes/SettingsSecurity/SettingsSecurity'
import { SettingsStorefront } from '../SettingsRoutes/SettingsStorefront/SettingsStorefront'
import { SettingsUnifiedAnalytics } from '../SettingsRoutes/SettingsUnifiedAnalytics'

export const SettingsRoute = (): ReactElement => {
  const { sfAccount } = useAppContext()
  const sites = sfAccount?.provisioningAccount?.sites
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        route: 'Settings',
      },
    })
  }, [])

  return (
    <div id="cart-settings" tw="flex w-full flex-col flex-wrap px-4 sm:px-8">
      <SettingsOrganizationsProvider>
        <div tw="w-full grow pt-7">
          <Routes>
            <Route path="security" element={<SettingsSecurity />} />
            {sites?.length > 0 &&
              [`${slugster(Services.STOREFRONT)}`, `online-store`].map((path) => (
                <Route key={Services.STOREFRONT} path={path} element={<SettingsStorefront />} />
              ))}
            <Route
              path={`${slugster(Services.MARKETPLACE_MANAGEMENT)}`}
              element={<SettingsMarketplaceManagement />}
            />
            <Route path="unified-analytics/*" element={<SettingsUnifiedAnalytics />} />
            <Route path="admin-tools/*" element={<AdminTools />} />

            {[`organization/businesses/:id`, `organization/businesses/:id/*`].map((path) => (
              <Route key="SettingsBusiness" path={path} element={<SettingsBusiness />} />
            ))}
            <Route
              key="SettingsOrganizations"
              path="organization/*"
              element={<SettingsOrganizations />}
            />
            <Route path="/" element={<Navigate to="profile" replace />} />
            <Route path="/profile" element={<SettingsProfile />} />
          </Routes>
        </div>
      </SettingsOrganizationsProvider>
    </div>
  )
}
