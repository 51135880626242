import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Combobox } from '@headlessui/react'
import assign from 'lodash/assign'
import debounce from 'lodash/debounce'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import tw from 'twin.macro'

import formMethods from '../../framework/forms/form-methods'
import {
  StyledComboboxInput,
  StyledComboboxOptions,
  StyledIcon,
  StyledMessage,
} from './autocomplete.styles'
import { AutocompleteOption, AutocompleteProps } from './autocomplete.types'

export const Autocomplete = ({
  blankState,
  className,
  fetchOptions,
  helperText,
  icon: Icon,
  id,
  label,
  onChange,
  options,
  placeholder,
  required,
  optionRender,
  rules,
  ...rest
}: AutocompleteProps) => {
  const [currentHelperMessage, setCurrentHelperMessage] = useState(helperText)
  const [filteredOptions, setFilteredOptions] = useState<AutocompleteOption[]>()
  const [hasError, setHasError] = useState<boolean>(false)
  const [hasFocus, setHasFocus] = useState<boolean>(false)
  const [query, setQuery] = useState<string>('')
  const [selectedItem, setSelectedItem] = useState({})

  const handleChange = (e: any) => {
    if (onChange) {
      onChange(e)
    }
    setSelectedItem(e)
  }

  const {
    formState: { errors },
    register,
  } = formMethods()

  useEffect(() => {
    const error = errors[id]
    setCurrentHelperMessage((error?.message || helperText) as unknown as string)
    setHasError(!!error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, errors[id]?.type, id, helperText])

  const defaultRules = {
    required: required ? 'This field is required' : undefined,
  }

  const inputRules = assign({}, defaultRules, rules)
  const {
    onChange: onChangeReactHookForm,
    onBlur,
    name,
    ref,
  } = register(id, inputRules as Record<string, unknown>)

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target?.value)
    onChangeReactHookForm?.(e)
  }

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setTimeout(() => onBlur?.(e), 300)
    setHasFocus(false)
  }

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(async () => {
        if (fetchOptions) {
          const fetchedOptions = await fetchOptions(query)
          setFilteredOptions(fetchedOptions as AutocompleteOption[])
        }
      }, 300),
    [fetchOptions, query],
  )

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel()
    }
  }, [debouncedChangeHandler])

  useEffect(() => {
    if (options) {
      setFilteredOptions(
        query === ''
          ? []
          : options.filter((option) => {
              return option.value.toLowerCase().includes(query.toLowerCase())
            }),
      )
    } else {
      debouncedChangeHandler()
    }
  }, [debouncedChangeHandler, fetchOptions, options, query])

  return (
    <div tw="relative [z-index:1]" className={className} {...rest}>
      <Combobox as="div" value={selectedItem} onChange={handleChange}>
        {label && <Combobox.Label tw="block font-medium text-xs">{label}</Combobox.Label>}
        <>
          <div tw="relative overflow-hidden rounded-md bg-transparent">
            {Icon && <StyledIcon icon={solid('magnifying-glass')} as={Icon} aria-hidden="true" />}
            <StyledComboboxInput
              id={id}
              onFocus={() => setHasFocus(true)}
              autoComplete="off"
              value={query}
              $hasError={hasError}
              $hasIcon={!!Icon}
              displayValue={(option: AutocompleteOption) => {
                setQuery(option.value || '')
                return option.value
              }}
              placeholder={placeholder}
              name={name}
              ref={ref}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
            />
            {currentHelperMessage && (
              <StyledMessage data-haserror={hasError} data-hasfocus={hasFocus}>
                {currentHelperMessage}
              </StyledMessage>
            )}
          </div>
          {filteredOptions && (
            <StyledComboboxOptions>
              {filteredOptions.map((option) => (
                <Combobox.Option
                  key={option.id}
                  className={({ active }) => (active ? 'active' : '')}
                  value={option}
                >
                  {({ selected }) => {
                    return (
                      <>
                        {optionRender && <>{optionRender(option, query, selected)}</>}
                        {!optionRender && (
                          <>
                            <span
                              css={[
                                tw`block truncate`,
                                selected ? tw`font-medium` : tw`font-normal`,
                              ]}
                            >
                              {option.value}
                            </span>
                            {selected ? (
                              <span
                                css={[
                                  tw`absolute inset-y-0 left-0 ml-3 flex items-center text-primary-700`,
                                ]}
                              >
                                <FontAwesomeIcon
                                  tw="h-5 w-5"
                                  aria-hidden="true"
                                  icon={solid('circle-check')}
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </>
                    )
                  }}
                </Combobox.Option>
              ))}
              {filteredOptions.length === 0 && (
                <Combobox.Option
                  key={-1}
                  className={({ active }) => (active ? 'active' : '')}
                  value={query}
                >
                  {typeof blankState === 'function' ? blankState(query) : blankState}
                </Combobox.Option>
              )}
            </StyledComboboxOptions>
          )}
        </>
      </Combobox>
    </div>
  )
}
