export const addBusinessIdParam = (menu, businessId) => {
  if (menu.items) {
    menu.items.forEach((item) => {
      if (
        item.url?.startsWith(`${process.env.NX_MCM_ORIGIN}`) &&
        !item.url.includes('?businessId=')
      ) {
        item.url = `${item.url}?businessId=${businessId}`
      }
      if (item.items) {
        addBusinessIdParam(item, businessId)
      }
    })
  }
}
