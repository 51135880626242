import { useLayoutStore } from '@brand-console/utilities'
import { useAppContext } from '@cart/ui'
import chunk from 'lodash/chunk'
import { useMemo } from 'react'

import {
  CustomerEngagement,
  DataScienceSolutions,
  FeedMarketing,
  Fulfillment,
  GrowthMarketing,
  MarketplaceManagement,
  MarketplaceServices,
  Reporting,
  Storefront,
} from '../AppAndServices.constants'
import { IEnabledServices } from '../AppsAndServices.types'
import { basicCardRanker, dynamicCardRanker } from './cardRanker'

export type MixMode = 'basic' | 'dynamic'
export const useAppCards = (mode: MixMode) => {
  const { mcmEnabled, dfwAccount, unifiedAnalyticsProvisioned, sfAccount } = useAppContext()
  const { fulfillmentProvisioned } = useLayoutStore()
  const [sfAccountProvisioned, dfwAccountProvisioned] = [
    sfAccount?.provisioned,
    dfwAccount?.provisioned,
  ]

  const [cards, hiddenCards] = useMemo(() => {
    const cardRanker = mode === 'dynamic' ? dynamicCardRanker() : basicCardRanker()
    const enabled: IEnabledServices = {
      mcmEnabled,
      sfEnabled: sfAccountProvisioned,
      dfwEnabled: dfwAccountProvisioned,
      rptEnabled: unifiedAnalyticsProvisioned,
      ffEnabled: fulfillmentProvisioned,
      gmEnabled: false,
      ceEnabled: false,
      dsEnabled: false,
      msEnabled: false,
    }
    const cardsToEnabled = {
      [Storefront.title]: enabled.sfEnabled,
      [MarketplaceManagement.title]: enabled.mcmEnabled,
      [FeedMarketing.title]: enabled.dfwEnabled,
      [Reporting.title]: enabled.rptEnabled,
      [Fulfillment.title]: enabled.ffEnabled,
      [GrowthMarketing.title]: enabled.gmEnabled,
      [CustomerEngagement.title]: enabled.ceEnabled,
      [DataScienceSolutions.title]: enabled.dsEnabled,
      [MarketplaceServices.title]: enabled.msEnabled,
    }

    cardRanker.filterEnabled(cardsToEnabled)
    cardRanker.rank(enabled)
    const [firstThree, ...rest] = chunk(cardRanker.getCards(), 3)
    return [firstThree || [], rest ? rest.flat() : []]
  }, [
    mode,
    mcmEnabled,
    sfAccountProvisioned,
    dfwAccountProvisioned,
    unifiedAnalyticsProvisioned,
    fulfillmentProvisioned,
  ])

  return {
    cards,
    hiddenCards,
  }
}
