import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SnackbarOrigin, SnackbarProps as MuiSnackbarProps } from '@mui/material/Snackbar'
import React, { ReactNode, useState } from 'react'

import { DeprecatedAlert } from '../deprecated-alert/deprecated-alert'
import { StyledSnackbar } from './snackbar.styles'

interface State extends SnackbarOrigin {
  open: boolean
}

export interface SnackbarProps extends MuiSnackbarProps {
  trigger?: ReactNode
  type?: 'base' | 'info' | 'error' | 'warning' | 'success'
}

export const Snackbar = (props: SnackbarProps) => {
  const { anchorOrigin, message, onClose, trigger, action: actionProp, type } = props
  const { vertical: defaultVertical = 'bottom', horizontal: defaultHorizontal = 'left' } =
    anchorOrigin || {}

  const [state, setState] = useState<State>({
    open: !trigger,
    vertical: defaultVertical,
    horizontal: defaultHorizontal,
  })

  const { vertical, horizontal, open } = state

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return null
    }
    onClose?.(event, reason as any)
    setState({ ...state, open: false })
    return null
  }

  const handleClick = () => {
    setState({ ...state, open: true })
  }

  const action = (
    <div tw="flex items-center">
      {actionProp && <div tw="leading-none">{actionProp}</div>}
      <button
        type="button"
        onClick={handleClose}
        tw="mr-2 ml-4 flex items-center text-xl leading-none mt-[1px]"
        aria-label="close"
      >
        <FontAwesomeIcon icon={solid('xmark')} />
      </button>
    </div>
  )

  return (
    <>
      {trigger && (
        <div role="button" onClick={handleClick} tabIndex={0} onKeyDown={handleClick}>
          {trigger}
        </div>
      )}
      <StyledSnackbar
        {...props}
        open={open}
        onClose={handleClose}
        action={action}
        anchorOrigin={{ vertical, horizontal }}
      >
        {type && (
          <div>
            <DeprecatedAlert
              text={message as string}
              type={type}
              allowClose
              onClose={handleClose}
            />
          </div>
        )}
      </StyledSnackbar>
    </>
  )
}
