import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC } from 'react'

import { IconButton } from '../../icon-button/icon-button'
import { DialogContext } from '../dialog.partials'
import { StyledDialogTitle } from './dialog-title.styles'
import { DialogTitleProps } from './dialog-title.types'

export const DialogTitle: FC<DialogTitleProps> = ({ children, hideCloseButton, ...props }) => {
  const context = React.useContext(DialogContext)
  const { onClose, hideCloseButton: hideCloseFromContext } = context || {}
  const hasChildren = React.Children.count(children) > 0

  const handleClick: React.MouseEventHandler<SVGSVGElement> = (event) => {
    if (onClose) {
      onClose(event, 'closeButtonClick')
    }
  }
  return (
    <StyledDialogTitle hasChildren={hasChildren} variant="h5" {...props}>
      {children && <span>{children}</span>}
      {!hideCloseButton && !hideCloseFromContext && (
        <IconButton size="small" tw="[z-index: 1] relative !ml-4 sm:!ml-0">
          <FontAwesomeIcon
            data-testid="cart-dialog-close"
            role="button"
            aria-label="close dialog"
            onClick={handleClick}
            icon={solid('xmark')}
            tw="!(h-6 w-6)"
          />
        </IconButton>
      )}
    </StyledDialogTitle>
  )
}
