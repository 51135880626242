import { logger } from '@brand-console/utilities'

import maintenance from './assets/images/maintenance.svg'

export function pageErrorHandler(error: Error) {
  const log = logger.setLogger(logger.LoggerNames.UNIFIED_ANALYTICS)
  log.error(`${error.name}: ${error.message}`, error)
}

export const GeneralContainerFallback: React.FC = () => {
  return (
    <div tw="flex w-full items-center justify-center">
      <div tw="flex flex-1 flex-col-reverse justify-center gap-8 md:(flex-row gap-6)">
        <div tw="flex flex-col items-center justify-center md:items-start">
          <h2>Unable to load page at this time</h2>
          <p tw="pt-2 text-center md:text-left">
            Sorry for the inconvenience. A technical issue on our end is preventing this page from
            loading right now. We&rsquo;re working to fix the situation as quickly as possible.
          </p>
        </div>
        <div tw="flex flex-col items-center justify-center">
          <img src={maintenance} alt="" />
        </div>
      </div>
    </div>
  )
}
