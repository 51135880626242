import { DeprecatedBadge, DeprecatedCard, DeprecatedCheckbox } from '@cart/ui'
import { formatDistanceToNow } from 'date-fns'
import React, { useState } from 'react'
import tw from 'twin.macro'

import { dataStatusColor } from '../../helpers/data'
import { DataConnectorIcon } from '../settings/DataConnectors/components/DataConnectorSection/DataConnectorSection.partials'
import { ActionsButton, ActionsHeaderButton } from './MyConnectors.partials'

const dataConnectorIsChecked = (connector, state) => {
  return Boolean(state.find((dataConnector) => dataConnector.id === connector.id))
}

export const MyConnectorsMobile = ({ data, updateActionConnector }) => {
  const [selectedConnectors, setSelectedConnectors] = useState([])
  const CardTitle = ({ dataConnector, onChange, checked }) => {
    const { title, business } = dataConnector
    return (
      <div tw="flex">
        <DeprecatedCheckbox
          id="box"
          value="fox"
          tw="mt-3.5 mr-2 self-baseline"
          onChange={onChange}
          checked={checked}
        />

        <div tw="grow text-sm">
          <div tw="flex">
            <DataConnectorIcon dataConnector={dataConnector} css={[tw`mr-2.5`]} /> {title}
          </div>
          <div tw="font-normal">{business.name}</div>
        </div>
        <div>
          <ActionsButton
            dataConnector={dataConnector}
            updateActionConnector={updateActionConnector}
          />
        </div>
      </div>
    )
  }

  let ALL_CHECKED: boolean | undefined
  if (selectedConnectors.length === 0) {
    ALL_CHECKED = false
  } else if (selectedConnectors.length === data.length) {
    ALL_CHECKED = true
  }

  const checkHandler = (clickedConnector) => {
    if (dataConnectorIsChecked(clickedConnector, selectedConnectors)) {
      setSelectedConnectors((prevState) =>
        prevState.filter((dataConnector) => dataConnector.id !== clickedConnector.id),
      )
    } else {
      setSelectedConnectors((prevState) => [...prevState, clickedConnector])
    }
  }

  const checkAllHandler = () => {
    if (selectedConnectors.length === data.length) {
      setSelectedConnectors([])
    } else {
      setSelectedConnectors(data)
    }
  }

  const dataConnectorCards = data?.map((dataConnector) => {
    return (
      <DeprecatedCard
        tw="mb-4"
        noPadding
        key={dataConnector.id}
        title={
          <CardTitle
            dataConnector={dataConnector}
            onChange={() => checkHandler(dataConnector)}
            checked={dataConnectorIsChecked(dataConnector, selectedConnectors)}
          />
        }
      >
        <div tw="border-t-2 border-t-monochrome-500 bg-monochrome-50 p-4">
          <div tw="mb-7 flex justify-between">
            <div tw="mr-3">Status</div>
            <div tw="text-right">
              <DeprecatedBadge
                text={dataConnector.status}
                type={dataStatusColor[dataConnector.status]}
                showDot={false}
              />
            </div>
          </div>
          <div tw="mb-7 flex justify-between">
            <div tw="mr-3">Last Updated</div>
            <div tw="text-right">
              {formatDistanceToNow(new Date(dataConnector.lastUpdated), { addSuffix: true })}
            </div>
          </div>
          <div tw="flex justify-between">
            <div tw="mr-3">Category</div>
            <div tw="text-right">
              {dataConnector.categories
                .map((category) => category.title)
                .sort()
                .join(', ')}
            </div>
          </div>
        </div>
      </DeprecatedCard>
    )
  })

  return (
    <>
      <div tw="ml-8 mb-7 flex w-full justify-start">
        <DeprecatedCheckbox id="all" value="" checked={ALL_CHECKED} onChange={checkAllHandler} />
        <ActionsHeaderButton
          dataConnectors={selectedConnectors}
          updateActionConnector={updateActionConnector}
        />
      </div>
      {dataConnectorCards}
    </>
  )
}
