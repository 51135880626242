import { dsApi } from './dsApiSlice'

type TicketResponseType = {
  data: {
    ticket: string
  }
}

type TableauJWTResponseType = {
  data: {
    token: string
  }
}

const tableauEndpoints = dsApi.injectEndpoints({
  endpoints: (builder) => ({
    getTrustedTicket: builder.query<TicketResponseType, string>({
      query: (organizationId) => {
        return `tableau/trusted-ticket/${
          typeof organizationId !== 'undefined' ? `?organization_id=${organizationId}` : ''
        }`
      },
    }),
    getTableauJWT: builder.query<TableauJWTResponseType, string>({
      query: (organizationId) => {
        return `tableau/jwt/${
          typeof organizationId !== 'undefined' ? `?organization_id=${organizationId}` : ''
        }`
      },
    }),
  }),
})

export const {
  useGetTrustedTicketQuery,
  useLazyGetTrustedTicketQuery,
  useGetTableauJWTQuery,
  useLazyGetTableauJWTQuery,
} = tableauEndpoints
