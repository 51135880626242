import { CardButton } from '@cart/ui'
import React from 'react'
import tw, { styled } from 'twin.macro'

type ShortcutProps = {
  icon: React.ReactElement
  title: string
  description: string
  color?: 'primary' | undefined
  onClick?: () => void
}

export const StyledCardButton = styled(CardButton)((props) => [
  tw`[width: 72px] [height: 72px] !rounded-2xl`,
  tw`[> button]:w-full`,
  props.color === 'primary' && tw`bg-primary-700`,
])

export const Shortcut = ({ icon, title, description, color, onClick }: ShortcutProps) => (
  <div tw="mb-6 flex flex-col items-center justify-start">
    <StyledCardButton color={color} onClick={onClick}>
      <div tw="inline-flex h-full w-full items-center justify-center">
        <div tw="w-full">{icon}</div>
      </div>
    </StyledCardButton>
    <button type="button" tw="mt-4 mb-2 font-bold text-sm leading-tight" onClick={onClick} data-testid={`shortcut-${title.toLowerCase().replace(/\s+/g, '-')}`}>
      {title}
    </button>
    <button type="button" tw="text-monochrome-900/60 text-sm leading-none" onClick={onClick}>
      {description}
    </button>
  </div>
)
