import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Spinner } from '@cart/ui'
import React from 'react'

export const ProtectedRoute = ({ component, Loading = <Spinner /> }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => Loading,
  })

  return <Component />
}
