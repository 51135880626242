import { validatePhoneRule } from '@brand-console/utilities'
import { DialogTitle, Input } from '@cart/ui'
import { Alert } from '@cart/ui/molecules/Alert'
import Typography from '@mui/material/Typography'
import React, { FC } from 'react'

import { useAccountConfirmContext } from './store/AccountConfirmForm.store'
import { AccountConfirmFormProps } from './AccountConfirmForm.types'

export const AccountConfirmForm: FC<AccountConfirmFormProps> = ({
  headerProps,
  bodyProps,
  showErrorAlert,
  title,
  dialogTitle,
}) => {
  const {
    formDefaultValues,
    methods,
    errorMessage,
    updateBizDisabled,
    shouldShowForm,
  } = useAccountConfirmContext()
  const { firstName, lastName, phone, businessName } = formDefaultValues
  const formTitle = title || 'Your account information'

  if (!shouldShowForm) return null

  return (
    <>
      {dialogTitle ? (
        <DialogTitle hideCloseButton id="trial-form-title" tw="!(px-0 pb-6)" data-testid="account-form-title">
          {formTitle}
        </DialogTitle>
      ) : (
        <Typography
          variant="h4"
          tw="mb-6"
          id="trial-form-title"
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...headerProps}
          data-testid="account-form-title"
        >
          {formTitle}
        </Typography>
      )}
      {showErrorAlert && errorMessage && (
        <Alert tw="!mb-6" severity="error">
          {errorMessage}
        </Alert>
      )}
      <div {...bodyProps}>
        <div tw="flex flex-col gap-6">
          <div tw="flex flex-col gap-6 lg:(grid grid-cols-2)">
            <Input
              id="firstName"
              label="First name"
              required
              defaultValue={firstName || ''}
              methods={methods}
              inputProps={{
                maxLength: 100,
              }}
            />
            <Input
              id="lastName"
              label="Last name"
              required
              defaultValue={lastName || ''}
              methods={methods}
              inputProps={{
                maxLength: 100,
              }}
            />
          </div>
          <div tw="flex flex-col gap-6 lg:(grid grid-cols-2)">
            <Input
              id="phone"
              label="Phone"
              required
              type="tel"
              defaultValue={phone}
              methods={methods}
              rules={{
                validate: validatePhoneRule,
              }}
              inputProps={{
                maxLength: 20,
              }}
            />
            <Input
              id="businessName"
              label="Business name"
              required
              defaultValue={businessName}
              methods={methods}
              inputProps={{
                maxLength: 100,
              }}
              disabled={updateBizDisabled}
            />
          </div>
        </div>
      </div>
    </>
  )
}
