/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

type DashboardSliceStateType = {
  hiddenVizHasLoaded: boolean
}

const initialState: DashboardSliceStateType = {
  hiddenVizHasLoaded: false,
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setHiddenVizHasLoaded: (state, action) => {
      state.hiddenVizHasLoaded = action.payload
    },
  },
})

export const { setHiddenVizHasLoaded } = dashboardSlice.actions
