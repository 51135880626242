/**
 * This function returns the initials of a name
 *
 * @param   {string}  string the string to get the initials from
 * @param   {boolean} getAll if all initials should be returned. Defaults to two
 * @returns string
 */
export const getInitials = ({
  string,
  getAll = false,
}: {
  string: string
  getAll?: boolean
}): string => {
  if (getAll) {
    const matches = string.match(/\b(\w)/g)
    if (matches && matches.length) {
      return matches.join('').toUpperCase()
    }
  }
  const fullString = string.split(' ')
  const shift = fullString.shift() || ''
  const pop = fullString.pop() || ''
  return (shift.charAt(0) + pop.charAt(0)).toUpperCase()
}
