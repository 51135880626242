import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ReactElement } from 'react'
import { StyleSheetManager } from 'styled-components'

import {
  StyledFooter,
  StyledFooterItem,
  StyledFooterLink,
  StyledFooterList,
  StyledFooterSocialIcon,
  StyledMarketingFooter,
  StyledMarketingFooterCopyright,
  StyledMarketingFooterSocial,
} from './footer.styles'
import { FooterProps } from './footer.types'

const year = new Date().getFullYear()

export const Footer = ({
  className,
  renderRoot,
  type = 'console',
  product,
}: FooterProps): ReactElement => (
  <StyleSheetManager target={renderRoot}>
    <StyledFooter product={product} className={className} data-testid="footer" data-type={type}>
      {type === 'console' && (
        <StyledFooterList>
          <StyledFooterItem>
            <StyledFooterLink href="https://cart.com" target="_blank" rel="noreferrer">
              &copy; {year} Cart.com
            </StyledFooterLink>
          </StyledFooterItem>
          <StyledFooterItem>
            <StyledFooterLink
              href="https://www.cart.com/terms-of-service"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </StyledFooterLink>
          </StyledFooterItem>
          <StyledFooterItem>
            <StyledFooterLink
              href="https://www.cart.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </StyledFooterLink>
          </StyledFooterItem>
        </StyledFooterList>
      )}
      {type === 'marketing' && (
        <StyledMarketingFooter>
          <StyledMarketingFooterCopyright>
            {year}&copy; Cart.com, Inc. All rights reserved.
          </StyledMarketingFooterCopyright>
          <StyledMarketingFooterSocial data-testid="footer-social-icons">
            <StyledFooterSocialIcon
              href="https://www.linkedin.com/company/cartdotcom"
              rel="noreferrer"
              target="_blank"
              tw="text-lg"
            >
              <FontAwesomeIcon icon={brands('linkedin')} />
            </StyledFooterSocialIcon>
            <StyledFooterSocialIcon
              href="https://www.facebook.com/cartdotcom"
              rel="noreferrer"
              target="_blank"
              tw="text-lg"
            >
              <FontAwesomeIcon icon={brands('facebook')} />
            </StyledFooterSocialIcon>
            <StyledFooterSocialIcon
              href="https://twitter.com/cartdotcom"
              rel="noreferrer"
              target="_blank"
              tw="text-lg"
            >
              <FontAwesomeIcon icon={brands('twitter')} />
            </StyledFooterSocialIcon>
          </StyledMarketingFooterSocial>
        </StyledMarketingFooter>
      )}
    </StyledFooter>
  </StyleSheetManager>
)
