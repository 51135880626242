import { Button, DeprecatedDialog, useAppContext } from '@cart/ui'
import { useCartAuth } from '@cartdotcom/auth'
import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import { ACSingleSignOn } from '../../../helpers'

export const StorefrontLaunch = ({
  buttonText = 'Launch',
  relativePath,
}: {
  buttonText?: string
  relativePath?: string
}) => {
  const { auth0Token, sfAccount } = useAppContext()
  const dialogTitle = !relativePath ? 'Select a store to launch' : `${buttonText} for which store?`
  const { auth0Id } = useCartAuth()
  const [showStoresListDialog, setShowStoresListDialog] = useState<boolean>(false)
  const sites = sfAccount?.provisioningAccount?.sites

  const toggleStoresListDialog = useCallback(() => {
    setShowStoresListDialog(!showStoresListDialog)
  }, [showStoresListDialog])

  if (sites.length === 1) {
    if (!relativePath) {
      return (
        <Button
          variant="contained"
          LinkComponent={Link}
          to="/storefront"
          tw="bg-primary-700 px-7 font-bold h-[38px] hover:bg-primary-800"
        >
          {buttonText}
        </Button>
      )
    }
    return (
      <Button
        variant="contained"
        tw="bg-primary-700 px-7 font-bold h-[38px] hover:bg-primary-800"
        onClick={() => {
          ACSingleSignOn(`https://${sites[0].sharedDomain}${relativePath}`, auth0Token, auth0Id)
        }}
      >
        {buttonText}
      </Button>
    )
  }

  return (
    <DeprecatedDialog
      id="stores-list-dialog"
      show={showStoresListDialog}
      close={toggleStoresListDialog}
      title={dialogTitle}
      trigger={
        <Button
          variant="contained"
          tw="bg-primary-700 px-7 font-bold h-[38px] hover:bg-primary-800"
          data-testid="stores-list-button"
        >
          {buttonText}
        </Button>
      }
      width={540}
    >
      <div tw="px-6 pt-6 pb-4">
        <ul tw="ml-0 grid list-none grid-cols-2 gap-6 p-0">
          {sites.map(({ id, name, sharedDomain }) => (
            <li key={id} tw="m-0 p-0">
              <button
                type="button"
                tw="flex w-full items-center justify-center overflow-hidden rounded-lg border border-monochrome-50 bg-transparent p-4 text-center font-bold text-base shadow-md transition-colors hover:(cursor-pointer bg-primary-700 text-white)"
                onClick={() => {
                  ACSingleSignOn(
                    `https://${sharedDomain}${relativePath || '/store/admin/login.aspx?fromBc=1'}`,
                    auth0Token,
                    auth0Id,
                  )
                }}
              >
                {name}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </DeprecatedDialog>
  )
}
