import { dataConnectorsData } from '../../helpers/data'
import { convertConciergeTypes } from '../../helpers/functions'
import {
  CreateDataConnectorResponse,
  CreateDataConnectorVariables,
  DataConnectorsCardsResponse,
  DataConnectorsResponse,
  GetConnectorsStatus,
  OAuthUrlDataConnectorsResponse,
} from '../types/dataConnectorsTypes'
import { dsApi } from './dsApiSlice'

const dataConnectorEndpoints = dsApi.injectEndpoints({
  endpoints: (builder) => ({
    getDataConnectors: builder.query<DataConnectorsResponse, GetConnectorsStatus>({
      query: (data) => {
        // ordering param can only have one value at a time
        let ordering: string
        if (data.lastUpdated) {
          ordering = 'last_updated'
        }
        if (data.dataConnectors) {
          ordering = 'data_connectors'
        }

        return {
          url: `data-connector/all/`,
          params: {
            status: data.status,
            business__name: data.business,
            search: data.search,
            ordering,
            organization_id: data.organizationId,
          },
        }
      },
      providesTags: ['DataConnectors'],
    }),

    getDataConnectorsCard: builder.query<DataConnectorsCardsResponse, string>({
      query: (organizationId) =>
        `data-connector/check-business/${
          typeof organizationId !== 'undefined' ? `?organization_id=${organizationId}` : ''
        }`,
      providesTags: ['DataConnectorsCards'],
    }),
    getOAuthUrlDataConnectors: builder.query<OAuthUrlDataConnectorsResponse, string>({
      query: (name) => `data-connector/${name}/auth-url/`,
      providesTags: ['DataConnectors'],
    }),
    createDataConnector: builder.mutation<
      CreateDataConnectorResponse,
      CreateDataConnectorVariables
    >({
      query: ({ organizationId, name, type, ...data }) => {
        if (name === 'google-adwords') {
          // eslint-disable-next-line no-param-reassign
          data.data.customer_id = data.data.customer_id.replace(/[^0-9]/g, '')
          // eslint-disable-next-line no-param-reassign
          data.data.login_customer_id = data.data.login_customer_id.replace(/[^0-9]/g, '')
        }

        const attributes = {
          ...data.data,
          organization_id: organizationId,
          activation_code: data.data.activation_code,
        }

        return {
          url: `data-connector/${name}/`,
          method: 'POST',
          body: JSON.stringify({
            data: {
              attributes,
              type,
            },
          }),
        }
      },
      invalidatesTags: ['DataConnectors', 'DataConnectorsCards'],
    }),

    // TODO: create a variable type
    syncDataConnectors: builder.mutation<void, any>({
      query: (connectorsToSync) => {
        const connectors = convertConciergeTypes(connectorsToSync)
        return {
          url: 'data-connector/sync/',
          method: 'POST',
          body: JSON.stringify({
            data: {
              type: 'DataConnectorSyncView',
              attributes: {
                connectors,
              },
            },
          }),
        }
      },
      invalidatesTags: ['DataConnectors', 'DataConnectorsCards'],
    }),

    deleteDataConnectors: builder.mutation<void, any>({
      query: (connectorsToDelete) => {
        const connectors = convertConciergeTypes(connectorsToDelete)
        return {
          url: 'data-connector/delete/',
          method: 'POST',
          body: JSON.stringify({
            data: {
              type: 'DataConnectorDeleteView',
              attributes: {
                connectors,
              },
            },
          }),
        }
      },
      invalidatesTags: ['DataConnectors', 'DataConnectorsCards'],
    }),

    resetDataConnectors: builder.mutation<void, any>({
      query: (connectorsToReset) => {
        const connectors = convertConciergeTypes(connectorsToReset)
        return {
          url: 'data-connector/reset/',
          method: 'POST',
          body: JSON.stringify({
            data: {
              type: 'DataConnectorResetView',
              attributes: {
                connectors,
              },
            },
          }),
        }
      },
      invalidatesTags: ['DataConnectors'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetDataConnectorsQuery,
  useLazyGetDataConnectorsQuery,
  useLazyGetOAuthUrlDataConnectorsQuery,
  useGetDataConnectorsCardQuery,
  useGetOAuthUrlDataConnectorsQuery,
  useLazyGetDataConnectorsCardQuery,
  useCreateDataConnectorMutation,
  useSyncDataConnectorsMutation,
  useDeleteDataConnectorsMutation,
  useResetDataConnectorsMutation,
} = dataConnectorEndpoints
