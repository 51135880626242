import { useReactiveVar } from '@apollo/client'
import {
  activeBizVar,
  activeOrgVar,
  InstanceType,
  useBusinessServicesLazyQuery,
  useOrgProductInstanceListQuery,
} from '@brand-console/generated-graphql-hooks'
import {
  isDFWEnabled,
  localStorageHelper,
  LocalStorageKeys,
  logger,
  useLayoutStore,
} from '@brand-console/utilities'
import { StorefrontAccountsList } from '@brand-console/types'
import { Spinner, useAppContext, useRouter } from '@cart/ui'
import { useCartAuth, useCurrentContext, useIsCsr } from '@cartdotcom/auth'
import { datadogRum } from '@datadog/browser-rum'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { memo, ReactElement, useEffect, useMemo, useState } from 'react'
import TagManager from 'react-gtm-module'
import { useLocation } from 'react-router'

import { AppRoutes } from '../../routes'
import { BrandHq } from '../../routes/BrandHq'
import { getSiteNavigation } from '../../routes/config'
import { OrganizationSelectionRoute } from '../../routes/OrganizationSelectionRoute/OrganizationSelectionRoute'
import { CreateOrganization } from '../../routes/OrganizationsRoutes/CreateOrganization/CreateOrganization'

const noActiveOrganizationPaths = ['/organization/new']

export const Main = memo((): ReactElement => {
  const log = useMemo(() => logger.setLogger(), [])
  const activeOrg = useReactiveVar(activeOrgVar)
  const activeBiz = useReactiveVar(activeBizVar)

  const {
    uaTempShowAlertsPage220520,
    uaTempShowEmailReports220520,
    uaTempShowNewMyConnectorsTable221004,
    uaTempCampaignMapping230125,
    seTempShowGrowthCapital230327,
    seTempShowMerchantTools230504,
  } = useFlags()
  const urlParams = new URLSearchParams(window.location.search)
  const location = useLocation()

  const { pathname, navigate } = useRouter()
  const [isProvisioningLoading, setIsProvisioningLoading] = useState<boolean>(true)

  const {
    auth0Token,
    mcmEnabled,
    siteNavigation,
    sfAccount,
    setDfwAccount,
    setMcmEnabled,
    setMcmIsLoading,
    setSfAccount,
    setSfAccountIsLoading,
    setSiteNavigation,
  } = useAppContext()
  const { setFulfillmentProvisioned } = useLayoutStore()
  const { setProduct } = useLayoutStore()

  const { organizations, userId, email, auth0Id, firstName, lastName, fullName, createdAt } =
    useCartAuth({
      enableSessionCheck: true,
    })
  const { setBusinessIdContext, currentBusiness, currentOrganization } = useCurrentContext()

  const isCsr = useIsCsr()

  const [getBusinessServices, { data: businessServices }] = useBusinessServicesLazyQuery({
    onCompleted: () => {
      setMcmIsLoading(false)
    },
  })

  const { data: productInstances } = useOrgProductInstanceListQuery({
    variables: {
      input: {
        businessId: activeBiz?.id,
      },
    },
    skip: !activeBiz?.id,
  })

  useEffect(() => {
    setProduct(
      window.location.pathname.split('/')[1]?.toLowerCase() === 'unified-analytics' ? 'ua' : 'bc',
    )
  }, [location, setProduct])

  // TODO: Move this to the OrgSelectionRoute
  useEffect(() => {
    const businessIdParam = parseInt(urlParams.get('businessId'), 10)
    if (businessIdParam && activeOrg && organizations.length && businessIdParam !== activeBiz?.id) {
      setBusinessIdContext(businessIdParam)
      const orgFromParam = organizations?.find((org) =>
        org.businesses?.find((biz) => biz.id === businessIdParam),
      )
      const businessFromParam = orgFromParam?.businesses?.find((biz) => biz.id === businessIdParam)
      if (businessFromParam) {
        if (activeOrg.id !== orgFromParam.id) {
          activeOrgVar({
            ...orgFromParam,
            businessEntityTerminology: 'Business',
            businesses: orgFromParam?.businesses || [],
          })
        }
        activeBizVar(businessFromParam)
        localStorageHelper.set(LocalStorageKeys.BUSINESS, businessFromParam)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrg, organizations])

  useEffect(() => {
    setIsProvisioningLoading(typeof mcmEnabled === 'undefined' && typeof sfAccount === 'undefined')
  }, [sfAccount, mcmEnabled])

  // Set Fulfillment Provisioning Status. This is a dupe of the code in the Header component, but it will
  // be the only code in place once the Header is no longer used and we've switched to Unified Sidebar.
  useEffect(() => {
    const hasFulfillmentProduct = productInstances?.org_productInstanceList?.find(
      (productInstance) => productInstance.instanceType === InstanceType.FULFILLMENT,
    )

    setFulfillmentProvisioned(!!hasFulfillmentProduct)

    if (hasFulfillmentProduct && !urlParams.get('constellation')) {
      navigate('/fulfillment')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productInstances, setFulfillmentProvisioned, navigate])

  // Site Navigation and Routes
  useEffect(() => {
    const initializeRoutesAndNavigation = async () => {
      if (!isProvisioningLoading) {
        const sites = sfAccount?.provisioningAccount?.sites
        const hasSFEnabled = !!sfAccount?.provisioned && sites?.length > 0
        const siteNavigationData = await getSiteNavigation({
          accessToken: auth0Token,
          hasMCMEnabled: mcmEnabled,
          hasSFEnabled,
          hasUAEmailReportsEnabled: uaTempShowEmailReports220520,
          hasUAAlertsEnabled: uaTempShowAlertsPage220520,
          hasUAChannelMappingEnabled: uaTempCampaignMapping230125,
          hasAdminToolsEnabled: isCsr,
          hasUANewMyConnectorsEnabled: uaTempShowNewMyConnectorsTable221004,
          shouldShowGrowthCapital: seTempShowGrowthCapital230327,
          hasMerchantToolsEnabled: seTempShowMerchantTools230504,
        })

        setSiteNavigation(siteNavigationData)
      }
    }
    initializeRoutesAndNavigation()
  }, [
    auth0Token,
    isProvisioningLoading,
    mcmEnabled,
    isCsr,
    seTempShowGrowthCapital230327,
    seTempShowMerchantTools230504,
    setSiteNavigation,
    sfAccount?.provisioned,
    sfAccount?.provisioningAccount?.sites,
    uaTempCampaignMapping230125,
    uaTempShowAlertsPage220520,
    uaTempShowEmailReports220520,
    uaTempShowNewMyConnectorsTable221004,
  ])

  // Feed Marketing Provisioning Status (DataFeedWatch)
  useEffect(() => {
    const fetchDFWAccountAPI = async () => {
      const provisioned = await isDFWEnabled({ token: auth0Token, email, log })
      setDfwAccount({ provisioned })
    }
    if (auth0Token && email) {
      fetchDFWAccountAPI()
    }
  }, [auth0Token, email])

  // Marketplace Management Provisioning Status
  useEffect(() => {
    const fetchBusinessServices = async () => {
      await getBusinessServices({ variables: { orgBusinessShowId: activeBiz.id } })
    }
    if (activeBiz) {
      fetchBusinessServices()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBiz])

  useEffect(() => {
    setMcmEnabled(businessServices?.org_businessShow?.businessServices?.mcmEnabled)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessServices?.org_businessShow])

  // Storefront Provisioning Status
  useEffect(() => {
    const fetchSFAccountsAPI = async () => {
      if (!auth0Token || !email || !auth0Id || !activeBiz) return
      setSfAccountIsLoading(true)
      try {
        const httpHeaders = {
          Authorization: `Bearer ${auth0Token}`,
          'x-cartid-sub': auth0Id,
          'x-cartid-email': email,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }

        const response = await fetch(`${process.env.NX_MYSTORES_API_URL}`, {
          method: 'GET',
          headers: httpHeaders,
        })

        if (!response.ok) throw new Error(await response.text())

        const sfAccountsResponse = await response.json()
        const provisioningAccounts =
          sfAccountsResponse?.result?.length > 0
            ? sfAccountsResponse?.result.flatMap((r) => r.provisioningAccounts)
            : []

        const provisioningAccount = provisioningAccounts.find(
          (pa) => pa.cartBusinessId === activeBiz.id,
        )

        if (sfAccountsResponse.success && provisioningAccounts.length > 0 && provisioningAccount) {
          const sfAccountMetadata: StorefrontAccountsList = sfAccountsResponse
          setSfAccount({
            metadata: sfAccountMetadata,
            provisioningAccount,
            provisioned: true,
          })
        } else {
          setSfAccount({
            provisioned: false,
          })
        }
      } catch (error) {
        log?.error('fetchSFAccountsAPI Error', error)
        setSfAccount(null)
      }
      setSfAccountIsLoading(false)
    }
    window.requeryACMyStoresAPI = fetchSFAccountsAPI
    if (activeBiz && process.env.NX_ENV === 'production') {
      fetchSFAccountsAPI()
    } else {
      setSfAccount(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBiz, auth0Token, email, auth0Id])

  useEffect(() => {
    /**
     * Google Tag Manager: Initialize Google Tag Manager Conneciton
     */
    if (!auth0Id) return
    const tagManagerArgs = {
      gtmId: process.env.NX_GTM_ID,
      dataLayer: {
        userId: auth0Id,
      },
      auth: process.env.NX_GTM_AUTH,
      preview: process.env.NX_GTM_PREVIEW,
    }
    try {
      TagManager.initialize(tagManagerArgs)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('ERROR: ', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0Id])

  useEffect(() => {
    if (userId) {
      datadogRum.setUser({
        id: userId.toString(),
        firstName,
        lastName,
        email,
      })
    }
    return () => datadogRum.removeUser()
  }, [email, userId, firstName, lastName])

  // Zendesk Chat Widget
  useEffect(() => {
    const { zE } = window
    if (email && zE) {
      zE('webWidget', 'prefill', {
        name: {
          value: fullName,
        },
        email: {
          value: email,
        },
      })
    }
  }, [pathname, email, fullName])

  // Send user and org information to gtm
  useEffect(() => {
    if (createdAt && email && userId && activeOrg?.id && activeBiz?.id) {
      const dataLayer = {
        event: 'UserChanged',
        organization_created_date: activeOrg.createdAt,
        organization_id: activeOrg.id,
        organization_name: activeOrg.name,
        user_cart_id: userId,
        user_created_date: createdAt,
        business_id: activeBiz.id,
        business_name: activeBiz.name,
        email,
      }
      try {
        TagManager.dataLayer({
          dataLayer,
        })
      } catch (error) {
        log?.error('TagManager.dataLayer UserChanged Event Error', dataLayer, error)
      }
    }
  }, [
    activeBiz?.id,
    activeBiz?.name,
    activeOrg?.createdAt,
    activeOrg?.id,
    activeOrg?.name,
    log,
    userId,
    email,
    createdAt,
  ])

  const pathsToSkipOrgSelectionRoute = noActiveOrganizationPaths.find((path) =>
    pathname.startsWith(path),
  )

  //activeOrg gets set on the Org Selection Page ... hack to check for invites
  if (!activeOrg && !pathsToSkipOrgSelectionRoute) {
    return <OrganizationSelectionRoute />
  }

  if (!siteNavigation || isProvisioningLoading) {
    return <Spinner tw="m-auto h-64 w-64" />
  }

  if (pathsToSkipOrgSelectionRoute && (!activeOrg || !activeBiz)) {
    switch (true) {
      case pathname === '/organization/new':
        return <CreateOrganization />
      default:
        return null
    }
  }

  return (
    <BrandHq>
      <AppRoutes siteNavigation={siteNavigation} />
    </BrandHq>
  )
})
