import { loggerNames } from '@brand-console/utilities'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import GitInfo from 'react-git-info/macro'

const sharedDDConfig = {
  clientToken: process.env.NX_DATADOG_CLIENT_TOKEN,
  env: process.env.NX_DATADOG_ENV,
  sessionSampleRate: 100,
  service: 'brand-console',
  site: 'datadoghq.com',
  trackInteractions: true,
  trackSessionAcrossSubdomains: true,
}

export const DD_RUM = () => {
  const gitInfo = GitInfo()

  datadogRum.init({
    applicationId: process.env.NX_DATADOG_APPLICATION_ID,
    defaultPrivacyLevel: 'mask-user-input',
    version: gitInfo.tags[0],
    ...sharedDDConfig,
  })
}

export const DD_LOGS = () => {
  datadogLogs.init({
    forwardErrorsToLogs: true,
    silentMultipleInit: true,
    useCrossSiteSessionCookie: true,
    ...sharedDDConfig,
  })
  datadogLogs.logger.setLevel('error')
  if (process.env.NX_ENV === 'local') {
    datadogLogs.logger.setHandler('console')
  }
  loggerNames.map((logName) => {
    const logger = datadogLogs.createLogger(logName)
    logger.setLevel('error')
    if (process.env.NX_ENV === 'local') {
      logger.setHandler('console')
    }
  })
}
