import { Button } from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export const CallbackError = ({ errorMessage }) => {
  return (
    <>
      <FontAwesomeIcon
        icon={solid('exclamation-triangle')}
        tw="mx-auto mt-40 mb-6 block h-20 w-20 text-accent-two-700"
      />
      <p tw="mx-auto mb-6 max-w-2xl text-center font-bold text-2xl text-accent-two-700">
        An error occurred while attempting to login
      </p>
      <p tw="mb-2 text-center text-accent-two-700">{errorMessage}</p>
      <div tw="mx-auto pt-8">
        <Button variant="contained" href="/logout">
          Try logging in again
        </Button>
      </div>
    </>
  )
}
