import { Menu } from '../../Menu/Menu'
import { showHideMenuItems } from '../Shared/visibility.helper'
import { uaMenuBase } from './UaMenu.menu'
import { UaMenuIds } from './UaMenu.types'
import { useUaMenuData } from './useUaMenuData'

export const UaMenu = () => {
  const { reportsMenuItems, hasDataConnectors } = useUaMenuData()

  const menu = reportsMenuItems ? uaMenuBase : []
  // enhance menu with options

  if (menu.length) {
    const connectorsMenuVisibility = {
      [UaMenuIds.DATA_CONNECTORS]: hasDataConnectors,
    }

    showHideMenuItems(menu[1].items[0].items, connectorsMenuVisibility)
  }

  return <Menu menuSections={menu} />
}
