import { usecannySsoMutation } from '@brand-console/generated-graphql-hooks'
import { Spinner } from '@cart/ui'
import React, { useEffect } from 'react'
import { createGlobalStyle } from 'styled-components'

const CustomStyles = createGlobalStyle`
  // Hide the Zendesk Launcher
  #launcher {
    display: none;
  }
`

export const Feedback = () => {
  const [error, setError] = React.useState(null)
  const [canny] = usecannySsoMutation()

  useEffect(() => {
    const getToken = async () => {
      try {
        const result = await canny()
        const ssoToken = result.data.org_userCannySso
        const params = {
          companyID: '644d2ef8f48c9709174459da',
          redirect: 'https://cart.canny.io/changelog',
          ssoToken,
        }
        const query = new URLSearchParams(params).toString()
        const url = `https://canny.io/api/redirects/sso?${query}`
        window.location.href = url
      } catch (err) {
        setError(err.toString())
      }
    }
    getToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <CustomStyles />
      <div tw="m-auto mt-20 max-w-5xl px-20 text-center">
        {error && <p tw="text-accent-two-700">{error}</p>}
      </div>
      <Spinner type="global" />
    </>
  )
}
