import React, { FC } from 'react'
import { StyleSheetManager } from 'styled-components'

import { StyledBadge, StyledDot } from './deprecated-badge.styles'
import { DeprecatedBadgeProps } from './deprecated-badge.types'

export const DeprecatedBadge: FC<DeprecatedBadgeProps & Record<string, unknown>> = ({
  className,
  renderRoot,
  showDot = true,
  size = 'medium',
  text,
  type,
}: DeprecatedBadgeProps) => (
  <StyleSheetManager target={renderRoot}>
    <StyledBadge
      data-testid="deprecated-badge"
      type={type}
      size={size}
      text={text}
      className={className}
    >
      {showDot && (
        <StyledDot type={type} text={text}>
          ●
        </StyledDot>
      )}
      {text}
    </StyledBadge>
  </StyleSheetManager>
)

export default DeprecatedBadge
