import { nanoid } from 'nanoid'
import React, { ReactElement } from 'react'

export const FulfillmentIcon = ({ className }: { className?: string }): ReactElement => {
  const title = 'Fulfillment Icon'
  const idA = nanoid()
  const idB = nanoid()
  return (
    <svg
      aria-label={title}
      className={className}
      fill="none"
      role="img"
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11775_76272)">
        <path
          d="M43.5161 5.76415L7.11894 15.6023C5.90537 15.9295 4.95256 16.9011 4.63162 18.1385L0.118329 35.3092C-0.202616 36.5466 0.138388 37.8658 1.03102 38.7658L13.3673 51.3447C14.2499 52.2446 15.5438 52.6026 16.7473 52.2753L53.1445 42.4372C54.358 42.1099 55.3109 41.1384 55.6318 39.901L59.6236 24.7245C60.2755 22.2496 59.5834 19.6214 57.8082 17.8112L46.8961 6.69478C46.0135 5.79483 44.7297 5.43689 43.5161 5.76415Z"
          fill="#DA7009"
        />
        <path
          d="M42.7029 30.564L37.0763 51.9685C36.8256 52.9298 37.6882 53.8093 38.6309 53.5639L51.0976 50.2606C52.4516 49.9027 53.5148 48.8289 53.8758 47.4483L59.7331 25.1745C60.4552 22.4337 59.6829 19.5089 57.7171 17.4942L41.6899 1.16212C40.707 0.170129 39.2929 -0.228714 37.9489 0.129222L25.4922 3.43245C24.5394 3.68812 24.2185 4.89488 24.9105 5.61075L40.2457 21.2474C42.6427 23.6814 43.5755 27.2403 42.7029 30.564Z"
          fill="#069AE3"
        />
        <path
          d="M19.1853 36.9556L13.5588 58.3601C13.308 59.3214 14.1706 60.2112 15.1133 59.9555L29.1045 56.2432C30.4585 55.8853 31.5217 54.8115 31.8827 53.4308L37.74 31.157C38.4621 28.4163 37.6898 25.4914 35.724 23.4768L19.7069 7.13445C18.724 6.13223 17.3098 5.74361 15.9659 6.10155L1.97466 9.82408C1.02185 10.0797 0.700906 11.2865 1.39294 12.0024L16.7281 27.639C19.1252 30.073 20.0579 33.6319 19.1853 36.9556Z"
          fill="#069AE3"
        />
        <path
          d="M37.7384 31.1469C38.4605 28.4061 37.6882 25.4812 35.7224 23.4666L23.6368 11.1434L7.33884 15.5511C6.73706 15.7147 6.18544 16.0215 5.74414 16.4306L16.7365 27.6391C19.1235 30.073 20.0663 33.6319 19.1837 36.9659L15.1619 52.265C15.7436 52.3878 16.3654 52.3775 16.9572 52.2139L33.3655 47.7755L37.7384 31.1469Z"
          fill={`url(#${idA})`}
        />
        <path
          d="M57.4877 17.4942L47.057 6.85845C46.0741 5.85623 44.6398 5.46761 43.3059 5.82555L28.9336 9.71171L40.2469 21.2475C42.6339 23.6814 43.5767 27.2403 42.6941 30.5742L38.5419 46.3848L52.9242 42.4986C54.2782 42.1304 55.3313 41.0566 55.6924 39.6862L59.5036 25.1745C60.2257 22.4235 59.4535 19.4987 57.4877 17.4942Z"
          fill={`url(#${idB})`}
        />
      </g>
      <defs>
        <linearGradient
          id={idA}
          x1="-2.18845"
          y1="23.3078"
          x2="63.1888"
          y2="47.7646"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1225" stopColor="#005982" />
          <stop offset="0.9787" stopColor="#069AE3" />
        </linearGradient>
        <linearGradient
          id={idB}
          x1="2.50513"
          y1="10.7642"
          x2="67.8823"
          y2="35.2211"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1225" stopColor="#005982" />
          <stop offset="0.9787" stopColor="#069AE3" />
        </linearGradient>
      </defs>
    </svg>
  )
}
