import { useRouter } from '@cart/ui'
import { Button } from '@cart/ui/atoms/Button'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'

export const ManageAccountButtonInternal = ({ handleClose }) => {
  const { navigate } = useRouter()
  return (
    <Button
      variant="contained"
      onClick={() => {
        handleClose()
        navigate(`/settings/profile`)
      }}
    >
      Manage Account
    </Button>
  )
}

export const SwitchOrganizationButtonInternal = ({ handleClose }) => {
  const navigate = useNavigate()
  return (
    <Button
      type="button"
      variant="text"
      tw="!(mt-2 justify-start font-sans font-normal) w-full"
      color="monochrome"
      startIcon={
        <FontAwesomeIcon icon={solid('building-circle-arrow-right')} tw="pl-2 !text-base" />
      }
      onClick={() => {
        handleClose()
        navigate(`/organization-selection`)
      }}
    >
      Switch Organization
    </Button>
  )
}
