import { theme } from 'twin.macro'

export const chartsBackgroundColorsScheme = [
  theme`colors.primary.700`,
  theme`colors.accent-four.700`,
  theme`colors.accent-five.700`,
  theme`colors.accent-three.700`,
  theme`colors.secondary.700`,
  theme`colors.accent-two.700`,
  theme`colors.primary.200`,
  theme`colors.accent-four.100`,
  theme`colors.accent-five.100`,
  theme`colors.accent-three.100`,
  theme`colors.secondary.100`,
  theme`colors.accent-two.100`,
]
