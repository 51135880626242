import { useCartAuth, useCurrentContext } from '@cartdotcom/auth'

import {
  ErrorStep,
  IAccountConfirmData,
  OnAccountConfirmFormSubmitProps,
  UseAccountConfirmFormSubmitProps,
} from '../AccountConfirmForm.types'
import { useUpdateBusinessName } from './useUpdateBusinessName'
import { useUpdateUser } from '../../../hooks/useUpdateUser/useUpdateUser'
import { getFriendlyErrorMessage, isNameExistsError } from '@brand-console/utilities/errors'

interface HandleFormErrorProps {
  error: Error
  step: ErrorStep
  data: IAccountConfirmData
  onError?: OnAccountConfirmFormSubmitProps['onError']
}

export const useAccountConfirmFormSubmit = ({
  methods,
  log,
  setErrorMessage,
}: UseAccountConfirmFormSubmitProps) => {
  const {firstName, lastName, phone} = useCartAuth()
  const [updateUser] = useUpdateUser({ log })
  const updateBusinessName = useUpdateBusinessName()
  const { currentOrganization } = useCurrentContext()
  const businessEntityTerminology = currentOrganization?.businessEntityTerminology ?? 'Business'

  const handleFormError = ({ onError, error, step, data }: HandleFormErrorProps) => {
    const { businessName } = data
    const { setError } = methods

    let errorMessage = ''
    let scope = ''
    if (step === ErrorStep.UpdateUser) scope = 'user'
    else if (step === ErrorStep.UpdateBiz) scope = businessEntityTerminology.toLowerCase()

    // Catch business name as field error
    if (isNameExistsError(error.message)) {
      errorMessage = `${businessEntityTerminology} name already exists`
      setError('businessName', {
        type: 'custom',
        message: errorMessage,
      })
    } else {
      errorMessage = getFriendlyErrorMessage(error, scope, {
        name: businessName,
        defaultMessage:
          'There was an error while updating your account information. Please try again, or if the error persists, contact us.',
      })
      setErrorMessage(errorMessage)
      if (onError) onError(error, errorMessage, scope)
    }

    if (log)
      log.error(
        'Lite Registration User Details Form error',
        {
          biz: businessName,
          formData: data,
          friendlyErrorMessage: errorMessage,
        },
        error,
      )
  }

  return async ({ data, onError }: OnAccountConfirmFormSubmitProps) => {
    let error = false

    if (
      firstName !== data.firstName ||
      lastName !== data.lastName ||
      phone !== data.phone
    ) {
      await updateUser({
        variables: {
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phone,
        },
        onError: (err) => {
          handleFormError({
            error: err,
            step: ErrorStep.UpdateUser,
            data,
            onError,
          })
          error = true
        },
      })
    }

    if (error) return false

    await updateBusinessName(data.businessName, (err) => {
      handleFormError({
        error: err,
        step: ErrorStep.UpdateBiz,
        data,
        onError,
      })
      error = true
    })

    if (error) return false

    return true
  }
}
