import { useAuthToken, useCurrentContext } from '@cartdotcom/auth'
import { useEffect, useState } from 'react'

import {
  getAnalyticsReportsMenuItems,
  getVizEnabled,
  hasDataConnectors as hasDataConnectorsFetch,
  isFreeTrial,
} from './queries/ua.queries'
import { logger } from '@brand-console/utilities'

export const useUaMenuData = () => {
  const log = logger
  const { currentOrganization, currentBusiness } = useCurrentContext()
  const token = useAuthToken()
  const [isVizEnabled, setIsVizEnabled] = useState(false)
  const [reportsMenuItems, setReportsMenuItems] = useState(null)
  const [isFreeTrialUser, setIsFreeTrialUser] = useState(false)
  const [hasDataConnectors, setHasDataConnectors] = useState(false)

  useEffect(() => {
    const getOrg = async () => {
      try {
        const isVizEnabledResult = await getVizEnabled(
          token,
          currentOrganization?.id,
          currentBusiness?.id,
        )
        setIsVizEnabled(isVizEnabledResult)
      } catch (error) {
        log?.error(error)
      }
    }
    if (token) {
      getOrg()
    }
  }, [token, currentOrganization?.id, currentBusiness?.id])

  useEffect(() => {
    const fetchUaMenuItems = async () => {
      try {
        const menuItemsResult = await getAnalyticsReportsMenuItems({ token })
        setReportsMenuItems(menuItemsResult)
      } catch (error) {
        log?.error(error)
      }
    }
    if (token) {
      fetchUaMenuItems()
    }
  }, [token])

  useEffect(() => {
    const fetchIsFreeTrialUser = async () => {
      try {
        const isFreeTrialResult = await isFreeTrial({ businessId: currentBusiness?.id, token })
        setIsFreeTrialUser(isFreeTrialResult)
      } catch (error) {
        log?.error(error)
      }
    }
    if (token) {
      fetchIsFreeTrialUser()
    }
  }, [token, currentBusiness?.id])

  useEffect(() => {
    const fetchHasDataConnectors = async () => {
      try {
        const hasDataConnectorsResult = await hasDataConnectorsFetch({
          organizationId: currentOrganization?.id,
          token,
        })
        setHasDataConnectors(hasDataConnectorsResult)
      } catch (error) {
        log?.error(error)
      }
    }
    if (token) {
      fetchHasDataConnectors()
    }
  }, [token, currentOrganization?.id])

  return { isVizEnabled, reportsMenuItems, isFreeTrialUser, hasDataConnectors }
}
