import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'

export const useAuthToken = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [token, setToken] = useState<string | null>(null)

  useEffect(() => {
    const fetchToken = async () => {
      const authToken = await getAccessTokenSilently()
      setToken(authToken)
    }
    fetchToken()
  }, [getAccessTokenSilently])

  return token
}
