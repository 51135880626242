import { useAuth0 } from '@auth0/auth0-react'
import { appendIfValid, logger } from '@brand-console/utilities'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Input } from '@cart/ui'
import { useCartAuth } from '@cartdotcom/auth'
import React, { FC, useState } from 'react'

import { ModalProps } from '../SettingsSecurity.types'

export interface SessionsLogoutModalProps extends ModalProps {
  successCallback: () => void
}
export const SessionsLogoutModal: FC<SessionsLogoutModalProps> = ({
  show,
  successCallback,
  closeCallback,
}) => {
  const log = logger.setLogger(logger.LoggerNames.SECURITY)
  const { getAccessTokenSilently } = useAuth0()
  const { auth0Id, email } = useCartAuth()
  const [showFailureAlert, setShowFailureAlert] = useState(false)
  const [failureMessage, setFailureMessage] = useState('FAILURE MESSAGE')
  const [currentPassword, setCurrentPassword] = useState('')
  const [showButtonSpinner, setShowButtonSpinner] = useState(false)

  const endAllSessions = async () => {
    const token = await getAccessTokenSilently()

    try {
      setShowButtonSpinner(true)
      const response = await fetch(`${process.env.NX_AUTH0_USER_API}/sessions`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-cartid-email': email,
          'x-cartid-sub': auth0Id,
        },
        body: JSON.stringify({
          password: currentPassword,
        }),
      })

      if (!response.ok) {
        throw new Error(`${response.status} - ${await response.text()}`)
      } else {
        // Response is Gucci. Leggo.
        setShowButtonSpinner(false)
        setShowFailureAlert(false)
        // closeCallback()
        successCallback()
      }
    } catch (error) {
      log.error('Sessions Logout error: endAllSessions', error)
      setFailureMessage('')
      setShowFailureAlert(true)
      setShowButtonSpinner(false)
    }
  }

  return (
    <Dialog
      id="end-sessions-dialog"
      open={show}
      onClose={closeCallback}
      title="End all sessions"
      width="sm"
    >
      <DialogTitle>Sign out of all sessions</DialogTitle>
      <DialogContent dividers>
        <div tw="w-full">
          {showFailureAlert && (
            <Alert severity="error" onClose={() => setShowFailureAlert(false)} tw="mb-9">
              {appendIfValid(
                'Something went wrong when attempting to sign you out of all sessions',
                failureMessage,
              )}
            </Alert>
          )}

          <Input
            id="password-confirm"
            type="password"
            placeholder="Enter your password"
            tw="mt-4"
            label="Enter your password"
            value={currentPassword}
            onChange={(e) => {
              setCurrentPassword(e.target.value)
            }}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => closeCallback()}>Cancel</Button>
        <Button
          loading={showButtonSpinner}
          variant="contained"
          onClick={endAllSessions}
          disabled={currentPassword.length < 1}
        >
          Sign out
        </Button>
      </DialogActions>
    </Dialog>
  )
}
