import { FC } from 'react'

import { StyledDialogContent } from './dialog-content.styles'
import { DialogContentProps } from './dialog-content.types'

export const DialogContent: FC<DialogContentProps> = ({ children, ...props }) => (
  <StyledDialogContent className="scrollable-mask" {...props}>
    {children}
  </StyledDialogContent>
)
