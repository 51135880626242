import { gql } from 'graphql-request'

export const mutation_sessionEnd = gql`
  mutation logout_sessionEnd {
    org_sessionEnd {
      isSessionValid
    }
  }
`
export const mutation_userAppSettingUpsert = gql`
  mutation Org_userAppSettingUpsert($input: UpsertUserAppSettingInput!) {
    org_userAppSettingUpsert(input: $input) {
      id
    }
  }
`
