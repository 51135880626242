import { Card, Table } from '@cart/ui'
import { HTMLProps } from 'react'
import tw, { css, styled, theme } from 'twin.macro'

const highlightedColumnDropshadowWidth = 35

const highlightedColumn = (activeColumn: number) => `
  &:nth-child(${activeColumn}) {
    position: relative;
    background: ${theme('colors.monochrome.50')};
    & + div {
      border-left: 1px solid ${theme('colors.monochrome.50')};
      background: transparent;
    }
    &:before {
      z-index: 1;
      box-shadow: -${highlightedColumnDropshadowWidth}px 0 ${highlightedColumnDropshadowWidth}px -${highlightedColumnDropshadowWidth}px rgba(0, 0, 0, 0.2) inset;
      content: ' ';
      left: -${highlightedColumnDropshadowWidth}px;
      position: absolute;
      top: 0;
      bottom: -1px;
      width: ${highlightedColumnDropshadowWidth}px;
      border-right: 1px solid ${theme('colors.monochrome.50')};
    }
  }
  &:nth-child(${activeColumn}):not(:last-child):after {
    z-index: 1;
    box-shadow: ${highlightedColumnDropshadowWidth}px 0 ${highlightedColumnDropshadowWidth}px -${highlightedColumnDropshadowWidth}px rgba(0, 0, 0, 0.2) inset;
    content: ' ';
    position: absolute;
    top: -1px;
    bottom: -1px;
    right: -${highlightedColumnDropshadowWidth}px;
    width: ${highlightedColumnDropshadowWidth}px;
    border-left: 1px solid ${theme('colors.monochrome.50')};
  }
`

export const StyledCardContainer = styled.div(() => [
  tw`flex w-full flex-col flex-wrap py-4 px-2 sm:(w-1/2 px-4) md:py-6 lg:w-1/4`,
])

export const StyledCard = styled(Card)((props) => [
  tw`flex-auto flex cursor-pointer flex-col py-10 px-2 shadow-md sm:py-6`,
  props['data-active'] && [
    tw`sm:(-translate-y-2 scale-105)`,
    css`
      outline: 2px solid ${theme('colors.primary.700')};
    `,
  ],
  css`
    transition: transform 0.3s ease-in-out, translate 0.3s ease-in-out;
    & > div {
      ${tw`h-full`}
    }
  `,
])

export const StyledCardContent = styled.div(() => [
  tw`flex w-full flex-col text-center [& > h2]:(font-bold tracking-tight)`,
])

export const StyledCardPrice = styled.div(() => [
  tw`mb-4 font-bold text-3xl text-primary-700 tracking-tighter`,
])

export const StyledCardInfo = styled.div(() => [tw`mb-4 flex-auto w-full text-base leading-6`])

export const StyledTable = styled(Table)((props: HTMLProps<HTMLElement>) => [
  css`
    & [role='row'] > [role='columnheader']:first-child {
      ${tw`!border-r-monochrome-500 border-solid`}
    }
    & [role='row'] > [role='cell']:first-child {
      ${tw`border-r-monochrome-500 md:![z-index: 2] lg:![z-index: 1]`}
    }
    & > section > div,
    & > section > div > div {
      ${tw`lg:overflow-visible`}
      &::-webkit-scrollbar {
        display: none;
      }
    }

    & [role='table'] > [role='rowgroup']:first-child > [role='row'] {
      ${tw`rounded-t-md border-monochrome-500 border-t`}
      & > div:first-child {
        ${tw`border-monochrome-500 border-r lg:border-r-0`}
      }
    }
    & [role='table'] > [role='rowgroup']:last-child > [role='row']:last-child {
      ${tw`rounded-tr-md border-monochrome-500 border-b`}
    }
    & [role='columnheader'],
    & [role='cell'] {
      ${tw`bg-monochrome-50`}
    }

    & [role='columnheader'] {
      &:first-child {
        ${tw`rounded-tl-md border-none`}
      }
      &:last-child {
        ${tw`rounded-tr-md`}
      }
    }

    & [role='row'] {
      box-shadow: none;
      & > div:first-child {
        ${tw`border-monochrome-500 border-r border-l-0 lg:border-r-0`}
      }
      & [role='columnheader'] {
        ${tw`![z-index:2]`}
        &[data-sticky-td="true"] {
          ${tw`border-l border-l-monochrome-50 !(bg-monochrome-50 [z-index:3]) lg:![z-index: 2]`}
        }
        &:nth-child(${props['data-active-column']}) {
          box-shadow: 0 -5px 10px 0px rgb(0 0 0 / 10%);
        }
        &:not(:first-child) {
          ${tw`border-monochrome-500 border-l border-solid`};
          ${highlightedColumn(props['data-active-column'])}
        }
      }
      & [role='cell'] {
        ${tw`items-center !border-monochrome-500 border-l border-t`}
        ${highlightedColumn(props['data-active-column'])}
        &[data-sticky-td="true"] {
          ${tw`mobile:![z-index:4]`}
        }
        &:first-child {
          ${tw`font-bold font-heading`}
        }
      }
    }
  `,
])

export const StyledSelectedPlanInnerContainer = styled.div(() => [tw`p-5`])

export const selectedPlanTransitionProps = {
  enter: tw`transition-transform duration-300 ease-in-out`,
  enterFrom: tw`scale-50`,
  enterTo: tw`scale-100`,
  leave: tw`transition-all duration-300 ease-in-out`,
  leaveFrom: tw`scale-100`,
  leaveTo: tw`scale-50 opacity-0`,
}
