import MuiAccordion from '@mui/material/Accordion'
import { FC } from 'react'

import { AccordionProps } from './accordion.types'

export const Accordion: FC<AccordionProps> = ({ children, color = 'primary', ...props }) => {
  return (
    <MuiAccordion color={color} {...props}>
      {children}
    </MuiAccordion>
  )
}
