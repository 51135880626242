import React, { createContext, ReactNode, useContext, useState } from 'react'

export interface AlertType {
  variant?: 'info' | 'error' | 'warning' | 'success' | undefined
  text: string
}

export interface SettingsOrganizationsStore {
  alert?: AlertType
  routedBusiness?: IRoutedBusiness
  clearAlert?: () => void
}

export interface IRoutedBusiness {
  id: number
  name: string
}

export type UseSettingsOrganizationsType = ReturnType<typeof useSettingsOrganizations>

const useSettingsOrganizations = (initial: SettingsOrganizationsStore = { alert: undefined }) => {
  const [store, setStore] = useState(initial)
  return {
    alert: store.alert,
    clearAlert: () => setStore({ ...store, alert: undefined }),
    routedBusiness: store.routedBusiness,
    setAlert: ({ variant, text }) => setStore({ ...store, alert: { variant, text } }),
    setRoutedBusiness: (business: IRoutedBusiness) =>
      setStore({ ...store, routedBusiness: business }),
  }
}

export const SettingsOrganizationsContext = createContext<UseSettingsOrganizationsType | null>(null)

export const useSettingsOrganizationsContext = () => useContext(SettingsOrganizationsContext)

export const SettingsOrganizationsProvider = ({ children }: { children: ReactNode }) => (
  <SettingsOrganizationsContext.Provider value={useSettingsOrganizations()}>
    {children}
  </SettingsOrganizationsContext.Provider>
)
