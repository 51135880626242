import { Breadcrumbs } from '@cart/ui'
import React, { ReactElement, useEffect } from 'react'
import TagManager from 'react-gtm-module'

export const GrowthCapitalRoute = (): ReactElement => {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        route: 'GrowthCapital',
      },
    })
  }, [])

  return (
    <main tw="mx-auto w-full max-w-screen-xl px-3.5">
      <Breadcrumbs
        tw="mb-5 mt-7 ml-9"
        items={[{ text: 'Home', href: '/' }, { text: 'Growth Capital' }]}
      />
      <div id="marketplace-services-overview" tw="mb-14">
        <iframe
          title="clearco"
          referrerPolicy="same-origin"
          loading="eager"
          src="https://my.clearbanc.com/partners/Cart-Com/signup-capital?logo_options=both"
          height="800"
          width="100%"
          style={{ border: '1px solid #f7f7f7' }}
        />
      </div>
    </main>
  )
}
