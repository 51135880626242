import { Spinner } from '@cart/ui'
import { Card } from '@mui/material'
import tw, { styled } from 'twin.macro'

export const StyledServiceCard = styled(Card)(({ 'aria-selected': selected }) => [
  tw`cursor-pointer ![border-width: 1px] px-5 py-4 [& label]:cursor-pointer`,
  tw`[&:hover]:(bg-monochrome-50)`,
  selected && tw`border-2 border-primary-700 !bg-primary-12`,
])

export const StyledDialogBody = styled.div(() => [
  tw`flex w-full flex-col items-center px-6 text-center mobile:px-1`,
])

export const StyledSpinner = styled(Spinner)(() => [tw`m-auto mt-12 mb-8 h-64 w-64`])
