import { RptMenuIds } from './RptMenu.types'

export const rptMenu = [
  {
    id: RptMenuIds.SHARED,
    hidden: true,
    items: [],
  },
  {
    id: RptMenuIds.GROWTH_MARKETING,
    title: 'Growth Marketing',
    hidden: true,
    items: [],
  },
  {
    id: RptMenuIds.MARKETPLACE_SERVICES,
    title: 'Marketplace Services',
    hidden: true,
    items: [],
  },
  {
    id: RptMenuIds.SETTINGS,
    title: 'Settings',
    items: [],
  },
]
