import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import tw, { css, styled } from 'twin.macro'

export const StyledMenu = styled(Menu)(() => [
  css`
    & > .MuiPaper-root {
      ${tw`![max-width: calc(100vw - 50px)] [width: 385px] mt-2 flex flex-wrap overflow-hidden`}
      & > .MuiMenu-list {
        ${tw`w-full`}
      }
    }
  `,
])

export const StyledDivider = styled(Divider)(() => [tw`bg-monochrome-900/[.15]`])

export interface StyledMenuSectionProps {
  borderTop?: boolean
  borderBottom?: boolean
}
export const StyledMenuSection = styled.div<StyledMenuSectionProps>(
  ({ borderTop, borderBottom }) => [
    borderTop ? tw`border-monochrome-900/[.15] border-t` : '',
    borderBottom ? tw`border-monochrome-900/[.15] border-b` : '',
  ],
)

export const StyledMenuSectionContent = styled(StyledMenuSection)(() => [tw`px-2 py-4`])

export const StyledMenuTitle = styled.div(() => [
  tw`px-4 font-sans font-normal text-monochrome-900/[0.65] text-xs uppercase leading-8 tracking-wider`,
])
