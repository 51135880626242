import { AdChannelInterestOpts, ContactPreferenceTypeOpts, SalesChannelInterestOpts } from '@brand-console/types'
import { hubspotFormSubmit, LoggerNames } from '@brand-console/utilities'
import { Dropdown, DropdownItem } from '@cart/ui'
import { useCartAuth, useCurrentContext } from '@cartdotcom/auth'
import { ReactElement } from 'react'
import TagManager from 'react-gtm-module'
import { $enum } from 'ts-enum-util'
import { useForm } from 'react-hook-form'

import { IAccountConfirmData } from '../AccountConfirmForm'
import { BaseServiceRequestForm } from './BaseServiceRequestForm'

export interface GrowthMarketingFormData {
  contactPreference
  serviceInterest
  adChannelInterest
  salesChannelInterest
}
export const GrowthMarketingForm = (): ReactElement => {
  const { currentBusiness, currentOrganization } = useCurrentContext()
  const methods = useForm({ mode: 'onChange' })
  const { email, phone } = useCartAuth()

  const { getValues } = methods

  const createMenuItemsFromEnum = (theEnum, stateKey: string) =>
    $enum(theEnum).map((value) => {
      let finalLabel = value
      switch (value.toLocaleLowerCase()) {
        case 'email':
          finalLabel = `${value}: ${email}`
          break
        case 'phone':
          finalLabel = `${value}: ${phone}`
          break
        default:
          break
      }
      return <DropdownItem key={`${stateKey}-${value}`} text={finalLabel} value={value} />
    })

  const sendGAFormEvent = (eventKey: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'formQuestion_filled',
        results: {
          formType: 'Growth Marketing Service Request',
          [eventKey]: getValues()[eventKey],
        },
      },
    })
  }

  const onSubmit = async (data: IAccountConfirmData & GrowthMarketingFormData) => {
    const {
      contactPreference,
      adChannelInterest,
      salesChannelInterest,
      firstName,
      lastName,
      businessName,
      orgName
    } = data

    const serviceInterest = 'Growth Marketing'

    TagManager.dataLayer({
      dataLayer: {
        event: 'form_submit',
        results: { formType: 'Growth Marketing Service Request', ...data },
      },
    })

    const hsForm = await hubspotFormSubmit({
      formId: '3948b829-d655-42e4-bb6a-a8a97ddafe0e',
      data: {
        email,
        firstname: firstName,
        lastname: lastName,
        console_organization_name: orgName,
        console_organization_id: currentOrganization.id,
        console_business_id: currentBusiness.id,
        console_business_name: businessName,
        service_interest__c: serviceInterest,
        digital_ad_channels_interest__c: adChannelInterest,
        sales_channel_interest__c: salesChannelInterest,
        contact_preference__c: contactPreference,
      },
      portalId: parseInt(process.env.NX_HUBSPOT_MARKETING_FORMS_PORTAL_ID, 10),
      pageName: 'Brand Console - Growth Marketing Trial Creation',
    })

    return !!hsForm
  }

  return (
    <BaseServiceRequestForm
      formName="Growth Marketing Service"
      logName={LoggerNames.GROWTH_MARKETING}
      onSubmit={onSubmit}
      methods={methods}
    >
      <li tw="mb-6">
        <div tw="mb-2 font-bold">
          What digital ad channels are of greatest interest to your brand? *
        </div>
        <Dropdown
          id="adChannelInterest"
          multipleSelection
          onChange={() => sendGAFormEvent('adChannelInterest')}
          placeholder="Select all that apply"
          required
        >
          {createMenuItemsFromEnum(
            AdChannelInterestOpts,
            'adChannelInterest',
          )}
        </Dropdown>
      </li>
      <li tw="mb-6">
        <div tw="mb-2 font-bold">
          Which sales channels are of greatest interest to your brand? *
        </div>
        <Dropdown
          id="salesChannelInterest"
          multipleSelection
          onChange={() => sendGAFormEvent('salesChannelInterest')}
          placeholder="Select all that apply"
          required
        >
          {createMenuItemsFromEnum(
            SalesChannelInterestOpts,
            'salesChannelInterest',
          )}
        </Dropdown>
      </li>
      <li tw="mb-6">
        <div tw="mb-2 font-bold">How would you prefer to be contacted? *</div>
        <Dropdown
          id="contactPreference"
          multipleSelection
          onChange={() => sendGAFormEvent('contactPreference')}
          placeholder="Select all that apply"
          required
        >
          {createMenuItemsFromEnum(ContactPreferenceTypeOpts, 'contactPreference')}
        </Dropdown>
      </li>
    </BaseServiceRequestForm>
  )
}
