import { useReactiveVar } from '@apollo/client'
import {
  activeOrgVar,
  useUAAmazonAdsGetProfilesQuery,
  useUACatalogListQuery,
  useUACreateDataConnectorMutation,
  useUAdataConnectorAuthUrlLazyQuery,
} from '@brand-console/generated-graphql-hooks'
import { logger } from '@brand-console/utilities'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { processErrorMessage } from '../../../helpers/functions'
import { useAppDispatch } from '../../../stores/hooks/hooks'
import { displayAlert } from '../../../stores/reducers/alertPropsSlice'
import { setCurrentView } from '../../../stores/reducers/dataConnectorsSlice'
import { RootState } from '../../../stores/root'
import { dataConnectorsFields } from '../data'
import { FormModal } from './components/Modal/FormModal'
import { SetUpModal } from './components/Modal/SetUpModal'

export const MyConnectorsModal = ({ show, onClose, packageTier, packageDataConnectorsList }) => {
  const activeOrg = useReactiveVar(activeOrgVar)
  const log = logger.setLogger(logger.LoggerNames.UNIFIED_ANALYTICS)
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [shopNameError, setShopNameError] = useState<string>()
  const [shopName, setShopName] = useState<string>()

  const selectedConnector = useSelector(
    (state: RootState) => state.dataConnectors.selectedConnector,
  )
  const connectorCodes = useSelector((state: RootState) => state.dataConnectors.connectorsData)
  const currentView = useSelector((state: RootState) => state.dataConnectors.currentView)
  const amazonAdsCode = useSelector(
    (state: RootState) => state.dataConnectors.connectorsData?.amazonAdsCode,
  )
  const amazonAdsState = useSelector(
    (state: RootState) => state.dataConnectors.connectorsData?.amazonAdsState,
  )
  const { data: catalog } = useUACatalogListQuery()

  const { loading: amazonAdsProfilesLoading, data: amazonAdsProfilesData } =
    useUAAmazonAdsGetProfilesQuery({
      variables: {
        state: amazonAdsState,
        code: amazonAdsCode,
      },
      skip: selectedConnector !== 'amazon-advertising',
    })

  const [getDataConnectorAuthUrl] = useUAdataConnectorAuthUrlLazyQuery()
  const [createDataConnector, { reset }] = useUACreateDataConnectorMutation({
    refetchQueries: ['UADataConnectorsList'],
    onCompleted: (data) => {
      if (selectedConnector === 'shopify') {
        getDataConnectorAuthUrl({
          variables: {
            state: data.ua_CreateDataConnector.instance.uuidId,
            shop: shopName && shopName,
            connectorType: dataConnectorsFields[selectedConnector].type,
          },
        })
      }
    },
  })

  const handleFormSubmit = (values) => {
    let data
    switch (selectedConnector) {
      case 'google-adwords':
        data = {
          customerId: values.customerId.replace(/[^0-9]/g, ''),
          loginCustomerId: values?.loginCustomerId?.replace(/[^0-9]/g, ''),
          business: values.business,
          startDate: values.startDate,
          code: connectorCodes.googleCode,
          state: connectorCodes.googleState,
        }
        break
      case 'google-analytics':
        data = {
          ...values,
          code: connectorCodes.googleCode,
          state: connectorCodes.googleState,
          startDate: values.startDate,
        }
        break
      case 'google-analytics-4':
        data = {
          businessId: values.businessId,
          code: connectorCodes.googleCode,
          propertyId: values.propertyId,
          state: connectorCodes.googleState,
          dateRangesStartDate: values.startDate,
        }
        break

      case 'google-search-console':
        data = {
          ...values,
          siteUrls: [values.siteUrls],
          code: connectorCodes.googleCode,
          state: connectorCodes.googleState,
        }
        break
      case 'facebook':
        data = {
          ...values,
          startDate: values.startDate,
          accessToken: connectorCodes.facebookAccessToken,
        }
        break
      case 'amazon-advertising':
        data = {
          ...values,
          refreshToken: amazonAdsProfilesData?.ua_AmazonAdsGetProfiles?.refreshToken,
          profiles: values.profiles.map((profile) => profile.profile_id),
        }
        break
      case 'shopify':
        data = {
          ...values,
          code: connectorCodes.shopifyData.code && connectorCodes.shopifyData.code,
          activationCode:
            connectorCodes.shopifyData.activationCode && connectorCodes.shopifyData.activationCode,
        }
        break
      case 'klaviyo':
        data = {
          startDate: values.startDate,
          apiKey: values.apiKey,
        }
        break
      case 'tiktok':
        data = {
          ...values,
          code: connectorCodes.tiktokAuthData.code,
          state: connectorCodes.tiktokAuthData.state,
        }
        break
      case 'hubspot':
        data = {
          startDate: values.startDate,
        }
        break
      case 'amazon-seller-central':
        data = {
          ...values,
          startDate: values.startDate,
        }
        break
      default:
        data = values
    }

    const catalogConnector = catalog?.ua_catalog.find(
      (item) => dataConnectorsFields[selectedConnector].type === item.title,
    )

    data = catalogConnector?.isConcierge
      ? {
          ...data,
          dataconnectorTypeId: Number(catalogConnector.id),
          dataConnectorType: 'ConciergeDataConnector',
        }
      : { ...data, dataConnectorType: dataConnectorsFields[selectedConnector].type }

    createDataConnector({
      variables: {
        organizationId: activeOrg?.id.toString(),
        ...data,
        businessId: Number(values.business),
      },
    })
      .then(() => {
        if (location.pathname.includes('/all-connectors')) {
          navigate('/unified-analytics/my-connectors/')
        }
        localStorage.setItem('connector_name', '')
        onClose()
        dispatch(setCurrentView(1))
      })
      .catch((createError: FetchBaseQueryError | SerializedError | undefined) => {
        log.error(`Error creating ${selectedConnector}: ${createError}`)
        dispatch(
          displayAlert({
            heading: 'Error while creating data connector',
            type: 'error',
            text: processErrorMessage(createError),
          }),
        )
        // TODO: remove calls to localStorage
        localStorage.removeItem('connector_name')
        onClose()
        reset()
      })
  }
  const handleShopifyFormSubmit = async (values) => {
    setShopName(values.activationCode)
    try {
      await createDataConnector({
        variables: {
          organizationId: activeOrg?.id.toString(),
          ...values,
          activationCode: values.activationCode && values.activationCode,
          dataConnectorType: dataConnectorsFields[selectedConnector].type,
          businessId: Number(values.business),
        },
      })
      onClose()
    } catch (shopifyError) {
      if ('status' in shopifyError && shopifyError.status === 403) {
        setShopNameError('The shop name you entered is already registered within Cart.com')
      } else {
        log.error(`Error creating Shopify connector: ${shopifyError.message}`)
        onClose()
      }
    }
  }
  if (currentView === 1) {
    return (
      <SetUpModal
        show={show}
        onClose={onClose}
        packageTier={packageTier}
        packageDataConnectorsList={packageDataConnectorsList}
      />
    )
  }
  if (currentView === 2 || currentView === 3) {
    return (
      <FormModal
        show={show}
        onClose={onClose}
        packageTier={packageTier}
        amazonAdsProfilesLoading={amazonAdsProfilesLoading}
        amazonAdsProfilesData={amazonAdsProfilesData}
        selectedConnector={selectedConnector}
        connectorCodes={connectorCodes}
        shopNameError={shopNameError}
        handleFormSubmit={handleFormSubmit}
        handleShopifyFormSubmit={handleShopifyFormSubmit}
      />
    )
  }

  return null
}
