import React, { FC, useEffect, useState } from 'react'
import { StyleSheetManager } from 'styled-components'

import {
  StyledCheckbox,
  StyledCheckboxCheckmark,
  StyledCheckboxInput,
  StyledCheckboxLabel,
  StyledSROnlyLabel,
} from './deprecated-checkbox.styles'
import { DeprecatedCheckboxProps } from './deprecated-checkbox.types'

export const DeprecatedCheckbox: FC<DeprecatedCheckboxProps & Record<string, unknown>> = ({
  checked,
  className,
  disabled,
  id,
  label,
  onChange,
  renderRoot,
  smallLabel,
  value,
}: DeprecatedCheckboxProps) => {
  const [currentState, setCurrentState] = useState<string>()

  useEffect(() => {
    let initialState
    if (typeof checked === 'undefined') {
      initialState = 'indeterminate'
    } else if (checked) {
      initialState = 'checked'
    } else {
      initialState = 'unchecked'
    }
    setCurrentState(initialState)
  }, [checked])

  const handleCheckboxChange = (e: MouseEvent) => {
    if (disabled) return
    setCurrentState((current) => (current === 'checked' ? 'unchecked' : 'checked'))
    if (onChange) {
      onChange(e)
    }
  }

  return (
    <StyleSheetManager target={renderRoot}>
      <StyledCheckbox disabled={disabled} onClick={handleCheckboxChange} className={className}>
        <StyledCheckboxInput
          disabled={disabled}
          id={id}
          name={id}
          data-testid="checkbox"
          type="checkbox"
          value={value}
        />
        <StyledCheckboxCheckmark
          className="checkmark"
          disabled={disabled}
          state={currentState}
          data-testid={`checkbox-checkmark-${id}`}
        />
        <StyledSROnlyLabel htmlFor={id} data-testid="checkbox-label">
          {label}
        </StyledSROnlyLabel>
        <StyledCheckboxLabel hasSmallLabel={smallLabel} data-testid={`checkbox-label-dt-${id}`}>
          {label}
          {smallLabel && <small>{smallLabel}</small>}
        </StyledCheckboxLabel>
      </StyledCheckbox>
    </StyleSheetManager>
  )
}

export default DeprecatedCheckbox
