import {
  useBusinessCreateMutation,
  useOrganizationRoleListQuery,
} from '@brand-console/generated-graphql-hooks'
import { Role } from '@brand-console/types'
import { checkIfPhrasesExist, logger } from '@brand-console/utilities'
import { Alert, Button, DeprecatedDialog, Input } from '@cart/ui'
import { useCurrentContext } from '@cartdotcom/auth'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { useSettingsOrganizationsContext } from '../SettingsOrganizations.store'

const { Body, Actions } = DeprecatedDialog

interface ICreateBusiness {
  close: (shouldReload?: boolean) => void
}

export const CreateBusiness = ({ close }: ICreateBusiness): ReactElement => {
  const log = logger.setLogger(logger.LoggerNames.ORGANIZATION)
  const methods = useForm()
  const { setAlert } = useSettingsOrganizationsContext()
  const { currentOrganization, setNewBusiness } = useCurrentContext()

  const [roles, setRoles] = useState<Role[]>()
  const { businessEntityTerminology } = currentOrganization
  const [validationError, setValidationError] = useState<string>()
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false)
  const { handleSubmit, trigger } = methods

  const { data: roleList } = useOrganizationRoleListQuery()

  useEffect(() => {
    if (roleList) {
      setRoles(roleList.org_roleList.data as Role[])
    }
  }, [roleList])

  const [createBusiness, { loading, reset }] = useBusinessCreateMutation()

  const onSubmit = useCallback(
    (formData) => {
      setShowErrorAlert(false)
      const { businessName: name } = formData
      setValidationError(undefined)

      createBusiness({
        variables: {
          createBusinessInput: {
            name,
            organizationId: currentOrganization.id,
          },
        },
        onCompleted({ org_businessCreate }) {
          setAlert({
            variant: 'success',
            text: `${businessEntityTerminology} ${name} was created successfully!`,
          })
          setNewBusiness(
            {
              id: org_businessCreate.id,
              name: org_businessCreate.name,
              roleName: org_businessCreate.assignedRoles?.[0]?.role.name,
            },
            currentOrganization.id,
          )

          close(true)
          setTimeout(() => window.location.reload(), 100)
        },
        onError(error) {
          log.error('CreateBusiness GraphQL error', error)

          if (
            checkIfPhrasesExist(
              ['already exists', 'Unique constraint failed on the fields'],
              error.message,
              true,
            )
          ) {
            setValidationError(
              `This name is already being used by another ${businessEntityTerminology.toLowerCase()} within this organization`,
            )
            trigger()
          } else {
            setShowErrorAlert(true)
          }
          reset()
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      createBusiness,
      reset,
      trigger,
      roles,
      businessEntityTerminology,
      close,
    ],
  )

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <Body>
          <div>
            {showErrorAlert && (
              <Alert severity="error" tw="mb-4" onClose={() => setShowErrorAlert(false)}>
                There was an error when trying to create the business
              </Alert>
            )}
            <h2 tw="mb-2 text-lg">Name your {businessEntityTerminology}</h2>
            <p>
              You can think of {businessEntityTerminology} as projects inside which you can add multiple services and
              users.
            </p>
            <p tw="mb-2">
              {businessEntityTerminology} names have to be unique within the organization.
            </p>
            <Input
              id="businessName"
              required
              inputProps={{ maxLength: 75 }}
              placeholder={`${businessEntityTerminology} Name`}
              error={!!validationError}
              helperText={validationError}
              onBlur={() => setValidationError(undefined)}
            />
          </div>
        </Body>
        <Actions>
          <Button type="submit" size="medium" variant="contained" disabled={loading}>
            Create {businessEntityTerminology}
          </Button>
        </Actions>
      </form>
    </FormProvider>
  )
}
