import { useReactiveVar } from '@apollo/client'
import { activeBizVar } from '@brand-console/generated-graphql-hooks'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Button,
  CardContent,
  CardFooter,
  CardHeader,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  StatusBadge,
  TabItem,
  Tabs,
  TabsProvider,
  useBreakpoint,
} from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Typography from '@mui/material/Typography'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useCallback, useState } from 'react'

import { UAPackages } from './SettingsUnifiedAnalytics.constants'
import { PriceLabel } from './SettingsUnifiedAnalytics.partials'
import { StyledPackagesContainer } from './SettingsUnifiedAnalytics.styles'
import { UnifiedAnalyticsForm } from './UnifiedAnalyticsForm'

export const SettingsUnifiedAnalytics = () => {
  const activeBiz = useReactiveVar(activeBizVar)
  const [showModal, setShowModal] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const toggleModal = useCallback(() => setShowModal(!showModal), [showModal])
  const { uaTempUpdateChannelsPackage } = useFlags()
  const isLarge = useBreakpoint('lg')

  // TODO: when backend team fixes useUApackageDataConnectorsListQuery
  // (currently, it's returning null), we need to add current package
  // in the UI as seen in the figma:
  // https://www.figma.com/file/FpoFiITxEasjRfMlhnbtRe/UA-Onboarding?type=design&node-id=848%3A50178&t=KgrxjzDBmfdziEMn-1
  return (
    <main tw="m-auto max-w-5xl px-3 sm:px-20">
      {success && (
        <Alert tw="mb-2" severity="success" onClose={() => setSuccess(false)}>
          <AlertTitle>Success</AlertTitle>
          Request submitted!
        </Alert>
      )}
      {error && (
        <Alert tw="mb-2" severity="error" onClose={() => setError(false)}>
          <AlertTitle>Error</AlertTitle>
          There was an error while processing your request. It&lsquo;s possible a browser extension
          is interfering with the form submission.
        </Alert>
      )}
      <div tw="mb-18 w-full">
        <TabsProvider>
          <h2 tw="pb-2">Unified Analytics Packages</h2>
          <Tabs root="/settings/unified-analytics" tw="pt-3">
            <TabItem index path="standalone" label="Standalone Packages">
              <StyledPackagesContainer type="standalone">
                {UAPackages.standAlone.map((packageTier) => (
                  <Accordion disableGutters expanded={isLarge ? true : undefined}>
                    <AccordionSummary expandIcon={<FontAwesomeIcon icon={solid('angle-down')} />}>
                      <CardHeader
                        title={<Typography variant="h5">{packageTier.title}</Typography>}
                        subheader={packageTier.subtitle}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <CardContent>
                        <div className="pkg-features">
                          <div>
                            {packageTier.features.map((feature) => (
                              <div className="pkg-tier-title" key={feature.title}>
                                <FontAwesomeIcon icon={solid('circle-check')} />
                                {feature.title}
                                {feature.comingSoon && (
                                  <StatusBadge type="primary" badgeContent="Coming Soon" />
                                )}
                              </div>
                            ))}
                          </div>
                          <p className="annual-rev">What is your annual revenue?</p>
                          {packageTier.gmv.map((gmv) => (
                            <PriceLabel key={gmv.price} priceData={gmv} />
                          ))}
                        </div>
                      </CardContent>
                      <CardFooter>
                        <Button onClick={toggleModal} fullWidth variant="outlined">
                          Get a Quote
                        </Button>
                      </CardFooter>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </StyledPackagesContainer>
            </TabItem>
            <TabItem path="co-sell" label="Co-sell Packages">
              <p tw="mb-7">
                Data Products from Cart.com are a differentiator of our platform. We commonly take
                pieces of UA product and co-sell it with other areas of cart.com, offering you a
                discount for being an active customer. In order to qualify for these packages you
                must be an active customer on one these Cart.com offerings (see co-sell offering
                links below).
              </p>
              <StyledPackagesContainer type="cosell">
                {UAPackages.coSell.map((packageTier) => (
                  <Accordion disableGutters expanded={isLarge ? true : undefined}>
                    <AccordionSummary expandIcon={<FontAwesomeIcon icon={solid('angle-down')} />}>
                      <CardHeader
                        title={
                          <Typography variant="h5">
                            {packageTier.title}
                            {!uaTempUpdateChannelsPackage &&
                              packageTier.title === 'Channels Analytics' && (
                                <Chip
                                  color="primary"
                                  variant="outlined"
                                  size="small"
                                  label="Coming Soon"
                                />
                              )}
                          </Typography>
                        }
                        subheader={
                          <>
                            <p className="pkg-subheader">
                              Co-sell offering:
                              <Link href={packageTier.link.link}>{packageTier.link.title}</Link>
                            </p>
                            <p>{packageTier.subtitle}</p>
                          </>
                        }
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <CardContent>
                        <div className="pkg-features">
                          <div>
                            {packageTier.features.map((feature) => (
                              <div className="pkg-tier-title" key={feature.title}>
                                <FontAwesomeIcon icon={solid('circle-check')} />
                                {feature.title}
                                {feature.comingSoon &&
                                  (uaTempUpdateChannelsPackage ||
                                    packageTier.title !== 'Channels Analytics') && (
                                    <StatusBadge
                                      type="primary"
                                      tw="whitespace-nowrap"
                                      badgeContent="Coming Soon"
                                    />
                                  )}
                              </div>
                            ))}
                          </div>

                          {packageTier.priceRange.length > 0 &&
                            packageTier.priceRange.map((priceRange) => (
                              <PriceLabel key={priceRange.price} priceData={priceRange} />
                            ))}
                          {packageTier.pricing && (
                            <div key={packageTier.pricing.value} tw="flex items-center">
                              <p tw="mr-1 font-semibold lg:[font-size: 20px]">
                                {packageTier.pricing.value}
                              </p>
                              <p>{packageTier.pricing.text}</p>
                            </div>
                          )}
                        </div>
                      </CardContent>
                      <CardFooter>
                        <Button onClick={toggleModal} fullWidth variant="outlined">
                          Get a Quote
                        </Button>
                      </CardFooter>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </StyledPackagesContainer>
            </TabItem>
          </Tabs>
        </TabsProvider>
        <Dialog id="ua-package-request-dialog" open={showModal} onClose={toggleModal} width="md">
          <DialogTitle>Get a Quote Unified Analytics Packages</DialogTitle>
          <DialogContent>
              <UnifiedAnalyticsForm
                activeBiz={activeBiz}
                toggleModal={toggleModal}
                setError={setError}
                setSuccess={setSuccess}
              />
          </DialogContent>
        </Dialog>
      </div>
    </main>
  )
}
