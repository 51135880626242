// common
import { Button, DeprecatedDialog } from '@cart/ui'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import tw from 'twin.macro'

import { ReactComponent as StarSvg } from '../../../../../assets/icons/star.svg'
import { ScrollbarStyled } from '../../../../../components/atoms/ScrollBarStyled/ScrollBarStyled'
import { SearchInput } from '../../../../../components/atoms/SearchInput/SearchInput'
import { upgradeResponse } from '../../../../../helpers/functions'
import { useMyConnectorsData } from '../../../../../hooks/useMyConnectorsData/useMyConnectorsData'
import { useAppDispatch } from '../../../../../stores/hooks/hooks'
import { selectConnector } from '../../../../../stores/reducers/dataConnectorsSlice'
import { RootState } from '../../../../../stores/root'
import {
  useGetDataConnectorsCardQuery,
  useGetDataConnectorsQuery,
} from '../../../../../stores/services/dataConnectorEndpoints'
import { ButtonSetup } from './ButtonSetup'

export const SetUpModal = ({ show, onClose, packageTier, packageDataConnectorsList }) => {
  const organizationId = JSON.parse(localStorage.getItem('organization'))?.id

  const [searchString, setSearchString] = useState('')
  const dispatch = useAppDispatch()
  const selectedConnector = useSelector(
    (state: RootState) => state.dataConnectors.selectedConnector,
  )
  const { dataConnectorsData } = useMyConnectorsData()

  useEffect(() => {
    setSearchString('')
  }, [show])

  const { data: dataConnectorsCard } = useGetDataConnectorsCardQuery(organizationId)
  const { data: dataConnectors } = useGetDataConnectorsQuery({ organizationId })

  const getCategory = (connectorName) => {
    let categoryName = ''
    Object.keys(dataConnectors?.data).forEach((category: string) => {
      Object.keys(dataConnectors?.data[category]).forEach((item: any) => {
        if (connectorName === item) {
          categoryName = category
        }
      })
    })
    return categoryName
  }
  if (packageTier === 'FreeTrial') {
    return (
      <DeprecatedDialog
        id="connectors-dialog"
        show={show}
        close={onClose}
        title="Choose a source"
        width={600}
      >
        <DeprecatedDialog.Body tw="flex-col px-4 pt-4 pb-10 md:p-6">
          <div tw="flex w-full flex-col">
            Set up a connector by searching for your data source.
            <SearchInput
              onChange={(value: string) => setSearchString(value)}
              id="searchSetUpModal"
              placeholder="Search by name..."
              value={searchString}
            />
            <div tw="mt-6 hidden rounded border border-monochrome-50 md:block">
              <ScrollbarStyled {...{ ...{ style: { height: '300px' } } }}>
                {dataConnectorsData
                  ?.filter(
                    (i) =>
                      (i.title.toLowerCase().includes(searchString.toLowerCase()) ||
                        i.description?.toLowerCase().includes(searchString.toLowerCase())) &&
                      i.button &&
                      dataConnectorsCard &&
                      !dataConnectorsCard[i.connectorName],
                  )
                  .map(({ title, icon, name, connectorName }) =>
                    packageTier === 'FreeTrial' &&
                    packageDataConnectorsList?.includes(connectorName) ? (
                      <div
                        key={connectorName}
                        css={[
                          tw`flex cursor-pointer items-center px-4 py-3 hover:bg-monochrome-50`,
                          name === selectedConnector && tw`bg-monochrome-50`,
                        ]}
                        onClick={() => dispatch(selectConnector(name))}
                        role="presentation"
                      >
                        {(
                          <img
                            alt={icon}
                            src={icon}
                            style={{
                              width: 35,
                              marginRight: 15,
                            }}
                          />
                        ) || <StarSvg />}
                        <div tw="ml-4">
                          <div tw="font-bold">{title}</div>
                          <div tw="text-monochrome-600">{getCategory(connectorName)}</div>
                        </div>
                      </div>
                    ) : (
                      <div
                        key={connectorName}
                        css={[
                          tw`flex cursor-pointer items-center justify-between px-4 py-3 hover:bg-monochrome-50`,
                          name === selectedConnector && tw`bg-monochrome-50`,
                        ]}
                        onClick={upgradeResponse}
                        role="presentation"
                      >
                        <div tw="flex">
                          {(
                            <img
                              alt={icon}
                              src={icon}
                              style={{
                                width: 35,
                                marginRight: 15,
                              }}
                            />
                          ) || <StarSvg />}
                          <div tw="ml-4">
                            <div tw="font-bold">{title}</div>
                            <div tw="text-monochrome-600">{getCategory(connectorName)}</div>
                          </div>
                        </div>
                        <Button variant="outlined">Upgrade to connect</Button>
                      </div>
                    ),
                  )}
              </ScrollbarStyled>
            </div>
            <div tw="mt-6 w-full rounded border border-monochrome-50 md:hidden">
              {dataConnectorsData
                ?.filter(
                  (i) =>
                    (i.title.toLowerCase().includes(searchString.toLowerCase()) ||
                      i.description?.toLowerCase().includes(searchString.toLowerCase())) &&
                    i.button &&
                    dataConnectorsCard &&
                    !dataConnectorsCard[i.connectorName],
                )
                .map(({ title, icon, name, connectorName }) => (
                  <div
                    key={connectorName}
                    css={[
                      tw`flex cursor-pointer items-center px-4 py-3`,
                      name === selectedConnector && tw`bg-monochrome-50`,
                    ]}
                    onClick={() => dispatch(selectConnector(name))}
                    role="presentation"
                  >
                    {(
                      <img
                        alt={icon}
                        src={icon}
                        style={{
                          width: 35,
                          marginRight: 15,
                        }}
                      />
                    ) || <StarSvg />}
                    <div tw="ml-4">
                      <div tw="font-bold">{title}</div>
                      <div tw="text-monochrome-600">{getCategory(connectorName)}</div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </DeprecatedDialog.Body>
        <DeprecatedDialog.Actions tw="justify-between px-4 py-3 md:justify-end">
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          {selectedConnector &&
          (selectedConnector === 'google-adwords' ||
            selectedConnector === 'google-analytics' ||
            selectedConnector === 'google-analytics-4' ||
            selectedConnector === 'google-search-console') ? (
            <ButtonSetup text="Sign in with Google" disabled={!selectedConnector} />
          ) : (
            <ButtonSetup text="Continue setup" disabled={!selectedConnector} />
          )}
        </DeprecatedDialog.Actions>
      </DeprecatedDialog>
    )
  }
  return (
    <DeprecatedDialog
      id="connectors-dialog"
      show={show}
      close={onClose}
      title="Choose a source"
      width={600}
    >
      <DeprecatedDialog.Body tw="flex-col px-4 pt-4 pb-10 md:p-6">
        <div tw="flex w-full flex-col">
          Set up a connector by searching for your data source.
          <SearchInput
            onChange={(value: string) => setSearchString(value)}
            id="searchSetUpModal"
            placeholder="Search by name..."
            value={searchString}
          />
          <div tw="mt-6 hidden rounded border border-monochrome-50 md:block">
            <ScrollbarStyled {...{ ...{ style: { height: '300px' } } }}>
              {dataConnectorsData
                ?.filter(
                  (i) =>
                    (i.title.toLowerCase().includes(searchString.toLowerCase()) ||
                      i.description?.toLowerCase().includes(searchString.toLowerCase())) &&
                    i.button &&
                    dataConnectorsCard &&
                    !dataConnectorsCard[i.connectorName],
                )
                .map(({ title, icon, name, connectorName }) => (
                  <div
                    key={connectorName}
                    css={[
                      tw`flex cursor-pointer items-center px-4 py-3 hover:bg-monochrome-50`,
                      name === selectedConnector && tw`bg-monochrome-50`,
                    ]}
                    onClick={() => dispatch(selectConnector(name))}
                    role="presentation"
                  >
                    {(
                      <img
                        alt={icon}
                        src={icon}
                        style={{
                          width: 35,
                          marginRight: 15,
                        }}
                      />
                    ) || <StarSvg />}
                    <div tw="ml-4">
                      <div tw="font-bold">{title}</div>
                      <div tw="text-monochrome-600">{getCategory(connectorName)}</div>
                    </div>
                  </div>
                ))}
            </ScrollbarStyled>
          </div>
          <div tw="mt-6 w-full rounded border border-monochrome-50 md:hidden">
            {dataConnectorsData
              ?.filter(
                (i) =>
                  (i.title.toLowerCase().includes(searchString.toLowerCase()) ||
                    i.description?.toLowerCase().includes(searchString.toLowerCase())) &&
                  i.button &&
                  dataConnectorsCard &&
                  !dataConnectorsCard[i.connectorName],
              )
              .map(({ title, icon, name, connectorName }) => (
                <div
                  key={connectorName}
                  css={[
                    tw`flex cursor-pointer items-center px-4 py-3`,
                    name === selectedConnector && tw`bg-monochrome-50`,
                  ]}
                  onClick={() => dispatch(selectConnector(name))}
                  role="presentation"
                >
                  {(
                    <img
                      alt={icon}
                      src={icon}
                      style={{
                        width: 35,
                        marginRight: 15,
                      }}
                    />
                  ) || <StarSvg />}
                  <div tw="ml-4">
                    <div tw="font-bold">{title}</div>
                    <div tw="text-monochrome-600">{getCategory(connectorName)}</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </DeprecatedDialog.Body>
      <DeprecatedDialog.Actions tw="justify-between px-4 py-3 md:justify-end">
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        {selectedConnector &&
        (selectedConnector === 'google-adwords' ||
          selectedConnector === 'google-analytics' ||
          selectedConnector === 'google-analytics-4' ||
          selectedConnector === 'google-search-console') ? (
          <ButtonSetup text="Sign in with Google" disabled={!selectedConnector} />
        ) : (
          <ButtonSetup text="Continue setup" variant="contained" disabled={!selectedConnector} />
        )}
      </DeprecatedDialog.Actions>
    </DeprecatedDialog>
  )
}
