import { Card, CardContent, CardHeader } from '@cart/ui'
import { FC, ReactNode } from 'react'

export type SettingSectionProps = {
  description: ReactNode
  cardTitle?: ReactNode
  cardSubtitle?: ReactNode
  cardContent: ReactNode
}
export const SettingSection: FC<SettingSectionProps> = ({
  description,
  cardTitle,
  cardSubtitle,
  cardContent,
}) => (
  <div tw="grid grid-cols-1 gap-8 mb-[64px] lg:grid-cols-3">
    {description}
    <div tw="col-span-2">
      <Card>
        {(cardTitle || cardSubtitle) && (
          <CardHeader
            tw="border-b-monochrome-500 border-b"
            title={cardTitle}
            subheader={cardSubtitle}
          />
        )}
        <CardContent tw="p-[24px]">{cardContent}</CardContent>
      </Card>
    </div>
  </div>
)

export type SectionDescriptionProps = {
  title: string
  content: string
}
export const SectionDescription: FC<SectionDescriptionProps> = ({ title, content }) => (
  <div>
    <h3>{title}</h3>
    <p>{content}</p>
  </div>
)

export type SectionCardTitleProps = {
  title: string
}
export const SectionCardTitle: FC<SectionCardTitleProps> = ({ title }) => <h4 tw="m-0">{title}</h4>
