import { useReactiveVar } from '@apollo/client'
import { routedBizVar, useOrganizationBusinessQuery } from '@brand-console/generated-graphql-hooks'
import { useRouter } from '@cart/ui'
import React, { ReactElement, useEffect } from 'react'
import TagManager from 'react-gtm-module'

import { BusinessOverview } from './BusinessOverview/BusinessOverview'
import { BusinessUsers } from './BusinessUsers/BusinessUsers'

export const SettingsBusiness = (): ReactElement => {
  const routedBusiness = useReactiveVar(routedBizVar)
  const { query } = useRouter()
  // eslint-disable-next-line @typescript-eslint/dot-notation
  const orgBusinessShowId = parseInt(query['id'] as string, 10)

  const { data } = useOrganizationBusinessQuery({
    variables: { orgBusinessShowId },
    onCompleted: (businessData) => {
      const {
        org_businessShow: { name: businessName, id, createdAt },
      } = businessData
      const curRoutedBusiness = {
        id,
        name: businessName,
        createdAt,
      }
      routedBizVar(curRoutedBusiness)
    },
  })

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        route: 'SettingsBusiness',
      },
    })
  }, [])

  if (!data || !routedBusiness) {
    return null
  }

  return (
    <div tw="m-auto w-full max-w-5xl">
      <BusinessOverview />
      <BusinessUsers />
    </div>
  )
}
