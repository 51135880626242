import tw, { css, styled } from 'twin.macro'

import { ButtonProps } from './deprecated-button.types'

const handleBehaviorVariants = (behavior?: string, variant = 'primary') => {
  switch (behavior) {
    case 'success':
      return [
        variant === 'primary' &&
          tw`bg-accent-three-700 text-white hocus:(bg-accent-three-800 text-white)`,
        variant === 'secondary' &&
          tw`bg-accent-three-50 text-accent-three-700 hocus:(bg-accent-three-700 text-white)`,
        variant === 'tertiary' && tw`bg-transparent text-accent-three-700 hocus:bg-accent-three-50`,
        variant === 'outline' &&
          tw`border-2 border-monochrome-500 border-solid bg-transparent text-monochrome-700 hocus:(border-accent-three-300 bg-accent-three-50 text-accent-three-700)`,
      ]
    case 'warning-secondary':
      return [
        variant === 'primary' &&
          tw`bg-secondary-700 text-white hocus:(bg-secondary-800 text-white)`,
        variant === 'secondary' &&
          tw`bg-secondary-50 text-secondary-700 hocus:(bg-secondary-700 text-white)`,
        variant === 'tertiary' && tw`bg-transparent text-secondary-700 hocus:bg-secondary-50`,
        variant === 'outline' &&
          tw`border-2 border-monochrome-500 border-solid bg-transparent text-monochrome-700 hocus:(border-secondary-300 bg-secondary-50 text-secondary-700)`,
      ]
    case 'warning-primary':
      return [
        variant === 'primary' &&
          tw`bg-accent-two-700 text-white hocus:(bg-accent-two-800 text-white)`,
        variant === 'secondary' &&
          tw`bg-accent-two-50 text-accent-two-700 hocus:(bg-accent-two-700 text-white)`,
        variant === 'tertiary' && tw`bg-transparent text-accent-two-700 hocus:bg-accent-two-50`,
        variant === 'outline' &&
          tw`border-2 border-monochrome-500 border-solid bg-transparent text-monochrome-700 hocus:(border-accent-two-300 bg-accent-two-50 text-accent-two-700)`,
      ]
    default:
      return [
        variant === 'primary' && tw`bg-primary-700 text-white hocus:(bg-primary-800 text-white)`,
        variant === 'secondary' &&
          tw`bg-primary-100 text-primary-700 hocus:(bg-primary-700 text-white)`,
        variant === 'tertiary' && tw`bg-transparent text-primary-700 hocus:bg-primary-100`,
        variant === 'quaternary' && tw`bg-primary-700 text-white`,
        variant === 'outline' &&
          tw`border-2 border-monochrome-500 border-solid bg-transparent text-monochrome-700 hocus:(border-primary-300 bg-primary-100 text-primary-700)`,
      ]
  }
}

export const StyledButton = styled.button(
  ({ behavior, disabled, variant = 'primary', size = 'medium' }: ButtonProps) => [
    // Base Styles
    tw`flex transform cursor-pointer items-center whitespace-nowrap rounded border-none font-bold leading-none no-underline duration-150`,
    tw`focus:(outline-none ring-2 ring-primary-800 ring-offset-2)`,
    css`
      line-height: 0.8;
    `,
    // Size Styles
    size === 'small' && [
      css`
        height: 30px;
      `,
      tw`px-4`,
    ],
    size === 'medium' && [
      css`
        height: 38px;
      `,
      tw`px-7`,
    ],
    size === 'large' && [
      css`
        height: 46px;
      `,
      tw`px-8`,
    ],
    size === 'xlarge' && [
      css`
        font-size: 24px;
        height: 72px;
      `,
      tw`px-8`,
    ],
    // Variant Styles
    css`
      &[type='button'],
      &[type='reset'],
      &[type='submit'] {
        ${handleBehaviorVariants(behavior, variant)}
      }
    `,
    // Address anchor tags
    handleBehaviorVariants(behavior, variant),
    // Disabled Styles
    disabled && tw`!(pointer-events-none select-none text-monochrome-600)`,
    disabled &&
      variant !== 'tertiary' &&
      tw`!bg-monochrome-500 hocus:!(bg-monochrome-500 text-monochrome-600)`,
    disabled &&
      (variant === 'tertiary' || variant === 'outline') &&
      tw`!bg-white hocus:!(bg-white text-monochrome-600)`,
  ],
)

export const StyledIcon = styled.span(({ iconPosition }: ButtonProps) => [
  tw`leading-none sm:align-baseline`,
  css`
    & svg {
      ${tw`align-bottom`}
    }
  `,
  iconPosition === 'left' && tw`pr-2`,
  iconPosition === 'right' && tw`pl-2`,
])
