import { CART_SITES_HOSTS, isLocalhost } from '@brand-console/utilities'
import { useOnClickOutside } from '@cart/ui/hooks/useOnClickOutside'
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { findDeep } from 'deepdash-es/standalone'
import React, { useMemo, useRef, useState } from 'react'
import tw from 'twin.macro'

import { useUnifiedSidebarStore } from '../../store/unified-sidebar.store'
import { IMenuItemProps } from '../../unified-sidebar.types'
import { StyledMenuHeader, StyledMenuItem } from '../unified-sidebar/UnifiedSidebar.styles'
import { getIcon } from './MenuItem.partials'
import { goToUrl } from './MenuItems.functions'

export const MenuItemHeader: React.FC<IMenuItemProps> = ({ node }) => {
  const { isMenuExpanded, isSpa, product, rerenderKey } = useUnifiedSidebarStore()
  const menuItemRef = useRef(null)

  const nodeHasActiveChild = !!useMemo(() => {
    return findDeep(
      node,
      (child) => {
        const { host, pathname } = new URL(child?.url, window.location.origin)
        if (isLocalhost) {
          return (
            CART_SITES_HOSTS[product]?.includes(host) &&
            (window.location.pathname === pathname ||
              (window.location.pathname !== '/' && pathname?.startsWith(window.location.pathname)))
          )
        }
        return (
          window.location.host === host &&
          (window.location.pathname === pathname ||
            (window.location.pathname !== '/' && pathname?.startsWith(window.location.pathname)))
        )
      },
      {
        childrenPath: ['nodes'],
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node, rerenderKey])

  const [isExpanded, setIsExpanded] = useState(nodeHasActiveChild)

  // Close the menu item when the user clicks outside of it
  useOnClickOutside(menuItemRef, () => {
    if (!isMenuExpanded) {
      setIsExpanded(false)
    }
  })

  return (
    <StyledMenuHeader ref={menuItemRef} isExpanded={isExpanded} isMenuExpanded={isMenuExpanded}>
      <button
        css={[
          tw`flex cursor-pointer items-center justify-between border-none bg-transparent text-sm text-monochrome-700`,
          !isMenuExpanded && tw`!border`,
        ]}
        type="button"
        onClick={() => setIsExpanded(!isExpanded)}
        data-isexpanded={isExpanded}
        aria-expanded={isExpanded}
        aria-controls={`usb-submenu-${node.title}`}
        aria-label={`Toggle ${node.title} submenu`}
      >
        {node.icon && (
          <span
            css={[
              tw`inline-flex w-5 items-center justify-center text-monochrome-600`,
              nodeHasActiveChild && tw`text-primary-700`,
              isMenuExpanded && tw`mr-2`,
            ]}
          >
            {getIcon(node.icon, node.title)}
          </span>
        )}
        <span data-id="node-title" tw="w-full">
          {node.title}
        </span>
        {isMenuExpanded && (
          <div css={[tw`transition-all`, isExpanded && tw`rotate-90`]}>
            <FontAwesomeIcon role="button" icon={faAngleRight} tw="text-lg text-monochrome-500" />
          </div>
        )}
      </button>

      {isExpanded && (
        <ul
          id={`usb-submenu-${node.title}`}
          tw="m-0 list-none pl-0"
          css={[
            !isMenuExpanded &&
            tw`absolute top-0 left-14 m-0 hidden w-80 list-none rounded border border-monochrome-400 border-solid bg-white !p-2 shadow-lg`,
            isMenuExpanded && tw`list-none`,
          ]}
        >
          {node.nodes.map((childNode) => {
            if (childNode.nodes?.length > 0) {
              return <MenuItemHeader key={childNode.title} node={childNode} />
            }

            const { host, origin, pathname } = window.location
            const { host: childNodeHost, pathname: childNodePathname } = new URL(
              childNode?.url,
              origin,
            )

            let nodeIsActive = false

            if (isLocalhost) {
              nodeIsActive =
                CART_SITES_HOSTS[product]?.includes(childNodeHost) &&
                (pathname === childNodePathname ||
                  (childNode?.url?.includes(pathname) && pathname !== '/') ||
                  childNode?.nodes?.some((child) => {
                    const { pathname: url } = new URL(child?.url, origin)
                    return pathname !== '/' && url?.startsWith(pathname)
                  }))
            } else {
              nodeIsActive =
                host === childNodeHost &&
                (pathname === childNodePathname ||
                  (childNode?.url?.includes(pathname) && pathname !== '/') ||
                  childNode?.nodes?.some((child) => {
                    const { pathname: url } = new URL(child?.url, origin)
                    return pathname !== '/' && url?.startsWith(pathname)
                  }))
            }

            return (
              <StyledMenuItem
                isMenuExpanded={isExpanded}
                key={childNode.title}
                active={nodeIsActive}
              >
                <a href={childNode.url} onClick={goToUrl.bind(this, isSpa)}>
                  {childNode.title}
                </a>
              </StyledMenuItem>
            )
          })}
        </ul>
      )}
    </StyledMenuHeader>
  )
}
