import { usePopState } from '@brand-console/utilities/usePopState'
import { useBreakpoint } from '@cart/ui/hooks/useBreakpoint'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment, useEffect, useState } from 'react'
import { Menu as ReactProSidebarMenu } from 'react-pro-sidebar'

import { IMenuItem } from '../MenuItem/MenuItem.types'
import { MenuSkeleton } from '../MenuSkeleton/MenuSkeleton'
import { StyledSidebar } from './Menu.styles'
import { MenuRenderer } from './MenuRenderer'
import { useLeftNavStore } from '../../store/left-nav.store'

export const Menu = ({ menuSections }) => {
  const [, setHref] = useState('') // re-render the menu items on href change to set highlighting
  const isLarge = useBreakpoint('lg')
  const { isCollapsed } = useLeftNavStore()

  //this triggers more reliably for Angular
  usePopState(() => {
    setHref(window.location.href)
  })

  //this is the main trigger for Brand Console
  useEffect(() => {
    setHref(window.location.href)
  }, [window.location.href])

  if (!menuSections.length) return <MenuSkeleton />

  return (
    <StyledSidebar
      collapsed={isCollapsed}
      collapsedWidth={!isLarge ? '0px' : '65px'}
    >
      {menuSections.map((menu) => (
        <Fragment key={menu.id}>
          {!isCollapsed && menu.title && <h5 className="leftnav-group-header">{menu.title}</h5>}
          <ReactProSidebarMenu
            key={menu.id}
            tw="whitespace-normal"
            renderExpandIcon={({ open }) =>
              !isCollapsed ? (
                <FontAwesomeIcon
                  icon={open ? solid('caret-down') : solid('caret-right')}
                  tw="text-monochrome-600"
                />
              ) : null
            }
          >
            {menu.items.map((item: IMenuItem) => (
              <MenuRenderer
                key={`${menu.id}:${item.id || item.title}`}
                item={item}
              />
            ))}
          </ReactProSidebarMenu>
        </Fragment>
      ))}
    </StyledSidebar>
  )
}
