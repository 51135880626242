import { DialogContent } from '@mui/material'
import tw, { css, styled } from 'twin.macro'

export const StyledDialogContent = styled(DialogContent)(() => [
  css`
    &.MuiDialogContent-root {
      ${tw`!(relative h-full px-4 py-2)`}
      ${tw`sm:!px-6`}
    }
  `,
])
