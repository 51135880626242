import React, { useCallback, useEffect, useRef, useState } from 'react'

import { StyledLabel, StyledRange } from './range.styles'
import { RangeProps } from './range.types'

export const Range = ({
  id,
  min,
  max,
  step,
  labelTemplate,
  onChange,
  value,
  labelProps,
  ...props
}: RangeProps) => {
  const rangeRef = useRef<HTMLInputElement>(null)
  const labelRef = useRef<HTMLElement>(null)
  const [labelPosition, setLabelPosition] = useState<number>()
  const initialValue = value?.toString() || (min + (max - min) / 2).toString()
  const [label, setLabel] = useState<string>(initialValue)

  const setSliderLabels = useCallback(() => {
    if (rangeRef.current && labelRef.current && !!label) {
      const rangeObj = rangeRef.current as HTMLInputElement
      const labelObj = labelRef.current as HTMLElement

      const halfHandlerWidth = 25 / 2
      const halfLabelWidth = labelObj.clientWidth / 2
      const sliderWidth = rangeObj.clientWidth
      const centerPosition = sliderWidth / 2

      let percentOfRange = (parseInt(rangeObj.value, 10) - min) / (max - min)
      if (Number.isNaN(percentOfRange)) {
        percentOfRange = 0
      }

      const valuePxPosition = percentOfRange * sliderWidth
      const distFromCenter = valuePxPosition - centerPosition
      const percentDistFromCenter = distFromCenter / centerPosition
      const offset = percentDistFromCenter * halfHandlerWidth
      const isMin = parseInt(rangeObj.value, 10) === min
      const isMax = parseInt(rangeObj.value, 10) === max
      let edgesOffset
      if (isMax) {
        edgesOffset = labelObj.clientWidth
      } else if (isMin) {
        edgesOffset = 0
      } else {
        edgesOffset = halfLabelWidth + offset
      }
      const finalLabelPosition = valuePxPosition - edgesOffset
      setLabelPosition(finalLabelPosition)
      const labelText = labelTemplate?.(rangeObj.value) || rangeObj.value
      setLabel(labelText)
    }
  }, [label, labelTemplate, max, min])

  useEffect(() => {
    if (rangeRef && rangeRef.current) {
      rangeRef.current.value = value?.toString() || initialValue
    }
    setSliderLabels()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    setSliderLabels()
  }, [setSliderLabels])

  const rangeOnChange: any = () => onChange?.(rangeRef.current?.value)

  const onChangeHandler = () => {
    setSliderLabels()
    rangeOnChange()
  }

  return (
    <div tw="relative w-full pt-1">
      <StyledRange
        id={id}
        max={max}
        min={min}
        name={id}
        ref={rangeRef}
        step={step}
        type="range"
        defaultValue={initialValue}
        onChange={onChangeHandler}
        {...props}
      />
      <StyledLabel {...labelProps} ref={labelRef} data-position={labelPosition}>
        {label}
      </StyledLabel>
    </div>
  )
}
