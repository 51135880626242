import { Button, Dialog, DialogTitle } from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ReactElement, useCallback, useState } from 'react'

import { CreateBusiness } from '../CreateBusiness/CreateBusiness'
import { useSettingsOrganizationsContext } from '../SettingsOrganizations.store'
import { BusinessManagementList } from './BusinessManagementList'
import { BusinessManagementListAll } from './BusinessManagementListAll'
import { isAtLeastOrgManager, isOrgRole, useCurrentContext } from '@cartdotcom/auth'

export const BusinessManagement = (): ReactElement => {
  const { clearAlert } = useSettingsOrganizationsContext()
  const { currentOrganization: { businessEntityTerminology } } = useCurrentContext()
  const { roleName } = useCurrentContext();

  const isOrgAdminOrAbove = isAtLeastOrgManager(roleName)
  const isOrgMember = isOrgRole(roleName)

  const [showCreateBusinessModal, setShowCreateBusinessModal] = useState<boolean>(false)
  const toggleCreateBusinessModal = useCallback(() => {
    setShowCreateBusinessModal(!showCreateBusinessModal)
  }, [showCreateBusinessModal])

  const handleCreateBusiness = () => {
    clearAlert()
    toggleCreateBusinessModal()
  }

  return (
    <div tw="-mx-5 w-[calc(100% + 2.50rem)]">
      {/* The 2 business management components utilize 2 different graphql queries
        For org admins/members, we render the component that queries for businesses at the org level
        For business admins/members, we render the component that queries for businesses at the user's level */}
      {isOrgMember ? (
        <BusinessManagementListAll />
      ) : (
        <BusinessManagementList />
      )}
      {isOrgAdminOrAbove && (
        <div tw="my-5 ml-7">
          <Button
            startIcon={<FontAwesomeIcon icon={solid('plus')} />}
            onClick={handleCreateBusiness}
            variant="outlined"
          >
            Create {businessEntityTerminology}
          </Button>
          <Dialog
            id="create-business-dialog"
            open={showCreateBusinessModal}
            onClose={() => toggleCreateBusinessModal()}
            width="sm"
          >
            <DialogTitle>Create a {businessEntityTerminology}</DialogTitle>
            <CreateBusiness close={toggleCreateBusinessModal} />
          </Dialog>
        </div>
      )}
    </div>
  )
}
