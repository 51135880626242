// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Hooks,
  Row,
  TableInstance,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { useSticky } from 'react-table-sticky'
import { StyleSheetManager } from 'styled-components'

import { useBreakpoint } from '../../framework/hooks/useBreakpoint'
import { DropdownItem } from '../dropdown/dropdown-item/dropdown-item'
import {
  StyledCheckbox,
  StyledMoveableTableContainer,
  StyledPaginationButton,
  StyledPaginationDropdown,
  StyledSort,
  StyledSortDown,
  StyledSortUp,
  StyledTable,
  StyledTableBody,
  StyledTableBodyCell,
  StyledTableBodyRow,
  StyledTableContainer,
  StyledTableContentSection,
  StyledTableFooter,
  StyledTableHead,
  StyledTableHeadContainer,
  StyledTableHeader,
  StyledTableHeaderActions,
  StyledTableHeaderCell,
  StyledTableHeaderRow,
  StyledTableWrapper,
} from './table.styles'
import { TableProps } from './table.types'

const SelectionCheckbox = (props: any) => {
  const { checked, id, isHeader, onChange } = props
  return (
    <StyledCheckbox
      checked={checked}
      id={isHeader ? undefined : id}
      onChange={onChange}
      size="small"
    />
  )
}

const selectionHook = (hooks: Hooks<any>) => {
  hooks.allColumns.push((columns) => [
    {
      id: '_selector',
      disableResizing: true,
      disableGroupBy: true,
      minWidth: 45,
      width: 45,
      maxWidth: 45,
      sticky: 'left',
      Aggregated: undefined,
      Header: ({ getToggleAllRowsSelectedProps }: any) => {
        const guid = Math.random().toString(36).slice(2)
        return <SelectionCheckbox id={guid} {...getToggleAllRowsSelectedProps()} isHeader />
      },
      Cell: ({ row }: { row: Row<any> }) => (
        <SelectionCheckbox {...(row as any).getToggleRowSelectedProps()} />
      ),
    },
    ...columns,
  ])
}

const headerContentHOC = (Component: any) =>
  function renderHeaderContent({ ...props }) {
    return <Component {...props} />
  }

const HeaderContentWithTable = headerContentHOC((props: any) => {
  const { headerContent, table } = props
  const Comp = headerContent
  return (
    <StyledTableHeaderActions>
      <Comp table={table} />
    </StyledTableHeaderActions>
  )
})

export const Table: FC<TableProps & Record<string, unknown>> = ({
  className,
  columns,
  data,
  fetchData,
  enableRowSelection,
  enablePagination,
  enableSorting = true,
  error,
  headerContent,
  id,
  mobileDisplay = 'moveable',
  onClick,
  onRowSelection,
  pagination,
  renderRoot,
  shortenedColumns,
  title,
}: TableProps) => {
  const [itemsPerPage, setItemsPerPage] = useState(pagination?.itemsPerPage || 10)

  const defaultColumn = useMemo(
    () => ({
      minWidth: 30,
      width: 150,
    }),
    [],
  )

  const {
    loading = false,
    pageCount: controlledPageCount = 0,
    strategy = 'client',
    totalCount = data ? data.length : 0,
    setItemsPerPageCustom,
  } = pagination || {}

  const tableRef = useRef<HTMLDivElement>(null)
  const tableBodyRef = useRef<HTMLDivElement>(null)
  const perPageOptions = [5, 10, 25, 50, 100]
  const isMobile = useBreakpoint('mobile')
  const shouldUseBlockLayout = ['collapsed', 'transformed'].includes(mobileDisplay)
  const manualPagination = enablePagination && strategy === 'server'
  let shortenedColumnsToHideOnMobile: any[] = []

  if (mobileDisplay === 'shorten' && shortenedColumns) {
    shortenedColumnsToHideOnMobile = columns
      .filter(({ accessor }) => !shortenedColumns.includes(accessor))
      .map((item: any) => item.accessor)
  }

  if (manualPagination && !fetchData) {
    throw new Error('Pagination strategy is set to server but no fechData function was provided')
  }

  const plugins: any = [useFlexLayout, useResizeColumns]
  if (enableSorting) {
    plugins.push(useSortBy)
  }
  if (mobileDisplay === 'collapsed') {
    plugins.push(useSticky)
  }
  if (enablePagination) {
    plugins.push(usePagination)
  }
  if (enableRowSelection) {
    plugins.push(useRowSelect)
    plugins.push(selectionHook)
  }

  const tableInstance = useTable<TableInstance>(
    {
      columns,
      data: data || [],
      defaultColumn,
      initialState: { pageIndex: 0, pageSize: itemsPerPage },
      manualPagination,
      autoResetPage: false,
      ...(manualPagination && { pageCount: controlledPageCount }), // adding pageCount prop breaks default pagination for some reason
    },
    ...plugins,
  )

  const {
    canNextPage,
    canPreviousPage,
    footerGroups,
    getTableBodyProps,
    getTableProps,
    headerGroups,
    nextPage,
    gotoPage,
    prepareRow,
    previousPage,
    page,
    rows,
    selectedFlatRows,
    setHiddenColumns,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance

  const iterator = enablePagination ? page : rows
  const firstItem = pageIndex * pageSize + 1

  const getLastItem = useMemo(() => {
    let lastItem = firstItem + pageSize - 1
    if (lastItem > totalCount) {
      lastItem = totalCount
    }
    return lastItem
  }, [firstItem, pageSize, totalCount])

  const updateRowsPerPage = useCallback(
    (selectedRows: any) => {
      const rowsPerPage = parseInt(selectedRows[0].value, 10)
      setItemsPerPage(rowsPerPage)
      if (setItemsPerPageCustom) {
        setItemsPerPageCustom(rowsPerPage)
      }
      setPageSize(rowsPerPage)
    },
    [setPageSize],
  )

  useEffect(() => {
    if (fetchData) {
      fetchData({ pageIndex, pageSize })
    }
  }, [fetchData, pageIndex, pageSize])

  useEffect(() => {
    if (pagination && pagination.currentPage && pagination.currentPage - 1 !== pageIndex) {
      gotoPage(pagination.currentPage - 1)
    }
  }, [pagination?.currentPage])

  useEffect(() => {
    if (pagination && pagination.currentPageCallback) {
      pagination.currentPageCallback(pageIndex + 1)
    }
  }, [pageIndex])
  useEffect(() => {
    if (typeof onRowSelection === 'function') {
      onRowSelection({ selectedRows: selectedFlatRows })
    }
  }, [onRowSelection, selectedFlatRows])

  useEffect(() => {
    setHiddenColumns(isMobile ? shortenedColumnsToHideOnMobile : [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile])

  const TableContainer =
    mobileDisplay === 'moveable' || mobileDisplay === 'collapsed'
      ? StyledMoveableTableContainer
      : Fragment

  const hasFooter = columns.some(({ Footer }) => !!Footer)

  return (
    <StyleSheetManager target={renderRoot}>
      <StyledTableWrapper id={id} className={className} data-testid="table" ref={tableRef}>
        <StyledTableContainer>
          {(title || headerContent) && (
            <StyledTableHeader data-testid="table-header">
              {title && <h2>{title}</h2>}
              {headerContent && (
                <HeaderContentWithTable table={tableInstance} headerContent={headerContent} />
              )}
            </StyledTableHeader>
          )}
          <StyledTableContentSection
            data-haspagination={enablePagination}
            data-mobiledisplay={mobileDisplay}
          >
            <TableContainer>
              <StyledTable
                as={shouldUseBlockLayout ? 'div' : undefined}
                data-mobiledisplay={mobileDisplay}
                data-ismobile={isMobile}
                {...getTableProps()}
              >
                <StyledTableHead
                  as={shouldUseBlockLayout ? 'div' : undefined}
                  data-mobiledisplay={mobileDisplay}
                  role="rowgroup"
                >
                  {headerGroups.map((headerGroup) => (
                    <StyledTableHeaderRow
                      as={shouldUseBlockLayout ? 'div' : undefined}
                      {...headerGroup.getHeaderGroupProps()}
                      role="row"
                    >
                      {headerGroup.headers.map((column: any, i) => {
                        let sorting
                        if (column.isSorted) {
                          if (column.isSortedDesc) {
                            sorting = 'desc'
                          } else {
                            sorting = 'asc'
                          }
                        }
                        const shouldShowSort =
                          enableSorting &&
                          (!enableRowSelection || (enableRowSelection && i > 0)) &&
                          !column.disableSortBy
                        return (
                          <StyledTableHeaderCell
                            as={shouldUseBlockLayout ? 'div' : undefined}
                            {...column.getHeaderProps(column.getSortByToggleProps?.())}
                            role="columnheader"
                            {...(!enableSorting
                              ? { onClick: () => onClick?.({ cell: column }) }
                              : {})}
                          >
                            <StyledTableHeadContainer>
                              {shouldShowSort && (
                                <StyledSort>
                                  <StyledSortUp icon={solid('sort-up')} data-sorting={sorting} />
                                  <StyledSortDown
                                    icon={solid('sort-down')}
                                    data-sorting={sorting}
                                  />
                                </StyledSort>
                              )}
                              {column.render('Header')}
                            </StyledTableHeadContainer>
                          </StyledTableHeaderCell>
                        )
                      })}
                    </StyledTableHeaderRow>
                  ))}
                </StyledTableHead>
                <StyledTableBody
                  ref={tableBodyRef}
                  as={shouldUseBlockLayout ? 'div' : undefined}
                  {...getTableBodyProps()}
                  data-bodyheight="auto"
                  data-haserror={!!error}
                  data-haspagination={enablePagination}
                  data-loading={loading}
                  data-mobiledisplay={mobileDisplay}
                  data-testid="table-body"
                >
                  {error && (
                    <StyledTableBodyRow
                      as={shouldUseBlockLayout ? 'div' : undefined}
                      data-testid="table-body-row"
                    >
                      <StyledTableBodyCell
                        colSpan={iterator.length > 0 ? iterator[0].cells.length : 0}
                        tw="w-full justify-center"
                      >
                        <div tw="flex h-full items-center justify-center py-6">{error}</div>
                      </StyledTableBodyCell>
                    </StyledTableBodyRow>
                  )}
                  {!error &&
                    iterator.map((row, i) => {
                      prepareRow(row)
                      const isSelected =
                        selectedFlatRows && selectedFlatRows.map(($row) => $row.index).includes(i)
                      return (
                        <StyledTableBodyRow
                          as={shouldUseBlockLayout ? 'div' : undefined}
                          data-mobiledisplay={mobileDisplay}
                          data-testid="table-body-row"
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell) => {
                            return (
                              <StyledTableBodyCell
                                as={shouldUseBlockLayout ? 'div' : undefined}
                                data-header={
                                  typeof cell.column.Header === 'string' ? cell.column.Header : ''
                                }
                                data-ismobile={isMobile}
                                data-isselected={isSelected}
                                data-mobiledisplay={mobileDisplay}
                                onClick={() => onClick?.({ cell })}
                                {...cell.getCellProps()}
                              >
                                {cell.render('Cell')}
                              </StyledTableBodyCell>
                            )
                          })}
                        </StyledTableBodyRow>
                      )
                    })}
                </StyledTableBody>
              </StyledTable>
            </TableContainer>
          </StyledTableContentSection>
          {hasFooter && (
            <StyledTableFooter as="div" tw="block p-0 [box-shadow:none]">
              {footerGroups.map((group) => (
                <StyledTableBodyRow as="div" {...group.getFooterGroupProps()} tw="rounded-b-lg">
                  {group.headers.map((column) => (
                    <StyledTableBodyCell as="div" {...column.getFooterProps()}>
                      {column.render('Footer')}
                    </StyledTableBodyCell>
                  ))}
                </StyledTableBodyRow>
              ))}
            </StyledTableFooter>
          )}
          {enablePagination && !error && (
            <StyledTableFooter tw="border-monochrome-50 border-t">
              <section tw="flex items-center justify-between text-monochrome-700 leading-none sm:justify-end">
                <div tw="flex items-center">
                  <span tw="whitespace-nowrap pr-2 sm:px-2">Rows per page:</span>
                  <StyledPaginationDropdown
                    id="my-dropdown"
                    tw="w-16 sm:w-20"
                    forceSelection
                    onChange={updateRowsPerPage}
                    placeholder=""
                  >
                    {perPageOptions.map((option) => {
                      return (
                        <DropdownItem
                          key={option}
                          text={`${option}`}
                          value={`${option}`}
                          selected={option === itemsPerPage}
                        />
                      )
                    })}
                  </StyledPaginationDropdown>
                </div>
                <div tw="px-0 text-center sm:(px-4 [min-width:80px])">
                  {totalCount > 0 && (
                    <>
                      {firstItem}-{getLastItem} of {totalCount}
                    </>
                  )}
                </div>
                <div tw="px-2 sm:px-6">
                  <StyledPaginationButton
                    type="button"
                    data-enabled={canPreviousPage}
                    onClick={previousPage}
                  >
                    <FontAwesomeIcon icon={solid('chevron-left')} />
                  </StyledPaginationButton>
                </div>
                <div tw="px-2 sm:px-6">
                  <StyledPaginationButton
                    type="button"
                    data-enabled={canNextPage}
                    onClick={nextPage}
                  >
                    <FontAwesomeIcon icon={solid('chevron-right')} />
                  </StyledPaginationButton>
                </div>
              </section>
            </StyledTableFooter>
          )}
        </StyledTableContainer>
      </StyledTableWrapper>
    </StyleSheetManager>
  )
}

export default Table
