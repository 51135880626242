import tw, { css, styled } from 'twin.macro'

export const StyledPackagesContainer = styled.div<{ type: 'standalone' | 'cosell' }>(({ type }) => [
  tw`flex w-full flex-wrap gap-6 lg:(grid grid-cols-3)`,
  type === 'standalone' && tw`lg:grid-cols-2`,
  css`
    & .MuiAccordion-root {
      ${tw`flex flex-col [:not(.Mui-expanded)]:h-fit`}

      &:before {
        ${tw`hidden`}
      }

      & .MuiAccordionSummary-root {
        ${tw`items-start bg-primary-50 p-4 lg:(cursor-default p-0)`}

        & .MuiAccordionSummary-expandIconWrapper {
          ${tw`lg:hidden`}
        }
        & .MuiAccordionSummary-content {
          ${tw`m-0 flex-col`}

          & .MuiCardHeader-root {
            ${tw`items-start bg-primary-50 p-0 lg:p-4`}
            ${type === 'cosell' && tw`lg:[min-height: 300px]`}
            ${type === 'standalone' && tw`lg:[min-height: 185px]`}

              & .MuiCardHeader-content h5 {
              ${tw`[font-weight: 600] [font-size: 20px] mb-3 flex flex-col`}
            }

            & .MuiChip-root {
              ${tw`mt-2 w-fit whitespace-nowrap`}
            }

            & .pkg-subheader {
              ${tw`mb-3 whitespace-nowrap text-monochrome-900`}

              & .MuiLink-root {
                ${tw`ml-1.5`}
              }
            }
          }
        }
      }
      & .MuiCollapse-root {
        ${tw`grow`}

        & .MuiCollapse-wrapper {
          ${tw`h-full`}

          & .MuiAccordion-region {
            ${tw`h-full`}
          }
        }
      }
      & .MuiAccordionDetails-root {
        ${tw`flex h-full grow flex-col p-0`}

        & .MuiCardContent-root {
          ${tw`grow`}

          & .annual-rev {
            ${tw`mb-2 font-semibold`}
          }

          & .pkg-features {
            ${tw`flex flex-col justify-between`}

            & > div:first-child {
              ${tw`[min-height: 323px] mb-2 border-b !border-b-monochrome-300/[15] pb-2`}

              & .pkg-tier-title {
                ${tw`my-4 flex items-center gap-3 text-sm first:mt-0`}

                & [data-icon="circle-check"] {
                  ${tw`text-accent-three-700`}
                }
              }
            }
          }
        }
      }
    }
  `,
])
