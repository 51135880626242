import { DataGridPro } from '@mui/x-data-grid-pro'
import tw, { styled } from 'twin.macro'

export const StyledDataGrid = styled(DataGridPro)`
  ${tw`rounded-none`}
  ${tw`[& .MuiDataGrid-row]:(border-b-2 border-b-monochrome-200)`}
  ${tw`[& .MuiDataGrid-row:last-child]:border-b-0`}
  ${tw`[& .MuiDataGrid-columnHeader]:bg-monochrome-50`}
  ${tw`[& .MuiDataGrid-columnHeader:first-of-type:not(.MuiDataGrid-columnHeaderCheckbox)]:pl-7`}
  ${tw`[& .MuiDataGrid-cell:first-of-type:not(.MuiDataGrid-cellCheckbox)]:pl-7`}
  ${tw`[& .MuiDataGrid-columnHeaderTitle]:!font-bold`}
`

export const StyledLevelTwoHeader = styled.h2`
  ${tw`text-base`}
`
