import { CSSObject } from 'styled-components'

const KEBAB_CANDIDATES = /([\da-z]|(?=[A-Z]))([A-Z])/g

export const filterKeyContainingString = (
  parsedJson: Record<string, unknown>,
  searchString: string,
) => Object.fromEntries(Object.entries(parsedJson).filter(([key]) => !key.includes(searchString)))

function kebabize(string: string): string {
  return string.replace(KEBAB_CANDIDATES, '$1-$2').toLowerCase()
}

export function convertCssObjectToString(
  cssObject: CSSObject | string | number | undefined,
): string {
  if (!cssObject) return ''
  return (
    Object.entries(cssObject)
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      .map(([k, v]) => convert(k, v))
      .join('\n')
  )
}

function convert(k: string, v: string | number): string {
  return typeof v === 'string'
    ? `  ${kebabize(k)}: ${v};`
    : `
      ${k} {
        ${convertCssObjectToString(v)}
      }
    `
}
