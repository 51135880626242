import { BrowserClient, defaultStackParser, Hub, makeFetchTransport } from '@sentry/react'

export interface ISentryLogger {
  client: BrowserClient
  hub: Hub
}

export const init = (dsn: string): ISentryLogger => {
  const client = new BrowserClient({
    dsn,
    integrations: [],
    tracesSampleRate: 1.0,
    environment: process.env.NX_ENV,
    stackParser: defaultStackParser,
    transport: makeFetchTransport,
  })
  const hub = new Hub(client)
  return { client, hub }
}
