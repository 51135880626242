import Card from '@mui/material/Card'
import tw, { css, styled } from 'twin.macro'

export const StyledCardActionArea = styled(Card)(() => [
  tw`flex h-full flex-col`,
  css`
    &:hover {
      & > .MuiCardActions-root {
        ${tw`bg-primary-700/[0.12]`}
      }
      & [data-id='card-cta'] svg {
        ${tw`[width: 20px]`}
      }
    }
    & [data-id='card-cta'] svg {
      ${tw`w-0 transition-all duration-300 ease-in-out`}
    }
    .MuiCardActionArea-focusHighlight {
      ${tw`hidden`}
    }
  `,
])
