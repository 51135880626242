import { CART_SITES_HOSTS, isLocalhost } from '@brand-console/utilities'
import { useCallback, useEffect, useMemo } from 'react'
import tw from 'twin.macro'

import { useUnifiedSidebarStore } from '../../store/unified-sidebar.store'
import { IMenuItemProps } from '../../unified-sidebar.types'
import { StyledMenuItem } from '../unified-sidebar/UnifiedSidebar.styles'
import { getIcon } from './MenuItem.partials'
import { MenuItemHeader } from './MenuItemHeader'
import { goToUrl } from './MenuItems.functions'

export const MenuItem: React.FC<IMenuItemProps> = ({ node }) => {
  const { isMenuExpanded, isSpa, product, rerenderKey, setRerenderKey } = useUnifiedSidebarStore()
  const {
    location: { pathname: currentPathname },
  } = window
  const { host, pathname } = new URL(node?.url, window.location.origin)

  // Force Rerender on PopState
  const onPopState = useCallback(() => {
    const randomString = Math.random().toString(36).substring(7)
    setRerenderKey(randomString)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isBCJazzDashboard = useMemo(() => {
    if (
      product === 'bc' &&
      currentPathname === '/unified-analytics/fulfillment' &&
      pathname === '/'
    ) {
      return true
    }
    return false
  }, [currentPathname, pathname, product])

  const nodeIsActive = useMemo(() => {
    if (isBCJazzDashboard) {
      return true
    }
    if (isLocalhost) {
      return (
        CART_SITES_HOSTS[product]?.includes(host) &&
        ((window.location.pathname === '/' && pathname === '/') ||
          (pathname?.includes(window.location.pathname) && window.location.pathname !== '/'))
      )
    }
    return (
      window.location.host === host &&
      ((pathname === '/' && window.location.pathname === '/') ||
        (pathname?.includes(window.location.pathname) && window.location.pathname !== '/'))
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [host, isBCJazzDashboard, pathname, product, rerenderKey])

  useEffect(() => {
    window.addEventListener('popstate', onPopState)
    return () => window.removeEventListener('popstate', onPopState)
  }, [onPopState])

  if (!node) {
    return null
  }

  if (node?.nodes?.length > 0) {
    return <MenuItemHeader node={node} />
  }

  return (
    <StyledMenuItem
      isMenuExpanded={isMenuExpanded}
      key={node.title}
      active={nodeIsActive}
      aria-label={node.title}
    >
      <a href={node.url} onClick={goToUrl.bind(this, isSpa)}>
        {node.icon && (
          <span
            css={[
              isMenuExpanded && tw`mr-2`,
              nodeIsActive ? tw`text-primary-700` : tw`text-monochrome-800`,
            ]}
            tw="inline-flex w-5 items-center justify-center"
          >
            {getIcon(node.icon, node.title)}
          </span>
        )}
        {isMenuExpanded && node.title}
      </a>
    </StyledMenuItem>
  )
}
