import tw, { css, styled } from 'twin.macro'

export const StyledTabs = styled.div(() => [
  css`
    & > header {
      ${tw`relative z-10 mb-3 block overflow-x-auto sm:inline-block`}
      &::-webkit-scrollbar {
        display: none;
      }
    }
  `,
])

StyledTabs.displayName = 'Tabs'

export const StyledTabsHeaderList = styled.ul(() => [
  tw`m-0 flex flex-wrap items-center gap-4 p-0 pb-3 font-heading`,
])

interface IHeaderItemProps {
  isActive?: boolean
  isDisabled?: boolean
}

export const StyledTabsHeaderItem = styled.li<IHeaderItemProps>(
  ({ isActive, isDisabled }: IHeaderItemProps) => [
    tw`whitespace-nowrap border-transparent border-b-2 pb-0 font-bold text-monochrome-700`,
    !isDisabled && [
      isActive && tw`border-primary-700 text-primary-700`,
      isActive &&
        css`
          & a,
          & a:hover {
            ${tw`text-primary-700`}
          }
        `,
      css`
        & a:hover {
          ${tw`text-primary-800`}
        }
      `,
    ],
    isDisabled && [tw`pointer-events-none select-none text-monochrome-600`],
  ],
)

export const StyledTabsBody = styled.section(() => [
  tw`relative`,
  css`
    &:after {
      content: '';
      display: table;
      clear: both;
    }
  `,
])
