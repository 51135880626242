import { HubspotFormMessage, Services } from '@brand-console/types'
import { DOMSanitizer, getHubspotCookie, logger } from '@brand-console/utilities'
import { Alert, Button, Card, CardContent, Transition, useBreakpoint, useRouter } from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { add, format } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import tw from 'twin.macro'

import { TrialForm, TrialFormProps } from '../../components/TrialForm'
import {
  useAccountConfirmContext,
  AccountConfirmForm,
  useAccountConfirmForm,
  AccountConfirmFormProvider,
} from '../../components/AccountConfirmForm'
import { comparisonData } from './FeedMarketingTrialRoute.data'
import {
  selectedPlanTransitionProps,
  StyledSelectedPlanInnerContainer,
} from './FeedMarketingTrialRoute.styles'
import { Plans } from './FeedMarketingTrialRoute.types'
import { useCartAuth, useCurrentContext } from '@cartdotcom/auth'
import { useForm } from 'react-hook-form'
import { addNumericalCommas } from '@brand-console/utilities/text'

const trialDays = 15

export const HeaderCell = ({
  activePlan,
  id,
  price,
  startFreeTrial,
  buttonText = 'Start free trial',
}) => {
  const buttonVariant = activePlan === id ? 'contained' : 'outlined'
  const priceText = typeof price !== 'undefined' && price !== 'Custom' ? `$${price}` : 'Custom'
  return (
    <div tw="flex w-full flex-wrap justify-center gap-4 py-4 text-center">
      <h3 tw="w-full font-heading font-bold text-monochrome-900 tracking-tight">{id}</h3>
      <p tw="w-full font-sans text-monochrome-900 text-base">{priceText}</p>
      <Button
        variant={buttonVariant}
        size="small"
        onClick={() => startFreeTrial(Plans[id.toUpperCase()])}
      >
        {buttonText}
      </Button>
    </div>
  )
}

export const PlanCell = ({
  activePlan,
  id,
  startFreeTrial,
  value,
  buttonText = 'Start free trial',
}) => {
  if (typeof value === 'boolean' && value) {
    return (
      <div tw="w-full text-center">
        <FontAwesomeIcon icon={solid('check-circle')} tw="text-primary-700 text-xl" />
      </div>
    )
  }
  if (['Shop', 'Merchant', 'Agency', 'Enterprise'].includes(value)) {
    const buttonVariant = activePlan === id ? 'contained' : 'outlined'
    return (
      <Button
        variant={buttonVariant}
        size="small"
        tw="mx-auto"
        onClick={() => startFreeTrial(Plans[id.toUpperCase()])}
      >
        {buttonText}
      </Button>
    )
  }
  return <div tw="w-full text-center">{DOMSanitizer(addNumericalCommas(value))}</div>
}

export const SelectedPlan = ({ currency, priceDisplay, selectedPlan, setShow }) => {
  const log = logger.setLogger(logger.LoggerNames.FEED_MARKETING)
  const { pathname } = useRouter()
  const isSmall = useBreakpoint('sm')
  const { email, firstName, lastName, phone } = useCartAuth()
  const { currentBusiness, currentOrganization } = useCurrentContext()
  const methods = useForm({ mode: 'onChange' })
  const formData = useAccountConfirmForm({ methods, log })
  const {
    formState: { isValid, isDirty },
    handleSubmit,
  } = methods
  const { submitForm: submitAccountForm, shouldShowForm } = formData

  const [error, setError] = useState<string>(null)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const { navigate } = useRouter()
  const [serverDateTime, setServerDateTime] = useState(new Date())
  const numberOfProducts = useMemo(() => {
    let products = comparisonData.find((x) => x.item === 'Number of products')[
      selectedPlan.toLowerCase()
    ]
    if (typeof products === 'number') {
      products = `${products / 1000}k`
    }
    return products
  }, [selectedPlan])

  useEffect(() => {
    const fetchData = async () => {
      const fetchDataObj = await fetch('/')
      return () => {
        setServerDateTime(new Date(fetchDataObj.headers.get('date')))
      }
    }
    fetchData()
  }, [])

  const trialPaymentDate = format(
    add(serverDateTime, {
      days: trialDays,
    }),
    'LLLL d, yyyy',
  )

  const back = () => {
    setShow(false)
  }

  const onSubmit = async (data) => {
    const portalId = 2773967
    const formId = '4e654040-d462-4bcb-a0a3-b0f262394542'
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`
    const { location } = window
    const fields = []

    const payload = {
      cart_brand_console: true,
      email,
      firstname: data.firstName,
      last_signup_form_step: 'plan',
      lastname: data.lastName,
    }
    new Map(Object.entries(payload)).forEach((value, key) => {
      fields.push({
        objectTypeId: '0-1',
        name: key,
        value,
      })
    })

    const dataWithDefaultValues = {
      firstName: data.firstName || firstName,
      lastName: data.lastName || lastName,
      phone: data.phone || phone,
      businessName: data.businessName || currentBusiness?.name,
      orgName: data.businessName || currentOrganization?.name,
      ...data,
    }

    try {
      setIsSubmitting(true)

      if (
        !(await submitAccountForm({
          data: dataWithDefaultValues,
          onError: (error, friendlyErrorMessage, scope) => {
            log.error(
              'Error submitting account confirmation form',
              {
                friendlyErrorMessage,
                scope,
              },
              error,
            )
            setError(friendlyErrorMessage)
            setIsSubmitting(false)
          },
        }))
      ) {
        return
      }

      await axios.post<HubspotFormMessage>(url, {
        submittedAt: Date.now(),
        fields,
        context: {
          hutk: getHubspotCookie(),
          pageUri: `${location.host}${pathname}`,
          pageName: `Cart.com | Brand Console | ${Services.FEED_MARKETING} | Free Trial`,
        },
      })
    } catch (e) {
      log?.error('FeedMarketingTrialRoute.partials > next', e)
    } finally {
      setIsSubmitting(false)
      navigate('./free-trial/confirm-phone')
    }
  }

  return (
    <AccountConfirmFormProvider value={formData} methods={methods}>
      <form tw="w-full" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Transition show tw="flex w-full justify-center">
          <section tw="flex w-full max-w-3xl justify-center py-10">
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Transition.Child {...selectedPlanTransitionProps} as="div" tw="w-full">
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Card>
                <CardContent css={[isSmall ? tw`px-16 !pb-0` : tw`p-0`]}>
                  <h2 tw="mb-4 text-center">Start your free trial</h2>
                  <p tw="text-center text-base sm:text-lg">
                    You will not be charged during your free trial.
                    <br tw="sm:hidden" />
                    {isSmall && ' '}
                    The first payment will be on {trialPaymentDate}.
                  </p>
                  <StyledSelectedPlanInnerContainer as={isSmall ? 'div' : Card}>
                    <div tw="flex flex-wrap items-center justify-center leading-none">
                      <div tw="mb-2 w-full text-center font-bold text-2xl sm:(mb-0 w-auto pr-4 text-4xl)">
                        {selectedPlan} Plan
                      </div>
                      <div tw="w-full text-center font-bold text-3xl text-primary-700 sm:(w-auto pl-4 text-5xl)">
                        {!Number.isNaN(Number(priceDisplay)) && currency}
                        {priceDisplay}
                      </div>
                      <div tw="mt-4 mb-2 w-full text-center text-base sm:my-7">
                        {numberOfProducts} products included
                      </div>
                    </div>
                    {error && <Alert severity="error">{error}</Alert>}
                    <AccountConfirmForm headerProps={{ style: tw`px-0` }} />
                    <div tw="flex flex-wrap justify-center mt-6 sm:flex-nowrap">
                      <Button
                        type="submit"
                        variant="contained"
                        tw="mb-4 w-full justify-center sm:(order-2 mb-0 ml-2 w-auto)"
                        loading={isSubmitting}
                        disabled={!shouldShowForm ? false : !isValid || !isDirty}
                      >
                        Next
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={back}
                        tw="w-full justify-center sm:(order-1 mr-2 w-auto)"
                      >
                        Back
                      </Button>
                    </div>
                  </StyledSelectedPlanInnerContainer>
                </CardContent>
              </Card>
            </Transition.Child>
          </section>
        </Transition>
      </form>
    </AccountConfirmFormProvider>
  )
}
