/* eslint-disable react/jsx-props-no-spreading */
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import React, { FC, MouseEventHandler, ReactNode, memo, useContext } from 'react'
import { Link, UNSAFE_LocationContext } from 'react-router-dom'
import tw from 'twin.macro'

export interface AppItemProps {
  title: string
  Icon: ReactNode
  href: string
  variant?: 'default' | 'discover'
  desc?: string
  handleClick?: MouseEventHandler<HTMLAnchorElement>
}

const AppItemComp: FC<AppItemProps> = ({ href, handleClick, title, Icon, desc, variant }) => {
  const isReactRouterAvailable = !!useContext(UNSAFE_LocationContext)

  const toHref = isReactRouterAvailable
    ? {
      to: href,
    }
    : {
      href: href !== "#" ? new URL(href, process.env.NX_APP_ORIGIN).href : href
    }

  return (
    <MenuItem
      component={isReactRouterAvailable ? Link : 'a'}
      tw="flex !(px-3 py-2)"
      role="menuitem"
      {...toHref}
      onClick={handleClick}
      data-testid={`apps-dropdown-${title.toLowerCase().replace(/\s+/g, '-')}`}
    >
      <div tw="mr-3.5 min-w-[20px]">{Icon}</div>
      <div tw="flex flex-col">
        <ListItemText
          tw="font-sans font-normal"
          css={[variant === 'discover' ? tw`text-xs leading-5` : tw`leading-6`]}
        >
          <span tw="text-sm">{title}</span>
        </ListItemText>
        {desc && (
          <ListItemText tw="whitespace-normal font-sans font-normal text-monochrome-900/[0.65] text-xs leading-5">
            <span tw="text-sm">{desc}</span>
          </ListItemText>
        )}
      </div>
      {variant === 'discover' && (
        <FontAwesomeIcon
          tw="m-1.5 text-monochrome-900/60"
          icon={solid('arrow-up-right-from-square')}
        />
      )}
    </MenuItem>
  )
}

export const AppItem = memo(AppItemComp)
