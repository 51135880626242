// common
// components
import { DeprecatedInput } from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// icons
import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'

type Props = {
  onChange: (event: string) => void
  id: string
  placeholder?: string
  onSearchIconClick?: (value?: string | undefined) => void | null
  filter?: boolean
  openMobileFilter?: (value: boolean) => void | null
  mobileFilter?: boolean
  actionsActive?: string[]
  noBorder?: boolean
  value: string
}

export const SearchInput = ({
  onChange,
  id,
  filter,
  placeholder,
  onSearchIconClick,
  openMobileFilter,
  mobileFilter,
  actionsActive,
  noBorder,
  value,
}: Props) => {
  const [elem, setElem] = useState(null)
  useEffect(() => {
    setElem(document.getElementById(id))
  }, [id])
  const removeValue = () => {
    elem.value = ''
    onChange('')
    setCloseIcon(false)
    onSearchIconClick && onSearchIconClick('')
  }
  const [closeIcon, setCloseIcon] = useState(false)
  const [focusInput, setFocusInput] = useState(false)
  return (
    <div data-testid="search-input" tw="relative w-full">
      <DeprecatedInput
        onChange={(event: any) => onChange(event.target.value)}
        id={id}
        noBorder={noBorder}
        placeholder={placeholder}
        value={value}
        searchIcon={
          <FontAwesomeIcon
            icon={solid('search')}
            tw="h-5 w-5 cursor-pointer [> path]:fill-monochrome-600"
            onClick={() => onSearchIconClick && onSearchIconClick()}
          />
        }
        trailingIcon={
          <div tw="flex h-full items-center">
            {closeIcon && (
              <FontAwesomeIcon
                icon={solid('times')}
                tw="mr-2 cursor-pointer [> path]:fill-monochrome-600"
                onClick={() => {
                  removeValue()
                }}
              />
            )}
            {filter && (
              <div
                onClick={() => openMobileFilter(!mobileFilter)}
                css={[
                  tw`flex h-full cursor-pointer items-center justify-center border-monochrome-500 border-l-2 w-[43px] md:hidden`,
                  focusInput && tw`border-primary-700`,
                ]}
              >
                <FontAwesomeIcon
                  icon={solid('filter')}
                  tw="h-6 w-6 [> path]:fill-monochrome-600"
                  style={mobileFilter && { fill: '#0a8aee' }}
                />
                {actionsActive && actionsActive.length > 0 && !mobileFilter && (
                  <FontAwesomeIcon
                    icon={solid('circle')}
                    tw="absolute top-1/4 left-3/4 h-2 -translate-y-1.5 translate-x-[-85%] [> path]:fill-primary-700"
                  />
                )}
              </div>
            )}
          </div>
        }
        onFocus={() => {
          setCloseIcon(true)
          setFocusInput(true)
        }}
        onBlur={() => {
          setFocusInput(false)
        }}
        search
        onKeyPress={(event: any) => {
          onChange(event.target.value)
          if (event.key === 'Enter' && onSearchIconClick) {
            onSearchIconClick()
          }
        }}
      />
    </div>
  )
}
