import { Hub } from '@sentry/react'
import * as React from 'react'

interface IErrorBoundaryProps {
  hub: Hub
  children?: React.ReactNode
}

interface IErrorBoundryState {
  hasError: boolean
}

export class SentryErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: { componentStack: string }) {
    const { hub } = this.props
    hub.run((currentHub: Hub) => {
      currentHub.withScope((scope) => {
        scope.setExtras(errorInfo)
        currentHub.captureException(error)
      })
    })
  }

  render() {
    const { children } = this.props
    const { hasError } = this.state
    if (hasError) {
      return null
    }

    return children
  }
}
