import { useAuthToken, useCartAuth } from '@cartdotcom/auth'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { ACSingleSignOn } from '../../authentication/ACSingleSignOn'
import { FetchSfAccountResponse } from '../../queries/fetchSfAccount'

export enum SfSiteStatus {
  NOT_PROVISIONED,
  SINGLE_SITE,
  MULTI_SITE,
  SKIPPED,
}
/** Get the data needed to navigate to storefront   */
export const useSfNavigation = (sfAccount?: FetchSfAccountResponse) => {
  const [status, setStatus] = useState<SfSiteStatus | null>(null)
  const [link, setLink] = useState<string | null>(null)
  const [onClick, setOnClick] = useState<(() => void) | null>(null)
  const { auth0Id } = useCartAuth()
  const token = useAuthToken()
  const sites = useMemo(
    () => sfAccount?.provisioningAccount?.sites || [],
    [sfAccount?.provisioningAccount?.sites],
  )

  const configuredSingleSignOn = useCallback(
    (optionalDomain?: string) => {
      const domain = typeof optionalDomain === 'string' ? optionalDomain : sites[0]?.sharedDomain
      if (!token || !domain) return
      ACSingleSignOn(`https://${domain}/store/admin/login.aspx?fromBc=1`, token, auth0Id)
    },
    [auth0Id, sites, token],
  )

  useEffect(() => {
    if (!sfAccount) {
      setStatus(SfSiteStatus.SKIPPED)
    } else if (sites.length === 1) {
      setStatus(SfSiteStatus.SINGLE_SITE)
    } else if (sites.length > 1) {
      setStatus(SfSiteStatus.MULTI_SITE)
    } else {
      setStatus(SfSiteStatus.NOT_PROVISIONED)
    }
  }, [sfAccount, sites])

  useEffect(() => {
    if (status === SfSiteStatus.SINGLE_SITE) {
      setOnClick(() => configuredSingleSignOn)
      setLink(null)
    } else {
      setOnClick(null)
      setLink('/storefront')
    }
  }, [status, configuredSingleSignOn])

  return {
    provisionedStatus: status,
    sites,
    isEnabled: status === SfSiteStatus.SINGLE_SITE || status === SfSiteStatus.MULTI_SITE,
    link,
    onClick,
  }
}
