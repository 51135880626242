import { activeBizVar } from '@brand-console/generated-graphql-hooks'
import {
  localStorageHelper,
  LocalStorageKeys,
  useLayoutStore,
} from '@brand-console/utilities'
import { Card } from '@cart/ui/molecules/Card'
import { Business, useCurrentContext } from '@cartdotcom/auth'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CardActionArea from '@mui/material/CardActionArea'
import React, { memo } from 'react'
import tw from 'twin.macro'

export const BusinessListItemComp = ({
  business,
  handleClose,
  activeBizId,
}: {
  business: Business
  handleClose: () => void
  activeBizId: number
}) => {
  const { product } = useLayoutStore()
  const { setBusinessIdContext } = useCurrentContext()

  const { id, name } = business
  const isActive = activeBizId === id

  const handleBusinessClick = async (e) => {
    handleClose()
    const { businessname } = e.currentTarget.dataset
    const businessId = parseInt(e.currentTarget.dataset.businessid, 10)
    if (businessId !== activeBizId) {
      const selectedBusiness = { id: businessId, name: businessname }
      localStorageHelper.set(LocalStorageKeys.BUSINESS, selectedBusiness)
      await setBusinessIdContext(businessId)
      if (product === 'mcm') {
        let {
          location: { pathname },
        } = window
        if (
          pathname.includes('no-products') ||
          pathname.includes('no-integrations') ||
          pathname.includes('no-listings')
        ) {
          const locations = pathname.split('/')
          pathname = `/${locations[1]}`
        }
        window.location.href = `${process.env.NX_MCM_ORIGIN}/redirect?businessId=${businessId}&returnUrl=${pathname}`
      } else {
        activeBizVar(selectedBusiness)
        const url = new URL(window.location.href)
        const params = new URLSearchParams(url.search)
        params.delete('businessId')
        if ([...params].length > 0) {
          window.location.search = params.toString()
        }
      }
    }
  }

  return (
    <li data-testid={isActive ? 'account-dropdown-active-business' : undefined}>
      <Card elevation={0} tw="!mt-0">
        <CardActionArea data-businessid={id} data-businessname={name} onClick={handleBusinessClick}>
          <div
            tw="relative px-4 py-3 text-sm leading-6"
            css={[isActive && tw`bg-monochrome-900/[0.08]`]}
          >
            <p
              title={name}
              tw="mb-1 overflow-hidden text-ellipsis whitespace-nowrap pr-8 text-primary-700"
            >
              {name}
            </p>
            <p tw="mb-0 overflow-hidden text-ellipsis whitespace-nowrap">
              Business ID: <span title={`${id}`}>{id}</span>
            </p>
            {isActive && (
              <FontAwesomeIcon
                icon={solid('check-circle')}
                tw="absolute inset-0 right-4 left-auto my-auto text-primary-700 text-base"
              />
            )}
          </div>
        </CardActionArea>
      </Card>
    </li>
  )
}

export const BusinessListItem = memo(BusinessListItemComp)
