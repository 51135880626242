import { IMenu } from '../../Menu/Menu.types'
import { UaMenuIds } from './UaMenu.types'

export const uaMenuBase = [
  {
    id: 'ua',
    items: [
      {
        title: 'Dashboard',
        url: '/unified-analytics/dashboard',
        icon: 'faGaugeHigh',
      },
    ],
  },
  {
    title: 'Settings',
    icon: 'faCog',
    id: 'settings',
    items: [
      {
        title: 'Data Connectors',
        icon: 'faDatabase',
        items: [
          {
            title: 'My Connectors',
            url: '/unified-analytics/my-connectors',
            hidden: true,
            id: UaMenuIds.DATA_CONNECTORS,
          },
          {
            title: 'All Connectors',
            url: '/unified-analytics/all-connectors',
          },
        ],
      },
    ],
  },
] as IMenu[]
