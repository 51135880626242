interface IScrollToOptions {
  offset?: number
}

const getDistanceToDocumentTop = (element: HTMLElement): number => {
  const rect = element.getBoundingClientRect()
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  return Math.round(rect.top + scrollTop)
}

export const scrollTo = (selector: string, options?: IScrollToOptions) => {
  const element = document.querySelector(selector) as HTMLElement
  if (element) {
    const headerHeight = document.querySelector('[data-testid="cart-header"]')?.clientHeight || 0
    const elementPosition = Math.floor(element.offsetTop)
    const { offset = 0 } = options || {}
    const currentScrollPosition = window.scrollY
    const newScrollPosition = getDistanceToDocumentTop(element) - headerHeight - offset
    const shouldScroll = newScrollPosition !== currentScrollPosition
    if (element && elementPosition !== 0 && shouldScroll) {
      window.scrollTo({
        top: getDistanceToDocumentTop(element) - headerHeight - offset,
        behavior: 'smooth',
      })
    }
  }
}
