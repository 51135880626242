import { Input } from '@cart/ui/atoms/Input'
import { useBreakpoint } from '@cart/ui/hooks/useBreakpoint'
import React from 'react'

import { asinMultiPattern, requiredRule } from '../helpers'
import { Form } from './Form'

const Description = () => (
  <>
    Create an Amazon search result page of your ASIN list.
    <br /> Each line should be a single ASIN. Do not use commas or spaces.
    <br /> Up to 50 ASINs can be specified.
  </>
)

export const ASINSearch = () => {
  const isSmall = useBreakpoint('sm')

  const generateUrl = (data: { asins: string }) => {
    const asins = data.asins.split(/\r?\n/)
    return `https://www.amazon.com/s/?k=${asins.reduce(
      (acc, asin, index) => `${acc}${index === 0 ? '' : '%7C+'}${asin}`,
    )}&ref=nb_sb_noss`
  }

  return (
    <Form
      handleGenerateLink={generateUrl}
      title="Targeted ASIN Search URL"
      description={<Description />}
      example="Example: https://www.amazon.com/s/?k=B0BX29KM91%7C+B0BX28Y8ZY&ref=nb_sb_noss"
    >
      <Input
        id="asins"
        label="ASINs"
        placeholder="ASINs"
        type="text"
        inputProps={{ maxLength: 549 }}
        multiline
        rows={isSmall ? 10 : 2}
        rules={{ ...asinMultiPattern, ...requiredRule }}
      />
    </Form>
  )
}
