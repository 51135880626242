import React, { useEffect, useState } from 'react'
import { usecannySsoMutation } from '@brand-console/generated-graphql-hooks'
import { Spinner } from '@cart/ui'

const BoardToken = 'b5b67eca-274a-63a3-a818-2564a9a4db70'

export const CannyFeatureRequest = () => {
  const [cannySso, { data, loading }] = usecannySsoMutation()
  const ssoToken = data?.org_userCannySso

  useEffect(() => {
    cannySso()
  }, [cannySso])

  useEffect(() => {
    if (!ssoToken) {
      return
    }

    // This script is copy & pasted directly from the Canny docs: https://developers.canny.io/install/widget/web
    // The file is JSX file so that it can be embedded without have to worry about spending
    // unneded time on fixing obscure typechecking errors.
    ;(function (w, d, i, s) {
      function l() {
        if (!d.getElementById(i)) {
          const f = d.getElementsByTagName(s)[0]
          const e = d.createElement(s)
          ;(e.type = 'text/javascript'),
            (e.async = !0),
            (e.src = 'https://canny.io/sdk.js'),
            f.parentNode.insertBefore(e, f)
        }
      }
      if (typeof w.Canny !== 'function') {
        var c = function () {
          c.q.push(arguments)
        }
        ;(c.q = []),
          (w.Canny = c),
          d.readyState === 'complete'
            ? l()
            : w.attachEvent
            ? w.attachEvent('onload', l)
            : w.addEventListener('load', l, !1)
      }
    })(window, document, 'canny-jssdk', 'script')

    Canny('render', {
      boardToken: BoardToken,
      basePath: '/feature-requests',
      ssoToken,
      theme: 'light', // options: light [default], dark, auto
    })
  }, [ssoToken])

  if (loading) {
    return <Spinner />
  }

  return <div data-canny tw="p-4" />
}
