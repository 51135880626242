import { useReactiveVar } from '@apollo/client'
import { useOrg_userConfirmMfaEnrollmentMutation } from '@brand-console/generated-graphql-hooks'
import { appendIfValid, countryCodes, logger } from '@brand-console/utilities'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Select,
  SelectItem,
  Spinner,
} from '@cart/ui'
import React, { FC, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { AuthenticationMethod, mfaTokenVar, recoveryCodeVar } from '../SettingsSecurity.partials'
import { ISuccess, ITokenError, ModalProps } from '../SettingsSecurity.types'

export const EnableSMSModal: FC<ModalProps<ISuccess, ITokenError>> = ({
  show,
  closeCallback,
  successCallback,
  handleTokenErrorCallback,
}) => {
  const log = logger.setLogger(logger.LoggerNames.SECURITY)
  const [showFailureAlert, setShowFailureAlert] = useState(false)
  const [failureAlertMessage, setFailureAlertMessage] = useState('An unspecified error occurred.')
  const [phoneNumberVal, setPhoneNumber] = useState('')
  const [countryPhoneCode, setCountryPhoneCode] = useState('+1') // default to US, used for form submission
  const [SMSOobCode, setSMSOobCode] = useState('')
  const [modalPage, setModalPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const methods = useForm()
  const { handleSubmit, reset } = methods
  const tokenVar = useReactiveVar(mfaTokenVar)

  interface EnrollSmsResponse {
    authenticator_type: string
    binding_method: string
    recovery_codes: string[]
    oob_channel: string
    oob_code: string
  }

  const resetModalOnExited = () => {
    setPhoneNumber('')
    setSMSOobCode('')
    setShowFailureAlert(false)
    setFailureAlertMessage('')
    setModalPage(1)
    reset()
  }

  const requestSms = async (phoneNumber) => {
    try {
      setIsLoading(true)
      const body = {
        authenticator_types: ['oob'],
        oob_channels: ['sms'],
        phone_number: phoneNumber,
      }
      const response = await fetch(`https://${process.env.NX_AUTH0_DOMAIN}/mfa/associate`, {
        method: 'POST',
        headers: { authorization: `Bearer ${tokenVar}`, 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      })
      setIsLoading(false)
      const responseJSON: EnrollSmsResponse = await response.json()
      if (responseJSON?.recovery_codes) {
        recoveryCodeVar(responseJSON.recovery_codes[0])
      }
      if (responseJSON.oob_code) {
        setSMSOobCode(responseJSON.oob_code)
        setModalPage(2)
      }
    } catch (error) {
      handleTokenErrorCallback('enablesms')
      log.error('Enable SMS error: requestSms', error)
      setFailureAlertMessage('')
      setShowFailureAlert(true)
    }
  }

  const [confirmSms, { loading }] = useOrg_userConfirmMfaEnrollmentMutation({
    onCompleted: (data) => {
      if (data.org_userConfirmMfaEnrollment.access_token) {
        successCallback(
          `${AuthenticationMethod[AuthenticationMethod.SMS]} based MFA successfully enabled!`,
          AuthenticationMethod.SMS,
        )
        resetModalOnExited()
        closeCallback()
      }
    },
    onError: (error) => {
      log.error('Enable SMS error: useOrg_userConfirmMfaEnrollmentMutation', error)
      setFailureAlertMessage('')
      setShowFailureAlert(true)
      handleTokenErrorCallback('enablesms')
    },
  })

  const onSubmit1 = (data) => {
    if (data.phonenumber === null) {
      setFailureAlertMessage('You must enter a phone number to continue')
      setShowFailureAlert(true)
    } else {
      setPhoneNumber(data.phonenumber)
      setCountryPhoneCode(data.countrycode)
      requestSms(data.countrycode + data.phonenumber)
    }
  }

  const onSubmit2 = (data) => {
    if (data.verificationcode === null) {
      setFailureAlertMessage('You must enter the code sent via SMS')
      setShowFailureAlert(true)
    } else {
      confirmSms({
        variables: {
          confirmEnrollmentInput: {
            mfatoken: tokenVar,
            oobcode: SMSOobCode,
            bindingcode: data.verificationcode,
            type: 'SMS',
          },
        },
      })
    }
  }

  return (
    <Dialog
      width="sm"
      open={show}
      id="enablesmsdialog"
      onClose={() => {
        resetModalOnExited()
        closeCallback()
      }}
    >
      <DialogTitle>Multi-factor authentication</DialogTitle>
      <DialogContent dividers>
        {isLoading || loading ? (
          <Spinner tw="m-auto mb-8 h-64 w-64" />
        ) : (
          <div tw="flex w-full flex-col px-2 text-left mobile:px-1">
            {(() => {
              switch (modalPage) {
                case 1:
                  return (
                    <>
                      {/* Failure Alert */}
                      {showFailureAlert && (
                        <Alert
                          tw="mb-3.5"
                          severity="error"
                          onClose={() => {
                            setShowFailureAlert(false)
                            setFailureAlertMessage('')
                          }}
                        >
                          {appendIfValid(
                            'Something went wrong when attempting to request your SMS code',
                            failureAlertMessage,
                          )}
                        </Alert>
                      )}
                      <p>
                        Enter your phone number below. A SMS will be sent to that number with a code
                        to enter on the next screen.
                      </p>
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      <FormProvider {...methods}>
                        <form
                          onSubmit={handleSubmit(onSubmit1)}
                          noValidate={false}
                          autoComplete="off"
                          tw="w-full"
                        >
                          <Select tw="pb-4" id="countrycode" label="Country Code:" required>
                            {countryCodes.map((country) => {
                              return (
                                <SelectItem key={country.name} value={`${country.dialCode}`}>
                                  {country.name} {country.dialCode}
                                </SelectItem>
                              )
                            })}
                          </Select>
                          <Input
                            id="phonenumber"
                            label="Enter your Phone number:"
                            placeholder="Phone Number"
                            required
                            tw="mb-4 w-full"
                            type="tel"
                          />
                        </form>
                      </FormProvider>
                    </>
                  )
                case 2:
                  return (
                    <>
                      {/* Failure Alert */}
                      {showFailureAlert && (
                        <Alert
                          tw="mb-3.5"
                          severity="error"
                          onClose={() => {
                            setShowFailureAlert(false)
                            setFailureAlertMessage('')
                          }}
                        >
                          {appendIfValid(
                            'Something went wrong when attempting to confirm your SMS code',
                            failureAlertMessage,
                          )}
                        </Alert>
                      )}
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      <FormProvider {...methods}>
                        <form
                          onSubmit={handleSubmit(onSubmit2)}
                          noValidate={false}
                          autoComplete="off"
                          tw="w-full"
                        >
                          <div tw="pb-3.5">
                            A verification code SMS was sent to:{' '}
                            <b>
                              {countryPhoneCode} {phoneNumberVal}
                            </b>
                          </div>
                          <Input
                            id="verificationcode"
                            label="Enter verification code"
                            required
                            tw="mb-4"
                            type="text"
                          />
                          <div tw="flex flex-row">
                            <div tw="self-center pr-2">Didn&apos;t receive a code? </div>
                            <Button
                              disabled={loading || isLoading}
                              variant="outlined"
                              onClick={() => requestSms(countryPhoneCode + phoneNumberVal)}
                            >
                              Resend
                            </Button>
                          </div>
                        </form>
                      </FormProvider>
                    </>
                  )
                default:
                  return <div>error</div>
              }
            })()}
          </div>
        )}
      </DialogContent>
      {(() => {
        switch (modalPage) {
          case 1:
            return (
              <DialogActions>
                <div>
                  <Button
                    type="submit"
                    onClick={() => setShowFailureAlert(false)}
                    disabled={loading || isLoading}
                    variant="contained"
                  >
                    Continue
                  </Button>
                </div>
              </DialogActions>
            )
          case 2:
            return (
              <DialogActions>
                <Button
                  type="submit"
                  onClick={() => setShowFailureAlert(false)}
                  variant="contained"
                  disabled={loading || isLoading}
                >
                  Continue
                </Button>
              </DialogActions>
            )
          default:
            return null
        }
      })()}
    </Dialog>
  )
}
