import React, { FC } from 'react'

import { TabItemProps } from './tab-item.types'

export const TabItem: FC<TabItemProps & Record<string, unknown>> = ({ children }: TabItemProps) => (
  <div>{children}</div>
)

TabItem.displayName = 'TabItem'

export default TabItem
