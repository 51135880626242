import React from 'react'

type Props = {
  message?: any
}

export const ErrorsMessage = ({ message }: Props) => {
  return <div className="text-left text-danger">{message}</div>
}

ErrorsMessage.defaultProps = {
  message: null,
}
