import { Spinner } from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { lazy, Suspense } from 'react'
import { createGlobalStyle } from 'styled-components'
import { useIsCsr } from '@cartdotcom/auth/useIsCsr'

const CustomStyles = createGlobalStyle`
  #cart-settings {
    padding: 0 !important;
  }
  #cart-settings-breadcumbs {
    display: none;
  }
`

const AdminToolsApp = lazy(() => import('./AdminToolsLazy'))

export const AdminTools = () => {
  const isCsr = useIsCsr()
  if (isCsr === null) return <Spinner type="global" />

  if (!isCsr) {
    return (
      <>
        <CustomStyles />
        <div tw="absolute inset-0 flex flex-wrap items-center justify-center">
          <div tw="p-8 text-center">
            <FontAwesomeIcon icon={solid('user-lock')} tw="mb-4 text-5xl text-primary-700" />
            <p tw="w-full font-bold text-xl text-monochrome-700">
              Sorry, but you don&apos;t have access to this page.
            </p>
            <p>If you think this is a mistake, please reach out to your administrator.</p>
          </div>
        </div>
      </>
    )
  }

  return (
    <Suspense fallback={<Spinner type="global" />}>
      <CustomStyles />
      <AdminToolsApp />
    </Suspense>
  )
}
