import { useOrganizationUpdateMutation } from '@brand-console/generated-graphql-hooks'
import { capitalize, localStorageHelper, LocalStorageKeys, logger } from '@brand-console/utilities'
import { Button, Card, CardContent, Input } from '@cart/ui'
import { isAtLeastOrgAdmin, useCurrentContext } from '@cartdotcom/auth'
import MUICardHeader from '@mui/material/CardHeader'
import React, { ReactElement, useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { BusinessManagement } from '../BusinessManagement/BusinessManagement'
import {
  OrganizationHeader,
  SettingsOrganizationCardHeader,
} from '../SettingsOrganization.partials'
import { useSettingsOrganizationsContext } from '../SettingsOrganizations.store'

export const OrganizationOverview = (): ReactElement => {
  const log = logger.setLogger(logger.LoggerNames.ORGANIZATION)
  const { currentOrganization, roleName } = useCurrentContext()
  const { id: organizationId } = currentOrganization
  const isOrgAdminOrAbove = isAtLeastOrgAdmin(roleName)

  const methods = useForm({
    mode: 'onChange',
    defaultValues: { organizationName: currentOrganization.name },
  })
  const {
    handleSubmit,
    formState: { isDirty },
  } = methods

  const { clearAlert, setAlert } = useSettingsOrganizationsContext()

  const persistOrg = (cache, { data: { org_organizationUpdate } }) => {
    window.setTimeout(() => {
      const persistedUpdatedOrganization = {
        ...currentOrganization,
        ...org_organizationUpdate,
      }
      localStorageHelper.set(LocalStorageKeys.ORGANIZATION, persistedUpdatedOrganization)
      window.location.reload()
    }, 2000)
  }

  const [updateOrganization, { loading: updateOrganizationIsLoading }] =
    useOrganizationUpdateMutation({
      update: persistOrg,
      onError: (error) => {
        setAlert({
          variant: 'error',
          text: capitalize(error.message),
        })
      },
    })

  const onSubmit = useCallback(
    (formData) => {
      const { organizationName, secondaryLevel } = formData
      updateOrganization({
        variables: {
          updateOrganizationInput: {
            name: organizationName,
            id: organizationId,
            businessEntityTerminology: secondaryLevel,
          },
        },
        onError(error) {
          log.error('OrganizationOverview GraphQL error', error)
        },
        onCompleted() {
          setAlert({
            variant: 'success',
            text: `Organization settings were changed successfully!`,
          })
        },
      })
    },
    [log, organizationId, setAlert, updateOrganization],
  )

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <div tw="flex justify-between">
          <OrganizationHeader />
          {isOrgAdminOrAbove && (
            <div>
              <Button
                type="submit"
                tw="z-10"
                onClick={clearAlert}
                variant="contained"
                disabled={!isDirty}
                loading={updateOrganizationIsLoading}
              >
                Save
              </Button>
            </div>
          )}
        </div>
        <div tw="order-1 mb-4 w-full lg:order-2">
          <Card tw="mb-8" title="Overview">
            {/* somehow our card header won't let us use a custom component */}
            <MUICardHeader component={() => SettingsOrganizationCardHeader({ text: 'Overview' })} />
            <CardContent tw="border-t-2 border-t-monochrome-200 !pb-0">
              <Input
                id="organizationName"
                name="organizationName"
                label="Organization Name"
                disabled={!isOrgAdminOrAbove}
                required
              />
              <h2 tw="mx-2 mt-9 mb-4 text-base">Businesses</h2>
              <BusinessManagement />
            </CardContent>
          </Card>
        </div>
      </form>
    </FormProvider>
  )
}
