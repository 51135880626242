export const UAPackages = {
  standAlone: [
    {
      title: 'UA Core',
      subtitle:
        'The essential analytics package tailored for efficient e-commerce optimization. With U-shaped attribution and sales channel insights, UA Core empowers businesses to make data-driven decisions and unlock valuable insights for campaign success and growth.',
      gmv: [
        { range: 'Under $1M', price: '350' },
        { range: '$1-5M', price: '500' },
        { range: '$5-10M', price: '1,000' },
        { range: '$10-15M', price: '1,500' },
        { range: '$15-20M', price: '2,000' },
        { range: '$20M+', price: 'Custom' },
      ],
      features: [
        { title: 'Marketing Analytics', comingSoon: false },
        { title: 'Sales Analytics', comingSoon: false },
        { title: 'Web Analytics', comingSoon: false },
        { title: 'Fulfillment Analytics', comingSoon: false },
        { title: 'Multi-Touch Attribution Non-AI', comingSoon: true },
        { title: 'Custom Views (Limited Access)', comingSoon: false },
        { title: '5 users', comingSoon: false },
        { title: 'Emailed reports (Limited Access)', comingSoon: true },
        { title: 'Marketplace Analytics', comingSoon: true },
      ],
    },
    {
      title: 'UA Plus',
      subtitle:
        'A comprehensive analytics solution that combines data harmonization, pixel-based machine learning attribution, fulfillment analytics, and channels and product feed insights. From optimized marketing campaigns to streamlined fulfillment operations, UA Plus empowers you to make data-driven decisions and propel your business forward.',
      gmv: [
        { range: 'Under $1M', price: '700' },
        { range: '$1-5M', price: '1,000' },
        { range: '$5-10M', price: '2,000' },
        { range: '$10-15M', price: '3,000' },
        { range: '$15-20M', price: '4,000' },
        { range: '$20M+', price: 'Custom' },
      ],
      features: [
        { title: 'Marketing Analytics', comingSoon: false },
        { title: 'Sales Analytics', comingSoon: false },
        { title: 'Web Analytics', comingSoon: false },
        { title: 'Fulfillment Analytics', comingSoon: false },
        { title: 'Multi-Touch Attribution with AI', comingSoon: false },
        { title: 'Custom Views', comingSoon: false },
        { title: 'Unlimited users', comingSoon: false },
        { title: 'Emailed reports', comingSoon: true },
        { title: 'Marketplace Analytics', comingSoon: true },
        { title: 'Demand Forecasting', comingSoon: false },
        { title: 'Unlimited Connectors', comingSoon: false },
      ],
    },
  ],
  coSell: [
    {
      title: 'Marketing Analytics',
      link: { title: 'Growth Marketing', link: 'https://cart.com/solution/growth-marketing' },
      subtitle:
        'Harmonizes data from your media & analytics tools, providing a unified and accurate source of information. Pixel-based attribution optimizes recommendations for reallocating campaign spend, allowing you to effectively enhance your ROAS.',
      priceRange: [],
      pricing: { value: '1%', text: 'of managed ads spend' },
      features: [
        { title: 'Marketing Analytics', comingSoon: false },
        { title: 'Sales Analytics', comingSoon: false },
        { title: 'Web Analytics', comingSoon: false },
        { title: 'Marketplace Analytics', comingSoon: true },
        { title: 'Unlimited Connections', comingSoon: false },
        { title: 'Custom Views (Limited Access)', comingSoon: false },
        { title: 'Emailed reports', comingSoon: true },
        { title: '15 users', comingSoon: false },
      ],
    },
    {
      title: 'Fulfillment Analytics',
      link: { title: 'Fulfillment Services', link: 'https://cart.com/solution/fulfillment' },
      subtitle:
        'Integrates with your warehouse order management system, providing real-time insights into SLAs, fulfillment cycle times, and SKU-level demand forecasting, empowering you to optimize operations and make data-driven decisions.',
      priceRange: [
        { range: '0-60K', price: '300' },
        { range: '60-120K', price: '800' },
        { range: '120-240K', price: '1,500' },
        { range: '240K+', price: 'Custom' },
      ],
      pricing: null,
      features: [
        { title: 'Service Level Analytics', comingSoon: false },
        { title: 'Demand Forecasting (SKU+Geo)', comingSoon: false },
        { title: 'SKU-Level Inventory Turnover', comingSoon: false },
        { title: 'Multi-Node Optimization', comingSoon: false },
        { title: 'Cycle Time Insights', comingSoon: false },
        { title: 'AI Driven Stock Alerts', comingSoon: false },
        { title: 'Custom Views', comingSoon: false },
        { title: 'Emailed reports', comingSoon: true },
        { title: '10 users', comingSoon: false },
      ],
    },
    {
      title: 'Channels Analytics',
      link: { title: 'Channel Management', link: 'https://cart.com/solution/channels' },
      subtitle:
        'Optimize your product feeds, by gaining visibility into all sales channels and product descriptions associated with a single SKU, empowering you to identify top-performing feeds and make informed decisions. Maximize your reach and sales potential across multiple sales channels with ease.',
      priceRange: [],
      pricing: { value: '$75', text: '/ month' },
      features: [
        { title: 'Marketing Analytics', comingSoon: false },
        { title: 'Web Analytics', comingSoon: false },
        { title: 'Marketplace Analytics', comingSoon: true },
        { title: 'Feeds Analytics', comingSoon: false },
        { title: 'Rules Engine', comingSoon: true },
        { title: 'Custom Views', comingSoon: false },
        { title: 'Emailed reports', comingSoon: true },
        { title: '10 users', comingSoon: false },
      ],
    },
  ],
}
