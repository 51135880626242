import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@mui/material'
import React, { useMemo } from 'react'
import tw from 'twin.macro'

interface IconButtonProps extends MuiIconButtonProps {
  /**
   * The shape of the focus area of the button.
   */
  shape?: 'circle' | 'square'
}

export const IconButton = React.forwardRef<
  HTMLButtonElement,
  IconButtonProps & { to?: string; href?: string }
>(({ shape = 'circle', ...props }, ref) => {
  const { size } = props

  const iconSize = useMemo(() => {
    if (size === `small`) {
      return tw`[&>svg]:(h-[16px] w-[16px])`
    }
    if (size === `large`) {
      return tw`[&>svg]:(h-[28px] w-[28px])`
    }
    return tw`[&>svg]:(h-[20px] w-[20px])`
  }, [size])

  return (
    <MuiIconButton
      ref={ref}
      data-testid="icon-button"
      css={[
        // eslint-disable-next-line prettier/prettier
          shape === 'square' && tw`hover:rounded [& .MuiTouchRipple-root .MuiTouchRipple-child]:rounded`,
        iconSize,
      ]}
      {...props}
    />
  )
})
