import { findDeep } from 'deepdash-es/standalone'
import { type SideMenuTenant } from 'libs/unified-sidebar/src/gql/graphql'

export const getTenantByTenantCode = (tenants: SideMenuTenant[], tenant: SideMenuTenant) => {
  const $tenant = findDeep(
    tenants,
    (_tenant) => {
      return (
        (!!_tenant.tenantCode && _tenant.tenantCode === tenant.tenantCode) ||
        (!!_tenant.businessId && _tenant.businessId === tenant.businessId) ||
        (!!_tenant.organizationId && _tenant.organizationId === tenant.organizationId)
      )
    },
    {
      childrenPath: ['nodes'],
    },
  )
  return $tenant
}
