import type { dataConnectorsFields } from './data'
// import {DATA_CONN}
export type TitlesCommonTableType = {
  dataConnectorsPage: { title: string; filter: boolean; fieldName?: string; checkbox: boolean }[]
  emailReportsPage: { title: string; filter: boolean; fieldName?: string; checkbox: boolean }[]
}

export type FooterDataType = {
  link: string
  text: string
}

export type RegexDataType = {
  api_key: any
  start_date: any
  customer_id: any
  login_customer_id: any
  view_id: any
  property_id: any
  account_id: any
  site_urls: any
  apikey: any
  username: any
}

export type MenuSectionType = {
  name: string
  key: string
  items: MenuItemType[]
}

export type MenuItemType = {
  name: string
  icon?: string
  link?: string
  order?: number
  children?: MenuSecondLevelType[] | []
}

export type MenuSecondLevelType = {
  name: string
  link: string
  order: number
}

// this is defined in the data_connector_categories.json file in the MIA API repo
export enum DATA_CONNECTOR_CATEGORIES {
  'Marketing Analytics' = 1,
  'Sales Analytics' = 2,
  'Web Analytics' = 4,
  'Cart.com' = 5,
}

export type DataConnector = {
  title: string
  category: DATA_CONNECTOR_CATEGORIES
  description?: string
  subTitle?: string
  icon?: string
  name: keyof typeof dataConnectorsFields
  button: boolean
  connectorName: string
  order?: number
}

export type ShopifyConnectorType = {
  shopify: {
    title: string
    validate: any
    fields: {
      name: 'activationCode'
      label: string
      placeholder: string
      type: string
    }[]
    initialValues: {
      activationCode: string
    }
  }
}

export type ActionsDropdownCommonTableType = {
  dataConnectorsPage: { title: string; fieldName: string }[]
  emailReportsPage: { title: string; fieldName: string }[]
}

export type ButtonsCommonTableType = {
  dataConnectorsPage: { title: string; buttonName: string }[]
  emailReportsPage: { title: string; buttonName: string }[] | null
}

export type FormikValues = {
  business: string
  period: string
  additional_emails?: string
  send_report: boolean
  report: string
  schemas: any
  logical_operator: string
  [key: string]: any
}

export type ReportToDeleteType = {
  id: number
  name: string
}
