import { useMeQuery } from '@brand-console/generated-graphql-hooks'
import { HubspotFormMessage, Services } from '@brand-console/types'
import {
  countryCodes,
  getHubspotCookie,
  logger,
  validatePhoneWithCCodeRule,
} from '@brand-console/utilities'
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardFooter,
  Input,
  Select,
  SelectItem,
  Spinner,
  useBreakpoint,
  useRouter,
} from '@cart/ui'
import { useCurrentContext } from '@cartdotcom/auth'
import axios from 'axios'
import React, { useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import tw from 'twin.macro'

import { useFeedMarketingStore } from '../../components/FeedMarketing/FeedMarketing.store'

export const FeedMarketingConfirmPhoneRoute = () => {
  const log = logger.setLogger(logger.LoggerNames.FEED_MARKETING)
  const { pathname, navigate } = useRouter()
  const formMethods = useForm()

  const { control } = formMethods

  const isSmall = useBreakpoint('sm')
  const { planId } = useFeedMarketingStore()

  const { currentBusiness, currentOrganization } = useCurrentContext()

  const { data } = useMeQuery()
  const { firstName, lastName, primaryEmail: email } = data?.me?.user || {}
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const cCode = useWatch({
    control,
    name: 'countryCode',
  })

  const redirectToFeedMarketing = (query: string) => {
    window.location.href = `${process.env.NX_DFW_REGISTRATIONS_API_URL}?${query}`
  }
  const formSubmit = async ({ countryCode, phoneNumber: phone_number }) => {
    setIsSubmitting(true)
    const params = {
      business_id: currentBusiness.id.toString(),
      organization_id: currentOrganization.id.toString(),
      phone_dial_number: countryCode,
      phone_number,
      plan_id: planId.toString(),
      first_name: firstName,
      last_name: lastName,
    }
    const query = new URLSearchParams(params).toString()

    const portalId = 2773967
    const formId = '4e654040-d462-4bcb-a0a3-b0f262394542'
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`
    const { location } = window
    const fields = []

    const payload = {
      country_code: countryCode,
      email,
      last_signup_form_step: 'phone',
      phone: phone_number,
    }
    new Map(Object.entries(payload)).forEach((value, key) => {
      fields.push({
        objectTypeId: '0-1',
        name: key,
        value,
      })
    })

    try {
      setIsSubmitting(true)
      await axios.post<HubspotFormMessage>(url, {
        submittedAt: Date.now(),
        fields,
        context: {
          hutk: getHubspotCookie(),
          pageUri: `${location.host}${pathname}`,
          pageName: `Cart.com | Brand Console | ${Services.FEED_MARKETING} | Confirm Phone Number`,
        },
      })
    } catch (e) {
      log?.error(
        'FeedMarketingConfirmPhoneRoute > formSubmit',
        {
          fields,
          pageUri: `${location.host}${pathname}`,
          pageName: `Cart.com | Brand Console | ${Services.FEED_MARKETING} | Confirm Phone Number`,
        },
        e,
      )
    } finally {
      redirectToFeedMarketing(query)
    }
  }

  const validateCombinedPhone = (value: string) => {
    return validatePhoneWithCCodeRule(cCode || '+1', value)
  }

  if (!data) {
    return <Spinner tw="m-auto h-64 w-64" type="global" />
  }

  return (
    <section tw="mx-auto w-full max-w-screen-xl px-3.5">
      <Breadcrumbs
        tw="relative z-10 mb-10 mt-7 sm:-ml-2"
        items={[
          { text: 'Home', href: '/' },
          { text: Services.FEED_MARKETING, href: '/feed-marketing' },
          { text: 'Free Trial', href: '/feed-marketing/free-trial' },
          { text: 'Confirm Phone' },
        ]}
      />
      <Card tw="mx-auto max-w-2xl">
        <CardContent tw="px-8 py-4">
          <h2 tw="mb-8 text-xl">Confirm your phone number</h2>
          <p tw="mb-7 text-base">
            A product expert will contact you to help you set up your account.
          </p>
          {/* eslint-disable-next-line react/jsx-props-no-spreading  */}
          <FormProvider {...formMethods}>
            <form noValidate onSubmit={formMethods.handleSubmit(formSubmit)} tw="mx-auto w-full">
              <div tw="mx-auto flex flex-col items-start sm:flex-row">
                <div tw="mb-6 flex w-full grow-0 flex-wrap items-center justify-center pr-2 leading-none sm:w-6/12">
                  <Select id="countryCode" label="Country code" defaultValue="+1">
                    {countryCodes.map((country) => {
                      return (
                        <SelectItem key={country.name} value={country.dialCode}>
                          {`${country.name} ${country.dialCode}`}
                        </SelectItem>
                      )
                    })}
                  </Select>
                </div>
                <div tw="flex w-full grow-0 flex-wrap items-center justify-center leading-none sm:w-6/12">
                  <Input
                    type="tel"
                    id="phoneNumber"
                    label="Phone number"
                    rules={{
                      validate: validateCombinedPhone,
                    }}
                    required
                  />
                </div>
              </div>
              <CardFooter tw="justify-end">
                <Button onClick={() => navigate('/feed-marketing')}>Back</Button>
                <Button type="submit" variant="contained" loading={isSubmitting}>
                  Continue to payment
                </Button>
              </CardFooter>
            </form>
          </FormProvider>
        </CardContent>
      </Card>
    </section>
  )
}
