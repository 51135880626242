import DOMPurify, { Config } from 'dompurify'
import parse from 'html-react-parser'
import React from 'react'

export const DOMSanitizer = (content: string | null, options?: Config) => {
  if (!content) {
    return null
  }
  const html = options
    ? DOMPurify.sanitize(content, { ...options, RETURN_DOM_FRAGMENT: false, RETURN_DOM: false })
    : DOMPurify.sanitize(content)
  return <>{parse(html)}</>
}

export default DOMSanitizer
