import { init, ISentryLogger, isLocalhost } from '@brand-console/utilities'

let logger: ISentryLogger

export const initEmbeddableSidebarLogger = () => {
  logger = init(process.env.NX_SENTRY_UNIFIED_SIDEBAR_DSN)
  logger.client.getOptions().enabled = !isLocalhost
  return logger
}

export const log = (message: string) => {
  const client = logger?.client ?? initEmbeddableSidebarLogger().client
  client.captureMessage(message)
}

export const logError = (exception) => {
  if (isLocalhost) {
    // eslint-disable-next-line no-console
    console.error(exception)
  }
  const client = logger?.client ?? initEmbeddableSidebarLogger().client
  client.captureException(exception)
}
