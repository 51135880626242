import { Card, CardContent, CardFooter } from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CardActionArea, Paper } from '@mui/material'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { StyledCardActionArea } from './AppCard.styles'
import { AppCardProps } from './AppCard.types'

export const AppCard: FC<AppCardProps> = ({
  buttonText,
  integrations,
  link,
  title,
  description,
  Icon,
}) => (
  <Card hoverElevation={8} tw="w-full sm:max-w-[320px]">
    <StyledCardActionArea>
      <CardActionArea tw="[flex-direction: column] flex h-full" component={Link} to={link}>
        <CardContent tw="flex-1 pb-6">
          <div tw="mb-1 font-heading font-semibold text-base">{title}</div>
          <div tw="text-monochrome-900/[0.65]">
            <div tw="mb-2.5 text-sm">{description}</div>
            <div tw="flex items-center gap-2.5">
              {integrations?.map((Integration, i) => {
                return <Integration key={`appcard-${title}-${i}`} tw="h-5" />
              })}
              {!!integrations?.length && <span tw="whitespace-nowrap text-xs">and more!</span>}
            </div>
          </div>
        </CardContent>
        <CardFooter tw="flex w-full justify-between bg-primary-700/[0.06] px-4 py-3">
          <Paper elevation={0} tw="flex p-1.5">
            <Icon tw="w-5" />
          </Paper>
          <div data-id="card-cta" tw="font-heading font-semibold text-primary-700 text-sm">
            {buttonText || 'Get started'}
            <FontAwesomeIcon tw="pl-2.5" icon={solid('arrow-right')} />
          </div>
        </CardFooter>
      </CardActionArea>
    </StyledCardActionArea>
  </Card>
)
