import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select'
import React, { FC, useEffect, useState } from 'react'
import { useController, Validate } from 'react-hook-form'

import formMethods from '../../framework/forms/form-methods'
import { SelectProps } from './select.types'

export const Select: FC<SelectProps> = ({
  children,
  defaultValue,
  disabled,
  id,
  label,
  multiple = false,
  required,
  variant = 'outlined',
  open,
  onOpen,
  onClose,
  size = 'medium',
  ...props
}) => {
  const { control } = formMethods()
  const {
    field: { ref, onChange, value: formValue },
    fieldState: { invalid },
    formState: { errors },
  } = useController({
    control,
    name: id,
    rules: {
      validate: (value: Validate<any> | Record<string, Validate<any>> | undefined) =>
        required && (value ? true : 'This field is required'),
    },
  })

  const { color = 'primary', tabIndex } = props
  const $defaultValue = multiple
    ? ((defaultValue || []) as string[])
    : ((defaultValue || '') as string)

  const [value, setValue] = useState<string | string[] | undefined>($defaultValue)

  const handleChange = (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
    const {
      target: { value: val },
    } = event
    const newValue = multiple ? val?.toString().split(',') : val?.toString()
    setValue(newValue)
    onChange(newValue)
    if (props.onChange) {
      props.onChange(event, child)
    }
  }

  useEffect(() => {
    setValue(formValue ?? '')
  }, [formValue])

  const helperText = invalid ? errors[id]?.message : undefined
  const selectProps = { ...props, className: '' }
  delete selectProps.onChange // overrides handleChange

  return (
    <Box className={props.className} tw="w-full">
      <FormControl fullWidth variant={variant} size={size}>
        <InputLabel error={invalid} id={`${id}-label`}>
          {label}
        </InputLabel>
        <MuiSelect
          error={invalid}
          color={color}
          defaultValue={defaultValue}
          disabled={disabled || !children || children.length === 0}
          id={id}
          label={label}
          labelId={`${id}-label`}
          multiple={multiple}
          inputRef={ref}
          onChange={handleChange}
          tabIndex={tabIndex}
          value={value}
          open={open}
          onOpen={onOpen}
          onClose={onClose}
          // fixes BUG: adds 17px padding right to <body>
          inputProps={{ MenuProps: { disableScrollLock: true } }}
          {...selectProps}
        >
          {children}
        </MuiSelect>
        <FormHelperText error>{helperText}</FormHelperText>
      </FormControl>
    </Box>
  )
}
