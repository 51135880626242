import { BusinessModelType } from '../types/commonTypes'
import { dsApi } from './dsApiSlice'

const businessEndpoints = dsApi.injectEndpoints({
  endpoints: (builder) => ({
    getBusiness: builder.query<BusinessModelType, string>({
      query: (organizationId) => {
        return `business/${
          typeof organizationId !== 'undefined' ? `?organization_id=${organizationId}` : ''
        }`
      },
    }),
  }),
})

export const { useGetBusinessQuery, useLazyGetBusinessQuery } = businessEndpoints
