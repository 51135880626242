import { Button } from '@cart/ui/atoms/Button'
import { Children, FC, ReactNode } from 'react'
import { useFormContext } from 'react-hook-form'
import tw from 'twin.macro'

import { encodeURL } from '../helpers'
import { useToolsContext } from '../merchant-tools.store'

export interface FormProps {
  title: string
  description: ReactNode
  example?: string
  buttonText?: string
  children: ReactNode
  handleGenerateLink: (data: unknown) => string
}
export const Form: FC<FormProps> = ({
  children,
  handleGenerateLink,
  title,
  description,
  example,
  buttonText = 'Generate URL',
}) => {
  const { handleSubmit } = useFormContext()
  const { setUrl, value } = useToolsContext()
  const numberOfChildren = Children.count(children)

  const onSubmit = (data: unknown) => {
    setUrl((prevState) => ({
      ...prevState,
      [value]: encodeURL(handleGenerateLink(data)),
    }))
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} id={title}>
        <h4 tw="text-base">{title}</h4>
        <p tw="mb-1">{description}</p>
        {example && <p tw="mb-6 text-monochrome-900/60 text-xs">{example}</p>}
        <div tw="grid gap-4" css={[numberOfChildren > 1 && tw`grid-cols-2`]}>
          {children}
        </div>
        <Button tw="mt-6 mb-2" variant="contained" type="submit">
          {buttonText}
        </Button>
      </form>
    </div>
  )
}
