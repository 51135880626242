import { customAlphabet } from 'nanoid'

/**
 * Adds the appropriate article ("a" or "an") before a word based on the first character's vowel sound.
 *
 * @param {string} word - The word to add the article to.
 * @param {boolean} [sentenceCase=false] - If true, the article will be capitalized.
 * @returns {string} The word preceded by the appropriate article.
 */
export const addArticle = (word, sentenceCase = false) => {
  const vowels = ['a', 'e', 'i', 'o', 'u']
  const firstChar = word.charAt(0).toLowerCase()
  const article = `${sentenceCase ? 'A' : 'a'}${vowels.includes(firstChar) ? 'n' : ''}`

  return `${article} ${word}`
}

export function generateFormDropdownTitle(
  stateToCheck: string[] | string,
  defaultTitle: string,
): string {
  if (Array.isArray(stateToCheck) && stateToCheck.length) {
    return stateToCheck.join('; ')
  }
  if (stateToCheck && typeof stateToCheck === 'string') {
    return stateToCheck
  }
  return defaultTitle
}

/**
 * Returns a string with space prepended and appended if the string is not empty
 * @param str The string to pad
 * @param options Options for padding
 * @param options.padEnd If false, no space will be appended
 * @returns The padded string
 */
export const padIfExist = (str: string, options: { padEnd?: boolean; padStart?: boolean }) => {
  const { padEnd = true, padStart = true } = options
  return str ? `${padStart ? ' ' : ''}${str}${padEnd ? ' ' : ''}` : ''
}

/**
 * Adds commas to a numerical string or number
 * @param value The value to add commas to
 * @returns Numerical string with commas or if value is not a number, the original value
 */
export const addNumericalCommas = (value: string | number): string => {
  if (Number.isNaN(Number(value)) || value === null) {
    return value?.toString() || (value as string)
  }

  let valueStr = String(value)

  const isNegative = valueStr.startsWith('-')
  if (isNegative) {
    valueStr = valueStr.slice(1)
  }

  const [integerPart, decimalPart] = valueStr.split('.')
  const valueReversed = integerPart.split('').reverse().join('')
  const valueWithCommasReversed = valueReversed.replace(/(\d{3})(?=\d)/g, '$1,')
  let result = valueWithCommasReversed.split('').reverse().join('')

  if (decimalPart) {
    result += `.${decimalPart}`
  }

  if (isNegative) {
    result = `-${result}`
  }

  return result
}

/**
 * Converts a string into a domain friendly string.
 * Replaces spaces with dashes and removes special characters.
 *
 * @param {string} str - The string to convert.
 * @return {string} The URL-friendly version of the given string. If the given "string" is invalid will return it
 */
export const createDomainFriendlyText = (str: string) => {
  if (!str) return str

  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9-\s]/g, '')
    .trim()
    .replace(/\s+/g, '')
}

/**
 * Generates a unique ID consisting of alphanumeric characters of the given length
 */
export const generateUniqueId = (length: number) => {
  return customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', length)()
}
