import { nanoid } from 'nanoid'
import React, { ReactElement } from 'react'

export const CustomerEngagementIcon = ({ className }: { className?: string }): ReactElement => {
  const title = 'Customer Engagement Icon'
  const idA = nanoid()
  const idB = nanoid()
  return (
    <svg
      aria-label={title}
      className={className}
      fill="none"
      role="img"
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${idB})`}>
        <path
          d="M44.2226 51.8177C43.5226 53.1045 42.3394 54.0583 40.9172 54.4421L21.196 59.8149C19.3406 60.3172 17.3684 59.7811 16.0074 58.3984L1.58039 43.7414C0.208236 42.3587 -0.313959 40.3552 0.186015 38.4701L0.352673 37.8267C0.363783 37.8493 0.374894 37.8719 0.386004 37.8945L0.369338 37.8437L2.95254 27.9952L2.96365 27.9896L5.47463 18.4572C5.98016 16.5553 7.42452 15.0879 9.27998 14.5856L14.2575 13.2311L12.752 18.937L12.7465 18.9482L10.1688 28.8023L10.1855 28.8531C10.1744 28.8305 10.1633 28.8079 10.1522 28.7797L9.97995 29.4231C9.49108 31.3081 10.0133 33.3117 11.3743 34.7001L25.8013 49.357C27.1679 50.7397 29.1401 51.2759 30.9955 50.7736L41.1228 48.0138L44.2226 51.8177Z"
          fill="#069AE3"
        />
        <path
          d="M50.0221 30.5914L48.0444 38.0695L48.0389 38.1033L47.1612 41.4049L47.15 41.4106L45.6112 47.1955L44.7224 50.5705C44.6057 51.0107 44.4335 51.4227 44.2224 51.8121L41.1226 48.0025L30.9953 50.7624C29.1399 51.259 27.1678 50.7229 25.8012 49.3458L11.3741 34.6945C10.0075 33.3061 9.48535 31.3026 9.97977 29.4175L10.152 28.7741C10.1631 28.8024 10.1742 28.8249 10.1853 28.8475L10.1687 28.7967L12.7518 18.9483L12.7574 18.937L14.2629 13.2311L29.0065 9.22404C30.8565 8.72739 32.8286 9.2579 34.1952 10.6406L48.6389 25.2976C49.9832 26.6803 50.5221 28.6838 50.0221 30.5914Z"
          fill={`url(#${idA})`}
        />
        <path
          d="M59.8165 21.5444L51.2725 56.598C50.9948 57.7606 49.6004 58.2008 48.7227 57.4164L44.2229 51.8177C44.434 51.4283 44.6062 51.0107 44.7229 50.5761L45.6117 47.2011L47.145 41.4162L47.1561 41.4106L48.0338 38.1089L48.0394 38.0751L50.017 30.5971C50.517 28.6895 49.9782 26.6859 48.6338 25.3032L34.1901 10.6462C32.8235 9.25788 30.8514 8.72736 29.0015 9.22966L14.2578 13.2367L15.2689 9.41026C15.78 7.5083 17.2243 6.04091 19.0798 5.54426L38.7954 0.182657C40.6509 -0.313997 42.623 0.21652 43.984 1.59925L58.4277 16.2562C59.7776 17.6333 60.3165 19.6425 59.8165 21.5444Z"
          fill="#DA7009"
        />
      </g>
      <defs>
        <linearGradient
          id={idA}
          x1="38.2823"
          y1="53.5898"
          x2="22.6384"
          y2="10.407"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#005982" />
          <stop offset="1" stopColor="#069AE3" />
        </linearGradient>
        <clipPath id={idA}>
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
