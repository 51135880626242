import { ApolloError, gql, useQuery, useReactiveVar } from '@apollo/client'
import { activeBizVar, activeOrgVar } from '@brand-console/generated-graphql-hooks'
import { useEffect } from 'react'

export const ORG_ASSIGNED_ROLE_LIST_ME = gql`
  query Org_assignedRoleListMe($organizationId: Int) {
    org_assignedRoleListMe(organizationId: $organizationId) {
      data {
        userId
        businessId
        organizationId
        role {
          displayName
          name
        }
        user {
          createdAt
          primaryEmail
          firstName
          lastName
          userData {
            phone
            company
          }
        }
      }
    }
  }
`

interface UserData {
  createdAt: Date
  email: string
  id: number
  isBusinessAdmin: boolean
  isBusinessMember: boolean
  isCustomerServiceRepresentative: boolean
  isOrganizationMember: boolean
  isOrganizationAdmin: boolean
  isOrganizationOwner: boolean
  isSuperAdmin: boolean
  isOrgAdminOrAbove: boolean
  name: string
  firstName: string
  lastName: string
  userData: {
    phone: string
    company: string
  }
  roles: string[]
  loading?: boolean
}

export const useMe = ({
  onCompleted,
  onError,
  reQuery,
}: {
  onCompleted?: (data) => void
  onError?: (error: ApolloError) => void
  reQuery?: boolean
} = {}) => {
  let userDetails: UserData = null
  const activeOrganization = useReactiveVar(activeOrgVar)
  const activeBusiness = useReactiveVar(activeBizVar)

  let organizationId
  let businessId
  if (activeOrganization) {
    organizationId = activeOrganization.id
  }
  if (activeBusiness) {
    businessId = activeBusiness.id
  }

  const { data, loading, refetch } = useQuery(ORG_ASSIGNED_ROLE_LIST_ME, {
    variables: {
      organizationId,
    },
    onCompleted: (assignedRolesData) => {
      if (onCompleted) {
        onCompleted(assignedRolesData)
      }
    },
    onError: (error) => {
      if (onError) {
        onError(error)
      }
    },
  })

  useEffect(() => {
    if (reQuery) {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reQuery])

  if (!activeOrganization || !activeBusiness || !data) {
    return null
  }

  const assignedRoles = data.org_assignedRoleListMe.data
  const currentBusiness = assignedRoles.filter(
    ({ businessId: currentBusinessId }) => currentBusinessId === businessId,
  )[0]
  const allUserRoles = [...new Set(assignedRoles.map((role) => role.role).map((role) => role.name))]

  if (
    !currentBusiness &&
    !allUserRoles.includes('org_admin') &&
    !allUserRoles.includes('org_member')
  ) {
    return null
  }

  const {
    user: { createdAt: createdAtDate, firstName, lastName, primaryEmail, userData },
  } = currentBusiness || assignedRoles[0]

  let createdAt = new Date(createdAtDate)
  if (!(createdAt instanceof Date) || !Number.isFinite(createdAt.getTime())) {
    createdAt = undefined
  }
  const name = [firstName, lastName].filter(Boolean).join(' ')

  userDetails = {
    createdAt,
    email: primaryEmail,
    id: data.org_assignedRoleListMe.data[0].userId,
    isBusinessAdmin:
      currentBusiness?.role?.name === 'business_admin' || allUserRoles.includes('org_admin'),
    isBusinessMember:
      currentBusiness?.role?.name === 'member' ||
      allUserRoles.includes('org_admin') ||
      allUserRoles.includes('org_member'),
    isCustomerServiceRepresentative: allUserRoles.includes('customer_sales_rep'),
    isOrganizationAdmin: allUserRoles.includes('org_admin'),
    isOrganizationOwner: allUserRoles.includes('org_owner'),
    isOrganizationMember: allUserRoles.includes('org_member'),
    isSuperAdmin: allUserRoles.includes('superadmin'),
    isOrgAdminOrAbove:
      allUserRoles.includes('org_admin') ||
      allUserRoles.includes('org_owner') ||
      allUserRoles.includes('superadmin'),
    name,
    firstName,
    lastName,
    userData,
    roles: allUserRoles as string[],
    loading,
  }
  return userDetails
}
