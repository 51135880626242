import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import React, { Children, FC, forwardRef, isValidElement, ReactElement, Ref, useMemo } from 'react'

import { useBreakpoint } from '../../framework/hooks/useBreakpoint'
import { DialogContext } from './dialog.partials'
import { StyledDialog } from './dialog.styles'
import { DialogProps } from './dialog.types'
import { DialogTitle } from './dialog-title/dialog-title'

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const Dialog: FC<DialogProps> = ({
  children,
  onClose,
  maxWidth,
  width,
  fullWidth,
  hideCloseButton,
  ...props
}) => {
  const isMobile = !useBreakpoint('sm')

  const memoDialogContent = useMemo(
    () => ({ onClose, hideCloseButton }),
    [hideCloseButton, onClose],
  )
  const isFullWidth = !!width || fullWidth
  const hasTitle = Children.toArray(children).some((child) => {
    return isValidElement(child) && child.type === DialogTitle
  })

  return (
    <DialogContext.Provider value={memoDialogContent}>
      <StyledDialog
        onClose={onClose}
        TransitionComponent={isMobile ? Transition : undefined}
        maxWidth={maxWidth || width}
        fullWidth={isFullWidth}
        {...props}
      >
        {!hasTitle && !hideCloseButton && <DialogTitle />}
        {children}
      </StyledDialog>
    </DialogContext.Provider>
  )
}
