import { useAuth0 } from '@auth0/auth0-react'
import { useBreakpoint } from '@cart/ui/hooks/useBreakpoint'
import { useKeyPress } from '@cart/ui/hooks/useKeyPress'
import { useOnClickOutside } from '@cart/ui/hooks/useOnClickOutside'
import { useCartAuth } from '@cartdotcom/auth'
import { faArrowRightFromBracket } from '@fortawesome/pro-solid-svg-icons/faArrowRightFromBracket'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import { CSSTransition } from 'react-transition-group'
import tw from 'twin.macro'

import { SideMenuUser } from '../../../gql/graphql'
import { resetStore } from '../../store/storeResetter'
import { useUnifiedSidebarStore } from '../../store/unified-sidebar.store'
import { getIcon } from '../menu-items/MenuItem.partials'
import { StyledUserInfoMenu } from './UserInfo.styles'

interface IUserInfoProps {
  user: SideMenuUser
}

export const UserInfo = ({ user }: IUserInfoProps) => {
  const { logoutUrl, userMenuItems, product } = useUnifiedSidebarStore()

  const [showUserMenu, setShowUserMenu] = useState<boolean>(false)
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const toggleUserMenu = () => setShowUserMenu(!showUserMenu)
  const userMenuRef = useRef(null)
  const isMobile = !useBreakpoint('sm')
  const { isAuthenticated } = useAuth0()
  const { onLogout } = useCartAuth()

  // If the user doesn't have a first or last name, just display their username
  const { firstName, lastName, username } = user
  const displayUserName = [firstName, lastName].some((variable) => !variable)
    ? username
    : `${firstName} ${lastName}`

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: isMobile ? 'top-start' : 'right-start',
  })

  // Close the user menu when the user presses the escape key
  useKeyPress({
    key: 'escape',
    onKeyPress: () => setShowUserMenu(false),
    preventDefault: true,
  })

  // Close the user menu when the user clicks outside of it
  useOnClickOutside(userMenuRef, () => setShowUserMenu(false))

  const onLogoutClick = () => {
    resetStore()
    // If the user is an Auth0 user, we need to make sure to clear the Auth0 session
    if (isAuthenticated && product !== 'bc') {
      onLogout({ returnUrl: logoutUrl })
    } else {
      onLogout({ returnUrl: logoutUrl, skipAuth0Logout: true })
    }
  }

  // Responsive spacing
  styles.popper = {
    ...styles.popper,
    marginLeft: isMobile ? 0 : '.75rem',
  }

  const nodeRef = useRef(null)

  return (
    <StyledUserInfoMenu ref={userMenuRef}>
      <button
        type="button"
        tw="flex w-full cursor-pointer items-center gap-5 overflow-hidden whitespace-nowrap border border-monochrome-900/30 !bg-white p-1"
        css={[
          !isMobile && tw`rounded-tr-xl rounded-tl-3xl rounded-bl-3xl rounded-br-xl`,
          isMobile && tw`rounded-3xl`,
        ]}
        ref={setReferenceElement}
        onClick={toggleUserMenu}
      >
        <img tw="h-9 w-9 shrink-0 rounded-full" src={user.avatar} alt="avatar" />
        <p tw="m-0 font-bold text-base">{displayUserName}</p>
      </button>
      <CSSTransition
        nodeRef={nodeRef}
        in={showUserMenu}
        timeout={300}
        classNames="user-info-menu"
        unmountOnExit
      >
        <div ref={nodeRef}>
          <div
            ref={setPopperElement}
            style={styles.popper}
            tw="[width: 385px] [max-width: calc(100vw - 2rem)] [z-index: 2147483647] overflow-auto rounded-lg border-monochrome-200 border border-solid bg-white shadow-lg drop-shadow-lg"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...attributes.popper}
          >
            <div tw="grid grid-cols-1">
              <div tw="[border-bottom-style: solid] flex flex-wrap justify-center border-monochrome-300 border-b px-4 pt-4 pb-6 font-sans">
                <img tw="h-8 w-8 shrink-0 rounded-full" src={user.avatar} alt="avatar" />
                <div tw="w-full text-center text-base">{displayUserName}</div>
                {/* TODO: Add user email when the service provides it */}
                {/* <div tw="w-full text-center text-sm text-monochrome-500">isabella@hc.com</div> */}
              </div>
              {userMenuItems?.length > 0 && (
                <div tw="[height: fit-content] [max-height: 450px] [border-bottom-style: solid] overflow-auto border-monochrome-300 border-b p-2">
                  <ul tw="m-0 list-none pl-0">
                    {userMenuItems.map((item) => (
                      <li key={item.title} tw="font-sans">
                        <a
                          href={item.url}
                          tw="flex w-full items-center rounded py-4 px-6 text-monochrome-700 leading-none no-underline hover:bg-monochrome-100"
                        >
                          {item.icon && (
                            <span tw="mr-2 inline-block w-5 text-monochrome-600">
                              {getIcon(item.icon, item.title)}
                            </span>
                          )}
                          <span tw="text-sm">{item.title}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div tw="p-2">
                <button
                  type="button"
                  tw="w-full cursor-pointer rounded border-none bg-transparent p-3 text-center font-heading text-sm text-primary-700 leading-none hover:bg-primary-50"
                  onClick={onLogoutClick}
                >
                  <FontAwesomeIcon icon={faArrowRightFromBracket} tw="mr-1" />
                  Log out
                </button>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </StyledUserInfoMenu>
  )
}
