import { useAssignedRoleListMeQuery } from '@brand-console/generated-graphql-hooks'
import { logger } from '@brand-console/utilities'
import uniqBy from 'lodash/uniqBy'
import React, { ReactElement, useEffect, useMemo } from 'react'

import { WrappedDataGrid } from '../SettingsOrganization.partials'
import { useSettingsOrganizationsContext } from '../SettingsOrganizations.store'
import { CreatedOnCell, NameCell } from './BusinessManagement.partials'
import { useCurrentContext } from '@cartdotcom/auth'

export const BusinessManagementList = (): ReactElement => {
  const log = logger.setLogger(logger.LoggerNames.ORGANIZATION)
  const { setAlert } = useSettingsOrganizationsContext()
  const { currentOrganization } = useCurrentContext()

  const errorMessage = 'An error occurred while trying to retrieve the list of businesses.'

  const { data, loading, error } = useAssignedRoleListMeQuery({
    variables: {
      organizationId: currentOrganization.id,
    },
    skip: !currentOrganization
  })

  const filteredData = useMemo(() => {
    if (!data) {
      return []
    }
    return uniqBy(
      data.org_assignedRoleListMe.data.filter(({ business }) => !!business),
      'business.id',
    ).map(({ business }) => business)
  }, [data])

  useEffect(() => {
    if (error) {
      log.error('BusinessManagementListMember GraphQL error', error)
      setAlert({ variant: 'error', text: errorMessage })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <WrappedDataGrid
      loading={loading}
      rows={loading ? [] : filteredData}
      columns={[
        {
          field: 'name',
          headerName: 'Name',
          flex: 2,
          minWidth: 250,
          renderCell: NameCell,
        },
        {
          field: 'id',
          headerName: 'ID',
          flex: 0.5,
          minWidth: 100,
        },
        {
          field: 'numUsers',
          headerName: 'Users',
          flex: 0.5,
          minWidth: 100,
        },
        {
          field: 'createdAt',
          headerName: 'Created On',
          flex: 1,
          minWidth: 150,
          valueFormatter: CreatedOnCell,
        },
      ]}
    />
  )
}
