import { Services } from '@brand-console/types'
import { Button, CardFooter, RadioGroup, RadioItem } from '@cart/ui'
import Typography from '@mui/material/Typography'
import React from 'react'

import { TrialForm } from '../../../components/TrialForm'
import { StyledServiceCard } from '../MarketplaceManagementRoute.styles'

export const MarketplaceManagementRouteForm = ({
  onError,
  onSubmit,
  beforeSubmit,
  isLoading,
  disabled,
  methods,
}) => {
  const selected = methods.watch('marketplace-radio-group')

  return (
    <div tw="mx-auto w-full max-w-3xl">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <TrialForm
        beforeSubmit={beforeSubmit}
        onSubmit={onSubmit}
        onError={onError}
        formTitle="What are you interested in?"
      >
        <RadioGroup id="marketplace-radio-group" methods={methods}>
          <div tw="flex flex-col gap-3 lg:(grid grid-cols-3 gap-10)">
            <StyledServiceCard variant="outlined" aria-selected={selected === "full"}>
              <label htmlFor="multichannelmethodfull">
                <div tw="w-full grow-0">
                  <RadioItem
                    value="full"
                    id="multichannelmethodfull"
                    label={
                      <Typography variant="h6" tw="inline-block">
                        Full Service
                      </Typography>
                    }
                  />
                </div>
                <div tw="mt-1 h-full">
                  Utilize our full set of {Services.MARKETPLACE_MANAGEMENT.toLowerCase()} services
                  to manage your inventory, online orders, and listings. Take advantage of our
                  automated repricing to save time and react to marketplace changes.
                </div>
              </label>
            </StyledServiceCard>
            <StyledServiceCard variant="outlined" aria-selected={selected === "listing"}>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="multichannelmethodlisting">
                <div tw="w-full">
                  <RadioItem
                    value="listing"
                    id="multichannelmethodlisting"
                    label={
                      <Typography variant="h6" tw="inline-block">
                        Full Listing
                      </Typography>
                    }
                  />
                </div>
                <div tw="mt-3 h-full">
                  Create new listings on the major US marketplaces (Amazon, Walmart, and eBay).
                  Publish changes to your marketplace catalogs from one location.
                </div>
              </label>
            </StyledServiceCard>
            <StyledServiceCard variant="outlined" aria-selected={selected === "repricing"}>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="marketplace-method-repricing">
                <div tw="w-full">
                  <RadioItem
                    value="repricing"
                    id="marketplace-method-repricing"
                    label={
                      <Typography variant="h6" tw="inline-block">
                        Repricing
                      </Typography>
                    }
                  />
                </div>
                <div tw="mt-3 h-full">
                  Set up strategies to automatically reprice your listings based on sales goals or
                  react to changes from your competition.
                </div>
              </label>
            </StyledServiceCard>
          </div>
        </RadioGroup>
        <CardFooter tw="mt-6 flex justify-end p-0">
          <Button type="submit" variant="contained" loading={isLoading} disabled={disabled}>
            Continue
          </Button>
        </CardFooter>
      </TrialForm>
    </div>
  )
}
