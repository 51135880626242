import { ContactPreferenceTypeOpts, DataScience } from '@brand-console/types'
import { hubspotFormSubmit, LoggerNames } from '@brand-console/utilities'
import { DeprecatedInput, Dropdown, DropdownItem, Textarea } from '@cart/ui'
import { useCartAuth, useCurrentContext } from '@cartdotcom/auth'
import React, { ReactElement } from 'react'
import TagManager from 'react-gtm-module'
import { $enum } from 'ts-enum-util'

import { IAccountConfirmData } from '../AccountConfirmForm'
import { BaseServiceRequestForm } from './BaseServiceRequestForm'
import { useForm } from 'react-hook-form'

export interface DataScienceFormData {
  contactPreference
  howCanWeHelp
  perfectWorld
  processSupport
  serviceInterest
  storeurl
}
export const DataScienceForm = (): ReactElement => {
  const { currentBusiness, currentOrganization } = useCurrentContext()
  const { email, phone } = useCartAuth()

  const methods = useForm({ mode: 'onChange' })
  const { getValues } = methods

  const createMenuItemsFromEnum = (theEnum, stateKey: string) =>
    $enum(theEnum).map((value) => {
      let finalLabel = value
      switch (value.toLocaleLowerCase()) {
        case 'email':
          finalLabel = `${value}: ${email}`
          break
        case 'phone':
          finalLabel = `${value}: ${phone}`
          break
        default:
          break
      }
      return <DropdownItem key={`${stateKey}-${value}`} text={finalLabel} value={value} />
    })

  const sendGAFormEvent = (eventKey: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'formQuestion_filled',
        results: {
          formType: 'Data Science Solutions Service Request',
          [eventKey]: getValues()[eventKey],
        },
      },
    })
  }

  const onSubmit = async (data: IAccountConfirmData & DataScienceFormData) => {
    const {
      contactPreference,
      howCanWeHelp,
      perfectWorld,
      processSupport,
      serviceInterest,
      storeurl,
      firstName,
      lastName,
      businessName,
      orgName
    } = data

    TagManager.dataLayer({
      dataLayer: {
        event: 'form_submit',
        results: { formType: 'Data Science Solutions Service Request', ...data },
      },
    })

    const hsForm = await hubspotFormSubmit({
      formId: '5cf89b53-5b7a-408a-b24b-68b92b001496',
      data: {
        email,
        firstname: firstName,
        lastname: lastName,
        console_organization_name: orgName,
        console_organization_id: currentOrganization.id,
        console_business_id: currentBusiness.id,
        console_business_name: businessName,
        service_interest__c: serviceInterest,
        perfect_world_ai_problems__c: perfectWorld,
        data_sciences_processes__c: processSupport,
        store_url: storeurl,
        how_can_we_help__c: howCanWeHelp,
        contact_preference__c: contactPreference,
      },
      portalId: parseInt(process.env.NX_HUBSPOT_MARKETING_FORMS_PORTAL_ID, 10),
      pageName: 'Brand Console - Data Science Trial Creation',
    })

    return !!hsForm
  }

  return (
    <BaseServiceRequestForm
      formName="Data Science Service"
      logName={LoggerNames.DATA_SCIENCE}
      onSubmit={onSubmit}
      methods={methods}
    >
      <li tw="mb-6">
        <div tw="mb-2 font-bold">What services would you like to know more about? *</div>
        <Dropdown
          id="serviceInterest"
          multipleSelection
          required
          placeholder="Select all that apply"
          onChange={() => sendGAFormEvent('serviceInterest')}
        >
          {createMenuItemsFromEnum(DataScience.ServiceInterestOpts, 'serviceInterest')}
        </Dropdown>
      </li>
      <li tw="mb-6">
        <div tw="mb-2 font-bold">
          In a perfect world, describe how an Artificial Intelligence engine would solve your
          problem
        </div>
        <DeprecatedInput
          id="perfectWorld"
          onBlur={() => sendGAFormEvent('perfectWorld')}
          placeholder="Answer here (optional)"
        />
      </li>
      <li tw="mb-6">
        <div tw="mb-2 font-bold">What data science processes could you use support in? *</div>
        <Dropdown
          id="processSupport"
          multipleSelection
          required
          placeholder="Select all that apply"
          onChange={() => sendGAFormEvent('processSupport')}
        >
          {createMenuItemsFromEnum(
            DataScience.DataScienceProcessSSupportOpts,
            'processSupport',
          )}
        </Dropdown>
      </li>
      <li tw="mb-6">
        <div tw="mb-2 font-bold">What is the store URL?</div>
        <DeprecatedInput
          id="storeurl"
          placeholder="Store URL (optional)"
          onBlur={() => sendGAFormEvent('storeurl')}
        />
      </li>
      <li tw="mb-6">
        <div tw="mb-2 font-bold">How can we help?</div>
        <Textarea id="howCanWeHelp" onBlur={() => sendGAFormEvent('howCanWeHelp')} />
      </li>
      <li tw="mb-6">
        <div tw="mb-2 font-bold">How would you prefer to be contacted? *</div>
        <Dropdown
          id="contactPreference"
          multipleSelection
          onChange={() => sendGAFormEvent('contactPreference')}
          placeholder="Select all that apply"
          required
        >
          {createMenuItemsFromEnum(ContactPreferenceTypeOpts, 'contactPreference')}
        </Dropdown>
      </li>
    </BaseServiceRequestForm>
  )
}
