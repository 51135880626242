import { CardActionArea } from '@mui/material'
import React, { FC, MouseEventHandler, useState } from 'react'

import { Button } from '../button/button'
import { CardContent } from '../card'
import { CardButtonProps } from './card-buton.types'
import { StyledCard } from './card-button.styles'

export const CardButton: FC<CardButtonProps> = ({
  cardContentProps,
  buttonProps,
  children,
  variant = 'elevation',
  elevation = 4,
  hoverElevation = 8,
  color = 'default',
  onMouseOver,
  onMouseOut,
  ...props
}) => {
  const buttonVariant = variant === 'elevation' ? 'contained' : 'outlined'
  const buttonColor = color === 'default' ? undefined : color

  return (
    <StyledCard
      elevation={elevation}
      hoverElevation={hoverElevation}
      color={buttonColor}
      variant={variant}
      {...props}
    >
      {buttonColor ? (
        <Button color={buttonColor} variant={buttonVariant} {...buttonProps}>
          {children}
        </Button>
      ) : (
        <CardActionArea {...buttonProps}>
          <CardContent {...cardContentProps}>{children}</CardContent>
        </CardActionArea>
      )}
    </StyledCard>
  )
}

export default CardButton
