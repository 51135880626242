import { gql } from 'graphql-request'

import { gqlClient } from '../../../../utils/gqlClient/gqlClient'
import { IMenuItem } from '../../../MenuItem/MenuItem.types'

interface IUAMenuItem extends IMenuItem {
  order?: number
}

export const getVizEnabled = async (
  token: string,
  organizationId: number,
  businessId: number,
): Promise<boolean> => {
  const queryUaOrganization = gql`
    query leftNav_UaOrganizationData($orgId: Int!, $businessId: Int) {
      ua_organization(orgId: $orgId, businessId: $businessId) {
        id
        business {
          id
          flags {
            id
            title
          }
        }
      }
    }
  `

  const response: any = await gqlClient.request(
    queryUaOrganization,
    { orgId: organizationId, businessId },
    {
      Authorization: `Bearer ${token}`,
    },
  )

  return response.ua_organization[0]?.business[0]?.flags?.some(
    (flag) => flag.title === 'viz_enabled',
  )
}

/**
 * Get the menu items for the Unified Analytics Reports section
 * @param {Object} params
 * @param {string} params.token
 */
export const getAnalyticsReportsMenuItems = async ({ token }: { token: string }) => {
  const BASE_URL = process.env.NX_DS_BASE_URL
  const response = await fetch(`${BASE_URL}analytics/`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const { data: reportsMenuData } = await response.json()

  const result: IUAMenuItem[] = Object.keys(reportsMenuData)
    .map((key) => {
      const section = reportsMenuData[key]
      let { icon } = section
      icon = `${icon.charAt(0).toLowerCase()}${icon.substring(1)}`
      if (key !== 'DataHealth') {
        return {
          title: key,
          icon,
          order: section.order,
          items: section.children
            .sort((a, b) => a.order - b.order)
            .map((item) => {
              const { label, path } = item
              return {
                title: label,
                url: `/unified-analytics/${section.path}/${path}`,
              }
            }),
        }
      }
      return null
    })
    .filter((item) => item && item.title !== 'DataHealth')
    .sort((a, b) => a.order - b.order)
  return result
}

export const hasDataConnectors = async ({ organizationId, token }) => {
  const query = `
  query UADataConnectorsListLeftNav($organizationId: String) {
    ua_dataConnectorsList(organizationId: $organizationId) {
      id
    }
  }`

  const data: any = await gqlClient.request(
    query,
    { organizationId: organizationId.toString() },
    { Authorization: `Bearer ${token}` },
  )

  return data?.ua_dataConnectorsList?.length > 0
}

export const isFreeTrial = async ({ businessId, token }) => {
  const query = `
  query UApackageDataConnectorsListLeftNav($businessId: String!) {
    ua_packagedataConnectorsList(businessId: $businessId) {
      dataConnectorTypes
      id
      title
      packageType {
          key
          title
        }
    }
  }`

  const data: any = await gqlClient.request(
    query,
    { businessId: businessId.toString() },
    { Authorization: `Bearer ${token}` },
  )

  return data?.ua_packagedataConnectorsList?.packageType.key === 'FreeTrial'
}
