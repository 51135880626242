import { ReactElement } from 'react'

import { AppsAndServices } from '../../components/AppsAndServices'
import { IncompleteProfileAlert } from '../../components/IncompleteProfileAlert/IncompleteProfileAlert'
import { Shortcuts } from '../../components/Shortcuts/Shortcuts'

export const Homepage = (): ReactElement => {
  return (
    <div tw="m-auto w-full max-w-5xl px-6 py-8 sm:px-8 md:overflow-hidden">
      <IncompleteProfileAlert />
      <section tw="mb-12">
        <Shortcuts />
      </section>
      <section tw="mb-12">
        <AppsAndServices />
      </section>
    </div>
  )
}
