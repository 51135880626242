/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import uniqueId from 'lodash/uniqueId'

interface AlertActionType {
  type?: 'base' | 'info' | 'error' | 'warning' | 'success' | null
  text: string | null
  heading?: string | null
}

interface AlertPropsType extends AlertActionType {
  show: boolean
  id: string
}

type AlertPropsStateType = {
  alertProps: AlertPropsType[]
}

const initialState: AlertPropsStateType = {
  alertProps: [],
}

export const alertPropsSlice = createSlice({
  name: 'alertProps',
  initialState,
  reducers: {
    displayAlert: (state, action: PayloadAction<AlertActionType>) => {
      const id = uniqueId()
      state.alertProps.push({ ...action.payload, show: true, id })
    },
    closeAlert: (state, action) => {
      state.alertProps = state.alertProps.filter((props) => props.id !== action.payload)
    },
  },
})

export const { displayAlert, closeAlert } = alertPropsSlice.actions
