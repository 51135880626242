import { getToken } from '../auth/auth'
import { gqlClient } from './gqlClient'
import { mutation_sessionEnd, mutation_userAppSettingUpsert } from './mutations'
import { query_sessionCheck } from './queries'

interface SessionCheckResponse {
  org_sessionCheck: {
    isSessionValid: boolean
  }
}

export const sessionCheck = async (bearer: string): Promise<boolean> => {
  const response: SessionCheckResponse = await gqlClient.request(
    query_sessionCheck,
    {},
    {
      Authorization: `Bearer ${bearer}`,
    },
  )

  return response.org_sessionCheck?.isSessionValid
}

export const sessionEnd = async (bearer: string): Promise<void> => {
  await gqlClient.request(
    mutation_sessionEnd,
    {},
    {
      Authorization: `Bearer ${bearer}`,
    },
  )
}

export const updateAppSettingCurrentContext = async (
  organizationId: number,
  businessId: number,
): Promise<void> => {
  try {
    const token = await getToken()
    await gqlClient.request(
      mutation_userAppSettingUpsert,
      {
        input: {
          type: 'CURRENT_BUSINESS_CONTEXT',
          setting: JSON.stringify({ organizationId, businessId }),
        },
      },
      {
        Authorization: `Bearer ${token}`,
      },
    )
  } catch (e) {
    console.error('Failed to update the AppUserSettings', e)
  }
}
