import React, { FC } from 'react'

export type PriceLabelProps = {
  priceData: { range: string; price: string }
}
/**
 * Formats the price range and price
 * @param priceData price range and price
 */
export const PriceLabel: FC<PriceLabelProps> = ({ priceData }) => {
  const { range, price } = priceData
  return (
    <div key={range} tw="flex items-center justify-between">
      <p tw="[font-size: 16px]">{range}</p>
      <p>
        <span tw="font-semibold lg:[font-size: 20px]">
          {price !== 'Custom' && '$'}
          {price}
        </span>
        {price !== 'Custom' && '/ month'}
      </p>
    </div>
  )
}
