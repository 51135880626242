import { Card, Dialog } from '@cart/ui'
import { useCurrentContext } from '@cartdotcom/auth'
import React, { FC, useEffect } from 'react'

import { TrialFormProps } from '../TrialForm.types'
import { TrialFormSkeleton } from './SkeletonForm'
import { TrialFormInner } from './TrialFormInner'
import { useAccountConfirmContext } from '../../AccountConfirmForm/store/AccountConfirmForm.store'

/**
 * The parent component for trial forms.
 * It is meant to be used inside of an AccountConfirmProvider, which provides the form state.
 * The forms waits for context to load before rendering, and will load a skeleton form in its place.
 *
 * It is used to combine a page's form with the account confirmation form.
 * If the account form is expected to show, it will show a stepper with the account form as the first step
 * and the page's form as the second step. Otherwise, it will only show the page's form.
 */
export const TrialForm: FC<TrialFormProps> = ({
  children,
  modal,
  errorComponent,
  cardProps,
  dialogProps,
  ...rest
}) => {
  const props = { errorComponent, modal, cardProps, ...rest }
  const { isLoaded } = useCurrentContext()
  const { isFormLoading } = useAccountConfirmContext()

  const showFormLoading = isFormLoading || !isLoaded

  return (
    <>
      {!modal && errorComponent}
      {modal ? (
        <Dialog width="md" open hideCloseButton aria-labelledby="trial-form-title" {...dialogProps}>
          {!showFormLoading ? <TrialFormInner {...props}>{children}</TrialFormInner> : <TrialFormSkeleton />}
        </Dialog>
      ) : (
        <Card {...cardProps}>
          {!showFormLoading ? <TrialFormInner {...props}>{children}</TrialFormInner> : <TrialFormSkeleton />}
        </Card>
      )}
    </>
  )
}
