import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { StyleSheetManager } from 'styled-components'

import { DropdownContext } from '../dropdown'
import {
  StyledCheckbox,
  StyledDropdownItem,
  StyledDropdownItemInfoIcon,
  StyledOptionText,
  StyledTooltip,
} from './dropdown-item.styles'
import { DropdownItemProps } from './dropdown-item.types'

export * from './dropdown-item.types'

export const DropdownItem: FC<DropdownItemProps> = ({
  renderRoot,
  selected = false,
  text,
  tooltip,
  value,
}) => {
  const ref = useRef()
  const {
    addOption,
    forceSelection,
    highlightedIndex,
    multipleSelection,
    selectedOptions,
    options,
    toggleSelection,
  } = useContext(DropdownContext)
  const [isSelected, setIsSelected] = useState(selected)
  const [isHighlighted, setIsHighlighted] = useState<boolean>(false)
  useEffect(() => {
    const option = { value, selected, text }
    addOption(option)
    if (isSelected) {
      toggleSelection(option)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (options.length > 0) {
      const optionByIndex = options.findIndex((x) => x.value === value)
      setIsHighlighted(optionByIndex === highlightedIndex)
      const isItemSelected = !!selectedOptions.find((x) => x.value === value)
      setIsSelected(isItemSelected)
    }
  }, [highlightedIndex, options, selected, selectedOptions, value])

  useEffect(() => {
    setIsSelected(selectedOptions.some((so) => so.value === value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions])

  const onClick = useCallback(() => {
    // If forceSelection is enabled, the single selection dropdown cannot be deselected
    if (!multipleSelection && forceSelection && isSelected) {
      toggleSelection({ value, selected: isSelected, text })
      setIsSelected(isSelected)
      return
    }
    toggleSelection({ value, selected: !isSelected, text })
    setIsSelected(($isSelected) => !$isSelected)
  }, [forceSelection, isSelected, multipleSelection, text, toggleSelection, value])

  return (
    <div>
      <StyleSheetManager target={renderRoot}>
        <StyledDropdownItem
          data-value={value}
          onClick={onClick}
          ref={ref}
          selected={selected}
          isHighlighted={isHighlighted}
          tabindex="-1"
        >
          {multipleSelection && (
            <StyledCheckbox
              id={`${text}-${value}`}
              value={value}
              label={text}
              checked={isSelected}
            />
          )}
          {!multipleSelection && <StyledOptionText selected={isSelected}>{text}</StyledOptionText>}
          {tooltip && (
            <StyledTooltip content={tooltip}>
              <StyledDropdownItemInfoIcon icon={solid('info-circle')} />
            </StyledTooltip>
          )}
        </StyledDropdownItem>
      </StyleSheetManager>
    </div>
  )
}

export default DropdownItem
