import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { AlertProps } from './alert.types'

export const severitySolidIcons = (severity: AlertProps['severity']) => {
  const icons = {
    info: (
      <FontAwesomeIcon tw="text-primary-800" width={20} height={20} icon={solid('circle-info')} />
    ),
    warning: (
      <FontAwesomeIcon
        tw="text-accent-five-800"
        width={20}
        height={20}
        icon={solid('triangle-exclamation')}
      />
    ),
    success: (
      <FontAwesomeIcon
        tw="text-accent-three-800"
        width={20}
        height={20}
        icon={solid('circle-check')}
      />
    ),
    error: (
      <FontAwesomeIcon
        tw="text-accent-two-800"
        width={20}
        height={20}
        icon={solid('circle-exclamation')}
      />
    ),
  }
  return icons[severity as keyof typeof icons]
}

export const severityOutlinedIcons = (severity: AlertProps['severity']) => {
  const icons = {
    info: (
      <FontAwesomeIcon tw="text-primary-800" width={20} height={20} icon={regular('circle-info')} />
    ),
    warning: (
      <FontAwesomeIcon
        tw="text-accent-five-800"
        width={20}
        height={20}
        icon={regular('triangle-exclamation')}
      />
    ),
    success: (
      <FontAwesomeIcon
        tw="text-accent-three-800"
        width={20}
        height={20}
        icon={regular('circle-check')}
      />
    ),
    error: (
      <FontAwesomeIcon
        tw="text-accent-two-800"
        width={20}
        height={20}
        icon={regular('circle-exclamation')}
      />
    ),
  }
  return icons[severity as keyof typeof icons]
}
