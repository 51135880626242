/* eslint-disable react/jsx-props-no-spreading */
import { Badge } from '@cart/ui/atoms/Badge'
import { Button } from '@cart/ui/atoms/Button'
import { useStoreInitializer } from '@cartdotcom/auth'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import React, { FC, useCallback, useEffect, useState } from 'react'

import { StyledIcon } from './McmNotifications.styles'
import { McmNotificationsProps } from './McmNotifications.types'

function notificationsLabel(count: number) {
  if (count === 0) {
    return 'no notifications'
  }
  if (count > 99) {
    return 'more than 99 notifications'
  }
  return `${count} notifications`
}

const UnreadCountToDisplay = ({ unreadCount }) => {
  const count = unreadCount > 9 ? '9+' : unreadCount
  return <div tw="text-xs">{count}</div>
}

interface CustomEventDetail {
  unreadCount: number
}

export const McmNotifications: FC<McmNotificationsProps> = ({ iconProps, badgeProps, ...args }) => {
  const [unreadCount, setUnreadCount] = useState<number>(0)
  const CUSTOM_EVENT = 'cart-notifications-updated'

  const { isLoaded } = useStoreInitializer()

  const handleCustomEvent = useCallback(
    (event: CustomEvent<CustomEventDetail>) => {
      setUnreadCount(event.detail.unreadCount)
    },
    [setUnreadCount],
  )

  const notificationsClick = (e: React.MouseEvent) => {
    const url = `/notifications`;
    window.history.pushState(null, null, url)
    const event = new PopStateEvent('popstate', { state: null })
    window.dispatchEvent(event)
  }

  // Listen for updates to the MCM notification count
  useEffect(() => {
    if (isLoaded) {
      window.addEventListener(CUSTOM_EVENT, handleCustomEvent as EventListener)
    }
    return () => {
      window.removeEventListener(CUSTOM_EVENT, handleCustomEvent as EventListener)
    }
  }, [handleCustomEvent, isLoaded])

  return (
    <Button
      type="button"
      id="mcm-notifications-element"
      data-testid="mcm-notifications-button"
      iconButton
      aria-label={notificationsLabel(unreadCount)}
      onClick={notificationsClick}
      {...args}
    >
      <Badge
        tw="[& .MuiBadge-badge]:(top-[-4px] right-[-4px])"
        badgeContent={
          unreadCount > 0 ? <UnreadCountToDisplay unreadCount={unreadCount} /> : undefined
        }
        color="error"
        {...badgeProps}
      >
        <StyledIcon icon={solid('bell')} {...iconProps} />
      </Badge>
    </Button>
  )
}
