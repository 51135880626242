export const UnifiedSidebarSkeleton = () => {
  return (
    <div tw="[min-width: 300px] [width: 300px] sticky top-0 flex h-screen max-h-screen flex-col divide-y divide-monochrome-100 bg-monochrome-50">
      <div tw="w-full shrink-0 p-4">
        <div tw="flex w-full animate-pulse items-center space-x-2">
          <div tw="h-10 w-8 rounded-full bg-monochrome-200" />
          <div tw="h-10 w-full rounded-full bg-monochrome-200" />
        </div>
      </div>
      <div tw="flex-auto w-full p-4">
        <div role="status" tw="max-w-lg animate-pulse space-y-5">
          <div tw="mb-6 flex w-full items-center space-x-2">
            <div tw="h-14 w-full rounded-lg bg-monochrome-200" />
          </div>
          <div tw="flex w-full items-center space-x-2">
            <div tw="h-5 w-8 rounded-full bg-monochrome-200" />
            <div tw="h-5 w-full rounded-full bg-monochrome-200" />
          </div>
          <div tw="flex w-full items-center space-x-2">
            <div tw="h-5 w-8 rounded-full bg-monochrome-200" />
            <div tw="h-5 w-full rounded-full bg-monochrome-200" />
          </div>
          <div tw="flex w-full items-center space-x-2">
            <div tw="h-5 w-8 rounded-full bg-monochrome-200" />
            <div tw="h-5 w-full rounded-full bg-monochrome-200" />
          </div>
          <div tw="flex w-full items-center space-x-2">
            <div tw="h-5 w-8 rounded-full bg-monochrome-200" />
            <div tw="h-5 w-full rounded-full bg-monochrome-200" />
          </div>
          <div tw="flex w-full items-center space-x-2">
            <div tw="h-5 w-8 rounded-full bg-monochrome-200" />
            <div tw="h-5 w-full rounded-full bg-monochrome-200" />
          </div>
          <div tw="flex w-full items-center space-x-2">
            <div tw="h-5 w-8 rounded-full bg-monochrome-200" />
            <div tw="h-5 w-full rounded-full bg-monochrome-200" />
          </div>
        </div>
      </div>
      <div tw="w-full shrink-0 p-2">
        <div tw="h-11 animate-pulse rounded-tr-xl rounded-tl-3xl rounded-bl-3xl rounded-br-xl bg-monochrome-200" />
      </div>
    </div>
  )
}
