import assign from 'lodash/assign'
import React, { FC, FocusEvent, useEffect, useState } from 'react'
import { StyleSheetManager } from 'styled-components'

import formMethods from '../../framework/forms/form-methods'
import {
  StyledInput,
  StyledInputContainer,
  StyledInputField,
  StyledInputLabel,
  StyledInputSearchIcon,
  StyledInputTrailingIcon,
  StyledMessage,
} from './deprecated-input.styles'
import { InputProps } from './deprecated-input.types'

export const DeprecatedInput: FC<InputProps & Record<string, any>> = ({
  className,
  disabled,
  helperText,
  id,
  label,
  onBlur,
  onFocus,
  placeholder,
  readonly,
  required,
  renderRoot,
  rules,
  trailingIcon,
  type = 'text',
  value,
  noBorder,
  onChangeState,
  searchIcon,
  ...props
}: InputProps) => {
  const [hasError, setHasError] = useState(false)
  const [hasFocus, setHasFocus] = useState(false)
  const [currentHelperMessage, setCurrentHelperMessage] = useState(helperText)
  const onFocusEvent = (e: FocusEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(e)
    }
    setHasFocus(true)
  }

  const onBlurEvent = (e: FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e)
    }
    setHasFocus(false)
  }

  const {
    formState: { errors },
    register,
  } = formMethods()

  useEffect(() => {
    const error = errors[id]
    setCurrentHelperMessage((error?.message || helperText) as unknown as string)
    setHasError(!!error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, errors[id]?.type, id, helperText])

  const defaultRules = {
    required: required ? 'This field is required' : undefined,
    pattern:
      type === 'email' ? { value: /^\S+@\S+$/i, message: 'Please enter a valid email' } : undefined,
  }

  const inputRules = assign({}, defaultRules, rules)
  const registerField = register(id, inputRules as Record<string, unknown>)
  return (
    <StyleSheetManager target={renderRoot}>
      <StyledInputContainer className={className}>
        {label && (
          <StyledInputLabel htmlFor={id} data-haserror={hasError} data-hasfocus={hasFocus}>
            {label}
          </StyledInputLabel>
        )}
        <StyledInput>
          {searchIcon && <StyledInputSearchIcon>{searchIcon}</StyledInputSearchIcon>}
          <StyledInputField
            aria-describedby={id}
            data-haserror={hasError}
            data-hastrailingicon={!!trailingIcon}
            data-noborder={noBorder}
            data-search={!!searchIcon}
            defaultValue={value}
            disabled={disabled}
            data-testid="input"
            id={id}
            placeholder={placeholder}
            readOnly={readonly}
            type={type}
            {...registerField}
            {...props}
            onBlur={onBlurEvent}
            onFocus={onFocusEvent}
          />
          {trailingIcon && <StyledInputTrailingIcon>{trailingIcon}</StyledInputTrailingIcon>}
        </StyledInput>
        {currentHelperMessage && (
          <StyledMessage data-haserror={hasError} data-hasfocus={hasFocus}>
            {currentHelperMessage}
          </StyledMessage>
        )}
      </StyledInputContainer>
    </StyleSheetManager>
  )
}
