import { useCartAuth } from '@cartdotcom/auth'

/** Use this information when the details such as organization and business are not given */
export const useDefaultInfo = () => {
  const { email } = useCartAuth()

  return {
    organization: {
      name: `${email} Organization`,
    },
    business: {
      name: `${email} Business`,
    },
  }
}
