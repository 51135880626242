import { useReactiveVar } from '@apollo/client'
import { callbackParamsVar, callbackRedirectUriVar } from '@brand-console/generated-graphql-hooks'
import { useRouter } from '@cart/ui'
import { useEffect } from 'react'

export const LoginSignUpRoutes = () => {
  const { pathname, location, navigate } = useRouter()

  useEffect(() => {
    const screenHint = pathname.substring(1)
    const searchParams = new URLSearchParams(location.search)
    // Is this deprecated?
    const isFromOnlineStore = searchParams.get('from-os') === '1'

    let redirectUri = searchParams.get('redirectUri')

    switch (screenHint) {
      case 'login':
        redirectUri = redirectUri ? `${redirectUri}` : null
        break
      case 'signup':
        redirectUri = isFromOnlineStore ? `/storefront?${searchParams}` : null
        break
      default:
        break
    }

    const isMcmNavParam = searchParams.get('nav') === 'mcm'
    const searchParamsEntries = Object.fromEntries(searchParams.entries())

    let redirectOpts = {
      appState: { isMcmNav: isMcmNavParam },
      screen_hint: screenHint,
      ...searchParamsEntries,
    }

    const persistedCallbackParams = JSON.parse(sessionStorage.getItem('callbackParams'))
    if (persistedCallbackParams) {
      redirectOpts = {
        ...persistedCallbackParams,
        ...redirectOpts,
      }
    }
    sessionStorage.setItem('callbackParams', JSON.stringify(redirectOpts))

    if (redirectUri?.includes('__hstc')) {
      sessionStorage.setItem('hstc', redirectUri)
    }

    callbackRedirectUriVar(redirectUri || `/?${searchParams}`)
    callbackParamsVar(redirectOpts)
    navigate('/callback')
  }, [location.search, navigate, pathname])
  return null
}
