export const CART_SITES_HOSTS = {
  bc: ['console-dev.cart.site', 'localhost:4040', 'console-stage.cart.site', 'console.cart.com'],
  dfw: [
    'app-preview.datafeedwatch.rocks',
    'app-preview2.datafeedwatch.rocks',
    'app-preview3.datafeedwatch.rocks',
    'app-preview4.datafeedwatch.rocks',
    'app-preview5.datafeedwatch.rocks',
    'app-preview6.datafeedwatch.rocks',
    'app-preview7.datafeedwatch.rocks',
    'app-preview8.datafeedwatch.rocks',
    'app-preview9.datafeedwatch.rocks',
    'app-stg.datafeedwatch.rocks',
    'app.datafeedwatch.com',
  ],
  jazz: [
    'fbflurry-qa01.jazz-oms.com',
    'fbflurry-qa02.jazz-oms.com',
    'piano.qa.cart.site',
    'fbflurry-uat01.jazz-oms.com',
  ],
  mcm: [
    'channels.cart.com',
    'channels.dev.cart.site',
    'channels.dev2.cart.site',
    'channels.dev3.cart.site',
    'channels.stage.cart.site',
    'localhost:7175',
  ],
}
