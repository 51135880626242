import tw, { css, styled } from 'twin.macro'

export const Styled404Container = styled.div(() => [
  tw`relative inset-0 flex h-full w-full items-center justify-center px-4`,
  css`
    &:after {
      ${tw`absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 font-heading font-bold text-monochrome-200 [z-index:-1]`}
      content: '404';
      font-size: 30vw;
    }
  `,
])
