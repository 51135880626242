import { useReactiveVar } from '@apollo/client'
import {
  activeOrgVar,
  useUACheckBusinessMyConnectorsQuery,
} from '@brand-console/generated-graphql-hooks'
import { useMemo } from 'react'

import { dataConnectorsData as originalDataConnectorsCardData } from '../../helpers/data'

export function useMyConnectorsData() {
  const activeOrg = useReactiveVar(activeOrgVar)

  const { data } = useUACheckBusinessMyConnectorsQuery({
    variables: {
      organizationId: activeOrg?.id.toString(),
    },
  })
  const dataConnectorsCardData = useMemo(() => {
    const freeConnectorType = data?.ua_dataConnectorCheckBusiness
      .filter(({ businesses }) => businesses.length > 0)
      .map((item) => item.connectorType)

    return freeConnectorType && freeConnectorType.length > 0
      ? originalDataConnectorsCardData.filter((item) =>
          freeConnectorType.includes(item.connectorName),
        )
      : []
  }, [data])

  return { dataConnectorsData: dataConnectorsCardData }
}
