import { useEffect, useState } from 'react'

type FocusHandler = () => void

/**
 * A custom React hook that lets a user respond to the browser tab gaining or losing focus.
 *
 * @param {() => void} [onFocus=() => {}] - A callback function that will be executed when the selected tab gains focus.
 * @param {() => void} [onBlur=() => {}] - A callback function that will be executed when the selected tab loses focus.
 * @returns {boolean} - A boolean value indicating whether the selected tab is focused.
 *
 * @example
 * const isFocused = useTabFocus(
 *   () => { console.log('Focused'); },
 *   () => { console.log('Blurred'); }
 * );
 */
export const useTabFocus = (onFocus: FocusHandler = () => {}, onBlur: FocusHandler = () => {}) => {
  const [isFocused, setIsFocused] = useState(document.visibilityState === 'visible')
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setIsFocused(true)
        if (onFocus) onFocus()
      } else {
        setIsFocused(false)
        if (onBlur) onBlur()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [onBlur, onFocus])

  return isFocused
}
