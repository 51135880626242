import { StorefrontAccountsList, StorefrontProvisioningAccount } from '@brand-console/types'

import { logger } from '../logging'

export interface FetchSFSiteDataProps {
  businessId: number
  token: string
  user: { auth0Id: string | undefined; email: string | undefined }
  log: typeof logger
}
export interface FetchSfAccountResponse {
  metadata?: StorefrontAccountsList
  provisioningAccount?: StorefrontProvisioningAccount
  provisioned: boolean
}
/**
 * Checks if the user has access to Storefront
 */
export const fetchSfAccount = async ({
  businessId,
  token,
  user,
  log,
}: FetchSFSiteDataProps): Promise<FetchSfAccountResponse> => {
  const httpHeaders = {
    Authorization: `Bearer ${token}`,
    'x-cartid-sub': user.auth0Id,
    'x-cartid-email': user.email,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  try {
    const response = await fetch(`${process.env.NX_MYSTORES_API_URL}`, {
      method: 'GET',
      headers: httpHeaders as Record<string, string>,
    })
    const sfAccountsResponse = await response.json()
    const provisioningAccounts =
      sfAccountsResponse?.result?.length > 0
        ? sfAccountsResponse?.result.flatMap((r) => r.provisioningAccounts)
        : []

    const provisioningAccount = provisioningAccounts.find((pa) => pa.cartBusinessId === businessId)
    if (sfAccountsResponse.success && provisioningAccounts.length > 0 && provisioningAccount) {
      const sfAccountMetadata: StorefrontAccountsList = sfAccountsResponse
      return {
        metadata: sfAccountMetadata,
        provisioningAccount,
        provisioned: true,
      }
    }
  } catch (error) {
    log.error('fetchSFAccountsAPI Error', { businessId }, error as Error)
    return {
      provisioned: false,
    }
  }
  return {
    provisioned: false,
  }
}
