import { datadogLogs, Logger, StatusType } from '@datadog/browser-logs'

import { LoggerNames, loggerNames } from './logger.constants'
import { LogListType } from './logger.types'

let loggers: LogListType = {
  global: datadogLogs.getLogger(LoggerNames.GLOBAL),
}

function buildLogger(log: Logger | undefined, logName: typeof loggerNames[number]) {
  return {
    /**
     * Change logger context used. With no parameters it can
     * be used to reset the stored logger.
     */
    setLogger(name: typeof loggerNames[number] = logName) {
      return buildLogger(buildLogger.getLogger(name), name)
    },
    getLogger() {
      return log
    },
    warn(message: string, messageContext?: object) {
      log?.warn(message, messageContext)
      return buildLogger(log, logName)
    },
    debug(message: string, messageContext?: object) {
      log?.debug(message, messageContext)
      return buildLogger(log, logName)
    },
    log(message: string, messageContext?: object, status?: StatusType) {
      log?.log(message, messageContext, status)
      return buildLogger(log, logName)
    },
    info(message: string, messageContext?: object) {
      log?.log(message, messageContext)
      return buildLogger(log, logName)
    },
    error(message: string, messageContext?: object, error: Error | undefined = undefined) {
      log?.error(message, messageContext, error)
      return buildLogger(log, logName)
    },
    LoggerNames,
  }
}

buildLogger.getLogger = (name: string) => {
  const log = loggers[name] || datadogLogs.getLogger(name)
  if (!loggers[name]) loggers[name] = log
  return log
}

/**
 * Sends logs to DataDog.
 * The default context is 'global'.
 * Use `.setLogger` to change.
 */
export const logger = buildLogger(buildLogger.getLogger(LoggerNames.GLOBAL), LoggerNames.GLOBAL)

/**
 * Meant for testing purposes, use caution if using elsewhere.
 * After using, make sure to update your logger object with `.setLogger`
 */
export const setLoggers = (logObj: object) => {
  loggers = {
    ...loggers,
    ...logObj,
  }
  return loggers
}

export const getLoggers = () => loggers

export { LoggerNames, loggerNames } from './logger.constants'
