import MuiBreadcrumbs from '@mui/material/Breadcrumbs'
import tw, { css, styled } from 'twin.macro'

export const StyledBreadcrumbs = styled(MuiBreadcrumbs)(() => [
  tw`text-monochrome-900/60`,
  css`
    & .MuiBreadcrumbs-li {
      &:last-of-type {
        ${tw`text-monochrome-900`}
        & a {
          ${tw`text-monochrome-900`}
        }
      }
      & a {
        ${tw`text-monochrome-900/60`}
      }
    }
  `,
])
