// core
import React from 'react'
import { ScrollbarProps, Scrollbars } from 'react-custom-scrollbars-2'

import {
  ThumbHorizontal,
  ThumbVertical,
  // TrackHorizontal,
  // TrackVertical,
} from './scroll-bar-styles'

interface PropsType extends ScrollbarProps {
  navBar?: boolean
}

export const ScrollbarStyled = (props: PropsType) => {
  const { navBar, autoHeight, autoHeightMin, autoHeightMax, style, children } = props
  return (
    <Scrollbars
      renderThumbHorizontal={(horizontalThumbProps) => (
        /* eslint-disable react/jsx-props-no-spreading */
        <ThumbHorizontal {...horizontalThumbProps} navBar={navBar} />
      )}
      renderThumbVertical={(verticalThumbProps) => (
        <ThumbVertical
          {...verticalThumbProps}
          navBar={navBar}
          style={{
            ...style,
            backgroundColor: '#E2E2E2',
            width: '4px',
          }}
        />
      )}
      // renderTrackHorizontal={(horizontalTrackProps) => (
      //     <TrackHorizontal {...horizontalTrackProps} navBar={navBar} />
      // )}
      // renderTrackVertical={(verticalTrackProps) => (
      //     <TrackVertical {...verticalTrackProps} navBar={navBar} />
      // )}
      /* eslint-enable react/jsx-props-no-spreading */
      style={style}
      // hideTracksWhenNotNeeded
      autoHeight={autoHeight}
      autoHeightMin={autoHeightMin}
      autoHeightMax={autoHeightMax}
      autoHide
    >
      {children}
    </Scrollbars>
  )
}

ScrollbarStyled.defaultProps = {
  navBar: false,
}
