import { useAuth0 } from '@auth0/auth0-react'
import { LDProvider } from 'launchdarkly-react-client-sdk'
import { ReactNode } from 'react'

export const FeatureFlagProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth0()
  const ldHash = user?.['https://cart.com/user_metadata']?.ldHash ?? user?.ldHash

  if (!user) {
    return children
  }

  return (
    <LDProvider
      clientSideID={process.env.NX_LD_CSID as string}
      context={{
        key: user.sub, // the hash and sub need to match in LD, so best to pull this from auth0
        kind: 'user',
        email: user.email,
      }}
      options={{
        hash: ldHash,
        sendEvents: false,
        diagnosticOptOut: true,
        bootstrap: 'localStorage',
      }}
    >
      {children}
    </LDProvider>
  )
}
