import { Auth0ClientOptions, createAuth0Client } from '@auth0/auth0-spa-js'

const getClient = async () => {
  const domain = process.env.NX_AUTH0_DOMAIN as string
  const clientId = process.env.NX_AUTH0_CLIENT_ID as string
  const audience = process.env.NX_AUTH0_AUDIENCE

  const options: Auth0ClientOptions = {
    domain,
    clientId,
    cacheLocation: 'localstorage',
    authorizationParams: {
      audience,
      redirect_uri: window.location.origin,
    },
  }

  const client = await createAuth0Client(options)
  return client
}

export const getToken = async () => {
  const client = await getClient()
  try {
    const token = await client.getTokenSilently()
    return token
  } catch (e) {
    if (e.error !== 'login_required') {
      // eslint-disable-next-line no-console
      console.error('Failed to get token', e)
    }
  }
  return null
}

/**
 * Attempts to get auth token. If it fails, it will retry up to specified times.
 * @param retries Number of retries to attempt.
 * @param count Current number of retries.
 */
export const getTokenWithRetries = async (retries = 5, count = 0) => {
  const sleep = (ms) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }
  await sleep(count * 50)
  const token = await getToken()
  if (!token && count < retries) return getTokenWithRetries(count + 1)
  return token
}
