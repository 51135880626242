import { useAuth0 } from '@auth0/auth0-react'

import { sessionCheck } from '../../gql/requests'

export const useSessionCheck = (onInvalidSession: (isLoggedOut?: boolean) => void) => {
  const { getAccessTokenSilently } = useAuth0()

  const checkSession = async () => {
    let token

    try {
      token = await getAccessTokenSilently({
        timeoutInSeconds: 2,
        // cacheMode: 'cache-only',
      })
    } catch (error) {
      onInvalidSession(true)
      return
    }

    if (!token) {
      onInvalidSession(true)
      return
    }

    const isSessionValid = await sessionCheck(token)
    if (!isSessionValid) {
      onInvalidSession()
    }
  }

  return { checkSession }
}
