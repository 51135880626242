import tw, { css, styled } from 'twin.macro'

// inline and margin are required to override BS defaults
export const StyledLabel = styled.label(() => [tw`relative flex items-center`])

export const StyledRadioButton = styled.span(() => [
  tw`absolute left-0 inline-block rounded-full border-2 border-monochrome-600 border-solid w-[18px] h-[18px]`,
  css`
    &::after {
      ${tw`[transform: translate(-50%, -50%)] absolute top-1/2 left-1/2 block rounded-full bg-primary-700 opacity-0 h-[10px] w-[10px]`}
    }
  `,
])

export const StyledLabelText = styled.span(() => [
  tw`py-2 text-sm leading-none [user-select:none] pl-[30px]`,
])

export const StyledInput = styled.input(() => [
  css`
    &:not(:disabled) {
      & ~ ${StyledLabel} {
        & ${StyledRadioButton} {
          ${tw`cursor-pointer`}
        }
        & ${StyledLabelText} {
          ${tw`cursor-pointer`}
        }
      }
      &:checked ~ ${StyledLabel} {
        & ${StyledRadioButton} {
          ${tw`border-primary-700`}
          &:after {
            content: '';
            ${tw`opacity-100`}
          }
        }
      }
    }
    &:disabled ~ ${StyledLabel} {
      ${tw`text-monochrome-600`}
      & ${StyledRadioButton} {
        ${tw`cursor-not-allowed border-monochrome-600`}
      }
      & ${StyledLabelText} {
        ${tw`cursor-not-allowed`}
      }
    }
    &:disabled:checked ~ ${StyledLabel} {
      & ${StyledRadioButton} {
        &:after {
          ${tw`bg-monochrome-600 opacity-100`}
        }
      }
    }
  `,
])
