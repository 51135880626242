import React from 'react'

export const ServiceOfferingItem = ({ children, ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div tw="flex-[1 1 360px] bg-monochrome-50" {...props}>
      <div tw="h-2.5 rounded-t-lg bg-primary-700" />
      <div tw="p-5">{children}</div>
    </div>
  )
}
