import { scrollTo } from '@brand-console/utilities'
import { Breadcrumbs, CustomerEngagementIcon } from '@cart/ui'
import { ReactElement, useEffect } from 'react'
import TagManager from 'react-gtm-module'

import { Recommendations } from '../../components/Recommendations'
import { CustomerEngagementForm } from '../../components/ServiceRequestForms/CustomerEngagementForm'
import { StickyHeader } from '../../components/StickyHeader'
import CustomerEngagementBannerPNG from '../../images/customer-engagement-banner.png'
import { ProductBanner } from './ProductBanner'
import { ServiceOfferingItem } from './ServiceOfferingItem'

export const CustomerEngagementRoute = (): ReactElement => {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        route: 'CustomerEngagement',
      },
    })
  }, [])

  return (
    <main tw="relative">
      <section tw="px-6 py-4 sm:px-8">
        <Breadcrumbs items={[{ text: 'Home', href: '/' }, { text: 'Customer Engagement' }]} />
      </section>

      <section tw="m-auto w-full max-w-5xl px-6 py-8 sm:px-8 md:overflow-hidden">
        <ProductBanner
          title="Customer Engagement"
          description="Increase satisfaction and sales with a white-labeled customer service team"
          icon={CustomerEngagementIcon}
          bgImage={CustomerEngagementBannerPNG}
          buttonAction={() => scrollTo('#customer-engagement-request', { offset: 90 })}
        />
      </section>

      <section
        id="customer-engagement-overview"
        tw="m-auto w-full max-w-5xl px-6 pb-8 sm:px-8 md:overflow-hidden"
      >
        <h2 tw="mb-7 mt-0">Overview</h2>
        <p tw="text-lg">
          Cart Customer Engagement gives fast-growing brands access to an on-demand, omnichannel and
          scalable customer support team that can respond to inquiries as they happen — without
          front-loading fixed costs — allowing digital brands to stay agile.
        </p>
      </section>

      <section id="customer-engagement-offerings" tw="bg-white py-9">
        <div tw="mx-auto w-full max-w-5xl sm:px-8">
          <h2 tw="mb-6 text-2xl">Service Offerings</h2>
          <div tw="flex flex-wrap gap-9">
            <ServiceOfferingItem>
              <h3 tw="mb-2 text-xl">Omnichannel customer support</h3>
              <p tw="m-0">
                Engage with customers via live chat, SMS, messenger, phone, email, social media
                messages and more. With the ability to answer product and order-related questions,
                the Cart Customer Engagement group turns shoppers into loyal fans of your brand.
              </p>
            </ServiceOfferingItem>
            <ServiceOfferingItem>
              <h3 tw="mb-2 text-xl">Trained Product Experts</h3>
              <p tw="m-0">
                Invigorate customer engagement with approachable customer service representatives
                that are trained to understand your product offering. Our team will dive deep into
                videos, MSDS sheets, manuals, FAQ&apos;s, scripts, product descriptions and
                documentation to provide an optimal pre and post purchase experience for your
                customers.
              </p>
            </ServiceOfferingItem>
            <ServiceOfferingItem>
              <h3 tw="mb-2 text-xl">Multichannel Communications</h3>
              <p tw="m-0">
                Engage with customers via live chat, SMS, messenger, phone, email, social messages,
                and more. With the ability to answer product and order related questions, the
                Cart.com customer service as a service group turns shoppers into loyal fans of your
                brand. The capabilities of our customer service team increase exponentially when
                paired with our fulfillment services and online store platform, which unlocks
                greater data visibility and deep integration across functions.
              </p>
            </ServiceOfferingItem>
          </div>
        </div>
      </section>

      <section tw="m-auto w-full max-w-3xl px-6 py-8 sm:px-8 justify-center ">
        <h2 id="customer-engagement-request">Contact Sales</h2>
        <p tw="mb-8 text-base">Want to know more about our customer engagement offerings?</p>
        <CustomerEngagementForm />
      </section>

      <section tw="[max-width: 100vw] m-auto w-full px-6 py-8 sm:([max-width: calc(100vw - 100px)] px-8) lg:max-w-5xl">
        <h2 tw="mb-2 pl-4 text-2xl lg:pl-0">Recommendations</h2>
        <Recommendations pageToOmit="/customer-engagement" />
      </section>
    </main>
  )
}
