export const copyUrlToClipboard = (url: string) => {
  navigator.clipboard.writeText(url)
}

export const navigateToUrl = (url: string) => {
  window.open(url, '_blank')
}

export const encodeURL = (url) => {
  return url
    .split('?')
    .map((part, index) => {
      return index === 0
        ? part
        : encodeURI(part).replaceAll('%20', '+').replaceAll('%25', '%').replaceAll("'", '%27')
    })
    .join('?')
}

export const requiredRule = {
  required: 'All fields are required.',
}

export const asinPattern = {
  pattern: {
    value: /^[a-zA-Z0-9]{10}$/,
    message: 'ASIN must be 10 alpha-numeric characters.',
  },
}

export const asinMultiPattern = {
  pattern: {
    value: /^(?:\w{10}[\r\n]?)*$/,
    message: 'Each line must be an ASIN of 10 alpha-numeric characters.',
  },
}

export const quantityPattern = {
  pattern: {
    value: /^[0-9]+$/,
    message: 'Quantity must be a numerical value.',
  },
}
