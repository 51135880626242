/* eslint-disable no-void */
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import assign from 'lodash/assign'
import remove from 'lodash/remove'
import uniqBy from 'lodash/uniqBy'
import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useKeyRef } from 'rooks'
import { StyleSheetManager } from 'styled-components'

import formMethods from '../../framework/forms/form-methods'
import {
  StyledCaretDown,
  StyledClearButton,
  StyledDropdown,
  StyledDropdownButton,
  StyledDropdownOptions,
  StyledHelperText,
  StyledLabel,
  StyledSelectElement,
} from './dropdown.styles'
import { DropdownProps, IDropdownState } from './dropdown.types'
import { DropdownItemProps } from './dropdown-item/dropdown-item.types'

const contextDefaultValues: IDropdownState = {
  addOption: () => void 0,
  forceSelection: false,
  highlightedIndex: undefined,
  multipleSelection: false,
  options: [],
  selectedOptions: [],
  setWidth: () => void 0,
  setHighlightedIndex: () => void 0,
  toggleSelection: () => void 0,
  width: 0,
}

export const DropdownContext = createContext(contextDefaultValues)

const findFirstOverflowAutoParent = (el: HTMLElement): HTMLElement | undefined => {
  if (!el.parentElement) {
    return undefined
  }
  if (getComputedStyle(el.parentElement).overflowY === 'auto') {
    return el.parentElement
  }
  return findFirstOverflowAutoParent(el.parentElement as HTMLElement)
}

export const Dropdown = ({
  children,
  className,
  forceSelection = false,
  helperText,
  id,
  label,
  multipleSelection = false,
  onChange,
  placeholder = 'Select an Option',
  readonly = false,
  required,
  rules,
  renderRoot,
  values,
  disabled,
  onOpen,
}: DropdownProps) => {
  const dropdownContainerRef = useRef<HTMLDivElement>(null)
  const dropdownOptionsRef = useRef<HTMLDivElement>(null)
  const [overflowAutoParent, setOverflowAutoParent] = useState<HTMLElement | undefined>()
  const selectRef = useRef<HTMLSelectElement | null>(null)
  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  const [focus, setFocus] = useState<boolean>(false)
  const [showOptions, setShowOptions] = useState<boolean>(false)
  const [selectedText, setSelectedText] = useState(placeholder)
  const [containerWidth, setContainerWidth] = useState<number>(contextDefaultValues.width)
  const [selectedOptions, setSelectedOptions] = useState<DropdownItemProps[]>([])
  const [options, setOptions] = useState<DropdownItemProps[]>([])
  const [currentHelperMessage, setCurrentHelperMessage] = useState(helperText)
  const [hasError, setHasError] = useState(false)
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1)

  const {
    formState: { errors },
    register,
    watch,
    setValue,
  } = formMethods({
    mode: 'onChange',
  })

  useEffect(() => {
    const error = errors[id]
    setCurrentHelperMessage((error?.message || helperText) as unknown as string)
    setHasError(!!error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, errors[id]?.type, id, helperText])

  const defaultRules = {
    required: required ? 'This field is required' : undefined,
  }

  const inputRules = assign({}, defaultRules, rules)
  const registerField = register(id, inputRules as Record<string, unknown>)
  const { ref } = registerField

  const hideOptions = useCallback(() => {
    if (overflowAutoParent) {
      overflowAutoParent.style.overflow = 'auto'
    }
    setShowOptions(false)
  }, [overflowAutoParent])

  const setWidth = (width: number) => {
    setContainerWidth((currentWidth) => {
      if (width <= currentWidth) return 0
      return width
    })
  }

  const updateSelectedText = useCallback(
    (newSelections: DropdownItemProps[]) => {
      switch (uniqBy(newSelections, 'value').length) {
        case 0:
          setSelectedText(placeholder)
          break
        case 1:
          setSelectedText(uniqBy(newSelections, 'value')[0].text)
          break
        default:
          setSelectedText(`${uniqBy(newSelections, 'value').length} selected`)
      }
    },
    [placeholder],
  )

  const toggleSelection = useCallback(
    ({ value, selected, text, isReset = false }: DropdownItemProps) => {
      let hasChanged = true
      let newSelections: DropdownItemProps[] = selectedOptions
      if (multipleSelection) {
        if (selected) {
          newSelections.push({ text, selected, value })
        } else {
          remove(newSelections, (option) => option.value === value)
        }
        remove(newSelections, (option) => option.text === '')
      } else {
        const currentSelection = selectedOptions.length > 0 ? selectedOptions[0].value : ''
        newSelections = selected ? [{ text, selected, value }] : []
        const newSelection = newSelections.length > 0 ? newSelections[0].value : ''
        if (currentSelection === newSelection) {
          hasChanged = false
        }
        hideOptions()
      }
      setSelectedOptions(newSelections)
      const selectElement = selectRef.current as HTMLSelectElement
      selectElement.options.length = 0
      newSelections.forEach((option) =>
        selectElement.options.add(new Option(option.text, option.value, true, true)),
      )
      selectElement.dispatchEvent(new Event('change', { bubbles: !isReset }))
      updateSelectedText(newSelections)
      if (hasChanged && onChange && !firstLoad) {
        onChange(newSelections)
      }
    },
    [firstLoad, hideOptions, multipleSelection, onChange, selectedOptions, updateSelectedText],
  )

  const handleDropdownDisplay = () => {
    if (onOpen) {
      onOpen()
    }
    if (dropdownContainerRef.current) {
      if (!showOptions) {
        if (!multipleSelection && selectedOptions.length > 0) {
          const optionByIndex = options.findIndex((x) => x.text === selectedText)
          setHighlightedIndex(optionByIndex)
        }
        const parentWithOverflowAuto = findFirstOverflowAutoParent(dropdownContainerRef.current)
        setOverflowAutoParent(parentWithOverflowAuto)
        const dropdownRef = dropdownContainerRef.current.getBoundingClientRect()

        if (dropdownOptionsRef.current) {
          // dropdownOptionsRef.current.style.top = `${dropdownOptionsRef.current.clientHeight * -1}px`
          dropdownOptionsRef.current.style.width = `${dropdownRef.width}px`
        }
        if (parentWithOverflowAuto) {
          parentWithOverflowAuto.style.overflow = 'hidden'
        }
      } else if (overflowAutoParent) {
        overflowAutoParent.style.overflow = 'auto'
      }
    }
    setShowOptions(!showOptions)
  }
  useEffect(() => {
    if (values) {
      if (values.length > 0) {
        setSelectedOptions(values)
        updateSelectedText(values)
      } else {
        setSelectedOptions([])
        updateSelectedText([])
      }
    }
  }, [values, updateSelectedText])
  const handleKeyboardOptionSelection = (e: KeyboardEvent) => {
    const { code } = e
    if (['ArrowUp', 'ArrowDown'].includes(code)) {
      if (code === 'ArrowUp') {
        setHighlightedIndex(highlightedIndex <= 0 ? 0 : highlightedIndex - 1)
      } else {
        if (!showOptions) {
          handleDropdownDisplay()
        }
        setHighlightedIndex(
          options.length - 1 === highlightedIndex
            ? options.length - 1
            : (highlightedIndex || 0) + 1,
        )
      }
    }
    if (['Enter', 'Space'].includes(code)) {
      if (highlightedIndex >= 0) {
        e.preventDefault()
        const currentOption = options[highlightedIndex]
        const { selected, text, value } = currentOption
        toggleSelection({
          selected: !selected,
          text,
          value,
        })
        const updatedOptions: DropdownItemProps[] = []
        options.forEach((option) => {
          const $option = option
          if (!multipleSelection) {
            $option.selected = false
          }
          if ($option.value === currentOption.value) {
            $option.selected = !selected
          }
          updatedOptions.push(option)
        })
        setOptions(updatedOptions)
      }
      if (highlightedIndex === -1 && showOptions) {
        e.preventDefault()
        handleDropdownDisplay()
        return false
      }
      if (!multipleSelection) {
        setHighlightedIndex(-1)
        if (showOptions) {
          handleDropdownDisplay()
        }
      }
    }
    if (['Escape', 'Tab'].includes(code)) {
      hideOptions()
      setHighlightedIndex(-1)
    }
    return false
  }

  const optionsRef = useKeyRef(
    ['ArrowDown', 'ArrowUp', 'Enter', 'Space', 'Tab', 'Escape'],
    handleKeyboardOptionSelection,
  )

  const clearSelectedOptions = () => {
    if (multipleSelection) {
      setSelectedOptions([])
      setValue(id, [])
      updateSelectedText([])
    }
  }

  // Handles value reset via React Hook Form's reset() function
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      const newValue = value[name as unknown as string]
      if (!newValue) {
        toggleSelection({
          isReset: true,
          selected: false,
          text: newValue,
          value: newValue,
        })
        clearSelectedOptions()
      }
    })
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch])

  useEffect(() => {
    const checkOutsideClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement
      const currentRef = dropdownContainerRef.current as HTMLElement
      if (showOptions && currentRef && !currentRef.contains(target)) {
        hideOptions()
      }
    }
    document.addEventListener('mousedown', checkOutsideClick)
    return () => document.removeEventListener('mousedown', checkOutsideClick)
  }, [hideOptions, showOptions])

  const dropdownContextProviderValue = useMemo(() => {
    const addOption = (option: DropdownItemProps) => setOptions((opt) => [...opt, option])

    return {
      forceSelection,
      highlightedIndex,
      multipleSelection,
      selectedOptions,
      setHighlightedIndex,
      setWidth,
      addOption,
      options,
      toggleSelection,
      width: containerWidth,
    }
  }, [
    containerWidth,
    forceSelection,
    highlightedIndex,
    multipleSelection,
    options,
    selectedOptions,
    toggleSelection,
  ])

  useEffect(() => setFirstLoad(false), [])

  return (
    <StyleSheetManager target={renderRoot}>
      <DropdownContext.Provider value={dropdownContextProviderValue}>
        <StyledDropdown
          ref={dropdownContainerRef}
          style={{ minWidth: `${containerWidth}px` }}
          className={className}
          data-readonly={readonly}
          data-testid="dropdown"
        >
          {label && (
            <StyledLabel data-haserror={hasError} data-hasfocus={focus}>
              {label}
            </StyledLabel>
          )}
          <StyledSelectElement
            tabIndex={-1}
            id={id}
            multiple={multipleSelection}
            {...registerField}
            ref={(e: any) => {
              ref(e)
              selectRef.current = e
            }}
          />
          <div>
            <StyledDropdownButton
              data-haserror={hasError}
              data-hasfocus={focus}
              id={`${id}-button`}
              onFocus={() => setFocus(true)}
              onBlur={() => {
                setFocus(false)
                setHighlightedIndex(-1)
              }}
              onClick={handleDropdownDisplay}
              type="button"
              data-hasselection={selectedOptions.length > 0}
              tabIndex={0}
              ref={optionsRef}
              disabled={disabled}
            >
              <div>{selectedText}</div>
              <StyledCaretDown icon={solid('caret-down')} />
            </StyledDropdownButton>
          </div>
          <StyledDropdownOptions
            ref={dropdownOptionsRef}
            shouldShow={showOptions}
            tabindex="-1"
            data-testid="dropdown-options"
          >
            <ul role="none">{children}</ul>
            {multipleSelection && (
              <StyledClearButton
                data-hasselection={selectedOptions.length > 0}
                onClick={clearSelectedOptions}
                type="button"
                disabled={selectedOptions.length === 0}
              >
                Clear
              </StyledClearButton>
            )}
          </StyledDropdownOptions>

          {currentHelperMessage && (
            <StyledHelperText data-haserror={hasError} data-testid="dropdown-helper-text">
              {currentHelperMessage}
            </StyledHelperText>
          )}
        </StyledDropdown>
      </DropdownContext.Provider>
    </StyleSheetManager>
  )
}

export default Dropdown
