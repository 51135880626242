import React from 'react'

import formMethods from '../../framework/forms/form-methods'
import { StyledInput, StyledLabel, StyledLabelText, StyledRadioButton } from './radio-button.styles'
import { RadioButtonProps } from './radio-button.types'

/**
 * @deprecated Deprecated in favor of using Mui based RadioGroup and RadioItem
 */
export const RadioButton = ({
  checked,
  isChecked,
  className,
  disabled,
  id,
  label,
  name,
  value,
  onChange,
}: RadioButtonProps) => {
  const { register } = formMethods()
  const registerField = register(name)
  const { onChange: fieldOnChange } = registerField

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    fieldOnChange(e)
    onChange?.(e)
  }

  return (
    <div className={className} data-testid="radio-button">
      <StyledInput
        id={id}
        type="radio"
        hidden
        defaultChecked={checked}
        checked={isChecked}
        disabled={disabled}
        value={value}
        {...registerField}
        onChange={handleChange}
      />
      <StyledLabel htmlFor={id}>
        <StyledRadioButton />
        {label ? <StyledLabelText>{label}</StyledLabelText> : '\u00A0'}
      </StyledLabel>
    </div>
  )
}
