/* eslint-disable react/jsx-props-no-spreading */
import { Role } from '@brand-console/types'
import { Select, SelectItem, SelectProps } from '@cart/ui'
import { FC } from 'react'

export interface AddUserRoleSelectProps extends Partial<SelectProps> {
  roles: Role[]
  label: string
}
export const AddUserRoleSelect: FC<AddUserRoleSelectProps> = ({ label, roles, ...props }) => (
  <Select id="role" label={label} required tw="mb-4" {...props}>
    {roles.map((role) => {
      const { id, name, displayName } = role
      return (
        <SelectItem key={id} value={name}>
          {displayName}
        </SelectItem>
      )
    })}
  </Select>
)
