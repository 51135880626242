import { useBreakpoint } from '@cart/ui/hooks/useBreakpoint'
import { useKeyPress } from '@cart/ui/hooks/useKeyPress'
import { useOnClickOutside } from '@cart/ui/hooks/useOnClickOutside'
import { CartIcon } from '@cart/ui/icons/Cart'
import { faArrowLeftToLine } from '@fortawesome/pro-solid-svg-icons/faArrowLeftToLine'
import { faArrowRightToLine } from '@fortawesome/pro-solid-svg-icons/faArrowRightToLine'
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import { CSSTransition } from 'react-transition-group'
import tw, { css } from 'twin.macro'

import { useUnifiedSidebarStore } from '../../store/unified-sidebar.store'
import { SearchForm } from '../search-form/SearchForm'
import { StyledSearch } from './Header.styles'

export const Header = ({ jazzUrl, toggleExpanded, ...rest }) => {
  const { isExpanded } = rest
  const { isMenuExpanded, searchUrl } = useUnifiedSidebarStore()
  const [showSearch, setShowSearch] = useState(false)
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const isMobile = !useBreakpoint('sm')
  const searchRef = useRef(null)
  const nodeRef = useRef(null)

  // Close the search popper when the user presses the escape key
  useKeyPress({
    key: 'escape',
    onKeyPress: () => setShowSearch(false),
    preventDefault: true,
  })

  // Close the search popper when the user clicks outside of it
  useOnClickOutside(searchRef, () => setShowSearch(false))

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: isMobile ? undefined : 'right-start',
  })
  const defaultWidth = '645px'
  let searchWidth = isMobile ? '90%' : defaultWidth
  if (!isMobile) {
    searchWidth = `calc(100vw - ${isMenuExpanded ? 300 : 60}px`
  }

  const mobilePosition = isMobile
    ? {
      left: '50%',
      top: '4rem',
      transform: 'translateX(-50%)',
    }
    : undefined
  styles.popper = {
    ...styles.popper,
    width: searchWidth,
    maxWidth: defaultWidth,
    ...mobilePosition,
  }

  const handleSearch = (term) => {
    const encodedSearchString = encodeURIComponent(term)
    let $searchUrl = searchUrl.replace('%s', encodedSearchString)
    const params = new URLSearchParams(window.location.search)
    $searchUrl = $searchUrl.concat(`&${params.toString()}`)
    window.location.href = $searchUrl
  }

  const gotoHome = (e) => {
    e.preventDefault()
    const params = new URLSearchParams(window.location.search)
    const queryString = params.toString()
    window.location.href = `${jazzUrl}?${queryString}`
  }

  return (
    <div
      css={[
        tw`[max-width: calc(100% - 1px)] [border-bottom-style: solid] flex w-full justify-between border-monochrome-300 border-b p-2 py-4 mobile:bg-monochrome-50`,
        !isMenuExpanded && !isMobile && tw`flex-wrap justify-center gap-y-4 py-4`,
        (isMobile || isMenuExpanded) && tw`flex-nowrap`,
        isMenuExpanded &&
        css`
            @media (display-mode: standalone) {
              padding-top: calc(1rem + env(safe-area-inset-top));
            }
          `,
      ]}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <div tw="flex items-center gap-5" css={[!isMenuExpanded && tw`flex w-full`]}>
        <div tw="pl-2" css={[!isMenuExpanded && tw`w-full`]}>
          <FontAwesomeIcon
            role="button"
            icon={isMenuExpanded ? faArrowLeftToLine : faArrowRightToLine}
            tw="cursor-pointer p-2 text-monochrome-700 text-xl"
            onClick={toggleExpanded}
            aria-expanded={isMenuExpanded}
            aria-label="Toggle Sidebar"
          />
        </div>
        {isMenuExpanded && (
          <a href="/" onClick={gotoHome} aria-label="Go to Dashboard">
            <CartIcon tw="h-10 w-10" />
          </a>
        )}
      </div>
      <StyledSearch ref={searchRef}>
        <button
          type="button"
          tw="flex cursor-pointer items-center border-none bg-transparent text-base"
          ref={setReferenceElement}
          onClick={() => setShowSearch(!showSearch)}
          aria-label="Toggle Search"
          aria-expanded={showSearch}
          aria-controls="usb-searchContent"
        >
          {!isExpanded && <FontAwesomeIcon icon={faSearch} tw="p-2 text-monochrome-700 text-xl" />}
        </button>
        <CSSTransition
          nodeRef={nodeRef}
          in={showSearch}
          timeout={300}
          classNames="search"
          unmountOnExit
        >
          <div ref={nodeRef}>
            <div
              id="usb-searchContent"
              ref={setPopperElement}
              style={styles.popper}
              tw="[z-index: 2147483647] rounded-lg border border-monochrome-300 border-solid bg-white p-4 shadow-lg drop-shadow-lg"
              role="dialog"
              aria-hidden={!showSearch}
              aria-labelledby="usb-search-input"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...attributes.popper}
            >
              <SearchForm onClose={() => setShowSearch(false)} onSearch={handleSearch} />
            </div>
          </div>
        </CSSTransition>
      </StyledSearch>
    </div>
  )
}
