import { Step as MuiStep } from '@mui/material'
import React from 'react'

import { StepProps } from './stepper.types'

export const Step: React.FC<StepProps> = ({ children, ...rest }) => {
  return (
    <MuiStep tw="first:pl-0 last:pr-0" {...rest}>
      {children}
    </MuiStep>
  )
}
