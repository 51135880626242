import assign from 'lodash/assign'
import React, { FC, FocusEvent, useEffect, useState } from 'react'
import { StyleSheetManager } from 'styled-components'

import formMethods from '../../framework/forms/form-methods'
import {
  StyledMessage,
  StyledTextareaContainer,
  StyledTextareaField,
  StyledTextareaLabel,
} from './textarea.styles'
import { TextareaProps } from './textarea.types'

export const Textarea: FC<TextareaProps> = ({
  className,
  id,
  helperText,
  required,
  rules,
  value,
  disabled,
  readonly,
  placeholder,
  label,
  onFocus,
  onBlur,
  ...props
}: TextareaProps) => {
  const [hasError, setHasError] = useState(false)
  const [hasFocus, setHasFocus] = useState(false)
  const [currentHelperMessage, setCurrentHelperMessage] = useState(helperText)

  const onFocusEvent = (e: FocusEvent<HTMLTextAreaElement>) => {
    if (onFocus) {
      onFocus(e)
    }
    setHasFocus(true)
  }

  const onBlurEvent = (e: FocusEvent<HTMLTextAreaElement>) => {
    if (onBlur) {
      onBlur(e)
    }
    setHasFocus(false)
  }

  const {
    formState: { errors },
    register,
  } = formMethods()

  useEffect(() => {
    const error = errors[id]
    setCurrentHelperMessage((error?.message || helperText) as unknown as string)
    setHasError(!!error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, errors[id]?.type, id, helperText])

  const defaultRules = {
    required: required ? 'This field is required' : undefined,
  }

  const inputRules = assign({}, defaultRules, rules)
  const registerField = register(id, inputRules as Record<string, unknown>)

  return (
    <StyleSheetManager>
      <StyledTextareaContainer className={className}>
        {label && (
          <StyledTextareaLabel htmlFor={id} data-haserror={hasError} data-hasfocus={hasFocus}>
            {label}
          </StyledTextareaLabel>
        )}
        <StyledTextareaField
          placeholder={placeholder}
          aria-describedby={id}
          data-haserror={hasError}
          defaultValue={value}
          disabled={disabled}
          data-testid="textarea"
          id={id}
          readOnly={readonly}
          {...registerField}
          {...props}
          onBlur={onBlurEvent}
          onFocus={onFocusEvent}
        />
        {currentHelperMessage && (
          <StyledMessage data-haserror={hasError} data-hasfocus={hasFocus}>
            {currentHelperMessage}
          </StyledMessage>
        )}
      </StyledTextareaContainer>
    </StyleSheetManager>
  )
}
