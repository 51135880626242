import { logger } from '@brand-console/utilities'
import Typography from '@mui/material/Typography'
import { UseFormReturn } from 'react-hook-form'
import { Dispatch, SetStateAction } from 'react'

export interface IAccountConfirmData {
  firstName: string
  lastName: string
  phone: string
  businessName: string
  orgName?: string
}

export enum ErrorStep {
  UpdateUser,
  UpdateBiz,
}


export interface AccountConfirmFormProps {
  headerProps?: React.ComponentProps<typeof Typography>
  bodyProps?: React.ComponentProps<'div'>
  /** Displays errors in the form itself. Default is false.  */
  showErrorAlert?: boolean
  title?: string
  dialogTitle?: boolean
}

export interface UseAccountConfirmFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  methods: UseFormReturn<any, any>
  log?: typeof logger
}

export interface UseFormProps extends Pick<UseAccountConfirmFormProps, 'log'> {
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
  shouldShowForm: boolean
  methods: UseFormReturn<IAccountConfirmData>
  canUpdateBiz: boolean
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>
}

export interface UseAccountConfirmFormSubmitProps {
  methods: UseFormReturn<IAccountConfirmData>
  log?: typeof logger
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

export interface OnAccountConfirmFormSubmitProps {
  data: IAccountConfirmData
  onError: (error: Error, friendlyErrorMessage: string, scope: string) => void
}
