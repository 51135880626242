import {
  MeDocument,
  UpdateUserInput,
  useMeQuery,
  useUserUpdateMutation,
} from '@brand-console/generated-graphql-hooks'
import { AlertMessageType } from '@brand-console/types'
import { logger, validatePhoneRule } from '@brand-console/utilities'
import { Alert, AlertTitle, Button, Input, Spinner } from '@cart/ui'
import debounce from 'lodash/debounce'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import { FormProvider, useForm } from 'react-hook-form'
import { useEffectOnceWhen } from 'rooks'
import tw from 'twin.macro'
import isEmail from 'validator/lib/isEmail'

import { useUpdateUser } from '../../../hooks/useUpdateUser/useUpdateUser'
import { IncompleteProfileAlert } from '../../../components/IncompleteProfileAlert/IncompleteProfileAlert'

export const SettingsProfile = (): ReactElement => {
  const log = logger.setLogger(logger.LoggerNames.PROFILE)

  const [updateUser, updateUserMutationLoading] = useUpdateUser({ log })
  const { loading, data } = useMeQuery()

  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState<AlertMessageType>({
    type: null,
    heading: null,
    text: null,
  })

  const formMethods = useForm()
  const {
    formState: { isDirty },
    trigger,
    watch,
    getValues,
  } = formMethods

  // If org service flag is on, use result of graphql query as source of data
  const getUserData = useCallback(() => {
    return {
      firstName: data?.me?.user?.firstName,
      lastName: data?.me?.user?.lastName,
      email: data?.me?.user?.primaryEmail,
      phone: data?.me?.user?.userData?.phone,
      address1: data?.me?.user?.userData?.address1,
      address2: data?.me?.user?.userData?.address2,
      country: data?.me?.user?.userData?.country,
      state: data?.me?.user?.userData?.state,
      city: data?.me?.user?.userData?.city,
      zip: data?.me?.user?.userData?.zip,
    }
  }, [
    data?.me?.user?.firstName,
    data?.me?.user?.lastName,
    data?.me?.user?.primaryEmail,
    data?.me?.user?.userData?.address1,
    data?.me?.user?.userData?.address2,
    data?.me?.user?.userData?.city,
    data?.me?.user?.userData?.country,
    data?.me?.user?.userData?.phone,
    data?.me?.user?.userData?.state,
    data?.me?.user?.userData?.zip,
  ])

  useEffect(() => {
    const { firstName, lastName, email, phone, address1, address2, country, state, city, zip } =
      getUserData()
    formMethods.setValue('firstName', firstName)
    formMethods.setValue('lastName', lastName)
    formMethods.setValue('primaryEmail', email)
    formMethods.setValue('phone', phone)
    formMethods.setValue('address1', address1)
    formMethods.setValue('address2', address2)
    formMethods.setValue('country', country)
    formMethods.setValue('state', state)
    formMethods.setValue('city', city)
    formMethods.setValue('zip', zip)
  }, [formMethods, getUserData])

  // Users may have an invalid phone number, so we need to show the error message
  useEffectOnceWhen(() => {
    if (!getValues('phone')) return
    trigger('phone')
  }, watch('phone') !== undefined)

  TagManager.dataLayer({
    dataLayer: {
      event: 'pageview',
      route: 'SettingsProfile',
    },
  })

  const handleAlertClose = () => {
    setShowAlert(false)
    setAlertMessage({ type: null, heading: null, text: null })
  }

  function submitClick(submitData: UpdateUserInput) {
    // Don't save if nothing has changed
    const userData = getUserData()
    if (
      userData.firstName === submitData.firstName &&
      userData.lastName === submitData.lastName &&
      userData.email === submitData.primaryEmail &&
      userData.phone === submitData.phone &&
      userData.address1 === submitData.address1 &&
      userData.address2 === submitData.address2 &&
      userData.country === submitData.country &&
      userData.state === submitData.state &&
      userData.city === submitData.city &&
      userData.zip === submitData.zip
    )
      return

    updateUser({
      variables: {
        firstName: submitData.firstName ?? undefined,
        lastName: submitData.lastName ?? undefined,
        primaryEmail: submitData.primaryEmail ?? undefined,
        phone: submitData.phone ?? undefined,
        address1: submitData.address1 ?? undefined,
        address2: submitData.address2 ?? undefined,
        country: submitData.country ?? undefined,
        city: submitData.city ?? undefined,
        state: submitData.state ?? undefined,
        zip: submitData.zip ?? undefined,
      },
      onCompleted: (mutationData) => {
        setAlertMessage({
          type: 'success',
          heading: 'Profile information changed successfully',
          text: null,
        })
        setShowAlert(true)
      },
      onError: (gqlError) => {
        setAlertMessage({
          type: 'error',
          heading: 'Something went wrong when attempting to update your profile',
          text: null,
        })
        setShowAlert(true)
        log.error(JSON.stringify(gqlError, null, 2))
      },
    })
  }

  const handleIncompleteProfileComplete = () => {
    // Phone number may go from being invalid to valid, so we need to clear error message
    trigger('phone')
  }

  if (loading) return <Spinner tw="m-auto h-64 w-64" type="global" />

  return (
    <div tw="m-auto max-w-5xl px-3 sm:px-20">
      {showAlert && (
        <Alert severity={alertMessage.type} tw="mb-4" onClose={handleAlertClose}>
          <AlertTitle css={[!alertMessage.text && tw`mb-0`]}>{alertMessage.heading}</AlertTitle>
          {alertMessage.text}
        </Alert>
      )}

      <IncompleteProfileAlert onComplete={handleIncompleteProfileComplete} />

      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...formMethods}>
        <form noValidate onSubmit={formMethods.handleSubmit(submitClick)}>
          <div tw="flex justify-between">
            <h1 tw="mb-9 text-[24px]">Profile</h1>
            <div>
              <Button
                type="submit"
                variant="contained"
                disabled={!isDirty}
                loading={updateUserMutationLoading}
              >
                Save
              </Button>
            </div>
          </div>

          <div tw="mb-7 rounded bg-white shadow lg:mb-20">
            <div tw="py-4 px-7">
              <h2 tw="text-base">General</h2>
              <p tw="text-sm">This is basic information about your account.</p>
            </div>

            <fieldset tw="border-t border-t-monochrome-300 py-5 px-7">
              <div tw="grid grid-cols-2 gap-3.5">
                <Input
                  inputProps={{ maxLength: 40 }}
                  id="firstName"
                  label="First name"
                  placeholder="First name"
                  required
                />
                <Input
                  inputProps={{ maxLength: 40 }}
                  id="lastName"
                  label="Last name"
                  placeholder="Last name"
                  required
                />
              </div>
            </fieldset>
          </div>

          <div tw="mb-7 rounded bg-white shadow lg:mb-20">
            <div tw="py-4 px-7">
              <h2 tw="text-base">Contact</h2>
              <p>
                This is the personal information you use to access and manage your account. Your
                email and phone number are used for security and support.
              </p>
            </div>

            <fieldset tw="border-t border-t-monochrome-300 py-5 px-7">
              <div tw="mb-6 grid grid-cols-2 gap-3.5">
                <Input
                  id="primaryEmail"
                  label="Email address"
                  placeholder="Email"
                  readOnly
                  disabled
                  rules={{
                    validate: (value) => isEmail(value) || 'Please enter a valid email address',
                  }}
                />

                <Input
                  id="phone"
                  label="Phone"
                  placeholder="Phone"
                  required
                  type="tel"
                  rules={{
                    validate: validatePhoneRule,
                  }}
                  onChange={debounce(() => trigger('phone'), 300)}
                />
              </div>

              <Input
                tw="mb-6"
                id="address1"
                label="Street address 1"
                placeholder="Street address 1"
              />

              <Input
                tw="mb-6"
                id="address2"
                label="Street address 2"
                placeholder="Street address 2"
              />

              <div tw="mb-6 grid grid-cols-3 gap-3.5">
                <Input id="city" label="City" placeholder="City" />
                <Input id="state" label="State / Province" placeholder="State" />
                <Input id="zip" label="Postal code" placeholder="Postal code" />
              </div>

              <Input id="country" label="Country / Region" placeholder="Country" />
            </fieldset>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}
