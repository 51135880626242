import { StateCreator } from 'zustand'

import { SideMenu } from '../../gql/graphql'
import { resetters } from './storeResetter'
import { type StoreStateType } from './unified-sidebar.store'

export interface IMenuState {
  isMenuExpanded: boolean
  isSpa: boolean
  product: string
  menuItemData: SideMenu
  setIsMenuExpanded: (isMenuExpanded: boolean) => void
  setIsSpa: (isSpa: boolean) => void
  setProduct: (product: string) => void
  setMenuItemData: (menuItemData: SideMenu) => void
}

const defaultMenuState = {
  isMenuExpanded: true,
  isSpa: false,
  product: null,
  menuItemData: null,
}

// Sort the tenants so that the one with nodes is always on top.
const sortTenants = (a, b) => {
  if (a.nodes === null && b.nodes !== null) {
    return -1
  }
  if (a.nodes !== null && b.nodes === null) {
    return 1
  }
  return 0
}

export const createMenuSlice: StateCreator<
  StoreStateType,
  [['zustand/devtools', never]],
  [],
  IMenuState
> = (set) => {
  resetters.push(() => set(defaultMenuState, false, 'resetMenuState'))
  return {
    ...defaultMenuState,
    setIsMenuExpanded: (isMenuExpanded) => set({ isMenuExpanded }, false, 'setIsMenuExpanded'),
    setIsSpa: (isSpa) => set({ isSpa }, false, 'setIsSpa'),
    setProduct: (product) => set({ product }, false, 'setProduct'),
    setMenuItemData: (menuItemData) => {
      menuItemData?.tenants?.[0]?.nodes?.sort(sortTenants)
      return set({ menuItemData }, false, 'setMenuItemData')
    },
  }
}
