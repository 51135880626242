/* eslint-disable react/jsx-props-no-spreading */
import { StorefrontIcon } from '@cart/ui'
import React, { useMemo } from 'react'

import { dataConnectorsData } from '../../../../../helpers/data'

export const DataConnectorIcon = ({ dataConnector, width = 26, ...rest }) => {
  const imageSource = useMemo(
    () =>
      dataConnectorsData.find(({ connectorName, title }) => {
        if (dataConnector.title === title) return true
        if (dataConnector.dataConnectorType) {
          return connectorName === dataConnector.dataConnectorType
        }
        return connectorName === dataConnector.dataconnector_type.title
      })?.icon,
    [dataConnector],
  )

  if (dataConnector?.dataconnector_type === 'StorefrontDataConnector') {
    return <StorefrontIcon tw="w-[26px]" {...rest} />
  }

  return <img alt="icon" src={imageSource} css={{ width }} {...rest} />
}
