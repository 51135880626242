import { useAuth0 } from '@auth0/auth0-react'
import { callbackRedirectUriVar } from '@brand-console/generated-graphql-hooks'
import { FeatureFlagProvider, logger } from '@brand-console/utilities'
import { Spinner, useAppContext, useParamState, useRouter } from '@cart/ui'
import { useStoreInitializer } from '@cartdotcom/auth'
import { useEffect, useRef, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import TagManager from 'react-gtm-module'

import { AuthCallbackRoute } from '../../routes/AuthCallbackRoute'
import { UnsError } from '../../routes/Error/UnsError'
import { Feedback } from '../../routes/Feedback'
import { LoginSignUpRoutes, SFLoginSignUpRoutes } from '../../routes/LoginSignUpRoutes'
import { StatusRoute } from '../../routes/StatusRoute'
import { Logout } from '../Logout'
import { Main } from '../Main'

const publicPaths = [
  '/order-chat',
  '/status',
  '/logout',
  '/login',
  '/signup',
  '/sflogin',
  '/sfsignup',
  '/unserror',
]

/**
 * This component is the main entry point for the application. If will assure that the user is logged in
 * before rendering the main application.
 * @returns
 *   - Loading state
 *   - Main application component
 */
export const PreApp = () => {
  const [isImportedState, setIsImportedState] = useState<string>(undefined)

  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0()
  const { auth0Token, setAuth0Token } = useAppContext()
  const { pathname, navigate, location } = useRouter()
  const isPublicPath = publicPaths.find((path) => pathname.startsWith(path))
  const prevPathRef = useRef<string>(null)
  const { navParam } = useParamState()

  const { fetchInitialData, isLoaded } = useStoreInitializer()

  // Should acquire the token as the very first thing
  useEffect(() => {
    const getAccessToken = async () => {
      const token = await getAccessTokenSilently()
      unstable_batchedUpdates(() => {
        setAuth0Token(token)
      })
    }
    if (isAuthenticated) {
      getAccessToken()
    }
  }, [getAccessTokenSilently, isAuthenticated, setAuth0Token])

  useEffect(() => {
    if (isAuthenticated && !isLoaded) {
      fetchInitialData()
    }
  }, [fetchInitialData, isAuthenticated, isLoaded])

  // Listen to route changes
  useEffect(() => {
    const whichNav = navParam || isImportedState
    setIsImportedState(whichNav)

    if (location.pathname !== prevPathRef.current) {
      try {
        TagManager.dataLayer({
          dataLayer: {
            event: 'PageChanged',
          },
        })
      } catch (error) {
        logger.error('Google Tag Manager Error: Could not register page change', error)
      } finally {
        prevPathRef.current = location.pathname
      }

      // for navigation from outside of BC
      if (!navParam && whichNav === 'mcm') {
        navigate(`${window.location.pathname}?nav=mcm`, { replace: true })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, navParam])

  if (isPublicPath) {
    switch (true) {
      case pathname === '/unserror':
        return <UnsError />
      case pathname === '/status':
        return <StatusRoute />
      case pathname.startsWith('/logout'):
        return <Logout />
      case pathname.startsWith('/login'):
      case pathname.startsWith('/signup'):
        return <LoginSignUpRoutes />
      case pathname.startsWith('/sflogin'):
      case pathname.startsWith('/sfsignup'):
        return <SFLoginSignUpRoutes />
      default:
        break
    }
  }

  if (!isLoading && auth0Token && isAuthenticated && pathname === '/feedback') {
    return <Feedback />
  }

  if ((!isAuthenticated && !isLoading) || pathname === '/callback') {
    if (pathname !== '/callback') {
      callbackRedirectUriVar(location.pathname + location.search + location.hash)
    }

    return <AuthCallbackRoute />
  }

  if (isAuthenticated && auth0Token && isLoaded) {
    return (
      <FeatureFlagProvider>
        <Main />
      </FeatureFlagProvider>
    )
  }

  return <Spinner tw="m-auto h-64 w-64" />
}
