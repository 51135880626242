/**
 * This function highlights a substring in a string by wrapping it in a `<b />` tag
 *
 * @param   {string} string    the original string
 * @param   {string} substring term to be highlighted within the string
 * @returns string
 */
export const highlightSubstring = (string: string, substring: string): string => {
  const reg = new RegExp(substring, 'gi')
  return string.replace(reg, (str) => `<b>${str}</b>`)
}
