import { useReactiveVar } from '@apollo/client'
import {
  activeOrgVar,
  useUADataConnectorsListQuery,
  useUAOrganizationDataQuery,
} from '@brand-console/generated-graphql-hooks'
import { Button, Card, CardContent, StatusBadge } from '@cart/ui'
import React from 'react'

import { upgradeResponse } from '../../../../../../helpers/functions'
import { ButtonSetup } from '../../../../../MyConnectors/MyConnectorsModal/components/Modal/ButtonSetup'

type Props = {
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  filteredSet: Array<unknown>
  packageDataConnectorsList: Array<unknown>
  packageTier: string
}

export const CardsConnector = ({
  setShow,
  filteredSet,
  packageDataConnectorsList,
  packageTier,
}: Props) => {
  const activeOrg = useReactiveVar(activeOrgVar)

  const {
    data: orgData,
    loading: orgDataLoading,
    error: orgDataError,
  } = useUAOrganizationDataQuery({
    variables: {
      orgId: activeOrg?.id,
    },
  })
  const {
    data: dataConnectorsList,
    loading: dataConnectorsListLoading,
    error,
  } = useUADataConnectorsListQuery({
    variables: {
      organizationId: activeOrg?.id.toString(),
    },
  })

  const cardButton = (name, button) => {
    if (button) {
      if (
        name === 'google-adwords' ||
        name === 'google-analytics' ||
        name === 'google-analytics-4' ||
        name === 'google-search-console'
      ) {
        return <ButtonSetup connectorName={name} setShow={setShow} text="Sign in with Google" />
      }
      return (
        <div>
          <ButtonSetup connectorName={name} setShow={setShow} variant="contained" text="Add" />
        </div>
      )
    }
  }
  const upgradeButton = (name, button) => {
    if (button) {
      return (
        <div>
          <ButtonSetup
            connectorName={name}
            setShow={setShow}
            variant="outlined"
            text="Upgrade to connect"
          />
        </div>
      )
    }
  }
  if (packageTier === 'FreeTrial') {
    return (
      <>
        {filteredSet?.some(({ connectorName }) =>
          packageDataConnectorsList?.includes(connectorName),
        ) && <h2 tw="my-4 text-[24px]">Available During Free Trial</h2>}
        <div tw="mt-4 grid w-full grid-cols-1 gap-7 pb-4 md:grid-cols-2 xl:grid-cols-3">
          {orgData &&
            filteredSet?.map(({ title, icon, name, description, button, connectorName }) => {
              return (
                dataConnectorsList &&
                !dataConnectorsList.ua_dataConnectorsList?.find(
                  (card) => card.dataConnectorType === connectorName,
                ) &&
                connectorName !== 'StorefrontDataConnector' &&
                packageDataConnectorsList?.includes(connectorName) && (
                  <Card key={`filtered-${name}`} tw="w-full p-1">
                    <CardContent tw="flex h-full flex-col justify-between">
                      <div tw="flex flex-wrap items-center justify-between">
                        <div tw="flex items-center">
                          <img alt={icon} src={icon} tw="w-[35px] mr-[15px]" />
                          <h3 tw="mb-0">{title}</h3>
                        </div>
                        {!button ? (
                          <StatusBadge
                            tw="whitespace-nowrap capitalize max-w-[95px]"
                            type="info"
                            badgeContent="Coming Soon"
                          />
                        ) : null}
                      </div>
                      <p tw="mb-4 pt-3">{description || 'no description provided'}</p>
                      {cardButton(name, button)}
                    </CardContent>
                  </Card>
                )
              )
            })}
        </div>
        <h2 tw="my-4 text-[24px]">Available In Paid Plans</h2>
        <Card elevation={3} tw="my-6 w-full">
          <CardContent tw="flex w-full justify-between bg-accent-one-50 p-4">
            <div>
              <h5>Upgrade to connect to other sources.</h5>
              <p>
                Upgrade to one of our Unified Analytics Paid Plans to gain access to our full suite
                of data connectors. Connect to all your marketing and sales platforms to unlock
                deeper insights and scale your business! Fulfillment data sources available upon
                request for specific partners.
              </p>
            </div>
            <div tw="flex h-auto w-96 items-center justify-end">
              <Button variant="contained" size="medium" onClick={() => upgradeResponse()}>
                See Plans
              </Button>
            </div>
          </CardContent>
        </Card>
        <div tw="mt-4 grid w-full grid-cols-1 gap-7 pb-4 md:grid-cols-2 xl:grid-cols-3">
          {orgData &&
            filteredSet?.map(
              ({ title, icon, name, description, button, connectorName }) =>
                dataConnectorsList &&
                !dataConnectorsList.ua_dataConnectorsList?.find(
                  (card) => card.dataConnectorType === connectorName,
                ) &&
                connectorName !== 'StorefrontDataConnector' &&
                !packageDataConnectorsList?.includes(connectorName) && (
                  <Card key={`filtered-${name}`} tw="w-full p-1">
                    <CardContent tw="flex h-full flex-col justify-between">
                      <div tw="flex flex-wrap items-center justify-between">
                        <div tw="flex items-center">
                          <img alt={icon} src={icon} tw="w-[35px] mr-[15px]" />
                          <h3 tw="mb-0">{title}</h3>
                        </div>
                        {!button ? (
                          <StatusBadge
                            tw="whitespace-nowrap capitalize max-w-[95px]"
                            type="info"
                            badgeContent="Coming Soon"
                          />
                        ) : null}
                      </div>
                      <p tw="mb-4 pt-3">{description || 'no description provided'}</p>
                      {upgradeButton(name, button)}
                    </CardContent>
                  </Card>
                ),
            )}
        </div>
      </>
    )
  }

  if (filteredSet && filteredSet.length > 0) {
    return (
      <div tw="mt-4 grid w-full grid-cols-1 gap-7 pb-4 md:grid-cols-2 xl:grid-cols-3">
        {orgData &&
          filteredSet?.map(
            ({ title, icon, name, description, button, connectorName }) =>
              dataConnectorsList &&
              !dataConnectorsList.ua_dataConnectorsList?.find(
                (card) => card.dataConnectorType === connectorName,
              ) &&
              connectorName !== 'StorefrontDataConnector' && (
                <Card key={`filtered-${name}`} tw="w-full p-1">
                  <CardContent tw="flex h-full flex-col justify-between">
                    <div tw="flex flex-wrap items-center justify-between">
                      <div tw="flex items-center">
                        <img alt={icon} src={icon} tw="w-[35px] mr-[15px]" />
                        <h3 tw="mb-0">{title}</h3>
                      </div>
                      {!button ? (
                        <StatusBadge
                          tw="whitespace-nowrap capitalize max-w-[95px]"
                          type="info"
                          badgeContent="Coming Soon"
                        />
                      ) : null}
                    </div>
                    <p tw="mb-4 pt-3">{description || 'no description provided'}</p>
                    {cardButton(name, button)}
                  </CardContent>
                </Card>
              ),
          )}
      </div>
    )
  }

  if (filteredSet && filteredSet.length === 0) {
    return (
      <div tw="w-full rounded-b bg-monochrome-50 p-8 text-center text-monochrome-600">
        <h2>No matching results</h2>
        <p tw="mb-0">No connectors match your search or filter criteria</p>
      </div>
    )
  }
}
