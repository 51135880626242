export enum BcMenuIds {
  ORDERS = 'bc-orders',
  SHIPMENTS = 'bc-shipments',

  SHIPPING_SETTINGS = 'shipping-settings',
  BILLING_SETTINGS = 'billing-settings',
  STOREFRONT_SETTINGS = 'storefront-settings',
  MCM_SETTINGS = 'mcm-settings',
  ADMIN_TOOLS = 'admin-tools',
}
