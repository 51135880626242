import { StateCreator } from 'zustand'

import { resetters } from './storeResetter'
import { type StoreStateType } from './unified-sidebar.store'

export interface IGlobalState {
  rerenderKey: string
  searchUrl?: string
  setRerenderKey: (rerenderKey: string) => void
  setSearchUrl: (logoutUrl: string) => void
}

const defaultGlobalState = {
  rerenderKey: Math.random().toString(36).substring(7),
  searchUrl: '',
}

export const createGlobalSlice: StateCreator<
  StoreStateType,
  [['zustand/devtools', never]],
  [],
  IGlobalState
> = (set) => {
  resetters.push(() => set(defaultGlobalState, false, 'resetGlobalState'))
  return {
    ...defaultGlobalState,
    setRerenderKey: (rerenderKey) => set({ rerenderKey }, false, 'setRerenderKey'),
    setSearchUrl: (searchUrl) => set({ searchUrl }, false, 'setSearchUrl'),
  }
}
