import tw, { css, styled } from 'twin.macro'

export const StyledTenantButton = styled('button')(() => {
  return [
    tw`flex w-full items-center justify-between overflow-hidden rounded px-4 py-3 text-left font-sans text-sm leading-none antialiased`,
    tw`border-none bg-white hover:(bg-monochrome-50 text-monochrome-900) focus:(outline-none ring-2 ring-inset)`,
  ]
})

export const StyledTenantInfo = styled('div')(() => {
  const nodeName = 'tenant-switcher'
  return [
    tw`relative w-full rounded-lg border-none bg-white`,
    css`
      .${nodeName}-enter {
        opacity: 0;
      }
      .${nodeName}-enter-done {
        opacity: 1;
      }
      .${nodeName}-enter-active {
        opacity: 1;
        transition: opacity 200ms;
      }
      .${nodeName}-exit {
        opacity: 1;
      }
      .${nodeName}-exit-active {
        opacity: 0;
        transition: opacity 200ms;
      }
    `,
  ]
})
