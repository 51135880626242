import { useBreakpoint } from '@cart/ui/hooks/useBreakpoint'
import { useKeyPress } from '@cart/ui/hooks/useKeyPress'
import { useOnClickOutside } from '@cart/ui/hooks/useOnClickOutside'
import { faBuilding } from '@fortawesome/pro-solid-svg-icons/faBuilding'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown'
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck'
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ChangeEvent, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import tw from 'twin.macro'

import { SideMenuTenant } from '../../../gql/graphql'
import { useUnifiedSidebarStore } from '../../store/unified-sidebar.store'
import { TenantOptions } from './TenantInfo.partials'
import { StyledTenantInfo } from './TenantInfo.styles'

export const TenantInfo = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const tenantFilterRef = useRef(null)
  const nodeRef = useRef(null)
  const isMobile = !useBreakpoint('sm')
  const tenantSwitcherRef = useRef(null)
  const tenantOptionsRef = useRef(null)

  const { currentTenant, isMenuExpanded, menuItemData } = useUnifiedSidebarStore()
  const { tenants } = menuItemData

  const jazzRootTenant = tenants.find((tenant) => tenant.tenantCode === 'fbflurry')
  const $tenants = jazzRootTenant
    ? tenants.find((tenant) => tenant.tenantCode === 'fbflurry').nodes
    : tenants

  const [inputValue, setInputValue] = useState('')

  const shouldShowTenantSwitcher = $tenants.length > 1

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    tenantOptionsRef.current.scrollTop = 0
    setInputValue(e.target.value.toLowerCase())
  }

  // Close the tenant switcher when the user presses the escape key
  useKeyPress({
    key: 'escape',
    onKeyPress: () => setIsOpen(false),
    preventDefault: true,
  })

  // Close the tenant switcher when the user clicks outside of it
  useOnClickOutside(tenantSwitcherRef, () => setIsOpen(false))

  const toggleOptions = () => setIsOpen(!isOpen)

  const changeTenant = (tenant: SideMenuTenant, isJazzTenant: boolean) => {
    const queryParams = new URLSearchParams(window.location.search)
    if (tenant.businessId) {
      queryParams.set('businessId', tenant.businessId)
      queryParams.delete('tenantCode')
    } else {
      queryParams.set('tenantCode', tenant.tenantCode)
      queryParams.delete('businessId')
    }
    // Jazz uses businessId for businessId and tenantCode 🤷
    if (isJazzTenant) {
      queryParams.set('businessId', tenant.tenantCode || tenant.businessId)
      queryParams.delete('tenantCode')
    }
    const updatedLocation = isJazzTenant ? process.env.NX_JAZZ_ORIGIN : window.location.origin
    window.location.href = `${updatedLocation}?${queryParams.toString()}`
    toggleOptions()
  }

  let title

  if (currentTenant?.nodes) {
    const org = currentTenant
    const business = currentTenant?.nodes?.[0]
    title = `${org?.name} (${org.tenantCode || org.organizationId}) - ${business.name} (${business.tenantCode || business.businessId
      })`
  } else {
    title = `${currentTenant?.name} (${currentTenant?.tenantCode || currentTenant?.businessId}))`
  }

  return (
    <StyledTenantInfo ref={tenantSwitcherRef}>
      {!isMenuExpanded && (
        <button
          onClick={shouldShowTenantSwitcher ? toggleOptions : undefined}
          tw="flex w-full justify-center rounded-lg border border-monochrome-400 border-solid bg-white py-3.5"
          css={[!shouldShowTenantSwitcher && tw`!cursor-default`]}
          title={title}
          type="button"
        >
          <FontAwesomeIcon icon={faBuilding} tw="text-lg text-monochrome-600" />
        </button>
      )}
      {isMenuExpanded && (
        <button
          type="button"
          onClick={shouldShowTenantSwitcher ? toggleOptions : undefined}
          tw="relative flex w-full cursor-pointer items-center rounded-md border border-monochrome-400 border-solid bg-white py-2 pl-3 pr-10 text-left focus:(outline-none ring-0) sm:text-sm"
          css={[!shouldShowTenantSwitcher && tw`!cursor-default`]}
        >
          <div tw="w-full text-monochrome-700" title={title}>
            {/* Currently selected tenant/child tenant (org/biz) on the sidebar */}
            <div tw="truncate font-bold text-base">{currentTenant?.nodes?.[0]?.name}</div>
            <div tw="truncate text-sm">{currentTenant?.name}</div>
          </div>
          {shouldShowTenantSwitcher && (
            <span
              tw="pointer-events-none absolute right-0 mr-2 flex items-center leading-none transition-transform duration-300 ease-out"
              css={[isOpen && tw`rotate-180 transform`]}
            >
              <FontAwesomeIcon icon={faCaretDown} tw="p-2 text-base text-monochrome-800" />
            </span>
          )}
        </button>
      )}
      <CSSTransition
        nodeRef={nodeRef}
        in={isOpen}
        timeout={300}
        classNames="tenant-switcher"
        unmountOnExit
        onEnter={() => tenantFilterRef.current.focus()}
      >
        <div
          ref={nodeRef}
          css={[
            tw`[width: 325px] [max-width: calc(100vw - 2rem)] [z-index: 2147483647] absolute top-0 overflow-auto rounded-lg border border-monochrome-300 border-solid bg-white shadow-lg drop-shadow-lg`,
            isMobile
              ? tw`[left: 0] w-full`
              : [isMenuExpanded ? tw`[left: 280px]` : tw`[left: 50px]`],
          ]}
        >
          <div tw="px-4 pt-4 pb-2">
            <div tw="flex items-center justify-between gap-2 rounded-3xl border border-monochrome-900/25 border-solid px-2 py-2 leading-none">
              <button
                aria-label="Perform Search"
                type="submit"
                tw="flex cursor-pointer items-center border-none bg-transparent pl-2"
              >
                <FontAwesomeIcon
                  icon={faSearch}
                  tw="text-lg text-monochrome-700 hover:text-primary-700"
                />
              </button>
              <input
                tw="w-full border-none text-sm focus:outline-none"
                value={inputValue}
                placeholder="Search"
                ref={tenantFilterRef}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div tw="[border-bottom-style: solid] select-none border-b border-b-monochrome-300 p-2">
            <button
              type="button"
              tw="flex w-full cursor-default items-center justify-between rounded border-none bg-monochrome-50 p-4 text-monochrome-900 leading-none"
              onClick={isMobile ? toggleOptions : undefined}
            >
              {/* Currently selected tenant on the tenant switcher */}
              <span
                tw="overflow-hidden text-ellipsis"
                title={currentTenant.nodes?.[0] ? currentTenant.nodes[0].name : currentTenant.name}
              >
                {currentTenant.nodes?.[0] ? currentTenant.nodes[0].name : currentTenant.name}
              </span>
              <FontAwesomeIcon icon={faCircleCheck} tw="text-primary-700" />
            </button>
          </div>
          {jazzRootTenant && (
            <div tw="[border-bottom-style: solid] select-none border-b border-b-monochrome-300 p-2">
              <button
                type="button"
                tw="flex w-full items-center rounded border-none px-4 py-3 text-left font-sans text-sm leading-none antialiased hover:(bg-monochrome-50 text-monochrome-900) focus:(outline-none ring-2 ring-offset-2 ring-offset-white)"
                onClick={() =>
                  changeTenant(
                    {
                      businessId: null,
                      name: null,
                      organizationId: null,
                      tenantCode: jazzRootTenant.tenantCode,
                    },
                    true,
                  )
                }
              >
                <FontAwesomeIcon icon={faBuilding} tw="pr-2 text-monochrome-600" />
                {jazzRootTenant.name}
              </button>
            </div>
          )}
          <div ref={tenantOptionsRef} tw="[max-height: 60vh] overflow-auto p-2">
            <TenantOptions filter={inputValue} changeTenant={changeTenant} />
          </div>
        </div>
      </CSSTransition>
    </StyledTenantInfo>
  )
}
