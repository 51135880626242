import { fetchSfAccount, logger, useLayoutStore } from '@brand-console/utilities'
import { useAuthToken, useCartAuth } from '@cartdotcom/auth'
import { useEffect, useState } from 'react'

export interface UseSfAccountProps { log: typeof logger, businessId: number, skip?: boolean }
/** Fetches storefront account data and stores it in Layout Store */
export const useSfAccount = ({ skip, businessId, log }: UseSfAccountProps) => {
  const [sfDataBusinessId, setSfDataBusinessId] = useState<number | undefined>(businessId)
  const [loading, setLoading] = useState(false)
  const token = useAuthToken()
  const { auth0Id, email } = useCartAuth()
  const { sfAccount, setSfAccount } = useLayoutStore(state => ({
    setSfAccount: state.setSfAccount,
    sfAccount: state.sfAccount,
  }))

  useEffect(() => {
    const getSfAccount = async () => {
      if(!token || typeof email !== 'string') return

      setLoading(true)
      const sfAccountResponse = await fetchSfAccount({
        businessId,
        token,
        user: { auth0Id, email },
        log
      })
      setSfAccount(sfAccountResponse)
      setSfDataBusinessId(businessId)
      setLoading(false)
    }

    if (!skip && token && businessId && (!sfAccount || businessId !== sfDataBusinessId)) getSfAccount()
  }, [token, businessId, auth0Id, email, skip])

  return [sfAccount, { loading }] as const
}
