import { hubspotFormSubmit, logger } from '@brand-console/utilities'
import { Button, Input, Select, SelectItem } from '@cart/ui'
import React, { ReactElement, useState } from 'react'
import TagManager from 'react-gtm-module'
import tw from 'twin.macro'

import { useCartAuth, useCurrentContext } from '@cartdotcom/auth'
import { FormProvider, useForm } from 'react-hook-form'

export interface UnifiedAnalyticsFormData {
  contactPreference: string
  currentPlatform: string
  company: string
  howLongSellingOnline: string
  salespackage: string
  shipmentsPerDay: string
  shippingSpecialConsiderations: string
  specialCircumstances: string
  website: string
}
export const UnifiedAnalyticsForm = ({
  activeBiz,
  toggleModal,
  setError,
  setSuccess,
}): ReactElement => {
  const log = logger.setLogger(logger.LoggerNames.UNIFIED_ANALYTICS)
  const [loading, setLoading] = useState<boolean>(false)
  const methods = useForm({ mode: 'onChange' })
  const { handleSubmit, getValues } = methods

  const { firstName, lastName, email, phone } = useCartAuth()

  const sendGAFormEvent = (eventKey: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'formQuestion_filled',
        results: {
          formType: 'Unified Analytics Package Request',
          [eventKey]: getValues()[eventKey],
        },
      },
    })
  }

  const onSubmit = async (data) => {
    const { firstName, lastName, email, phoneNumber, company, salespackage, website } = data

    toggleModal()
    setError(false)
    setSuccess(false)
    setLoading(true)

    TagManager.dataLayer({
      dataLayer: {
        event: 'form_submit',
        results: { formType: 'Fulfillment Service Request', ...data },
      },
    })

    const hsForm = await hubspotFormSubmit({
      formId: 'fd5931f6-d781-4f58-bc31-3476f81bd47c',
      data: {
        email,
        firstname: firstName,
        lastname: lastName,
        phone: phoneNumber,
        company,
        salespackage,
        website,
      },
      portalId: parseInt(process.env.NX_HUBSPOT_MARKETING_FORMS_PORTAL_ID, 10),
      pageName: 'Brand Console - Unified Analytics Quote Request',
    }).catch((error) => {
      log.error('Unified Analytics Quote Request error', error)
      setError(true)
      setSuccess(false)
    })

    setLoading(false)
    if (hsForm) {
      setSuccess(true)
      setError(false)
    }
  }

  return (
    <section tw="w-full justify-center">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <form tw="mx-auto w-full" onSubmit={handleSubmit(onSubmit)} noValidate>
          <small tw="mb-4 block text-right text-primary-700">* Indicate required fields</small>
          <div tw="grid grid-cols-2 gap-4">
            <div>
              <div tw="mb-2 font-bold">First Name *</div>
              <Input
                id="firstName"
                size="small"
                placeholder="First Name"
                defaultValue={firstName}
                required
                onBlur={() => sendGAFormEvent('firstName')}
              />
            </div>
            <div>
              <div tw="mb-2 font-bold">Last Name *</div>
              <Input
                id="lastName"
                size="small"
                placeholder="Last Name"
                defaultValue={lastName}
                required
                onBlur={() => sendGAFormEvent('lastName')}
              />
            </div>
            <div>
              <div tw="mb-2 font-bold">Work Email *</div>
              <Input
                id="email"
                size="small"
                placeholder="Work Email"
                defaultValue={email}
                required
                onBlur={() => sendGAFormEvent('email')}
              />
            </div>
            <div>
              <div tw="mb-2 font-bold">Phone Number *</div>
              <Input
                id="phoneNumber"
                size="small"
                placeholder="Phone Number"
                defaultValue={phone}
                required
                onBlur={() => sendGAFormEvent('phoneNumber')}
              />
            </div>
            <div>
              <div tw="mb-2 font-bold">Company Name *</div>
              <Input
                id="company"
                size="small"
                placeholder="Company Name"
                defaultValue={activeBiz?.name}
                required
                onBlur={() => sendGAFormEvent('company')}
              />
            </div>
            <div>
              <div tw="mb-2 font-bold">Website URL *</div>
              <Input
                id="website"
                size="small"
                placeholder="Website URL"
                required
                onBlur={() => sendGAFormEvent('website')}
              />
            </div>

            <div>
              <div tw="mb-2 font-bold">Sales Package *</div>
              <Select
                id="salespackage"
                multiple
                required
                label="Select all that apply"
                size="small"
                onChange={() => sendGAFormEvent('salespackage')}
              >
                <SelectItem value="UA Core">Unified Analytics Core - Standalone Package</SelectItem>
                <SelectItem value="UA Plus">Unified Analytics Plus - Standalone Package</SelectItem>
                <SelectItem value="Marketing Analytics Co-Sell">
                  Marketing Analytics - Co-Sell Package
                </SelectItem>
                <SelectItem value="Fulfillment Analytics Co-Sell">
                  Fulfillment Analytics - Co-Sell Package
                </SelectItem>
                <SelectItem value="Channels Analytics Co-Sell">
                  Channels Analytics - Co-Sell Package
                </SelectItem>
              </Select>
            </div>
          </div>

          <div tw="mt-4 flex flex-wrap justify-end">
            <Button type="submit" loading={loading} variant="contained">
              Submit
            </Button>
          </div>
        </form>
      </FormProvider>
    </section>
  )
}
