import { useReactiveVar } from '@apollo/client'
import { routedBizVar, useBusinessUsersQuery, useorgSettings_UnblockUserMutation } from '@brand-console/generated-graphql-hooks'
import { Button, Card, Dialog, DialogTitle } from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MUICardHeader from '@mui/material/CardHeader'
import React, { useCallback, useEffect, useState } from 'react'

import { AddUsers } from '../../SettingsOrganizations/AddUsers/AddUsers'
import {
  ActionsCell,
  NameCell,
  RoleCell,
  StatusCell,
  UsersTableHeader,
} from '../../SettingsOrganizations/OrganizationUsers/OrganizationUsers.partials'
import { RemoveUsers } from '../../SettingsOrganizations/RemoveUsers/RemoveUsers'
import { WrappedDataGrid } from '../../SettingsOrganizations/SettingsOrganization.partials'
import { useSettingsOrganizationsContext } from '../../SettingsOrganizations/SettingsOrganizations.store'
import { isAtLeastBizAdmin, isOrgRole, useCurrentContext } from '@cartdotcom/auth'

export const BusinessUsers = () => {
  const { roleName } = useCurrentContext()
  const routedBusiness = useReactiveVar(routedBizVar)
  const { clearAlert, setAlert } = useSettingsOrganizationsContext()

  const isBusinessAdminOrAbove = isAtLeastBizAdmin(roleName)
  const orgBusinessShowId = routedBusiness.id

  const { data, loading, error, refetch } = useBusinessUsersQuery({
    variables: { orgBusinessShowId },
  })

  useEffect(() => {
    if (error) {
      setAlert({ variant: 'error', text: `There was a problem retrieving users: ${error}` })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  let members = [...data?.org_businessShow?.members || []]
  members = members.sort((a, b) => a.primaryEmail?.localeCompare(b.primaryEmail))

  const [showAddUsersModal, setShowAddUsersModal] = useState<boolean>(false)

  const toggleAddUsersModal = useCallback(
    (shouldReload: boolean) => {
      if (shouldReload) {
        refetch()
      }
      setShowAddUsersModal(!showAddUsersModal)
    },
    [refetch, showAddUsersModal],
  )

  const handleAddUserClick = () => {
    clearAlert()
    toggleAddUsersModal(true)
  }

  const [showRemoveUsersModal, setShowRemoveUsersModal] = useState(false)
  const [usersSelectedForRemoval, setUsersSelectedForRemoval] = useState([]);

  const toggleRemoveUsersModal = useCallback(
    (shouldReload: boolean) => {
      if (showRemoveUsersModal && shouldReload) {
        refetch()
      }
      setShowRemoveUsersModal(!showRemoveUsersModal)
    },
    [refetch, showRemoveUsersModal],
  )

  const handleRemoveUserClick = useCallback(
    (userId: number) => {
      const users = members.filter((u) => u.id === userId)
      setUsersSelectedForRemoval(users)
      if (users) {
        toggleRemoveUsersModal(true)
      }
    },
    [members, setUsersSelectedForRemoval]
  )

  const [unblockUser] = useorgSettings_UnblockUserMutation()

  const handleUnblockUserClick = (userId: number) => {
    clearAlert()
    unblockUser({
      variables: {
        orgUserId: userId,
      },
      onCompleted: () => {
        setAlert({ variant: 'success', text: 'User unblocked' })
      },
      onError: (error) => {
        console.error('Error occurred unblocking user', error)
        setAlert({ variant: 'error', text: `Error occurred unblocking user: ${error.message}` })
      },
    })
  }

  const shouldShowActionsColumn = isBusinessAdminOrAbove

  return (
    <section>
      {isBusinessAdminOrAbove && (
        <div tw="mb-6 flex w-full justify-end">
          <Dialog
            id="remove-users-dialog"
            open={showRemoveUsersModal}
            onClose={() => toggleRemoveUsersModal(false)}
            width="sm"
          >
            <DialogTitle>Remove users</DialogTitle>
            <RemoveUsers
              close={toggleRemoveUsersModal}
              scope="business"
              users={usersSelectedForRemoval}
            />
          </Dialog>
        </div>
      )}
      <Card tw="w-full">
        <MUICardHeader component={() => <UsersTableHeader />} />
        <WrappedDataGrid
          loading={loading}
          columns={[
            {
              headerName: 'User',
              field: 'firstName',
              renderCell: NameCell,
              flex: 2,
              minWidth: 250,
            },
            {
              headerName: 'Business Role',
              field: 'role',
              valueGetter: RoleCell,
              flex: 1,
              minWidth: 150,
            },
            {
              headerName: 'Status',
              field: 'user',
              valueGetter: () => 'null',
              renderCell: StatusCell,
              flex: 1,
              minWidth: 150,
            },
            {
              headerName: 'Actions',
              field: 'id',
              renderCell: (params) => <ActionsCell row={params.row} canRemove={isBusinessAdminOrAbove} onRemoveClick={handleRemoveUserClick} onUnblockClick={handleUnblockUserClick} />,
              sortable: false,
              disableColumnMenu: true,
              hide: !shouldShowActionsColumn,
            },
          ]}
          rows={loading ? [] : members}
        />
        {isBusinessAdminOrAbove && (
          <div tw="my-5 ml-7">
            <Button
              startIcon={<FontAwesomeIcon icon={solid('plus')} />}
              variant="outlined"
              onClick={handleAddUserClick}
              data-testid="add-business-users-button"
            >
              Add User
            </Button>
            <Dialog
              id="add-users-dialog"
              open={showAddUsersModal}
              onClose={() => toggleAddUsersModal(false)}
              width="sm"
            >
              <DialogTitle>Add users and set permissions</DialogTitle>
              <AddUsers close={toggleAddUsersModal} flow="business" />
            </Dialog>
          </div>
        )}
      </Card>
    </section>
  )
}
