import React, { ReactElement } from 'react'

export const CartLogo = ({
  className,
  color,
}: {
  className?: string
  color?: string
}): ReactElement => {
  const title = 'Cart.com Logo'
  const cartColor = color ? 'transparent' : '#fff'
  const shapeColor = color || '#289dd7'
  return (
    <svg
      aria-label={title}
      className={className}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 136 76"
    >
      <title>{title}</title>
      <path
        d="M4.48,28.27.56,43l1.65,5L47.83,60.9,71.45,48.27l1.34-4.92.74-14.18-1-3.89L66,18.16,9.35,22.93Z"
        fill={cartColor}
      />
      <path
        d="M36.39,33.48a5.65,5.65,0,0,0-4.18-1.65,5.57,5.57,0,0,0-4.15,1.65,5.82,5.82,0,0,0-1.62,4.25A5.83,5.83,0,0,0,28.06,42a5.57,5.57,0,0,0,4.15,1.65A5.65,5.65,0,0,0,36.39,42,5.73,5.73,0,0,0,38,37.73,5.72,5.72,0,0,0,36.39,33.48Zm0,0a5.65,5.65,0,0,0-4.18-1.65,5.57,5.57,0,0,0-4.15,1.65,5.82,5.82,0,0,0-1.62,4.25A5.83,5.83,0,0,0,28.06,42a5.57,5.57,0,0,0,4.15,1.65A5.65,5.65,0,0,0,36.39,42,5.73,5.73,0,0,0,38,37.73,5.72,5.72,0,0,0,36.39,33.48Zm37.3-9.23L51.8,2.39A8.14,8.14,0,0,0,43.94.28l-29.87,8A8.16,8.16,0,0,0,8.29,14L4.48,28.29a10.9,10.9,0,0,1,5.95-1.62A11,11,0,0,1,16,28.14a9.84,9.84,0,0,1,3.82,3.94l-4.67,2.71a4.67,4.67,0,0,0-1.9-2.06A5.4,5.4,0,0,0,10.38,32a5.4,5.4,0,0,0-4,1.62,6.09,6.09,0,0,0,0,8.23,5.4,5.4,0,0,0,4,1.62,5.47,5.47,0,0,0,2.91-.75,4.57,4.57,0,0,0,1.91-2l4.69,2.68a10.73,10.73,0,0,1-3.89,4,10.87,10.87,0,0,1-5.59,1.49,10.65,10.65,0,0,1-7.92-3.19,10.91,10.91,0,0,1-2-2.68l-.25,1a8.14,8.14,0,0,0,2.11,7.86L24.25,73.61a8.14,8.14,0,0,0,7.86,2.11l29.88-8a8.16,8.16,0,0,0,5.77-5.78l3.69-13.71c-3.43.31-5.86,0-7.3-1.11s-2.29-3.12-2.29-6V23.24l5.41-3.12v7.12H72v5.2H67.27v8.74a2.09,2.09,0,0,0,.54,1.6,2.53,2.53,0,0,0,1.6.57c.59,0,2.24,0,3.35,0l3-11.21A8.19,8.19,0,0,0,73.69,24.25Zm-30.24,24H40.51A2.48,2.48,0,0,1,38,45.75,7.9,7.9,0,0,1,35.13,48a9.36,9.36,0,0,1-3.92.77A9.35,9.35,0,0,1,24,45.59a11.17,11.17,0,0,1-3-7.86,11.14,11.14,0,0,1,3-7.86,9.53,9.53,0,0,1,7.19-3.22A8.26,8.26,0,0,1,38,29.71V27.24h5.41ZM58.76,32.86a6.3,6.3,0,0,0-4.43,1,4.87,4.87,0,0,0-2,4.33v10H47v-21h5.41v3.61a5.74,5.74,0,0,1,2.5-3,7.61,7.61,0,0,1,3.89-1Zm-26.55-1a5.57,5.57,0,0,0-4.15,1.65,5.82,5.82,0,0,0-1.62,4.25A5.83,5.83,0,0,0,28.06,42a5.57,5.57,0,0,0,4.15,1.65A5.65,5.65,0,0,0,36.39,42,5.73,5.73,0,0,0,38,37.73a5.72,5.72,0,0,0-1.65-4.25A5.65,5.65,0,0,0,32.21,31.83Z"
        fill={shapeColor}
      />
      <path
        d="M109.24,35.36a7.63,7.63,0,0,0-5.55-2.24,7.67,7.67,0,0,0-7.76,7.75,7.67,7.67,0,0,0,7.76,7.76,7.6,7.6,0,0,0,5.55-2.24,7.86,7.86,0,0,0,0-11Zm-2.68,8.43A3.92,3.92,0,0,1,103.69,45a3.8,3.8,0,0,1-2.83-1.16,4.3,4.3,0,0,1,0-5.83,3.8,3.8,0,0,1,2.83-1.16,4.13,4.13,0,0,1,2.87,7Z"
        fill={shapeColor}
      />
      <path d="M76.76,48.45a2.5,2.5,0,1,0-2.5-2.5A2.5,2.5,0,0,0,76.76,48.45Z" fill={shapeColor} />
      <path
        d="M88.25,48.63a7.6,7.6,0,0,1-7.78-7.78,7.64,7.64,0,0,1,2.21-5.54,8,8,0,0,1,9.49-1.22,7,7,0,0,1,2.68,2.79l-3.27,1.91a3.35,3.35,0,0,0-1.34-1.45,4.07,4.07,0,0,0-4.85.6,3.91,3.91,0,0,0-1.11,2.91,4,4,0,0,0,1.11,2.89,3.9,3.9,0,0,0,2.84,1.13,4,4,0,0,0,2-.52,3.14,3.14,0,0,0,1.34-1.41l3.3,1.88a7.36,7.36,0,0,1-2.73,2.78A7.48,7.48,0,0,1,88.25,48.63Z"
        fill={shapeColor}
      />
      <path
        d="M130.09,33.09a5.23,5.23,0,0,1,4,1.65,6.35,6.35,0,0,1,1.52,4.41v9.07h-3.79V39.43a3,3,0,0,0-.65-2.06,2.3,2.3,0,0,0-1.83-.75,2.45,2.45,0,0,0-2,.85,3.69,3.69,0,0,0-.72,2.48v8.27h-3.79V39.43a3,3,0,0,0-.64-2.06,2.31,2.31,0,0,0-1.83-.75,2.51,2.51,0,0,0-2,.85,3.69,3.69,0,0,0-.75,2.48v8.27h-3.79V33.5h3.79v1.57a4.58,4.58,0,0,1,4.1-2,4.33,4.33,0,0,1,4,2.11A5,5,0,0,1,130.09,33.09Z"
        fill={shapeColor}
      />
    </svg>
  )
}
