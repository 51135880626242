import { ChoicesModelType } from '../types/choicesTypes'
import { Statuses } from '../types/dataConnectorsTypes'
import { dsApi } from './dsApiSlice'

type ChoicesResponseType = {
  data: ChoicesModelType
}

type StatusesResponse = {
  data: {
    data_connector_status: Statuses[]
  }
}

const choicesEndpoints = dsApi.injectEndpoints({
  endpoints: (builder) => ({
    getChoices: builder.query<ChoicesResponseType, string>({
      query: (choices: string) => `core/choices/?models=${choices}`,
    }),

    getStatuses: builder.query<StatusesResponse, void>({
      query: () => 'core/choices/?models=data_connector_status',
    }),
  }),
})

export const {
  useGetChoicesQuery,
  useLazyGetChoicesQuery,
  useGetStatusesQuery,
  useLazyGetStatusesQuery,
} = choicesEndpoints
