// common
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// types
import { ChoicesTitleType } from '../types/emailReportsTypes'

// Define a type for the slice state

// @ts-ignore
const timeZones = Intl.supportedValuesOf('timeZone')
interface EmailReportsStateType {
  activeTimePeriod: 'daily' | 'weekly' | 'monthly'
  chooseTimePeriod: {
    everyDay: boolean
    someDay: boolean
    anyDayOfMonth: boolean
    specificDayOfMonth: boolean
    chooseWeekDay: string[]
  }
  checkedSendReport: boolean
  weekDays: {
    value: 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat'
    title: string
  }[]
  radioButtons: { value: 'daily' | 'weekly' | 'monthly'; title: string }[]
  choosingChoices: ChoicesTitleType | null
  filterBy: {
    subject: boolean | null
    business: boolean | null
    emailSchedule: boolean | null
    recipients: boolean | null
    reportsIncluded: boolean | null
    createdBy: boolean | null
  }
  actionsActive: {
    business: string | null
    createdBy: string | null
  }
  activeSearch: string
  selectedReports: string[]
  timeOptions: { value: string; title: string }[]
  byAccount: { value: string; title: string }[]
  timeZoneOptions: { value: string; title: string }[]
}

// Define the initial state using type
const initialState: EmailReportsStateType = {
  activeTimePeriod: 'daily',
  chooseTimePeriod: {
    everyDay: true,
    someDay: false,
    anyDayOfMonth: true,
    specificDayOfMonth: false,
    chooseWeekDay: [],
  },
  checkedSendReport: false,
  choosingChoices: null,
  filterBy: {
    subject: null,
    business: null,
    emailSchedule: null,
    recipients: null,
    reportsIncluded: null,
    createdBy: null,
  },
  actionsActive: {
    business: 'default',
    createdBy: 'default',
  },
  activeSearch: '',
  selectedReports: [],
  timeOptions: [
    { value: '24', title: '12AM' },
    { value: '1', title: '1AM' },
    { value: '2', title: '2AM' },
    { value: '3', title: '3AM' },
    { value: '4', title: '4AM' },
    { value: '5', title: '5AM' },
    { value: '6', title: '6AM' },
    { value: '7', title: '7AM' },
    { value: '8', title: '8AM' },
    { value: '9', title: '9AM' },
    { value: '10', title: '10AM' },
    { value: '11', title: '11AM' },
    { value: '12', title: '12PM' },
    { value: '12', title: '1PM' },
    { value: '14', title: '2PM' },
    { value: '15', title: '3PM' },
    { value: '16', title: '4PM' },
    { value: '17', title: '5PM' },
    { value: '18', title: '6PM' },
    { value: '19', title: '7PM' },
    { value: '20', title: '8PM' },
    { value: '21', title: '9PM' },
    { value: '22', title: '10PM' },
    { value: '23', title: '11PM' },
  ],
  weekDays: [
    { value: 'sun', title: 'Sunday' },
    { value: 'mon', title: 'Monday' },
    { value: 'tue', title: 'Tuesday' },
    { value: 'wed', title: 'Wednesday' },
    { value: 'thu', title: 'Thursday' },
    { value: 'fri', title: 'Friday' },
    { value: 'sat', title: 'Saturday' },
  ],
  timeZoneOptions: timeZones.map((zone) => {
    return { value: zone, title: zone }
  }),
  radioButtons: [
    { value: 'daily', title: 'Daily' },
    { value: 'weekly', title: 'Weekly' },
    { value: 'monthly', title: 'Monthly' },
  ],
  byAccount: [
    { value: '1', title: '1st' },
    { value: '2', title: '2nd' },
    { value: '3', title: '3rd' },
    { value: 'last', title: 'last' },
  ],
}

export const emailReportsSlice = createSlice({
  name: 'emailReports',
  initialState,
  reducers: {
    setInitialTimePeriod: (state) => {
      state.chooseTimePeriod = initialState.chooseTimePeriod
    },
    setActiveTimePeriod: (state, action: PayloadAction<'daily' | 'weekly' | 'monthly'>) => {
      state.activeTimePeriod = action.payload
    },
    setTimePeriod: (state, action) => {
      state.chooseTimePeriod = {
        ...state.chooseTimePeriod,
        [action.payload.field]: action.payload.value,
      }
    },
    setChoicesKeys: (state, action: PayloadAction<string[]>) => {
      state.choosingChoices = {}
      action.payload.map((item) => (state.choosingChoices[item] = []))
    },
    setFilterBy: (state, action) => {
      state.filterBy[action.payload.filterName] = action.payload.filter
    },
    setCheckedSendReport: (state) => {
      state.checkedSendReport = !state.checkedSendReport
    },
    setActionActive: (state, action) => {
      state.actionsActive[action.payload.actionName] = action.payload.action
    },
    setSearchActive: (state, action) => {
      state.actionsActive = initialState.actionsActive
      state.activeSearch = action.payload
    },
    setChoosingReports: (state, action: PayloadAction<{ id: string; key: string } | null>) => {
      const isReportChosen = state.choosingChoices[action.payload.key]?.find(
        (val) => val === action.payload.id,
      )
      if (isReportChosen) {
        state.choosingChoices = {
          ...state.choosingChoices,
          [action.payload.key]: state.choosingChoices[action.payload.key].filter(
            (val) => val !== action.payload.id,
          ),
        }
      } else {
        state.choosingChoices = {
          ...state.choosingChoices,
          [action.payload.key]: [...state.choosingChoices[action.payload.key], action.payload.id],
        }
      }
    },
    setChoosingAllReports: (state, action: PayloadAction<{ result: any; key: string } | null>) => {
      state.choosingChoices = {
        ...state.choosingChoices,
        [action.payload.key]: action.payload.result,
      }
    },
    setSelectedReports: (state, action: PayloadAction<string>) => {
      if (
        state.selectedReports.length > 0 &&
        state.selectedReports.find((reportId) => reportId === action.payload)
      ) {
        state.selectedReports = state.selectedReports.filter(
          (reportId) => reportId !== action.payload,
        )
      } else {
        state.selectedReports = [...state.selectedReports, action.payload]
      }
    },
  },
})

export const {
  setSelectedReports,
  setChoosingReports,
  setChoosingAllReports,
  setFilterBy,
  setActionActive,
  setSearchActive,
  setChoicesKeys,
  setActiveTimePeriod,
  setTimePeriod,
  setInitialTimePeriod,
  setCheckedSendReport,
} = emailReportsSlice.actions
export default emailReportsSlice.reducer
