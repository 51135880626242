import { theme } from 'tailwindcss/defaultConfig'

const getBreakpointValue = (value: string): number => {
  return +value.slice(0, value.indexOf('px'))
}

export const getCurrentBreakpoint = (): string => {
  let currentBreakpoint = ''
  let biggestBreakpointValue = 0
  const map = new Map(Object.entries(theme?.screens ?? []))
  map.forEach((value: unknown, key: string) => {
    const breakpoint = value as string
    if (key === 'mobile') {
      currentBreakpoint = key
      return
    }
    const breakpointValue = getBreakpointValue(breakpoint)
    if (breakpointValue > biggestBreakpointValue && window.innerWidth >= breakpointValue) {
      biggestBreakpointValue = breakpointValue
      currentBreakpoint = key
    }
  })
  return currentBreakpoint
}
