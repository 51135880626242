import tw, { css, styled } from 'twin.macro'

const renderMenuItemsIndentation = ({ isMenuExpanded, numberOfTimes, currentPadding }) => {
  if (numberOfTimes <= 0) {
    return ''
  }
  if (!isMenuExpanded) {
    // eslint-disable-next-line no-param-reassign
    currentPadding -= 2
  }
  const padding = (currentPadding * 1.75).toFixed(2)
  return `
    & ul {
      & button,
      & a {
        padding-left: ${padding}rem;
      }
      ${renderMenuItemsIndentation({
        isMenuExpanded,
        numberOfTimes: numberOfTimes - 1,
        currentPadding: currentPadding + 1,
      })}
    }
  `
}

export const StyledMenuItem = styled('li')<{ isMenuExpanded: boolean; active?: boolean }>(
  ({ active, isMenuExpanded }) => [
    tw`flex items-center text-center`,
    isMenuExpanded && [tw`text-left`],
    css`
      & a {
        ${tw`flex w-full justify-start whitespace-normal rounded px-4 font-sans text-sm text-monochrome-700 no-underline`}
        ${!active && tw`hover:bg-monochrome-200`}
        ${isMenuExpanded ? tw`py-2` : tw`py-3.5`}
      }
    `,
    active &&
      css`
        & a {
          ${tw`bg-monochrome-300 text-primary-700`}
        }
      `,
  ],
)

export const StyledMenuHeader = styled('li')<{ isExpanded?: boolean; isMenuExpanded?: boolean }>(
  ({ isExpanded, isMenuExpanded }) => [
    tw`relative list-none text-center`,
    isMenuExpanded && tw`text-left`,
    !isMenuExpanded && [
      // Show only the first level of the node label if the menu is collapsed
      css`
        & button {
          ${tw`bg-monochrome-50/50`}
          & [data-id='node-title'] {
            ${tw`hidden`}
          }
          & + ul button [data-id='node-title'] {
            ${tw`block`}
          }
        }
      `,
      // Only first level should be absolute positioned when menu is not expanded
      css`
        & > ul ul {
          ${tw`static !(m-0 p-0) border-none shadow-none w-full`}
          & a {
            ${tw`!pl-8`}
          }
        }
      `,
      isExpanded &&
        css`
          & > ul {
            ${tw`[max-height: 500px] -ml-1 block list-none overflow-auto pl-0`}
          }
        `,
    ],
    css`
      & button {
        ${tw`w-full whitespace-normal rounded px-4 text-left hover:(!rounded bg-monochrome-200)`}
        ${isMenuExpanded ? tw`py-2` : tw`py-3.5`}
      }
      ${renderMenuItemsIndentation({ isMenuExpanded, numberOfTimes: 10, currentPadding: 1.5 })}
    `,
  ],
)
