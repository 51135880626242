import { createContext } from 'react'

export type HttpHeaders = {
  Accept: string
  'Content-Type': string
  Authorization: string
  'x-cartid-sub': string
  'x-cartid-email': string
}

export const HttpHeadersContext = createContext<HttpHeaders | null>(null)
