/**
 * Concatenate a secondary string if its valid.
 * The default delimiter is a colon, :.
 */
export function appendIfValid(
  requiredMsg: string,
  optionalMessage?: string | null,
  delimiter = ': ',
) {
  return `${requiredMsg}${optionalMessage ? delimiter : ''}${optionalMessage ?? ''}`
}
