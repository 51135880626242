import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { AppState, Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getTopLevelDomain } from '../helpers/getTopLevelDomain/getTopLevelDomain'
import { logger } from '../logging'
import { HttpHeaders, HttpHeadersContext } from './HttpHeadersContext'

export const Auth0ApolloProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const log = logger.setLogger()
  const { user, getAccessTokenSilently } = useAuth0()
  const [httpHeaders, setHttpHeaders] = useState<HttpHeaders | null>(null)
  const httpLink = new HttpLink({
    uri: process.env.NX_GRAPHQL_URL,
  })

  useEffect(() => {
    const setData = async () => {
      const token = await getAccessTokenSilently()
      setHttpHeaders({
        Authorization: `Bearer ${token}`,
        'x-cartid-sub': user?.sub || '',
        'x-cartid-email': user?.email || '',
        Accept: 'application/json',
        'Content-Type': 'application/json',
      })
    }
    if (user) {
      setData()
    }
  }, [getAccessTokenSilently, user])

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        log.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      })
    }
    if (networkError) {
      log.error(`[Network error]: Message: ${networkError}`)
    }
  })

  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...httpHeaders,
        ...headers,
      },
    }
  })

  const client: ApolloClient<Record<string, unknown>> = new ApolloClient({
    link: from([errorLink, authLink.concat(httpLink)]),
    cache: new InMemoryCache(),
    connectToDevTools: true,
  })

  return (
    <HttpHeadersContext.Provider value={httpHeaders}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </HttpHeadersContext.Provider>
  )
}

export const Auth0ProviderWithHistory = ({ children }: { children: ReactNode }): ReactElement => {
  const domain = process.env.NX_AUTH0_DOMAIN ?? ''
  const clientId = process.env.NX_AUTH0_CLIENT_ID ?? ''
  const audience = process.env.NX_AUTH0_AUDIENCE ?? ''
  const navigate = useNavigate()

  const onRedirectCallback = (appState?: AppState): void => {
    let route: string
    const {
      location: { search, pathname },
    } = window
    const separator = !search ? '?' : '&'
    switch (true) {
      case appState?.isMcmNav:
        route = `${pathname}${search}${separator}nav=mcm`
        break
      default:
        route = `${pathname}${search}`
    }
    navigate(appState?.returnTo || route)
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      skipRedirectCallback={window.location.pathname.includes('unified-analytics/my-connectors')}
      cookieDomain={`.${getTopLevelDomain(window.location.hostname)}`}
    >
      {children}
    </Auth0Provider>
  )
}
