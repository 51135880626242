/* eslint-disable react/jsx-props-no-spreading */
import { Button } from '@cart/ui/atoms/Button'
import { Card } from '@cart/ui/molecules/Card'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link as MuiLink, Tab, Tabs } from '@mui/material'
import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { matchPath, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { copyUrlToClipboard, navigateToUrl } from '../../helpers'
import { useToolsContext } from '../../merchant-tools.store'
import { ROUTES } from './ResourceTabs.constants'

export const ResourcesTabs = () => {
  const methods = useForm()
  const location = useLocation()
  const navigate = useNavigate()
  const { value, url, setValue } = useToolsContext()

  let tabIndex = ROUTES.findIndex((route) => matchPath(location.pathname, route.path))
  if (tabIndex < 0) {
    tabIndex = 0
  }

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    const targetElement = event.target as HTMLAnchorElement // Type assertion to HTMLAnchorElement
    const { href } = targetElement
    const { pathname } = new URL(href)
    navigate(pathname)
  }

  useEffect(() => {
    setValue(location.pathname)
  }, [location.pathname, setValue])

  return (
    <main>
      <div tw="mb-7 text-center">
        <h1>Amazon URL Builder</h1>
        <p>
          Create links for your products that boost your Amazon listing on search engines like
          Google.
        </p>
      </div>
      <div tw="bg-white px-7 py-4">
        <Tabs
          value={tabIndex}
          aria-label="Amazon URL Builder Functionalities"
          scrollButtons="auto"
          variant="scrollable"
        >
          {ROUTES.map((route) => (
            <Tab
              key={route.path}
              label={route.label}
              LinkComponent={MuiLink}
              href={route.path}
              onClick={handleClick}
            />
          ))}
        </Tabs>
        <div tw="py-6">
          <FormProvider {...methods}>
            <Routes>
              <Route path="/" element={ROUTES[0].element} />
              {ROUTES.map((route) => {
                const path = route.path.replace('/free-tools', '')
                return <Route key={`route-${route.path}`} path={path} element={route.element} />
              })}
            </Routes>
          </FormProvider>
        </div>
        <hr tw="mb-6 h-px border-0 bg-monochrome-400" />
        <div tw="flex flex-wrap items-center justify-end gap-4 sm:flex-nowrap">
          <div tw="w-full">
            <Card
              elevation={0}
              tw="flex-1 border-2 border-monochrome-300 p-2 text-base !text-monochrome-600"
              data-testid="amazon-url-builder-generated-url"
            >
              {url[value] || "Your URL will show here once it's generated"}
            </Card>
          </div>
          <div>
            <Button
              startIcon={<FontAwesomeIcon icon={solid('copy')} />}
              disabled={!url[value]}
              onClick={() => copyUrlToClipboard(url[value])}
            >
              Copy
            </Button>
          </div>
          <div>
            <Button
              startIcon={<FontAwesomeIcon icon={solid('arrow-up-right-from-square')} />}
              disabled={!url[value]}
              onClick={() => navigateToUrl(url[value])}
            >
              View
            </Button>
          </div>
        </div>
      </div>
    </main>
  )
}
