import { useEffect } from 'react'

export const usePopState = (onPopState: (event: PopStateEvent) => void) => {
  useEffect(() => {
    window.addEventListener('popstate', onPopState)
    return () => {
      window.removeEventListener('popstate', onPopState)
    }
  }, [onPopState])
}
