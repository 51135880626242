import { useEffect } from 'react'

import { Chip } from '../../components/chip/chip'
import { useBreakpoint } from '../hooks/useBreakpoint'
import { getCurrentBreakpoint } from '../utils/getCurrentBreakpoint'

function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
  return o[propertyName]
}

const breakpointsTitle = {
  mobile: '',
  sm: 'Small',
  md: 'Medium',
  lg: 'Large',
  xl: 'Extra Large',
  '2xl': 'Extra Extra Large',
}

export const ResponsiveDebugger = () => {
  const isSmall = useBreakpoint('sm')
  const isMedium = useBreakpoint('md')
  const isLarge = useBreakpoint('lg')
  const isExtraLarge = useBreakpoint('xl')
  const isExtraExtraLarge = useBreakpoint('2xl')
  const currentBreakpoint = getCurrentBreakpoint() as any
  const breakpointText = getProperty(breakpointsTitle, currentBreakpoint)

  const enabledBreakpoints = [
    isSmall && 'sm',
    isMedium && 'md',
    isLarge && 'lg',
    isExtraLarge && 'xl',
    isExtraExtraLarge && '2xl',
  ].filter(Boolean)

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('enabled breakpoints', enabledBreakpoints)
  }, [enabledBreakpoints])

  return (
    <div tw="fixed bottom-2 right-2">
      {breakpointText && (
        <Chip
          label={breakpointText}
          color="primary"
          tw="sm:bg-accent-four-800! md:bg-accent-five-800! lg:(bg-monochrome-800 text-white)! xl:bg-accent-three-800! 2xl:bg-secondary-800!"
        />
      )}
    </div>
  )
}
