import { Services } from '@brand-console/types'
import {
  CustomerEngagementIcon,
  DataScienceIcon,
  FeedMarketingIcon,
  FulfillmentIcon,
  GrowthMarketingIcon,
  MarketplaceManagementIcon,
  MarketplaceServicesIcon,
  StorefrontIcon,
  UnifiedAnalyticsIcon,
} from '@cart/ui'
import React from 'react'
import slugster from 'slugster'

import { CardType, PageURL } from './Recommendations.types'

export const cardContent: CardType[] = [
  {
    title: Services.STOREFRONT,
    description: 'Build your online store with the best tools and services.',
    image: <StorefrontIcon />,
    action: 'Add',
    page: `/${slugster(Services.STOREFRONT)}` as PageURL,
  },
  {
    title: Services.GROWTH_MARKETING,
    description: 'Find and win more customers with data-driven marketing services and automation.',
    image: <GrowthMarketingIcon />,
    action: 'Request',
    page: `/${slugster(Services.GROWTH_MARKETING)}` as PageURL,
  },
  {
    title: Services.CUSTOMER_ENGAGEMENT,
    description: 'Cultivate lifetime fans by giving your customers top-tier multichannel support.',
    image: <CustomerEngagementIcon />,
    action: 'Request',
    page: `/${slugster(Services.CUSTOMER_ENGAGEMENT)}` as PageURL,
  },
  {
    title: Services.FULFILLMENT,
    description: 'Ship products faster with our nationwide fulfillment network.',
    image: <FulfillmentIcon />,
    action: 'Request',
    page: `/${slugster(Services.FULFILLMENT)}` as PageURL,
  },
  {
    title: Services.UNIFIED_ANALYTICS,
    description:
      'Experience end-to-end analytics across your shopper journey as you attract and fulfill buyer demand.',
    image: <UnifiedAnalyticsIcon />,
    action: 'Request',
    page: `/${slugster(Services.UNIFIED_ANALYTICS)}` as PageURL,
  },
  {
    title: Services.MARKETPLACE_SERVICES,
    description: 'Get product visibility and conversions in the world’s most popular marketplaces',
    image: <MarketplaceServicesIcon />,
    action: 'Request',
    page: `/${slugster(Services.MARKETPLACE_SERVICES)}` as PageURL,
  },
  {
    title: Services.DATA_SCIENCE_SOLUTIONS,
    description:
      'Leverage machine learning and automation to acquire, convert, and fulfill shopper demand at scale.',
    image: <DataScienceIcon />,
    action: 'Request',
    page: `/${slugster(Services.DATA_SCIENCE_SOLUTIONS)}` as PageURL,
  },
  {
    title: Services.MARKETPLACE_MANAGEMENT,
    description:
      'Simultaneously build and manage marketplace listings for Amazon, Walmart, eBay and more - All from one centralized location.',
    image: <MarketplaceManagementIcon />,
    action: 'Request',
    page: `/${slugster(Services.MARKETPLACE_MANAGEMENT)}` as PageURL,
  },
  {
    title: Services.FEED_MARKETING,
    description: `Boost your online presence with automated ${Services.FEED_MARKETING.toLowerCase()} across 2000+ advertising, social media, and shopping channels worldwide.`,
    image: <FeedMarketingIcon />,
    action: 'Request',
    page: `/${slugster(Services.FEED_MARKETING)}` as PageURL,
  },
]
