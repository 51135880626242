import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import tw, { css, styled } from 'twin.macro'

import { DropdownProps } from './dropdown.types'

export const StyledDropdown = styled.div((props: DropdownProps) => [
  tw`block w-full text-left`,
  props['data-readonly'] && tw`pointer-events-none`,
])

export const StyledLabel = styled.div((props: DropdownProps) => [
  tw`mb-1 text-xs`,
  props['data-haserror'] && tw`text-accent-two-700`,
  props['data-hasfocus'] && tw`text-primary-700`,
])

export const StyledHelperText = styled.div((props: DropdownProps | any) => [
  tw`mt-1 text-xs`,
  props['data-haserror'] && tw`text-accent-two-700`,
])

export const StyledDropdownButton = styled.button((props: DropdownProps) => [
  tw`inline-flex w-full items-center justify-between rounded-md border-2 border-monochrome-500 border-solid bg-white px-4 py-2 font-medium text-sm text-monochrome-600 focus:outline-none`,
  props['data-hasfocus'] && tw`border-primary-700 text-monochrome-900`,
  props['data-hasselection'] && tw`text-monochrome-900`,
  props['data-haserror'] && tw`border-accent-two-700 focus:border-accent-two-700`,
  props.disabled && tw`bg-monochrome-50`,
  css`
    height: 38px;
    > div {
      ${tw`overflow-hidden overflow-ellipsis whitespace-nowrap text-monochrome-900`}
      ${props.disabled && tw`text-monochrome-600`}
    }
  `,
])

export const StyledCaretDown = styled(FontAwesomeIcon)`
  ${tw`ml-3 -mr-1 text-monochrome-900`}
`

export const StyledDropdownOptions = styled.div<DropdownProps>(({ shouldShow }: DropdownProps) => [
  // Top is temporary to not add white space to page and is meant to get overwritten by handleDropdownDisplay
  tw`invisible absolute mb-4 mt-1 max-h-60 overflow-auto whitespace-nowrap rounded-md bg-white shadow-lg ring-monochrome-900 ring-opacity-5 z-[99999] focus:outline-none`,
  shouldShow && tw`visible`,
  css`
    & > ul {
      ${tw`mb-0 pl-0`}
    }
  `,
])

export const StyledDropdownItem = styled.li(() => [
  tw`m-1 block rounded px-4 py-2 text-monochrome-700 hocus:bg-monochrome-50`,
])

export const StyledSelectElement = styled.select(() => tw`sr-only`)

export const StyledClearButton = styled.button((props: DropdownProps) => [
  tw`mb-2 py-2 pl-4 font-normal text-monochrome-600 text-sm`,
  props['data-hasselection'] && tw`text-primary-700`,
])
