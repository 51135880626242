import { StateCreator } from 'zustand'

import { type SideMenuTenant } from '../../gql/graphql'
import { resetters } from './storeResetter'
import { type StoreStateType } from './unified-sidebar.store'

export interface ITenantState {
  currentTenant?: SideMenuTenant
  currentTenantCode?: string
  tenantsList?: SideMenuTenant[]
  setCurrentTenant: (currentTenant: SideMenuTenant) => void
  setCurrentTenantCode: (currentTenantCode: string) => void
  setTenantsList: (tenantsList: SideMenuTenant[]) => void
}

const defaultTenantState = {
  currentTenant: undefined,
  currentTenantCode: '',
  tenantsList: [],
}

export const createTenantSlice: StateCreator<
  StoreStateType,
  [['zustand/devtools', never]],
  [],
  ITenantState
> = (set) => {
  resetters.push(() => set(defaultTenantState, false, 'resetTenantState'))
  return {
    ...defaultTenantState,
    setCurrentTenant: (currentTenant) => set({ currentTenant }, false, 'setCurrentTenant'),
    setCurrentTenantCode: (currentTenantCode) =>
      set({ currentTenantCode }, false, 'setCurrentTenantCode'),
    setTenantsList: (tenantsList) => {
      const $tenantsList = JSON.stringify(tenantsList)
      const sortedTenants = JSON.parse($tenantsList)
      sortedTenants.sort((a, b) =>
        a.name?.trim().toLowerCase().localeCompare(b.name?.trim().toLowerCase()),
      )
      return set({ tenantsList: sortedTenants }, false, 'setTenantsList')
    },
  }
}
