import { IconName } from '@fortawesome/fontawesome-svg-core'
import Tooltip from '@mui/material/Tooltip'
import { FC } from 'react'
import tw from 'twin.macro'

import { getIcon } from './Icon.partials'

export interface IconProps {
  icon: IconName
  showTooltip: boolean
  title: string
  hidden?: boolean
}
export const Icon: FC<IconProps> = ({ hidden, icon, showTooltip, title }) => (
  <span css={[hidden && tw`opacity-0`, tw`w-5`]}>
    {showTooltip ? (
      <Tooltip
        title={title}
        placement="right"
        componentsProps={{ tooltip: { style: tw`bg-monochrome-900` } }}
      >
        <div>{getIcon(icon)}</div>
      </Tooltip>
    ) : (
      getIcon(icon)
    )}
  </span>
)
