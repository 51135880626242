import { ApolloError } from '@apollo/client'
import { MeDocument, useUserUpdateMutation } from '@brand-console/generated-graphql-hooks'
import { useCartAuth } from '@cartdotcom/auth'
import { UseUpdateUserProps, updateUserDetailsProps } from './useUpdateUser.types'

export const useUpdateUser = ({ log }: UseUpdateUserProps) => {
  const { setUser, userId, email } = useCartAuth()

  const [updateUser, { loading }] = useUserUpdateMutation({
    refetchQueries: [MeDocument],
  })

  const updateUserDetails = async ({
    onCompleted,
    variables,
    ...props
  }: updateUserDetailsProps) => {

    await updateUser({
      variables: {
        updateUserInput: {
          id: userId,
          ...variables
        },
      },
      onCompleted: (userUpdateData, options) => {
        const { org_userUpdate: data } = userUpdateData

        setUser({
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.userData.phone,
          email: variables.primaryEmail || email,
        })
        onCompleted?.(userUpdateData, options)
      },
      ...props,
    })
  }

  return [updateUserDetails, loading] as const
}
