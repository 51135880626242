import { Box, Divider, Skeleton } from '@mui/material'
import { DataGridProProps } from '@mui/x-data-grid-pro'
import React from 'react'

import { StyledDataGrid, StyledLevelTwoHeader } from './SettingsOrganization.styles'
import { useCurrentContext } from '@cartdotcom/auth'

export const OrganizationHeader = () => {
  const { currentOrganization } = useCurrentContext()
  return (
    <div>
      <h1 tw="mb-2 text-[24px]">Organization</h1>
      <div tw="mb-7 flex w-fit gap-4">
        <div>
          <strong tw="whitespace-nowrap">Organization ID</strong> {currentOrganization.id}
        </div>
      </div>
    </div>
  )
}

export const SettingsOrganizationCardHeader = ({ text }) => (
  <StyledLevelTwoHeader tw="mx-7 my-4">{text}</StyledLevelTwoHeader>
)

const TableLoadingSkeleton = ({
  height = 70,
  rows = 1,
  divider = true,
  key = 'unique',
}: {
  height?: number
  rows?: number
  divider?: boolean
  key?: string
}) => (
  <Box tw="px-4">
    {[...Array(rows)].map((string, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={`${index}${key}`}>
        <Skeleton animation="wave" variant="rounded" height={height} tw="my-2" />
        {divider && <Divider />}
      </div>
    ))}
  </Box>
)

export const WrappedDataGrid = (props: DataGridProProps) => {
  const { rows, loading } = props
  return (
    <StyledDataGrid
      autoHeight
      hideFooter={rows.length < 25}
      pagination={rows.length > 25}
      initialState={{
        pagination: { pageSize: 25 },
      }}
      loading={loading}
      components={{
        LoadingOverlay: () => TableLoadingSkeleton({ height: 50 }),
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}
