import { Auth0Provider } from '@auth0/auth0-react'
import { SentryErrorBoundary } from '@brand-console/utilities'
import { getTopLevelDomain } from '@brand-console/utilities/getTopLevelDomain'
import { ThemeProvider } from '@cart/ui/theme'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { bool, oneOf, string } from 'prop-types'
import React, { useEffect } from 'react'
import * as ReactDOM from 'react-dom/client'
import reactToWebComponent from 'react-to-webcomponent'
import { createGlobalStyle } from 'styled-components'

import { SideMenuNode } from '../gql/graphql'
import { UnifiedSidebar as UnifiedSidebarComponent } from './components/unified-sidebar/UnifiedSidebar'
import { initEmbeddableSidebarLogger, logError } from './logger/logger'
import { useUnifiedSidebarStore } from './store/unified-sidebar.store'

const CustomStyles = createGlobalStyle`
  html {
    font-size: 16px;
  }
  html, body {
    margin: 0;
    padding: 0;
    border: none;
  }
  // Hide the Zendesk Launcher
  #launcher {
    display: none;
  }
  // Stops widget from overflowing the container
  #webWidget {
    max-width: 100%;
  }
  [data-cartid="layout-container"] {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
    flex-direction: column;
  }
  [data-id="cart-unified-sidebar"] * {
    box-sizing: border-box;
  }
  [data-cartid="app-container"] {
    width: 100%;
    padding: 1rem;
    z-index: 0;
    overflow-x: hidden;
  }
  @media (min-width: 640px) {
    [data-cartid="layout-container"] {
      flex-wrap: nowrap;
      flex-direction: row;
    }
  }
`

const tuple = <T extends string[]>(...args: T) => args
const PRODUCTS = tuple('bc', 'dfw', 'jazz', 'mcm', 'rpt')

const queryClient = new QueryClient()

interface IUnifiedSidebarProps {
  'auth-method'?: string
  'bc-url': string
  'business-id'?: string
  'dfw-url': string
  'jazz-url': string
  'logout-url'?: string
  'mcm-url': string
  'search-url'?: string
  'tenant-code'?: string
  'uns-url': string
  'user-menu-items'?: string
  product: (typeof PRODUCTS)[number]
  spa?: boolean
}

const Wrapper = (props) => {
  const { children, product } = props
  if (product === 'bc') {
    return <div>{children}</div>
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Auth0Provider {...props} />
}

export const UnifiedSidebar: React.FC<IUnifiedSidebarProps> = ({
  product,
  spa = false,
  'auth-method': authMethod = 'auth0',
  'bc-url': bcUrl,
  'business-id': businessId,
  'dfw-url': dfwUrl,
  'jazz-url': jazzUrl,
  'mcm-url': mcmUrl,
  'tenant-code': tenantCode = '',
  'uns-url': unsUrl,
  'user-menu-items': userMenuItems,
  'logout-url': logoutUrl,
  'search-url': searchUrl,
}) => {
  const logger = initEmbeddableSidebarLogger()
  const urlParams = new URLSearchParams(window.location.search)
  const tenantCodeFromUrl = urlParams.get('tenantCode')
  const businessIdFromUrl = urlParams.get('businessId')

  const {
    setCurrentBusinessId,
    setCurrentTenantCode,
    setLogoutUrl,
    setProduct,
    setSearchUrl,
    setUserMenuItems,
  } = useUnifiedSidebarStore()

  const domain = process.env.NX_AUTH0_DOMAIN
  const clientId = process.env.NX_AUTH0_CLIENT_ID
  const audience = process.env.NX_AUTH0_AUDIENCE

  useEffect(() => {
    setCurrentBusinessId(businessIdFromUrl || businessId)
    setCurrentTenantCode(tenantCodeFromUrl || tenantCode)
    setLogoutUrl(logoutUrl)
    setProduct(product)
    setSearchUrl(searchUrl)
    if (userMenuItems) {
      try {
        const parsedUserMenuItems = JSON.parse(userMenuItems) as SideMenuNode[]
        setUserMenuItems(parsedUserMenuItems)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Unified Sidebar: could not parse the provided menu items', error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId, businessIdFromUrl, product, tenantCode, tenantCodeFromUrl, userMenuItems])

  if (!tenantCode && !businessId) {
    logError('No tenant code or business id provided')
    return null
  }

  return (
    <SentryErrorBoundary hub={logger.hub}>
      <Wrapper
        domain={domain}
        clientId={clientId}
        authorizationParams={{
          audience,
        }}
        cacheLocation="localstorage"
        product={product}
        cookieDomain={`.${getTopLevelDomain(window.location.hostname)}`}
      >
        <QueryClientProvider client={queryClient}>
          <ThemeProvider includeCSSDefaults={false}>
            <CustomStyles />
            <UnifiedSidebarComponent
              dfwUrl={dfwUrl}
              jazzUrl={jazzUrl}
              mcmUrl={mcmUrl}
              bcUrl={bcUrl}
              spa={Boolean(spa)}
              unsUrl={unsUrl}
              authMethod={authMethod}
            />
          </ThemeProvider>
        </QueryClientProvider>
      </Wrapper>
    </SentryErrorBoundary>
  )
}

UnifiedSidebar.propTypes = {
  'auth-method': string,
  'bc-url': string,
  'business-id': string,
  'dfw-url': string,
  'jazz-url': string,
  'logout-url': string,
  'mcm-url': string,
  'search-url': string,
  'tenant-code': string,
  'uns-url': string,
  'user-menu-items': string,
  product: oneOf(PRODUCTS),
  spa: bool,
}

const CartUnifiedSidebar = reactToWebComponent(UnifiedSidebar, React, ReactDOM)

export { UnifiedSidebarComponent }

customElements.define('cart-unified-sidebar', CartUnifiedSidebar)
