import { DeprecatedButton, DeprecatedChip, Dropdown, DropdownItem, useBreakpoint } from '@cart/ui'
import React, { useMemo, useState } from 'react'
import tw from 'twin.macro'

import { SearchInput } from '../../components/atoms/SearchInput/SearchInput'

export const MyConnectorsFilter = ({ data, setValue, values }) => {
  const [mobileFilter, openMobileFilter] = useState(false)
  const isMedium = useBreakpoint('md')
  const business = useMemo(
    () =>
      values.business.map((value) => {
        return {
          value,
          title: data.business.find((item) => item.value === value).title,
        }
      }),
    [values.business, data.business],
  )
  const status = useMemo(
    () =>
      values.status.map((value) => {
        return {
          value,
          title: data.status.find((item) => item.value === value).title,
        }
      }),
    [values.status, data.status],
  )
  const category = useMemo(
    () =>
      values.category.map((value) => {
        return {
          value,
          title: data.category.find((item) => item.value === value).title,
        }
      }),
    [values.category, data.category],
  )

  const dropdownItems = useMemo(
    () => [
      {
        id: 'business',
        actions: data.business,
        placeholder: 'Business',
      },
      {
        id: 'status',
        actions: data.status,
        placeholder: 'Status',
      },

      {
        id: 'category',
        actions: data.category,
        placeholder: 'Category',
      },
    ],
    [data],
  )
  return (
    <>
      <div
        css={[
          tw`mb-4 grid items-end gap-4 md:(grid-rows-1 grid-cols-[1fr 2fr])`,
          mobileFilter ? tw`grid-rows-[1fr 3fr]` : tw`grid-rows-1`,
        ]}
      >
        <SearchInput
          onChange={(value: string) => setValue('search', value)}
          placeholder="Search by name..."
          id="search"
          filter
          openMobileFilter={openMobileFilter}
          mobileFilter={mobileFilter}
          value={values.search}
          actionsActive={[...business, ...category, ...status]}
        />
        {(isMedium || mobileFilter) && (
          <div tw="grid grid-rows-3 gap-4 md:(grid-cols-3 grid-rows-1)">
            {dropdownItems.map((dropdown) => (
              <Dropdown
                key={dropdown.id}
                id={dropdown.id}
                placeholder={dropdown.placeholder}
                multipleSelection
                values={
                  values[dropdown.id].length > 0
                    ? values[dropdown.id].map((value) => {
                        return {
                          value,
                          text: dropdown.actions.find((item) => item.value === value).title,
                          selected: true,
                        }
                      })
                    : []
                }
              >
                {dropdown.actions?.map((item) => (
                  <DropdownItem
                    data-testid="select-option"
                    key={item.value}
                    text={item.title}
                    value={item.value}
                  />
                ))}
              </Dropdown>
            ))}
          </div>
        )}
      </div>
      <div tw="mb-4 flex flex-wrap items-center">
        {business.length > 0 && (
          <div tw="mr-2 mb-2 flex">
            {business.map(({ value, title }) => (
              <DeprecatedChip
                text="Business is"
                emphasisText={title}
                key={`Business${title}`}
                onClose={() =>
                  setValue(
                    'business',
                    values.business.filter((item) => item !== value),
                  )
                }
                className="mr-2"
              />
            ))}
          </div>
        )}
        {status.length > 0 && (
          <div tw="mr-2 mb-2 flex">
            {status.map(({ title, value }) => (
              <DeprecatedChip
                text="Status is"
                emphasisText={title}
                key={`Status${title}`}
                className="mr-2"
                onClose={() =>
                  setValue(
                    'status',
                    values.status.filter((item) => item !== value),
                  )
                }
              />
            ))}
          </div>
        )}
        {category.length > 0 && (
          <div tw="mr-2 mb-2 flex">
            {category.map(({ title, value }) => (
              <DeprecatedChip
                text="Category is"
                emphasisText={title}
                key={`Category${title}`}
                className="mr-2"
                onClose={() =>
                  setValue(
                    'category',
                    values.category.filter((item) => item !== value),
                  )
                }
              />
            ))}
          </div>
        )}
        {(values.status.length > 0 || values.category.length > 0 || values.business.length > 0) && (
          <DeprecatedButton
            variant="tertiary"
            text="Clear filters"
            onClick={() => {
              setValue('business', [])
              setValue('status', [])
              setValue('category', [])
              setValue('search', '')
            }}
            size="small"
          />
        )}
      </div>
    </>
  )
}
