import tw, { css, styled } from 'twin.macro'

export const StyledUserInfoMenu = styled('div')(() => {
  const nodeName = 'user-info-menu'
  return [
    tw`relative`,
    css`
      .${nodeName}-enter {
        opacity: 0;
      }
      .${nodeName}-enter-done {
        opacity: 1;
      }
      .${nodeName}-enter-active {
        opacity: 1;
        transition: opacity 200ms;
      }
      .${nodeName}-exit {
        opacity: 1;
      }
      .${nodeName}-exit-active {
        opacity: 0;
        transition: opacity 200ms;
      }
    `,
  ]
})
