import { ResponsiveBar } from '@nivo/bar'
import { ValueFormat } from '@nivo/core'
import { DatumValue, ResponsiveLine } from '@nivo/line'
import { ResponsivePie } from '@nivo/pie'
import React from 'react'

import { useBreakpoint } from '../../framework/hooks/useBreakpoint'
import { chartsBackgroundColorsScheme } from './chart.constants'
import { ChartContainer } from './chart.styles'
import { IChart } from './chart.types'

export const Chart = ({ aspectRatio, datasets, type, valueFormat }: IChart) => {
  const parsedData: any = []
  const isDoughnutOrPie = ['doughnut', 'pie'].includes(type)
  let chartAspectRatio
  const isSmall = useBreakpoint('sm')

  switch (type) {
    case 'doughnut': {
      chartAspectRatio = aspectRatio || '1/1'
      break
    }
    default: {
      chartAspectRatio = aspectRatio || '16/9'
      if (!aspectRatio && !isSmall) {
        chartAspectRatio = '4/3'
      }
      break
    }
  }

  if (isDoughnutOrPie) {
    datasets.map((datasetItem) => {
      return datasetItem.data.map((dataItem) => {
        return parsedData.push({
          id: dataItem.label,
          label: dataItem.label,
          value: dataItem.value,
        })
      })
    })
  }

  if (type === 'vertical-bar') {
    datasets.map((datasetItem) => {
      return datasetItem.data.map((dataItem) => {
        return parsedData.push({
          label: dataItem.label,
          value: dataItem.value,
        })
      })
    })
  }

  if (type === 'line') {
    datasets.map((datasetItem) => {
      return parsedData.push({
        id: datasetItem.id,
        ...(datasetItem.color ? { color: datasetItem.color } : {}),
        data: datasetItem.data.map((dataItem) => {
          return {
            x: dataItem.label,
            y: dataItem.value,
          }
        }),
      })
    })
  }

  return (
    <ChartContainer data-ratio={chartAspectRatio}>
      {type === 'line' && (
        <ResponsiveLine
          data={parsedData}
          animate
          margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
          pointLabelYOffset={-12}
          enableSlices="x"
          pointSize={4}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          axisBottom={{
            tickRotation: isSmall ? 0 : 90,
          }}
          colors={
            parsedData.find((x: any) => !!x.color)
              ? parsedData.map((item: any) => item.color)
              : chartsBackgroundColorsScheme
          }
          yFormat={valueFormat as ValueFormat<DatumValue, void>}
        />
      )}
      {type === 'vertical-bar' && (
        <ResponsiveBar
          colors={({ index }) => chartsBackgroundColorsScheme[index]}
          data={parsedData}
          indexBy="label"
          keys={['value']}
          margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
          tooltipLabel={({ indexValue }) => `${indexValue}`}
          axisBottom={{
            tickRotation: isSmall ? 0 : 90,
          }}
        />
      )}
      {isDoughnutOrPie && (
        <ResponsivePie
          data={parsedData}
          colors={chartsBackgroundColorsScheme}
          innerRadius={type === 'doughnut' ? 0.75 : 0}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          motionConfig="default"
          valueFormat={valueFormat}
        />
      )}
    </ChartContainer>
  )
}
