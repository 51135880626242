import React, { ReactNode } from 'react'

import { ResourcesTabs } from './components/ResourcesTabs'
import { ToolsProvider } from './merchant-tools.store'

export interface DialogTitleProps {
  id: string
  children?: ReactNode
  onClose: () => void
}

export const MerchantTools = () => {
  return (
    <ToolsProvider>
      <ResourcesTabs />
    </ToolsProvider>
  )
}
