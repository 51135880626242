import tw from 'twin.macro'

export const transitionProps = {
  enter: tw`transition-opacity duration-1000 ease-in-out`,
  enterFrom: tw`opacity-0`,
  enterTo: tw`opacity-100`,
  leave: tw`transition-opacity duration-1000 ease-in-out`,
  leaveFrom: tw`opacity-100`,
  leaveTo: tw`opacity-0`,
}
