import tw, { styled } from 'twin.macro'

import { CardProps } from './deprecated-card.types'

export const StyledCard = styled.section(() => [
  tw`flex w-full max-w-full flex-col flex-wrap overflow-hidden rounded-md bg-white text-left shadow-lg`,
])

export const StyledCardHeaderContainer = styled.div(() => [
  tw`mb-0 w-full border-monochrome-500 border-b p-4 md:(px-8 py-5)`,
])

export const StyledCardHeader = styled.header(({ noPadding }: CardProps) => [
  tw`w-full text-left font-bold text-lg leading-none`,
  noPadding && tw`p-0`,
])

export const StyledCardSubHeader = styled.p(({ noPadding }: CardProps) => [
  tw`mt-2 mb-0 w-full text-left leading-none`,
  noPadding && tw`p-0`,
])

export const StyledCardBody = styled.div((props: CardProps) => [
  tw`grid h-full w-full flex-grow p-4 text-left font-light md:(px-8 py-0)`,
  props['data-nopadding'] && tw`p-0 md:p-0`,
  (props['data-footer'] || props['data-title']) && tw`h-auto`,
])

export const StyledCardFooter = styled.footer(({ noPadding }: CardProps) => [
  tw`w-full border-monochrome-500 border-t px-4 py-7 text-sm`,
  tw`md:(px-8 pt-6 pb-7)`,
  noPadding && tw`p-0`,
])
