import { Input } from '@cart/ui'
import React from 'react'

import { requiredRule } from '../helpers'
import { Form } from './Form'

const Description = () => (
  <>
    Create an Amazon search results page filtered by Seller Id and keyword.
    <br />
    Seller Id must match exactly.
  </>
)
export const TwoStepViaSeller = () => {
  const generateUrl = (data) => {
    return `https://www.amazon.com/s/?url=search-alias%3Daps&field-keywords=${data.keywords}&me=${data.sellerId}`
  }

  return (
    <Form
      title="2 Step via Seller"
      description={<Description />}
      example="Example:  https://www.amazon.com/s/ref=nb_sb_noss_2?url=search-alias%3Daps&field-keywords=Toothbrush&me=AJDT2HML7HWGL"
      handleGenerateLink={generateUrl}
    >
      <Input
        id="keywords"
        label="Keywords"
        placeholder="Keywords"
        helperText="Required"
        rules={{ ...requiredRule }}
      />
      <Input
        id="sellerId"
        label="Seller Id"
        placeholder="Seller Id"
        helperText="Required"
        rules={{ ...requiredRule }}
      />
    </Form>
  )
}
