import { useReactiveVar } from '@apollo/client'
import {
  activeBizVar,
  activeOrgVar,
  useCreateOrganizationMutation,
} from '@brand-console/generated-graphql-hooks'
import { ActiveOrganization } from '@brand-console/types'
import {
  localStorageHelper,
  LocalStorageKeys,
  logger,
  RegEx,
  removeTrailingSlashes,
} from '@brand-console/utilities'
import {
  Button,
  CartLogo,
  DeprecatedAlert,
  Footer,
  Input,
  Select,
  SelectItem,
  useAppContext,
} from '@cart/ui'
import { useLogout } from '@cartdotcom/auth/useLogout'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Navigate } from 'react-router-dom'

export const CreateOrganization = (): React.ReactElement => {
  const log = logger.setLogger(logger.LoggerNames.ORGANIZATION)
  const { auth0Token } = useAppContext()
  const activeOrg = useReactiveVar(activeOrgVar)
  const { onLogout } = useLogout()
  const [orgCreated, setOrgCreated] = useState<ActiveOrganization>()

  const [showFailureAlert, setShowFailureAlert] = useState(false)
  const [failureMessage, setFailureMessage] = useState('')

  const methods = useForm()
  const { handleSubmit } = methods

  const [createOrganization, { reset }] = useCreateOrganizationMutation()

  const handleFailureAlertClose = () => {
    setShowFailureAlert(false)
  }

  useEffect(() => {
    if (orgCreated) {
      activeOrgVar(orgCreated)
      activeBizVar(orgCreated.businesses[0])
    }
  }, [orgCreated])

  if ((activeOrg && activeBizVar) || !auth0Token) {
    return <Navigate to="/" />
  }

  const onSubmit = (data) => {
    createOrganization({
      variables: {
        createOrganizationInput: {
          name: data['organization-name'],
          website: data['organization-url']
            ? removeTrailingSlashes(data['organization-url'].replace(/(^\w+:|^)\/\//, ''))
            : undefined,
          revenue: data.revenue,
          salesChannel: data['sales-channel'],
          businessName: data['organization-name'],
        },
      },
      onError(error) {
        log.error('Organization creation error', error)
        setFailureMessage(error.message || 'An error occurred. Please try again later.')
        setShowFailureAlert(true)
        reset()
      },
      onCompleted(returnData) {
        const { id, name, businessEntityTerminology, businesses, createdAt } =
          returnData.org_organizationCreate
        const org = {
          businessEntityTerminology,
          businesses,
          createdAt,
          id,
          name,
        }
        localStorageHelper.set(LocalStorageKeys.ORGANIZATION, org)
        localStorageHelper.set(LocalStorageKeys.BUSINESS, businesses[0])
        setOrgCreated(org)
      },
    })
  }

  const signOut = () => {
    onLogout({ returnUrl: window.location.origin })
    return null
  }

  return (
    <>
      <header tw="relative flex justify-between bg-white p-4 shadow-lg">
        <CartLogo tw="w-20 [height:38px]" />
        <Button variant="outlined" onClick={signOut}>
          Sign Out
        </Button>
      </header>
      <div tw="flex flex-wrap items-center justify-center bg-white py-14">
        <div tw="w-full max-w-xl px-4 text-left">
          {showFailureAlert && (
            <DeprecatedAlert
              type="error"
              tw="my-9 w-full"
              text={failureMessage}
              onClose={handleFailureAlertClose}
            />
          )}
          <h1>Organization</h1>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FormProvider {...methods}>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Input
                tw="mt-9"
                id="organization-name"
                name="organization-name"
                label="Organization Name"
                placeholder="Organization Name"
                required
              />
              <Input
                tw="mt-7"
                id="organization-url"
                name="organization-url"
                label="Organization Website"
                placeholder="e.g. example.com"
                rules={{
                  pattern: {
                    value: RegEx.VALID_DOMAIN_WITH_OR_WITHOUT_PROTOCOL,
                    message:
                      'Please use a valid website. (e.g. example.com, www.example.com, https://example.com/)',
                  },
                }}
              />
              <Select tw="mt-7" label="What is your current revenue?" id="revenue" required>
                <SelectItem value="$0 - $200,000">$0 - $200,000</SelectItem>
                <SelectItem value="$200,000 - $400,000">$200,000 - $400,000</SelectItem>
                <SelectItem value="$400,000 - $1,000,000">$400,000 - $1,000,000</SelectItem>
                <SelectItem value="$1,000,000 - $5,000,000">$1,000,000 - $5,000,000</SelectItem>
                <SelectItem value="$5,000,000 - $10,000,000">$5,000,000 - $10,000,000</SelectItem>
                <SelectItem value="> $10,000,000">&gt; $10,000,000</SelectItem>
              </Select>
              <Select
                tw="mt-7"
                label="Where are your products currently sold?"
                id="sales-channel"
                required
              >
                <SelectItem value="online">Online</SelectItem>
                <SelectItem value="in-store">In-store</SelectItem>
                <SelectItem value="both">Both</SelectItem>
              </Select>
              <p tw="mt-7 text-xs">* Indicate required fields</p>
              <Button tw="mt-7" type="submit" variant="contained">
                Create Organization
              </Button>
            </form>
          </FormProvider>
        </div>
      </div>
      <Footer />
    </>
  )
}
