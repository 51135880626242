import tw, { css, styled, theme } from 'twin.macro'

import { StyledLabelProps } from './range.types'

const rangeHandlerSharedStyles = `
  border: 4px solid ${theme('colors.primary.100')};
  height: 27px;
  width: 27px;
  border-radius: 50%;
  background: ${theme('colors.primary.700')};
  cursor: pointer;
`

const rangeTrackSharedStyles = `
  width: 100%;
  height: 20px;
  cursor: pointer;
  background: ${theme('colors.primary.200')};
  border-radius: 10px;
`

export const StyledRange = styled.input(() => [
  tw`w-full`,
  css`
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
    }
    &:focus {
      outline: none;
    }
    &:::-ms-track {
      width: 100%;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      color: transparent;
    }
  `,
  // Range Handler
  css`
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      margin-top: -4px;
      ${rangeHandlerSharedStyles}
    }
    &::-moz-range-thumb {
      ${rangeHandlerSharedStyles}
    }
    &::-ms-thumb {
      ${rangeHandlerSharedStyles}
    }
  `,
  // Range Track
  css`
    &::-webkit-slider-runnable-track {
      ${rangeTrackSharedStyles}
    }
    &::-moz-range-track {
      ${rangeTrackSharedStyles}
    }
    &::-ms-track {
      ${rangeTrackSharedStyles}
      border-color: transparent;
      color: transparent;
    }
  `,
])

export const StyledLabel = styled.span<StyledLabelProps>((props) => [
  css`
    left: ${props['data-position']}px;
  `,
  tw`absolute -top-11 whitespace-nowrap text-lg tracking-tight`,
])
