
import { useBusinessListByOrgIdQuery } from '@brand-console/generated-graphql-hooks'
import { logger } from '@brand-console/utilities'
import React, { ReactElement, useEffect } from 'react'

import { WrappedDataGrid } from '../SettingsOrganization.partials'
import { useSettingsOrganizationsContext } from '../SettingsOrganizations.store'
import { CreatedOnCell, NameCell } from './BusinessManagement.partials'
import { useCurrentContext } from '@cartdotcom/auth'

export const BusinessManagementListAll = (): ReactElement => {
  const log = logger.setLogger(logger.LoggerNames.ORGANIZATION)
  const { setAlert } = useSettingsOrganizationsContext()
  const { currentOrganization } = useCurrentContext()

  const errorMessage = 'An error occurred while trying to retrieve the list of businesses.'

  const { loading, error, data } = useBusinessListByOrgIdQuery({
    variables: {
      organizationId: currentOrganization?.id,
    },
    skip: !currentOrganization
  })

  useEffect(() => {
    if (error) {
      log.error('BusinessManagementListAdmin GraphQL error', error)
      setAlert({ variant: 'error', text: errorMessage })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const businessListData = data ? data.org_businessListByOrgId.data : []

  return (
    <WrappedDataGrid
      loading={loading}
      columns={[
        {
          field: 'name',
          headerName: 'Name',
          flex: 2,
          minWidth: 250,
          renderCell: NameCell,
        },
        {
          field: 'id',
          headerName: 'ID',
          flex: 0.5,
          minWidth: 100,
        },
        {
          field: 'numUsers',
          headerName: 'Users',
          flex: 0.5,
          minWidth: 100,
        },
        {
          field: 'createdAt',
          headerName: 'Created On',
          flex: 1,
          minWidth: 150,
          valueFormatter: CreatedOnCell,
        },
      ]}
      rows={loading ? [] : businessListData}
    />
  )
}
