import { cartMuiTheme } from '@cart/ui'
import { PaletteColor } from '@mui/material/styles'
import { FC } from 'react'
import tw, { styled } from 'twin.macro'
import { PaletteColors } from '../../framework/ThemeProvider/ThemeProvider.types'
import { ButtonProps } from '../button/button.types'
import { Button } from '../button/button'

import { Card, CardProps } from '../card'

export const StyledCard = styled(Card)<CardProps>(({ variant, color }) => [
  tw`inline-flex content-center items-center text-center`,
  // Don't need outline, since button outline will be used
  variant === 'outlined' && color ? tw`!border-0` : '',
  tw`[& button]:(h-full p-0)`,
  tw`[& .MuiCardContent-root]:min-w-[calc(40px+16px*2)]`,
])
