import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import tw, { css, styled } from 'twin.macro'

import { Checkbox } from '../checkbox/checkbox'
import { Dropdown } from '../dropdown/dropdown'
import { TableProps } from './table.types'

export const StyledTableWrapper = styled.div(() => [
  tw`block w-full max-w-full rounded-lg sm:shadow`,
])

export const StyledTableContainer = styled.section(() => [tw`block w-full shadow-md`])

export const StyledTableContentSection = styled.div((props: any) => [
  tw`w-full rounded-lg sm:rounded-t-none mobile:shadow`,
  !props['data-haspagination'] &&
    props['data-mobiledisplay'] === 'collapsed' && [tw`overflow-x-hidden rounded-lg`],
])

export const StyledMoveableTableContainer = styled.div(() => [tw`w-full overflow-x-auto`])

export const StyledTableHeader = styled.header(() => [
  tw`mb-4 flex w-full items-center justify-between rounded-lg border-monochrome-50 bg-white p-2 py-3 pl-6 sm:(mb-0 rounded-b-none) mobile:shadow`,
  css`
    & > h2 {
      ${tw`m-0 flex h-11 w-full items-center font-heading text-xl leading-none sm:w-auto`}
    }
  `,
])

export const StyledTableHeaderActions = styled.div(() => [tw`mt-0 pr-4`])

export const StyledCheckbox = styled(Checkbox)`
  ${tw`-ml-4`}
`

export const StyledTable = styled.table((props: any) => [
  tw`grid w-full`,
  css`
    min-width: 100% !important;
    width: max-content;
  `,
  props['data-ismobile'] &&
    props['data-mobiledisplay'] === 'collapsed' &&
    css`
      & [data-sticky-td='true'] {
        box-shadow: 0px 0px 20px rgba(32, 33, 36, 0.1);
        clip-path: inset(0px -15px 0px 0px);
      }
    `,
])

export const StyledTableHead = styled.thead((props: any) => [
  tw`w-full border-monochrome-50 border-t bg-white [display:contents]`,
  props['data-mobiledisplay'] === 'transformed' && tw`hidden sm:block`,
])

export const StyledTableHeadContainer = styled.div(() => [tw`flex h-full select-none items-center`])

export const StyledTableBody = styled.tbody((props: any) => [
  tw`box-border w-full border-monochrome-50 border-t bg-white [display:contents]`,
  props['data-mobiledisplay'] !== 'collapsed' && [
    !props['data-haspagination'] && tw`overflow-x-hidden rounded-b-lg`,
    props['data-haserror'] && tw`overflow-x-hidden rounded-b-lg`,
  ],
  css`
    height: ${props['data-bodyheight']}px;
  `,
])

export const StyledTableHeaderRow = styled.tr(() => [
  tw`scale-100 border-monochrome-500 shadow-md sm:border-t-2`,
])

export const StyledTableHeaderCell = styled.th(
  () => [
    tw`whitespace-nowrap bg-white px-2 py-3 font-heading text-monochrome-600 [min-width:30vw] sm:[min-width:auto]`,
  ],
  css`
    &:first-of-type {
      ${tw`pl-6`}
    }
  `,
)

export const StyledTableBodyRow = styled.tr((props: any) => [
  tw`flex flex-auto border-monochrome-50 border-b`,
  props['data-mobiledisplay'] === 'transformed' && [
    tw`flex-wrap`,
    css`
      & [data-ismobile='true'][class*='StyledTableBodyCell']:first-of-type {
        display: none;
      }
    `,
  ],
])

export const StyledTableBodyCell = styled.td((props: any) => [
  tw`flex break-normal bg-white px-2 py-4 [justify-content:flex-start] [align-items:flex-start]`,
  css`
    &:first-of-type {
      ${tw`pl-6`}
    }
  `,
  props['data-mobiledisplay'] === 'transformed' &&
    props['data-ismobile'] && [
      tw`border-monochrome-50 border-b`,
      css`
        width: 100% !important;
        padding-left: 50% !important;
        position: relative;
        &:before {
          ${tw`absolute left-0 pl-6`}
          content: '${props['data-header']}';
        }
        &:last-child {
          box-shadow: 0px 4px 3px 0px rgb(32 33 36 / 10%);
          z-index: 1;
        }
      `,
    ],
  props['data-isselected'] && tw`bg-primary-100`,
])

export const StyledSort = styled.div(() => [
  tw`relative mr-1 -ml-1 inline-flex h-4 w-4 cursor-pointer border border-transparent`,
])

export const StyledSortUp = styled(FontAwesomeIcon)((props: TableProps) => [
  tw`absolute bottom-0 left-0 right-0`,
  props['data-sorting'] === 'desc' && tw`text-primary-700`,
])

export const StyledSortDown = styled(FontAwesomeIcon)((props: TableProps) => [
  tw`absolute top-0 left-0 right-0`,
  props['data-sorting'] === 'asc' && tw`text-primary-700`,
])

export const StyledTableFooter = styled.div(() => [
  tw`rounded-b-lg bg-white px-2 py-1 text-xs sm:px-4`,
])

export const StyledPaginationDropdown = styled(Dropdown)(() => [
  css`
    & [class*='StyledDropdownButton'] {
      ${tw`-ml-3 justify-end border-none bg-transparent text-xs text-monochrome-700 sm:-ml-6`}
    }
    & [class*='StyledCaretDown'] {
      ${tw`ml-0 shrink-0 sm:ml-1`}
    }
  `,
])

export const StyledPaginationButton = styled.button((props: any) => [
  tw`h-8 w-8`,
  !props['data-enabled'] && tw`text-monochrome-500`,
])
