import { Plans } from './FeedMarketingTrialRoute.types'

export const steps = [
  0,
  1000,
  2000,
  3000,
  4000,
  5000,
  10000,
  15000,
  20000,
  25000,
  30000,
  40000,
  50000,
  60000,
  70000,
  80000,
  90000,
  100000,
  'ABOVE',
]

export const compareTablePlansColumnIndex = {
  [Plans.SHOP]: 2,
  [Plans.MERCHANT]: 3,
  [Plans.AGENCY]: 4,
  [Plans.ENTERPRISE]: 5,
}

export const comparisonData = [
  {
    item: 'Number of shops',
    shop: 1,
    merchant: 2,
    agency: 'Unlimited',
    enterprise: 'Unlimited',
  },
  {
    item: 'Number of products',
    shop: 1000,
    merchant: 5000,
    agency: 30000,
    enterprise: 'Unlimited',
  },
  {
    item: 'Number of channel feeds',
    shop: 3,
    merchant: 10,
    agency: 150,
    enterprise: 'Unlimited',
  },
  {
    item: 'Additional 1000 extra products',
    shop: '$5',
    merchant: '$3',
    agency: '$2',
    enterprise: '$2',
  },
  {
    item: 'Extra Shop',
    shop: null,
    merchant: '$24',
    agency: null,
    enterprise: null,
  },
  {
    item: 'Automatic feed reviews',
    shop: 'Unlimited',
    merchant: 'Unlimited',
    agency: 'Unlimited',
    enterprise: 'Unlimited',
  },
  {
    item: 'Users',
    shop: undefined,
    merchant: 5,
    agency: 'Unlimited',
    enterprise: 'Unlimited',
  },
  {
    item: '15-day free trial',
    shop: true,
    merchant: true,
    agency: true,
    enterprise: true,
  },
  {
    item: 'Multiple data sources integration',
    shop: true,
    merchant: true,
    agency: true,
    enterprise: true,
  },
  {
    item: 'Free setup service',
    shop: '2 feeds',
    merchant: '3 feeds',
    agency: '5 feeds',
    enterprise: '5 feeds',
  },
  {
    item: 'Technical support (6:30 am - 5:30 pm EST)',
    shop: 'Reply <br/><strong>within 24 hrs</strong>',
    merchant: 'Reply <br/><strong>within a few hrs</strong>',
    agency: 'Reply <br/><strong>within 10 mins</strong>',
    enterprise: 'Reply <br/><strong>within 1 min</strong>',
  },
  {
    item: 'Contact options',
    shop: 'Email',
    merchant: 'Email,<br/>Live Chat',
    agency: 'Email,<br/>Live Chat,<br/>Phone',
    enterprise: 'Email,<br/>Live Chat,<br/>Phone',
  },
  {
    item: 'DataFeedWatch Academy',
    shop: true,
    merchant: true,
    agency: true,
    enterprise: true,
  },
  {
    item: 'Onboarding - live session',
    shop: undefined,
    merchant: 1,
    agency: 'Unlimited',
    enterprise: 'Unlimited',
  },
  {
    item: 'Marketplace',
    shop: undefined,
    merchant: true,
    agency: true,
    enterprise: true,
  },
  {
    item: 'DFW - Analytics',
    shop: undefined,
    merchant: true,
    agency: true,
    enterprise: true,
  },
  {
    item: 'Multiple automatic daily updates',
    shop: undefined,
    merchant: true,
    agency: true,
    enterprise: true,
  },
  {
    item: 'Dedicated account manager',
    shop: undefined,
    merchant: undefined,
    agency: true,
    enterprise: true,
  },
  {
    item: '',
    shop: 'Shop',
    merchant: 'Merchant',
    agency: 'Agency',
    enterprise: 'Enterprise',
  },
]
