import { isStorefrontEnabled } from '@brand-console/utilities'
import { useEffect, useState } from 'react'

import { useCartAuth } from '../cartAuth'
import { useAuthToken } from './useAuthToken'

export const useSfEnabled = (businessId: number) => {
  const token = useAuthToken()
  const { auth0Id, email } = useCartAuth()
  const [sfEnabled, setSfEnabled] = useState<boolean | null>(null)

  useEffect(() => {
    const getSfEnabled = async () => {
      const isSfEnabledResponse = await isStorefrontEnabled({
        businessId,
        token,
        user: { auth0Id, email },
      })
      setSfEnabled(isSfEnabledResponse)
    }
    if (token && businessId) getSfEnabled()
  }, [token, businessId, auth0Id, email])

  return sfEnabled
}
