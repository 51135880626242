import { Spinner } from '@cart/ui'
import React, { lazy, Suspense } from 'react'

const StorefrontEmailSmsApp = lazy(() => import('./StorefrontEmailSmsLazy'))

export const StorefrontEmailSms = () => {
  return (
    <Suspense fallback={<Spinner type="global" />}>
      <StorefrontEmailSmsApp />
    </Suspense>
  )
}
