import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MarketplaceManagementIcon,
} from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { StyledDialogBody, StyledSpinner } from '../MarketplaceManagementRoute.styles'
import { MCMModalState } from '../MarketplaceManagementRoute.types'

export const MarketplaceManagementRouteDialog = ({ show, state, onCloseClick, error }) => {
  return (
    <Dialog width="sm" open={show} id="multichanneldialog" hideCloseButton>
      <DialogTitle />
      <DialogContent>
        <>
          {state === MCMModalState.PROVISIONING && (
            <StyledDialogBody>
              <StyledSpinner />
              <h2 tw="mx-6 mobile:mx-0">One Platform. Multiple Markets.</h2>
              <h2 tw="mb-4">Selling Online Has Never Been Easier.</h2>
              Your 14 day free trial is being created with Cart.com&apos;s powerful, Multichannel
              Management software.
            </StyledDialogBody>
          )}
          {state === MCMModalState.SUCCESS && (
            <div tw="flex flex-col items-center px-6 pt-8">
              <MarketplaceManagementIcon tw="mb-6 w-24" />
              <h2 tw="mb-4">Your free trial is ready!</h2>
              <div tw="text-center">
                To connect your sales channels, you will be taken to our powerful Multichannel
                platform.
                <br />
                <br /> You may return to Cart.com anytime to add other services to supercharge your
                e-commerce business. Onward in your ecommerce journey! We will be with you each step
                of the way.
                <br />
                <br />
                You are being redirected now ...
              </div>
            </div>
          )}
          {state === MCMModalState.FAILURE && (
            <div tw="flex w-full flex-col items-center px-6 pt-8">
              <FontAwesomeIcon
                icon={solid('exclamation-triangle')}
                tw="mb-6 text-6xl text-accent-two-700"
              />
              <h2 tw="mb-4">{error.header}</h2>
              <div tw="text-center">{error.body}</div>
            </div>
          )}
        </>
      </DialogContent>
      <DialogActions>
        {state === MCMModalState.FAILURE && (
          <div tw="mx-auto">
            <Button onClick={onCloseClick} variant="contained">
              Try again
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  )
}
