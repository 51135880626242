import { StateCreator } from 'zustand'

import { SideMenuNode } from '../../gql/graphql'
import { resetters } from './storeResetter'
import { type StoreStateType } from './unified-sidebar.store'

export interface IUserState {
  logoutUrl?: string
  userMenuItems?: SideMenuNode[]
  setLogoutUrl: (logoutUrl: string) => void
  setUserMenuItems: (userMenuItems: SideMenuNode[]) => void
}

const defaultUserState = {
  userMenuItems: [],
  logoutUrl: '',
}

export const createUserSlice: StateCreator<
  StoreStateType,
  [['zustand/devtools', never]],
  [],
  IUserState
> = (set) => {
  resetters.push(() => set(defaultUserState, false, 'resetMenuState'))
  return {
    ...defaultUserState,
    setLogoutUrl: (logoutUrl) => set({ logoutUrl }, false, 'setLogoutUrl'),
    setUserMenuItems: (userMenuItems) => set({ userMenuItems }, false, 'setUserMenuItems'),
  }
}
