import { Button } from '@cart/ui'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import tw, { css, styled, theme } from 'twin.macro'

import image from '../../../assets/images/card-new-blue.png'

type PropTypes = {
  data: any
}

export const CtaContainerAccess = styled.div(() => [
  tw`absolute left-1/2 w-3/4 rounded-md bg-primary-700 bg-center bg-cover bg-no-repeat drop-shadow-sm max-h-[400px] top-[50px] translate-x-[-50%] sm:(flex max-h-[250px]) md:(top-1/4 w-2/3 translate-y-[-50%]) lg:top-1/2 xl:w-1/2`,
  css`
    background-image: url(${image});
    @media (max-width: 639px) {
      background-image: none;
    }
  `,
])

export const StyledList = styled.ul(() => [
  tw`mt-3 inline-block pl-0 pr-4 text-xs leading-relaxed`,
  css`
    & li {
      ${tw`relative mb-4 flex items-center leading-none`}
      &:before {
        color: red;
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path fill="${theme`colors.primary.700`.replace(
    '#',
    '%23',
  )}" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>');
        padding-right: 0.5rem;
        vertical-align: top;
        line-height: 1;
      }
    }
  `,
])

export const CTAContainer = ({ data }: PropTypes) => {
  const navigate = useNavigate()

  const handleButtonClick = (link, isLocal) => {
    if (isLocal) {
      navigate(link)
    } else {
      window.location.href = link
    }
  }
  return (
    <CtaContainerAccess>
      <div tw="flex h-1/2 w-full flex-col items-center justify-center gap-4 rounded-t-md bg-white p-6 text-left sm:(rounded-l-md bg-transparent w-[60%])">
        <div tw="mx-auto">
          <h3 tw="mb-0 text-primary-700">{data.generalTitle[0]}</h3>
          <p css={[tw`mt-4 text-sm`, data.list.length > 0 && tw`whitespace-nowrap`]}>
            {data.text[0]}
          </p>
          {data.list.length > 0 && (
            <StyledList>
              {data.list.map((item: any, index: number) => (
                <li key={index}>{item}</li>
              ))}
            </StyledList>
          )}
        </div>
      </div>
      <div tw="flex h-1/2 w-full flex-col items-center justify-center gap-4 rounded-b-md bg-primary-700 p-10 sm:(rounded-r-md bg-transparent w-[45%])">
        <h3 tw="mb-0 w-full text-right text-white leading-none">{data.generalTitle[1]}</h3>
        <div tw="text-right text-xs text-white">{data.text[1]}</div>
        <Button
          onClick={() => handleButtonClick(data.link, data.isLocal)}
          variant="outlined"
          tw="ml-auto mr-0 bg-white hover:bg-monochrome-200"
        >
          {data.linkText}
        </Button>
      </div>
    </CtaContainerAccess>
  )
}
