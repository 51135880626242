import { MouseEvent } from 'react'

export const goToUrl = (isSpa: boolean, event: MouseEvent<HTMLAnchorElement>) => {
  event.preventDefault()
  const { dispatchEvent, history, location } = window
  const { href } = event.target as HTMLAnchorElement
  const params = new URLSearchParams(location.search)
  const newTab = event.ctrlKey || event.metaKey

  if (newTab) {
    window.open(href, '_blank')
  } else if (!isSpa) {
    location.href = href
  } else {
    const url = new URL(href, location.origin)
    const isSameDomain = location.origin === url.origin
    if (isSameDomain) {
      history.pushState(null, null, url.pathname.concat(`?${params.toString()}`))
      const $event = new PopStateEvent('popstate', { state: null })
      dispatchEvent($event)
    } else {
      location.href = href
    }
  }
}
