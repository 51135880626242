import { IAccountConfirmData } from '../../components/AccountConfirmForm'

export type MCMFormData = IAccountConfirmData & {
  'marketplace-radio-group': string
}

export enum MCMModalState {
  'STANDBY',
  'PROVISIONING',
  'SUCCESS',
  'FAILURE',
}
