import { ContextMenu, StatusBadge, StatusBadgeProps } from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GridRenderCellParams } from '@mui/x-data-grid'
import { format } from 'date-fns'
import React from 'react'

import { StyledLevelTwoHeader } from '../SettingsOrganization.styles'

const badgeType = {
  active: 'success',
  invited: 'info',
}

export const ActionsCell = ({ row, canRemove, onRemoveClick, onUnblockClick }: { row: any, canRemove: boolean, onRemoveClick: (userId: number) => void, onUnblockClick: (userId: number) => void }) => {
  const items = []

  const { id, status } = row

  if (id && status !== 'Pending' && canRemove) {
    items.push(
      {
        icon: <FontAwesomeIcon icon={solid('trash-alt')} />,
        label: 'Remove User',
        onClick: () => onRemoveClick(id),
      },
    )
  }

  if (id && status === 'Active' && row.auth0Id) {
    items.push(
      {
        icon: <FontAwesomeIcon icon={solid('unlock')} />,
        label: 'Unblock User',
        onClick: () => onUnblockClick(id),
      },
    )
  }

  return (
    <div tw="flex w-full justify-end pr-5 text-xs text-monochrome-700">
      { items.length > 0 ? <ContextMenu
        tw="ml-3"
        items={[items]}
      >
        <div tw="flex h-7 w-7 cursor-pointer items-center justify-center rounded-full border border-monochrome-200 bg-white p-2 text-monochrome-700 text-xs leading-none hover:bg-monochrome-50">
          <FontAwesomeIcon icon={solid('ellipsis-vertical')} />
        </div>
      </ContextMenu> : null }
    </div>
  )
}

export const StatusCell = (params: GridRenderCellParams) => {
  const { row } = params
  const { createdAt, status } = row
  const isInvited = status === 'Pending'
  const badgeText = isInvited ? 'Invited' : 'Active'

  const badgeTypeObjs = badgeType[isInvited ? 'invited' : 'active'] as StatusBadgeProps['type']

  const createdAtDate = new Date(createdAt)
  return (
    <div>
      <div>
        <StatusBadge badgeContent={badgeText} type={badgeTypeObjs} />
      </div>
      <div tw="mt-1">
        <p tw="block text-monochrome-900/60">
          {isInvited
            ? `on ${format(createdAtDate, 'MMMM d, yyyy')}`
            : `since ${format(createdAtDate, 'MMMM d, yyyy')}`}
        </p>
      </div>
    </div>
  )
}

export const NameCell = ({ row }) => {
  return (
    <p>
      {row.primaryEmail}
      <br />
      <span tw="text-monochrome-900/60">
        {row.firstName || ''} {row.lastName || ''}
      </span>
    </p>
  )
}

export const RoleCell = ({ value }) => {
  if (!value) {
    return ''
  }
  return value.displayName
}

export const BusinessesCell = ({ value }) => {
  if (!value) {
    return ''
  }
  return value.length
}

export const UsersTableHeader = () => {
  return (
    <div tw="mx-7 my-4 flex justify-between">
      <StyledLevelTwoHeader>Users</StyledLevelTwoHeader>
    </div>
  )
}
