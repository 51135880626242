import tw, { css, styled } from 'twin.macro'

import { InputProps } from './deprecated-input.types'

export const StyledInputContainer = styled.div(() => [tw`w-full text-left`])

export const StyledInputLabel = styled.label((props: InputProps | any) => [
  tw`block font-normal text-xs text-monochrome-900`,
  props['data-hasfocus'] && !props['data-haserror'] && tw`text-primary-700`,
  props['data-haserror'] && tw`text-accent-two-700`,
])

export const StyledInput = styled.div(() => [tw`relative mt-1 rounded-md`])

export const StyledInputField = styled.input((props: InputProps | any) => [
  tw`block w-full rounded-md border-2 border-monochrome-500 border-solid p-2 pr-10 text-sm placeholder-monochrome-600 disabled:bg-monochrome-50`,
  tw`focus:(border-primary-700 outline-none ring-accent-two-700)`,
  props['data-haserror'] && tw`border-accent-two-700 focus:border-accent-two-700`,
  props['data-hastrailingicon'] && tw`pr-10`,
  props['data-noborder'] && tw`md:(border-transparent focus:border-transparent)`,
  props['data-search'] && tw`pl-10`,
  css`
    height: 38px;
  `,
])

export const StyledInputTrailingIcon = styled.div`
  ${tw`absolute inset-y-0 right-0 flex items-center pr-3`}
  & svg {
    ${tw`h-5 w-5 text-monochrome-900`}
  }
`
export const StyledInputSearchIcon = styled.div`
  ${tw`absolute inset-y-0 left-3 flex items-center pr-3`}
`

export const StyledMessage = styled.p((props: InputProps | any) => [
  tw`mt-1 text-monochrome-600 text-xs`,
  props['data-haserror'] && tw`text-accent-two-700`,
])
