import { useAssignedPermissionCheckQuery } from '@brand-console/generated-graphql-hooks'

/**
 * This hook is used to check if the user has the specified permission.
 * @param permissionName The name of the permission to check.
 * @param businessId The business ID of the business to check the permission for.
 * @returns A boolean indicating whether the user has the specified permission.
 */
export const usePermissionCheck = (
  permissionName: string,
  businessId: number,
  organizationId: number,
) => {
  const { data: permissionCheckData, loading } = useAssignedPermissionCheckQuery({
    variables: { permissionName, businessId, organizationId },
  })
  return { access: permissionCheckData?.org_assignedPermissionCheck.access, loading }
}
