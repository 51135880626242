import React from 'react'
import * as Yup from 'yup'

import amazonAdvertising from '../assets/icons/amazon_advertising2.svg'
import amazonSellerCentral from '../assets/icons/aws.svg'
import googleAds from '../assets/icons/google_ads.svg'
import googleAnalytics from '../assets/icons/google_analytics.svg'
import googleSearch from '../assets/icons/google_search_console.svg'
import hubSpot from '../assets/icons/hub_spot.svg'
import klaviyo from '../assets/icons/klaviyo.svg'
import LinkedInAdsIcon from '../assets/icons/linkedin_ads.svg'
import mailChimp from '../assets/icons/mail_chimp.svg'
import facebook from '../assets/icons/meta.svg'
import MicrosoftAdsIcon from '../assets/icons/microsoft_ads.svg'
import PinterestAdsIcon from '../assets/icons/pinterest_ads.svg'
import SearchAds360Icon from '../assets/icons/search_ads_360.svg'
import shopify from '../assets/icons/shopify.svg'
import SnapchatAdsIcon from '../assets/icons/snapchat_ads.svg'
import storeFrontIcon from '../assets/icons/storeFrontIcon.svg'
import TikTokAdsIcon from '../assets/icons/tiktok_ads.svg'
import TwitterAdsIcon from '../assets/icons/twitter_ads.svg'
// import { StorefrontIcon } from '@cart/ui'
import {
  ActionsDropdownCommonTableType,
  ButtonsCommonTableType,
  DATA_CONNECTOR_CATEGORIES,
  DataConnector,
  FooterDataType,
  MenuSectionType,
  RegexDataType,
  ShopifyConnectorType,
  TitlesCommonTableType,
} from './data.types'

const regexFields: RegexDataType = {
  account_id: /^([0-9]){5,30}$/,
  apikey: '',
  api_key: '',
  customer_id: /^([0-9-]){5,19}$/,
  login_customer_id: /^([0-9-]){5,19}$/,
  site_urls: /^(ftp|http|https):\/\/[^ "]+$/,
  start_date: /\d{4}-\d{2}-\d{2}/,
  username: '',
  view_id: /^([0-9]){5,15}$/,
  property_id: /^([0-9]){5,15}$/,
}

const validateFieldCommon = {
  start_date: Yup.string()
    .matches(regexFields.start_date, 'Please enter correct start date in form yyyy-mm-dd')
    .required('Please enter start date'),
  end_date: Yup.string()
    .matches(regexFields.start_date, 'Please enter correct end date in form yyyy-mm-dd')
    .required('Please enter end date'),
  view_id: Yup.string()
    .matches(regexFields.view_id, 'Please enter correct view ID')
    .required('Please enter view ID'),
  property_id: Yup.string()
    .matches(regexFields.property_id, 'Please enter correct property ID')
    .required('Please enter property ID'),

  account_id: Yup.string()
    .matches(regexFields.account_id, 'Please enter correct account ID')
    .required('Please enter account ID'),
  customer_id: Yup.string()
    .matches(regexFields.customer_id, 'Please enter correct customer ID')
    .required('Please enter customer ID'),
  login_customer_id: Yup.string().matches(
    regexFields.login_customer_id,
    'Please enter correct login customer ID',
  ),
  shop: Yup.string().required('Please enter a Shopify store name'),
  apikey: Yup.string().required('Please enter API key'),
  api_key: Yup.string().required('Please enter API key'),
  username: Yup.string().required('Please enter your username'),
  site_urls: Yup.string()
    .matches(regexFields.site_urls, 'Please enter correct url')
    .required('Please enter url'),
  profileId: Yup.array().required('Please enter your profile IDs seperated by commas'),
}

const createValidate = (fields) => {
  const validate = fields.reduce((acc: Record<string, unknown>, key: string) => {
    // eslint-disable-next-line no-param-reassign
    acc[key] = validateFieldCommon[key]
    return acc
  }, {})
  return Yup.object().shape(validate)
}

// data
const year = new Date().getFullYear()

export const footerData: FooterDataType[] = [
  { link: 'https://www.cart.com/', text: `© ${year} Cart.com` },
  {
    link: 'https://www.cart.com/terms-of-service',
    text: 'Terms of Services',
  },
  { link: 'https://www.cart.com/privacy-policy', text: 'Privacy Policy' },
]

export const menuData: MenuSectionType[] = [
  {
    name: '',
    key: 'dashboard',
    items: [
      {
        name: 'Dashboard',
        icon: 'dashboard',
        link: '/unified-analytics/dashboard',
        order: 1,
        children: [],
      },
    ],
  },
  {
    name: 'Reports',
    key: 'reports',
    items: [
      {
        name: 'Marketing Intelligence',
        icon: 'marketing_intelligence',
        children: [],
      },
      {
        name: 'Web Analytics',
        icon: 'web_analytics',
        children: [],
      },
      {
        name: 'Online Store',
        icon: 'online_store',
        children: [],
      },
      {
        name: 'Order Fulfillment',
        icon: 'order_fulfillment',
        children: [],
      },
    ],
  },
  {
    name: 'Settings',
    key: 'settings',
    items: [
      {
        name: 'Data connectors',
        icon: 'data_connectors',
        order: 1,
        children: [
          {
            name: 'My Connectors',
            link: 'my-connectors',
            order: 1,
          },
          {
            name: 'All Connectors',
            link: 'all-connectors',
            order: 2,
          },
        ],
      },
    ],
  },
]

export const dataConnectorsData: DataConnector[] = [
  {
    title: 'Google Analytics',
    category: DATA_CONNECTOR_CATEGORIES['Web Analytics'],
    icon: googleAnalytics,
    name: 'google-analytics',
    subTitle: 'Marketing, Product',
    button: true,
    description:
      'Gain immediate insights into your web performance metrics so you can better understand customers and assess the performance of your content.',
    connectorName: 'GoogleAnalyticsDataConnector',
  },
  {
    title: 'Google Analytics 4',
    category: DATA_CONNECTOR_CATEGORIES['Web Analytics'],
    icon: googleAnalytics,
    name: 'google-analytics-4',
    subTitle: 'Marketing, Product',
    button: true,
    description:
      'Gain immediate insights into your web performance metrics so you can better understand customers and assess the performance of your content.',
    connectorName: 'GoogleAnalyticsGA4DataConnector',
  },
  {
    title: 'Google Ads',
    category: DATA_CONNECTOR_CATEGORIES['Marketing Analytics'],
    icon: googleAds,
    name: 'google-adwords',
    button: true,
    subTitle: 'Marketing',
    description:
      'Optimize your campaign budgets, find the right audiences, and drive more revenue by connecting your Google Ads data with other sources.',
    connectorName: 'GoogleAdwordsDataConnector',
  },
  {
    title: 'Google Search Console',
    category: DATA_CONNECTOR_CATEGORIES['Web Analytics'],
    icon: googleSearch,
    name: 'google-search-console',
    button: true,
    description:
      'Monitor and maintain your site’s presence in Google Search results by integrating your site data into a unified experience.',
    connectorName: 'GoogleSearchConsoleDataConnector',
  },
  {
    title: 'Facebook',
    category: DATA_CONNECTOR_CATEGORIES['Marketing Analytics'],
    name: 'facebook',
    icon: facebook,
    button: true,
    subTitle: 'Marketing',
    description:
      'Integrate your Facebook Ads performance data, such as campaigns and spending, into a single view that uncovers trends across marketing channels.',
    connectorName: 'FacebookDataConnector',
  },
  {
    title: 'Amazon Seller Central',
    category: DATA_CONNECTOR_CATEGORIES['Sales Analytics'],
    name: 'amazon-seller-central',
    icon: amazonSellerCentral,
    button: true,
    description:
      'Analyze the sales and fulfillment data through your integration as a supplier with Amazon Vendor Central.',
    connectorName: 'AmazonSellerCentralDataConnector',
  },
  // {
  //   title: 'MailChimp',
  //   category: DATA_CONNECTOR_CATEGORIES['Marketing Analytics'],
  //   name: 'mailchimp',
  //   icon: mailChimp,
  //   button: true,
  //   description:
  //     'Connect your email marketing statistics to better understand target audiences, campaign effectiveness, and subscriber behavior.',
  //   connectorName: 'MailchimpDataConnector',
  // },
  {
    title: 'Klaviyo',
    category: DATA_CONNECTOR_CATEGORIES['Marketing Analytics'],
    name: 'klaviyo',
    icon: klaviyo,
    button: true,
    description:
      'Target, personalize, measure, and optimize email and Facebook campaigns from a list of best practice metrics provided by your Klaviyo account.',
    connectorName: 'KlaviyoDataConnector',
  },
  // {
  //   title: 'HubSpot',
  //   category: DATA_CONNECTOR_CATEGORIES['Marketing Analytics'],
  //   name: 'hubspot',
  //   icon: hubSpot,
  //   button: true,
  //   description:
  //     'Compile your reports about contacts, blogs, lead sources, and pipeline management to understand open rates and leads for campaigns.',
  //   connectorName: 'HubspotDataConnector',
  // },
  {
    title: 'Shopify',
    category: DATA_CONNECTOR_CATEGORIES['Sales Analytics'],
    name: 'shopify',
    icon: shopify,
    button: true,
    description:
      'Connect your Shopify store to analyze web performance, sales, and fulfillment data.',
    connectorName: 'ShopifyDataConnector',
  },
  {
    title: 'TikTok',
    category: DATA_CONNECTOR_CATEGORIES['Marketing Analytics'],
    name: 'tiktok',
    icon: TikTokAdsIcon,
    button: true,
    description:
      'Optimize how your TikTok content is performing amongst other campaigns and make adjustments to improve product visibility.',
    connectorName: 'TikTokDataConnector',
  },
  {
    title: 'Amazon Advertising',
    category: DATA_CONNECTOR_CATEGORIES['Marketing Analytics'],
    name: 'amazon-advertising',
    icon: amazonAdvertising,
    button: true,
    description:
      'Optimize how your Amazon ads content is performing amongst other campaigns and make adjustments to improve product visibility.',
    connectorName: 'AmazonAdvertisingDataConnector',
  },
  {
    title: 'Microsoft Advertising',
    category: DATA_CONNECTOR_CATEGORIES['Marketing Analytics'],
    name: 'microsoft-advertising',
    icon: MicrosoftAdsIcon,
    button: true,
    description:
      'Optimize how your Microsoft Advertising content is performing amongst other campaigns and make adjustments to improve product visibility.',
    connectorName: 'MicrosoftAdvertisingDataConnector',
  },
  {
    title: 'LinkedIn Ads',
    category: DATA_CONNECTOR_CATEGORIES['Marketing Analytics'],
    name: 'linkedin-ads',
    icon: LinkedInAdsIcon,
    button: true,
    description:
      'Optimize how your LinkedIn Ads content is performing amongst other campaigns and make adjustments to improve product visibility.',
    connectorName: 'LinkedinDataConnector',
  },
  {
    title: 'Snapchat Ads',
    category: DATA_CONNECTOR_CATEGORIES['Marketing Analytics'],
    name: 'snapchat-ads',
    icon: SnapchatAdsIcon,
    button: true,
    description:
      'Optimize how your Snapchat Ads content is performing amongst other campaigns and make adjustments to improve product visibility.',
    connectorName: 'SnapChatDataConnector',
  },
  {
    title: 'Pinterest Ads',
    category: DATA_CONNECTOR_CATEGORIES['Marketing Analytics'],
    name: 'pinterest-ads',
    icon: PinterestAdsIcon,
    button: true,
    description:
      'Optimize how your Pinterest Ads content is performing amongst other campaigns and make adjustments to improve product visibility.',
    connectorName: 'PinterestDataConnector',
  },
  // {
  //   title: 'Search Ads 360',
  //   category: DATA_CONNECTOR_CATEGORIES['Marketing Analytics'],
  //   name: 'search-ads-360',
  //   icon: SearchAds360Icon,
  //   button: true,
  //   description:
  //     'Use Google’s powerful search management platform to track and manage multiple campaigns across search engines and media channels.',
  //   connectorName: 'SearchAds360DataConnector',
  // },
  // {
  //   title: 'Twitter Ads',
  //   category: DATA_CONNECTOR_CATEGORIES['Marketing Analytics'],
  //   name: 'twitter-ads',
  //   icon: TwitterAdsIcon,
  //   button: true,
  //   description:
  //     'Optimize how your Twitter Ads content is performing amongst other campaigns and make adjustments to improve product visibility.',
  //   connectorName: 'TwitterDataConnector',
  // },
  {
    title: 'Storefront',
    category: DATA_CONNECTOR_CATEGORIES['Cart.com'],
    icon: storeFrontIcon,
    name: 'storefront',
    button: false,
    description: '',
    connectorName: 'StorefrontDataConnector',
  },
]

export const shopifyConnector: ShopifyConnectorType = {
  shopify: {
    title: 'Shopify',
    validate: createValidate(['activation_code']),
    fields: [
      {
        name: 'activationCode',
        label: 'Enter your activation code',
        placeholder: 'activation code',
        type: 'text',
      },
    ],
    initialValues: {
      activationCode: '',
    },
  },
}

export const dataConnectorsFields = {
  'google-search-console': {
    title: 'Google Search Console',
    type: 'GoogleSearchConsoleDataConnector',
    validate: createValidate(['start_date', 'site_urls']),
    fields: [
      {
        name: 'site_urls',
        label: 'Site Url',
        placeholder: 'Type your site url ',
        type: 'text',
      },
      {
        name: 'start_date',
        label: 'Start Date',
        placeholder: 'yyyy-mm-dd',
        type: 'text',
      },
    ],
    initialValues: {
      business: null,
      start_date: '',
      site_urls: '',
    },
  },
  'google-analytics': {
    title: 'Google Analytics',
    type: 'GoogleAnalyticsDataConnector',
    validate: createValidate(['start_date', 'view_id']),
    fields: [
      {
        name: 'view_id',
        label: 'View ID',
        placeholder: 'Enter your view ID',
        type: 'text',
      },
      {
        name: 'start_date',
        label: 'Start Date',
        placeholder: 'yyyy-mm-dd',
        type: 'text',
      },
    ],
    initialValues: {
      business: null,
      start_date: '',
      view_id: '',
    },
  },
  'google-analytics-4': {
    title: 'Google Analytics',
    type: 'GoogleAnalyticsDataConnector',
    validate: createValidate(['start_date', 'property_id']),
    fields: [
      {
        name: 'propertyId',
        label: 'Property ID',
        placeholder: 'Enter your property ID',
        type: 'text',
      },
      {
        name: 'start_date',
        label: 'Start Date',
        placeholder: 'yyyy-mm-dd',
        type: 'text',
      },
    ],
    initialValues: {
      business: null,
      start_date: '',
      property_id: '',
    },
  },

  'google-adwords': {
    title: 'Google Ads',
    type: 'GoogleAdwordsDataConnector',
    validate: createValidate(['start_date', 'customer_id', 'login_customer_id']),
    fields: [
      {
        name: 'start_date',
        label: 'Start Date',
        placeholder: 'yyyy-mm-dd',
        type: 'text',
      },
      {
        name: 'customer_id',
        label: 'Customer ID',
        placeholder: 'Enter customer ID',
        type: 'text',
      },
      {
        name: 'login_customer_id',
        label: 'Login Customer ID',
        placeholder: 'Enter login customer ID',
        type: 'text',
      },
    ],
    initialValues: {
      business: null,
      start_date: '',
      customer_id: '',
      login_customer_id: '',
    },
  },
  facebook: {
    title: 'Facebook',
    type: 'FacebookDataConnector',
    validate: createValidate(['start_date', 'account_id']),
    fields: [
      {
        name: 'account_id',
        label: 'Account ID',
        placeholder: 'Enter account ID',
        type: 'text',
      },
      {
        name: 'start_date',
        label: 'Start Date',
        placeholder: 'yyyy-mm-dd',
        type: 'text',
      },
    ],
    initialValues: {
      business: null,
      start_date: '',
      account_id: '',
    },
  },
  'amazon-seller-central': {
    title: 'Amazon Seller Central',
    type: 'ConciergeDataConnector',
    validate: createValidate(['start_date']),
    fields: [],
    initialValues: {
      business: null,
      start_date: '',
    },
  },
  shopify: {
    title: 'Shopify',
    type: 'ShopifyDataConnector',
    validate: createValidate(['start_date']),
    fields: [
      {
        name: 'start_date',
        label: 'Start Date',
        placeholder: 'yyyy-mm-dd',
        type: 'text',
      },
    ],
    initialValues: {
      business: null,
      start_date: '',
    },
  },
  'amazon-advertising': {
    title: 'Amazon Advertising',
    type: 'AmazonAdvertisingDataConnector',
    validate: createValidate(['start_date']),
    fields: [
      {
        name: 'start_date',
        label: 'Start Date',
        placeholder: 'yyyy-mm-dd',
        type: 'text',
      },
      {
        name: 'profiles',
        label: 'Profile IDs',
        placeholder: 'e.g. 1234, 5678, 9876543',
        type: 'array',
      },
    ],
    initialValues: {
      business: null,
      start_date: '',
    },
  },
  klaviyo: {
    title: 'Klaviyo',
    type: 'KlaviyoDataConnector',
    validate: createValidate(['api_key', 'start_date']),
    fields: [
      {
        name: 'api_key',
        label: 'API key',
        placeholder: 'Enter your API key',
        type: 'text',
      },
      {
        name: 'start_date',
        label: 'Start Date',
        placeholder: 'yyyy-mm-dd',
        type: 'text',
      },
    ],
    initialValues: {
      business: null,
      api_key: '',
      start_date: '',
    },
  },
  mailchimp: {
    title: 'MailChimp',
    type: 'MailchimpDataConnector',
    validate: createValidate(['username', 'apikey']),
    fields: [
      {
        name: 'username',
        label: 'User Name',
        placeholder: 'Enter your user name',
        type: 'text',
      },
      {
        name: 'apikey',
        label: 'API key',
        placeholder: 'Enter your API key',
        type: 'text',
      },
    ],
    initialValues: {
      username: '',
      apikey: '',
      business: null,
    },
  },
  hubspot: {
    title: 'HubSpot',
    type: 'HubspotDataConnector',
    validate: null,
    fields: [
      {
        name: 'api_key',
        label: 'API key',
        placeholder: 'Enter your API key',
        type: 'text',
      },
      {
        name: 'start_date',
        label: 'Start Date',
        placeholder: 'yyyy-mm-dd',
        type: 'text',
      },
    ],
    initialValues: {
      api_key: '',
      start_date: '',
      business: null,
    },
  },
  tiktok: {
    title: 'TikTok',
    type: 'TikTokDataConnector',
    validate: createValidate(['start_date', 'customer_id', 'login_customer_id']),
    fields: [
      {
        name: 'start_date',
        label: 'Start Date',
        placeholder: 'yyyy-mm-dd',
        type: 'text',
      },
    ],
    initialValues: {
      business: null,
      start_date: '',
      end_date: '',
    },
  },
  storefront: {
    title: 'StoreFront',
    type: 'StorefrontDataConnector',
    validate: null,
    fields: [],
    initialValues: {
      business: null,
    },
  },
  searchmetrics: {
    title: 'Searchmetrics',
    type: 'ConciergeDataConnector',
    validate: null,
    fields: [],
    initialValues: {
      store: null,
    },
  },
  'microsoft-advertising': {
    title: 'Microsoft Advertising',
    type: 'ConciergeDataConnector',
    validate: null,
    fields: [],
    initialValues: {
      store: null,
    },
  },
  'tiktok-ads': {
    title: 'TikTok Ads',
    type: 'ConciergeDataConnector',
    validate: null,
    fields: [],
    initialValues: {
      store: null,
    },
  },
  'linkedin-ads': {
    title: 'LinkedIn Ads',
    type: 'ConciergeDataConnector',
    validate: null,
    fields: [],
    initialValues: {
      store: null,
    },
  },
  'snapchat-ads': {
    title: 'Snapchat Ads',
    type: 'ConciergeDataConnector',
    validate: null,
    fields: [],
    initialValues: {
      store: null,
    },
  },
  'pinterest-ads': {
    title: 'Pinterest Ads',
    type: 'ConciergeDataConnector',
    validate: null,
    fields: [],
    initialValues: {
      store: null,
    },
  },
  'search-ads-360': {
    title: 'Search Ads 360',
    type: 'ConciergeDataConnector',
    validate: null,
    fields: [],
    initialValues: {
      store: null,
    },
  },
  'twitter-ads': {
    title: 'Twitter Ads',
    type: 'ConciergeDataConnector',
    validate: null,
    fields: [],
    initialValues: {
      store: null,
    },
  },
}

export const dataStatusColor = {
  failed: 'error',
  inactive: 'secondary',
  deprecated: 'secondary',
  pending: 'info',
  running: 'primary',
  incomplete: 'warning',
  succeeded: 'success',
  active: 'success',
  cancelled: 'error',
}

export const titlesCommonTable: TitlesCommonTableType = {
  dataConnectorsPage: [
    { title: 'Category', filter: false, checkbox: true },
    { title: 'Data Connector', filter: false, fieldName: 'dataConnectors', checkbox: false },
    { title: 'Business', filter: false, checkbox: false },
    { title: 'Status', filter: false, checkbox: false },
    { title: 'Last Updated', filter: false, fieldName: 'lastUpdated', checkbox: false },
  ],
  emailReportsPage: [
    { title: 'Subject', filter: true, fieldName: 'subject', checkbox: true },
    { title: 'Business', filter: true, fieldName: 'business', checkbox: false },
    { title: 'Email Schedule', filter: true, fieldName: 'emailSchedule', checkbox: false },
    { title: 'Created by', filter: true, fieldName: 'createdBy', checkbox: false },
    { title: 'Recipients', filter: true, fieldName: 'recipients', checkbox: false },
    { title: 'Reports included', filter: true, fieldName: 'reportsIncluded', checkbox: false },
  ],
}

export const actionsDropdownCommonTable: ActionsDropdownCommonTableType = {
  dataConnectorsPage: [
    { title: 'Business', fieldName: 'business' },
    { title: 'Status', fieldName: 'status' },
  ],
  emailReportsPage: [
    { title: 'Business', fieldName: 'business' },
    { title: 'Created by', fieldName: 'createdBy' },
  ],
}

export const buttonsCommonTable: ButtonsCommonTableType = {
  dataConnectorsPage: [
    { title: 'Sync', buttonName: 'sync' },
    { title: 'Reset', buttonName: 'reset' },
    { title: 'Delete', buttonName: 'delete' },
  ],
  emailReportsPage: null,
}

export const setupGuideText: { [key: string]: { title: string; guide: string[] } } = {
  customer_id: {
    title: 'Customer ID',
    guide: [
      'Enter the Customer ID for the Google Ads account you will be connecting to the selected Storefront. If you are not using a manager account, this is the customer ID for your Google Ads account.',
    ],
  },
  login_customer_id: {
    title: 'Login Customer ID',
    guide: [
      'If you are using a manager account, enter the Customer ID for that account here. If you are not using a manager account, leave this field blank.',
    ],
  },
  view_id: {
    title: 'Find View ID in Google Analytics',
    guide: [
      'Sign in to Google Analytics',
      'Click ADMIN, and navigate to View',
      'In the VIEW column, click VIEW SETTINGS',
      'VIEW ID is underneath BASIC SETTINGS',
    ],
  },
  // account_id: { title: '', guide: [] },
  // shop: { title: '', guide: [] },
  // site_urls: { title: '', guide: [] },
  // start_date: { title: '', guide: [] },
  // api_key: { title: '', guide: [] },
  // apikey: { title: '', guide: [] },
  // username: { title: '', guide: [] },
}
