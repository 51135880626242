import { ISidebarItem } from '@brand-console/types'
import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react'

import { SnackbarProps } from '../../components/snackbar/snackbar'
import { ServiceProvisioning, SFProvisioning } from './App.types'

export const AppContext = createContext<IAppContext | null>(null)

interface IAppContext {
  auth0Token: string | undefined
  loadingMyStores: boolean
  mcmEnabled: boolean | undefined
  mcmIsLoading: boolean
  dfwAccount: ServiceProvisioning | undefined
  sfAccount: SFProvisioning | undefined
  sfAccountIsLoading: boolean
  showFooter: boolean
  showSidebar: boolean
  siteNavigation: any
  snackbar?: SnackbarProps
  unifiedAnalyticsProvisioned: boolean
  /**
   * An array of node.link strings. Any link string in this array will be filtered out of the site navigation menu.
   */
  menuFilter: string[] | undefined
  setAuth0Token: (arg: string) => void
  setDfwAccount: (arg: ServiceProvisioning) => void
  setLoadingMyStores: (arg: boolean) => void
  setMcmEnabled: (arg: boolean) => void
  setMcmIsLoading: (arg: boolean) => void
  setSfAccount: (arg: SFProvisioning) => void
  setSfAccountIsLoading: (arg: boolean) => void
  setShowFooter: (arg: boolean) => void
  setShowSidebar: (arg: boolean) => void
  setSiteNavigation: (arg: any) => void
  setSnackbar: (snackbar: SnackbarProps) => void
  setUnifiedAnalyticsProvisioned: (arg: boolean) => void
  /**
   * Set the array of node.link strings to be filtered out of the site navigation menu.
   */
  setMenuFilter: (arg?: string[]) => void
}

export const useAppContext = () => {
  const context = useContext(AppContext)
  if (!context) throw new Error('AppContext must be used with AppProvider!')
  return context
}

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const [showFooter, setShowFooter] = useState<boolean>(false)
  const [showSidebar, setShowSidebar] = useState<boolean>(false)

  const [auth0Token, setAuth0Token] = useState<string | undefined>()
  const [dfwAccount, setDfwAccount] = useState<ServiceProvisioning | undefined>()
  const [loadingMyStores, setLoadingMyStores] = useState<boolean>(true)
  const [mcmEnabled, setMcmEnabled] = useState<boolean | undefined>()
  const [mcmIsLoading, setMcmIsLoading] = useState<boolean>(true)
  const [sfAccount, setSfAccount] = useState<SFProvisioning | undefined>()
  const [sfAccountIsLoading, setSfAccountIsLoading] = useState(false)
  const [siteNavigation, setSiteNavigation] = useState<ISidebarItem[]>()
  const [snackbar, setSnackbar] = useState<SnackbarProps>()
  const [unifiedAnalyticsProvisioned, setUnifiedAnalyticsProvisioned] = useState<boolean>(false)
  const [menuFilter, setMenuFilter] = useState<string[]>()

  const value = useMemo(
    () => ({
      auth0Token,
      dfwAccount,
      loadingMyStores,
      mcmEnabled,
      mcmIsLoading,
      menuFilter,
      sfAccount,
      sfAccountIsLoading,
      showFooter,
      showSidebar,
      siteNavigation,
      snackbar,
      unifiedAnalyticsProvisioned,
      setAuth0Token,
      setDfwAccount,
      setLoadingMyStores,
      setMcmEnabled,
      setMcmIsLoading,
      setMenuFilter,
      setSfAccount,
      setSfAccountIsLoading,
      setShowFooter,
      setShowSidebar,
      setSiteNavigation,
      setSnackbar,
      setUnifiedAnalyticsProvisioned,
    }),
    [
      auth0Token,
      dfwAccount,
      loadingMyStores,
      mcmEnabled,
      mcmIsLoading,
      menuFilter,
      sfAccount,
      sfAccountIsLoading,
      showFooter,
      showSidebar,
      siteNavigation,
      snackbar,
      unifiedAnalyticsProvisioned
    ],
  )
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}
