/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { createContext, ReactElement, ReactNode, useContext, useState } from 'react'
import Children from 'react-children-utilities'

const buildRoutes = (theChildren: any) => {
  const routes: any = []
  const headers: any = []
  if (Array.isArray(theChildren)) {
    theChildren.map((child) => {
      if (
        ['TabItem'].includes(child?.type?.name) ||
        ['TabItem'].includes(child?.type?.displayName) ||
        ['TabItem'].includes(child?.props?.originalType?.displayName) ||
        ['TabItem'].includes(child?.props?.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.name) ||
        ['TabItem'].includes(child?.props?.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.displayName)
      ) {
        const nestedTabs: React.ReactNode = Children.deepFind(
          child.props.children,
          (child: any) => {
            const tagName = child.type?.name
            const tagDisplayName = child.props?.originalType?.displayName
            const tagEmotion = child?.props?.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.displayName
            const theTag = tagName || tagDisplayName || tagEmotion
            return theTag === 'Tabs'
          },
        )
        const { exact = false, label, path, index } = child.props
        headers.push({
          exact,
          label,
          path,
        })
        routes.push({
          index: !!index,
          path: child.props.path === '' ? '' : `${child.props.path}/*`,
          element: child,
          ...(nestedTabs
            ? {
                children: buildRoutes(
                  React.Children.toArray((nestedTabs as ReactElement).props.children),
                ).routes,
              }
            : {}),
        })
      }
      return null
    })
  }
  return {
    headers,
    routes,
  }
}

const useTabs = (initial: any) => {
  const [store, setStore] = useState(initial)
  return {
    root: store.root,
    routes: store.routes,
    setRoot: (routes: any) => setStore({ ...store, routes }),
    updateRoutes: (root: string, children: any) => {
      const currentRoutes = store.routes
      const rootWithoutAst = root.replace('/*', '')
      const parsedRoot = rootWithoutAst.substring(rootWithoutAst.lastIndexOf('/') + 1)
      const routesWithChildren = currentRoutes.find((route: any) =>
        (route.path as string)?.startsWith(parsedRoot),
      )
      const childrenRoutes = buildRoutes(children)
      if (routesWithChildren) {
        routesWithChildren.children = childrenRoutes.routes
      }
      setStore({ ...store, routes: currentRoutes })
    },
  }
}

export const TabsContext = createContext<any>(null)

export const useTabsContext = () => useContext<any>(TabsContext)

export const TabsProvider = ({ root, children }: { root?: any; children: ReactNode }) => {
  const deepTab: any = Children.deepFind(children, (child) => {
    return (
      (child as any)?.type?.name === 'Tabs' ||
      (child as any)?.type?.displayName === 'Tabs' ||
      (child as any)?.props?.originalType?.displayName === 'Tabs' ||
      (child as any)?.props.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.name === 'Tabs' ||
      (child as any)?.props.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.displayName === 'Tabs'
    )
  })

  const theRoutes = React.useMemo(() => {
    if (deepTab) {
      return buildRoutes(deepTab.props?.children).routes
    }
    return null
  }, [deepTab])

  const theHeaders = React.useMemo(() => {
    if (deepTab) {
      return buildRoutes(deepTab.props?.children).headers
    }
    return null
  }, [deepTab])

  return (
    <TabsContext.Provider value={useTabs({ routes: theRoutes, headers: theHeaders, root })}>
      {children}
    </TabsContext.Provider>
  )
}
