import { useReactiveVar } from '@apollo/client'
import { useOrg_userConfirmMfaEnrollmentMutation } from '@brand-console/generated-graphql-hooks'
import { appendIfValid, logger } from '@brand-console/utilities'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Spinner,
} from '@cart/ui'
import QRCode from 'qrcode'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { AuthenticationMethod, mfaTokenVar, recoveryCodeVar } from '../SettingsSecurity.partials'
import { ISuccess, ITokenError, ModalProps } from '../SettingsSecurity.types'

interface MfaOtpEnrollResponse {
  authenticator_type: string
  barcode_uri: string
  recovery_codes?: string[]
  secret: string
}

type ConfirmOtpInput = {
  authenticationcode: string
}

export const EnableOTPModal: FC<ModalProps<ISuccess, ITokenError>> = ({
  show,
  closeCallback,
  successCallback,
  handleTokenErrorCallback,
}): ReactElement => {
  const log = logger.setLogger(logger.LoggerNames.SECURITY)
  const [authenticatorKey, setAuthenticatorKey] = useState('')
  const [showFailureAlert, setShowFailureAlert] = useState(false)
  const [failureAlertMessage, setFailureAlertMessage] = useState('An unspecified error occurred.')
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const tokenVar = useReactiveVar(mfaTokenVar)
  const methods = useForm()
  const { handleSubmit, reset } = methods

  const resetModalOnExited = () => {
    setQrCodeDataUrl('')
    setAuthenticatorKey('')
    setShowFailureAlert(false)
    setFailureAlertMessage('')
    reset()
  }

  const requestOtp = async () => {
    try {
      setIsLoading(true)
      const body = { authenticator_types: ['otp'] }
      const response = await fetch(`https://${process.env.NX_AUTH0_DOMAIN}/mfa/associate`, {
        method: 'POST',
        headers: { authorization: `Bearer ${tokenVar}`, 'content-type': 'application/json' },
        body: JSON.stringify(body),
      })
      setIsLoading(false)
      const responseJSON: MfaOtpEnrollResponse = await response.json()
      if (responseJSON?.recovery_codes) {
        recoveryCodeVar(responseJSON.recovery_codes[0])
      }
      if (responseJSON?.barcode_uri) {
        QRCode.toDataURL(responseJSON.barcode_uri)
          .then((url) => {
            setQrCodeDataUrl(url)
          })
          .catch((error) => {
            logger.error('Enable OTP error: QRCode.toDataURL', error)
          })
      }
      if (responseJSON?.secret) {
        setAuthenticatorKey(responseJSON.secret)
      }
    } catch (error) {
      setIsLoading(false)
      log.error('Enable OTP error: requestOtp', error)
      setFailureAlertMessage('')
      setShowFailureAlert(true)
      handleTokenErrorCallback('enableotp')
    }
  }

  const [confirmOtp, { loading }] = useOrg_userConfirmMfaEnrollmentMutation({
    onCompleted: (data) => {
      if (data.org_userConfirmMfaEnrollment.access_token) {
        successCallback(
          `${AuthenticationMethod[AuthenticationMethod.App]} based MFA successfully enabled!`,
          AuthenticationMethod.App,
        )
        resetModalOnExited()
        closeCallback()
      }
    },
    onError: (error) => {
      log.error('Enable OTP error: useOrg_userConfirmMfaEnrollmentMutation', error)
      setFailureAlertMessage(error.message)
      setShowFailureAlert(true)
    },
  })

  const onSubmit = (data: ConfirmOtpInput) => {
    if (data.authenticationcode === null) {
      setFailureAlertMessage('You must enter the code generated by the OTP app')
      setShowFailureAlert(true)
    } else {
      confirmOtp({
        variables: {
          confirmEnrollmentInput: {
            mfatoken: tokenVar,
            otpcode: data.authenticationcode,
            type: 'OTP',
          },
        },
      })
    }
  }

  useEffect(() => {
    // if we are viewing this modal and we just got a token get a QR code
    if (show && tokenVar !== '') {
      requestOtp()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenVar, show])

  return (
    <Dialog
      width="sm"
      open={show}
      id="enableotpdialog"
      onClose={() => {
        resetModalOnExited()
        closeCallback()
      }}
    >
      <DialogTitle>Multi-factor authentication</DialogTitle>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off" tw="w-full">
          <DialogContent tw="max-h-screen" dividers>
            {isLoading ? (
              <Spinner tw="m-auto mb-8 h-64 w-64" />
            ) : (
              <div tw="flex w-full flex-col mobile:px-1">
                {showFailureAlert && (
                  <Alert
                    tw="mb-3.5"
                    severity="error"
                    onClose={() => {
                      setShowFailureAlert(false)
                      setFailureAlertMessage('')
                    }}
                  >
                    {appendIfValid(
                      'Something went wrong when attempting to validate your authentication code',
                      failureAlertMessage,
                    )}
                  </Alert>
                )}
                <h2 tw="mb-6">App Authenticator</h2>
                <p tw="font-bold">1. Install an authenticator app.</p>
                <p>
                  A Cart.com supported authentication app (such as Google Authenticator or Microsoft
                  Authenticator) can be used to generate login codes that help confirm your identity
                  when you log in.
                </p>

                <p tw="mt-3 font-bold">2. Scan the QR code below.</p>
                <p tw="mb-0">
                  Scan the QR code using a Cart.com supported authenticator app on your mobile
                  device.
                </p>

                <img alt="QR Code" src={qrCodeDataUrl} tw="mx-auto block w-1/2" />

                <p>Can&apos;t scan the QR code? Use this key to configure the app:</p>
                <p tw="mb-6 font-bold">{authenticatorKey}</p>

                <strong tw="block">3. Enter the code generated by the authenticator app:</strong>

                <Input
                  id="authenticationcode"
                  placeholder="Enter authenticaton code *"
                  required
                  tw="mb-1"
                  type="text"
                />
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeCallback}>Cancel</Button>
            <Button
              disabled={loading}
              type="submit"
              onClick={() => setShowFailureAlert(false)}
              variant="contained"
            >
              Enable
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  )
}
