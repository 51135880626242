import { nanoid } from 'nanoid'
import React, { ReactElement } from 'react'

export const GrowthCapitalIcon = ({ className }: { className?: string }): ReactElement => {
  const title = 'Growth Capital Icon'
  const idA = nanoid()
  const idB = nanoid()
  const idC = nanoid()
  return (
    <svg
      aria-label={title}
      className={className}
      fill="none"
      role="img"
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${idC})`}>
        <path
          d="M22.3909 44.075L4.41453 26.0137C2.65751 24.2484 3.46045 21.2398 5.85982 20.6039L30.4203 13.9887C32.9898 13.2959 35.7197 14.0362 37.5996 15.9249L55.576 33.9861C57.333 35.7515 56.5301 38.7601 54.1307 39.396L29.5702 46.0112C27.0102 46.6945 24.2708 45.9637 22.3909 44.075Z"
          fill="#DA7009"
        />
        <path
          d="M25.8675 30.3417L7.89109 12.2804C6.13407 10.5151 6.93701 7.50647 9.33639 6.87057L33.8969 0.255385C36.4663 -0.437454 39.1963 0.30284 41.0761 2.19154L59.0525 20.2528C60.8096 22.0181 60.0066 25.0267 57.6073 25.6626L33.0467 32.2778C30.4773 32.9612 27.7473 32.2304 25.8675 30.3417Z"
          fill="#069AE3"
        />
        <path
          d="M18.9241 57.8085L0.947734 39.7472C-0.809288 37.9819 -0.00634828 34.9733 2.39303 34.3374L26.9535 27.7222C29.523 27.0293 32.253 27.7696 34.1328 29.6583L52.1092 47.7196C53.8662 49.4849 53.0633 52.4935 50.6639 53.1294L26.1034 59.7446C23.5434 60.4375 20.804 59.6972 18.9241 57.8085Z"
          fill="#069AE3"
        />
        <path
          d="M34.1318 29.6583C32.252 27.7696 29.5126 27.0388 26.9526 27.7222L10.5254 32.1449L22.3995 44.0751C24.2793 45.9638 27.0187 46.6946 29.5787 46.0112L46.0059 41.5884L34.1318 29.6583Z"
          fill={`url(#${idA})`}
        />
        <path
          d="M37.6006 15.925C35.7208 14.0363 32.9813 13.3055 30.4214 13.9888L13.9941 18.4116L25.8682 30.3417C27.748 32.2304 30.4875 32.9612 33.0474 32.2779L49.4747 27.8551L37.6006 15.925Z"
          fill={`url(#${idB})`}
        />
      </g>
      <defs>
        <linearGradient
          id={idA}
          x1="3.40222"
          y1="36.8667"
          x2="62.0229"
          y2="36.8667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DA7009" />
          <stop offset="1" stopColor="#069AE3" />
        </linearGradient>
        <linearGradient
          id={idB}
          x1="12.1982"
          y1="23.1333"
          x2="50.1983"
          y2="23.1333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#005982" />
          <stop offset="1" stopColor="#069AE3" />
        </linearGradient>
        <clipPath id={idC}>
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
