import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Combobox } from '@headlessui/react'
import tw, { css, styled, theme } from 'twin.macro'

import { IStyledComboboxInput } from './autocomplete.types'

export const StyledMenuLi = styled.li(() => [tw`mb-2`])

export const StyledComboboxInput = styled(Combobox.Input)<IStyledComboboxInput>(
  ({ $hasIcon, $hasError }) => [
    tw`[border-width: 1px] block w-full rounded-md border-monochrome-400 px-4 py-3.5 pr-10 text-sm placeholder-monochrome-600 hover:border-monochrome-black/80 disabled:bg-monochrome-50`,
    css`
      outline-color: ${theme`colors.primary.700`};
    `,
    $hasIcon && tw`pl-11`,
    $hasError && [
      tw`border-accent-two-700`,
      css`
        outline-color: ${theme`colors.accent-two.700`};
      `,
    ],
  ],
)

export const StyledComboboxOptions = styled(Combobox.Options)(() => [
  tw`absolute left-0 right-0 m-0 max-h-72 overflow-y-auto px-0 pb-2 text-xs text-monochrome-800`,
  tw`mt-1 mb-4 max-h-60 whitespace-nowrap rounded-md bg-white shadow-lg ring-monochrome-900 ring-opacity-5 focus:outline-none`,
  css`
    & li {
      ${tw`relative cursor-default select-none py-2 pl-10 pr-4 text-monochrome-700`}
      &.active {
        ${tw`bg-monochrome-50`}
      }
    }
  `,
])

export const StyledIcon = styled(FontAwesomeIcon)(() => [
  tw`pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-monochrome-700`,
])

export const StyledMessage = styled.p((props: any) => [
  tw`mt-1 text-xs text-monochrome-600`,
  props['data-haserror'] && tw`text-accent-two-700`,
])
