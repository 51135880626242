import * as React from 'react'
import { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import tw from 'twin.macro'

import { Link } from '../link'
import { StyledBreadcrumbs } from './breadcrumbs.styles'
import { BreadcrumbsProps } from './breadcrumbs.types'

export const Breadcrumbs: FC<BreadcrumbsProps> = (props) => {
  const { children, className, items } = props

  if (!children && !items) {
    throw new Error('Breadcrumbs must have either children or items')
  }

  return (
    <div role="presentation">
      <StyledBreadcrumbs
        className={className}
        aria-label="breadcrumb"
        {...props}
        tw="text-monochrome-900/60"
      >
        {children ||
          items?.map((item, index) => {
            const { text, href } = item
            const ItemAsComponent = text

            if (typeof text === 'function') {
              return <ItemAsComponent />
            }

            if (href) {
              return (
                <Link
                  to={href}
                  component={RouterLink as any}
                  key={text}
                  tw="!text-monochrome-900/60"
                  underline="hover"
                >
                  {text as string}
                </Link>
              )
            }

            return (
              <span
                key={text}
                css={[
                  index === items.length - 1 ? tw`text-monochrome-900` : tw`text-monochrome-900/60`,
                ]}
              >
                {text as string}
              </span>
            )
          })}
      </StyledBreadcrumbs>
    </div>
  )
}
