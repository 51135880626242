import { makeVar } from '@apollo/client'

export const recoveryCodeVar = makeVar('')
export const authenticatorIdToRemoveVar = makeVar('')
export const mfaTokenVar = makeVar('')

export enum AuthenticationMethod {
  'App',
  'SMS',
}
