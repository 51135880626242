import { useReactiveVar } from '@apollo/client'
import {
  activeBizVar,
  activeOrgVar,
  useUAActionDataConnectorMutation,
  useUApackageDataConnectorsListQuery,
} from '@brand-console/generated-graphql-hooks'
import { Spinner } from '@cart/ui'
import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'

import plugImage from '../../../assets/images/image_data_con.png'
import { SearchInput } from '../../../components/atoms/SearchInput/SearchInput'
import { CTAContainer } from '../../../components/molecules/CTAContainer/CTAContainer'
import { DeleteModal } from '../../../components/molecules/DeleteModal/DeleteModal'
import { DATA_CONNECTOR_CATEGORIES } from '../../../helpers/data.types'
import { SERVICE_CTA_DATA } from '../../../helpers/serviceCtaData'
import { useDataConnectorsData } from '../../../hooks'
import { useAppDispatch } from '../../../stores/hooks/hooks'
import { selectConnector, setCurrentView } from '../../../stores/reducers/dataConnectorsSlice'
import { useGetDataConnectorsQuery } from '../../../stores/services/dataConnectorEndpoints'
import { useDsMeQuery } from '../../../stores/services/userEndpoints'
import { MyConnectorsModal } from '../../MyConnectors/MyConnectorsModal/MyConnectorsModal'
import { CardsConnector } from './components/DataConnectorsModal/components/CardsConnector'
import { IncompleteConnectors } from './IncompleteConnectors'

type CategoryType = keyof typeof DATA_CONNECTOR_CATEGORIES | 'All Data Sources'

export const AllConnectors = () => {
  const activeBiz = useReactiveVar(activeBizVar)
  const activeOrg = useReactiveVar(activeOrgVar)
  const dispatch = useAppDispatch()
  const { dataConnectorsData } = useDataConnectorsData()
  const [showDataConnectorModal, setShowDataConnectorModal] = useState(false)
  const [filteredDataConnectors, setFilteredDataConnectors] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState('All Data Sources')
  const [searchString, setSearchString] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedConnector, setSelectedConnector] = useState([])

  useEffect(() => {
    if (filteredDataConnectors?.length !== dataConnectorsData?.length) {
      setFilteredDataConnectors(dataConnectorsData)
    }
  }, [dataConnectorsData])

  const organizationId = JSON.parse(localStorage.getItem('organization'))?.id
  const { data: user, isLoading: userLoading } = useDsMeQuery(organizationId)
  const {
    data: dataConnectors,
    isLoading: dataConnectorsLoading,
    isFetching: dataConnectorsFetching,
  } = useGetDataConnectorsQuery({ organizationId })

  const { data: packageDataConnectorsList, loading: packageDataConnectorsListLoading } =
    useUApackageDataConnectorsListQuery({
      variables: {
        businessId: activeBiz?.id.toString(),
      },
    })

  const [updateActionConnector, { loading: UpdateActionConnectorLoading }] =
    useUAActionDataConnectorMutation()
  const isLoading =
    packageDataConnectorsListLoading ||
    dataConnectorsLoading ||
    (!showDataConnectorModal && dataConnectorsFetching) ||
    UpdateActionConnectorLoading ||
    userLoading

  const dataConnectorCategories: string[] = ['All Data Sources']
  if (dataConnectors?.data) {
    Object.keys(dataConnectors?.data).forEach((category: CategoryType) => {
      // NOTE: once we have cart-specific data connectors, this check should be removed
      if (category !== 'Cart.com') {
        dataConnectorCategories.push(category)
      }
    })
  }
  const onSearchIconClick = (input) => {
    setSelectedCategory('All Data Sources')
    setFilteredDataConnectors([])
    const filtered = dataConnectorsData.filter((connector) => {
      return connector.title.toLowerCase().includes(input.toLowerCase())
    })
    setFilteredDataConnectors(filtered)
  }

  const setChosenAndShowDeleteModal = (value) => {
    setSelectedConnector([{ id: value.id, dataConnectorType: value.dataConnectorType }])
    setShowDeleteModal(true)
  }

  const onCategorySelect = (category: string) => {
    setSelectedCategory(category)
    if (category === 'All Data Sources') {
      setFilteredDataConnectors(dataConnectorsData)
      return
    }

    const dataConnectorsByCategory = dataConnectorsData.filter(
      (dataConnector) => dataConnector.category === DATA_CONNECTOR_CATEGORIES[category],
    )

    setFilteredDataConnectors(dataConnectorsByCategory)
  }

  const handleClose = () => {
    dispatch(setCurrentView(1))
    dispatch(selectConnector(undefined))
    setShowDataConnectorModal(false)
  }

  if (isLoading) {
    return <Spinner tw="m-auto h-64 w-64" type="global" />
  }

  return (
    <>
      {user?.data.attributes.flags.marketing_analytics_flag ? (
        <div data-testid="all-connectors-container" tw="flex w-full flex-col max-w-[1400px]">
          <h1 tw="mb-0 text-3xl [margin-top:24px]">All Connectors</h1>
          <IncompleteConnectors
            setShow={setShowDataConnectorModal}
            setChosenAndShowDeleteModal={setChosenAndShowDeleteModal}
          />
          <section tw="mt-7 flex flex-col gap-8 md:flex-row">
            <div tw="md:w-64">
              <h5 tw="mb-4 overflow-hidden text-ellipsis text-xs text-monochrome-500 uppercase leading-none">
                Categories
              </h5>
              {dataConnectorCategories.map(
                (category) =>
                  category && (
                    <button
                      type="button"
                      key={category}
                      onKeyPress={() => onCategorySelect(category)}
                      onClick={() => onCategorySelect(category)}
                      css={[
                        tw`block w-full px-4 py-3 text-left text-monochrome-700 [border-radius:0.5rem] hover:(bg-monochrome-50 text-monochrome-900)`,
                        category === selectedCategory &&
                          tw`bg-monochrome-200 text-primary-700 hover:bg-monochrome-200`,
                      ]}
                    >
                      {category}
                    </button>
                  ),
              )}
            </div>
            <div tw="md:w-3/4 lg:w-[80%] xl:w-4/5">
              <SearchInput
                onChange={(e: string) => {
                  onSearchIconClick(e)
                  setSearchString(e)
                }}
                placeholder="Search by name..."
                id="searchAllConnectors"
                onSearchIconClick={(value: string | undefined) =>
                  onSearchIconClick(value === undefined ? searchString : value)
                }
                value={searchString}
              />
              <CardsConnector
                filteredSet={filteredDataConnectors}
                setShow={setShowDataConnectorModal}
                packageTier={
                  packageDataConnectorsList?.ua_packagedataConnectorsList?.packageType?.key
                }
                packageDataConnectorsList={
                  packageDataConnectorsList?.ua_packagedataConnectorsList?.dataConnectorTypes
                }
              />
            </div>
          </section>
        </div>
      ) : (
        <div data-testid="all-connectors-cta-container" tw="relative w-full overflow-hidden">
          <img
            src={plugImage}
            alt="call to action"
            tw="w-full object-cover shadow-sm brightness-50 scale-[1.01] blur-[2px] h-[80vh]"
          />
          <CTAContainer data={SERVICE_CTA_DATA.marketing_analytics_flag} />
        </div>
      )}
      {/* Needed for potential Incomplete connector deletion */}
      <DeleteModal
        isOpen={showDeleteModal}
        setIsOpen={setShowDeleteModal}
        deleteCallback={() =>
          updateActionConnector({
            variables: {
              action: 'delete',
              dataConnectorType: selectedConnector[0].dataConnectorType,
              dataConnectorId: Number(selectedConnector[0].id),
              organizationId: activeOrg?.id.toString(),
            },
          })
        }
        title="Please confirm"
        describe={`Are you sure you want to delete this Data Connector${
          selectedConnector.length > 1 ? 's' : ''
        }?`}
        width={639}
      />
      <MyConnectorsModal
        packageTier={packageDataConnectorsList?.ua_packagedataConnectorsList?.packageType?.key}
        packageDataConnectorsList={
          packageDataConnectorsList?.ua_packagedataConnectorsList?.dataConnectorTypes
        }
        show={showDataConnectorModal}
        onClose={() => handleClose()}
      />
    </>
  )
}
