import { callbackParamsVar, callbackRedirectUriVar } from '@brand-console/generated-graphql-hooks'
import { useRouter } from '@cart/ui'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import { useEffect } from 'react'

export const SFLoginSignUpRoutes = () => {
  const { pathname, navigate, location } = useRouter()

  useEffect(() => {
    const screenHint = pathname.substring(1)
    const searchParams = new URLSearchParams(location.search)
    searchParams.append('mode', 'modal')
    searchParams.toString()

    const redirectUri = `/storefront?${searchParams}`

    const queryParams = omitBy(
      {
        _ga: searchParams.get('_ga'),
        fbclid: searchParams.get('fbclid'),
        gclid: searchParams.get('gclid'),
        login_hint: searchParams.get('login_hint'),
        utm_campaign: searchParams.get('utm_campaign'),
        utm_content: searchParams.get('utm_content'),
        utm_id: searchParams.get('utm_id'),
        utm_medium: searchParams.get('utm_medium'),
        utm_source: searchParams.get('utm_source'),
        utm_term: searchParams.get('utm_term'),
        inviteToken: searchParams.get('inviteToken'),
        screen_hint: screenHint,
        app: 'sf',
      },
      isNil,
    )

    callbackRedirectUriVar(redirectUri)
    callbackParamsVar(queryParams)
    navigate('/callback')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return null
}
