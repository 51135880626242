import {
  BusinessShowDocument,
  useBusinessNameMutation,
  useBusinessShowQuery,
} from '@brand-console/generated-graphql-hooks'
import { Button, Card, CardContent, Input, Spinner, useRouter } from '@cart/ui'
import { isAtLeastBizAdmin, useCurrentContext, useOrganization } from '@cartdotcom/auth'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MUICardHeader from '@mui/material/CardHeader'
import { format } from 'date-fns'
import React, { FC, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { SettingsOrganizationCardHeader } from '../../SettingsOrganizations/SettingsOrganization.partials'
import { useSettingsOrganizationsContext } from '../../SettingsOrganizations/SettingsOrganizations.store'

export const BusinessOverview: FC = () => {
  const router = useRouter()
  const { currentOrganization, roleName } = useCurrentContext()
  const { updateCurrentBusiness } = useOrganization()
  const isBusinessAdminOrAbove = isAtLeastBizAdmin(roleName)
  const { businessEntityTerminology = 'Business' } = currentOrganization
  const { setAlert } = useSettingsOrganizationsContext()

  const {
    query: { id },
  } = router

  const businessId = parseInt(id as string, 10)

  const {
    data: showBusinessData,
    loading: showBusinessLoading,
    error: showBusinessError,
  } = useBusinessShowQuery({
    variables: { orgBusinessShowId: businessId },
  })

  const methods = useForm({
    defaultValues: { businessName: showBusinessData.org_businessShow.name },
  })
  const { handleSubmit } = methods

  const [
    mutateBusinessNameFunc,
    { loading: mutateBusinessNameLoading, error: mutateBusinessNameError },
  ] = useBusinessNameMutation({ refetchQueries: [BusinessShowDocument, 'org_businessShow'] })

  const onSubmit = (data) => {
    if (data.businessName !== showBusinessData.org_businessShow.name) {
      const updateBusinessInput = {
        id: businessId,
        name: data.businessName,
      }
      mutateBusinessNameFunc({
        variables: {
          updateBusinessInput,
        },
        onCompleted: () => {
          setAlert({
            variant: 'success',
            text: `${businessEntityTerminology} name was changed successfully!`,
          })

          // TODO: This should change any business not just the current one
          updateCurrentBusiness({ name: data.businessName })
          window.location.reload()
        },
      })
    }
  }

  useEffect(() => {
    if (showBusinessError) {
      setAlert({
        variant: 'error',
        text: `Something went wrong while attempting to retrieve the business information: ${showBusinessError.message}`,
      })
    }
    if (mutateBusinessNameError) {
      setAlert({
        variant: 'error',
        text: `Something went wrong while attempting to update the business information: ${mutateBusinessNameError.message}`,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showBusinessError, mutateBusinessNameError])

  if (showBusinessLoading) {
    return <Spinner tw="m-auto h-64 w-64" />
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <div tw="mb-2 flex items-start justify-between">
          <div>
            <div tw="flex items-center gap-4">
              <Link to="/settings/organization" tw="leading-none">
                <FontAwesomeIcon tw="h-6 w-6 text-monochrome-600" icon={solid('arrow-left')} />
              </Link>
              <h1 tw="text-[24px]">{showBusinessData?.org_businessShow.name}</h1>
            </div>
            <div tw="mb-7 flex w-fit flex-wrap gap-4">
              <div>
                <strong>Business ID</strong> {showBusinessData?.org_businessShow.id}
              </div>
              <div>
                <strong>Organization</strong> {currentOrganization.name}
              </div>
              <div>
                <strong>Created on</strong>{' '}
                {format(new Date(showBusinessData?.org_businessShow.createdAt), 'MMM d, yyyy')}
              </div>
            </div>
          </div>

          {isBusinessAdminOrAbove && (
            <Button
              type="submit"
              disabled={!methods.formState.isDirty}
              variant="contained"
              loading={mutateBusinessNameLoading}
            >
              Save
            </Button>
          )}
        </div>
        <Card tw="mb-8" title="Overview">
          {/* somehow our card header won't let us use a custom component */}
          <MUICardHeader component={() => SettingsOrganizationCardHeader({ text: 'Overview' })} />
          <CardContent tw="border-t-2 border-t-monochrome-200">
            <Input
              id="businessName"
              name="businessName"
              label="Business Name"
              disabled={!isBusinessAdminOrAbove}
            />
          </CardContent>
        </Card>
      </form>
    </FormProvider>
  )
}
