import { useEffect, useState } from 'react'

export const useDfwNavigation = () => {
  const [link, setLink] = useState<string | null>(null)
  const [onClick, setOnClick] = useState<(() => void) | null>(null)

  useEffect(() => {
    const url = `${process.env.NX_DFW_AUTHENTICATE_API_URL}?provider=cart_openid`
    setOnClick(() => () => {
      window.location.href = url
    })
    setLink(url)
  }, [])

  return { link, onClick }
}
