import iconFulfillment from '../assets/icons/fulfillment_icon.svg'
import iconMarketing from '../assets/icons/marketing_icon.svg'
import iconOnline from '../assets/icons/online_icon.svg'
import iconWeb from '../assets/icons/web_icon.svg'
import fulfillment_1 from '../assets/images/fulff_1.png'
import fulfillment_2 from '../assets/images/fulff_2.png'
import fulfillment_3 from '../assets/images/fulff_3.png'
import inventory_1 from '../assets/images/inventory_1.png'
import inventory_2 from '../assets/images/inventory_2.png'
import inventory_3 from '../assets/images/inventory_3.png'
import marketing_1 from '../assets/images/marketing_1.png'
import marketing_2 from '../assets/images/marketing_2.png'
import marketing_3 from '../assets/images/marketing_3.png'
import sale_1 from '../assets/images/sale_1.png'
import sale_2 from '../assets/images/sale_2.png'
import sale_3 from '../assets/images/sale_3.png'

export const SERVICE_CTA_DATA = {
  viz_enabled: {
    prompt:
      'Data Health ensures accuracy in Unified Analytics by validating data sources, dates & KPIs. This allows you to track any errors & reconnect to data as needed.',
  },
  online_store_flag: {
    common_icon: iconOnline,
    header: 'Online Store',
    generalTitle: ['Store Analytics', 'Online Store'],
    text: [
      'Investigate consolidated sales and orders to...',
      'Build a brand new online store or migrate your existing one',
    ],
    link: 'https://www.cart.com/contact/sales?utm_source=Hero_CTA&utm_medium=direct&utm_campaign=UA&utm_id=UA_Feature&utm_term=Book+a+Demo',
    isLocal: false,
    list: [
      'Analyze revenue performance across products ',
      'Review drivers of average order value',
      'Uncover emerging product sales trends',
    ],
    linkText: 'Learn More',
    widgets: [
      {
        id: 1,
        title: 'Sale_1',
        image: sale_1,
      },
      {
        id: 2,
        title: 'Sale_2',
        image: sale_2,
      },
      {
        id: 3,
        title: 'Sale_3',
        image: sale_3,
      },
    ],
    prompt: 'Analyzing your sales trends supports monthly business planning and forecasting',
  },
  inventory_flag: {
    common_icon: iconWeb,
    header: 'Web Analytics',
    generalTitle: ['Web Analytics', 'Marketing Services'],
    text: [
      'Migrate your online store to Cart.com so we can provide advanced conversion analysis and increase your sales (trust us, we make it easy!). Our proprietary pixel trackers, web crawlers, and Insider sources enable one-click insights for how your online conversion could improve.',
      'Expert digital marketing services for omnichannel sales growth',
    ],
    link: 'https://www.cart.com/contact/sales?utm_source=Hero_CTA&utm_medium=direct&utm_campaign=UA&utm_id=UA_Feature&utm_term=Book+a+Demo',
    isLocal: false,
    list: [],
    linkText: 'Learn More',
    widgets: [
      {
        id: 1,
        title: 'Marketing_1',
        image: marketing_1,
      },
      {
        id: 2,
        title: 'Marketing_2',
        image: marketing_2,
      },
      {
        id: 3,
        title: 'Marketing_3',
        image: marketing_3,
      },
    ],
    prompt: 'Understanding drivers of online store conversions increases your net sales',
  },
  web_analytics_flag: {
    common_icon: iconWeb,
    header: 'Web Analytics',
    generalTitle: ['Web Analytics', 'Marketing Services'],
    text: [
      'Migrate your online store to Cart.com so we can provide advanced conversion analysis and increase your sales (trust us, we make it easy!). Our proprietary pixel trackers, web crawlers, and Insider sources enable one-click insights for how your online conversion could improve.',
      'Expert digital marketing services for omnichannel sales growth',
    ],
    link: 'https://www.cart.com/contact/sales?utm_source=Hero_CTA&utm_medium=direct&utm_campaign=UA&utm_id=UA_Feature&utm_term=Book+a+Demo',
    isLocal: false,
    list: [],
    linkText: 'Learn More',
    widgets: [
      {
        id: 1,
        title: 'Marketing_1',
        image: marketing_1,
      },
      {
        id: 2,
        title: 'Marketing_2',
        image: marketing_2,
      },
      {
        id: 3,
        title: 'Marketing_3',
        image: marketing_3,
      },
    ],
    prompt: 'Understanding drivers of online store conversions increases your net sales',
  },
  marketing_analytics_flag: {
    header: 'Marketing Intelligence',
    common_icon: iconMarketing,
    generalTitle: ['Marketing Intelligence', 'Add Connectors'],
    text: [
      'Connect to Google Ads, Social Ads, and Email to...',
      'Integrate advertising and campaign data from marketing sources',
    ],
    link: 'https://www.cart.com/contact/sales?utm_source=Hero_CTA&utm_medium=direct&utm_campaign=UA&utm_id=UA_Feature&utm_term=Book+a+Demo',
    isLocal: false,
    list: [
      'Analyze channel results across campaigns',
      'Track campaign pacing to adjust marketing strategy',
      'Review campaign performance against KPIs',
    ],
    linkText: 'Learn More',
    widgets: [
      {
        id: 1,
        title: 'Inventory_1',
        image: inventory_1,
      },
      {
        id: 2,
        title: 'Inventory_2',
        image: inventory_2,
      },
      {
        id: 3,
        title: 'Inventory_3',
        image: inventory_3,
      },
    ],
    prompt:
      'Analyzing the impact of your campaigns will improve chances of increased customer acquisition',
  },
  fulfillment_customer_flag: {
    header: 'Order Fulfillment',
    common_icon: iconFulfillment,
    generalTitle: ['Inventory Analytics', 'Order Fulfillment'],
    text: [
      "Let Cart.com fulfill your orders with the same ecommerce infrastructure and national footprint as some of the world's largest retailers. Then, utilize our aging, cycle-time, and demand forecasting tools to optimize your storage costs without risking lost sales. ",
      'Ship products faster with our nationwide fulfillment network',
    ],
    link: '/fulfillment',
    isLocal: true,
    list: [],
    linkText: 'Learn More',
    widgets: [
      {
        id: 1,
        title: 'Fulfillment_1',
        image: fulfillment_1,
      },
      {
        id: 2,
        title: 'Fulfillment_2',
        image: fulfillment_2,
      },
      {
        id: 3,
        title: 'Fulfillment_3',
        image: fulfillment_3,
      },
    ],
    prompt: 'Knowing inventory trends and shipping costs support business cost planning',
  },
  attribution_ai_flag: {
    common_icon: iconOnline,
    header: 'Forecast Pacing',
    generalTitle: ['Forecast Pacing', 'Optimize Your Performance'],
    text: [
      'Gain insights into your campaign performance with precise pacing data. Our forecasting analytics allow you to stay on track and meet your goals.',
      'Stay on track with actual vs. goals analytics',
    ],
    link: 'https://www.cart.com/contact/sales?utm_source=Hero_CTA&utm_medium=direct&utm_campaign=UA&utm_id=UA_Feature&utm_term=Book+a+Demo',
    isLocal: false,
    list: [],
    linkText: 'Learn More',
    widgets: [
      {
        id: 1,
        title: 'Sale_1',
        image: sale_1,
      },
      {
        id: 2,
        title: 'Sale_2',
        image: sale_2,
      },
      {
        id: 3,
        title: 'Sale_3',
        image: sale_3,
      },
    ],
    prompt: 'Analyzing your sales trends supports monthly business planning and forecasting',
  },

  default: {
    common_icon: iconWeb,
    header: 'Web Analytics',
    generalTitle: ['Web Analytics', 'Marketing Services'],
    text: [
      'Migrate your online store to Cart.com so we can provide advanced conversion analysis and increase your sales (trust us, we make it easy!). Our proprietary pixel trackers, web crawlers, and Insider sources enable one-click insights for how your online conversion could improve.',
      'Expert digital marketing services for omnichannel sales growth',
    ],
    link: 'https://www.cart.com/contact/sales?utm_source=Hero_CTA&utm_medium=direct&utm_campaign=UA&utm_id=UA_Feature&utm_term=Book+a+Demo',
    isLocal: false,
    list: [],
    linkText: 'Learn More',
    widgets: [
      {
        id: 1,
        title: 'Marketing_1',
        image: marketing_1,
      },
      {
        id: 2,
        title: 'Marketing_2',
        image: marketing_2,
      },
      {
        id: 3,
        title: 'Marketing_3',
        image: marketing_3,
      },
    ],
    prompt: 'Understanding drivers of online store conversions increases your net sales',
  },
}
