import tw, { styled } from 'twin.macro'

import { StatusBadgeProps } from './status-badge.types'

const handleBadgeColor = (type = 'default') => {
  switch (type) {
    case 'primary':
      return tw`bg-primary-50 text-primary-900`
    case 'secondary':
      return tw`bg-secondary-50 text-secondary-900`
    case 'error':
      return tw`bg-accent-two-50 text-accent-two-900`
    case 'warning':
      return tw`bg-accent-five-50 text-accent-five-900`
    case 'info':
      return tw`bg-accent-one-50 text-accent-one-900`
    case 'success':
      return tw`bg-accent-three-50 text-accent-three-900`
    default:
      return tw`bg-monochrome-200 text-monochrome-900`
  }
}

export const StyledStatusBadge = styled.div(({ type }: Partial<StatusBadgeProps>) => [
  tw`inline-block whitespace-nowrap rounded-full px-2 text-xs`,
  handleBadgeColor(type),
])
