import { getHubspotCookie } from '../getHubspotCookie'
import { fetchAbstract } from './hubspotFormSubmit.functions'
import {
  HubspotFormBody,
  HubspotFormFieldsPayload,
  HubspotFormResponse,
  HubspotFormSubmitProps,
} from './hubspotFormSubmit.types'

/**
 * Submits a form to Hubspot
 *
 * @param {Object} data - The payload
 * @param {string} formId - The unique ID of the form you're sending data to
 * @param {string} [pageName=document.title] - The name or title of the page the submission happened on
 * @param {string} [pageUri=window.location.href] - The URI of the page the submission happened on
 * @param {number} portalId - The ID of the portal in Hubspot
 *
 * @returns {Promise<HubspotFormResponse>} - A promise that resolves to the response from Hubspot
 */
export const hubspotFormSubmit = async ({
  data,
  formId,
  pageName = document.title,
  pageUri = window.location.href,
  portalId,
}: HubspotFormSubmitProps): Promise<HubspotFormResponse> => {
  const hutk = getHubspotCookie()
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`

  const fields: HubspotFormFieldsPayload[] = []
  new Map(Object.entries(data)).forEach((value, key) => {
    if (!!key && !!value) {
      if (typeof value === 'object') {
        new Map(Object.entries(value)).forEach(($value) => {
          fields.push({
            objectTypeId: '0-1',
            name: key,
            value: $value as string,
          })
        })
      } else {
        fields.push({
          objectTypeId: '0-1',
          name: key,
          value,
        })
      }
    }
  })

  const body: HubspotFormBody = {
    fields,
    context: {
      ...(hutk
        ? ({
            hutk,
          } as const)
        : []),
      pageName,
      pageUri,
    },
  }

  const obj = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }

  let response = await fetchAbstract(url, obj)

  if (response.status === 'error') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const hasHsTokenError = (response.errors as any[]).some(
      (error) => error.errorType === 'INVALID_HUTK',
    )
    if (hasHsTokenError) {
      delete body.context.hutk
      obj.body = JSON.stringify(body)
      response = await fetchAbstract(url, obj)
    }
  }

  return new Promise((resolve, reject) => {
    if (response.status === 'error') {
      reject(response)
    } else {
      resolve(response)
    }
  })
}
