export interface IsStorefrontEnabledProps {
  businessId: number
  token: string
  user: { auth0Id: string | undefined; email: string | undefined }
}
/**
 * Checks if the user has access to Storefront
 */
export const isStorefrontEnabled = async ({
  businessId,
  token,
  user,
}: IsStorefrontEnabledProps): Promise<boolean> => {
  const httpHeaders = {
    Authorization: `Bearer ${token}`,
    'x-cartid-sub': user.auth0Id,
    'x-cartid-email': user.email,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  try {
    const response = await fetch(`${process.env.NX_MYSTORES_API_URL}`, {
      method: 'GET',
      headers: httpHeaders,
    })
    const sfAccountsResponse = await response.json()
    const provisioningAccounts =
      sfAccountsResponse?.result?.length > 0
        ? sfAccountsResponse?.result.flatMap((r) => r.provisioningAccounts)
        : []

    const provisioningAccount = provisioningAccounts.find((pa) => pa.cartBusinessId === businessId)
    if (sfAccountsResponse.success && provisioningAccounts.length > 0 && provisioningAccount) {
      return true
    }
  } catch (error) {
    return false
  }
  return false
}
