import { TextField } from '@mui/material'
import tw, { css, styled } from 'twin.macro'

export const StyledTextField = styled(TextField)(({ type }) => [
  type === 'hidden' && tw`hidden`,
  css`
    & input {
      ${tw`indent-1 text-sm`}
    }
    & .MuiInputLabel-root:not(.MuiInputLabel-shrink) {
      ${tw`text-sm`}
    }
  `,
])
