import { ProductType } from '@brand-console/types'
import { create } from 'zustand'

import { FetchSfAccountResponse } from '../queries/fetchSfAccount'

interface LayoutStore {
  logoutUrl?: string
  product?: ProductType
  token?: string
  fulfillmentProvisioned: boolean
  sfAccount?: FetchSfAccountResponse
  setSfAccount: (sfAccount: FetchSfAccountResponse) => void
  setLogoutUrl: (logoutUrl: string) => void
  setProduct: (product: ProductType) => void
  setToken: (token: string) => void
  setFulfillmentProvisioned: (fulfillmentProvisioned: boolean) => void
}

export const useLayoutStore = create<LayoutStore>((set) => ({
  logoutUrl: undefined,
  product: undefined,
  token: undefined,
  fulfillmentProvisioned: false,
  sfAccount: undefined,
  setSfAccount: (sfAccount) => set(() => ({ sfAccount })),
  setLogoutUrl: (logoutUrl) => set(() => ({ logoutUrl })),
  setProduct: (product) => set(() => ({ product })),
  setToken: (token) => set(() => ({ token })),
  setFulfillmentProvisioned: (fulfillmentProvisioned) => set(() => ({ fulfillmentProvisioned })),
}))
