import React, { ReactElement } from 'react'
import tw, { theme } from 'twin.macro'

import { CartLogo } from '../../icons'
import { SpinnerProps } from './spinner.types'

const SpinnerSVG = ({ color, ...rest }: { color?: string }): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-0.5 -0.5 51 51" {...rest}>
      <path
        fill={color}
        d="M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="1.5s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  )
}

export const Spinner = ({
  color = theme`colors.primary.700`,
  type = 'inline',
  logo = false,
  ...rest
}: SpinnerProps): ReactElement => {
  return (
    <div
      role="progressbar"
      css={[
        tw`m-auto max-w-xs`,
        type === 'global' && tw`absolute inset-0`,
        type === 'inline' && tw`relative h-full`,
      ]}
      {...rest}
      data-testid="spinner"
    >
      <SpinnerSVG color={color} tw="relative top-0 left-0 h-full w-full" />
      {logo && (
        <CartLogo tw="absolute top-0 left-0 h-full w-full [filter:drop-shadow(0px 4px 4px rgba(0,0,0,.5))]" />
      )}
    </div>
  )
}

export default Spinner
