import { AddToCart } from '../AddToCart'
import { ASINSearch } from '../ASINSearch'
import { BuyTogether } from '../BuyTogether'
import { CanonicalUrlBuilder } from '../CanonicalUrlBuilder'
import { TwoStepViaBrand } from '../TwoStepViaBrand'
import { TwoStepViaSeller } from '../TwoStepViaSeller'

export const ROUTES = [
  {
    element: <CanonicalUrlBuilder />,
    label: 'Canonical URL',
    path: '/free-tools/amazon-url-builder/canonical-url',
  },
  {
    element: <AddToCart />,
    label: 'Add to Cart',
    path: '/free-tools/amazon-url-builder/add-to-cart',
  },
  {
    element: <BuyTogether />,
    label: 'Buy Together',
    path: '/free-tools/amazon-url-builder/buy-together',
  },
  {
    element: <ASINSearch />,
    label: 'ASIN Search',
    path: '/free-tools/amazon-url-builder/asin-search',
  },
  {
    element: <TwoStepViaBrand />,
    label: 'Brand',
    path: '/free-tools/amazon-url-builder/brand',
  },
  {
    element: <TwoStepViaSeller />,
    label: 'Seller',
    path: '/free-tools/amazon-url-builder/seller',
  },
]
