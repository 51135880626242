import { useSearchParams } from 'react-router-dom'

export const useParamState = () => {
  const [urlParams] = useSearchParams()

  const mode = urlParams.get('mode')
  return {
    isMcmNav: urlParams.get('nav') === 'mcm',
    navParam: urlParams.get('nav'),
    isConstellation: urlParams.get('constellation') === 'true',
    businessId: urlParams.get('businessId'),
    tenantCode: urlParams.get('tenantCode'),
    displayMode: mode === 'modal' ? mode : null,
  } as const
}
