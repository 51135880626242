import MuiLink from '@mui/material/Link'
import React, { FC } from 'react'

import { LinkProps } from './link.types'

export const Link: FC<LinkProps> = ({ children, className, ...props }) => {
  const { href } = props
  let component

  if (!href) {
    component = 'button'
  }

  return (
    <MuiLink component={component} className={className} {...props}>
      {children}
    </MuiLink>
  )
}
