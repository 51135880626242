import queryString from 'query-string'
import { useCallback, useMemo } from 'react'
import { To, useLocation, useNavigate, useParams } from 'react-router-dom'

import { useParamState } from '../useParamState'

const includesParam = (url: To | number, param: string) => {
  if (typeof url === 'number') return false

  const search = url.search?.toString()
  if (search) {
    const searchParams = new URLSearchParams(search)
    return searchParams.has(param)
  }

  return false
}

/**
 * useRouter is a hook that allows you to access the React Router state in your components.
 * Source: https://usehooks.com/useRouter/
 */
export const useRouter = () => {
  const { isMcmNav } = useParamState()
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const interceptedNavigate: typeof navigate = useCallback(
    (url, ...args) => {
      let updatedUrl = url
      const paramsToAdd = [...(isMcmNav && !includesParam(url, 'nav') ? ['nav=mcm'] : [])]
      if (paramsToAdd.length && typeof updatedUrl !== 'number') {
        updatedUrl += `${updatedUrl.toString().includes('?') ? '&' : '?'}${paramsToAdd.join('&')}`
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      navigate(updatedUrl, ...args)
      window.scrollTo(0, 0)
    },
    [isMcmNav, navigate],
  )

  return useMemo(() => {
    return {
      navigate: interceptedNavigate,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
      location,
    }
  }, [interceptedNavigate, location, params])
}
