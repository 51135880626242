import { useAuth0 } from '@auth0/auth0-react'
import { useBreakpoint } from '@cart/ui/hooks/useBreakpoint'
import React, { useEffect, useState } from 'react'
import { createGlobalStyle, css } from 'styled-components'
import tw from 'twin.macro'

import { SideMenuTenant } from '../../../gql/graphql'
import { useMenuItemData } from '../../hooks/useMenuItemData'
import { logError } from '../../logger/logger'
import { useUnifiedSidebarStore } from '../../store/unified-sidebar.store'
import { Header } from '../header/Header'
import { MenuItems } from '../menu-items/Menutems'
import { TenantInfo } from '../tenant-info/TenantInfo'
import { UserInfo } from '../user-info/UserInfo'
import { getTenantByTenantCode } from './UnifiedSidebar.functions'
import { UnifiedSidebarSkeleton } from './UnifiedSidebar.partials'

interface IUnifiedSidebarComponentProps {
  dfwUrl: string
  jazzUrl: string
  mcmUrl: string
  bcUrl: string
  spa: boolean
  unsUrl: string
  authMethod?: string
}

enum AuthMethods {
  AUTH0 = 'auth0',
  JAZZ = 'jazz',
}

const cookies = decodeURIComponent(document.cookie).split(';')

const jazzUnsOidcTokenCookie = cookies.find((cookie) =>
  cookie.trim().startsWith('jazz_uns_oidc_token='),
)
const jazzUnsOidcToken = jazzUnsOidcTokenCookie?.split('=')[1]

const CustomStyles = createGlobalStyle`
  @media (display-mode: standalone) {
    [data-cartid="app-container"] {
      padding-top: 3rem;
    }
  }
`

export const UnifiedSidebar: React.FC<IUnifiedSidebarComponentProps> = ({
  dfwUrl,
  jazzUrl,
  mcmUrl,
  bcUrl,
  spa,
  unsUrl,
  authMethod,
}) => {
  const {
    currentBusinessId,
    currentTenant,
    currentTenantCode,
    isMenuExpanded,
    logoutUrl,
    menuItemData,
    searchUrl,
    tenantsList,
    setAuthHeader,
    setCurrentTenant,
    setIsMenuExpanded,
    setIsSpa,
    setLogoutUrl,
    setMenuItemData,
    setSearchUrl,
    setTenantsList,
  } = useUnifiedSidebarStore()

  const { getAccessTokenSilently } = useAuth0()
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false)
  const isMobile = !useBreakpoint('sm')

  const handleResetHeader = async () => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.NX_AUTH0_AUDIENCE,
      },
    })
    setAuthHeader(`Bearer ${token}`)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const retryFn = (failureCount: number, error: any): boolean => {
    if (error?.response?.error?.includes('token')) {
      const { host } = window.location
      // TODO: Replace check with `product === 'jazz'` once the Jazz side correcly passes the attribute
      if (host.includes('piano') || host.includes('jazz-oms')) {
        document.cookie = `jazz_uns_oidc_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
        const currentUrl = window.location.href
        const newURL =
          currentUrl !== jazzUrl
            ? `${jazzUrl}oidc/authenticate/?redirectUri=${encodeURIComponent(currentUrl)}`
            : `${jazzUrl}oidc/authenticate/`
        window.location.href = newURL
      } else if (authMethod === AuthMethods.AUTH0) {
        // Must not await!
        // The react query expects a synchronous return value
        handleResetHeader()
      }
    }

    return failureCount < 6
  }

  const { data, isLoading, error, refetch } = useMenuItemData({
    unsUrl,
    dfwUrl,
    jazzUrl,
    mcmUrl,
    bcUrl,
    retryFn,
  })

  const userDataNeedsRefetch = menuItemData?.user.username !== data?.getSideMenu?.user.username

  useEffect(() => {
    setIsMenuExpanded(!isMobile)
  }, [isMobile, setIsMenuExpanded])

  useEffect(() => {
    setIsSpa(spa)
  }, [setIsSpa, spa])

  const toggleExpanded = async () => {
    setIsMenuExpanded(!isMenuExpanded)
    setIsTransitioning(true)
    await new Promise((resolve) => {
      setTimeout(resolve, 300)
    })
    setIsTransitioning(false)
  }

  useEffect(() => {
    async function setAuthHeaderState() {
      try {
        if (authMethod === AuthMethods.JAZZ) {
          setAuthHeader('Session jazz')
        } else if (jazzUnsOidcToken) {
          setAuthHeader(`Bearer ${jazzUnsOidcToken}`)
        } else if (authMethod === AuthMethods.AUTH0) {
          await handleResetHeader()
        }
      } catch (e) {
        logError(e)
      }
    }
    setAuthHeaderState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authMethod, getAccessTokenSilently, setAuthHeader])

  useEffect(() => {
    if (data && !isLoading && !error) {
      const { getSideMenu } = data
      const { tenants } = getSideMenu

      // Logout URL is set only if not provided by the user
      if (!logoutUrl) {
        setLogoutUrl(getSideMenu.logoutUrl)
      }

      // Search URL is set only if not provided by the user
      if (!searchUrl) {
        setSearchUrl(getSideMenu.searchUrl)
      }

      const jazzRootTenant = tenants.find((tenant) => tenant?.tenantCode === 'fbflurry')
      const $tenants = jazzRootTenant ? jazzRootTenant.nodes : tenants

      const isInvalidTenant =
        !currentTenant?.tenantCode && !currentTenant?.businessId && !currentTenant?.organizationId

      const hasTenantChanged =
        currentTenant?.businessId !== currentBusinessId ||
        currentTenantCode !== currentTenant?.tenantCode

      if ($tenants?.length === 1) {
        setCurrentTenant({
          tenantCode: $tenants[0].tenantCode,
          name: $tenants[0].name,
          businessId: $tenants[0].businessId,
          organizationId: $tenants[0].organizationId,
          nodes: null,
        })
      } else if ($tenants?.length > 1 && (isInvalidTenant || hasTenantChanged)) {
        const tenantFromService = getTenantByTenantCode($tenants, {
          tenantCode: currentTenantCode,
          name: null,
          businessId: currentBusinessId,
          organizationId: null,
        })
        if (tenantFromService) {
          const parentTenant = tenantFromService.parent as unknown as SideMenuTenant
          // If the tenant has no parent, it is a top level tenant (under fbflurry)
          if (Array.isArray(parentTenant)) {
            setCurrentTenant({
              tenantCode: tenantFromService.value.tenantCode,
              name: tenantFromService.value.name,
              businessId: null,
              organizationId: null,
            })
          } else {
            setCurrentTenant(
              tenantFromService.parent
                ? {
                    tenantCode: parentTenant.tenantCode,
                    name: parentTenant.name,
                    businessId: parentTenant.businessId,
                    organizationId: parentTenant.organizationId,
                    nodes: [
                      {
                        tenantCode: tenantFromService.value.tenantCode,
                        name: tenantFromService.value.name,
                        businessId: tenantFromService.value.businessId,
                        organizationId: null,
                      },
                    ],
                  }
                : {
                    tenantCode: tenantFromService.value.tenantCode,
                    name: tenantFromService.value.name,
                    businessId: tenantFromService.value.businessId,
                    organizationId: null,
                    nodes: null,
                  },
            )
          }
        } else {
          setCurrentTenant({
            tenantCode: jazzRootTenant.tenantCode,
            name: jazzRootTenant.name,
            businessId: null,
            organizationId: null,
            nodes: null,
          })
        }
      }
      setTenantsList($tenants)
      setMenuItemData(getSideMenu)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTenantCode, data, error, isLoading, setCurrentTenant, setMenuItemData])

  useEffect(() => {
    if (userDataNeedsRefetch) {
      refetch()
    }
  }, [refetch, userDataNeedsRefetch])

  if (error) {
    const pathSeparator = bcUrl.endsWith('/') ? '' : '/'
    logError(error)
    window.location.assign(`${bcUrl}${pathSeparator}unserror?return=${window.location}`)
  }

  if (!currentTenant || userDataNeedsRefetch || !menuItemData || tenantsList?.length === 0) {
    return isMobile ? null : <UnifiedSidebarSkeleton />
  }

  const { user, nodes } = menuItemData

  return (
    <nav
      data-id="cart-unified-sidebar"
      css={[
        tw`[transition-property: width] [z-index: 1] sticky top-0 flex h-screen border-none bg-monochrome-50 text-monochrome-800 duration-300 sm:[max-width: 300px]`,
        css`
          @media (display-mode: standalone) {
            padding-top: env(safe-area-inset-top);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 427.97 57.03'%3E%3Cpath d='m243.67 0H0v57.03h227.08c4.76-18.83 9.04-37.79 16.3-55.89.14-.36.22-.74.28-1.13Z' fill='%23175882'/%3E%3Cpath d='m280.08 57.03h67.49c15.92-20.89 37.23-33.68 62.38-40.67 6-1.67 12.01-3.29 18.02-4.92V0h-115.83c-7.35 8.58-14.47 17.28-20.05 27.22-5.32 9.47-9.2 19.4-12.01 29.81Z' fill='%23df8935'/%3E%3Cpath d='m243.39 1.13c-7.26 18.1-11.54 37.07-16.3 55.89h53c2.81-10.4 6.7-20.34 12.01-29.81 5.58-9.94 12.7-18.64 20.05-27.22h-68.47c-.07.39-.14.78-.28 1.13Z' fill='%23d56e27'/%3E%3Cpath d='m347.57 57.03h80.4V11.43c-6.01 1.64-12.02 3.26-18.02 4.92-25.15 6.99-46.45 19.78-62.38 40.67Z' fill='%23e6a867'/%3E%3C/svg%3E");
          }
          @media (orientation: landscape) {
            background-image: none;
          }
        `,
        isMobile && tw`max-h-18 w-full flex-wrap`,
        !isMobile && [
          tw`border-monochrome-900/10 border border-l border-solid`,
          isMenuExpanded ? tw`shrink-0 w-[300px]` : tw`w-[64px]`,
          isTransitioning && tw`overflow-hidden`,
        ],
      ]}
    >
      <CustomStyles />
      {isMobile && (
        <Header jazzUrl={jazzUrl} isExpanded={isMenuExpanded} toggleExpanded={toggleExpanded} />
      )}
      <div
        css={[
          tw`[transition-property: left] flex h-full w-full flex-col bg-monochrome-50 duration-300 sm:bg-transparent`,
          isMenuExpanded && tw`sm:[min-width:300px]`,
          isMobile && [
            tw`absolute inset-0 z-10 h-screen bg-monochrome-50`,
            !isMenuExpanded && tw`[left: -100vw]`,
          ],
        ]}
      >
        <Header jazzUrl={jazzUrl} toggleExpanded={toggleExpanded} url={searchUrl} />
        <div
          tw="w-full shrink-0 whitespace-nowrap px-2 pt-4 pb-2"
          css={[isMenuExpanded && tw`px-4`]}
        >
          <TenantInfo />
        </div>
        <div
          tw="flex-auto w-full whitespace-nowrap p-2 transition-all duration-300"
          css={[isMenuExpanded && tw`overflow-auto px-4`]}
        >
          <MenuItems nodes={nodes} />
        </div>
        <div
          tw="w-full whitespace-nowrap p-2 transition-all duration-300"
          css={[
            isMenuExpanded && tw`px-4`,
            css`
              @media (display-mode: standalone) {
                padding-bottom: 2rem;
                @media (orientation: landscape) {
                  padding-bottom: 1rem;
                }
              }
            `,
          ]}
        >
          <UserInfo user={user} />
        </div>
      </div>
    </nav>
  )
}
