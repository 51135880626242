import tw, { css, styled } from 'twin.macro'

export const StyledCarouselItem = styled.div(
  ({ slidesToScroll, variableWidth }: { slidesToScroll: number; variableWidth: number }) => [
    slidesToScroll === 1 && !variableWidth && tw`relative max-w-full [flex: 0 0 100%] ml-[10px]`,
    (slidesToScroll !== 1 || variableWidth) && tw`relative pl-[10px]`,
    !!variableWidth && tw`ml-[10px]`,
    css`
      min-width: ${variableWidth ? `${variableWidth}px` : `${100 / slidesToScroll}%`};
    `,
  ],
)
export const StyledCarouselView = styled.div(({ variableWidth }: { variableWidth: number }) => [
  tw`relative h-full py-6`,
  css`
    width: ${variableWidth ? `${variableWidth}px` : 'auto'};
  `,
])
