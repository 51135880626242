import { useReactiveVar } from '@apollo/client'
import { activeOrgVar, useUADataConnectorsListQuery } from '@brand-console/generated-graphql-hooks'
import { Button, Card, CardContent, StatusBadge } from '@cart/ui'
import React, { useMemo } from 'react'

import { dataConnectorsData, dataConnectorsFields, dataStatusColor } from '../../../helpers/data'
import { ButtonSetup } from '../../MyConnectors/MyConnectorsModal/components/Modal/ButtonSetup'

type IncompleteConnectorsProps = {
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  setChosenAndShowDeleteModal?
}

export const IncompleteConnectors = ({
  setShow,
  setChosenAndShowDeleteModal,
}: IncompleteConnectorsProps) => {
  const activeOrg = useReactiveVar(activeOrgVar)

  const { data: dataConnectorsList } = useUADataConnectorsListQuery({
    variables: {
      organizationId: activeOrg?.id.toString(),
    },
  })

  const incompleteDataConnectors = useMemo(() => {
    const incompletes = dataConnectorsList?.ua_dataConnectorsList.flat().filter((dataConnector) => {
      return (
        dataConnector.status !== 'active' &&
        dataConnector.status !== 'pending' &&
        dataConnector.status !== 'running' &&
        dataConnector.status !== 'succeeded'
      )
    })
    const newIncomplete = []
    incompletes?.forEach((incomplete) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const fieldKey of Object.keys(dataConnectorsFields)) {
        if (incomplete.title === dataConnectorsFields[fieldKey].title) {
          newIncomplete.push({
            title: incomplete.title,
            business: incomplete.business,
            id: incomplete.id,
            field: fieldKey,
            dataConnectorType: incomplete.dataConnectorType,
            categories: incomplete.categories,
            status: incomplete.status,
          })
        }
      }
    })
    return newIncomplete
  }, [dataConnectorsList])

  if (incompleteDataConnectors?.length === 0) {
    return null
  }

  return (
    <>
      <h3 tw="mt-7 mb-0">Incomplete</h3>
      <div
        data-testid="incomplete-connectors"
        tw="mt-7 grid w-full grid-cols-1 gap-7 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
      >
        {incompleteDataConnectors &&
          incompleteDataConnectors.map((dc) => (
            <Card key={`${dc.title}-${dc.business.id}`} tw="w-full md:w-auto">
              <CardContent tw="flex items-center justify-between">
                <div tw="flex items-center">
                  <div>
                    <div tw="flex items-center">
                      <img
                        alt={dc.title}
                        src={dataConnectorsData.find(({ title }) => title === dc.title).icon}
                        style={{
                          width: 35,
                          marginRight: 15,
                        }}
                      />
                      <h3 tw="m-0">{dc.title}</h3>
                    </div>
                    <p tw="mt-5 flex items-center">{dc.business.name} </p>

                    {dc.status && (
                      <div tw="w-fit capitalize">
                        <StatusBadge type={dataStatusColor[dc.status]} badgeContent={dc.status} />
                      </div>
                    )}
                    <div tw="mt-5 flex items-start">
                      <ButtonSetup
                        isIncomplete
                        incompleteDataConnector={dc}
                        connectorName={dc.field as keyof typeof dataConnectorsFields}
                        setShow={setShow}
                        variant="contained"
                        text="Connect"
                      />
                      <Button
                        variant="text"
                        size="medium"
                        onClick={() => setChosenAndShowDeleteModal(dc)}
                        tw="ml-2"
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}
      </div>
    </>
  )
}
