import { useAuth0 } from '@auth0/auth0-react'
import { localStorageHelper, LocalStorageKeys } from '@brand-console/utilities'

import { OnLogoutProps } from '../../auth.types'
import { sessionEnd } from '../../gql/requests'

export const useLogout = () => {
  const { getAccessTokenSilently, isAuthenticated, logout } = useAuth0()

  const checkLogoutUrl = (url?: string) => {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('nav') === 'mcm') {
      return `${process.env.NX_MCM_ORIGIN}/login`
    }
    return url || window.location.origin
  }

  const clearLocalStorage = () => {
    localStorageHelper.remove(LocalStorageKeys.ORGANIZATION)
    localStorageHelper.remove(LocalStorageKeys.BUSINESS)
  }

  const logoutAuth0 = (returnUrl: string) => {
    logout({ logoutParams: { returnTo: returnUrl } })
  }

  const endSession = async () => {
    try {
      const token = await getAccessTokenSilently()
      await sessionEnd(token)
    } catch (e) {
      //
    }
  }

  const onLogout = async (options: OnLogoutProps) => {
    const o = options || {}
    const returnUrl = checkLogoutUrl(o.returnUrl)
    if (!o.skipEndSession) await endSession()
    if (!o.skipClearLocalStorage) clearLocalStorage()
    if (o.skipAuth0Logout || !isAuthenticated) {
      if (o.callback) {
        o.callback()
      }
      if (returnUrl) {
        window.location.href = returnUrl
      }
    } else {
      logoutAuth0(returnUrl)
    }
  }

  return { onLogout }
}
