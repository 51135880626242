import { hubspotFormSubmit, logger } from '@brand-console/utilities'
import { Breadcrumbs, Button, Dialog, useAppContext } from '@cart/ui'
import { useCartAuth, useCurrentContext } from '@cartdotcom/auth'
import CardActions from '@mui/material/CardActions'
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import TagManager from 'react-gtm-module'
import tw from 'twin.macro'

import { TrialForm, TrialFormProps } from '../../components/TrialForm'
import { useAccountConfirmContext } from '../../components/AccountConfirmForm'
import { ACSingleSignOn } from '../../helpers'
import { CreateStore } from './CreateStore'
import { CreateStoreState } from './CreateStore/CreateStore.types'
import { StorefrontErrorTypes, useStorefrontApi } from './hooks/useStorefrontApi/useStorefrontApi'
import { businessError, Form, FormErrors } from './StorefrontRoute.form'
import { IAccountConfirmData } from '../../components/AccountConfirmForm'
import { NameValidationConfig } from './StorefrontRoute.constants'
import { createDomainFriendlyText, generateUniqueId } from '@brand-console/utilities/text'

type StorefrontRouteProps = { modalMode?: boolean }
export const StorefrontRoute = ({ modalMode }: StorefrontRouteProps): ReactElement => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [errorHeader, setErrorHeader] = useState<string>('')
  const [errorCode, setErrorCode] = useState<number>(null)
  const [showFailureAlert, setShowFailureAlert] = useState<boolean>(false)
  const [showCreateStoreDialog, setShowCreateStoreDialog] = useState<boolean>(false)
  const [state, setState] = useState(CreateStoreState.PROVISIONING)
  const { auth0Token } = useAppContext()
  const { email, auth0Id } = useCartAuth()
  const { currentBusiness, currentOrganization } = useCurrentContext()
  const { methods } = useAccountConfirmContext()
  const savedValidStoreName = useRef()

  const log = logger.setLogger(logger.LoggerNames.STOREFRONT)
  const {
    watch,
    setError,
    formState: { errors },
    clearErrors,
    setValue,
  } = methods

  const [provisioningFailureMessage, setProvisioningFailureMessage] = useState(
    'An unspecified error occurred.',
  )

  const storeName = watch('storeName')

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        route: 'OnlineStore',
      },
    })
  }, [])

  const { callProvisioningAPI, validateStoreName } = useStorefrontApi()

  const onBeforeUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault()
    e.returnValue = 'Your trial is currently processing.  Are you sure you want to exit?'
  }

  const toggleCreateStoreDialog = useCallback(() => {
    setShowCreateStoreDialog(!showCreateStoreDialog)
  }, [showCreateStoreDialog])

  const handleAlertClose = () => {
    setShowFailureAlert(false)
  }

  /** Displays either error alert or field error */
  const handleError = ({
    header = 'Something went wrong when attempting to validate your store name:',
    message,
    code,
    fieldId,
  }: {
    message: string
    code?: number
    /** The alert header message. Not needed for field errors */
    header?: string
    /** The field to associate with the error message. Use this for field-specific errors rather than alert errors. */
    fieldId?: string
  }) => {
    setErrorHeader(header)
    setErrorCode(code)
    setError(fieldId || businessError, { message, type: 'custom' })
    if (!fieldId) {
      setShowFailureAlert(true)
    }
  }

  const onAccountFormError: TrialFormProps['onError'] = (error, friendlyErrorMessage) => {
    setShowFailureAlert(true)
    handleError({
      message: friendlyErrorMessage,
      header: 'Something went wrong when attempting to update your account information:',
    })
  }

  const getValidName = async (originalName: string, currName?: string, retry = 0) => {
    if (retry >= NameValidationConfig.MAX_RETRY_LIMIT) {
      return undefined
    }

    const result = await validateStoreName(currName || originalName)
    const error = result.errors?.[0]

    if (!result.success) {
      const newName =
        error.errorType === StorefrontErrorTypes.STORE_NAME_TAKEN
          ? `${originalName}${generateUniqueId(NameValidationConfig.SHORT_ID_LENGTH)}`
          : generateUniqueId(NameValidationConfig.LONG_ID_LENGTH)

      return getValidName(originalName, newName, retry + 1)
    }

    return currName || originalName
  }


  // Try to prefill in the store name with the businessName
  const preValidateForm: TrialFormProps['formTransitionIn'] = useCallback(async (data, resolve) => {
    let validName = savedValidStoreName.current

    if (!validName) {
      const { businessName } = data
      const domainFriendlyName = createDomainFriendlyText(businessName)
      validName = await getValidName(domainFriendlyName)
      savedValidStoreName.current = validName
    }

    setValue('storeName', validName)
    resolve()
  }, [])

  const beforeSubmit: TrialFormProps['beforeSubmit'] = () => {
    setShowFailureAlert(false)
    clearErrors('businessError')
  }

  const onSubmit = async (data: IAccountConfirmData) => {
    const { firstName, lastName, phone, orgName, businessName } = data

    setState(CreateStoreState.PROVISIONING)
    window.addEventListener('beforeunload', onBeforeUnload)
    setIsSubmitting(true)
    // Validate the storeName server side before we request provisioning.
    try {
      const result = await validateStoreName(storeName)
      const error = result.errors?.[0]

      if (!result.success) {
        handleError({
          message: error.errorMessage,
          code: error.code,
          fieldId: error.fieldId,
        })
        window.removeEventListener('beforeunload', onBeforeUnload)
        return
      }

      hubspotFormSubmit({
        formId: '8d21d162-a352-460a-b0e0-c0e4669bf79b',
        data: {
          email,
          firstname: firstName,
          lastname: lastName,
          phone,
          console_organization_name: orgName,
          console_organization_id: currentOrganization?.id,
          console_business_id: currentBusiness?.id,
          console_business_name: businessName,
          console_trial___storefront: true,
        },
        portalId: parseInt(process.env.NX_HUBSPOT_MARKETING_FORMS_PORTAL_ID, 10),
        pageName: 'Brand Console - Storefront Trial Creation',
      })
      setShowCreateStoreDialog(true)
      setShowFailureAlert(false)

      const provisionResult = await callProvisioningAPI(storeName)
      window.removeEventListener('beforeunload', onBeforeUnload)
      if (provisionResult.success) {
        setState(CreateStoreState.SUCCESS)
        ACSingleSignOn(
          `https://${storeName}.americommerce.com/store/admin/login.aspx?fromBC=1`,
          auth0Token,
          auth0Id,
        )
      } else {
        setState(CreateStoreState.FAILURE)
        setProvisioningFailureMessage(provisionResult.message)
      }
    } catch (error) {
      log.error(
        'Storefront Trial Error: validateStoreNameOnServer',
        {
          storeName,
          firstName,
          lastName,
          email,
        },
        error,
      )
      setShowFailureAlert(true)
      handleError({
        message: error.message,
      })
    } finally {
      window.removeEventListener('beforeunload', onBeforeUnload)
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <Dialog id="create-store-dialog" open={showCreateStoreDialog} hideCloseButton width="sm">
        <CreateStore
          close={toggleCreateStoreDialog}
          state={state}
          errorMessage={provisioningFailureMessage}
        />
      </Dialog>

      <section tw="mx-auto w-full max-w-screen-xl px-3.5">
        {!modalMode && (
          <Breadcrumbs
            tw="relative z-10 my-6 sm:ml-4"
            items={[{ text: 'Home', href: '/' }, { text: 'Storefront' }]}
          />
        )}

        <div tw="mx-auto max-w-3xl">
          <TrialForm
            modal={modalMode}
            formTitle="Name your store"
            formProps={{ name: 'nameYourStore' }}
            beforeSubmit={beforeSubmit}
            onSubmit={onSubmit}
            onError={onAccountFormError}
            formTransitionIn={preValidateForm}
            dialogProps={{
              transitionDuration: 0,
            }}
            errorComponent={
              <FormErrors
                errors={errors}
                handleAlertClose={handleAlertClose}
                errorHeader={errorHeader}
                errorCode={errorCode}
                showFailureAlert={showFailureAlert}
              />
            }
          >
            <div css={!modalMode && [tw`mx-auto max-w-3xl`]}>
              <Form />
            </div>
            <CardActions tw="justify-end p-0 pt-6">
              <Button
                loading={isSubmitting}
                type="submit"
                variant="contained"
                onClick={() => clearErrors('businessError')}
              >
                Build My Store
              </Button>
            </CardActions>
          </TrialForm>
        </div>
      </section>
    </>
  )
}
